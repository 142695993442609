/**
 * Декоратор для фабрики
 * @param name
 * @returns {Function}
 * @constructor
 */
export function Constant(name) {
	return function (target) {
		const res = {name, controller: new target()};

		res.controller.$type = "constant";

		return res;
	};
}
