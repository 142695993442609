angular
	.module("ezd.backend")
	.factory("AEProfiles", aeProfiles);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
function aeProfiles(Core) {
	return Core.service("/api/ae_profiles");
}

aeProfiles.$inject = ["Core"];

