const URL = "/api/ec_schedules";
const DATE_FORMAT = "YYYY-MM-DD";

import _flow from "lodash/fp/flow";
import _groupBy from "lodash/fp/groupBy";
import _map from "lodash/fp/map";
import _reduce from "lodash/fp/reduce";
import _getOr from "lodash/fp/getOr";

class ECSchedule {
	/**
	 * Constructor
	 * @param weekNumber {number}
	 * @param beginDate  {string} "YYYY-MM-DD"
	 * @param endDate    {string} "YYYY-MM-DD"
	 */
	constructor(weekNumber, beginDate, endDate) {
		this.id = null;
		this.hours_count = 0;
		this.items_count = 0;
		this.week_number = weekNumber;
		this.begin_date = beginDate;
		this.end_date = endDate;
	}
}


class ECSchedulesService {
	static $inject = ["EC", "$$academicYear", "bigQuery"];

	/**
	 * Constructor
	 */
	constructor(EC, $$academicYear, bigQuery) {
		this.ECService = EC;
		this.services = {$$academicYear, bigQuery};
		this.collection = EC.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.ECService.one(URL, id);
	}


	/**
	 * Загружаем расписания
	 * @param weeklyLoadsFilter: {[key: string]: number | string}
	 * week_number: {number}
	 * education_level_id: {number}
	 * class_level_id: {number}
	 * class_unit_ids: {Array<number>}
	 */
	async getSchedules(weeklyLoadsFilter, getLoad = true) {
		const {$$academicYear, bigQuery} = this.services;
		const weeklyLoadsUrl = `${URL}/get_planned_weekly_load`;

		try {
			const academicYear = await $$academicYear.getSelected();
			const schedules = await bigQuery.queue.getList(this.collection, {academic_year_id: academicYear.id});
			let weeklyLoads = [];
			if (getLoad) {
				weeklyLoads = await this.ECService.all(weeklyLoadsUrl).getList(weeklyLoadsFilter);
				weeklyLoads = mergeWeekLoads(weeklyLoads);
			}
			// @ToDo отбрасываем недели без номера (пока бэк не поправит)
			const $schedules = _.filter(schedules, (sc) => Boolean(sc.week_number));

			return this.$mapSchedules($schedules, weeklyLoads, academicYear);
		} catch (error) {
			throw error;
		}

		/**
		 * Совмещение данных групп по одинаковым неделям
		 * */
		function mergeWeekLoads(loadsData) {
			return _flow(
				_groupBy("week_number"),
				_map((weekLoads) => {
					return _reduce((load, next) => Object({
						week_number: next.week_number,
						hours_count: _getOr(0, "hours_count")(load) + next.hours_count,
						items_count: _getOr(0, "items_count")(load) + next.items_count
					}), {})(weekLoads);
				})
			)(loadsData);
		}
	}


	/**
	 * Копируем расписание
	 * @param id: number
	 * @param query: {is_hard_mode: boolean, week_numbers: number[]}
	 */
	async copy(id, query) {
		try {
			return this.bindOne(id).all("copy").customPUT(query);
		} catch (error) {
			throw error;
		}
	}


	/**
	 * Удаляем расписание
	 * @param id
	 * @returns {Promise<*>}
	 */
	async remove(id) {
		try {
			return this.bindOne(id).remove();
		} catch (error) {
			throw error;
		}
	}


	/**
	 * Мапим расписания
	 */
	$mapSchedules(schedules, weeklyLoads, academicYear) {
		const {begin_date: begin, end_date: end} = academicYear;
		const dummyWeeks = [];
		const beginYear = moment(begin, DATE_FORMAT);
		const endYear = moment(end, DATE_FORMAT);
		let beginMoment = moment(begin, DATE_FORMAT);
		let endMoment = moment(begin, DATE_FORMAT).endOf("week").endOf("day");
		let weekNumber = 1;

		while (beginMoment.isBetween(beginYear, endYear, null, "[]")) {
			const schedule = _.find(schedules, {week_number: weekNumber});
			const weekLoad = _.find(weeklyLoads, {week_number: weekNumber}) || {hours_count: 0, items_count: 0};
			const beginDate = beginMoment.format(DATE_FORMAT);
			const endDate = endMoment.isAfter(endYear) ? endYear.format(DATE_FORMAT) : endMoment.format(DATE_FORMAT);

			if (Boolean(schedule)) {
				_.assign(schedule, {begin_date: beginDate, end_date: endDate}, weekLoad);
			} else {
				dummyWeeks.push(_.assign(new ECSchedule(weekNumber, beginDate, endDate), weekLoad));
			}

			weekNumber += 1;
			beginMoment = endMoment.clone().add(1, "day");
			endMoment = beginMoment.clone().endOf("week").endOf("day");
		}

		return _.sortBy([...schedules, ...dummyWeeks], "week_number");
	}
}


angular
	.module("ezd.backend")
	.service("ECSchedules", ECSchedulesService);
