import {Constant} from "app/decorators/constant";

@Constant("RIGHTS")
export class RightsConst {
	SECURITY_RESPONSIBLE = 1; // отвественный за алгоритм безопасности
	REPLACEMENTS = 2; // работа с заменами
	ADDITIONAL_EDUCATION = 3; // ответственный за дополнительное образование
	STUDENTS = 4; // ответственный за контингент
	EC_ACTIVITY = 5; // ответственный за внеурочку
	PASSPORT_PRINTER = 6; // ответственный за печать аттестатов
}
