import {Inject} from "./inject";
import isEmpty from "lodash/fp/isEmpty";

/**
 * Декоратор для компонента
 * @param options
 * @returns {Function}
 * @constructor
 */
export function Component(options) {
	return function (target) {
		const res = {...options, controller: target};

		if (target.template) {
			res.template = target.template;
			res.template.$inject = ["$element", "$attrs"];
			delete target.template;
		}

		// иньекция зависимостей в контроллер
/*		if (isEmpty(target.$inject)) {
			res.controller = Inject(null, null, {value: target}).value;
		}*/

		return res;
	};
}
