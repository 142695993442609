import _assign from "lodash/fp/assign";

angular
	.module("ezd.backend")
	.factory("Rights", rights);

rights.$inject = ["Core", "RIGHTS"];


/**
 *
 * @param Core
 * @param RIGHTS
 * @returns {Object|*}
 */
function rights(Core, RIGHTS) {
	return _assign(Core.service("/api/rights"), RIGHTS);
}
