angular
	.module("ezd.backend")
	.factory("AEAttendances", aeAttendances);


/**
 *
 * @param Core
 * @param $q
 * @returns {*|Object}
 */
aeAttendances.$inject = ["Core", "$q"];
function aeAttendances(Core, $q) {
	const url = "/api/ae_attendances";
	const Service = Core.service(url);
	const ABSENCE_REASONS = {
		OUT: 2,
		FREE: 1
	};

	/**
	 *
	 * DECLARATION
	 *
	 */

	Service.getTemplate = getTemplate;
	Service.getString = getString;
	Service.createUpdateAttendance = createUpdateAttendance;
	Service.removeAttendance = removeAttendance;
	Service.getPreschoolTemplate = getPreschoolTemplate;
	Service.getStudentNonattendances = getStudentNonattendances;
	Service.getList = (query) => Core.all(url).getList(query);


	/**
	 *
	 * RUN
	 *
	 */
	return Service;


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 * Получаем шаблон для пропуска
	 * @param scheduleLessonId
	 * @param studentId
	 * @returns {{absence_reason: null, schedule_lesson_id: *, student_profile_id: *}}
	 */
	function getTemplate(scheduleLessonId, studentId) {
		return {
			absence_reason: null,
			ae_lesson_id: scheduleLessonId,
			student_profile_id: studentId
		};
	}

	function getPreschoolTemplate(date, studentId) {
		return {
			date,
			student_profile_id: studentId,
			absence_reason_id: ABSENCE_REASONS.OUT
		};
	}


	/**
	 * Получаем название пропуска по типу причины отсутствия
	 * @param attendance
	 * @returns {string}
	 */
	function getString(attendance) {
		return (attendance.absence_reason_id) !== null
			? ((attendance.absence_reason_id === ABSENCE_REASONS.OUT) ? "н" : "о") : null;
	}

	/**
	 * Удаление пропуска
	 * @param attendance
	 * @returns {*}
	 */
	function removeAttendance(attendance) {
		if (!attendance.restangularized) {
			Core.restangularizeElement(null, attendance, url);
		}

		return attendance.remove();
	}

	/**
	 * Создание / Изменение пропуска
	 * @param attendance {Object}
	 * @returns {Promise}
	 */
	function createUpdateAttendance(attendance) {
		let localAttendance;

		if (!attendance.restangularized) {
			localAttendance = Core.restangularizeElement(null, attendance, url);
		} else {
			localAttendance = attendance;
		}

		return localAttendance.save()
			.then((result) => {
				result.status = getString(result);

				return $q.when(result);
			});
	}

	function getStudentNonattendances(id) {
		if (id) {
			return Service.getList({
				student_profile_ids: id
			});
		}
		throw id;
	}
}