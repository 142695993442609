import forEach from "lodash/fp/forEach";
import isFunction from "lodash/fp/isFunction";
import getOr from "lodash/fp/getOr";
import isString from "lodash/fp/isString";

/**
 * Декоратор для модуля
 * @param options { name: String, imports: Array<String>, providers: Array<Function>, declarations: Array<Object> }
 * @returns String
 * @constructor
 */
export function Module({name, imports = [], providers = [], declarations = []}) {
	if (!Boolean(name)) {
		throw new Error("Не задано имя модуля");
	}

	return function (moduleClass) {
		const moduleClassInstance = new moduleClass();
		const moduleClassProto = Object.getPrototypeOf(moduleClassInstance);

		// создаем новый модуль
		const newModule = angular.module(name, [...imports]);

		// подключаем сервисы, фильтры, фабрики из providers
		forEach((provider) => {
			if (isString(provider.controller.$type) && isFunction(getOr(null, provider.controller.$type, newModule))) {
				getOr(null, provider.controller.$type, newModule).call(newModule, provider.name, provider.controller);
			} else {
				console.warn("Cannot get provider type", provider.controller.$type);
			}
		})(providers);

		// подключаем компоненты из declarations
		forEach((component) => {
			newModule.component(component.selector, component);
		})(declarations);


		/**
		 * выполняем конфигурационные функции ангуляровского модуля (run, config и т.п),
		 * передавая в них методы целевого класса
		 */
		forEach((instanceMethodName) => {
			if ((instanceMethodName !== "constructor") && isFunction(getOr(null, instanceMethodName, newModule))) {
				getOr(null, instanceMethodName, newModule).call(moduleClassInstance, getOr(null, instanceMethodName, moduleClassInstance));
			}
		})(Object.getOwnPropertyNames(moduleClassProto));

		return newModule.name;
	};
}
