import _find from "lodash/fp/find";
import _includes from "lodash/fp/includes";

angular
	.module("ezd.backend")
	.factory("Sessions", Sessions);

Sessions.$inject = ["LMSv3", "$q", "$cookies", "$$user", "$$profile", "$window", "$$academicYear", "Restangular", "SmartiusService"];

/**
 *
 * @param LMSv3
 * @param $q
 * @param $cookies
 * @param $$user
 * @param $$profile
 * @param $window
 * @param $location
 * @param $$academicYear
 * @param Restangular
 * @returns {*|Object}
 * @constructor
 */
function Sessions(LMSv3, $q, $cookies, $$user, $$profile, $window, $$academicYear, Restangular, Smartius) {
	const Service = LMSv3.service("/api/sessions");

	// bindings
	Service.drop = drop;
	Service.dropSession = dropSession;
	Service.loadEntities = loadEntities;
	Service.dropEntities = dropEntities;
	Service.isStudentOrParent = isStudentOrParent;
	Service.initSession = initSession;
	Service.getPin = getPin;

	Service.retryCount = 0;

	return Service;


	// implementation

	/**
	 * Загрузка сообщений
	 */
	function loadEntities() {
		// $$message.loadList()
	}

	/**
	 * Очистка связанных с текущей сессией данных в сервисах
	 */
	function dropEntities() {
		$$user.drop();
		$$profile.drop();
	}

	/**
	 * Перекидывает родителей и учеников в кабинет рсааг
	 */
	function isStudentOrParent(callback) {
		$$user.getCurrentUser()
			.then((currentUser) => {
				if (currentUser && currentUser.profiles && currentUser.profiles.length > 0) {
					let currentProfiles = currentUser.profiles[0];
					if (currentProfiles && currentProfiles.roles instanceof Array) {
						let currentRoles = currentProfiles.roles;
						if (currentRoles.includes("student") || currentRoles.includes("parent")) {
							return true;
						}
					}
				}

				return false;
			})
			.then((studentOrParent) => {
				callback(studentOrParent);
			})
			.catch((err) => console.log(err));
	}

	/**
	 *
	 * @returns {*}
	 */
	function getPin() {
		return LMSv3.one("/api/sessions", "pin").get();
	}

	/**
	 * Удалить сессию пользователя на бэке
	 */
	function dropSession() {
		const token = $cookies.get("auth_token");
		const params = {authentication_token: token};

		return Restangular.all("lms/api/sessions").customDELETE(null, params, {"Content-Type": "application/json"});
	}

	/**
	 * Очистка данных текущего пользователя
	 */
	function drop() {
		window.location.pathname = "/authenticate/oauth/epos/logout";
		const allCookies = $cookies.getAll();
		// drop cookies
		_.forOwn(allCookies, (val, k) => {
			if (!_.includes(["journalRightMenuState"], k)) {
				$cookies.remove(k);
			}
		});

		dropEntities();

		Smartius.clearUserData();
	}

	/**
	 * инициализация данных пользователя
	 */
	function initSession() {
		// если в cookies нет токена, то отправляем на страницу авторизации
		if (!$cookies.get("auth_token")) {
			Service.drop();

			return $q((r, rj) => rj());
		}

		// получаем данные по токену
		return Service
			.post({
				auth_token: $cookies.get("auth_token")
			})
			.then((res) => {
				return $q.all({
					years: $$academicYear.getList(),
					user: res
				});
			})
			.then(async ({user}) => {
				await $$user.setCurrentUser(user);
				Service.retryCount = 0;

				const foundProfile = _find((profile) => {
					return _includes(profile.type)(["admin", "admin_security"]);
				})(user.profiles);

				if (foundProfile) {
					$cookies.put("profile_roles", foundProfile.roles.join(","));
				}

				Smartius.insertUserData(user);
			})
			.catch(() => {
				if (Service.retryCount > 2) {
					console.log("drop", Service.retryCount);

					drop();
				}
				Service.retryCount++;
				Service.initSession();
			});
	}
}

