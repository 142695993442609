

angular
	.module("ezd.backend")
	.service("$$educationLevel", $$educationLevel);


/**
 *
 * @param Core
 */
$$educationLevel.$inject = ["Core"];
function $$educationLevel(Core) {
	const url = "/api/education_levels";
	const vm = this;

	vm.list = [];
	vm.collection = Core.all(url);

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);
}