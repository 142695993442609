import {Component} from "app/decorators/index";

import ezdSelectTemplate from "app/components/common/components/ezd-autocomplete-select/ezd-autocomplete-select.html";
import ezdSelectStyles from "app/components/common/components/ezd-autocomplete-select/ezd-autocomplete-select.local.scss";
import subjectItemStyles from "./subject-item-select.local.scss";
import subjectItemTemplate from "./subject-item-select.html";

import _map from "lodash/fp/map";
import _flow from "lodash/fp/flow";
import _filter from "lodash/fp/filter";
import _includes from "lodash/fp/includes";
import {EzdAutocompleteSelectComponent} from "components/common/components/ezd-autocomplete-select/ezd-autocomplete-select.component";
import _getNumEnding from "tools/fp/getNumEnding";


@Component({
	selector: "subjectSelect",
	bindings: {
		educationLevels: "<",
		showClassLevel: "<",
		...EzdAutocompleteSelectComponent.bindings
	},
	controllerAs: EzdAutocompleteSelectComponent.controllerAs
})
class SubjectSelectComponent extends EzdAutocompleteSelectComponent.controller {
	static $inject = ["$scope", "$timeout", "$element"];

	/**
	 *
	 */
	static template() {
		return ezdSelectTemplate.replace("<item-placeholder></item-placeholder>", subjectItemTemplate);
	}

	constructor($scope, $timeout, $element) {
		super($scope, $timeout, $element);
		this.styles = {...ezdSelectStyles, ...subjectItemStyles};
	}

	async $onInit() {
		super.$onInit();
	}

	$onChanges(changes) {
		if (changes.collection || changes.educationLevels || changes.showClassLevel) {
			this.$mapSubjects();
		}
	}

	/**
	 *
	 */
	$mapSubjects() {
		this.collection = _map((subject) => {
			subject.educationLevelNames = _flow(
				_filter((level) => _includes(level.id, subject.education_level_ids)),
				_map("name")
			)(this.educationLevels)
				.join(", ");

			subject.hours_unit = this.$getHoursUnit(subject.hours);

			return subject;
		})(this.collection);
	}

	/**
	 * getHoursUnit
	 */
	$getHoursUnit(hours) {
		if (!hours) {
			return;
		}

		return `${_getNumEnding(["час", "часа", "часов"])(hours)}`;
	}
}


angular
	.module("ezd.common.ui")
	.component(SubjectSelectComponent.selector, SubjectSelectComponent);
