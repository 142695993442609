import {Component} from "app/decorators";
import template from "./ezd-button-group.component.html";

// export interface EzdButtonGroupInput {
// 	title: string;
// 	value: any;
// 	isDisabled?: boolean;
// }
//
// export interface EzdButtonGroupConfig {
// 	background?: BG_COLORS;
// }

const BG_COLORS = {
	GREEN: "ezd-button-group-green",
	GREY: "ezd-button-group-grey"
};


@Component({
	selector: "ezdButtonGroup",
	template,
	bindings: {
		model: "<", // any
		collection: "<", // EzdButtonGroupInput[]
		color: "@", // string
		onChange: "&" // Function
	}
})
export class EzdButtonGroupComponent {
	config = null;

	/**
	 * OnInit
	 */
	$onInit() {
		if (typeof this.color === "undefined") {
			this.config = {background: BG_COLORS.GREEN};
		} else {
			this.config = {background: BG_COLORS[this.color.toUpperCase()] || BG_COLORS.GREEN};
		}
	}

	/**
	 * Обработчик нажатия на кнопку
	 * @param item
	 */
	selectItem(item) {
		if (typeof this.onChange === "function") {
			this.onChange({$event: item.value});
		}
	}
}
