/**
 * Календарно-тематический план вд
 */
class ECCourseCalendarPlan {
	static $inject = ["EC"];
	static URL = "/api/ec_course_calendar_plan";

	/**
	 * Constructor
	 */
	constructor(EC) {
		this.ECService = EC;
		this.collection = EC.all(ECCourseCalendarPlan.URL);
	}


	one(id) {
		return this.ECService.one(ECCourseCalendarPlan.URL, id);
	}

	/**
	 * Возвращает шаблон тематического плана вд
	 */
	getTemplate() {
		return {
			class_unit_id: null, // id класса, для групп которого создается ктп
			ec_activity_id: null, // id рабочей программы, для которой создается ктп
			ec_course_lesson_plan_id: null, // id тематического плана, из которого создается ктп
			modules: [] // массив модулей (ECCourseCalendarModule)
		};
	}

	/**
	 * редактирование плана
	 */
	updatePlan(plan) {
		return this.one(plan.id).customPUT(plan);
	}

	/**
	 * Удаление плана
	 * @param plan
	 * @returns {*}
	 */
	removePlan(plan) {
		return this.one(plan.id).customDELETE();
	}


	/**
	 * Обновление связей между уроками расписания и занятиями календарно-тематического плана.
	 * Осуществляется обновление всего КТП, включая исключения удалённых из расписания занятий
	 * и изменение занятий.
	 *
	 * @param planId id календарно-тематического плана
	 *
	 * @returns {*}
	 */
	recalcPlanById(planId) {
		return this.one(planId).all("recalc").customPOST();
	}

	/**
	 * Обновление связей между уроками расписания и занятиями календарно-тематического плана.
	 * Осуществляется обновление всего КТП, включая исключения удалённых из расписания занятий
	 * и изменение занятий.
	 *
	 * @param plan календарно-тематический план
	 *
	 * @returns {*}
	 */
	recalcPlan(plan) {
		return this.one("recalc").customPOST(plan);
	}

	/**
	 * Осуществляется добавление занятий из расписания, к уже имеющимся (без их обновления)
	 *
	 * @param plan календарно-тематический план
	 *
	 * @returns {*}
	 */
	finishPlan(plan) {
		return this.one(plan.id).all("finish").customPOST();
	}
}


angular
	.module("ezd.backend")
	.service("ECCourseCalendarPlan", ECCourseCalendarPlan);
