import template from "./reportFilters.html";


angular
	.module("ezd.common.ui")
	.directive("reportFilters", Directive);

/**
 *
 * @returns {{controller: Controller, controllerAs: string, scope: {student: string, classUnit: string, filter: string, onlyPeriods: string}, template}}
 * @constructor
 */
function Directive() {
	return {
		controller: Controller,
		controllerAs: "rFiltersCtrl",
		scope: {
			student: "=?", // профиль ученика, если нужно ограничить фильтры одним учеником, а не только классом
			classUnit: "=", // класс, для которого в фильтр загружаются данные
			filter: "=", // фильтр
			onlyPeriods: "=?",
			additionalCustomPeriods: "=" // дополнительные периоды когда выбран "custom_period">"За аттестационный период" (для onlyPeriods=false)
		},
		template
	};
}


/**
 *
 * @param $scope
 * @param $$academicYear
 * @param $$reportFilters
 * @constructor
 */
Controller.$inject = ["$scope", "$$academicYear", "$$reportFilters"];
function Controller($scope, $$academicYear, $$reportFilters) {
	const vm = this;

	// графики аттестационных периодов
	vm.attestationPeriodsSchedules = [];
	// учебные планы
	vm.lessonPlans = [];
	// графики учебных периодов
	vm.periodsSchedules = [];
	// индикатор загрузки периодов для фильтра
	vm.periodsLoader = null;
	// выбранный учебный год
	vm.year = null;
	// дополнительные периоды
	vm.additionalCustomPeriods = [];

	vm.periodGetterSetter = periodGetterSetter;


	init();

	function init() {
		if (!_.isObject($scope.filter)) {
			$scope.filter = {};
			initFilterValues();
		}

		$scope.onlyPeriods = Boolean($scope.onlyPeriods);

		$$academicYear.getSelected()
			.then((year) => {
				vm.year = year;
				// загружаем данные для фильтра
				$scope.$watch(() => {
					return $scope.classUnit;
				},
				(newVal, oldVal) => {
					if (!_.isEmpty($scope.classUnit)) {
						$scope.filter.classUnitId = _.int($scope.classUnit.id);
						loadPeriodsData();
					} else {
						// сброс параметров фильтров
						initFilterValues();
					}
				});
			});
	}

	/**
	 * initFilterValues
	 */
	function initFilterValues() {
		$scope.filter.markType = "";

		if ($scope.onlyPeriods) {
			$scope.filter.periodType = "all";
			$scope.filter.periodId = 0;
			$scope.filter.periodIds = [];
		} else {
			$scope.filter.periodType = "";
			$scope.filter.periodId = null;
			$scope.filter.periodIds = [];
		}
	}

	/**
	 * Загрузка данных для фильтра
	 */
	function loadPeriodsData() {
		const requestData = {
			academic_year_id: vm.year.id,
			class_unit_id: $scope.filter.classUnitId
		};

		if ($scope.student) {
			requestData.student_profile_id = $scope.student.id;
		}

		vm.periodsLoader = $$reportFilters
			.get(requestData)
			.then((data) => {
				vm.attestationPeriodsSchedules = data.attestation_periods_schedules || [];
				vm.periodsSchedules = data.periods_schedules || [];
				vm.lessonPlans = data.lesson_plans || [];
				vm.additionalCustomPeriods = $scope.additionalCustomPeriods || [];
			});
	}

	/**
	 * periodGetterSetter
	 */
	function periodGetterSetter(value) {
		let realValue;

		if (angular.isDefined(value) && !_.isNull(value)) {
			realValue = value.split("-");
			$scope.filter.periodType = realValue[0];
			$scope.filter.periodId = !_.isNaN(_.int(realValue[1])) ? _.int(realValue[1]) : realValue[1];
			$scope.filter.periodIds = realValue.slice(1);
		}

		return $scope.filter.periodType + "-" + (angular.isDefined($scope.filter.periodIds) ? $scope.filter.periodIds.join("-") : $scope.filter.periodId);
	}
}
