const URL = "/api/ae_attestation_periods";

class AEAttestationPeriods {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	one(id) {
		return this.CoreService.one(URL, id);
	}
}


angular
	.module("ezd.backend")
	.service("AEAttestationPeriods", AEAttestationPeriods);
