

angular
	.module("ezd.backend")
	.factory("$$parCurStudentsAssmnt", parCurStudentsAssmnts);


/**
 *
 * @param $q
 * @param Jersey
 * @returns {Object|*}
 */
parCurStudentsAssmnts.$inject = ["$q", "Jersey"];
function parCurStudentsAssmnts($q, Jersey) {
	const Service = Jersey.service("/api/parallel_curriculum_assignments");
	const checkAssignmentUrl = "/api/parallel_curriculum_assignments/checkIgnoreHomebase";

	Service.checkAssignment = checkAssignment;
	Service.getTemplate = getTemplate;
	Service.restoreAssmnt = restoreAssmnt;

	return Service;

	/**
	 * Проверяет возможность привязки студента к учебному плану.
	 * @param studentProfileId {number}
	 * @param beginDate {moment}
	 * @param endDate {moment}
	 * @param checkedAssignment {number}
	 * @returns {*}
	 */
	function checkAssignment(studentProfileId, beginDate, endDate, checkedAssignment) {
		const request = {
			student_profile_id: studentProfileId,
			begin_date: [
				beginDate.year(),
				beginDate.month() + 1,
				beginDate.date()
			],
			end_date: [
				endDate.year(),
				endDate.month() + 1,
				endDate.date()
			]
		};

		if (checkedAssignment) {
			request.checked_assignment = checkedAssignment;
		}

		return Jersey.all(checkAssignmentUrl)
			.post(request)
			.then((response) => {
				return response.result;
			});
	}

	function getTemplate() {
		return {
			generate_groups: true, // флаг определяет создавать ли группы для привязки. false - создать группы, true - не создавать группы
			parallel_curriculum_id: null, // id учебного плана
			home_base_period_id: null, // id периода надомного обучения
			student_profile_id: null, // id профиля ученика
			begin_date: [], // дата начала действия периода
			end_date: [], // дата конца действия периода
			is_archived: false // флаг определяет, является ли эта привязкой архивной
		};
	}

	function restoreAssmnt(assmnt, withGroups, withGroupsAssignments) {
		return Jersey.one(`/api/parallel_curriculum_assignments/${assmnt.id}/restore`)
			.customPUT(null, null, {
				with_groups: withGroups,
				with_groups_assignments: withGroupsAssignments
			});
	}
}

