

angular
	.module("ezd.backend")
	.service("$$isppEvent", isppEventService);

/**
 *
 * @param Core
 * @param $q
 */
isppEventService.$inject = ["Core", "$q"];
function isppEventService(Core, $q) {
	const url = "/api/ispp_events";
	const vm = this;


	/**
	 * DECLARATIONS
	 */

	vm.getEventsForClassUnits = getEventsForClassUnits;

	/**
	 * VARIABLES
	 */

	vm.collection = Core.all(url);
	vm.list = [];


	/**
	 * IMPLEMENTATION
	 */

	function getEventsForClassUnits(classUnitIds) {
		if (classUnitIds) {
			return $q
				.all(_.map(classUnitIds, (classUnitId) => {
					return vm.collection.getList({class_unit_id: classUnitId});
				}))
				.then((data) => {
					if (!data || (data.errCode && data.errCode !== 0)) {
						throw undefined;
					}

					return _.flatten(_.compact(_.uniqBy(data, "student_profile_id")));
				});
		}

		return $q((resolve, reject) => reject());
	}
}
