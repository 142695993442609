angular
	.module("ezd.backend")
	.factory("Notification", Notification);

/**
 * @desc Создаем новый инстанс Restangular с конфигом для модуля Core
 * @param $cookies
 * @param Restangular
 * @param Interceptors
 * @returns {*}
 * @constructor
 */
Notification.$inject = ["$cookies", "Restangular", "Interceptors"];
function Notification($cookies, Restangular, Interceptors) {
	return Restangular.withConfig((RestangularConfigurer) => {
		const headers = {Accept: "application/json"};

		if ($cookies.get("auth_token")) {
			headers["Auth-Token"] = $cookies.get("auth_token");
		}

		if ($cookies.get("profile_id")) {
			headers["Profile-Id"] = $cookies.get("profile_id");
			RestangularConfigurer.setDefaultRequestParams({pid: $cookies.get("profile_id")});
		}

		RestangularConfigurer.setDefaultHeaders(headers);
		RestangularConfigurer.setBaseUrl("/notification");
		RestangularConfigurer.setErrorInterceptor(Interceptors.error);
		RestangularConfigurer.addFullRequestInterceptor(Interceptors.fullRequest);
		RestangularConfigurer.addResponseInterceptor(Interceptors.responseInterceptor);
	});
}
