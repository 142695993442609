

angular
	.module("ezd.backend")
	.factory("$$booksToc", booksTocs);


/**
 * @desc Сервис
 * @param CMS - Restangular wrapper for CMS
 * @returns {Object|*}
 */
booksTocs.$inject = ["CMS_v4"];
function booksTocs(CMS_v4) {
	return CMS_v4.service("/api/books");
}
