

/** работа с комментариями домашних заданий */
angular
	.module("ezd.backend")
	.factory("HomeWorksComments", HomeWorksComments);

HomeWorksComments.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {Object|*}
 */
function HomeWorksComments(Core) {
	const Service = Core.service("/api/homework_entry_comments");

	/** getComments - метод для получения комментариев
	 * @param {number} id - homework_entry_id
	 * @returns {Promise} - возвращает промис комментариев к домашнему заданию */
	Service.getComments = (id) => Service.getList({homework_entry_id: id});

	/**
	 * removeComment - метод отправляющий запрос на удаление комментария
	 * @param {number} id - id комментария
	 * @param {array} commentsList - массив комментариев из которых нужно удалить один комментарий
	 * @return {Promise} - возвращает промис запроса
	 * */
	Service.removeComment = (id, commentsList) => {
		if (id >= 0 && commentsList) {
			return commentsList[_.findIndex(commentsList, {id})].remove();
		}
	};

	/**
	 * addComment - метод отправляющий запрос для добавления нового комментария
	 * @param {comment, id, teacherName} commentData - информация о комментарии
	 * @return {Promise} - возвращает промис запроса
	 * */
	Service.addComment = (commentData) => {
		if (commentData) {
			return Service.post({
				homework_entry_id: commentData.id,
				comment: commentData.comment,
				teacher_name: commentData.teacherName,
				homework_entry: commentData.homework_entry
			});
		}
	};

	return Service;
}
