import {Component} from "app/decorators/index";
import template from "./run-scripts.html";
import {EzdRunScriptsComponent} from "components/common/components/ezd-run-scripts/ezd-run-scripts.component";
import {eye, play, smallDots} from "images/icons/index";
import styles from "./run-scripts.local.scss";

@Component({
	selector: "runScripts",
	template,
	bindings: {
		deleteMaterial: "&",
		...EzdRunScriptsComponent.bindings
	},
	controllerAs: EzdRunScriptsComponent.controllerAs
})
export class RunScriptsComponent extends EzdRunScriptsComponent.controller {
	static $inject = ["$cookies", "EzdAlertDialog", "$mdDialog"];

	/**
	 *
	 */
	constructor($cookies, EzdAlertDialog, $mdDialog) {
		super($cookies, EzdAlertDialog, $mdDialog);
		this.icons = {play, eye, smallDots};
		this.styles = styles;
	}
}
