/**
 * Декоратор для инжекта зависимостей
 * @param dependencies: Array<String>
 * @returns Function
 */
export function Inject(dependencies) {
	return function (target, method) {
		target[method].$inject = dependencies;

		return target;
	};
}
