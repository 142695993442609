import "./styles.scss";
import template from "./ajax-loader.html";
import {Component} from "app/decorators/index";

@Component({
	selector: "ajaxLoader",
	template,
	bindings: {
		promise: "<",
		onSuccess: "&",
		onReject: "&",
		onFinally: "&"
	}
})
class AjaxLoaderComponent {
	static $inject = ["$q", "$element"];

	/**
	 * @ngInject
	 * */
	constructor($q, $element) {
		this.services = {$q, $element};
		/*
		 promise.$$state.status === 0 // pending
		 promise.$$state.status === 1 // resolved
		 promise.$$state.status === 2 // rejected
		 */
		this.state = 1;
	}

	$onChanges() {
		const {$q} = this.services;
		if (!this.promise) {
			this.state = 1;

			return;
		}

		this.state = 0;
		this.$fixLoaderWrapperPosition();
		$q.all([this.promise])
			.then(() => {
				this.state = 1;
				this.onSuccess();
			}).catch(() => {
				this.state = 2;
				this.onReject();
			}).finally(this.onFinally);
	}

	/**
	 * */
	$fixLoaderWrapperPosition() {
		const {$element} = this.services;

		this.loaderWrapperStyles = {};
		// если промис изменяется раньше, чем отрисовывается лоадер, то $element === undefined
		if (!$element) {
			return;
		}

		const parentRelativeElement = this.$getParentWithRelativePosition($element[0]);

		if (!parentRelativeElement) {
			return;
		}

		if (parentRelativeElement.scrollHeight) {
			this.loaderWrapperStyles.height = `${parentRelativeElement.scrollHeight}px`;
		}

		if (parentRelativeElement.scrollWidth) {
			this.loaderWrapperStyles.width = `${parentRelativeElement.scrollWidth}px`;
		}
	}


	/**
	 * */
	$getParentWithRelativePosition(element) {
		const parentElement = element.offsetParent || null;

		if (!parentElement) {
			return null;
		}

		const parentPositionType = window.getComputedStyle(parentElement).getPropertyValue("position");

		if (parentPositionType === "relative") {
			return parentElement;
		}

		return this.$getParentWithRelativePosition(parentElement);
	}
}

export {AjaxLoaderComponent};
