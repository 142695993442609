import template from "./ComponentTemplate.html";


angular
	.module("ezd.common.ui")
	.component("lessonTypesLegend", {
		template,
		scope: {},
		controller: componentController
	});


/**
 *
 * @param $scope
 * @param $mdDialog
 */
componentController.$inject = ["$scope", "$mdDialog"];
function componentController($scope, $mdDialog) {
	const vm = this;

	/**
	 * STYLES
	 **/
	vm.styles = require("./styles.local.scss");
	vm.icons = {
		help: require("images/icons/ic_help_outline_black_24px.svg"),
		close: require("images/icons/ic_close_white_24px.svg")
	};

	/**
	 * VARIABLES
	 **/

	/**
	 * BINDINGS
	 **/
	vm.modalOpen = modalOpen;
	vm.modalClose = modalClose;

	/**
	 * INIT
	 ***/
	console.log("Help component linked!");


	/**
	 * Открываем модальное окно
	 * */
	function modalOpen() {
		$mdDialog.show({
			parent: angular.element(document.body),
			clickOutsideToClose: true,
			scope: $scope,
			preserveScope: true,
			template: require("./DialogTemplate.html")
		});
	}

	/**
	 * Закрываем модальное окно
	 * */
	function modalClose() {
		$mdDialog.hide();
	}
}