import template from "./ezd-time-date-picker.template.html";
import {Component} from "app/decorators/index";
import styles from "./ezd-time-date-picker.local.scss";

import moment from "tools/moment.local";

@Component({
	selector: "ezdTimeDatePicker",
	template,
	bindings: {
		placeholder: "<",
		modelTime: "<",
		modelDate: "<",
		minDate: "<",
		maxDate: "<",
		minTime: "<",
		maxTime: "<",
		onChange: "&",
		required: "<",
		name: "@"
	}
})
export class EzdTimeDatePickerComponent {
	static $inject = ["$scope", "$$academicYear"];

	styles = styles;

	/**
	 * */
	constructor($scope, $$academicYear) {
		this.model = {
			time: this.modelTime,
			date: this.modelDate
		};
	}

	/**
	 * */
	$onChanges(changes) {
		if (changes.modelDate) {
			this.model.date = this.modelDate;
		}
	}

	/**
	 * */
	$onChangeTime(model) {
		this.modelTime = moment(model, "HH:mm:ss");

		this.$onChange();
	}

	/**
	 * */
	$onChangeDate() {
		const date = moment(this.model.date);
		this.modelDate = date.isValid() ? date : null;
		this.$onChange();
	}

	/**
	 * */
	$onChange() {
		const time = this.modelTime ? moment(this.modelTime, "HH:mm:ss") : null;
		const date = this.modelDate ? moment(this.modelDate) : null;
		this.onChange({
			data: {
				time,
				date
			}
		});
	}
}

angular
	.module("ezd.common.ui")
	.component(EzdTimeDatePickerComponent.selector, EzdTimeDatePickerComponent);
