/**
 * Декоратор для сервиса
 * @param name
 * @returns {Function}
 * @constructor
 */
export function Service(name) {
	return function (target) {
		const res = {name, controller: target};

		target.$type = "service";

		return res;
	};
}
