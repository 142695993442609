

angular
	.module("ezd.backend")
	.factory("RegistryStudents", RegistryStudents);


/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
RegistryStudents.$inject = ["Core"];
function RegistryStudents(Core) {
	return Core.service("/api/registry_students");
}
