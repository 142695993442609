import {localforage} from "main/localforage";


angular
	.module("ezd.backend")
	.service("$$calendarLesson", calendarLessonService);

/**
 *
 * @param Jersey
 * @param $q
 */
calendarLessonService.$inject = ["Jersey", "$q"];
function calendarLessonService(Jersey, $q) {
	const url = "/api/calendar_lessons";
	const vm = this;

	vm.list = [];
	vm.collection = Jersey.all(url);

	vm.bindOne = bindOne;
	vm.getList = getList;

	/**
	 * @deprecated
	 *
	 */
	vm.getCalendarLessonWithControlWorkAnalysis = getCalendarLessonWithControlWorkAnalysis;


	/**
	 *
	 * @param id
	 * @returns {*|{a}|{get}}
	 */
	function bindOne(id) {
		return Jersey.one(url, id);
	}


	/**
	 *
	 */
	function getList() {
		return vm.collection.getList();
	}


	/**
	 *
	 * @param id
	 * @param students
	 * @param cis
	 * @returns {*|Promise.<TResult>|{catch}}
	 */
	function getCalendarLessonWithControlWorkAnalysis(id, students, cis) {
		return $q.all(
			{
				calendarLesson: vm.collection.getList({ids: id}),
				students: localforage.getItem("cwAnalysis")
			})
			.then((data) => {
				data.calendarLesson.students = _.map(students, (student) => {
					student.controllable_items = _.map(_.cloneDeep(cis), (ci) => {
						const storedStudent = _.find(data.students, {id: student.id});
						ci.result = Boolean(_.get(_.find(_.get(storedStudent, "controllable_items"), {id: ci.id}), "result"));

						return ci;
					});

					return student;
				});

				return data;
			});
	}
}

