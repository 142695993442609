angular
	.module("ezd.backend")
	.factory("$$attachments", attachments);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
attachments.$inject = ["Core"];
function attachments(Core) {
	const url = "/api/attachments";
	const Service = Core.service(url);

	Service.bindOne = (id) => Core.one(url, id);
	Service.getList = (query) => Core.all(url).getList(query);

	return Service;
}

