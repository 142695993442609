

angular
	.module("ezd.backend")
	.service("$$lessonComment", $$lessonComment);


/**
 *
 * @param Core
 */
$$lessonComment.$inject = ["Core"];
function $$lessonComment(Core) {
	const url = "/api/lesson_comments";
	const vm = this;


	vm.list = [];
	vm.collection = Core.all(url);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);
}

