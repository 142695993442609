import {Component} from "app/decorators/index";
import styles from "./ezd-select.local.scss";

import selectTemplate from "./ezd-select.html";
import {selectArrow} from "images/icons/index";

import _guid from "tools/fp/guid";


@Component({
	selector: "ezdSelect",
	bindings: {
		required: "<",
		collection: "<",
		idField: "@",
		nameField: "@",
		showField: "@",
		search: "<",
		model: "=",
		onChange: "&",
		nullText: "@",
		placeholder: "@",
		width: "@",
		customStyle: "<",
		disabledText: "@",
		disabled: "<",
		type: "@",
		name: "@",
		onBlur: "&"
	},
	controllerAs: "ezdSelectCtrl"
})
export class EzdSelectComponent {
	static $inject = ["$scope", "$timeout", "$element"];
	styles = styles;
	icons = {selectArrow};
	selected = {};
	showOptionsList = false;
	selectId = _guid();


	/**
	 *
	 * @param tElem
	 */
	static template(tElem) {
		const itemTemplate = tElem.find("ezd-item-template").html() || "{{::item[ezdSelectCtrl.nameField]}}";

		// if (tAttrs.type === "line") {
		// 	tElem[0].querySelector("[header]").remove();
		// } else {
		// 	tElem[0].querySelector("[header-line]").remove();
		// }

		return selectTemplate.replace("<item-placeholder></item-placeholder>", itemTemplate);
	}

	/**
	 *
	 * @param $scope
	 * @param $timeout
	 * @param $element
	 */
	constructor($scope, $timeout, $element) {
		this.services = {$scope, $timeout, $element};
	}


	/**
	 *
	 * @returns {string}
	 */
	getHeaderClasses() {
		return _.compact([
			this.styles["ezd-select-header"],
			this.type === "line" && this.styles["ezd-select-header__line"]
		])
			.join(" ");
	}


	/**
	 *
	 */
	$onInit() {
		const {$scope} = this.services;
		this.idField = this.idField || "id";
		this.nameField = this.nameField || "name";
		this.placeholder = this.placeholder || "Выбрать";

		this.customStyle = this.customStyle;

		this.watcherModel = $scope.$watch(() => this.model, this.updateModel.bind(this));
	}

	/**
	 *
	 */
	$postLink() {
		const {$element} = this.services;

		if (this.width) {
			$element.css({width: this.width});
		} else {
			this.width = ($element[0].clientWidth || 200) + "px";
		}

		this.updateWidth();
	}

	/**
	 * смотрим на ширину хедера, чтобы подстроить минимальную ширину списка
	 */
	updateWidth() {
		const {$timeout} = this.services;

		$timeout(() => {
			const headerElement = document.getElementById(this.selectId);

			this.headerWidth = headerElement ? document.getElementById(this.selectId).clientWidth + "px" : "95%";
		}, 0);
	}


	/**
	 *
	 * @param newVal
	 */
	updateModel(newVal) {
		// console.log(newVal);
		if (_.isNull(newVal)) {
			this.selected = null;
		} else {
			this.selected = _.find(this.collection, {[this.idField]: newVal});
		}
	}


	/**
	 *
	 * @param changes
	 */
	$onChanges(changes) {
		// console.log("changes from ezd-select :", changes);
		if (changes.collection && this.model) {
			this.selected = _.find(this.collection, {[this.idField]: this.model}) || {};
		}

		if (changes.disabled) {
			this.updateWidth();
		}
	}


	/**
	 *
	 */
	$onDestroy() {
		this.watcherModel();
	}

	/**
	 * Закрытие выпадающего списка без выбора пунта
	 */
	optionsListClosed() {
		this.onBlur({$event: {name: this.name}});
	}

	/**
	 *
	 */
	toggleOptionsList() {
		this.showOptionsList = !this.showOptionsList;
	}


	/**
	 *
	 */
	closeOptionsList() {
		this.showOptionsList = false;
	}

	/**
	 *
	 */
	onSearchKeydown($event) {
		$event.stopPropagation();
	}

	/**
	 *
	 */
	searchByNameField() {
		const object = {};
		object[this.nameField] = this.searchText;

		return object;
	}

	/**
	 *
	 * @param item
	 */
	selectItem(item) {
		const {$timeout} = this.services;
		this.showOptionsList = false;

		if (this.selected === item) {
			return;
		}

		this.selected = item;
		this.model = item ? this.selected[this.idField] : null;

		//	TODO: скользкая дорожка
		$timeout(this.onChange.bind(null, {$event: this.model}), 0);
		// this.onChange({$event: this.model});
	}
}


angular
	.module("ezd.common.ui")
	.component(EzdSelectComponent.selector, EzdSelectComponent);
