import {Component} from "app/decorators/index";

import template from "./ezd-radio-buttons-group.html";
import styles from "./ezd-radio-buttons-group.local.scss";

import forEach from "lodash/fp/forEach";
import find from "lodash/fp/find";
import getOr from "lodash/fp/getOr";
import filter from "lodash/fp/filter";
import flow from "lodash/fp/flow";
import isEqual from "lodash/fp/isEqual";
import size from "lodash/fp/size";

@Component({
	selector: "ezdRadioButtonsGroup",
	template,
	bindings: {
		collection: "<",
		model: "=",
		disabled: "<",
		titleField: "@",
		valueField: "@",
		onChange: "&",
		withButtons: "<", // изменить внешний вид на кнопки
		required: "<",
		name: "@",
		nullText: "@",
		multiple: "<", // доступен ли множественный выбор
		enableAll: "<" // доступен ли пункт "Все" при multiple=true
	}
})
export class EzdRadioButtonsGroupComponent {
	static $inject = ["$timeout", "$scope"];

	/**
	 *
	 */
	constructor($timeout, $scope) {
		this.services = {$timeout, $scope};
		this.styles = styles;
	}


	/**
	 *
	 */
	$onInit() {
		const {$scope} = this.services;
		this.titleField = this.titleField || "name";
		this.valueField = this.valueField || "id";

		this.groupName = this.$generateRandomString();

		this.$mapCollection();

		if (this.withButtons) {
			this.$updateActiveButton();
			$scope.$watch(() => this.model, () => {
				this.$updateActiveButton();
				this.$updateSelection();
			});
		}

		this.$setNullTextItem();
	}

	/**
	 *
	 * @param changes
	 */
	$onChanges(changes) {
		if (changes.collection) {
			this.$mapCollection();
			this.$updateActiveButton();
			this.$updateSelection();
			this.$setNullTextItem();
		}

		/* if (changes.model) {
			console.log(changes.model);
			this.$updateActiveButton();
			this.$updateSelection();
		}*/
	}

	/**
	 * */
	$setNullTextItem() {
		if (this.nullText) {
			const item = {
				[this.titleField]: this.nullText,
				[this.valueField]: null
			};
			this.collection.unshift({
				...item,
				inputId: this.$generateRandomString() + "yeah" + item[this.valueField]
			});
		}
	}

	/**
	 *
	 */
	$mapCollection() {
		this.collection = _.map(this.collection, (item) => {
			const inputId = this.$generateRandomString() + "yeah" + item[this.valueField];

			return {
				...item,
				inputId
			};
		});
	}


	/**
	 *
	 * @returns {string}
	 */
	$generateRandomString() {
		return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
	}


	/**
	 * Callback на изменении модели при multiple=false
	 */
	onChangeSingle(item) {
		const {$timeout} = this.services;

		this.$setActive(item);
		$timeout(() => {
			this.onChange({$event: this.model});
		}, 0);
	}

	/* ### для withButtons=true && multiple=false ### */

	/**
	 * */
	$updateActiveButton() {
		if (!this.withButtons && !this.multiple) {
			return;
		}

		const activeItem = find((item) => {
			return getOr(null, this.valueField, item) === this.model;
		})(this.collection);

		this.$setActive(activeItem);
	}

	/**
	 * */
	$setActive(selected) {
		if (!selected) {
			return;
		}

		forEach((item) => {
			item.$isActive = false;
		})(this.collection);

		selected.$isActive = true;
	}


	/* ### для multiple=true ### */

	/**
	 * */
	$updateSelection() {
		if (!this.multiple) {
			return;
		}

		forEach((item) => {
			item.$isActive = Boolean(find({[this.valueField]: item[this.valueField]})(this.model));
		})(this.collection);
	}

	/**
	 * */
	toggleSelection(item) {
		item.$isActive = !item.$isActive;

		// если указан флаг enableAll проверяем не выбраны ли все элементы
		if (this.enableAll && this.collection.length > 1) {
			this.isSelectedAll = flow(
				filter({$isActive: true}),
				size,
				isEqual(this.collection.length)
			)(this.collection);
		}

		this.$onChangeMultiple();
	}

	/**
	 * */
	toggleAll() {
		this.isSelectedAll = !this.isSelectedAll;
		forEach((item) => {
			item.$isActive = this.isSelectedAll;
		})(this.collection);
		this.$onChangeMultiple();
	}

	/**
	 * Callback на изменении выделенных элементов при multiple=true
	 */
	$onChangeMultiple() {
		const selectedItems = filter({$isActive: true})(this.collection);
		this.onChange({$event: selectedItems});
	}
}
