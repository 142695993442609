/**
 * Тема тематического плана
 */
class ECCourseLessonTopic {
	/**
	 * Возвращает шаблон темы тематического плана
	 */
	getTemplate() {
		return {
			name: "",
			is_test_planned: false, // флаг, определяющий запланирована ли промежуточная аттестация для этой темы
			ordinal: 1, // порядок темы в модуле
			lessons: [] // массив ECLessonPlanLesson
		};
	}
}


angular
	.module("ezd.backend")
	.service("ECCourseLessonTopic", ECCourseLessonTopic);
