

angular
	.module("ezd.backend")
	.factory("$$studyProfile", studyProfileFactory);


/**
 *
 * @param LMSv2
 * @returns {Object|*}
 */
studyProfileFactory.$inject = ["LMSv2"];
function studyProfileFactory(LMSv2) {
	const s = LMSv2.service("/api/study_profiles");

	s.collection = {
		getList: s.getList
	};


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	s.bindOne = (id) => s.one(null, id);

	return s;
}