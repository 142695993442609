angular
	.module("ezd.backend")
	.factory("AEFinalMarks", aeFinalMarks);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeFinalMarks.$inject = ["Core"];
function aeFinalMarks(Core) {
	return Core.service("/api/ae_final_marks");
}

