const URL = "/api/pilot_schools";

export class PilotSchools {
	static $inject = ["Jersey"];

	/**
	 * Constructor
	 */
	constructor(Jersey) {
		this.JerseyService = Jersey;
		this.getPilotSchoolsIdsList();
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	bindOne = (id) => this.JerseyService.one(URL, id);

	/**
	 *
	 * @returns {*}
	 */
	getPilotSchoolsIdsList() {
		return this.JerseyService.all(URL).getList().then((data) => {
			this.pilotIds = data;
		});
	}

	/**
	 *
	 * @param profile
	 * @returns {boolean}
	 */
	checkAccess({profile}) {
		return _.intersects(profile.roles, ["school_admin"]) && this.pilotIds.includes(profile.school_id);
	}

	/**
	 *
	 * @returns {Promise<*>}
	 */
	async isPilotSchool(schoolId) {
		if (!this.pilotIds) {
			return this.getPilotSchoolsIdsList().then(() => {
				return this.pilotIds.includes(schoolId);
			});
		}

		return this.pilotIds.includes(schoolId);
	}


	/**
	 *
	 * @returns {Promise<*>}
	 */
	async getWeeksList() {
		return this.JerseyService.all(URL + "/weeks").getList();
	}

	/**
	 *
	 * @returns {Promise<*>}
	 */
	async getPilotSchoolList(query) {
		return this.JerseyService.all(URL + "/list").getList(query);
	}
}

angular
	.module("ezd.backend")
	.service("PilotSchools", PilotSchools);
