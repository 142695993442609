angular
	.module("ezd.backend")
	.service("$$user", userService);


userService.$inject = ["ACL", "Core", "$cookies", "$$profile", "$q"];
/**
 *
 * @param ACL
 * @param Core
 * @param $cookies
 * @param $$profile
 * @param $q
 */
function userService(ACL, Core, $cookies, $$profile, $q) {
	const url = "/api/users";
	const vm = this;

	vm.list = [];
	vm.data = {};
	// vm.collection = ($cookies.get("admin_pid")) ? getAdminProvider().all(url) : ACL.all(url);
	vm.collection = ACL.all(url);

	vm.relations = Core.service("/api/user_relationships");

	// методы для загрузки данных с сервера
	vm.bindOne = bindOne;
	vm.getList = getList;
	vm.loadCurrent = loadCurrent;

	// методы для загрузки данных с сервера
	vm.newUser = newUser;

	// методы для работы с данными в сервисе
	vm.setData = setData;
	vm.drop = drop;

	// методы для работы с данными в local storage
	vm.setCurrentUser = setCurrentUser;
	vm.getCurrentUser = getCurrentUser;
	vm.getFullUsername = getFullUsername;
	vm.isValidUserId = isValidUserId;

	vm.setHideParent = setHideParent;

	vm.makeUserProfileInfo = makeUserProfileInfo;

	vm.isSupport = isSupport;

	/**
	 * Метод для изменения свойства is_hidden у родителя
	 * @param id
	 * @param isHidden
	 */
	function setHideParent(id, isHidden) {
		return Core.service("/api/hide_parent_users").one(id).customPUT({is_hidden: isHidden});
	}

	// /**
	//  *
	//  * @returns {*}
	//  */
	// function getAdminProvider() {
	// 	return ACL.withConfig((RestangularConfigurer) => {
	// 		const headers = angular.copy(RestangularConfigurer.defaultHeaders);
	//
	// 		headers["Profile-Id"] = $cookies.get("admin_pid");
	// 		RestangularConfigurer.setDefaultHeaders(headers);
	// 	});
	// }

	/**
	 * Получение пользователя по его id
	 * @param id {*}
	 * @returns {*}
	 */
	function bindOne(id) {
		return ACL.one(url, id);
	}

	/**
	 * Получение списка пользователей
	 * @param params {{}} объект с параметрами запроса {a:1, b:2 ...}
	 * @returns {*}
	 */
	function getList(params) {
		return vm.collection.getList(params);
	}

	/**
	 * @deprecated ?? Загружает с сервера текущего пользователя
	 */
	function loadCurrent() {
		return bindOne($cookies.get("user_id"))
			.get()
			.then(setCurrentUser);
	}

	/**
	 * Создаёт
	 */
	function newUser(user) {
		// return (($cookies.get("admin_pid")) ? getAdminProvider() : ACL).all(url + "/secure").post(user);
		return ACL.all(url + "/secure").post(user);
	}

	/**
	 * Сохраняет в сервис объект текущего пользователя
	 * @param data
	 */
	function setData(data) {
		// var adminProfile

		vm.data = data;

		// // save admin_pid for impersonation
		// adminProfile = _.find(data.profiles, profile => {
		// 	$$profile.castTypesToRolesForProfile(profile)
		// 	return _.intersection(profile.roles, ['admin', 'school_admin']).length > 0
		// })
		//
		// if (!!adminProfile) {
		// 	$cookies.put('admin_pid', adminProfile.id)
		// }
	}

	/**
	 * Обнуляет в сервисе объект текущего пользователя
	 */
	function drop() {
		vm.data = {};
	}

	/**
	 * Сохраняет в local storage объект текущего пользователя
	 * @param userData
	 * @returns {*}
	 */
	function setCurrentUser(userData) {
		vm.data = userData;

		return vm.makeUserProfileInfo()
			.then((profiles) => {
				return $$profile.setDefaultProfile(profiles);
			});
	}

	/**
	 * Возвращает promise с текущим пользователем из local storage
	 * @returns {*}
	 */
	function getCurrentUser() {
		const deferred = $q.defer();

		if (_.isEmpty(vm.data)) {
			const t = setInterval(() => {
				if (!_.isEmpty(vm.data)) {
					clearInterval(t);
					deferred.resolve(vm.data);
				}
			}, 200);
		} else {
			deferred.resolve(vm.data);
		}

		return deferred.promise;
	}


	/**
	 *
	 * @returns {boolean}
	 */
	function isValidUserId() {
		if (_.isEmpty(vm.data)) {
			return true;
		}

		if ($cookies.get("uid") && !$cookies.get("user_id")) {
			$cookies.put("user_id", $cookies.uid);
			$cookies.remove("uid");
		}

		return vm.data.id === _.int($cookies.get("user_id"));
	}


	/**
	 *
	 * @param user
	 * @returns {string}
	 */
	function getFullUsername(user) {
		return (!user)
			? ""
			: _.compact([
				user.last_name,
				user.first_name,
				user.middle_name
			]).join(" ");
	}


	/**
	 * загрузка привязанных к профилям пользователей
	 */
	function makeUserProfileInfo() {
		let currentUser;

		return vm.getCurrentUser()
			.then((user) => {
				// собираем id пользователей для получения информации о пользователях
				currentUser = user;
				$cookies.put("user_id", currentUser.id);
				const usersIds = _.chain($$profile.list.length ? $$profile.list : currentUser.profiles)
				// .reject({user_id: user.id})
					.map("user_id")
					.compact()
					.value();

				return (usersIds.length > 0) ? vm.collection.getList({ids: usersIds.join(",")}) : [];
			})
			.then((users) => {
				const $users = [...users, currentUser];
				const profileList = $$profile.list.length ? $$profile.list : currentUser.profiles;
				_.forEach(profileList, (profile) => {
					profile.user = _.find($users, {id: profile.user_id});
					profile.children_profile_ids = _.chain(profile.user.profiles).find({id: profile.id}).get("children_profile_ids").value();
					profile.user_name = vm.getFullUsername(profile.user);
					delete profile.user.profiles;

					if (profile.user_id !== currentUser.id) {
						profile.is_impersonated = true;
					}
				});

				return profileList;
			});
	}

	/**
	 * Проверка вошёл ли пользователь с правами поддержки
	 * @param profile
	 */
	function isSupport() {
		return $cookies.get("is_support");
	}
}

