

angular
	.module("ezd.backend")
	.service("$$reportAllMarks", Service);

/**
 *
 * @param REP
 * @constructor
 */
Service.$inject = ["REP"];
function Service(REP) {
	const url = "api/progress";
	const $ = this;

	$.getJson = getJson;

	/**
	 * Загрузка данных для отчета в json
	 * @param params {{}}
	 * {ACADEMIC_YEAR_ID} - учебный год
	 * {STUDENT_PROFILE_ID} - учебный год
	 * {PERIOD_ID} - id учебного периода
	 * {ATTESTATION_PERIOD_ID} - id аттестационного периода
	 * {MODULE_ID} - id модуля
	 * {IS_EXAM} - оценка за контрольную работу? true/false
	 * {MARK_TYPE} - тип оценки за период (
	 *      current - за текущий учебный период
	 *      half_year_first - за первое полугодие
	 *      half_year_last- за второе полугодие
	 * )
	 * @returns {*}
	 */
	function getJson(params) {
		return REP.one(url, "json").get(params);
	}
}
