/**
 * Сервис для получения информации о днях, когда у ученика есть пропуски/уведомления о пропусках.
 * Возвращает массив объектов, содержащих дату, id студента, список ids всех уроков в этот день.
 */
class AttendanceDaysMetaData {
	static $inject = ["Core"];
	static URL = "/api/attendance_days_meta_data";

	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(AttendanceDaysMetaData.URL);
	}
}

angular
	.module("ezd.backend")
	.service("AttendanceDaysMetaData", AttendanceDaysMetaData);
