

angular
	.module("ezd.backend")
	.service("$$lessonReplacement", $$lessonReplacement);


/**
 *
 * @param Jersey
 * @param $$teacherProfiles
 */
$$lessonReplacement.$inject = ["Jersey", "$$teacherProfiles"];
function $$lessonReplacement(Jersey, $$teacherProfiles) {
	const url = "/api/lesson_replacements";
	const vm = this;

	vm.list = [];
	vm.collection = Jersey.all(url);
	vm.collection.getList = (query) => Jersey.all(url).getList(query);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Jersey.one(url, id);

	/**
	 *
	 * @param surname
	 * @returns {Array}
	 */
	vm.getAutocompleteTeachers = (surname) => {
		const teachers = [];
		if (surname) {
			$$teacherProfiles.collection.getList({starts_with: surname,
				buildings: false,
				rooms: false,
				subjects: false})
				.then((data) => {
					_.forEach(data, (teacher) => {
						teachers.push({
							id: teacher.id,
							fio: $$teacherProfiles.getShortNameForTeacher(teacher),
							fullName: teacher.name
						});
					});
				});
		}

		return teachers;
	};
}

