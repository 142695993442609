import {Service} from "app/decorators/service";
import moment from "tools/moment.local";
import _filter from "lodash/fp/filter";
import _flow from "lodash/fp/flow";
import _forEach from "tools/fp/forEach";
import _isArray from "lodash/fp/isArray";
import _isString from "lodash/fp/isString";
import _forOwn from "lodash/fp/forOwn";
import _findIndex from "lodash/fp/findIndex";
import _isEqual from "lodash/fp/isEqual";
import _remove from "lodash/fp/remove";
import _union from "lodash/fp/union";
import _reject from "lodash/fp/reject";
import _compact from "lodash/fp/compact";
import _map from "lodash/fp/map";
import _flatten from "lodash/fp/flatten";
import _clone from "lodash/fp/clone";
import _getOr from "lodash/fp/getOr";
import _intersection from "lodash/fp/intersection";
import _merge from "lodash/fp/merge";
import _find from "lodash/fp/find";
import _includes from "lodash/fp/includes";

@Service("$$event")
export default class EventService {
	static $inject = ["$rootScope", "$q", "ORG", "$$user", "$$profile", "$$studentProfiles",
		"$$attachments", "$$testLessons", "$$academicYear", "bigQuery", "EVENTS_TYPES"];
	getGlobalString = getPageString.bind(undefined, '$global');

	url = "api/events";

	newEventSkeleton = {
		type: null,
		title: "",
		description: "",
		address: "",
		date: null,
		date_end: null,
		regularity_step: null,
		regularity_type: null,
		link_id: null,
		school_id: null,
		user_id: null,
		interval_end: null,
		interval_start: null,
		priority: 100,
		regularity_weekday: [],
		attachment_ids: [],
		building_id: null,
		room_id: null,
		place_comment: null,
		comment: null,
		external_link: null,
		ae_program_ids: [],
		class_level_ids: [],
		class_unit_ids: [],
		ec_field_ids: [],
		ec_form_ids: [],
		ec_activity_ids: [],
		education_level_ids: [],
		group_ids: [],
		role: null,
		city_building_id: null,
		association_ids: [],
		subject_ids: []
	};

	data = {
		private: {
			regular: [],
			irregular: []
		},
		public: {
			regular: [],
			irregular: []
		},
		links: [],
		typesToShow: [200, 300, 400, 500]
	};

	regularityTypes = {
		D: "day",
		W: "week",
		M: "month",
		Y: "year",
		E: "day"
	};

	eventDateFormat = "YYYY-MM-DD";
	// eventTimeFormat = "HH:mm:ss";

	attachments = [];
	selectedDay = moment().startOf("day");
	selectedMonth = moment().startOf("day");

	testLessons = {}; // мапа контрольных по месяцам, ключ - дата начала месяца DD.MM.YYYY

	constructor($rootScope, $q, ORG, $$user, $$profile, $$studentProfiles, $$attachments, $$testLessons, $$academicYear, bigQuery, EVENTS_TYPES) {
		this.services = {
			$rootScope,
			$q,
			ORG,
			$$user,
			$$profile,
			$$studentProfiles,
			$$attachments,
			$$testLessons,
			$$academicYear,
			bigQuery,
			EVENTS_TYPES
		};
		this.collection = ORG.all(this.url);
		this.eventTypes = [
			{title: this.getGlobalString("extracurricular_activities"), type: EVENTS_TYPES.EC},
			{title: "Дополнительное образование", type: EVENTS_TYPES.AE},
			{title: "Для сотрудников", type: EVENTS_TYPES.STAFF},
			{title: "Для родителей", type: EVENTS_TYPES.PARENT}
		];
		// this.collection.getList = (query) => ORG.all(this.url).getList(query);
	}


	/**
	 *
	 * @param id
	 */
	bindOne(id) {
		const {ORG} = this.services;

		return ORG.one(this.url, id);
	}


	/**
	 *
	 * @param schoolId
	 */
	async getLoopEvents(schoolId) {
		const {bigQuery} = this.services;
		try {
			if (this.loopEvents) {
				this.loopEvents = await this.makeLoopEventsClones(this.loopEvents);
			}

			const request = {
				loop: true,
				year: this.selectedMonth.year()
			};

			if (schoolId) {
				request.school_id = schoolId;
			}

			const res = await bigQuery.queue.getList(this.collection, request, 50);
			this.loopEvents = await this.makeLoopEventsClones(res);

			return this.loopEvents;
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Создание копий для повторяющихся с соответствующими датами
	 * для отображения в календаре
	 */
	async makeLoopEventsClones(originEvents) {
		// const {$$academicYear} = this.services;
		const loopClones = [];
		try {
			// const currentYear = await $$academicYear.getSelected();
			// const yearEnd = moment(currentYear.end_date, "YYYY-MM-DD");
			// console.time("LOOP CLONING");
			_flow(
				_filter((event) => event.regularity_type && (event.regularity_step > 1 || event.regularity_type !== "D")),
				_forEach((event) => {
					const repeatType = this.regularityTypes[event.regularity_type];
					const repeatStep = event.regularity_step || 1;

					event.$isRange = _isString(event.date) && _isString(event.date_end) && !_isEqual(event.date, event.date_end);
					event.$dateRange = moment.range(
						moment(event.date, "YYYY-MM-DD"),
						moment(event.date_end, "YYYY-MM-DD")
					);
					event.$duration = Math.abs(event.$dateRange.diff("days"));

					let checkedDay = moment(event.$dateRange.end).add(repeatStep, repeatType);

					while (moment(checkedDay).add(event.$duration, "days").isSameOrBefore(this.selectedMonth)) {
						// клонируем событие
						const eventClone = _clone(event);
						eventClone.$isRegularityClone = true;

						// устанавливаем клону соответствующие даты
						eventClone.date = checkedDay.format("YYYY-MM-DD");
						if (event.$isRange) {
							eventClone.$dateRange.start = checkedDay;
							eventClone.$dateRange.end = moment(checkedDay).add(event.$duration, "days");

							eventClone.date_end = eventClone.$dateRange.end.format("YYYY-MM-DD");
						} else {
							eventClone.date_end = eventClone.date;
						}

						// добавляем событие в список событий месяца
						loopClones.push(eventClone);

						// смещаем проверяемый повтор на regularity_step
						checkedDay = moment(event.$isRange ? eventClone.$dateRange.end : checkedDay).add(repeatStep, repeatType);
					}
				})
			)(originEvents);
			// console.timeEnd("LOOP CLONING");
			// console.log("LOOP CLONES", loopClones);
		} catch (er) {
			console.error(er);
		}

		return this.$makeRangeClones(originEvents.concat(loopClones));
	}

	/**
	 *
	 * @param schoolId
	 */
	getMonthEvents(schoolId) {
		const request = {
			month: this.selectedMonth.month() + 1,
			year: this.selectedMonth.year()
		};

		if (schoolId) {
			request.school_id = schoolId;
		}

		return this.collection.getList(request);
	}

	/**
	 *
	 */
	getPersonalEvents() {
		const request = {
			month: this.selectedMonth.month() + 1,
			year: this.selectedMonth.year(),
			only_personal_user_events: true
		};

		return this.collection.getList(request);
	}


	/**
	 * Загрузка событий органайзера для пользователя
	 */
	async loadEvents() {
		const {$$profile, $$academicYear, $$studentProfiles, $rootScope, $q} = this.services;
		try {
			const profile = await $$profile.getCurrentProfile();
			this.selectedYear = await $$academicYear.getSelected();

			let schoolId;

			// если это родитель, то определяем школу выбранного ученика, и загружаем события для этой школы
			if (_includes("parent")(profile.roles)) {
				const student = await $$studentProfiles.getCurrentProfile();
				schoolId = student.school_id;
			} else {
				schoolId = profile.school_id;
			}

			const {loopEvents, monthEvents, personalEvents} = await $q.all({
				loopEvents: this.getLoopEvents(schoolId),
				monthEvents: this.getMonthEvents(schoolId),
				personalEvents: this.getPersonalEvents(),
				testLessons: (profile.type !== "observer"
					&& profile.type !== "curator"
					&& profile.type !== "admin"
					&& !(profile.right_ids && profile.right_ids.indexOf(6) > -1)) ? this.getTestLessonsByMonth() : null
			});
			_.forEach(personalEvents, (personalEvent) => {
				personalEvent.type = null;
				monthEvents.push(personalEvent);
			});
			// loop events
			this.setLoopEvents(loopEvents);
			// month events
			this.setMonthEvents(monthEvents);
			// links to events
			this.prepareEventsLinks();
			//
			$rootScope.$emit("organizer:EventListChanged");

			return await this.loadEventAttachments(loopEvents, monthEvents);
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Загрузка событий на весь год
	 */
	async loadEventsByYear(params, longIdsParam) {
		const {$$academicYear, bigQuery, $$profile, $$studentProfiles} = this.services;
		try {
			this.selectedYear = await $$academicYear.getSelected();
			const roleParams = await this.$getParamsByRole(params);
			let schoolId;

			const profile = await $$profile.getCurrentProfile();
			this.selectedYear = await $$academicYear.getSelected();

			// если это родитель, то определяем школу выбранного ученика, и загружаем события для этой школы
			if (_includes("parent")(profile.roles)) {
				const student = await $$studentProfiles.getCurrentProfile();
				schoolId = student.school_id;
			} else {
				schoolId = profile.school_id;
			}

			let events;
			const allParams = {
				year: this.selectedYear.id,
				school_id: schoolId,
				..._merge(params)(roleParams)
			};

			if (longIdsParam) {
				events = await bigQuery.getList(this.collection, allParams, longIdsParam, 200);
			} else {
				events = await bigQuery.queue.getList(this.collection, allParams, 200);
			}

			await this.loadEventAttachments(events);

			return events;
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 *
	 * @returns {Promise<{class_unit_ids: *}>}
	 */
	async $getParamsByRole(params) {
		try {
			if (params && params.event_type === 600) {
				return {};
			}

			const {$$studentProfiles, $$profile} = this.services;
			const profile = await $$profile.getCurrentProfile();
			let classUnitsIds;

			if (_includes("parent")(profile.roles)) {
				// const children = await $$studentProfiles.getStudentsListForParent();
				const student = await $$studentProfiles.getCurrentProfile();
				classUnitsIds = student.class_unit.id;
				// classUnitsIds = _map((child) => child.class_unit.id)(children).join(",");
				// schoolIds = _map("school_id")(children).join(",");
				//  schoolId = student.school_id;
			}

			if (_includes("student")(profile.roles)) {
				const classUnit = await $$studentProfiles.getClassUnitForStudent(profile);
				classUnitsIds = classUnit.id;
				// schoolIds = profile.school_id;
			}

			return {
				class_unit_ids: classUnitsIds
				// school_id: schoolIds
			};
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Получение контрольных работ на весь год
	 * */
	async getTestLessonsByYear(params) {
		const {$$testLessons, $$academicYear, $$profile, $$studentProfiles} = this.services;
		const currentProfile = await $$profile.getCurrentProfile();
		const selectedYear = await $$academicYear.getSelected();

		if ((_intersection(["curator", "admin"])(currentProfile.roles).length)) {
			return [];
		}

		try {
			const requestParams = {
				academic_year_id: selectedYear.id,
				from: _getOr(null, "begin_date", params),
				to: _getOr(null, "end_date", params)
			};

			if (_includes("parent", currentProfile.roles)) {
				const student = await $$studentProfiles.getCurrentProfile();
				requestParams.student_id = student.id;
			}

			const testLessons = await $$testLessons.getList(requestParams);

			return _flow([
				_filter((test) => {
					return (test.test.test_status === "APPROVED") && _isArray(test.date);
				}),
				_forEach((test) => {
					const date = moment([test.date[0], test.date[1] - 1, test.date[2], test.begin_time[0], test.begin_time[1]]);

					test.$date = date;
					test.date = date.format("YYYY-MM-DD");
					test.interval_start = date.format("HH:mm");
					test.interval_end = date.add(test.duration, "minutes").format("HH:mm");
				})
			])(testLessons);
		} catch (e) {
			console.error(e);

			return [];
		}
	}

	/**
	 * события по страницам
	 * @param num
	 * @param filterData
	 * @param longIdsParam
	 */
	async getPage(num, filterData, longIdsParam) {
		const {ORG, $$academicYear, bigQuery, $$profile, $$studentProfiles} = this.services;
		this.selectedYear = await $$academicYear.getSelected();
		const roleParams = await this.$getParamsByRole();
		const result = {
			data: [],
			pages: 1
		};

		const rest = ORG.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true));

		const profile = await $$profile.getCurrentProfile();
		let schoolId;
		// если это родитель, то определяем школу выбранного ученика, и загружаем события для этой школы
		if (_includes("parent")(profile.roles)) {
			const student = await $$studentProfiles.getCurrentProfile();
			schoolId = student.school_id;
		} else {
			schoolId = profile.school_id;
		}

		let params = {
			page: num,
			per_page: 50,
			year: this.selectedYear.id,
			school_id: schoolId
		};

		if (_.keys(filterData).length !== 0) {
			params = angular.extend(params, filterData, roleParams);
		}

		let response;
		if (longIdsParam) {
			response = await bigQuery.getList(this.collection, params, longIdsParam, 20);
		} else {
			response = await rest.all(this.url).getList(params);
		}

		result.data = response.data;
		result.pages = response.headers("pages") || 1; // X-Pagination-Total-Pages
		result.total_entries = response.headers("X-Pagination-Total-Entries") || 0;

		return result;
	}

	/**
	 *
	 * @returns {Promise<Array>}
	 */
	async getTestLessonsByMonth() {
		const {$$testLessons, $$studentProfiles, $$profile} = this.services;
		try {
			const key = this.selectedMonth.startOf("month").format("DD.MM.YYYY");

			if (this.testLessons[key]) {
				return this.testLessons;
			}

			const profile = await $$profile.getCurrentProfile();
			let studentId;
			// если это родитель, то определяем школу выбранного ученика, и загружаем события для этой школы
			if (_includes("parent")(profile.roles)) {
				const student = await $$studentProfiles.getCurrentProfile();
				studentId = student.id;
			}


			this.testLessons[key] = _flow([
				_filter((test) => {
					return test.test.test_status === "APPROVED";
				}),
				_forEach((test) => {
					const date = moment([test.date[0], test.date[1] - 1, test.date[2], test.begin_time[0], test.begin_time[1]]);

					test.date = date.format("YYYY-MM-DD");
					test.interval_start = date.format("HH:mm");
					test.interval_end = date.add(test.duration, "minutes").format("HH:mm");
				})
			])(await $$testLessons.getList({
				student_id: studentId,
				academic_year_id: this.selectedYear.id,
				from: moment(this.selectedMonth.startOf("month")).format("YYYY-MM-DD"),
				to: moment(this.selectedMonth.endOf("month")).format("YYYY-MM-DD")
			}));

			return this.testLessons;
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 *
	 * @returns {Array}
	 */
	getTestLessonsForDate(date) {
		const key = date ? moment(date).startOf("month").format("DD.MM.YYYY") : this.selectedMonth.startOf("month").format("DD.MM.YYYY");

		return _filter((test) => {
			const compareTo = date ? moment(date) : this.selectedDay;

			return moment(test.date, "YYYY-MM-DD").isSame(compareTo, "d");
		})(this.testLessons[key]);
	}


	/**
	 *
	 * @param loop
	 * @param month
	 */
	loadEventAttachments(loop, month = []) {
		const {$$attachments, $rootScope} = this.services;
		let aids = _union(_flow([
			_map("attachment_ids"),
			_flatten
		])(loop))(_flow([
			_map("attachment_ids"),
			_flatten
		])(month));

		const aImgIds = _union(_map("image_attachment_id")(loop))(_map("image_attachment_id")(month));

		aids = _compact(aids.concat(aImgIds));

		if (aids.length === 0) {
			return;
		}

		return $$attachments
			.getList({ids: aids.join(",")})
			.then((data) => {
				this.attachments = data || [];

				_flow(
					_filter((event) => (event.attachment_ids && event.attachment_ids.length) || event.image_attachment_id),
					_forEach((event) => {
						const attachments = _filter((attach) => _includes(attach.id)(event.attachment_ids))(this.attachments);
						const imageAttachment = _find({id: event.image_attachment_id})(this.attachments);

						if (attachments) {
							event.$attachments = attachments;
						}

						if (imageAttachment) {
							event.$imageAttachment = imageAttachment;
						}
					})
				)(loop.concat(month));

				$rootScope.$emit("organizer:EventListChanged");
			});
	}

	/**
	 */
	async loadById(id) {
		const {$$attachments} = this.services;
		let event = null;

		try {
			event = await this.bindOne(id).get();
			if (event.attachment_ids && event.attachment_ids.length) {
				try {
					const attachments = await $$attachments.getList({ids: event.attachment_ids.join(",")});
					event.$attachments = attachments;
				} catch (er) {
					console.error(er);
				}
			} else {
				event.attachment_ids = [];
				event.$attachments = [];
			}

			if (event.image_attachment_id) {
				try {
					const attachments = await $$attachments.getList({ids: event.image_attachment_id});
					event.$imageAttachment = _isArray(attachments) ? attachments[0] : null;
				} catch (er) {
					console.error(er);
				}
			}
		} catch (er) {
			console.error(er);
		}

		return event;
	}


	/**
	 *
	 */
	prepareEventsLinks() {
		const linksEvents = _reject({link_id: null})(this.data.private.irregular);

		this.data.links = [];

		_forEach((event) =>
			this.data.links.push({
				link_id: event.link_id,
				priority: event.priority
			})
		)(linksEvents);
	}


	/**
	 *
	 * @param loopEvents
	 */
	setLoopEvents(loopEvents) {
		this.data.private.regular = _filter({type: 100})(loopEvents);
		this.data.public.regular = _reject({type: 100})(loopEvents);
	}


	/**
	 *
	 * @param monthEvents
	 */
	setMonthEvents(monthEvents) {
		const monthEventsWithClones = this.$makeRangeClones(monthEvents);

		this.data.private.irregular = _filter({type: 100})(monthEventsWithClones);
		this.data.public.irregular = _reject({type: 100})(monthEventsWithClones);
	}

	/**
	 * Создание копий событий для многодневных событий с соответствующими датами
	 * для отображения в календаре
	 * */
	$makeRangeClones(originEvents) {
		const rangeClones = [];
		_forEach((event) => {
			if (event.date !== event.date_end) {
				event.$isRange = true;

				const eventRange = moment.range(
					moment(event.date, this.eventDateFormat).add(1, "day"),
					moment(event.date_end, this.eventDateFormat)
				);

				for (const day of eventRange.by("day")) {
					const eventClone = _clone(event);
					eventClone.$isRangeClone = true;
					eventClone.$origin = event;
					eventClone.date = day.format(this.eventDateFormat);
					eventClone.date_end = eventClone.date;

					eventClone.interval_start = day.startOf("day").format("HH:mm:ss");
					eventClone.interval_end = day.endOf("day").format("HH:mm:ss");
					rangeClones.push(eventClone);
				}
			}
		})(originEvents);

		return originEvents.concat(rangeClones);
	}


	/**
	 * возвращает массив событий для даты date
	 * @param date дата, для которой нужны события
	 * @param eventsType тип событий private|public
	 * @returns {*}
	 */
	findEventsForDate(date, eventsType) {
		const events = this.data[eventsType].regular.concat(this.data[eventsType].irregular);
		let result;

		return _filter((event) => {
			// если у события задана дата окончания, и она раньше текущей,
			// то событие прошло и не требует отображения в календаре
			// p.s. повторяющиеся события повторяются в соответствии со своей регулярностью до конца учебного года
			const isEventEnded = event.date_end && !this.checkEndDate(date, event.date_end);
			const isNotRegularEvent = (!event.regularity_type || (event.regularity_type === "D" && event.regularity_step <= 1));
			if (isEventEnded && isNotRegularEvent) {
				return false;
			}

			// однократные события
			if (event.regularity_type === null) {
				result = (event.date === date.format(this.eventDateFormat));
			}

			// повторящиеся по дням недели события
			if ((event.regularity_type === "E") && _isArray(event.regularity_weekday)) {
				result = event.regularity_weekday.indexOf(date.isoWeekday()) !== -1;
			} else { // повторящиеся события
				result = this.inPeriodic(event, date);
			}

			return result || (event.date === date.format(this.eventDateFormat));
		})(events);
	}


	/**
	 *
	 * @param checkedDate
	 * @param eventEndDateStr
	 * @returns {*}
	 */
	checkEndDate(checkedDate, eventEndDateStr) {
		const eventEndDate = moment(eventEndDateStr, this.eventDateFormat).startOf("day");
		const dayDate = moment(checkedDate).startOf("day");

		return dayDate.isBefore(eventEndDate) || dayDate.isSame(eventEndDate);
	}


	/**
	 * возвращает ссылки на событие с заданным приоритетом
	 * @param event {{}}
	 * @param priority {number}
	 * @returns {Array}
	 */
	getLinksForEventWithPriority(event, priority) {
		return _filter({
			link_id: event.id,
			priority
		})(this.data.links);
	}


	/**
	 * проверяет должно ли отображаться в переданный день (date) повторяющееся событие (event)
	 * @param event событие
	 * @param date проверяемый день
	 * @returns {boolean}
	 */
	inPeriodic(event, date) {
		let interval;
		const eventDate = moment(event.date, this.eventDateFormat).startOf("day");
		const delta = date.unix() - eventDate.unix();
		// если проверяемая дата раньше даты создания события,
		// значит событие не должно показываться

		if (delta <= 0) {
			return false;
		}
		// шаг повтора события
		event.regularity_step = _.int(event.regularity_step);

		switch (event.regularity_type) {
			// ежедневное событие
			case "D":
				interval = event.regularity_step * 24 * 3600;

				return (delta % interval === 0);
				break;

			// еженедельное событие (каждая n-неделя)
			case "W":
				// повторяющиеся события клонируются на даты повторов в методе makeLoopEventsClones
				// так что можно просто сравнить даты
				return date.isSame(eventDate);// (delta % interval === 0);
				break;

			// ежемесячное событие
			case "M":
				interval = date.month() - eventDate.month();
				if (interval % event.regularity_step !== 0) {
					return false;
				}

				return date.date() === eventDate.date();
				break;

			// ежегодное событие (каждый n-год начиная с даты создания события)
			case "Y":
				interval = date.year() - eventDate.year();
				if (interval % event.regularity_step !== 0) {
					return false;
				}

				return (date.date() === eventDate.date() && date.month() === eventDate.month());
				break;

			// другой тип
			default:
				return false;
				break;
		}
	}


	/**
	 *
	 * @param events
	 * @returns {{importance_level_0: number, importance_level_1: number, importance_level_2: number}}
	 */
	getPriorityForEvents(events) {
		const priority = {
			importance_level_0: 0,
			importance_level_1: 0,
			importance_level_2: 0
		};

		_forEach((event) => {
			priority.importance_level_0 += event.priority <= 100 ? 1 : 0;
			priority.importance_level_1 += (event.priority > 100 && event.priority <= 200) ? 1 : 0;
			priority.importance_level_2 += event.priority > 200 ? 1 : 0;
		})(events);

		return priority;
	}


	/**
	 * удаление события
	 * @param event {{}} событие
	 * @returns {*}
	 */
	dropEvent(event) {
		const {$rootScope} = this.services;

		/**
		 *
		 * @param list
		 * @param eventId
		 */
		function removeById(list, eventId) {
			_remove({id: eventId})(list);
		}

		return this.bindOne(event.id)
			.remove()
			.then(() => {
				removeById(this.data.private.regular, event.id);
				removeById(this.data.private.irregular, event.id);
				removeById(this.data.public.regular, event.id);
				removeById(this.data.public.irregular, event.id);
				$rootScope.$emit("organizer:EventListChanged");
			});
	}


	/**
	 * создание ссылки на событие
	 * @param event
	 * @param priority
	 * @returns {*}
	 */
	createEventLink(event, priority) {
		const {$rootScope} = this.services;

		return this.collection
			.post({
				link_id: event.id,
				priority
			})
			.then((data) => {
				this.data.links.push({
					link_id: event.id,
					priority
				});
				$rootScope.$emit("organizer:EventListChanged");

				return data;
			});
	}


	/**
	 * Сохранение/создание события
	 * @param event
	 * @returns {*}
	 */
	saveEvent(event) {
		const {$$profile, $rootScope, $q, $$user} = this.services;

		// теперь профиль дополняется объектом
		return $$profile.getCurrentProfile()
			.then((profile) => {
				const {user_id: userId} = profile;

				return $q.all({
					profile,
					user: $$user.bindOne(userId).get()
				});
			})
			.then(({profile, user}) => {
				const data = {
					user_id: user.id,
					user_name: (event.id) ? event.user_name : $$user.getFullUsername(user),
					school_id: profile.school_id,
					date: event.date,
					date_end: event.date_end === null ? null : event.date_end,
					regularity_type: event.regularity_type,
					regularity_step: event.regularity_step,
					regularity_weekday: event.regularity_weekday,
					priority: event.priority,
					type: event.type,
					title: event.title,
					interval_start: event.interval_start === null ? null : event.interval_start,
					interval_end: event.interval_end === null ? null : event.interval_end,
					address: event.address,
					description: event.description,
					attachment_ids: event.attachment_ids,

					class_level_ids: event.class_level_ids,
					class_unit_ids: event.class_unit_ids,
					ec_field_ids: event.ec_field_ids,
					ec_form_ids: event.ec_form_ids,
					ec_activity_ids: event.ec_activity_ids,
					education_level_ids: event.education_level_ids,
					group_ids: event.group_ids,
					role: event.role,
					city_building_id: event.city_building_id,
					association_ids: event.association_ids,
					ae_program_ids: event.ae_program_ids,
					subject_ids: event.subject_ids,

					building_id: event.building_id,
					room_id: event.room_id,
					place_comment: event.place_comment,
					comment: event.comment,
					external_link: event.external_link,

					image_attachment_id: event.image_attachment_id,
					image_external_url: event.image_external_url
				};

				return (event.id) ? this.bindOne(event.id).customPUT(data, null, {sendMails: event.sendMails}) : this.collection.customPOST(data, null, {sendMails: event.sendMails});
			})
			.then((data) => {
				const type = data.type === 100 ? "private" : "public";
				const regularityType = data.regularity_type === null ? "irregular" : "regular";
				const eventIndex = _findIndex({id: event.id})(this.data[type][regularityType]);

				this.prepareBeforeUpdate(data, type, regularityType);

				data.regularity_weekday = data.regularity_weekday || [];
				data.regularity_type = data.regularity_type || null;

				if (event.id && eventIndex !== -1) {
					this.data[type][regularityType][eventIndex] = data;
				} else {
					this.data[type][regularityType].push(data);
				}
				$rootScope.$emit("organizer:EventListChanged");

				return data;
			});
	}


	/**
	 * Подготавливаем данные перед созданием/обновлением эвента
	 * Если эвент присутствует в других подмассивах, то удаляем его из них
	 */
	prepareBeforeUpdate(event, type, regularityType) {
		const typeKeys = ["private", "public"];
		const regularityTypeKeys = ["irregular", "regular"];

		for (const typeKey of typeKeys) {
			for (const regularityTypeKey of regularityTypeKeys) {
				if (typeKey === type && regularityTypeKey === regularityType) {
					continue;
				}

				_remove({id: event.id})(this.data[typeKey][regularityTypeKey]);
			}
		}
	}

	/**
	 * Возвращает шаблон нового события
	 */
	getTemplate() {
		return angular.copy(this.newEventSkeleton);
	}

	/**
	 *
	 * @returns {*}
	 */
	getTypes() {
		return angular.copy(this.eventTypes);
	}
}
