

angular
	.module("ezd.backend")
	.factory("SanPinSchedule", SanPinSchedule);


/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
SanPinSchedule.$inject = ["Core"];
function SanPinSchedule(Core) {
	return Core.service("/api/reports/sanpin_schedule");
}

