

angular
	.module("ezd.backend")
	.factory("RegistryStaff", RegistryStaff);


/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
RegistryStaff.$inject = ["Core"];
function RegistryStaff(Core) {
	return Core.service("/api/registry_teachers");
}