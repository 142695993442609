import template from "./topMenu.html";
import styles from "./top-menu.local.scss";
import "./top-menu.scss";

import _filter from "lodash/fp/filter";
import _find from "lodash/fp/find";
import _includes from "lodash/fp/includes";
import _intersects from "tools/fp/intersects";
import _isEmpty from "lodash/fp/isEmpty";


import {arrowBack, expandMore, help, home, linkArrow} from "images/icons/index";
import OrgLocalization from "components/backend/components/services/OrgLocalization";
import EomUrl from "components/common/eomUrl";

const BEGIN_CLASS_LEVELS_IDS = [1, 2, 3, 4];
const GRADUATION_CLASS_LEVEL_IDS = [9, 11, 12];
const PRESCHOOL_CLASS_LEVEL_ID = 14;

angular
	.module("ezd.common.ui")
	.component("topMenu", {
		template,
		controller: Controller
	});

Controller.$inject = ["$rootScope", "$location", "$window", "$cookies", "$q", "flashAlert", "$$history", "$$academicYear", "$$mcko", "$$mrko", "$$profile",
	"$$studentProfiles", "$$schedules", "$$teacherProfiles", "$$user", "ProfileRights", "Rights", "$scope", "$$group", "EzdAlertDialog", "EVENTS_TYPES", "$httpParamSerializer"];

/**
 * @param $rootScope
 * @param $location
 * @param $window
 * @param $cookies
 * @param $q
 * @param flashAlert
 * @param $$history
 * @param $$academicYear
 * @param $$mcko
 * @param $$mrko
 * @param $$profile
 * @param $$studentProfiles
 * @param $$schedules
 * @param $$teacherProfiles
 * @param $$user
 * @param ProfileRights
 * @param Rights
 * @param $scope
 * @param $$group
 * @param EzdAlertDialog
 * @param EVENTS_TYPES
 * @param $httpParamSerializer
 * @returns {*|boolean|boolean}
 * @constructor
 */
function Controller($rootScope, $location, $window, $cookies, $q, flashAlert, $$history, $$academicYear, $$mcko, $$mrko, $$profile,
										$$studentProfiles, $$schedules, $$teacherProfiles, $$user, ProfileRights, Rights, $scope, $$group,
										EzdAlertDialog, EVENTS_TYPES, $httpParamSerializer) {
	const tm = this;
	let matrix = [];
	let roles = [];
	let rights = [];
	let managedClassUnits = [];
	const NODE_ENV = require("NODE_ENV");
	const getGlobalString = OrgLocalization.text.bind(undefined, "$global");
	const getPageString = OrgLocalization.text.bind(undefined, "0625b91a5a6b6c4c0643d9ce4c06900c");
	const getPageVisibility = OrgLocalization.visibility.bind(undefined, "0625b91a5a6b6c4c0643d9ce4c06900c");

	tm.eomurl = EomUrl.getBaseUrl();

	tm.goBack = goBack;
	tm.setActiveMenuItem = setActiveMenuItem;

	tm.activeMenuItem = null;
	tm.styles = styles;
	tm.icons = {
		q_mark: help,
		home,
		arrowBack,
		expandMore,
		linkArrow
	};

	init();


	/**
	 *
	 */
	function init() {
		$q.all({
			profile: $$profile.getCurrentProfile(),
			academicYear: $$academicYear.getSelected()
		})
			.then(({profile, academicYear}) => {
				const request = {};
				let preschoolClassUnits = null;
				let noPreschoolClassUnits = null;
				let managedGraduationClassUnits = [];

				tm.profile = profile;
				roles = profile.roles;
				rights = profile.right_ids;

				if (!_isEmpty(profile.managed_class_units)) {
					managedClassUnits = profile.managed_class_units;
					managedGraduationClassUnits = _filter((classUnit) => {
						return _includes(classUnit.class_level_id)(GRADUATION_CLASS_LEVEL_IDS);
					})(managedClassUnits);
					noPreschoolClassUnits = _filter((classUnit) => {
						return classUnit.class_level_id !== PRESCHOOL_CLASS_LEVEL_ID;
					})(managedClassUnits);
					preschoolClassUnits = _filter((classUnit) => {
						return classUnit.class_level_id === PRESCHOOL_CLASS_LEVEL_ID;
					})(managedClassUnits);
				} else {
					managedClassUnits = [];
				}

				if (profile.type === "parent" || profile.type === "student") {
					request.student_profile = $$studentProfiles.getCurrentProfile();
				}

				tm.isParentOrStudent = _includes(profile.type)(["parent", "student"]);
				tm.isMedal = profile.type === "medal_commission";
				tm.isObserver = profile.type === "observer";
				tm.isCurator = profile.type === "curator";
				tm.isMckoState = $location.path().search("/medal_commission_mcko") > -1;
				tm.isSchoolAdmin = _.intersects(["school_admin", "school_admin_read_only"], profile.roles);
				tm.isPrincipal = checkRoles(["principal"]);
				tm.isEducator = checkRoles(["junior_educator"]);
				tm.isTeacher = checkRoles(["teacher"]);
				tm.isKinderGarten = profile.org_type_id === 5;

				tm.ibAdmin = _.intersects(["admin", "admin_security"], profile.roles);

				tm.preschoolEducationMentor = tm.isEducator && !_isEmpty(preschoolClassUnits);
				tm.generalEducationMentor = tm.isTeacher && !_isEmpty(noPreschoolClassUnits);

				tm.graduationMentor = checkRoles(["mentor"]) && !_isEmpty(managedGraduationClassUnits);

				tm.defaultPageUrl = getDefaultPage();
				tm.eomLink = getEOMLink();

				request.academic_year = tm.academicYear = academicYear;

				return $q.all(request);
			})

			.then((response) => {
				tm.student_profile = response.student_profile;

				if (tm.profile.type === "parent") {
					tm.isPreschoolParent = tm.student_profile.class_unit.class_level_id === PRESCHOOL_CLASS_LEVEL_ID;
					tm.isNooParent = BEGIN_CLASS_LEVELS_IDS.indexOf(tm.student_profile.class_unit.class_level_id) > -1;
				}

				if (tm.profile.type === "student") {
					tm.isPreschooler = tm.student_profile.class_unit.class_level_id === PRESCHOOL_CLASS_LEVEL_ID;
				}

				if (_.includes(["parent", "student"], tm.profile.type)) {
					const classLevelId = tm.student_profile.class_unit.class_level_id;
					tm.isParentStudentUpperFirstParallel = classLevelId > 1 && classLevelId <= 11;
					tm.isSchool = tm.student_profile.org_type_id === 1;
				}

				if (_.intersects(tm.profile.roles, ["educator", "junior_educator"])) {
					return $$teacherProfiles.bindOne(tm.profile.id).get({with_assigned_groups: true});
				}

				return null;
			})
			.then((teacherProfile) => {
				tm.teacherProfile = teacherProfile;

				matrix = [me, ae, ec, add, observer, staff, diary, marks, programs, other];

				let menu = filterByCheckRoles(_.cloneDeep(matrix));
				_.forEach(menu, (group) => {
					group.items = filterByCheckRoles(group.items);
					_.forEach(group.items, (item) => {
						if (item.items) {
							item.items = filterByCheckRoles(item.items);
							_.forEach(item.items, (item2) => {
								item2.action = item2.action || ((e) => {
									e.preventDefault();
									go(e.which, item2.url);
								});
							});
						} else {
							item.action = item.action || ((e) => {
								e.preventDefault();
								go(e.which, item.url);
							});
						}
					});
				});
				menu = filterVisibility(menu);

				tm.menu = menu;
				tm.setActiveMenuItem();

				tm.topLevelMenuItems = filterByCheckRoles([ibAdmin, users]);
			});

		// если стейт меняется - меняем конфиг для экрана
		tm.onStateChangeWatcher = $scope.$on("$stateChangeSuccess", () => {
			tm.setActiveMenuItem();
		});

		// установка активного пункта меню при переходе на экран событий
		$rootScope.$on("top-menu:events-came-from", (ev, type) => {
			switch (type) {
				case EVENTS_TYPES.AE:
					tm.activeMenuItem = _.find(tm.menu, {name: "Дополнительное образование"});
					break;
				case EVENTS_TYPES.EC:
					tm.activeMenuItem = _.find(tm.menu, {name: "Внеурочная деятельность"});
					break;
			}
		});
	}

	/**
	 * Поиск активной группы экранов в меню по текущему урлу
	 */
	function setActiveMenuItem() {
		tm.activeMenuItem = getActiveRootMenuItem($window.location.pathname, tm.menu);

		/**
		 * */
		function getActiveRootMenuItem(path, items = tm.menu) {
			let result = null;

			_.forEach(items, (item) => {
				if (item.url && path.startsWith(item.url)) {
					result = item;

					return false;
				}

				if (item.items && item.items.length) {
					const isPathInChildItems = getActiveRootMenuItem(path, item.items);
					if (isPathInChildItems) {
						result = item;

						return false;
					}
				}
			});

			return result;
		}
	}

	/**
	 *
	 */
	function goBack() {
		if (tm.isCurator && tm.isMckoState) {
			return $window.location.assign("/medal_commission_gmc");
		}

		if ($window.location.pathname.search("manage/new_calendar_plans/edit") !== -1 && $cookies.get("from_journal")) {
			return $window.location.assign("/desktop");
		}

		return $$history.goBack();
	}

	/**
	 *
	 * @param items
	 * @returns {*}
	 */
	function filterVisibility(items) {
		return items.filter((item) => {
			const visibility = _.get(item, "visibility", true);
			if (item.items) {
				item.items = filterVisibility(item.items);
			}

			return visibility;
		});
	}

	/**
	 *
	 * @param items
	 * @returns {*|boolean}
	 */
	function filterByCheckRoles(items) {
		return _.filter(items, (item) => {
			if (typeof item.access === "function") {
				return item.access();
			}

			const intersect = _.reject(item.access, (role) => _.startsWith(role, "!")) || [];
			let notIntersect = _.reject(item.access, (role) => !_.startsWith(role, "!")) || [];
			let itemRights = _.filter(item.access, (role) => _.startsWith(role, "right:"));


			notIntersect = _.map(notIntersect, (n) => _.trimStart(n, "!"));
			itemRights = _.map(itemRights, (n) => _.int(_.trimStart(n, "right:")));

			return (_.intersects(intersect, roles) && !_.intersects(notIntersect, roles)) || _.intersects(itemRights, rights);
		});
	}

	/**
	 *
	 * @param rolesToCheck
	 * @returns {*|boolean}
	 */
	function checkRoles(rolesToCheck) {
		const intersect = _.reject(rolesToCheck, (role) => _.startsWith(role, "!")) || [];
		let notIntersect = _.reject(rolesToCheck, (role) => !_.startsWith(role, "!")) || [];
		let itemRights = _.reject(rolesToCheck, (role) => !_.startsWith(role, "right:"));

		notIntersect = _.map(notIntersect, (n) => _.trimStart(n, "!"));
		itemRights = _.map(itemRights, (n) => _.int(_.trimStart(n, "right:")));

		return (_.intersects(intersect, roles) && !_.intersects(notIntersect, roles)) || _.intersects(itemRights, rights);
	}


	const me = {
		name: getGlobalString("general_education"),
		visibility: getPageVisibility("general_education"),
		access() {
			if (tm.isKinderGarten) {
				return checkRoles(["principal", "deputy", "school_admin", "school_admin_read_only"])
					|| tm.generalEducationMentor;
			}

			return checkRoles([
				"school_admin", "school_admin_read_only", "principal", "deputy", "admin", "teacher", "educator", "junior_educator", "curator",
				`right:${Rights.REPLACEMENTS}`,
				`right:${Rights.SECURITY_RESPONSIBLE}`,
				`right:${Rights.ADDITIONAL_EDUCATION}`,
				`right:${Rights.PASSPORT_PRINTER}`
			]);
		},
		items: [
			{
				name: "Справочники",
				access: ["school_admin", "school_admin_read_only", "principal", "deputy", "admin", "curator"],
				items: [
					{
						name: "Здания, кабинеты, места",
						url: "/buildings",
						visibility: getPageVisibility("general_education/refs/buildings"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Кадры",
						url: "/refs/_staff_list",
						visibility: getPageVisibility("general_education/refs/staff_list"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Контингент",
						url: "/refs/students",
						visibility: getPageVisibility("general_education/refs/students"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Календарные учебные графики",
						url: "/refs/study_periods",
						visibility: getPageVisibility("general_education/refs/study_periods"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Режим пребывания",
						url: "/refs/_timetables",
						visibility: getPageVisibility("general_education/refs/_timetables"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: getGlobalString("subjects"),
						url: "/refs/subjects",
						visibility: getPageVisibility("general_education/refs/subjects"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Формы контроля",
						url: "/refs/_controlforms",
						visibility: getPageVisibility("general_education/refs/_controlforms"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Общегородские площадки",
						url: "/city_buildings",
						visibility: getPageVisibility("general_education/refs/city_buildings"),
						access: ["curator"]
					},
					{
						name: "Периоды аттестации",
						url: "/manage/final_attestation_form",
						visibility: getPageVisibility("general_education/refs/final_attestation_form"),
						access: ["deputy", "school_admin", "principal"]
					},
					{
						name: getGlobalString("class_unit_management"),
						url: "/refs/mentoring",
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					}
				]
			},
			{
				name: "Учебные планы",
				access: ["school_admin", "school_admin_read_only", "deputy", "principal"],
				visibility: getPageVisibility("study_plan"),
				items: [
					{
						name: "Учебные планы",
						url: "/study_plan",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: getPageString("study_plan.class_unit_curricula"),
						url: "/study_plan/class_unit_curricula",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: getPageString("study_plan.groups_subjects"),
						url: "/study_plan/groups_subjects",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: getPageString("study_plan.teachertogroup"),
						url: "/study_plan/teachertogroup",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: getPageString("study_plan.teacherload"),
						url: "/study_plan/teacherload",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: getPageString("study_plan.students_to_groups"),
						url: "/study_plan/_students_to_groups",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: getPageString("study_plan.connected_par_cur"),
						url: "/study_plan/connected_par_cur",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal"]
					}
				]
			},
			{
				name: "Расписание",
				access: ["school_admin", "school_admin_read_only", "deputy", "principal", `right:${Rights.REPLACEMENTS}`, `right:${Rights.ADDITIONAL_EDUCATION}`],
				visibility: getPageVisibility("schedule"),
				items: [
					{
						name: "Расписание на год",
						url: "/study_plan/scheduleteacher",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`]
					},
					{
						name: "Замены",
						url: "/study_plan/replacements",
						access: ["deputy", "principal", "school_admin", "school_admin_read_only", `right:${Rights.REPLACEMENTS}`]
					}
				]
			},
			{
				name: "Планирование содержания",
				access: ["principal", "teacher", "school_admin", "school_admin_read_only", "deputy"],
				visibility: getPageVisibility("content_planning"),
				items: [
					{
						name: "Рабочие программы",
						url: "/manage/new_lesson_plans",
						access: ["teacher", "school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: "График контрольных работ",
						url: "/manage/test_lessons",
						access: ["deputy", "principal"]
					},
					{
						name: getPageString("content_planning.homework_classwork"),
						url: "/manage/hw",
						access: ["teacher", "school_admin", "school_admin_read_only", "principal"]
					}
				]
			},
			{
				name: "Журнал",
				access: ["teacher", "deputy", "principal", "school_admin", "school_admin_read_only"],
				visibility: getPageVisibility("journal"),
				items: [
					{
						name: getPageString("manage.journals"),
						url: "/manage/journals",
						access: ["deputy", "principal", "school_admin", "school_admin_read_only"]
					},
					{
						name: getPageString("manage.myjournals"),
						url: "/manage/myjournals",
						access: ["teacher"]
					}
				]
			},
			{
				name: getGlobalString("class_unit_management"),
				access() {
					return tm.preschoolEducationMentor || tm.generalEducationMentor;
				},
				items: [
					{
						name: getPageString("mentor._students"),
						url: "/mentor/_students",
						access() {
							return tm.preschoolEducationMentor || tm.generalEducationMentor;
						}
					},
					{
						name: getPageString("mentor.journals"),
						url: "/mentor/journals",
						access() {
							return tm.generalEducationMentor && !tm.isKinderGarten;
						}
					},
					{
						name: getPageString("mentor.attendances_journal"),
						url: "/manage/attendances_journal",
						access() {
							return tm.generalEducationMentor;
						}
					},
					{
						name: getPageString("mentor.schedule.class"),
						action: mentorScheduleRedirect,
						url: "/mentor/schedule/class/",
						access() {
							return tm.generalEducationMentor && !tm.isKinderGarten;
						}
					}
				]
			},
			{
				name: "Олимпиады",
				visibility: getPageVisibility("olympiads"),
				url: "/olympiads",
				access: ["curator"]
			},
			{
				name: "Отсутствующие сегодня",
				visibility: getPageVisibility("non_attendances"),
				url: "/common/nonattendances",
				access: ["principal", "deputy", "school_admin", "school_admin_read_only", "mentor", `right:${Rights.SECURITY_RESPONSIBLE}`]
			},
			{
				name: "Отчеты",
				visibility: getPageVisibility("reports"),
				access: [
					"mentor", "deputy", "school_admin", "school_admin_read_only", "teacher", "principal", "admin", "curator", "educator",
					`right:${Rights.REPLACEMENTS}`,
					`right:${Rights.SECURITY_RESPONSIBLE}`
				],
				items: [
					{
						name: "Активность пользователей",
						url: "/reports/user_activity",
						access: ["school_admin", "deputy", "principal", "school_admin_read_only", "mentor"]
					},
					{
						name: "Ведомость для медальной комиссии",
						url: "/medal_commission_school",
						access: ["school_admin", "school_admin_read_only", "support", "principal", "deputy", "mentor"]
					},
					{
						name: "Ведомость для медальной комиссии",
						url: "/medal_commission_gmc",
						access: ["curator"]
					},
					{
						name: "Замены",
						url: "/reports/replacements",
						access: ["school_admin", "school_admin_read_only", "deputy", `right:${Rights.REPLACEMENTS}`, "principal"]
					},
					{
						name: "Статистика ЭПОС.Школа",
						url: "/reports/epos",
						access: ["admin"]
					},
					{
						name: "Качество обученности",
						url: "/reports/students_quality",
						access: ["mentor", "deputy", "school_admin", "school_admin_read_only", "principal"]
					},
					{
						name: "Качество работы педагога",
						url: "/reports/teachers",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: "Непрохождение промежуточной аттестации",
						access: ["principal", "deputy", "school_admin", "school_admin_read_only", "mentor", "teacher"],
						url: "/reports/academic_debt"
					},
					{
						name: "Отсутствующие",
						url: "/reports/missing/by_class_level",
						access: ["mentor", "deputy", "school_admin", "school_admin_read_only", `right:${Rights.SECURITY_RESPONSIBLE}`, "principal"]
					},
					{
						name: "О реализации УП",
						url: "/reports/implementation_curriculum",
						access: ["school_admin", "school_admin_read_only", "deputy", "mentor", "principal"]
					},
					{
						name: "Проверка журнала",
						url: "/reports/journal_check",
						access: ["school_admin", "school_admin_read_only", "deputy", "teacher", "principal"]
					},
					{
						name: "Расписание",
						url: "/reports/class_san_pin_schedule",
						access: ["school_admin", "deputy", "principal", "school_admin_read_only"]
					},
					{
						name: "Результаты периодов аттестации",
						url: "/reports/finalmarks",
						access: ["mentor", "deputy", "school_admin_read_only", "school_admin", "principal"]
					},
					{
						name: "Средние баллы",
						url: "/reports/avg_marks",
						access: ["mentor", "school_admin", "school_admin_read_only", "deputy", "principal"]
					},
					{
						name: "Успеваемость",
						url: "/reports/progress",
						access: ["school_admin", "mentor", "deputy", "school_admin_read_only", "principal"]
					},
					{
						name: getPageString("reports.teachersext"),
						url: "/reports/teachersext",
						access: ["school_admin", "school_admin_read_only", "deputy", "teacher", "principal"]
					}
				]
			},
			{
				name: getPageString("certificate"),
				visibility: getPageVisibility("certificate"),
				access() {
					return checkRoles(["school_admin", "school_admin_read_only", "deputy", "passport_printer", "principal", `right:${Rights.PASSPORT_PRINTER}`])
						|| tm.graduationMentor;
				},
				items: [
					{
						name: "Сведения об учреждении",
						url: "/certificate/school_meta_info",
						access: ["admin", "school_admin", "school_admin_read_only", "deputy", "curator", "principal", `right:${Rights.PASSPORT_PRINTER}`]
					},
					{
						name: getPageString("certificate.subjects"),
						url: "/certificate/subjects",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal", `right:${Rights.PASSPORT_PRINTER}`]
					},
					{
						name: "Ведомость итоговых отметок",
						url: "/certificate/marks",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal", `right:${Rights.PASSPORT_PRINTER}`]
					},
					{
						name: "Ведомость экзаменационных отметок",
						url: "/certificate/final_marks_sheet",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal", `right:${Rights.PASSPORT_PRINTER}`]
					},
					{
						name: "Номера",
						url: "/certificate/numbers",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal", `right:${Rights.PASSPORT_PRINTER}`]
					},
					{
						name: "Печать",
						url: "/certificate/print",
						access: ["school_admin", "school_admin_read_only", "deputy", "principal", `right:${Rights.PASSPORT_PRINTER}`]
					},
					{
						name: "Аттестационная ведомость",
						access() {
							return tm.graduationMentor;
						},
						action(e) {
							e.preventDefault();
							$window.open(`/reports/api/certificate_numbers/xlsx?class_unit_id=${managedClassUnits[0].id}`, "_blank");
						}
					}, {
						name: "Оценочная ведомость",
						access() {
							return tm.graduationMentor;
						},
						action(e) {
							e.preventDefault();
							$window.open(`/reports/api/certificate_marks/xlsx?class_unit_id=${managedClassUnits[0].id}`, "_blank");
						}
					},
					{
						name: "Фиксация итоговых отметок",
						url: "/certificate/certificate_hold",
						access: ["deputy", "principal", "school_admin", "school_admin_read_only"]
					},
					{
						name: "Дубликаты",
						url: "/certificate/duplicate_registry/list",
						access: ["admin", "school_admin", "school_admin_read_only", "deputy", "principal", `right:${Rights.PASSPORT_PRINTER}`]
					}
				]
			},
			{
				name: "Настройки учебного года",
				access: ["principal", "deputy", "school_admin", "school_admin_read_only"],
				visibility: getPageVisibility("school_year_settings"),
				items: [
					{
						name: "Фиксация отметок",
						access: ["principal", "deputy", "school_admin", "school_admin_read_only"],
						url: "/admin/school_settings/marks_fixation"
					}
				]
			},
			{
				name: "Академические задолженности",
				visibility: getPageVisibility("academic_debt"),
				url: "/academic_debt/list",
				access: ["school_admin", "deputy", "principal", "school_admin_read_only", "mentor"]
			},
			{
				name: "Управление системными шаблонами РП",
				visibility: getPageVisibility("system_templates"),
				url: "/manage/system_templates",
				access: ["curator"]
			}
		]
	};

	const ae = {
		name: "Дополнительное образование",
		access() {
			if (tm.isKinderGarten) {
				return checkRoles(["school_admin", "school_admin_read_only", "ae_admin", "principal", "deputy", "ae_educator", `right:${Rights.ADDITIONAL_EDUCATION}`]);
			}

			return checkRoles(["school_admin", "school_admin_read_only", "ae_admin", "principal", "deputy", "ae_educator", "mentor", `right:${Rights.ADDITIONAL_EDUCATION}`]);
		},
		items: [
			{
				name: "Справочники",
				access: ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`],
				items: [
					{
						name: "Здания, кабинеты, места",
						url: "/buildings",
						visibility: getPageVisibility("additional_education/refs/buildings"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Кадры",
						url: "/refs/_staff_list",
						visibility: getPageVisibility("additional_education/refs/staff_list"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Контингент",
						url: "/refs/students",
						visibility: getPageVisibility("additional_education/refs/students"),
						access: ["school_admin", "school_admin_read_only", "principal", "deputy"]
					},
					{
						name: "Группы, педагоги, места",
						url: "/ae/refs/groups_teachers_places",
						access: ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`]
					},
					{
						name: "Календарные учебные графики",
						url: "/ae/refs/_study_periods",
						access: ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`]
					},
					{
						name: "Формы контроля",
						access: ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`],
						url: "/ae/refs/control_forms"
					},
					{
						name: "Аттестационные периоды",
						url: "/ae/refs/final_attestation/forms",
						access: ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`]
					}
				]
			},
			{
				name: "Занятость учащихся в ДО",
				access: ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "mentor", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`],
				url: "/refs/ae_class_units",
				action(e) {
					e.preventDefault();
					if (_.intersects(roles, ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`])) {
						go(e.which, "/refs/ae_class_units");
					} else if (_.includes(roles, "mentor")) {
						go(e.which, `/refs/ae_class_units/${_.map(managedClassUnits, "id")}`);
					}
				}
			},
			{
				name: "Планирование",
				access: ["deputy", "school_admin", "school_admin_read_only", "ae_admin", "principal", "ae_educator", `right:${Rights.ADDITIONAL_EDUCATION}`],
				items: [
					{
						name: "Дополнительные общеразвивающие программы",
						url: "/planning/ae_lesson_plans",
						access: ["deputy", "teacher", "school_admin", "school_admin_read_only", "ae_admin", "principal", "ae_educator", `right:${Rights.ADDITIONAL_EDUCATION}`]
					},
					{
						name: "Учебные планы",
						url: "/ae/planning/parallel_curricula",
						access: ["deputy", "school_admin", "school_admin_read_only", "ae_admin", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`]
					}
				]
			},
			{
				name: "Расписание",
				access: ["school_admin", "school_admin_read_only", "ae_admin", "principal", "deputy", `right:${Rights.ADDITIONAL_EDUCATION}`],
				items: [
					{
						name: "Расписание на год",
						access: ["school_admin", "school_admin_read_only", "ae_admin", "principal", "deputy", `right:${Rights.ADDITIONAL_EDUCATION}`],
						url: "/ae/schedules"
					}
				]
			},
			{
				name: "Журнал",
				access: ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "principal", "ae_educator", `right:${Rights.ADDITIONAL_EDUCATION}`],
				items: [
					{
						name: "Журналы",
						url: "/ae/journal/list",
						access: ["school_admin", "school_admin_read_only", "ae_admin", "deputy", "principal", `right:${Rights.ADDITIONAL_EDUCATION}`]
					},
					{
						name: "Мои журналы",
						url: "/ae/journal/my_list",
						access: ["ae_educator"]
					}
				]
			},
			{
				name: "Олимпиады",
				access: ["school_admin", "olympiads_responsible"],
				url: getAeServiceUrl("olympiads"),
				visibility: getPageVisibility("olympiads"),
				action(e) {
					e.preventDefault();
					if (_.intersects(roles, ["school_admin", "olympiads_responsible"])) {
						const olympiadsUrl = getAeServiceUrl("olympiads");
						$window.open(olympiadsUrl, "_blank");
					}
				}
			},
			{
				name: "Мероприятия",
				access: ["school_admin", "school_admin_read_only", "ae_admin", "principal", "deputy",
					"ae_educator", "mentor", `right:${Rights.ADDITIONAL_EDUCATION}`],
				action(e) {
					e.preventDefault();
					go(e.which, "/events/_feed/calendar", {preset: EVENTS_TYPES.AE});
				}
			}
		]
	};

	const ec = {
		name: getGlobalString("extracurricular_activities"),
		visibility: getPageVisibility("extracurricular_activities"),
		access: () => {
			// Для пользователя, имеющего только роль Воспитателя и/или Старшего воспитателя помимо сотрудника,
			// но при этом имеющего классное руководство дошкольной группы, скрыть пункт "внеурочная деятельность"
			// на экране Мероприятий
			const isEducator = _intersects(["junior_educator", "educator"])(roles);
			const educatorWithGroups = isEducator && tm.teacherProfile.assigned_ec_group_ids.length > 0;
			const otherCheckedRoles = ["school_admin", "school_admin_read_only", "principal", "deputy", "teacher", `right:${Rights.EC_ACTIVITY}`];
			const isOtherIntersect = _intersects(otherCheckedRoles)(roles);
			const isPreschoolMentor = Boolean(_find({class_level_id: PRESCHOOL_CLASS_LEVEL_ID})(managedClassUnits));

			if (isPreschoolMentor && isEducator && !isOtherIntersect) {
				return false;
			}

			const defaultCheckedRoles = [...otherCheckedRoles, "mentor"];

			return _intersects(roles)(defaultCheckedRoles) || educatorWithGroups;
		},
		items: [
			{
				name: "Справочники",
				access: ["school_admin", "school_admin_read_only", "principal", "deputy", `right:${Rights.EC_ACTIVITY}`],
				items: [
					{
						name: "Формы организации ВД",
						url: "/ec/refs/forms",
						access: ["school_admin", "school_admin_read_only", "principal", "deputy", `right:${Rights.EC_ACTIVITY}`]
					},
					{
						name: "Формы контроля",
						url: "/refs/_ec_control_forms",
						access: ["school_admin", "school_admin_read_only", "principal", "deputy", `right:${Rights.EC_ACTIVITY}`]
					}
				]
			},
			{
				name: "Планирование",
				access: ["school_admin", "school_admin_read_only", "principal", "deputy", "mentor", "teacher", "junior_educator", "educator", `right:${Rights.EC_ACTIVITY}`],
				items: [
					{
						name: "Рабочие программы",
						url: "/ec/planning/programs",
						access: ["school_admin", "school_admin_read_only", "principal", "deputy", "mentor", "teacher", "junior_educator", "educator", `right:${Rights.EC_ACTIVITY}`]
					},
					{
						name: "Формирование учебных групп",
						url: "/ec/planning/groups_formation",
						access: ["school_admin", "school_admin_read_only", "principal", "deputy", `right:${Rights.EC_ACTIVITY}`]
					},
					{
						name: "Привязка " + getGlobalString("students_gen_case") + " к группам",
						url: "/ec/planning/groups_assignments",
						access: ["school_admin", "school_admin_read_only", "principal", "deputy", "mentor", `right:${Rights.EC_ACTIVITY}`]
					}
				]
			},
			{
				name: "Расписание",
				access: ["school_admin", "school_admin_read_only", "principal", "deputy", `right:${Rights.EC_ACTIVITY}`],
				items: [
					{
						name: "Расписание на год",
						url: "/ec/schedules",
						access: ["school_admin", "school_admin_read_only", "principal", "deputy", `right:${Rights.EC_ACTIVITY}`]
					}
				]
			},
			{
				name: "Журнал",
				access: ["teacher", "junior_educator", "educator", "deputy", "principal", "school_admin", "school_admin_read_only", `right:${Rights.EC_ACTIVITY}`],
				items: [
					{
						name: "Журналы групп",
						url: "/ec/journal",
						access: ["deputy", "principal", "school_admin", "school_admin_read_only", `right:${Rights.EC_ACTIVITY}`]
					},
					{
						name: "Мои группы",
						url: "/ec/journal/my_journals",
						access: ["teacher", "junior_educator", "educator", "principal", "junior_educator", "educator", `right:${Rights.EC_ACTIVITY}`]
					}
				]
			},
			{
				name: "Отчеты",
				access: ["school_admin", "school_admin_read_only", "principal", "deputy", "mentor", `right:${Rights.EC_ACTIVITY}`],
				items: [
					{
						name: "План " + getGlobalString("extracurricular_activities_genitive"),
						url: "/ec/reports/plan",
						access: ["school_admin", "school_admin_read_only", "principal", "deputy", "mentor", `right:${Rights.EC_ACTIVITY}`]
					}
				]
			},
			{
				name: "Мероприятия",
				access: ["school_admin", "school_admin_read_only", "principal", "deputy", "teacher", "junior_educator", "educator", "mentor", `right:${Rights.EC_ACTIVITY}`],
				action(e) {
					e.preventDefault();
					go(e.which, "/events/_feed/calendar", {preset: EVENTS_TYPES.EC});
				}
			}
		]
	};

	const add = {
		name: "Дополнительно",
		access: ["school_admin", "school_admin_read_only", "principal", "deputy", "teacher", "educator", "admin",
			"curator", "admin_security", "junior_educator", "ae_educator"],
		items: [
			{
				name: "Администрирование",
				access() {
					if (tm.isKinderGarten) {
						return checkRoles(["admin", "school_admin_read_only", "school_admin", "curator", "principal"]);
					}

					return checkRoles(["admin", "school_admin_read_only", "school_admin", "curator", "principal", "deputy"]);
				},
				items: [
					{
						name: "Пользователи",
						url: "/admin/users",
						access: ["school_admin_read_only", "school_admin", "curator", "principal"]
					},
					{
						name: "Логирование",
						url: "/admin/logs",
						access: ["admin", "school_admin_read_only", "school_admin", "principal"]
					},
					{
						name: "Все ДЗ",
						url: "/admin/homeworks",
						visibility: getPageVisibility("additional/admin/homeworks"),
						access: ["school_admin", "school_admin_read_only", "admin", "principal", "deputy"]
					},
					{
						name: "Все оценки",
						url: "/admin/marks",
						visibility: getPageVisibility("additional/admin/marks"),
						access: ["school_admin", "school_admin_read_only", "admin", "principal", "deputy"]
					},
					{
						name: "Распределение прав",
						// url: '/admin/roles',
						action: redirectToAdminRoles,
						access: ["admin"]
					},
					{
						name: "Категории новостей",
						url: "/admin/news_categories",
						access: ["admin"]
					},
					{
						name: "Перенос аттестационных оценок",
						url: "/admin/pills/final_marks_transfer",
						visibility: getPageVisibility("additional/admin/final_marks_transfer"),
						access: ["admin", "school_admin", "principal"]
					},
					{
						name: "Системные пользователи",
						url: "/admin/system_users",
						access: ["admin"]
					},
					{
						name: "Информирование",
						url: "/admin/system_messages",
						access: ["admin"]
					},
					{
						name: "Организации",
						url: "/admin/schools",
						access: ["admin", "curator"]
					},
					{
						name: "Расчет черновика",
						url: "/admin/draft_calculation",
						access: ["admin", "curator"]
					},
					{
						name: "Настройки отчётов",
						url: "/admin/reports_settings",
						access: ["admin"]
					},
					{
						name: "Производственный календарь",
						url: "/admin/calendar_transpositions",
						access: ["admin"]
					}
				]
			}, {
				name: "Внешние ссылки",
				access: ["teacher", "principal", "deputy", "school_admin", "school_admin_read_only", "mentor", "educator", "junior_educator", "ae_educator"],
				items: [
					{
						name: "Полезные ссылки",
						url: "/outter/links",
						access: ["teacher", "principal", "deputy", "school_admin", "school_admin_read_only", "mentor", "educator", "junior_educator", "ae_educator"]
					}
				]
			},
			{
				name: "Логирование",
				url: "/admin/logs",
				access: ["admin_security"]
			}
		]
	};


	const diary = {
		name: "Дневник",
		access() {
			return checkRoles(["parent"]) || (checkRoles(["student"]) && !tm.isPreschooler);
		},
		items: [
			{
				name: "Дневник",
				url: "/student_diary/student_diary",
				access() {
					return checkRoles(["parent", "student"]) && !tm.isPreschoolParent;
				},
				action(e) {
					e.preventDefault();
					$$studentProfiles
						.getCurrentProfile()
						.then((studentProfile) => {
							window.open(`/student_diary/student_diary/${studentProfile.id}`, "_self");
						});
				}
			},
			{
				name: "Домашнее задание, классная работа",
				url: "/student_diary/homeworks",
				access() {
					return checkRoles(["parent", "student"]) && !tm.isPreschoolParent;
				},
				action(e) {
					e.preventDefault();
					window.open("/student_diary/homeworks", "_self");
				}
			},
			{
				name: "Уведомление об отсутствии",
				url: "/nonattendances_notifications/parent",
				access() {
					return checkRoles(["parent"]) && !tm.isPreschoolParent;
				}
			},
			{
				name: "Расписание",
				url: "/manage/student_schedule",
				access() {
					return checkRoles(["parent", "student"]) && !tm.isPreschoolParent;
				}
			},
			{
				name: "Режим пребывания",
				url: "/refs/student_timetables",
				access() {
					return checkRoles(["parent", "student"]) && !tm.isPreschoolParent;
				}
			},
			{
				name: "Посещение дошкольной группы",
				url: "#",
				action(e) {
					e.preventDefault();
					$$studentProfiles
						.getCurrentProfile()
						.then((studentProfile) => {
							go(e.which, "/manage/visiting_preschool/" + studentProfile.id);
						});
				},
				access() {
					return checkRoles(["parent"]) && tm.student_profile.class_unit.class_level_id === PRESCHOOL_CLASS_LEVEL_ID;
				}
			}
		]
	};

	const marks = {
		name: "Оценки",
		access() {
			return (checkRoles(["parent"]) && !tm.isPreschoolParent)
				|| (checkRoles(["student"]) && !tm.isPreschooler);
		},
		items: [
			{
				name: "Все оценки",
				url: "/progress/all_marks",
				access: ["student", "parent"]
			},
			{
				name: "Итоговые оценки",
				url: "/progress/final_marks",
				access: ["student", "parent"]
			},
			{
				name: "Динамика успеваемости",
				url: "/progress/dynamic",
				access: ["student", "parent"]
			},
			{
				name: "Недельный дайджест",
				url: "/student_diary/digests",
				access() {
					return checkRoles(["parent", "student"]) && tm.isSchool;
				},
				action(e) {
					e.preventDefault();
					window.open("/student_diary/digests", "_self");
				}
			},
			{
				name: "Непрохождение промежуточной аттестации",
				access: ["student", "parent"],
				url: "/reports/academic_debt"
			},
			{
				name: "Ведомость оценок",
				access() {
					return checkRoles(["parent", "student"]) && tm.student_profile.previously_profile_id;
				},
				url: "/reports/student_mark_statement"
			}
		]
	};

	const programs = {
		name: "Образование",
		access() {
			return (checkRoles(["parent"]) && !tm.isPreschoolParent)
				|| (checkRoles(["student"]) && !tm.isPreschooler);
		},
		items: [
			{
				name: "Рабочие программы",
				url: "/student_diary/lesson_plans",
				access: ["student", "parent"],
				action(e) {
					e.preventDefault();
					window.open("/student_diary/lesson_plans", "_self");
				}
			},
			{
				name: "Учебный план",
				url: "/student_diary/parallel_curricula",
				access: ["student", "parent"],
				action(e) {
					e.preventDefault();
					window.open("/student_diary/parallel_curricula", "_self");
				}
			},
			{
				name: "Календарный учебный график",
				url: "/refs/study_periods/edit/",
				action(e) {
					e.preventDefault();
					$$studentProfiles
						.getCurrentProfile()
						.then((studentProfile) => {
							return $$studentProfiles.getStudentPeriodsSchedule(studentProfile);
						})
						.then((periodsSchedule) => {
							if (periodsSchedule) {
								go(e.which, `/refs/study_periods/edit/${periodsSchedule.id}`);
							} else {
								flashAlert.error("Не найден учебный план ученика");
							}
						});
				},
				access: ["student", "parent"]
			}
		]
	};

	const other = {
		name: "Прочее",
		access: ["student", "parent"],
		items: [
			{
				name: "Внешние ссылки",
				access() {
					return checkRoles(["parent"]) && tm.isNooParent;
				},
				items: [
					{
						name: "Полезные ссылки",
						url: "/outter/links",
						access: ["parent"]
					}
				]
			},
			{
				name: "Полезные ссылки",
				url: "/outter/links",
				access() {
					return checkRoles(["parent", "student"]) && !tm.isNooParent;
				}
			}
		]
	};

	const observer = {
		name: "Образовательные организации",
		access: ["observer"],
		items: [{
			name: "Образовательные организации",
			url: "/admin/schools",
			access: ["observer"]
		}]
	};


	const staff = {
		name: "Школы",
		access: ["super_staff"],
		items: [{
			name: "Школы",
			url: "/admin/schools",
			access: ["super_staff"]
		}]
	};

	const users = {
		name: "Пользователи",
		access: ["admin", "admin_security"],
		url: "/administrator/users",
		action(e) {
			e.preventDefault();
			window.open("/administrator/users", "_self");
		}
	};

	const ibAdmin = {
		name: "Безопасность",
		access: ["admin_security"],
		url: "/administrator/security/authorized_users",
		action(e) {
			e.preventDefault();
			window.open("/administrator/security/authorized_users", "_self");
		}
	};

	/**
	 *
	 * @param {object} e event
	 */
	function redirectToAdminRoles(e) {
		e.preventDefault();
		window.location.pathname = "/admin/roles";
	}

	/**
	 *
	 * @param e
	 */
	function mentorScheduleRedirect(e) {
		e.preventDefault();
		if (tm.profile.managed_class_unit_ids.length === 1) {
			const selectedClassId = tm.profile.managed_class_unit_ids[0];
			const selectedClassLevelId = tm.profile.managed_class_units[0].class_level_id;
			const academicYearStart = moment(tm.academicYear.begin_date, "YYYY-MM-DD").startOf("week");
			const dateStart = moment().startOf("week");
			const dateEnd = moment().endOf("week");
			const studyWeek = dateStart.diff(academicYearStart, "weeks") + 1;
			const url = [
				"/mentor/schedule/class/",
				selectedClassId,
				"/level/",
				selectedClassLevelId,
				"/week/",
				studyWeek,
				"?begin_date=",
				dateStart.format("YYYY-MM-DD"),
				"&end_date=",
				dateEnd.format("YYYY-MM-DD")
			].join("");

			$location.url(url);
		} else {
			$location.url("/mentor/schedule");
		}
	}

	/**
	 *
	 * @param {number} button number
	 * @param {string} url address
	 * @param {object} params
	 */
	function go(button, url, params = {}) {
		if (button === 1) {
			if (!_isEmpty(params) && ($location.path() === url)) {
				$window.location.href = `${url}?${$httpParamSerializer(params)}`;
			} else {
				$location.path(url).search(params);
			}
		} else {
			$window.open(url);
		}
	}

	/**
	 * Получить ссылку на экран по умолчанию
	 */
	function getDefaultPage() {
		if (!tm.ibAdmin) {
			return "/desktop";
		}

		return `${location.origin}/administrator`;
	}

	/**
	 * Получить ссылку на авторизованный переход в библиотеку
	 * @returns {string}
	 */
	function getEOMLink() {
		const groupRole = tm.isParentOrStudent ? tm.profile.type : "employee";

		return `${tm.eomurl}/eom-acl/oidc/login?groupRole=${groupRole}`;
	}

	/**
	 * return additional education service url by service name
	 * @param serviceName
	 * @returns {string}
	 */
	function getAeServiceUrl(serviceName) {
		let url = "";
		switch (NODE_ENV) {
			case "production":
				url = "https://edu-epos.permkrai.ru";
				break;
			case "test":
				url = "https://ezdtest.permkrai.ru";
				break;
			case "dev":
				url = "https://ezd.test.dfsystems.ru";
				break;
		}
		url += "/authenticate/goto_ae_service?service=" + serviceName;

		return url;
	}
}
