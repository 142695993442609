

angular
	.module("ezd.backend")
	.factory("$$homeworkEntry", homeworkEntry);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
homeworkEntry.$inject = ["Core"];
function homeworkEntry(Core) {
	const url = "/api/homework_entries";
	const Service = Core.service(url);

	Service.bindOne = bindOne;
	Service.getLinksFromDescription = getLinksFromDescription;

	return Service;

	function bindOne(id) {
		return Core.one(url, id);
	}


	function getLinksFromDescription(description) {
		let links;
		let jsonString;

		try {
			jsonString = description.split("JSON::")[1] || "[]";
			links = JSON.parse(jsonString);

			return _.map(links, (link) => {
				const linkObject = {
					url: "//uchebnik-epos.permkrai.ru/player2/books/" + link.ebook_id + "." + link.version,
					title: link.name
				};
				linkObject.url += (Boolean(link.path)) ? "/articles/" + link.path : "";

				return linkObject;
			});
		} catch (e) {
			return [];
		}
	}
}
