import {SupportFormOpenLinkComponent} from "./support-form-open-link/support-form-open-link.component";
import {EzdSupportFormService} from "./ezd-support-form.service";

export default angular
	.module("ezd.common.ui")
	.service("EzdSupportFormService", EzdSupportFormService)
	.component(SupportFormOpenLinkComponent.selector, SupportFormOpenLinkComponent)
	.config(route)
	.name;

route.$inject = ["$routeProvider"];

function route($routeProvider) {
	$routeProvider
		.when("/support-form", {
			controllerAs: "$ctrl",
			resolve: {
				report: () => ({})
			},
			screenSizes: {
				page: {
					width: "1200px"
				},
				top_menu: {
					width: "1200px"
				}
			}
		});
}
