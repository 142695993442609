

angular
	.module("ezd.backend")
	.service("$$controllableItem", $$controllableItem);


/**
 *
 * @param Core
 */
$$controllableItem.$inject = ["Core"];
function $$controllableItem(Core) {
	const url = "/api/controllable_items";
	const vm = this;

	vm.collection = Core.all(url);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);
}