

angular
	.module("ezd.backend")
	.factory("$$studentDigest", studentDigests);


/**
 *
 * @param Core
 * @param $q
 * @returns {Object|*}
 */
studentDigests.$inject = ["Core", "$q"];
function studentDigests(Core, $q) {
	// const localforage = require("localforage");
	const url = "/api/student_digests";
	const Service = Core.service(url);

	/**
	 *
	 * BINDINGS
	 *
	 */

	Service.switchDigest = switchDigest;
	Service.create = create;


	/**
	 *
	 * RUN
	 *
	 */

	return Service;


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */


	/**
	 *
	 * @param studentDigest {student_profile_id, calendar_lesson_id, controllable_item_id}
	 * @returns Promise
	 */
	function create(studentDigest) {
		if (studentDigest.student_profile_id && studentDigest.calendar_lesson_id && studentDigest.controllable_item_id) {
			return Service.post(studentDigest);
		}

		return $q((resolve, reject) => reject());
	}


	/**
	 *
	 * @returns {*}
	 */
	function switchDigest() {
		return $q((resolve) => resolve());
	}
}
