

angular
	.module("ezd.backend")
	.service("$$homework", $$homework);

/**
 *
 * @param Core
 */
$$homework.$inject = ["Core"];
function $$homework(Core) {
	const url = "/api/homeworks";
	const vm = this;

	vm.list = [];
	vm.collection = Core.all(url);
	vm.collection.getList = (query) => Core.all(url).getList(query);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);


	/**
	 * @deprecated
	 *
	 * @param num
	 * @param filter
	 * @returns {*}
	 */
	vm.getPage = (num, filter) => {
		let params = {page: num, per_page: 50};

		if (_.keys(filter).length !== 0) {
			params = angular.extend(params, filter);
		}

		return Core.all(url).getList(params);
	};
}
