angular
	.module("ezd.backend")
	.factory("ECCategories", ECCategories);


ECCategories.$inject = ["EC"];

/**
 *
 * @param EC
 * @returns {*|Object}
 */
function ECCategories(EC) {
	return EC.service("/api/ec_activity_category");
}

