import {Component} from "app/decorators/index";
import template from "./ezd-accordion.html";
import styles from "./ezd-accordion.local.scss";
import {expandMore} from "images/icons";

/**
 * Простой аккордеон.
 *
 * <ezd-accordion disable-expand="$ctrl.isDisabled" is-opened="$ctrl.isOpenedAccordion">
 *   <accordion-header>
 *      контент, который видно до раскрытия аккордеона. Клик по нему будет раскрывать аккордеон.
 *   </accordion-header>
 *   <accordion-content>
 *      контент, который появляется в раскрывающейся части аккордеона
 *   </accordion-content>
 * </ezd-accordion>
 *
 * disable-expand="$ctrl.isDisabled" - boolean флаг, который определяет будет ли аккордеон раскрываться или нет
 * is-opened="$ctrl.isOpenedAccordion" -  boolean флаг, определяющий состояние аккордиона: раскрыт или скрыт
 *
 * Есть возможность программно закрыть все аккордеоны на странице. Для этого достаточно бродкастить событие "ezdAccordion:closeAll"
 *
 */

@Component({
	selector: "ezdAccordion",
	template,
	transclude: {
		aheader: "accordionHeader",
		acontent: "accordionContent"
	},
	bindings: {
		isOpened: "=?", // true - аккордеон раскрыт, false - скрыт
		disableExpand: "<", // true - не дает раскрывать аккордеон
		arrowTopPosition: "<", // возможность вручную установить высоту иконки стрелки. указывается числом, обозначающим количество пикселей от верхней границы
		arrowLeftPosition: "<", // возможность вручную установить левую координату иконки стрелки. указывается числом, обозначающим количество пикселей от левой границы
		onOpen: "&", // возможность передать метод, срабатывающий при открытии аккордеона,
		onToggle: "&" // возможность передать метод, срабатывающий при любом переключении аккордеона,
	}
})
class EzdAccordionComponent {
	static $inject = ["$rootScope"];

	/**
	 * Стили
	 */
	styles = styles;

	/**
	 * Иконки
	 */
	icons = {expandMore};

	/**
	 * @param $rootScope
	 */
	constructor($rootScope) {
		this.services = {$rootScope};
	}

	/**
	 *
	 */
	$onInit() {
		const {$rootScope} = this.services;

		if (_.isUndefined(this.isOpened)) {
			this.isOpened = false;
		}

		this.closeAllAccordion = $rootScope.$on("ezdAccordion:closeAll", () => {
			this.isOpened = false;
		});
	}

	/**
	 *
	 */
	$onDestroy() {
		this.closeAllAccordion();
	}

	/**
	 *
	 */
	toggle() {
		if (Boolean(this.disableExpand)) {
			return;
		}
		this.isOpened = !this.isOpened;

		if (this.isOpened) {
			this.onOpen();
		}

		this.onToggle();
	}
}


angular
	.module("ezd.common.ui")
	.component(EzdAccordionComponent.selector, EzdAccordionComponent);
