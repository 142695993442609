

angular
	.module("ezd.backend")
	.service("ReportBagPack", ReportBagPack);


/**
 *
 * @param REP
 * @constructor
 */
ReportBagPack.$inject = ["REP"];
function ReportBagPack(REP) {
	const self = this;

	self.getForMentor = getForMentor;
	self.getForParent = getForParent;


	function getForMentor(params) {
		return REP.all("/api/bag/mentor/json").getList(params);
	}

	function getForParent(params) {
		return REP.one("/api/bag/parent/json").get(params);
	}
}