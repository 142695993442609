import _getOr from "lodash/fp/getOr";


/**
 * Сервис для работы с апи выгрузки журнала групп внеурочной деятельности
 */
// Чтобы не дублировать код репозиториев и DAO в mod_ec, обработка запроса направляется в mod_core
class ECJournalsArchive {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.ECService = Core;
	}

	/**
	 * скачка файла
	 * @returns {Promise<*>}
	 */
	getECArchive() {
		return this.ECService.all("/api/journals/get_latest?generate_archive=false&is_ec_journals=true").withHttpConfig({responseType: "blob"}).customGET();
	}

	/**
	 * Получение даты последнего скачивания выгрузки
	 * @returns {Promise<*>}
	 */
	async getLastDownloadDate() {
		const response = await this.ECService
			.all("/api/journals")
			.one("get_ec_last_download_date")
			.get();

		return _getOr("", "last_download_date", response);
	}
}

angular
	.module("ezd.backend")
	.service("$$ecJournalsArchive", ECJournalsArchive);
