angular
	.module("ezd.backend")
	.service("$$studentProfiles", Service);

Service.$inject = ["$cookies", "$q", "Core", "$$academicYear", "$$classUnit", "$$parallelCurricula", "$$profile",
	"bigQuery", "$$periodSchedule", "flashAlert", "$$userSelectProfile"];

/**
 *
 * @param $cookies
 * @param $q
 * @param Core
 * @param $$academicYear
 * @param $$classUnit
 * @param $$parallelCurricula
 * @param $$profile
 * @param bigQuery
 * @param $$periodSchedule
 * @param flashAlert
 * @param $$userSelectProfile
 * @constructor
 */
function Service($cookies, $q, Core, $$academicYear, $$classUnit, $$parallelCurricula, $$profile,
								 bigQuery, $$periodSchedule, flashAlert, $$userSelectProfile) {
	/**
	 *
	 * CONSTANTS AND VARIABLES
	 *
	 */

	const url = "/api/student_profiles";
	const vm = this;

	vm.list = [];
	vm.collection = Core.all(url);
	vm.collection.getList = (query) => Core.all(url).getList(query);
	vm.selectedProfile = {};


	/**
	 *
	 * DECLARATION
	 *
	 */
	vm.getList = getList;
	vm.bindOne = bindOne;
	vm.getCurrentProfile = getCurrentProfile;
	vm.getGrades = getGrades;
	vm.getPage = getPage;
	vm.getBirthYears = getBirthYears;
	vm.getParallelCurriculaForStudent = getParallelCurriculaForStudent;
	vm.getClassUnitForStudent = getClassUnitForStudent;
	vm.setCurrentProfile = setCurrentProfile;
	vm.getStudentShortName = getStudentShortName;
	vm.getTimeTableId = getTimeTableId;
	vm.getStudentsListForParent = getStudentsListForParent;
	vm.getStudentPeriodsSchedule = getStudentPeriodsSchedule;
	vm.updateClassLevel = updateClassLevel;


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */


	/**
	 *
	 * @returns {Promise.<*>}
	 */
	async function getStudentsListForParent() {
		try {
			const academicYear = await $$academicYear.getSelected();

			if (_.has(vm, "studentsForParent")) {
				return vm.studentsForParent;
			}

			const students = await (this.getChildrenPromise = bigQuery.queue.getList(vm.collection, {academic_year_id: academicYear.id}, 50));

			if (students.length === 0) {
				flashAlert.info("Идет обработка приказов о зачислении. Данные об учениках временно недоступны. Повторите попытку позднее");
			}

			this.getChildrenPromise = null;
			if (_.isEmpty(this.selectedProfile)) {
				this.setCurrentProfile(getDefaultChildrenProfile(students) || students[0]);
			}

			vm.studentsForParent = students;

			return students;
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 *
	 * @param profiles
	 * @returns {T|*}
	 */
	function getDefaultChildrenProfile(profiles) {
		return _.find(profiles, {id: _.int($cookies.get("student_id"))}) || profiles[0];
	}

	/**
	 *
	 * @returns {*}
	 */
	function getList(options) {
		return vm.collection.getList(options);
	}


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(url, id);
	}


	/**
	 * Возвращает оценки ученика по предметам subjectIds
	 * @param profileId {number} id профиля ученика
	 * @param subjectIds [] массив id предметов
	 * @returns {promise}
	 */
	function getGrades(profileId, subjectIds) {
		return Core
			.one(url, profileId)
			.getList("grades", {
				subject_ids: subjectIds.join(",")
			});
	}


	/**
	 *
	 * @param num
	 * @param filter
	 * @returns {Promise.<T>|*}
	 */
	function getPage(num, filter) {
		const result = {
			data: [],
			pages: 1
		};
		const rest = Core.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true));
		let params = {page: num, per_page: 50, with_parents: true};

		if (_.keys(filter).length !== 0) {
			params = angular.extend(params, filter);
		}

		return rest.all(url)
			.getList(params)
			.then((response) => {
				result.data = response.data;
				result.pages = _.int(response.headers("Pages")) || 1;
				result.characters = response.headers("X-Fio-Letters");
				result.total = _.int(response.headers("total-entities")) || 0;

				return result;
			});
	}

	function getBirthYears() {
		return Core.all(url + "/birth_years").customGET();
	}

	/**
	 *
	 * @param profile
	 * @returns {Promise.<T>}
	 */
	function getParallelCurriculaForStudent(profile) {
		return $$academicYear
			.getSelected()
			.then((academicYear) => vm.bindOne(profile.id).get({academic_year_id: academicYear.id}))
			.then(
				(studentProfile) => (studentProfile.curricula && studentProfile.curricula.id) ? $$parallelCurricula.getCurriculaForStudent(studentProfile) : null)
			.then((curricula) => curricula);
	}

	/**
	 *
	 * @param profile
	 * @returns {*}
	 */
	function getClassUnitForStudent(profile) {
		return $$academicYear
			.getSelected()
			.then((academicYear) => vm.bindOne(profile.id).get({academic_year_id: academicYear.id}))
			.then((studentProfile) => $$classUnit.bindOne(studentProfile.class_unit.id).get())
			.then((classUnit) => classUnit);
	}

	/**
	 * Получение графика учебного периода для ученика
	 * @param profile
	 * @returns {PromiseLike<T | never>}
	 */
	function getStudentPeriodsSchedule(profile) {
		return $$academicYear
			.getSelected()
			.then((year) => vm.bindOne(profile.id).get({academic_year_id: year.id}))
			.then((studentProfile) => {
				const params = {
					student_profile_id: studentProfile.id
				};

				return $$periodSchedule.collection.getList(params);
			})
			.then((periodSchedules) => periodSchedules[0]);
	}


	/**
	 * @todo выкинуть работу с setInterval в отдельный метод
	 */
	function getCurrentProfile() {
		const deferred = $q.defer();

		$$profile
			.getCurrentProfile()
			.then((profile) => {
				if (_.includes(profile.roles, "student")) {
					deferred.resolve(profile);
				} else if (_.isEmpty(vm.selectedProfile) && _.includes(profile.roles, "parent")) {
					if (!this.getChildrenPromise) {
						setTimeout(() => {
							this.getStudentsListForParent();
						}, 0);
					}

					const t = setInterval(() => {
						if (!_.isEmpty(vm.selectedProfile)) {
							clearInterval(t);
							deferred.resolve(vm.selectedProfile);
						}
					}, 200);
				} else {
					deferred.resolve(vm.selectedProfile);
				}
			});

		return deferred.promise;
	}

	/**
	 * Возвращает id раписания звонков для ученика, выбранного в шапке
	 * @returns {*}
	 */
	function getTimeTableId() {
		return $q((resolve) =>
			vm.getCurrentProfile()
				.then((studentProfile) => vm.getClassUnitForStudent(studentProfile))
				.then((classUnit) => resolve(classUnit.bells_timetable_id))
		);
	}

	/**
	 *
	 * @param profile
	 * @returns {*}
	 */
	function setCurrentProfile(profile) {
		return $q((resolve) => {
			if (!_.isEmpty(profile)) {
				$$userSelectProfile.send(profile);
				$cookies.put("student_id", profile.id);
				vm.selectedProfile = profile;
			}
			resolve(profile);
		});
	}


	/**
	 * Возвращает ФИО ученика
	 * @param fullName {string} Полное Имя Ученика
	 * return shortName {string} Краткое И. У.
	 */
	function getStudentShortName(fullName) {
		if (!fullName) {
			console.error("Не передано ФИО ученика");
		}
		const splitName = fullName.split(" ");
		let shortName = splitName[0] + " " + splitName[1].substr(0, 1) + ". ";

		// отчество не всегда бывает
		if (splitName[2]) {
			shortName += splitName[2].substr(0, 1) + ".";
		}

		return shortName;
	}

	/**
	 *
	 * @returns {*}
	 */
	function updateClassLevel(student) {
		return Core.one(url + "/class_level", student.id).customPUT(student);
	}
}

