

angular
	.module("ezd.backend")
	.service("$$gradeSystems", $$gradeSystems);


/**
 *
 * @param Core
 */
$$gradeSystems.$inject = ["Core"];
function $$gradeSystems(Core) {
	const url = "/api/grade_systems";
	const vm = this;

	vm.list = [];
	vm.collection = Core.all(url);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);


	/**
	 * Возвращает шаблон для шкалы
	 * @param scaleType string
	 * @returns {*}
	 */
	vm.getDefaultDataForScale = (scaleType) => _.find(defaultData, {type: scaleType}) || {};


	/**
	 *
	 */
	vm.getList = () => defaultData;


	/**
	 *
	 * @param scaleType
	 * @returns {{}}
	 */
	vm.getDefaultDataTemplate = (scaleType) => {
		const template = vm.getDefaultDataForScale(scaleType);

		return (template.defaults && template.defaults.length > 0) ? angular.copy(template.defaults[0]) : {};
	};


	/**
	 *
	 * @param value
	 * @param gradeSystemType
	 * @returns {*}
	 */
	vm.convertMarkWithDefaults = (value, gradeSystemType) => {
		let result;


		/**
		 *
		 * @param mark
		 * @returns {number}
		 */
		function hundredToFive(mark) {
			const $mark = _.float(mark);
			switch (true) {
				case $mark >= 0 && $mark <= 10 :
					return 1;
					break;

				case $mark >= 11 && $mark <= 20 :
					return 1;
					break;

				case $mark >= 21 && $mark <= 30 :
					return 2;
					break;

				case $mark >= 31 && $mark <= 60 :
					return 3;
					break;

				case $mark >= 61 && $mark <= 80 :
					return 4;
					break;

				default:
					return 5;
					break;
			}
		}


		/**
		 *
		 * @param mark
		 * @returns {number}
		 */
		function decimalToFive(mark) {
			const $mark = _.float(mark);
			switch (true) {
				case $mark >= 0 && $mark < 3 :
					return 1;
					break;

				case $mark >= 3 && $mark < 5 :
					return 2;
					break;

				case $mark >= 5 && $mark < 7 :
					return 3;
					break;

				case $mark >= 7 && $mark < 9 :
					return 4;
					break;

				default:
					return 5;
					break;
			}
		}

		switch (gradeSystemType) {
			case "hundred":
				result = hundredToFive(value);
				break;

			case "five":
				result = value;
				break;

			case "decimal":
				result = decimalToFive(value);
				break;

			default :
				result = 0;
				break;
		}

		return result;
	};


	/**
	 * рассчет средней оценки
	 * ср = (оценка1*вес1 + оценка2*вес2) / (вес1 + вес2)
	 * @param marks
	 * @returns {*}
	 */
	vm.getAvg = (marks) => {
		let marksSum = 0;
		let weightSum = 0;

		/**
		 *
		 * @param num
		 * @returns {number}
		 */
		function getDecimal(num) {
			let str = String(num);
			const zeroPos = str.indexOf(".");
			if (zeroPos === -1) { return 0; }
			str = str.slice(zeroPos);

			return Number(str);
		}

		const $marks = marks || [];
		// нет оценок - средний бал 0
		if ($marks.length === 0) {
			return 0;
		}
		_.forEach($marks, (mark) =>
			_.forEach(mark.values, (markValue) => {
				const markFiveValue = _.float(markValue.grade.five) || 0;
				// if (markFiveValue !== 0) {
				marksSum += markFiveValue * mark.weight;
				weightSum += mark.weight;
				// }
			})
		);

		return marksSum / weightSum;
	};

	/**
	 * расчет средне-взешенной оценки с учетом grade_system_type:
	 * five - расчет по grade.five
	 * hundred - расчет по grade.hundred
	 * abc - расчет по grade.hundred, далее преобразование в abc
	 * approve - расчет по grade.hundred, далее преобразование в approve
	 * criterial - расчет по grade.origin
	 * decimal - расчет по grade.origin
	 * nscale - расчет по grade.origin
	 *
	 * если несколько различных grade_system_type и scale=="ORIGINAL", то расчет возвращает ""
	 * @param marks
	 * @param scale в какой шкале вернуть результат: "FIVE", "HUNDRED", "ORIGINAL"
	 * @returns {*}
	 */
	vm.getWeightedMean = function (marks, scale) {
		/**
		 * hundredToAbc
		 * @param mark
		 * @returns {*}
		 */
		function hundredToAbc(mark) {
			const $mark = _.float(mark);
			switch (true) {
				case $mark < 10 :
					return "F";
				case $mark >= 10 && $mark < 20 :
					return "E";
				case $mark >= 20 && $mark < 30 :
					return "D";
				case $mark >= 30 && $mark < 60 :
					return "C";
				case $mark >= 60 && $mark < 80 :
					return "B";
				default:
					return "A";
			}
		}
		/**
		 * hundredToApprove
		 * @param mark
		 * @returns {*}
		 */
		function hundredToApprove(mark) {
			return _.float(mark) >= 75 ? "З" : "Н";
		}
		const gradeSystemTypes = [];
		let sum = _(marks)
			.map((mark) => {
				const values = _.map(mark.values, (value) => {
					if (scale.toLowerCase() === "original" && !gradeSystemTypes.includes(value.grade_system_type)) {
						gradeSystemTypes.push(value.grade_system_type);
						if (gradeSystemTypes.length > 1) {
							return 0;
						}
					}
					let $value;
					switch (scale.toLowerCase()) {
						case "five":
							$value = value.grade.five;
							break;
						case "hundred":
							$value = value.grade.hundred;
							break;
						default: { // "ORIGINAL"
							switch (value.grade_system_type) {
								case "abc":
									$value = value.grade.hundred;
									break;
								case "approve":
									$value = value.grade.hundred;
									break;
								default: // criterial, decimal, nscale
									$value = value.grade.origin;
							}
						}
					}

					return $value;
				});

				return _.sum(values) * mark.weight;
			})
			.sum();

		if (!sum) {
			return 0;
		}

		if (scale.toLowerCase() === "original" && gradeSystemTypes.length > 1) {
			return "";
		}

		let result = sum / _.sumBy(marks, "weight");
		if (gradeSystemTypes.includes("abc")) {
			result = hundredToAbc(result);
		} else if (gradeSystemTypes.includes("approve")) {
			result = hundredToApprove(result);
		}

		return result;
	};

	vm.getAvgHint = function () {
		return "Средняя оценка высчитывается по формуле:"
			+ " ср.знач = Σ(оценка * вес оценки) / Σ(веса оценок). "
			+ "Критериальные оценки не учитываются при расчёте средневзвешенной оценки.";
	};


	const defaultData = [
		{
			name: "Критериальная шкала",
			type: "criterial",
			defaults: [
				{
					name: "Критерий 1",
					inversion: false,
					nmax: 100,
					names: [],
					n: [
						{
							range: {to: 100.00, from: 81.00}
						},
						{
							range: {to: 80.99, from: 61.00}
						},
						{
							range: {to: 60.99, from: 31.00}
						},
						{
							range: {to: 30.99, from: 21.00}
						},
						{
							range: {to: 20.99, from: 11.00}
						},
						{
							range: {to: 10.99, from: 0.00}
						}
					],
					hundred: [
						{
							range: {to: 100.00, from: 81.00}
						},
						{
							range: {to: 80.00, from: 61.00}
						},
						{
							range: {to: 60.00, from: 31.00}
						},
						{
							range: {to: 30.00, from: 21.00}
						},
						{
							range: {to: 20.00, from: 11.00}
						},
						{
							range: {to: 10.00, from: 0.00}
						}
					],
					five: [
						{mark: 5.00},
						{mark: 4.00},
						{mark: 3.00},
						{mark: 2.00},
						{mark: 1.00},
						{mark: 0.00}
					]
				}
			]
		},
		{
			name: "ABC-шкала",
			type: "abc",
			defaults: [
				{
					name: "",
					nmax: 6,
					n: [],
					names: ["A", "B", "C", "D", "E", "F"],
					hundred: [
						{
							range: {to: 100.00, from: 81.00}
						},
						{
							range: {to: 80.00, from: 61.00}
						},
						{
							range: {to: 60.00, from: 31.00}
						},
						{
							range: {to: 30.00, from: 21.00}
						},
						{
							range: {to: 20.00, from: 11.00}
						},
						{
							range: {to: 10.00, from: 0.00}
						}
					],
					five: [
						{mark: 5.00},
						{mark: 4.00},
						{mark: 3.00},
						{mark: 2.00},
						{mark: 1.00},
						{mark: 0.00}
					]
				}
			]
		},
		{
			name: "100-балльная шкала",
			type: "hundred",
			defaults: [
				{
					name: "",
					nmax: 100.00,
					n: [],
					names: [],
					hundred: [
						{
							range: {to: 100.00, from: 81.00}
						},
						{
							range: {to: 80.00, from: 61.00}
						},
						{
							range: {to: 60.00, from: 31.00}
						},
						{
							range: {to: 30.00, from: 21.00}
						},
						{
							range: {to: 20.00, from: 11.00}
						},
						{
							range: {to: 10.00, from: 0.00}
						}
					],
					five: [
						{mark: 5.00},
						{mark: 4.00},
						{mark: 3.00},
						{mark: 2.00},
						{mark: 1.00},
						{mark: 0.00}
					]
				}
			]
		},
		{
			name: "10-балльная шкала",
			type: "decimal",
			defaults: [
				{
					name: "",
					nmax: 10,
					names: [],
					n: [
						{
							range: {to: 10.00, from: 9.00}
						},
						{
							range: {to: 8.99, from: 7.00}
						},
						{
							range: {to: 6.99, from: 5.00}
						},
						{
							range: {to: 4.99, from: 3.00}
						},
						{
							range: {to: 2.99, from: 0.00}
						}
					],
					hundred: [
						{
							range: {to: 100.00, from: 81.00}
						},
						{
							range: {to: 80.00, from: 61.00}
						},
						{
							range: {to: 60.00, from: 31.00}
						},
						{
							range: {to: 30.00, from: 21.00}
						},
						{
							range: {to: 20.00, from: 0.00}
						}
					],
					five: [
						{mark: 5.00},
						{mark: 4.00},
						{mark: 3.00},
						{mark: 2.00},
						{mark: 1.00}
					]
				}
			]
		},
		{
			name: "5-балльная шкала",
			type: "five",
			defaults: [
				{
					name: "",
					nmax: 6.00,
					n: [],
					names: [],
					hundred: [
						{
							range: {to: 100.00, from: 81.00}
						},
						{
							range: {to: 80.00, from: 61.00}
						},
						{
							range: {to: 60.00, from: 31.00}
						},
						{
							range: {to: 30.00, from: 21.00}
						},
						{
							range: {to: 20.00, from: 11.00}
						},
						{
							range: {to: 10.00, from: 0.00}
						}
					],
					five: [
						{mark: 5.00},
						{mark: 4.00},
						{mark: 3.00},
						{mark: 2.00},
						{mark: 1.00},
						{mark: 0.00}
					]
				}
			]
		},
		{
			name: "Зачёт/Незачёт",
			type: "approve",
			defaults: [
				{
					name: "",
					nmax: 2,
					n: [],
					names: ["Зачёт", "Незачёт"],
					hundred: [
						{
							range: {to: 100.00, from: 51.00}
						},
						{
							range: {to: 50.00, from: 0.00}
						}
					],
					five: [
						{mark: 5.00},
						{mark: 2.00}
					]
				}
			]
		},
		{
			name: "N-балльная шкала",
			type: "nscale",
			defaults: [
				{
					name: "",
					nmax: 100.00,
					names: [],
					n: [
						{
							range: {to: 100.00, from: 81.00}
						},
						{
							range: {to: 80.99, from: 61.00}
						},
						{
							range: {to: 60.99, from: 31.00}
						},
						{
							range: {to: 30.99, from: 21.00}
						},
						{
							range: {to: 20.99, from: 11.00}
						},
						{
							range: {to: 10.99, from: 0.00}
						}
					],
					hundred: [
						{
							range: {to: 100.00, from: 81.00}
						},
						{
							range: {to: 80.00, from: 61.00}
						},
						{
							range: {to: 60.00, from: 31.00}
						},
						{
							range: {to: 30.00, from: 21.00}
						},
						{
							range: {to: 20.00, from: 11.00}
						},
						{
							range: {to: 10.00, from: 0.00}
						}
					],
					five: [
						{mark: 5.00},
						{mark: 4.00},
						{mark: 3.00},
						{mark: 2.00},
						{mark: 1.00},
						{mark: 0.00}
					]
				}
			]
		}
	]; // end defaultData
}
