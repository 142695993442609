import {Component} from "app/decorators/index";
import styles from "./ezd-search-input.local.scss";

import template from "./ezd-search-input.html";
import {find} from "images/icons/index";


@Component({
	selector: "ezdSearchInput",
	template,
	bindings: {
		placeholder: "@",
		width: "@",
		model: "<",
		onChange: "&",
		mode: "@", // в этот параметр можно передать строку "keypress" и в этом случае коллбэк будет срабатывать при вводе строки с клавиатуры
		debounce: "<" // можно передать значение задержки в милисекундах при mode = "keypress"
	}
})
class EzdSelectComponent {
	static $inject = ["$timeout", "$element"];

	/**
	 *
	 * @param $timeout
	 * @param $element
	 */
	constructor($timeout, $element) {
		this.services = {$timeout, $element};

		this.styles = styles;
		this.icons = {find};

		this.modelIsChanged = false;
	}


	/**
	 *
	 */
	$onInit() {
		const {$element} = this.services;

		if (this.width) {
			$element.css({width: this.width});
		}

		if (this.mode === "keypress") {
			this.debounce = this.debounce || 0;
		} else {
			this.debounce = 0;
		}
	}

	/**
	 * метод, срабатывающий в стандартном режиме
	 */
	onBlur() {
		if (!this.modelIsChanged) {
			return;
		}

		if (this.mode === "keypress") {
			return;
		}

		this.searchTextChanged();
	}

	/**
	 * метод, срабатывающий в режиме ввода с клавиатуры
	 */
	onChangeModel() {
		this.modelIsChanged = true;

		if (this.mode !== "keypress") {
			return;
		}

		this.searchTextChanged();
	}

	/**
	 *
	 */
	searchTextChanged() {
		this.modelIsChanged = false;
		this.onChange({
			$event: this.model
		});
	}

	/**
	 * Обработчик нажатия на Enter в input
	 */
	onKeydown(ev) {
		if (ev.keyCode === 13 && this.mode !== "keypress") {
			this.searchTextChanged();
		}
	}
}


angular
	.module("ezd.common.ui")
	.component(EzdSelectComponent.selector, EzdSelectComponent);
