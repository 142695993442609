import template from "./date-picker.template.html";
import {Component} from "app/decorators/index";
import styles from "./ezd-datepicker.local.scss";
import moment from "tools/moment.local";

@Component({
	selector: "datePicker",
	template,
	bindings: {
		enabledInput: "=?", // флаг, определяющий давать ли возможность ручного ввода даты
		selected: "=", // выбранная в календаре дата
		resultFormat: "@?", // формат, в котором отдавать выбранную дату
		displayFormat: "@?", // формат, в котором отображать выбранную дату
		academicYearLimit: "=?", // использовать ли учебный год для ограничения календаря,
		startLimit: "=?", // начальная дата ограничения календаря
		endLimit: "=?", // конечная дата ограничения календаря
		isWeekly: "=?", // режим подсветки выбранной недели (если isWeekly === true , то подсвечивается выбранная неделя )
		attachTo: "@?", // сбрасывать выбранную дату на конец или начало недели
		forceCurrentDate: "=?",
		nullable: "=?",
		isDisabled: "=?"
	}
})
export class EzdDatePickerComponent {
	static $inject = ["$scope", "$$academicYear"];

	styles = styles;

	/**
	 * массив дней выбранного месяца
	 */
	days = [];

	/**
	 * Выбранная дата в заданном формате отображения
	 */
	displayValue = "";

	/**
	 * Флаг некорректной даты
	 * @type {boolean}
	 */
	error = false;

	/**
	 * Флаг видимости календаря
	 */
	isShowCalendar = false;

	/**
	 * Выбранная дата
	 */
	selectedDate = moment();
	displayedDate = moment();

	defaultDateFormat = "DD.MM.YYYY"; // формат даты по-умолчанию
	academicYearStart = null; //  {{moment}} начало учебного года
	academicYearEnd = null; // {{moment}} конец учебного года

	/**
	 *
	 */
	constructor($scope, $$academicYear) {
		this.services = {$scope, $$academicYear};
		this.styles = styles;

		this.startLimit = this.startLimit ? moment(this.startLimit, "DD.MM.YYYY") : null;
		this.endLimit = this.endLimit ? moment(this.endLimit, "DD.MM.YYYY") : null;
	}

	/**
	 * */
	$onInit() {
		const {$scope, $$academicYear} = this.services;
		if (!_.isBoolean(this.forceCurrentDate)) {
			this.forceCurrentDate = true;
		}
		// формат по-умолчанию для возвращаемой даты
		this.resultFormat = this.resultFormat || this.defaultDateFormat;
		// формат по-умолчанию для отображаемой даты
		this.displayFormat = this.displayFormat || this.defaultDateFormat;
		// использовать ли ограничения учебного года
		this.academicYearLimit = Boolean(this.academicYearLimit);
		this.isWeekly = Boolean(this.isWeekly);
		// флаг, определяющий давать ли возможность ручного ввода даты
		if (!angular.isDefined(this.enabledInput)) {
			this.enabledInput = false;
		}

		if (!this.academicYearLimit) {
			this.setDefaultDate();
			this.displayValue = this.getSelected(this.displayFormat);
		} else {
			// если включено ограничение по учебному году, то получаем начало и конец учебного года
			$$academicYear
				.getSelected()
				.then((selectedAcademicYear) => {
					this.academicYearStart = moment(selectedAcademicYear.begin_date, "YYYY-MM-DD").startOf("day");
					this.academicYearEnd = moment(selectedAcademicYear.end_date, "YYYY-MM-DD").endOf("day");
					this.setDefaultDate();
					this.displayValue = this.getSelected(this.displayFormat);
				});
		}

		// вотчер для установки значения даты из-под кода
		this.watcherSelected = $scope.$watch(() => this.selected, this.updateSelected.bind(this));
	}

	$onDestroy() {
		this.watcherSelected();
	}

	/**
	 *
	 * @param value
	 */
	updateSelected(value) {
		if (!angular.isDefined(value)) {
			return;
		}

		const newValue = moment(value, this.resultFormat, true);
		this.error = this.enabledInput && (!newValue.isValid() || !this.dateInAcademicYear(newValue).isInside || !this.dateInLimits(newValue));
		this.selectedDate = angular.copy(newValue);
		this.displayValue = this.getSelected(this.displayFormat);
	}

	/**
	 * */
	$onChanges(changes) {
		if (changes.selected && !changes.selected.isFirstChange()) {
			this.setDefaultDate();
			this.displayValue = this.getSelected(this.displayFormat);
		}
	}

	/**
	 * Устанавливает дату по умолчанию
	 */
	setDefaultDate() {
		// если задана дата
		if (this.nullable && this.selected === null) {
			this.selectedDate = null;

			return;
		}

		if (this.selected) {
			this.selectedDate = moment(this.selected, this.resultFormat);
		} else if (!this.forceCurrentDate) {
			return;
		}
		if (this.isWeekly) {
			const dayNumber = (this.attachTo === "begin") ? 0 : 6;
			this.selectedDate = moment(this.selected, "DD.MM.YYYY").weekday(dayNumber);
			this.displayedDate = angular.copy(this.selectedDate);
		}
		// если дата по-умолчанию выходит за пределы периода выбранного учебного года, то выставляем дату либо в конец либо в начало года
		const dateYearPosition = this.dateInAcademicYear(this.selectedDate);
		if (!dateYearPosition.isInside) {
			if (dateYearPosition.position === "before" || dateYearPosition.position === "start") {
				this.selectedDate = angular.copy(this.academicYearStart);
				this.displayedDate = angular.copy(this.academicYearStart);
			} else if (dateYearPosition.position === "after" || dateYearPosition.position === "end") {
				this.selectedDate = angular.copy(this.academicYearEnd);
				this.displayedDate = angular.copy(this.academicYearEnd);
			}
		}

		// выбранная дата по-умолчанию
		this.selected = this.getSelected(this.resultFormat);
		// заполняем массив дней для выбранной даты по-умолчанию
		this.monthDaysByWeeks();
	}

	/**
	 * Переключение флага видимости календаря
	 */
	toggleShowingCalendar() {
		if (this.isDisabled) {
			return;
		}

		this.isShowCalendar = !this.isShowCalendar;
		if (this.selectedDate === null) {
			this.selectedDate = moment();
			this.selected = this.getSelected(this.resultFormat);
		}
		if (this.isShowCalendar) {
			this.displayedDate = angular.copy(this.selectedDate);
			this.monthDaysByWeeks();
		}
	}

	/**
	 * */
	outsideClickListerner() {
		if (this.isShowCalendar) {
			this.toggleShowingCalendar();
		}
	}

	/**
	 * Сдвиг месяца на 1 "назад"
	 */
	calendarBack() {
		this.displayedDate.subtract(1, "months");
		this.monthDaysByWeeks();
	}


	/**
	 * Сдвиг месяца на 1 "вперед"
	 */
	calendarForward() {
		this.displayedDate.add(1, "months");
		this.monthDaysByWeeks();
	}

	/**
	 * Возвращает заголовок календаря
	 * @returns {*}
	 */
	getDateTitle() {
		return this.displayedDate.format("MMM YYYY");
	}

	/**
	 * геттер/сеттер для поля ввода даты
	 * @param value {string} дата
	 * @returns {string}
	 */
	getterSetterDateValue(value) {
		const newValue = moment(value, this.displayFormat, true);

		if (angular.isDefined(value)) {
			this.selected = value; // для работы вотчера и обработки дат после некорректного ввода, иначе не отлавливается
			this.displayValue = value;
			if (!newValue.isValid()) {
				this.error = true;
			} else if (this.dateInAcademicYear(newValue).isInside) {
				this.selectDate(newValue);
				this.displayValue = newValue;
			}
		}

		return this.displayValue;
	}


	/**
	 * Построение сетки календаря
	 */
	monthDaysByWeeks() {
		const start = moment(this.displayedDate).startOf("month").startOf("week"); // начало месяца
		const end = moment(this.displayedDate).endOf("month").endOf("week"); // конец месяца
		const daysDiff = end.diff(start, "days") + 1; // количество днеЙ в выбранном месяце
		const result = [];

		_.times(daysDiff, () => {
			result.push({
				day: start.date(),
				item: angular.copy(start)
			});
			start.add(1, "day");
		});

		this.days = _.chunk(result, 7);
	}

	/**
	 * проверка входит ли дата в период выбранного учебного года
	 * @param checkedDate {{}} moment объект даты
	 * @returns {obj}
	 */
	dateInAcademicYear(checkedDate) {
		const answer = {
			isInside: true,
			position: ""
		};

		if (!this.academicYearLimit || !this.academicYearStart || !this.academicYearEnd
			|| checkedDate.isSame(this.academicYearStart)
			|| checkedDate.isSame(this.academicYearEnd)) {
			if (checkedDate.isSame(this.academicYearStart)) {
				answer.position = "start";
			} else if (checkedDate.isSame(this.academicYearEnd)) {
				answer.position = "end";
			}

			return answer;
		}

		if (checkedDate.isBetween(this.academicYearStart, this.academicYearEnd)) {
			return answer;
		}
		answer.isInside = false;
		if (checkedDate.isBefore(this.academicYearStart)) {
			answer.position = "before";
		} else {
			answer.position = "after";
		}

		return answer;
	}

	/**
	 * проверка входит ли дата в лимиты startLimit и endLimit
	 * @param checkedDate {{}} moment объект даты
	 * @returns {boolean}
	 */
	dateInLimits(checkedDate) {
		return !((this.startLimit && checkedDate.isBefore(moment(this.startLimit, "DD.MM.YYYY")))
			|| (this.endLimit && checkedDate.isAfter(moment(this.endLimit, "DD.MM.YYYY"))));
	}


	/**
	 * Выбор даты по клику в календаре
	 * @param dayObject {{}} объект moment для выбранного дня
	 */
	selectDate(dayObject) {
		if (!this.dateInAcademicYear(dayObject).isInside || !this.dateInLimits(dayObject)) {
			return;
		}
		this.error = false;
		this.selectedDate = angular.copy(dayObject);
		this.selected = this.getSelected(this.resultFormat);
		this.displayValue = this.getSelected(this.displayFormat);
		this.toggleShowingCalendar();
	}

	/**
	 * Получение выбранной даты в соответствии с указанным форматом
	 * @param format {string} формат даты
	 * @returns {*}
	 */
	getSelected(format) {
		return this.selectedDate ? this.selectedDate.format(format) : "--.--.----";
	}

	/**
	 * Проверка является ли день выходным днем
	 * @param dayObject
	 * @returns {boolean}
	 */
	isWeekend(dayObject) {
		return ([6, 7].indexOf(dayObject.isoWeekday()) !== -1) && this.dateInAcademicYear(dayObject).isInside;
	}

	/**
	 * */
	isSelectedDay(dayObject) {
		return this.selectedDate.format("YYYY-MM-DD") === dayObject.format("YYYY-MM-DD");
	}

	/**
	 * */
	isSelectedWeek(weekNum) {
		if (!this.isWeekly) {
			return false;
		}

		let isSelected = false;
		_.forEach(this.days[weekNum], (day) => {
			if (day.item.isSame(this.selectedDate, "day")) {
				isSelected = true;

				return false;
			}
		});

		return isSelected;
	}
}

