angular
	.module("ezd.backend")
	.service("$$groupPeriod", groupPeriodService);


/**
 *
 * @param bigQuery
 * @param $q
 * @param $$academicYear
 * @param $$attestationPeriodsSchedule
 * @param $$classUnit
 * @param $$group
 * @param $$lessonPlan
 * @param $$lessonScheduleItem
 * @param $$periodSchedule
 * @param $$studentProfiles
 */
groupPeriodService.$inject = ["bigQuery", "$q", "$$academicYear", "$$attestationPeriodsSchedule", "$$classUnit", "$$group",
	"$$lessonPlan", "$$lessonScheduleItem", "$$periodSchedule", "$$studentProfiles"];
function groupPeriodService(bigQuery, $q, $$academicYear, $$attestationPeriodsSchedule, $$classUnit, $$group,
							$$lessonPlan, $$lessonScheduleItem, $$periodSchedule, $$studentProfiles) {
	const vm = this;
	vm.periods = {
		study: [],
		attestation: [],
		module: []
	};

	const periodTypeRu = {
		attestation: "Аттестационный",
		study: "Учебный",
		module: "Модули"
	};

	/**
	 * Добавляем в группы тип периода и сами периоды
	 * @param groups
	 * @param teacherId
	 * return []
	 */
	vm.addPeriods = (groups, teacherId) => {
		let academicYear;
		const deferred = $q.defer();

		$$academicYear.getSelected()
			.then((data) => {
				academicYear = data;
				vm.subgroupIds = [];
				_.forEach(groups, (group) => {
					if (group.is_metagroup) {
						vm.subgroupIds.push(group.subgroup_ids[0]);
					}
				});
			})
			// получаем подгруппы, чтобы по ним находить периоды в метагруппах
			.then(() => {
				if (vm.subgroupIds.length > 0) {
					return bigQuery.getList($$group.collection, {
						academic_year_id: academicYear.id,
						group_ids: vm.subgroupIds.join(",")
					}, "group_ids", 50);
				}

				return [];
			})
			.then((data) => {
				vm.subgroups = data;
			})
			.then(() => {
				vm.classUnitIds = [];
				_.forEach(groups, (group) => {
					vm.classUnitIds = vm.classUnitIds.concat(group.class_unit_ids);
					// @TODO Перепилить на сервис
					group.period_type = getGroupAttestationType(group);
					group.period_type_ru = periodTypeRu[group.period_type];
				});

				// потому что по подгруппам мы тоже активно шерстим
				_.forEach(vm.subgroups, (group) => {
					group.period_type = getGroupAttestationType(group);
					group.period_type_ru = periodTypeRu[group.period_type];
				});
			})

			.then(() => {
				vm.classUnitIds = _.uniq(vm.classUnitIds);

				return $q.all({
					studentProfiles: bigQuery.queue.getList($$studentProfiles.collection, {
						class_unit_ids: vm.classUnitIds.join(",")
					}),
					classUnits: $$classUnit.collection.getList({ids: vm.classUnitIds.join(",")})
				});
			})

			.then((data) => {
				vm.studentProfiles = data.studentProfiles;
				// filter lodash не может достучаться до внутреннего поля, поэтому делаем так
				_.forEach(data.studentProfiles, (classUnit) => {
					classUnit.class_unit_id = classUnit.class_unit.id;
				});
				vm.classUnits = data.classUnits;
			})

			// по модулям
			// получаем все уроки по классам (lessons)
			// группируем по module_id
			// берём первый урок и берём его модуль
			// вы восхитительны
			.then(() => {
				const moduleGroups = _.filter(groups, {period_type: "module"});
				let moduleClassUnitsIds;
				const lessonQueries = [];
				if (moduleGroups.length > 0) {
					moduleClassUnitsIds = _.uniq(_.flatten(_.map(moduleGroups, "class_unit_ids")));
					_.forEach(moduleClassUnitsIds, (id) => {
						lessonQueries.push(
							bigQuery.queue.getList($$lessonScheduleItem, {
								from: academicYear.begin_date,
								to: academicYear.end_date,
								academic_year_id: academicYear.id,
								class_unit_id: id
							}, 75)
						);
					});

					return $q.all(lessonQueries);
				}

				return [];
			})

			.then((data) => {
				vm.lessons = _.flatten(data);
				if (data.length > 0) {
					return getLessonPlans(_.uniq(_.map(data, "lesson_plan_id")));
				}

				return [];
			})

			.then((lessonPlans) => {
				const $lessonPlans = _.filter(lessonPlans, {teacher_id: teacherId});
				if ($lessonPlans.length > 0) {
					setModulesFromLessons($lessonPlans, vm.lessons);
				}

				return [];
			})

			// по учебным периодам
			// берём все группы
			// забираем все periods_schedule_id
			// получаем периоды для них
			// вы непревзойдённы
			.then(() => {
				const periodQueries = [];
				const studyGroup = _.filter(groups, {period_type: "study"});
				const studySubgroup = _.filter(vm.subgroups, {period_type: "study"});
				const periodsScheduleIds = _.uniq(
					_.compact(
						_.map(studyGroup, "periods_schedule_id")
							.concat(_.map(studySubgroup, "periods_schedule_id"))
					)
				);


				if (periodsScheduleIds.length > 0) {
					_.forEach(periodsScheduleIds, (id) => periodQueries.push($$periodSchedule.bindOne(id)));

					return $q.all(periodQueries);
				}

				return [];
			})

			.then((studyPeriods) => {
				_.forEach(studyPeriods, (studyPeriod) => {
					studyPeriod.periods = _.sortBy(_.filter(studyPeriod.periods, {is_vacation: false}), "begin_date");
				});

				_.forEach(groups, (group) => {
					if (group.period_type === "study") {
						if (group.is_metagroup) {
							const subgroup = _.find(vm.subgroups, {id: group.subgroup_ids[0]});
							if (subgroup) {
								group.period_schedule = _.cloneDeep(_.find(studyPeriods, {id: subgroup.periods_schedule_id}));
							} else {
								console.error("Не найдена подгруппа для метагруппы с id = " + group.id);
							}
						} else {
							group.period_schedule = _.cloneDeep(_.find(studyPeriods, {id: group.periods_schedule_id}));
						}
					}
				});
			})

			.then(() => {
				// по аттестационным периодам
				// смотри учебные периоды
				// повторяй тоже самое для attestation_periods_schedule_id
				// наслаждайся жизнью, КРАСАВЧИК
				// const attestationPeriodQueries = [];

				const attestationGroup = _.filter(groups, {period_type: "attestation"});
				const attestationSubGroup = _.filter(vm.subgroups, {period_type: "attestation"});
				const attestationGroupPeriodIds = _.map(attestationGroup, "attestation_periods_schedule_id");
				const attestationSubGroupPeriodIds = _.map(attestationSubGroup, "attestation_periods_schedule_id");

				const attestationPeriodsIds = _.uniq(attestationGroupPeriodIds.concat(attestationSubGroupPeriodIds));

				if (attestationPeriodsIds.length > 0) {
					return $$attestationPeriodsSchedule.collection.getList({
						academic_year_id: academicYear.id,
						ids: attestationPeriodsIds.join(",")
					});
				}

				return [];
			})

			.then((attestationPeriods) => {
				if (attestationPeriods.length > 0) {
					_.forEach(attestationPeriods, (attestationPeriod) => {
						attestationPeriod.periods = _.sortBy(attestationPeriod.periods, "begin_date");
					});
				}
				_.forEach(groups, (group) => {
					if (group.period_type === "attestation") {
						if (group.is_metagroup) {
							const subgroup = _.find(vm.subgroups, {id: group.subgroup_ids[0]});
							if (subgroup) {
								group.period_schedule = _.cloneDeep(_.find(attestationPeriods, {id: subgroup.attestation_periods_schedule_id}));
							} else {
								console.error("Не найдена подгруппа для метагруппы с id = " + group.id);
							}
						} else {
							group.period_schedule = _.cloneDeep(_.find(attestationPeriods, {id: group.attestation_periods_schedule_id}));
						}
					}
				});
			})

			.finally(() => deferred.resolve(groups));

		return deferred.promise;

		/**
		 * загрузка поурочных планов для группировки модулей
		 * @param LessonPlanIds
		 * @returns {*}
		 */
		function getLessonPlans(LessonPlanIds) {
			const request = [];
			//
			_.forEach(LessonPlanIds, (id) => {
				// TODO встречал уроки с null вместо lesson_plan_id
				if (id) {
					request.push($$lessonPlan.bindOne(id).get());
				}
			});

			//
			return $q.all(request);
		}


		/**
		 *
		 * @param lessonPlans
		 * @param rawLessons
		 */
		function setModulesFromLessons(lessonPlans, rawLessons) {
			function getLessonDateObject(lesson) {
				return moment()
					.year(lesson.date[0])
					.month(lesson.date[1] - 1)
					.date(lesson.date[2])
					.hour(lesson.time[0])
					.minutes(lesson.time[1])
					.seconds(lesson.time[2]);
			}


			/**
			 *
			 * @param group
			 * @returns {*}
			 */
			function makeMagic(group) {
				const modulesFromLessons = [];
				const periods = [];

				let groupLessons = _.filter(rawLessons, {group_id: group.id});
				groupLessons = _.sortBy(groupLessons, "formatted_date");

				_.forEach(_.groupBy(groupLessons, "module_id"), (lessons) => {
					const module = {
						id: lessons[0].module_id,
						name: lessons[0].module_name,
						lesson_plan_id: lessons[0].lesson_plan_id,
						begin_date: getLessonDateObject(lessons[0]).startOf("day").format("YYYY-MM-DD"),
						end_date: getLessonDateObject(lessons[lessons.length - 1]).endOf("day").format("YYYY-MM-DD")
					};
					modulesFromLessons.push(module);
				});

				const $lessonPlans = _.sortBy(lessonPlans, "name");

				//
				_.forEach($lessonPlans, (lessonPlan) => {
					lessonPlan.modules = _.filter(modulesFromLessons, {lesson_plan_id: lessonPlan.id});
					if (lessonPlan.modules.length > 0) {
						lessonPlan.periods = _.sortBy(lessonPlan.modules, "begin_date");
						periods.push(lessonPlan);
					}
				});

				let groupModules;
				_.forEach(periods, (module) => {
					// У группы может быть несколько учителей, поэтому мы смотрим по конкретному
					if (group.subject_id === module.subject_id
						&& teacherId === module.teacher_id
						&& group.class_level_id === module.class_level_id
					) {
						groupModules = _.cloneDeep(module);

						return false;
					}
				});

				return groupModules;
			}

			_.forEach(rawLessons, (lesson) => {
				lesson.formatted_date = moment({
					y: lesson.date[0],
					M: lesson.date[1] - 1,
					d: lesson.date[2]
				}).format("YYYY-MM-DD");
			});

			_.forEach(groups, (group) => {
				if (group.period_type === "module") {
					if (group.is_metagroup) {
						const subgroup = _.find(vm.subgroups, {id: group.subgroup_ids[0]});

						if (subgroup) {
							group.period_schedule = makeMagic(subgroup);
						} else {
							console.error("Не найдена подгруппа для метагруппы с id = " + group.id);
						}
					} else {
						group.period_schedule = makeMagic(group);
					}
				}
			});
		}


		/**
		 * проверка типа формы аттестации для группы
		 * @param group {{}}
		 * @returns {*}
		 */
		function getGroupAttestationType(group) {
			if (group.is_metagroup) {
				const subgroup = _.find(vm.subgroups, {id: group.subgroup_ids[0]});

				return getGroupAttestationType(subgroup);
			}

			// Аттестация по учебным периодам
			if (group.is_final_by_periods === true
				&& group.periods_schedule_id !== null
				&& group.attestation_periods_schedule_id === null) {
				return "study";
			}
			// Аттестация по аттестационным периодам
			if (group.attestation_periods_schedule_id !== null) {
				return "attestation";
			}
			//  Аттестация по модулям
			// if (group.is_final_by_periods === false && group.attestation_periods_schedule_id === null) {
			//    return 'module'
			// }

			return "module";
		}
	};
}

