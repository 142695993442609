import template from "./pageTitle.html";

import "./style.scss";

angular
	.module("ezd.common.ui")
	.component("pageTitle", {
		transclude: true,
		template,
		bindings: {
			showMenuButton: "<"
		},
		controller: Controller
	});


/**
 *
 * @constructor
 */
function Controller() {
	const vm = this;

	vm.showMenuButton = typeof vm.showMenuButton === "undefined";
}