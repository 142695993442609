import template from "./LeftNav.html";

import "./left-nav.scss";

angular
	.module("ezd.common.ui")
	.component("leftNav", {
		template,
		controller: LeftNavController
	});

/**
 *
 * @param $rootScope
 * @param $route
 * @param $location
 * @param $cookies
 * @param $$academicYear
 * @constructor
 */
LeftNavController.$inject = ["$rootScope", "$route", "$location", "$cookies", "$$academicYear"];
function LeftNavController($rootScope, $route, $location, $cookies, $$academicYear) {
	const vm = this;

	// $rootScope.closedMenu = true;

	$rootScope.fixedRightNav = false;

	$rootScope.closeMenu = closeMenu;
	$rootScope.openMenu = openMenu;
	$rootScope.setFixedRightNav = setFixedRightNav;
	$rootScope.toggleMenu = toggleMenu;

	vm.year = "";

	vm.icons = {
		desktop: require("images/icons/desktop.svg"),
		calendar: require("images/icons/calendar.svg"),
		feed: require("images/icons/feed.svg"),
		news: require("images/icons/news.svg"),
		messages: require("images/icons/ic_email_white_24px.svg"),
		statistic: require("images/icons/statistics.svg"),

		tests: require("./components/icons/ic_access_alarm_white_24px.svg"),
		nonattendance: require("./components/icons/ic_people_outline_white_24px.svg"),
		replacements: require("./components/icons/ic_group_add_white_24px.svg"),
		schedule: require("./components/icons/ic_notifications_white_24px.svg")
	};


	vm.goToFeed = goToFeed;
	vm.goToDesktop = goToDesktop;


	init();


	function init() {
		vm.curDateEl = angular.element(document.querySelector("current-date div"));
		//  TODO: figure this out
		//  vm.splitPath = ($route.current.originalPath).split("/");
		$$academicYear
			.getSelected()
			.then((year) => {
				vm.year = year.name;
			});
	}


	/**
	 *
	 */
	function goToFeed() {
		$location.path("/feed");
	}


	/**
	 *
	 */
	function goToDesktop() {
		$rootScope.switcher = {
			events: true,
			news: true,
			messages: true
		};
		$cookies.remove("top_switcher");
		$cookies.putObject("top_switcher", $rootScope.switcher);

		$location.path("/");
	}


	function closeMenu() {
		$rootScope.closedMenu = true;
	}


	function openMenu() {
		$rootScope.closedMenu = false;
		$rootScope.closedMenuTimeOut = false;
	}


	function toggleMenu() {
		if ($rootScope.closedMenu) {
			$rootScope.openMenu();
		} else {
			$rootScope.closeMenu();
		}
	}

	function setFixedRightNav(rightNavMenuTop, rightNavMenuHeight, referenceTop, screenvisibleHeight) {
		$rootScope.fixedRightNav = ((referenceTop < -56) && (rightNavMenuHeight + 60 < screenvisibleHeight));
	}
}

