

angular
	.module("ezd.backend")
	.service("$$groupComment", groupCommentService);


/**
 *
 * @param Jersey
 * @param $q
 */
groupCommentService.$inject = ["Jersey", "$q"];
function groupCommentService(Jersey, $q) {
	const url = "/api/group_comments";
	const vm = this;


	/**
	 * DECLARATIONS
	 */
	vm.getGlobalString = getPageString.bind(undefined, '$global');
	vm.bindOne = bindOne;
	vm.create = create;
	vm.setViewed = setViewed;
	vm.getList = getList;


	/**
	 * VARIABLES
	 */

	vm.collection = Jersey.all(url);
	vm.collection.getList = (query) => Jersey.all(url).getList(query);

	/**
	 * IMPLEMENTATION
	 */


	/**
	 *
	 * @param id
	 * @returns {{get}|*}
	 */
	function bindOne(id) {
		return Jersey.one(url, id);
	}


	/**
	 *
	 * @param query
	 */
	function getList(query) {
		return vm.collection.getList(query);
	}


	/**
	 *
	 * @param comment
	 * @param groupIds
	 * @returns {*}
	 */
	function create(comment, groupIds) {
		const teacherIds = [];
		if (comment.teacherId) {
			teacherIds.push(comment.teacherId);
		} else {
			return $q.reject({message: vm.getGlobalString('teacher_is_not_selected')});
		}

		if (!comment.comment) {
			return $q.reject({message: "Комментарий не может быть пуст!"});
		}

		return vm.collection.post({
			comment: comment.comment,
			available_for: teacherIds,
			group_ids: groupIds,
			important: comment.important
		})
			.then((data) => {
				if (!data || (data.errCode && data.errCode !== 0)) {
					throw data;
				}
				return data;
			});
	}

	/**
	 *
	 * @param comment
	 * @returns {*}
	 */
	function setViewed(comment) {
		if (comment) {
			return vm.bindOne(comment.id)
				.customPUT({is_viewed: true}, undefined, undefined, undefined)
				.then((data) => {
					if (!data || (data.errCode && data.errCode !== 0)) {
						throw data;
					}

					return data;
				});
		}

		return $q((resolve, reject) => reject("No comment passed"));
	}
}
