

angular
	.module("ezd.backend")
	.factory("$$roles", roles);


/**
 *
 * @param Jersey
 * @returns {Object|*}
 */
roles.$inject = ["Jersey"];
function roles(Jersey) {
	return Jersey.service("/api/roles");
}
