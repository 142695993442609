import {EzdTabsComponent} from "./tabs/ezd-tabs.component";


/**
 * ezd-tabs-item - все перечисленные в примере атрибуты
 * В ezd-tabs-content-item необходимо класть компонент, либо шаблон, который не связан ни с одним контроллером
 *
 * Пример использования:
 *
 * <ezd-tabs model="$ctrl.activeTab" on-change="$ctrl.test()">
 *
 *   <ezd-tabs-head>
 *     <ezd-tabs-item ng-value="1">Tab 01</ezd-tabs-item>
 *     <ezd-tabs-item ng-value="2" ng-disabled="true" disabled-text="Вкладка неактивна.">Tab 02</ezd-tabs-item>
 *    </ezd-tabs-head>
 *
 *    <ezd-tabs-content>
 *      <ezd-tabs-content-item ng-value="1">
 *        <some-component-01></some-component-01>
 *      </ezd-tabs-content-item>
 *      <ezd-tabs-content-item ng-value="2">
 *        <some-component-02></some-component-02>
 *      </ezd-tabs-content-item>
 *    </ezd-tabs-content>
 *
 * </ezd-tabs>
 *
 */
angular
	.module("ezd.common.ui")
	.component(EzdTabsComponent.selector, EzdTabsComponent);
