import {Component} from "app/decorators/index";
import styles from "./ezd-multiple-select.local.scss";
import find from "lodash/fp/find";


/**
 * @desc Возможные атрибутыб помимо параметров компонента:
 *  hide-check-all - убрать кнопку "Выбрать все".
 *  zero-is-all - пишет "Выбрано: все", когда ничего не выбрано. Нужно в некоторых компонентах
 *  placeholder - плейсхолдер в инпуте поиска
 *  widthSelect - длина инпута
 *  heightList - высота списка
 */
@Component({
	selector: "ezdMultipleSelect",
	bindings: {
		model: "<",
		checkedField: "@",
		disabledField: "@",
		nameField: "@",
		name: "@",
		onItemToggle: "&?", // необязательный, т.к. если не передадим, то будет функция и фильтрация будет работать не правильно
		onSearchTextChange: "&?", // необязательный, т.к. если не передадим, то будет функция и фильтрация будет работать не правильно
		onModelChange: "&?",	// удобней использовать вместо onItemToggle
		required: "<",
		placeholder: "@"
	}
})
export class EzdMultipleSelectComponent {

	styles = styles;
	model = [];
	searchInputId = this.$generateRandomString();

	/**
	 *
	 * @param element
	 * @param attrs
	 */
	static template(element, attrs) {
		const itemTemplate = element.find("ezd-element-template").detach();
		const itemHtml = itemTemplate.length ? itemTemplate.html() : "{{ item[$ctrl.nameField] }}";

		const listStyle = attrs.heightList ? {height: attrs.heightList} : "";
		const selectStyle = attrs.widthSelect ? {width: attrs.widthSelect} : "";
		const zeroIsAll = _.has(attrs, "zeroIsAll");
		const showCheckAll = !_.has(attrs, "hideCheckAll");
		const allCheckBoxPadding = showCheckAll ? 'padding-top: 50px;' : '';

		let html = `
			<div class="${styles["ezd-multiple-select"]}" layout="column" style="width: ${selectStyle.width}">
				<div class="${styles["ezd-multiple-select__search"]}">
					<label for="{{::$ctrl.searchInputId}}"></label>
					<input id="{{::$ctrl.searchInputId}}"
						   type="text"
						   class="ezd-input"
						   placeholder="{{::$ctrl.placeholder}}"
						   ng-model="searchText"
						   ng-change="$ctrl.filterItems(searchText)"
					/>
				</div>
		`;

		if (showCheckAll) {
			html += `
				<div ng-if="$ctrl.filteredItems.length" class="${styles["check-all"]}">
					<ezd-checkbox model="$ctrl.allSelected" on-change="$ctrl.toggleSelectAll($event)">
						{{ $ctrl.allSelected ? "Снять выделение" : "Выбрать все"}}
					</ezd-checkbox>
					<span style="float: right;">Выбрано: {{(${zeroIsAll} && !$ctrl.selectedItemsCount) ? 'все' : $ctrl.selectedItemsCount}}</span>
				</div>
			`;
		}

		html += `
			<div class="${styles["ezd-multiple-select__list-wrapper"]}"
						style="height: ${listStyle.height}; ${allCheckBoxPadding}">
					<md-virtual-repeat-container
							class="${styles["ezd-multiple-select__list"]}">
							<div md-virtual-repeat="item in $ctrl.filteredItems"
									 md-item-size="26"
									 class="${styles["repeated-item"]}">
								<ezd-checkbox
												on-change="$ctrl.changeItem(item, $event)"
												ng-disabled="item[$ctrl.disabledField]"
												model="item[$ctrl.checkedField]">
									${itemHtml}
								</ezd-checkbox>
							</div>
					</md-virtual-repeat-container>
				</div>
			</div>

			<input class="invisible-form-ctrl"
				 name="{{$ctrl.name}}"
				 type="checkbox"
				 ng-required="$ctrl.required"
				 ng-model="$ctrl.hasSelectedItems">
		`;

		return html;
	}

	static $inject = ['$attrs'];

	constructor($attrs) {

		// setup binding defaults
		if (!this.onItemToggle)
			this.onItemToggle = () => {};
		if (!this.onModelChange)
			this.onModelChange = () => {};
		if (!Boolean(this.checkedField)) {
			this.checkedField = "checked";
		}
		if (!Boolean(this.disabledField)) {
			this.disabledField = "disabled";
		}
		if (!Boolean(this.nameField)) {
			this.nameField = "name";
		}
		// if (!Boolean(this.placeholder)) {
		// 	this.placeholder = $attrs.placeholder;
		// }
	}

	$onInit() {
	}

	$onChanges(changes) {
		if (changes.model && !(changes.model.isFirstChange() && changes.model.currentValue === undefined)) {
			if (_.isArray(changes.model.currentValue)) {
				this.model = changes.model.currentValue;
			} else {
				this.model = [];
			}
			this.filterItems();
		}
	}

	toggleSelectAll(value) {
		_.forEach(this.filteredItems, (item) => {
			item[this.checkedField] = value;
		});

		this.$updateSelectedItemsFlag();
	}


	changeItem(item, value) {
		const index = _.findIndex(this.model, {id: item.id});

		if (index > -1) {
			this.model[index][this.checkedField] = value;
			this.onItemToggle({$event: this.model[index]});
		}

		this.$updateSelectedItemsFlag();
	}


	filterItems(searchText) {
		if (!searchText) {
			this.filteredItems = [...this.model];
			this.$updateSelectedItemsFlag();

			return;
		}

		if (this.onSearchTextChange) {
			this.filteredItems = this.onSearchTextChange({$event: searchText});
		} else {
			this.filteredItems = [...this.model];
			const pattern = new RegExp(searchText, "i");

			this.filteredItems = _.filter(this.model, (item) => {
				return pattern.test(item[this.nameField]);
			});
		}

		this.$updateSelectedItemsFlag();
	}

	$updateSelectedItemsFlag() {
		this.allSelected = _.every(this.filteredItems, {[this.checkedField]: true});
		this.selectedItemsCount = _.filter(this.model, {[this.checkedField]: true}).length;
		this.hasSelectedItems = Boolean(this.selectedItemsCount);
		this.onModelChange({$event: this.model});
	}

	/**
	 *
	 * @returns {string}
	 */
	$generateRandomString() {
		return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
	}

}
