import {Component} from "app/decorators/index";
import {ImageCropper} from "./image-cropper";
import template from "./image-cropper.html";
import styles from "./image-cropper.local.scss";


@Component({
	selector: "imageCropper",
	template,
	bindings: {
		model: "<"
	}
})
export class ImageCropperComponent {
	static $inject = ["$element", "$mdDialog"];

	/**
	 * CONSTRUCTOR
	 */
	constructor($element, $mdDialog) {
		this.services = {$element, $mdDialog};
		this.styles = styles;

		this.cropper = null;
	}


	/**
	 * ONINIT
	 */
	$onInit() {
		const {$element} = this.services;
		const canvas = $element.find("canvas")[0];

		$element.css("display", "block");
		canvas.style.cssText = "display: block; cursor: move;";
		canvas.setAttribute("width", "380");
		canvas.setAttribute("height", "380");

		this.cropper = new ImageCropper(canvas, this.model);
	}


	save() {
		const {$mdDialog} = this.services;
		const croppedImage = this.cropper.getCropedImage();

		$mdDialog.hide({
			croppedImage
		});
	}

	cancel() {
		const {$mdDialog} = this.services;

		$mdDialog.cancel();
	}
}