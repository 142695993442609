angular
	.module("ezd.backend")
	.factory("AEMarks", aeMarks);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeMarks.$inject = ["Core"];
function aeMarks(Core) {
	return Core.service("/api/ae_marks");
}

