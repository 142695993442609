

angular
	.module("ezd.common.ui")
	.directive("mainContent", ["$rootScope", function ($rootScope) {
		return {
			link(scope, element) {
				$rootScope.contentHeight = element[0].offsetHeight;
			}
		};
	}]);