import template from "./impersonate.html";
import {users} from "images/icons/index";


angular
	.module("ezd.common.ui")
	.directive("impersonate", impersonateDirective);


/**
 *
 * @returns {{controller: impersonateController, controllerAs: string, scope: {teacher: string}, template}}
 */
function impersonateDirective() {
	return {
		controller: impersonateController,
		controllerAs: "impersonate",
		scope: {
			teacher: "="
		},
		template
	};
}


/**
 *
 * @param flashAlert
 * @param $$profile
 * @param $$user
 * @param Impersonation
 * @param $cookies
 */
function impersonateController(flashAlert, $$profile, $$user, Impersonation, $cookies) {
	const impersonate = this;

	impersonate.icons = {users};

	impersonate.impersonateProfile = impersonateProfile;

	/**
	 *
	 * @param profile
	 */
	function impersonateProfile(profile) {
		Impersonation.to(profile)
			.then((response) => {
				$$profile.castTypesToRolesForProfile(response);
			});
	}
}

impersonateController.$inject = ["flashAlert", "$$profile", "$$user", "Impersonation", "$cookies"];
