import {Module} from "app/decorators/module";

import "angular-cookies";
import "restangular";
import "angular-md5";

import {EventsTypesConst} from "components/events/events-types.const";
import EventService from "./components/services/Event.service";
import EzdGuardsService from "./components/services/ezd-guards/EzdGuards.service";
import {GuardsConst} from "./components/services/ezd-guards/guards.const";
import {RightsConst} from "./components/factories/Rights.const";

@Module({
	name: "ezd.backend",
	imports: ["ngCookies", "restangular", "angular-md5"],
	providers: [EventService, EventsTypesConst, EzdGuardsService, GuardsConst, RightsConst]
})
export default class EzdBackendModule {}

/**
 *
 * @param r
 */
function requireAll(r) { r.keys().map(r); }
requireAll(require.context("./components", true, /\.js$/));

