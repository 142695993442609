import "./popup.css";


angular
	.module("ezd.common.ui")
	.directive("popup", Directive);


function Directive() {
	return {
		transclude: true,
		scope: {
			pShow: "=",
			pOutside: "&?",
			pClose: "&?",
			pTitle: "=",
			pW: "=?",
			pH: "=?"
		},
		template: `
		<div ng-if="pShow" class="popup-container">
		<div style="margin: auto" action-on-click-outside="pOutside()">
			<div class="b-pop-up__wrap" style="width: {{ pW || 'auto' }}; height: {{ pH || 'auto' }};">
				<div class="b-pop-up__head">
					<div class="b-pop-up__title">
						<span>{{ pTitle }}</span>
					</div>
					<div class="b-pop_up_close">
						<i ng-click="pClose ? pClose() : pOutside()" class="i_icon_close-big"></i>
					</div>
				</div>

				<div class="b-pop-up__body">
					<ng-transclude></ng-transclude>
				</div>
			</div>
		</div>
		</div>
		</div>`
	};
}
