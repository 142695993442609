angular
	.module("ezd.backend")
	.factory("AEControlForms", AEControlForms);

/**
 *
 * @param Core
 * @returns {*|Object}
 * @constructor
 */
AEControlForms.$inject = ["Core"];
function AEControlForms(Core) {
	const s = Core.service("/api/ae_control_forms");

	s.getTemplate = getTemplate;


	/**
	 *
	 * @returns {{short_name: string, name: string, weight: number, is_exam: boolean, education_level_id: null, subject_id: null, school_id: null,
	 *     grade_system_id: null, origin_control_form_id: null}}
	 */
	function getTemplate() {
		return {
			short_name: "",
			name: "",
			weight: 1,
			is_exam: false,
			education_level_id: null,
			subject_id: null,
			school_id: null,
			grade_system_id: null,
			origin_control_form_id: null
		};
	}


	return s;
}

