

angular
	.module("ezd.common.ui")
	.directive("ezdDatepicker", ezdDatePicker);

/**
 * @desc По факту параметры абсолютно аналогичны md-datepicker
 *
 * @param $compile
 * @returns {{restrict: string, controller: EzdDatePickerController, controllerAs: string, link: link}}
 */
function ezdDatePicker($compile) {
	return {
		restrict: "E",
		controller: EzdDatePickerController,
		controllerAs: "ctrl",
		link
	};

	/**
	 *
	 * @param scope
	 * @param el
	 * @param attrs
	 */
	function link(scope, el, attrs) {
		let attributes = "";
		_.forOwn(attrs, (v, k) => {
			if (k[0] !== "$") {
				attributes += `${attrs.$attr[k]}="${v}"`;
			}
		});

		const id = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
		const datePicker = `
				<md-datepicker
							style="margin-right: 0; padding-right: 0;" 
							id="${id}"
							${attributes} md-hide-icons="all" class="ezd-datepicker-input" 
							md-is-open="ctrl.isOpen['${id}']" ng-click="ctrl.focus($event)"
				></md-datepicker>
			`;

		el.replaceWith($compile(datePicker)(scope));
	}
}
ezdDatePicker.$inject = ["$compile"];


/**
 * @constructor
 */
function EzdDatePickerController() {
	this.isOpen = {};
}

EzdDatePickerController.prototype.focus = function ($event) {
	const id = $event.target.parentNode.parentNode.id;
	this.isOpen[id] = true;
};
