

angular
	.module("ezd.common.ui")
	.directive("passCheckOverlay", directive);

/**
 *
 * @returns {{controller: controller, controllerAs: string, template: *}}
 */
function directive() {
	return {
		controller,
		controllerAs: "pc",
		template: require("./PassCheckOverlay.html")
	};
}


/**
 *
 * @param $$user
 * @param Sessions
 * @param Credentials
 * @param flashAlert
 */
controller.$inject = ["$$user", "Sessions", "Credentials", "flashAlert"];
function controller($$user, Sessions, Credentials, flashAlert) {
	const vm = this;
	let userId = null;

	// defaults
	vm.showOverlay = false;

	// bindings
	vm.cancel = cancel;
	vm.update = update;


	// init
	$$user
		.getCurrentUser()
		.then((res) => {
			userId = res.id;

			if (res.password_change_required) { vm.showOverlay = Boolean(res.password_change_required); }

			// костыль для демонстрации школе (ereminaeg_test) возможности смены пароля
			if (!_.some(res.profiles, {school_id: 40})) {
				vm.showOverlay = false;
			}
		});


	function cancel() {
		if (confirm("Вы уверены что хотите выйти из ЭПОС.Школа?")) {
			Sessions.drop();
		}
	}


	/**
	 * Сохраняет изменения в ЛК
	 */
	function update() {
		if (!vm.password || vm.password === "") {
			flashAlert.error("пароль не может быть пустым");

			return;
		}

		if (vm.password !== vm.password_again) {
			flashAlert.error("пароли в полях не совпадают");

			return;
		}

		Credentials
			.one(userId)
			.all("/credentials")
			.post({password: vm.password})
			.then(() => {
				flashAlert.success("данные успешно обновлены");
				vm.showOverlay = false;
			});
	}
}
