import flatten from "lodash/fp/flatten";
import map from "lodash/fp/map";


angular
	.module("ezd.backend")
	.service("$$group", $$group);

$$group.$inject = ["Jersey", "$q", "$$academicYear"];

/**
 *
 * @param Jersey
 * @param $q
 * @param $$academicYear
 */
function $$group(Jersey, $q, $$academicYear) {
	const url = "/api/groups";
	const vm = this;

	vm.url = url;
	vm.list = [];
	vm.collection = Jersey.all(url);
	vm.collection.getList = getList;
	vm.filter = {};
	vm.teacher_groups = {};


	vm.bindOne = bindOne;
	vm.getConnectedFor = getConnectedFor;
	vm.getList = getList;
	vm.getCurrentGroups = getCurrentGroups;


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Jersey.one(url, id);
	}


	/**
	 *
	 * @param query
	 * @returns {*}
	 */
	function getList(query) {
		return Jersey.all(url).getList(query);
	}


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function getConnectedFor(id) {
		return vm.bindOne(id).all("connection").getList();
	}

	/**
	 * Возвращает promise групп учителя
	 * @returns {*}
	 */
	async function getCurrentGroups(profile) {
		if (_.isEmpty(vm.teacher_groups)) {
			if (profile.assigned_group_ids.length !== 0) {
				const year = await $$academicYear.getSelected();

				vm.teacher_groups = await vm.getList({
					group_ids: profile.assigned_group_ids.join(","),
					academic_year_id: year.id
				});

				return vm.teacher_groups;
			}
		}

		return vm.teacher_groups;
	}
}
