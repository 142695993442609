const url = "/api/buildings";
const imgUrl = "/api/image_building";

class BuildingsService {
	static $inject = ["Core"];

	/**
	 * CONSTRUCTOR
	 * @param Core
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.list = [];
		this.collection = Core.all(url);
		this.collection.getList = (query) => Core.all(url).getList(query);
	}

	/**
	 * @param id
	 * @returns {*}
	 */
	bindOne(id) {
		return this.CoreService.one(url, id);
	}


	/**
	 * Получаем картинку здания по id здания
	 * @param id
	 */
	getImage(id) {
		return this.CoreService.one(`${imgUrl}?building_id=${id}`).get();
	}


	/**
	 * Получаем список картинок зданий по запросу
	 * @param query
	 */
	getImagesList(query) {
		return this.CoreService.all(imgUrl).getList(query);
	}
}

angular
	.module("ezd.backend")
	.service("$$building", BuildingsService);