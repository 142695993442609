import {Component} from "app/decorators/index";

import template from "./ezd-paginator.html";
import styles from "./ezd-paginator.local.scss";
import left from "./arrow_left.svg";
import right from "./arrow_right.svg";
import _isUndefined from "lodash/fp/isUndefined";

@Component({
	selector: "ezdPaginator",
	template,
	bindings: {
		totalPages: "<",
		page: "=",
		perPage: "=",
		onChange: "&",
		onChangePerPage: "&"
	}
})
export class EzdPaginatorComponent {
	static $inject = ["$timeout", "$attrs"];

	/**
	 *
	 */
	constructor($timeout, $attrs) {
		this.services = {$timeout, $attrs};
		this.styles = styles;
		this.icons = {left, right};
	}

	/**
	 * */
	$onInit() {
		this.perPageSwitchData = [
			{
				perPage: 15
			},
			{
				perPage: 30
			},
			{
				perPage: 45
			},
			{
				perPage: 60
			}
		];

		this.perPage = this.perPage || 30;
	}

	/**
	 *
	 */
	$onChanges(changes) {
		if (changes.totalPages) {
			this.totalPages = changes.totalPages.currentValue;
			this.pagesToShow = _.range(1, _.int(this.totalPages) + 1);
		}
		if (changes.perPage) {
			this.perPage = changes.perPage.currentValue;
		}
		this.buildPages();
	}

	/**
	 *
	 */
	switchPerPage($event) {
		const {$timeout, $attrs} = this.services;

		this.perPage = $event;
		this.page = 1;
		this.buildPages();
		$timeout(() => {
			if (!_isUndefined($attrs.onChangePerPage)) {
				return this.onChangePerPage();
			}

			this.onChange();
		}, 0);
	}

	/**
	 *
	 */
	setPage(page) {
		if (page !== "...") {
			const {$timeout} = this.services;

			this.page = page;
			this.buildPages();
			$timeout(() => this.onChange(), 0);
		}
	}

	/**
	 *
	 */
	nextPage() {
		const {$timeout} = this.services;

		if (this.page < this.totalPages) {
			this.page += 1;
			this.buildPages();
			$timeout(() => this.onChange(), 0);
		}
	}

	/**
	 *
	 */
	prevPage() {
		const {$timeout} = this.services;

		if (this.page > 1) {
			this.page -= 1;
			this.buildPages();
			$timeout(() => this.onChange(), 0);
		}
	}

	/**
	 *
	 */
	firstPage() {
		const {$timeout} = this.services;

		this.page = 1;
		this.buildPages();
		$timeout(() => this.onChange(), 0);
	}

	/**
	 *
	 */
	lastPage() {
		const {$timeout} = this.services;

		this.page = _.int(this.totalPages || 1);
		this.buildPages();
		$timeout(() => this.onChange(), 0);
	}

	/**
	 *
	 */
	buildPages() {
		if (this.totalPages > 9) {
			if (this.page < 5) {
				this.pagesToShow = [..._.range(1, 9), "...", _.int(this.totalPages)];
			} else if (this.page > this.totalPages - 5) {
				this.pagesToShow = [1, "...", ..._.range(_.int(this.totalPages) - 7, _.int(this.totalPages) + 1)];
			} else if (this.page >= 5 && this.page <= this.totalPages - 5) {
				this.pagesToShow = [1, "...", ..._.range(this.page - 3, this.page + 4), "...", _.int(this.totalPages)];
			}
		}
	}
}
