import {Component} from "app/decorators/index";
import styles from "./ezd-checkbox.local.scss";
import template from "./ezd-checkbox.html";

/**
 * type {String}: "simple", "thin", "thin_dis"
 *       default: "simple"
 */
@Component({
	selector: "ezdCheckbox",
	template,
	bindings: {
		type: "@",
		model: "=",
		small: "@",
		onChange: "&"
	},
	transclude: true
})
export class EzdCheckboxComponent {
	static $inject = ["$scope", "$timeout", "$element"];

	types = {
		SIMPLE: "simple",
		THIN: "thin",
		THIN_DISABLE: "thin_dis"
	};

	/**
	 *
	 * @param $scope
	 * @param $timeout
	 * @param $element
	 */
	constructor($scope, $timeout, $element) {
		this.services = {$scope, $timeout, $element};

		this.styles = styles;

		this.checkBoxId = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now() + "yeah" + $scope.$id;

		this.watcher = $scope.$watch(() => $element.attr("disabled"), (newVal) => {
			this.disabled = newVal;
		});
	}


	/**
	 * OnInit
	 */
	$onInit() {
		const {SIMPLE, THIN, THIN_DISABLE} = this.types;

		switch (this.type) {
			case SIMPLE:
				break;
			case THIN:
				break;
			case THIN_DISABLE:
				break;
			default:
				this.type = SIMPLE;
		}
	}


	/**
	 *
	 */
	$onChange() {
		const {$timeout} = this.services;

		$timeout(() => {
			this.onChange({$event: this.model});
		}, 0);
	}


	/**
	 *
	 */
	$onDestroy() {
		this.watcher();
	}
}
