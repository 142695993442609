angular
	.module("ezd.backend")
	.factory("ECParallelCurricula", ECParallelCurricula);


/**
 *
 * @param EC
 * @returns {*|Object}
 */
ECParallelCurricula.$inject = ["EC"];

function ECParallelCurricula(EC) {
	return EC.service("/api/ec_parallel_curricula");
}

