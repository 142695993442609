angular
	.module("ezd.backend")
	.factory("$$attendance", attendances);


/**
 *
 * @param Core
 * @param $q
 * @returns {*|Object}
 */
attendances.$inject = ["Core", "$q"];
function attendances(Core, $q) {
	const url = "/api/attendances";
	const Service = Core.service(url);
	const ABSENCE_REASONS = {
		OUT: 2,
		FREE: 1
	};

	/**
	 *
	 * DECLARATION
	 *
	 */

	Service.getTemplate = getTemplate;
	Service.getString = getString;
	Service.removeAttendance = removeAttendance;
	Service.getPreschoolTemplate = getPreschoolTemplate;
	Service.getStudentNonattendances = getStudentNonattendances;
	Service.getList = (query) => Core.all(url).getList(query);


	/**
	 *
	 * RUN
	 *
	 */
	return Service;


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 * Получаем шаблон для пропуска
	 * @param scheduleLessonId
	 * @param studentId
	 * @returns {{absence_reason: null, schedule_lesson_id: *, student_profile_id: *}}
	 */
	function getTemplate(scheduleLessonId, studentId) {
		return {
			absence_reason: null,
			schedule_lesson_id: scheduleLessonId,
			student_profile_id: studentId
		};
	}

	function getPreschoolTemplate(date, studentId) {
		return {
			date,
			student_profile_id: studentId,
			absence_reason_id: ABSENCE_REASONS.OUT
		};
	}


	/**
	 * Получаем название пропуска по типу причины отсутствия
	 * @param attendance
	 * @returns {string}
	 */
	function getString(attendance) {
		return (attendance.absence_reason_id !== null)
			? ((attendance.absence_reason_id === ABSENCE_REASONS.OUT) ? "н" : "о") : null;
	}

	/**
	 * Удаление пропуска
	 * @param attendance
	 * @returns {*}
	 */
	function removeAttendance(attendance) {
		return Service.one(attendance.id).customDELETE();
	}

	function getStudentNonattendances(id) {
		if (id) {
			return Service.getList({student_profile_ids: id});
		}

		return $q((rs, rj) => rj());
	}
}
