angular
	.module("ezd.backend")
	.factory("StudyBooks", studyBooks);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
function studyBooks(Core) {
	const Service = Core.service("/api/study_books");

	Service.Deleted = Core.service("/api/study_books/delete");

	return Service;
}

studyBooks.$inject = ["Core"];
