import {Component} from "app/decorators/index";


import template from "./ezd-date-range-picker.html";
import styles from "./ezd-date-range-picker.local.scss";

/**
 * Компонент для выбора дат
 * Использование:
 *
 * <ezd-date-range-picker
 *      date-start="$ctrl.dateStart"
 *      date-end="$ctrl.dateEnd"
 *      min-date="$ctrl.minDate"
 *      max-date="$ctrl.maxDate"
 *      on-change="$ctrl.onChange()"
 *      week-mode="true"
 * ></ezd-date-range-picker>
 */


@Component({
	selector: "ezdDateRangePicker",
	template,
	bindings: {
		dateStart: "=?",
		dateEnd: "=?",
		minDate: "<?",
		maxDate: "<?",
		onChange: "&",
		format: "@?",
		weekMode: "<?", // выбирает неделю целиком
		weekHideDelay: "<"
	}
})
export class EzdDateRangePicker {
	static $inject = ["$timeout", "$mdMenu", "$scope"];

	inputModel = null;
	styles = styles;


	/**
	 *
	 */
	constructor($timeout, $mdMenu, $scope) {
		this.services = {$timeout, $mdMenu, $scope};
	}

	/**
	 *
	 */
	$onInit() {
		const {$scope} = this.services;
		const fieldsToCheck = ["dateStart", "dateEnd", "minDate", "maxDate"];

		_.forEach(fieldsToCheck, (field) => {
			if (_.isNull(this[field]) || _.isUndefined(this[field])) {
				return false;
			}

			if (this[field] && !(this[field] instanceof Date) && !moment.isMoment(this[field])) {
				throw new Error(`Компонент <ezd-date-range-picker> ждет в параметре "${field}" либо объект Date либо Moment! Передано ${this[field]}`);
			} else if (this[field] instanceof Date) {
				this[field] = moment(this[field]);
			}
		});

		this.format = this.format || "DD MMM";
		$scope.$watch(() => this.dateStart, this.createInputModel.bind(this));
		this.createInputModel();
	}


	/**
	 *
	 * @param changes
	 */
	$onChanges(changes) {
		if (changes.minDate || changes.maxDate) {
			const fieldsToCheck = ["minDate", "maxDate"];

			_.forEach(fieldsToCheck, (field) => {
				if (_.isString(this[field])) {
					const val = moment(this[field]);
					console.log(this[field], val);

					if (val.isValid()) {
						this[field] = val;
					}
				}
			});
		}
	}


	/**
	 *
	 */
	createInputModel() {
		this.inputModel = "";
		if (this.dateStart) {
			this.inputModel = this.dateStart.format(this.format);
		}

		if (this.dateEnd) {
			this.inputModel += " - " + this.dateEnd.format(this.format);
		}
	}


	/**
	 *
	 * @param $event
	 */
	changeDates($event) {
		const {$mdMenu, $timeout} = this.services;

		if (this.weekMode) {
			$timeout(() => {
				$mdMenu.hide();
			}, this.weekHideDelay ? this.weekHideDelay : 0);
		}

		this.dateStart = $event.dateStart;
		this.dateEnd = $event.dateEnd;
		this.createInputModel();

		$timeout(() => {
			this.onChange();
		}, 0);
	}
}
