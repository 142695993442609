

angular
	.module("ezd.backend")
	.service("$$reportFilters", reportFilters);


/**
 *
 * @param REP
 */
reportFilters.$inject = ["REP"];
function reportFilters(REP) {
	/**
	 * GET /api/periods_filter?academic_year_id={ACADEMIC_YEAR_ID}&class_unit_id={CLASS_UNIT_ID}&student_profile_id={STUDENT_PROFILE_ID}
	 * {ACADEMIC_YEAR_ID} - учебный год
	 * {CLASS_UNIT_ID} - id класса
	 * {STUDENT_PROFILE_ID} - id ученика (необязательный параметр)
	 */
	const vm = this;

	vm.collection = REP.all("/api/periods_filter");

	vm.get = get;


	function get(params) {
		return REP.one("api", "periods_filter").get(params);
	}
}
