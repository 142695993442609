import {Component} from "app/decorators/index";
import protocolCheck from "custom-protocol-detection";
import template from "./ezd-run-scripts.html";
import device from "tools/device";
import "./ezd-run-scripts.scss";
import {eyeInCircle, playInCircle} from "./icons";

import _find from "lodash/fp/find";
import EomUrl from "components/common/eomUrl";


@Component({
	selector: "ezdRunScripts",
	template,
	bindings: {
		script: "<",
		profile: "<",
		groupId: "<",
		isPlan: "<?"
	},
	controllerAs: "ezdRunScriptCtrl"
})
export class EzdRunScriptsComponent {
	static $inject = ["$cookies", "EzdAlertDialog", "$mdDialog"];

	/**
	 * Constructor
	 */
	constructor($cookies, EzdAlertDialog, $mdDialog) {
		this.services = {$cookies, EzdAlertDialog, $mdDialog};
		this.icons = {playInCircle, eyeInCircle};
		this.deeplinkWrapper = "https://uchebnik.page.link/?&apn=ru.mos.mes&amv=350&efr=1&ibi=ru.mos.etbook&imv=2.1.123&link=";
		this.eomurl = EomUrl.getBaseUrl();
	}


	/**
	 *
	 * @param changes
	 */
	$onChanges(changes) {
		if (!Boolean(changes)) {
			return;
		}

		if (Boolean(this.script) && Boolean(this.profile)) {
			this.watchUrl = this.$getScriptWatchUrl();
			this.runUrl = this.$getScriptRunUrl();
		}
	}


	/**
	 * Строим ссылку на сценарий
	 * @return {string}
	 */
	$getScriptWatchUrl() {
		let viewUrl = `${this.eomurl}/composer3/lesson/${this.script.material_id ? this.script.material_id : this.script.id}/view`;
		const type = this.script.material_type ? this.script.material_type : this.script.type;
		const materialId = this.script.material_id ? this.script.material_id : this.script.id;

		switch (type) {
			case "TestSpecification":
				viewUrl = `${this.eomurl}/exam/test/${this.isPlan ? "selftest/spec" : "training_task"}/${materialId}`;
				break;
			case "GameApp":
				if (this.script.current_version != null) {
					viewUrl = `${this.eomurl}${this.script.current_version.entry_url}`;
				} else {
					viewUrl = `${this.eomurl}`;
					if (this.isPlan) {
						viewUrl += `/catalogue/material_view/game_apps/${materialId}${this.script.sharing_key ? "?sharing_key=" + this.script.sharing_key : ""}`;
					} else {
						viewUrl += this.script.sharing_key
							? `/material/app/${materialId}?sharing_key=${this.script.sharing_key}`
							: `/catalogue/material_view/game_apps/${materialId}`;
					}
				}
				break;
			case "LessonTemplate":
				viewUrl = `${this.eomurl}/composer3/lesson/${materialId}/view`;
				break;
			case "ComposedDocument":
				viewUrl = `${this.eomurl}/composer3/document/${materialId}/view`;
				break;
			case "ExternalMaterial":
				viewUrl = `${this.eomurl}/extern/api/${this.isPlan ? "selftest" : "homework"}?materialId=${materialId}`;
				break;
		}

		const groupRole = _.includes(["parent", "student"], this.profile.type) ? this.profile.type : "employee";
		const url = [
			`${this.eomurl}/eom-acl/oidc/login?`,
			`groupRole=${groupRole}`,
			`&redirectUrl=${viewUrl}`
		].join("");

		return device.mobile() ? this.deeplinkWrapper + url : url;
	}


	/**
	 * Строим ссылку на запуск сценария
	 * @return {string}
	 */
	$getScriptRunUrl() {
		const {$cookies} = this.services;

		return [
			"lesson:{",
			`profile_id:${this.profile.id},`,
			`auth_token:'${$cookies.get("auth_token")}',`,
			`lesson_template_id:${this.script.material_id ? this.script.material_id : this.script.id},`,
			`group_id:${this.groupId}`,
			"}"
		].join("");
	}


	/**
	 * Отменяем всплытие события при клике на кнопку просмотра сценария
	 */
	viewScript(event) {
		event.stopPropagation();
	}


	/**
	 * Запускаем сценарий
	 */
	runScript(event) {
		event.preventDefault();
		event.stopPropagation();
		let elem = event.target;

		if (elem.nodeName === "MD-ICON") {
			elem = elem.nextElementSibling ? elem.nextElementSibling : elem.parentNode;
		} else if (elem.nodeName === "BUTTON") {
			const foundElem = _find({nodeName: "A"})(elem.children);
			elem = foundElem;
		}

		let href = elem.href;

		if (elem.href.indexOf("unsafe:") > -1) {
			href = elem.href.split("unsafe:")[1];
		}

		protocolCheck(href, this.$openDownloadPopup.bind(this));
	}


	/**
	 * Открываем попап скачивания приложения
	 * @returns {Promise.<void>}
	 */
	async $openDownloadPopup() {
		const groupRole = _.includes(["parent", "student"], this.profile.type) ? this.profile.type : "employee";
		const url = [
			`${this.eomurl}/eom-acl/oidc/login?`,
			`groupRole=${groupRole}`,
			`&redirectUrl=${this.eomurl}/apps`
		].join("");

		const {EzdAlertDialog} = this.services;
		const params = {
			text: "Для запуска сценария необходим IB клиент.<br />Если на вашем устройстве не установлен IB клиент, нажмите \"Скачать\" и повторите попытку.<br />Если IB клиент установлен, закройте окно.",
			title: "Запуск сценария",
			type: "error",
			yesButtonText: "Скачать",
			buttonText: "Закрыть",
			isIBClient: true,
			downloadLink: url
		};

		try {
			await EzdAlertDialog.error(params);
		} catch (err) {
			console.error(err);
		}
	}
}
