import {eyeClosed, eyeOpened} from "images/icons/index";
import styles from "./ezd-password-input.local.scss";

angular
	.module("ezd.common.ui")
	.directive("ezdPasswordInput", EzdPasswordInput);


/**
 *
 * @returns {{restrict: string, link: EzdPasswordInputLink}}
 * @constructor
 */
function EzdPasswordInput() {
	return {
		restrict: "A",
		link: EzdPasswordInputLink,
		scope: true,
		replace: true,
		template
	};


	/**
	 *
	 * @param element
	 * @param attrs
	 * @returns {string}
	 */
	function template(element, attrs) {
		delete attrs.ezdPasswordInput;
		element.removeAttr("ezd-password-input");
		element.css("margin-right", "-43px");

		return `
			<div layout="row" layout-align="start center">
				<div class='${styles["wrapper__password-input"]}'>
					${element[0].outerHTML}
					<md-icon class="${styles.icon}" ng-click="togglePassVisible()" md-svg-src="{{passVisible ? eyeOpened : eyeClosed}}"></md-icon>
					<md-tooltip ng-if="!passVisible">Показать пароль</md-tooltip>
					<md-tooltip  ng-if="passVisible">Скрыть пароль</md-tooltip>
				</div>
			</div>
		`;
	}


	/**
	 *
	 * @constructor
	 */
	function EzdPasswordInputLink(scope, element) {
		scope.passVisible = false;
		scope.eyeClosed = eyeClosed;
		scope.eyeOpened = eyeOpened;
		scope.togglePassVisible = togglePassVisible;
		element.removeClass("ezd-input");

		let input = element.find("input");

		/**
		 *
		 */
		function togglePassVisible() {
			input = input[0] ? input : element.find("input");
			if (scope.passVisible) {
				scope.passVisible = false;
				input.attr("type", "password");
			} else {
				scope.passVisible = true;
				input.attr("type", "text");
			}
		}
	}
}

EzdPasswordInput.$inject = [];
