import _flow from "lodash/fp/flow";
import _flatten from "lodash/fp/flatten";
import _map from "lodash/fp/map";
import _getOr from "lodash/fp/getOr";

angular
	.module("ezd.backend")
	.factory("AEHomeWorks", aeHomeWorks);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeHomeWorks.$inject = ["Core"];
function aeHomeWorks(Core) {
	const api = Core.service("/api/ae_homeworks");

	api.getAttachments = getAttachments;

	return api;
}

/**
 *
 */
function getAttachments(homework) {
	const attachments = _flow(
		_map((hw) => {
			return _getOr([], "attachments", hw);
		}),
		_flatten,
	)(homework.ae_homework_entries);

	return _map((item) => {
		item.homework_id = _.get(homework, "id");

		return $getAttachmentItem(item);
	})(attachments);
}

/**
 */
function $getAttachmentItem(item) {
	if (!item) {
		return null;
	}

	const fileName = _.get(item, "file_file_name", "");
	const simpleName = fileName.split(".");
	const extension = (simpleName.length > 1) ? simpleName.pop() : "";

	item.name = simpleName.join(".");
	item.extension = extension ? `.${extension}` : "";
	item.url = item.path;

	return item;
}
