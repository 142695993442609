const URL = "/api/external_materials";


class ExternalMaterialsService {
	static name = "ExternalMaterials";
	static $inject = ["CMS"];

	/**
	 * constructor
	 * @param {object} CMS service
	 */
	constructor(CMS) {
		this.collection = CMS.all(URL);
		this.fullApi = CMS.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true)).all(URL);

		this.classLevelsApi = this.collection.all("class_levels");
		this.subjectsApi = this.collection.all("subjects");
	}

	/**
	 * */
	getPage(params) {
		return this.fullApi.getList(params);
	}
}

angular
	.module("ezd.backend")
	.service(ExternalMaterialsService.name, ExternalMaterialsService);
