import {Component} from "app/decorators/index";
import template from "./ezd-square-radio-buttons.html";
import styles from "./ezd-square-radio-buttons.local.scss";

@Component({
	selector: "ezdSquareRadioButtons",
	template,
	bindings: {
		collection: "<",
		onChange: "&",
		defaultType: "@"
	}
})

export class EzdSquareRadioButtonsComponent {
	constructor() {
		this.styles = styles;
		this.selectedType = this.defaultType;
	}

	/**
	 *
	 * @param item
	 */
	selectItem(item) {
		this.selectedType = item.type;
		this.onChange();
	}
}
