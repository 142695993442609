import arityOf2 from "./arityOf2";
import isArray from "lodash/fp/isArray";
import intersection from "lodash/fp/intersection";
import isEmpty from "lodash/fp/isEmpty";

export default arityOf2((base, subject) => {
	if (!isArray(base)) { throw new Error("intersects: base must be an Array"); }
	if (!isArray(subject)) { throw new Error("intersects: subject must be an Array"); }

	return !isEmpty(intersection(base, subject));
});
