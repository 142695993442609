angular
	.module("ezd.backend")
	.service("$$eCards", Service);

Service.$inject = ["Core"];

/**
 * @param Core
 */
function Service(Core) {
	/**
	 *
	 * CONSTANTS AND VARIABLES
	 *
	 */

	const vm = this;
	vm.url = "/api/eCards";

	vm.list = [];
	vm.collection = Core.all(vm.url);
	vm.collection.getList = (query) => Core.all(vm.url).getList(query);
	vm.selectedProfile = {};

	/**
	 *
	 * DECLARATION
	 *
	 */
	vm.getList = getList;
	vm.create = create;
	vm.save = save;
	vm.remove = remove;

	/**
	 *
	 * IMPLEMENTATION
	 *
	 */
	function getList(options) {
		return vm.collection.getList(options);
	}

	/**
	 * Создание привязки ученика к карте
	 * @param eCard
	 * @returns {*}
	 */
	function create(eCard) {
		if (!eCard.restangularized) {
			Core.restangularizeElement(null, eCard, vm.url);
		}

		return eCard.post();
	}

	/**
	 * Сохранение карты
	 * @param eCard
	 * @returns {*}
	 */
	function save(eCard) {
		Core.restangularizeElement(null, eCard, vm.url);

		return eCard.put();
	}

	/**
	 * Удаление карты
	 * @param eCard
	 * @returns {*}
	 */
	function remove(eCard) {
		if (!eCard.restangularized) {
			Core.restangularizeElement(null, eCard, vm.url);
		}

		return eCard.remove();
	}
}
