

angular
	.module("ezd.backend")
	.factory("ECFields", ECFields);


/**
 *
 * @param EC
 * @returns {*|Object}
 */
ECFields.$inject = ["EC"];
function ECFields(EC) {
	return EC.service("/api/ec_fields");
}

