angular
	.module("ezd.common.ui")
	.directive("inlineEdit", Directive);

/**
 *
 * @returns {{
 * 		restrict: string, scope: {value: string, inputClass: string, spanClass: string, disabl: string, onBlur: string}, template: string, link:
 *     (function(*, *))}}
 * @constructor
 */
function Directive() {
	return {
		restrict: "E",
		scope: {
			value: "=",
			inputClass: "@",
			spanClass: "@",
			disabl: "=",
			onBlur: "&",
			onChange: "&"
		},
		template: "<span ng-class=\"{black: disabl}\" ng-click=\"disabl ? disabl : edit()\" class=\"{{spanClass}}\" ng-bind=\"value\"></span>"
		+ "<input ng-blur=\"blur(); onBlur()\" ng-disabled=\"disabl\" ng-change=\"value = change(value); onChange()\" class=\"{{inputClass}}\" ng-model=\"value\"/>",
		link($scope, element) {
			// This directive should have a set class so we can style it.
			element.addClass("edit-in-place");

			$scope.editing = false;

			$scope.change = (val) => {
				let newValue = _.int(val);
				if (_.isNaN(newValue)) {
					newValue = 0;
				} else {
					newValue = newValue > 99 ? 99 : newValue;
				}

				return newValue;
			};

			// ng-click handler to activate edit-in-place
			$scope.edit = function () {
				$scope.editing = true;
				// We control display through a class on the directive itself. See the CSS.
				element.addClass("active");

				// And we must focus the element.
				// `angular.element()` provides a chainable array, like jQuery so to access a native DOM function,
				// we have to reference the first element in the array.
				element.children()[1].focus();
				element.children()[1].select();
			};

			// When we leave the input, we're done editing.
			$scope.blur = function () {
				$scope.editing = false;
				element.removeClass("active");
			};
		}
	};
}

