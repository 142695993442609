import {Component} from "app/decorators/index";


import styles from "./pagination.local.scss";
import template from "./pagination.html";
import {chevronLeft, chevronRight} from "images/icons/index";


@Component({
	selector: "pagination",
	template,
	bindings: {
		page: "=",
		pages: "<",
		prevPage: "&prev",
		nextPage: "&next",
		editClb: "&"
	}
})
class PaginationComponent {
	/**
	 *
	 */
	constructor() {
		this.styles = styles;
		this.icons = {chevronLeft, chevronRight};
	}


	/**
	 *
	 * @returns {number}
	 */
	getPagesCount() {
		return !_.int(this.pages) ? 1 : this.pages;
	}


	/**
	 * Преобразовывает результат работы EditInPlace в целочисленное число
	 * @param value значение для преобразования в номер
	 */
	numEditParser(value) {
		const newNum = _.int(value);

		if (_.isFinite(newNum)) {
			if (newNum >= 1 && newNum <= this.pages) {
				return newNum;
			} else if (newNum > this.pages) {
				return this.pages;
			}
		}

		return 1;
	}
}


angular
	.module("ezd.common.ui")
	.component(PaginationComponent.selector, PaginationComponent);
