import template from "./ProcessingPersonalData.html";


angular
	.module("ezd.common.ui")
	.directive("processingPersonalData", processingPersonalDataDirective);


/**
 *
 * @returns {{controller: processingPersonalDataController, controllerAs: string, template}}
 */
function processingPersonalDataDirective() {
	return {
		controller: processingPersonalDataController,
		controllerAs: "processingData",
		template
	};
}


/**
 *
 * @param $$profile
 * @param Sessions
 */
processingPersonalDataController.$inject = ["$$profile", "Sessions"];
function processingPersonalDataController($$profile, Sessions) {
	const vm = this;


	// defaults
	vm.showOverlay = false;

	vm.logout = logout;
	vm.saveAgree = saveAgree;


	// init
	$$profile.getCurrentProfile()
		.then((profile) => {
			vm.profile = profile;

			if (!profile.agree_pers_data) {
				vm.showOverlay = true;
			}
		});

	function logout() {
		Sessions.drop();
	}

	function saveAgree() {
		vm.profile.agree_pers_data = true;
		$$profile.bindOne(vm.profile.id).customPUT(vm.profile);
		vm.showOverlay = false;
	}
}
