import template from "./logo.html";
import * as icons from "images/icons/index";

angular
	.module("ezd.common.ui")
	.component("logo", LogoComponent());

/**
 *
 * @returns {{bindings: {textColor: string}, template, controller: LogoController}}
 * @constructor
 */
function LogoComponent() {
	return {
		bindings: {
			textColor: "@"
		},
		template,
		controller: LogoController
	};
}


LogoController.$inject = ["$$profile"];

/**
 *
 * @constructor
 */
function LogoController($$profile) {
	const vm = this;
	vm.getPageString = getPageString.bind(undefined, "f00f25ba2612124e9c8b079c6c066151");
	vm.styles = require("./oezd-logo.local.scss");
	vm.logo = icons[this.getPageString("logo_icon")];
	vm.isMedal = false;
	vm.isObserver = false;
	$$profile.getCurrentProfile()
		.then((data) => {
			vm.currentProfile = data;
			if (_.intersects(vm.currentProfile.roles, "medal_commission")) {
				vm.isMedal = true;
			}
			if (vm.currentProfile.type === "observer") {
				vm.isObserver = true;
			}

			vm.ibAdmin = _.intersects(["admin", "admin_security"], vm.currentProfile.roles);

			vm.defaultPageUrl = getDefaultPage();
		});


	/**
	 * Получить ссылку на экран по умолчанию
	 */
	function getDefaultPage() {
		if (!vm.ibAdmin) {
			return "/desktop";
		}

		return `${location.origin}/administrator`;
	}
}
