import template from "./wys-insert-table-modal.html";
import {Component} from "app/decorators/component";

import styles from "./wys-insert-table-modal.local.scss";

@Component({
	selector: "wysInsertTableModal",
	template
})
export class WysInsertTableModalComponent {
	static $inject = ["$mdDialog"];

	constructor($mdDialog) {
		this.services = {$mdDialog};
		this.trCount = 1;
		this.tdCount = 1;
		this.html = "";
		this.styles = styles;
		console.log(this.styles);
	}

	$buildTable() {
		let tableTemplate = "<table>";

		_.times(this.trCount, () => {
			tableTemplate += "<tr>";
			_.times(this.tdCount, () => {
				tableTemplate += "<td></td>";
			});
			tableTemplate += "</tr>";
		});

		tableTemplate += "</table>";

		this.html = tableTemplate;
	}

	insertTable() {
		const {$mdDialog} = this.services;
		this.$buildTable();
		$mdDialog.hide(this.html);
	}

	cancel() {
		const {$mdDialog} = this.services;
		$mdDialog.cancel();
	}
}
