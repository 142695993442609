import EzdAlertDialogTemplate from "./ezd-alert-dialog.html";
import {EzdAlertDialogController} from "./ezd-alert-dialog.controller";


angular
	.module("ezd.common.ui")
	.service("EzdAlertDialog", EzdAlertDialog);

EzdAlertDialog.$inject = ["$mdDialog"];

/**
 *
 */
function EzdAlertDialog($mdDialog) {
	const vm = this;
	vm.success = success;
	vm.error = error;
	vm.warn = warn;
	vm.info = info;
	vm.confirm = confirm;
	vm.openDialog = openDialog;

	/**
	 *
	 */
	function success(params) {
		const $params = {
			text: "",
			title: "Успех!",
			type: "success",
			buttonText: "Ок"
		};

		_.assign($params, params);

		return vm.openDialog($params);
	}

	/**
	 *
	 */
	function error(params) {
		const $params = {
			text: "",
			title: "Ошибка!",
			type: "error",
			buttonText: "Ок"
		};

		_.assign($params, params);

		return vm.openDialog($params);
	}

	/**
	 *
	 */
	function warn(params) {
		const $params = {
			text: "",
			title: "Внимание!",
			type: "warn",
			buttonText: "Ок"
		};

		_.assign($params, params);

		return vm.openDialog($params);
	}

	/**
	 *
	 */
	function info(params) {
		const $params = {
			text: "",
			title: "Информация",
			type: "info",
			buttonText: "Ок"
		};

		_.assign($params, params);

		return vm.openDialog($params);
	}

	/**
	 *
	 */
	function confirm(params) {
		const $params = {
			text: "",
			title: "Подтверждение",
			type: null,
			yesButtonText: "Да",
			buttonText: "Нет"
		};

		_.assign($params, params);

		return vm.openDialog($params);
	}

	/**
	 *
	 */
	function openDialog(params) {
		return $mdDialog.show({
			params,
			parent: params.parent || angular.element(document.body),
			clickOutsideToClose: true,
			template: EzdAlertDialogTemplate,
			controller: EzdAlertDialogController,
			controllerAs: "$ctrl"
		});
	}
}
