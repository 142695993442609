import {Component} from "app/decorators/index";
import template from "./assignments-table-header.template.html";
import {chevronLeft, chevronRight} from "images/icons/index";
import styles from "./assignments-table-header.local.scss";

@Component({
	selector: "assignmentsTableHeader",
	template,
	transclude: true,
	bindings: {
		contentData: "<",
		tableTitle: "@",
		subTitle: "@"
	}
})
export class AssignmentsTableHeaderComponent {
	static $inject = ["$element", "$timeout", "$window", "$rootScope"];
	styles = styles;
	icons = {chevronLeft, chevronRight};
	offset = 0;
	OFFSET_VALUE = 100;

	contentWidth = 0;
	wrapperWidth = 0;
	isContentWidthCalculated = false;

	/**
	 * */
	constructor($element, $timeout, $window, $rootScope) {
		this.services = {$element, $timeout, $window, $rootScope};
	}

	/**
	 * */
	$onInit() {
		const {$window} = this.services;
		this.getDOMElementInfo();

		$window.addEventListener("resize", () => {
			this.getWrapperWidth();
		});
	}

	/**
	 * */
	$onChanges(changes) {
		if (changes.contentData) {
			this.offset = 0;
			this.isContentWidthCalculated = false;
		}
	}


	/**
	 * Сдвинуть таблицу
	 * */
	shift(isLeft) {
		const {$rootScope} = this.services;
		if (!this.isContentWidthCalculated) {
			this.getContentWidth();
		}

		if ((this.wrapperWidth > this.contentWidth)
			|| (isLeft ? this.offset >= 0 : this.offset < (this.wrapperWidth - this.contentWidth))) {
			return;
		}

		const direction = isLeft ? 1 : -1;
		this.offset += direction * this.OFFSET_VALUE;
		$rootScope.$broadcast("assignmentsTableHeader:shift", this.offset);
	}

	/**
	 * Получение информации о размерах контентной части компонента
	 * */
	getDOMElementInfo() {
		const {$element, $timeout} = this.services;

		$timeout(() => {
			this.contentNode = $element[0].firstElementChild.querySelector("#content");
			this.wrapperNode = this.contentNode.parentNode;
			this.wrapperWidth = parseInt(getComputedStyle(this.wrapperNode).width, 10);
			this.getWrapperWidth();
		});
	}

	/**
	 * Получение ширины контентной части компонента
	 * */
	getContentWidth() {
		this.contentWidth = parseInt(getComputedStyle(this.contentNode).width, 10);
	}

	/**
	 * Получение ширины враппера
	 * */
	getWrapperWidth() {
		const {$timeout, $rootScope} = this.services;
		$timeout(() => {
			this.wrapperWidth = parseInt(getComputedStyle(this.wrapperNode).width, 10);
			this.offset = 0;
			$rootScope.$broadcast("assignmentsTableHeader:shift", this.offset);
		});
	}
}
