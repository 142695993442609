

angular
	.module("ezd.backend")
	.service("$$lessonScheduleItem", lessonScheduleItemService);


/**
 *
 * @param Jersey
 */
lessonScheduleItemService.$inject = ["Jersey"];
function lessonScheduleItemService(Jersey) {
	const url = "/api/schedule_items";
	const vm = this;

	// методы для запроса данных с сервера
	vm.bindOne = bindOne;
	vm.getList = getList;


	function bindOne(id) {
		return Jersey.one(url, id);
	}

	function getList(params) {
		return Jersey.all(url).getList(params);
	}
}