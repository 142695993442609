import {Component} from "app/decorators/index";


import template from "./ezd-range-date-picker-calendar.html";
import {chevronLeft, chevronRight} from "images/icons/index";
import styles from "./ezd-range-date-picker-calendar.local.scss";

@Component({
	selector: "ezdDateRangePickerCalendar",
	bindings: {
		dateStart: "<",
		dateEnd: "<",
		minDate: "<",
		maxDate: "<",
		onChange: "&",
		weekMode: "<"
	},
	template
})
export class EzdDateRangePickerCalendar {
	icons = {chevronLeft, chevronRight};
	styles = styles;

	weekDays = [
		{number: 1, name: "пн"},
		{number: 2, name: "вт"},
		{number: 3, name: "ср"},
		{number: 4, name: "чт"},
		{number: 5, name: "пт"},
		{number: 6, name: "сб"},
		{number: 0, name: "вс"}
	];

	weeks = [];


	/**
	 *
	 */
	$onInit() {
		this.now = moment.isMoment(this.dateStart) ? this.dateStart.clone() : moment();

		if (this.minDate && moment.isMoment(this.minDate) && this.minDate.isAfter(this.now)) {
			this.now = this.minDate.clone();
		}

		if (this.maxDate && moment.isMoment(this.maxDate) && this.maxDate.isBefore(this.now)) {
			this.now = this.maxDate.clone();
		}

		this.makeMonth();
	}


	/**
	 *
	 */
	nextMonth() {
		if (this.maxDate && this.now.isSame(this.maxDate, "month")) {
			return;
		}
		this.now.add(1, "month");
		this.makeMonth();
	}


	/**
	 *
	 */
	prevMonth() {
		if (this.minDate && this.now.isSame(this.minDate, "month")) {
			return;
		}
		this.now.subtract(1, "month");
		this.makeMonth();
	}

	/**
	 *
	 */
	makeMonth() {
		const start = this.now.clone().startOf("month");
		const end = this.now.clone().endOf("month");
		const startDay = start.day();
		this.weeks = [];

		if (startDay > 1 || startDay === 0) {
			const times = startDay === 0 ? 6 : (startDay - 1);
			start.subtract(times, "d");
		}

		while (start.isBefore(end, "d")) {
			const week = [];
			_.times(7, () => {
				week.push({
					date: start.date(),
					isCurrentMonth: start.month() === this.now.month(),
					isToday: start.isSame(moment(), "d"),
					moment: start.clone()
				});
				start.add(1, "d");
			});
			this.weeks.push(week);
		}
	}

	/**
	 *
	 * @param day
	 * @returns {string}
	 */
	dayClasses(day) {
		let start = false;
		let end = false;
		let active = false;
		if (this.dateStart && this.dateEnd) {
			start = day.moment.isSame(this.dateStart, "d");
			end = day.moment.isSame(this.dateEnd, "d");
			active = start || end || day.moment.isBetween(this.dateStart, this.dateEnd, "[]");
		}

		const singleActive = this.dateStart && !this.dateEnd && day.moment.isSame(this.dateStart, "d");

		return _.chain([
			this.styles.day,
			!day.isCurrentMonth && this.styles["not-current-month"],
			day.isToday && this.styles.today,
			(active || start || end) && this.styles.active,
			start && this.styles.start,
			end && this.styles.end,
			singleActive && this.styles["single-active"]
		]).compact().value().join(" ");
	}


	/**
	 *
	 * @param day
	 */
	selectDay(day) {
		if (this.weekMode) {
			this.dateStart = moment(day.moment).startOf("week");
			this.dateEnd = moment(day.moment).endOf("week");
		} else {
			if (!this.dateStart) {
				this.dateStart = day.moment.clone();

				return;
			}

			if (day.moment.isSameOrBefore(this.dateStart)) {
				this.dateEnd = this.dateStart.clone();
				this.dateStart = day.moment.clone();
			}

			if (day.moment.isSameOrAfter(this.dateStart)) {
				this.dateEnd = day.moment.clone();
			}
		}

		this.onChange({
			$event: {
				dateStart: this.dateStart,
				dateEnd: this.dateEnd
			}
		});
	}


	/**
	 *
	 */
	reset() {
		this.dateStart = null;
		this.dateEnd = null;
		this.onChange({
			$event: {
				dateStart: this.dateStart,
				dateEnd: this.dateEnd
			}
		});
	}
}
