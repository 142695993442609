angular
	.module("ezd.backend")
	.service("$$column", $$column);

/**
 *
 * @param Core
 */
$$column.$inject = ["Jersey"];

function $$column(Jersey) {
	const url = "/api/lesson_column";
	const vm = this;

	vm.list = [];
	vm.collection = Jersey.all(url);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Jersey.one(url, id);
}
