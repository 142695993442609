angular
	.module("ezd.backend")
	.factory("AECourseLessonPlan", AECourseLessonPlan);


AECourseLessonPlan.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {Object|*}
 */
function AECourseLessonPlan(Core) {
	const Service = Core.service("/api/ae_course_lesson_plan");

	Service.newLessonPlan = newLessonPlan;
	Service.editLessonName = editLessonName;

	return Service;

	/**
	 *
	 */
	function newLessonPlan() {
		const lessonPlan = {
			name: "",
			program_is_new: true,
			modules: [
				{
					name: "",
					ordinal: 1,
					topics: [
						{
							name: "Тема 1",
							ordinal: 1,
							lessons: []
						}
					]
				}
			]
		};

		return lessonPlan;
	}

	function editLessonName(lessonId, lesson) {
		return Service.one("lessons").all(lessonId).customPUT(lesson);
	}
}
