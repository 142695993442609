

angular
	.module("ezd.common.ui")
	.directive("goBackButtonDirective", Directive);

/**
 *
 * @param $rootScope
 * @param $$history
 * @constructor
 */
Controller.$inject = ["$rootScope", "$$history"];
function Controller($rootScope, $$history) {
	const vm = this;
	let historyLength = 0;

	vm.goBack = $$history.goBack;
	vm.isShowingBackButton = isShowingBackButton;


	init();


	function init() {
		$$history.getHistory()
			.then((localHistory) => {
				localHistory = localHistory || [];
				historyLength = localHistory.length;
			});
	}


	function isShowingBackButton() {
		if ($rootScope.refName === "my_calendar") {
			return false;
		}

		return (historyLength > 0);
	}
}

/**
 *
 * @returns {{scope: {}, template: string, controller: Controller, controllerAs: string}}
 * @constructor
 */
function Directive() {
	const directive = {
		scope: {},
		template: "<div class=\"b-calendar\" ng-click=\"goBackCtrl.goBack()\" ng-show=\"goBackCtrl.isShowingBackButton()\" >"
		+ "<div class=\"b-row_back\">"
		+ "<i class=\"i-icon-big_row-back\"></i>"
		+ "</div>"
		+ "</div>",
		controller: Controller,
		controllerAs: "goBackCtrl"
	};

	return directive;
}