angular
	.module("ezd.backend")
	.factory("GenresWrapper", GenresWrapper)
	.factory("Genres", Genres);

GenresWrapper.$inject = ["$cookies", "Restangular", "Interceptors"];

/**
 * @desc Создаем новый инстанс Restangular с конфигом для модуля Genres
 * @param $cookies
 * @param Restangular
 * @param Interceptors
 * @returns {*}
 * @constructor
 */
function GenresWrapper($cookies, Restangular, Interceptors) {
	return Restangular.withConfig((RestangularConfigurer) => {
		const headers = {Accept: "application/vnd.cms-v4+json"};

		if ($cookies.get("auth_token")) {
			headers["Auth-Token"] = $cookies.get("auth_token");
		}

		if ($cookies.get("profile_id")) {
			headers["Profile-Id"] = $cookies.get("profile_id");
			RestangularConfigurer.setDefaultRequestParams({pid: $cookies.get("profile_id")});
		}

		if ($cookies.get("user_id")) {
			headers["User-Id"] = $cookies.get("user_id");
		}

		RestangularConfigurer.setDefaultHeaders(headers);
		RestangularConfigurer.setBaseUrl("/api");
		RestangularConfigurer.setErrorInterceptor(Interceptors.error);
		RestangularConfigurer.addFullRequestInterceptor(Interceptors.fullRequest);
		RestangularConfigurer.addResponseInterceptor(Interceptors.responseInterceptor);
	});
}

Genres.$inject = ["GenresWrapper"];

/**
 *
 * @param $GenresWrapper
 * @constructor
 */
function Genres($GenresWrapper) {
	const vm = this;

	vm.getGenres = getGenres;

	/**
	 *
	 */
	function getGenres() {
		return $GenresWrapper.service("/genres/ezd").getList();
	}

	return vm;
}
