angular
	.module("ezd.backend")
	.factory("AELessons", aeLessons);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeLessons.$inject = ["Core"];
function aeLessons(Core) {
	const s = Core.service("/api/ae_lessons");

	s.LESSON_TYPES = {
		LESSON: "LESSON",
		MASS_ACTIVITY: "MASS_ACTIVITY",
		ATTESTATION: "ATTESTATION"
	};

	return s;
}
