import angular from "angular";
import styles from "./ezd-range.local.scss";


/**
 * Использовать как атрибутную директиву для директивы md-slider
 * ***********  ВНИМАНИЕ!!!  **************
 * Имеется зависимость от компонента editInPlace
 * @ToDo В дальнейшем избавиться от этой внешней зависимости
 */
ezdRange.$inject = ["$compile", "$parse"];

export function ezdRange($compile, $parse) {
	return {
		restrict: "A",
		require: "ngModel",
		link: ezdRangeLink
	};

	/**
	 * LinkFunction для нашей директивы
	 * @param scope
	 * @param elem
	 * @param attrs
	 * @param ngModelCtrl
	 */
	function ezdRangeLink(scope, elem, attrs, ngModelCtrl) {
		const min = $parse(attrs.min)(scope);
		const max = $parse(attrs.max)(scope);
		const wideTypeClassName = attrs.rangeInputType && attrs.rangeInputType === "wide" ? styles["wide-type"] : "";

		const editInPlaceTemplate = `
			<edit-in-place
				value="inputValue"
				cb="changeValue()"
				parser="editInPlaceParser(value)"
				class="${styles["thumb-input"]} ${wideTypeClassName}"
				ng-show="isShowed"
				>
			</edit-in-place>
		`;
		const scopeForInput = createScope(scope, attrs, ngModelCtrl);
		const editInPlace = $compile(editInPlaceTemplate)(scopeForInput);

		ngModelCtrl.$parsers.unshift((viewValue) => {
			scopeForInput.inputValue = _.int(viewValue);
			scopeForInput.isShowed = checkValue(viewValue, min, max);

			return _.int(viewValue);
		});

		ngModelCtrl.$formatters.unshift((modelValue) => {
			scopeForInput.inputValue = _.int(modelValue) || scopeForInput.inputValue || 0;
			scopeForInput.isShowed = checkValue(modelValue, min, max);

			return _.int(modelValue);
		});

		const minSpan = angular.element(`<span class="${styles["min-value"]}">${attrs.min}</span>`);
		const maxSpan = angular.element(`<span class="${styles["max-value"]}">${attrs.max}</span>`);

		const sliderWrapper = angular.element(elem[0].getElementsByClassName("md-slider-wrapper")[0]);
		const thumb = angular.element(elem[0].getElementsByClassName("md-thumb")[0]);
		const input = angular.element(editInPlace[0].getElementsByTagName("input")[0]);

		input.attr("type", "number").addClass("ezd-input");

		elem.addClass(styles["ezd-range"]);
		thumb.append(editInPlace);
		sliderWrapper.append(minSpan);
		sliderWrapper.append(maxSpan);
	}

	/**
	 * Создаем scope для нашего кастомного элемента
	 * @param parentScope
	 * @param attrs
	 * @param ngModelCtrl
	 * @return {*|Object}
	 */
	function createScope(parentScope, attrs, ngModelCtrl) {
		const scope = parentScope.$new(true);
		const min = $parse(attrs.min)(parentScope);
		const max = $parse(attrs.max)(parentScope);

		scope.inputValue = $parse(attrs.ngModel)(parentScope);

		scope.changeValue = function () {
			ngModelCtrl.$setViewValue(scope.inputValue);
			ngModelCtrl.$render();
		};

		scope.editInPlaceParser = function (value) {
			let inputValue = _.int(value);

			if (_.isNaN(inputValue)) {
				inputValue = scope.inputValue;
			}

			if (inputValue > max) {
				inputValue = scope.inputValue;
			}

			if (inputValue < min) {
				inputValue = scope.inputValue;
			}

			return inputValue;
		};

		return scope;
	}


	/**
	 * Проверяем входит ли значение в заданный диапазон
	 * @param val
	 * @param min
	 * @param max
	 * @return {boolean}
	 */
	function checkValue(val, min, max) {
		const $val = _.int(val);

		if ($val <= min) {
			return false;
		}
		if ($val >= max) {
			return false;
		}

		return true;
	}
}
