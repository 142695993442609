angular
	.module("ezd.backend")
	.factory("DuplicateRegistry", DuplicateRegistry);

DuplicateRegistry.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {*|Object}
 */
function DuplicateRegistry(Core) {
	const vm = this;

	vm.getList = getList;
	vm.getReasons = getReasons;
	vm.saveDuplicate = saveDuplicate;
	vm.addReason = addReason;
	vm.get = get;
	vm.removeDuplicate = removeDuplicate;

	/**
	 *
	 * @param id
	 */
	function get(id) {
		return Core.service("/api/attestation_duplicate").get(id);
	}

	/**
	 *
	 * @returns {*|Object}
	 */
	function getList() {
		return Core.service("/api/attestation_duplicate").getList();
	}

	/**
	 *
	 * @returns {*|Object}
	 */
	function getReasons() {
		return Core.service("/api/attestation_duplicate_reasons").getList();
	}

	/**
	 *
	 * @param data
	 */
	function addReason(data) {
		return Core.service("/api/attestation_duplicate_reasons").post(data);
	}

	/**
	 *
	 * @param data
	 */
	function saveDuplicate(data) {
		if (!data.id) {
			return Core.service("/api/attestation_duplicate").post(data);
		}

		return Core.service("/api/attestation_duplicate").one(data.id).customPUT(data);
	}

	/**
	 *
	 * @param data
	 * @returns {*}
	 */
	function removeDuplicate(data) {
		return Core.service("/api/attestation_duplicate").one(data.id).customDELETE();
	}

	return vm;
}

