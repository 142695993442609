import {Component} from "app/decorators/index";
import styles from "./ezd-tooltip.local.scss";


/**
 * type: {String}: ["hover", "click"] (default: "hover")
 */
@Component({
	selector: "ezdTooltip",
	template: "",
	transclude: true,
	bindings: {
		direction: "@",
		alignment: "@",
		scrollableParent: "@",
		delay: "@",
		autohide: "<",
		type: "@"
	}
})
class EzdTooltipComponent {
	static $inject = ["$element", "$compile", "$transclude", "$attrs", "$rootScope"];

	/**
	 *
	 * @param $element
	 * @param $compile
	 * @param $transclude
	 */
	constructor($element, $compile, $transclude, $attrs, $rootScope) {
		this.services = {$element, $compile, $transclude, $attrs, $rootScope};

		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);

		this.parentBounds = null;
	}


	/**
	 *
	 */
	$onInit() {
		const {$rootScope} = this.services;

		if (!this.direction) {
			this.direction = "top";
		}

		if (!this.type) {
			this.type = "hover";
		}

		if (this.alignment) {
			const res = (/(\D+)\s+(\d*)/gi).exec(this.alignment);
			this.align = res[1];
			this.offset = _.int(res[2]);
		} else {
			this.align = "center";
			this.offset = 0;
		}

		this.delay = this.delay || 0;

		$rootScope.$on("$locationChangeStart", () => {
			this.removeTooltipElem();
		});
	}


	/**
	 *
	 */
	$postLink() {
		const {$element, $transclude, $compile, $attrs} = this.services;
		this.container = $element[0].parentElement;

		$transclude((clone, scope) => {
			const wrapper = angular.element("<ezd-tooltip-el></ezd-tooltip-el>");
			wrapper.append(clone);
			this.tooltipEl = $compile(wrapper)(scope)[0];
		});

		if (this.autohide !== false) {
			switch (this.type) {
				case "click":
					this.container.addEventListener("click", this.onMouseEnter);
					break;
				default:
					this.container.addEventListener("mouseenter", this.onMouseEnter);
					break;
			}

			this.container.addEventListener("mouseleave", this.onMouseLeave);
		}

		// TODO: make hideByClick mode
	}


	/**
	 *
	 */
	$onDestroy() {
		switch (this.type) {
			case "click":
				this.container.removeEventListener("click", this.onMouseEnter);
				break;
			default:
				this.container.removeEventListener("mouseenter", this.onMouseEnter);
				break;
		}

		this.container.removeEventListener("mouseleave", this.onMouseLeave);
		this.removeTooltipElem();
	}


	/**
	 *
	 */
	onMouseLeave() {
		clearTimeout(this.timeout);

		if (this.type === "click") {
			this.timeout = setTimeout(() => {
				this.removeTooltipElem();
			}, 500);

			return;
		}

		this.removeTooltipElem();
	}


	/**
	 *
	 */
	removeTooltipElem() {
		if (this.appended) {
			document.body.removeChild(this.tooltipEl);
			this.appended = false;
		}
	}


	/**
	 *
	 */
	onMouseEnter() {
		const {$element} = this.services;

		if ($element[0].className) {
			this.tooltipEl.className = $element[0].className;
		}

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.appended = true;

			document.body.appendChild(this.tooltipEl);

			this.parentBounds = this.parentBounds || _.findElementPos(this.container);
			this.parentBounds.width = this.container.offsetWidth;
			this.parentBounds.height = this.container.offsetHeight;

			let scrollTop = 0;
			if (this.scrollableParent) {
				const parent = this.container.closest(this.scrollableParent);
				if (parent && _.int(parent.scrollTop) > 0) {
					scrollTop = _.int(parent.scrollTop);
				}
			}

			let top;
			let left;

			switch (this.direction) {
				case "top":
					left = this.parentBounds.left + this.offset;
					switch (this.align) {
						case "start":
							left = left - (this.tooltipEl.offsetWidth / 2);
							break;
						case "center":
							left = left + (this.parentBounds.width / 2) - (this.tooltipEl.offsetWidth / 2);
							break;
						case "end":
							left = left + this.parentBounds.width - (this.tooltipEl.offsetWidth / 2);
							break;
					}
					left += "px";
					top = (this.parentBounds.top - this.tooltipEl.offsetHeight - scrollTop) + "px";
					break;
			}
			this.tooltipEl.style.left = left;
			this.tooltipEl.style.top = top;
		}, this.delay);
	}
}

@Component({
	selector: "ezdTooltipEl",
	template: `<div class="${styles.wrapper}" ng-transclude></div>`,
	transclude: true
})
class EzdTooltipElComponent {
}

angular
	.module("ezd.common.ui")
	.component(EzdTooltipComponent.selector, EzdTooltipComponent)
	.component(EzdTooltipElComponent.selector, EzdTooltipElComponent);

