angular
	.module("ezd.backend")
	.factory("MedalCommissionMckoStatement", MedalCommissionMckoStatement);

MedalCommissionMckoStatement.$inject = ["REP"];

/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
function MedalCommissionMckoStatement(REP) {
	const vm = this;
	vm.collection = REP.service("/api/medal_of_honor/json");


	vm.getList = getList;

	/**
	 *
	 */
	async function getList(query = {}) {
		vm.list = await vm.collection.getList(query);

		return vm.list;
	}


	return vm;
}

