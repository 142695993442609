import "angular-mocks";

export default angular
	.module("ezd.mocks.roles", ["ngMockE2E"])
	.run(Runner)
	.name;

Runner.$inject = ["$httpBackend"];
function Runner($httpBackend) {
	$httpBackend.whenGET(/api\/roles/).respond(200, [
		{
			id: 1,
			name: "завуч",
			rules: {
				1: "full",
				2: "view",
				7: "full"
			}

		}, {
			id: 2,
			name: "директор",
			rules: {
				1: "full",
				2: "view",
				7: "full"
			}
		}, {
			id: 3,
			name: "учитель",
			rules: {
				1: "full",
				2: "view",
				7: "full"
			}
		}, {
			id: 4,
			name: "школьный админ",
			rules: {
				1: "full",
				2: "view",
				7: "full"
			}
		}, {
			id: 5,
			name: "суперадмин",
			rules: {
				1: "full",
				2: "view",
				7: "full"
			}
		}
	]);


	$httpBackend.whenGET(/api\/services/).respond(200, [
		{
			id: 1,
			name: "Причины отсутствия",
			url: "/absence_reasons"
		}, {
			id: 2,
			name: "Достижения",
			url: "/achievements"
		}, {
			id: 3,
			name: "Файлы",
			url: "/attachments"
		}, {
			id: 4,
			name: "УП",
			url: "/parallel_curricula"
		}, {
			id: 5,
			name: "Файлы",
			url: "/attachments"
		}, {
			id: 6,
			name: "Новости",
			url: "/news_items"
		}, {
			id: 7,
			name: "Контрольные",
			url: "/test_lessons"
		}, {
			id: 8,
			name: "Учебный год",
			url: "/academic_years"
		}, {
			id: 9,
			name: "Здания",
			url: "/buildings"
		}, {
			id: 10,
			name: "Параллели",
			url: "/class_levels"
		}, {
			id: 11,
			name: "Пользователи",
			url: "/users"
		}, {
			id: 12,
			name: "Группы",
			url: "/groups"
		}

	]);
}
