import "angular-mocks";

export default angular
	.module("ezd.mocks.study_profiles", ["ngMockE2E"])
	.run(Runner)
	.name;

Runner.$inject = ["$httpBackend"];
function Runner($httpBackend) {
	$httpBackend.whenGET(/\/api\/study_profiles/).respond(200,
		[
			{
				id: 2,
				name: "Гуманитарный",
				min_subject_count: 3
			},
			{
				id: 1,
				name: "Естественно-научный",
				min_subject_count: 3
			},
			{
				id: 3,
				name: "Социально-экономический",
				min_subject_count: 3
			},
			{
				id: 4,
				name: "Технологический",
				min_subject_count: 3
			},
			{
				id: 5,
				name: "Универсальный",
				min_subject_count: 0
			}
		]
	);
}
