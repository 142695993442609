angular
	.module("ezd.backend")
	.factory("$$absenceReason", absenceReasons);

/**
 *
 * @param $q
 * @returns {{}}
 */
absenceReasons.$inject = ["$q"];
function absenceReasons($q) {
	const Service = {};

	// binding
	Service.getList = getList;

	return Service;

	// return LMSv2.service('/api/absence_reasons')

	/**
	 *
	 * @returns {*}
	 */
	function getList() {
		return $q((resolve) => {
			resolve([
				{
					id: 2,
					name: "Н"
				},
				{
					id: 1,
					name: "ОСВ"
				}
			]);
		});
	}
}

