import ezdBackend from "components/backend";

class YandexMapsService {
	static name = "YandexMaps";
	static $inject = ["$q"];

	/**
	 * CONSTRUCTOR
	 */
	constructor($q) {
		this.services = {$q};

		this.loaded = false;
	}


	/**
	 * Загружаем скрипт Яндекс.Карты
	 */
	loadScript() {
		const {$q} = this.services;

		let deferred = null;

		if (Boolean(deferred) && !Boolean(deferred.promise.$$state.status)) {
			return deferred.promise;
		}

		if (this.loaded) {
			return $q.when(ymaps);
		}

		const script = document.createElement("script");
		deferred = $q.defer();
		script.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
		script.type = "text/javascript";

		if (script.readyState) { // IE
			script.onreadystatechange = function () {
				if (script.readyState === "loaded" || script.readyState === "complete") {
					script.onreadystatechange = null;
					ymaps.ready(() => {
						this.loaded = true;
						deferred.resolve(ymaps);
					});
				}
			};
		} else { // Другие браузеры
			script.onload = () => {
				ymaps.ready(() => {
					this.loaded = true;
					deferred.resolve(ymaps);
				});
			};
		}

		document.body.appendChild(script);

		return deferred.promise;
	}


	/**
	 * Создаем и возвращаем экземпляр карты
	 * @param elemId    {String}
	 * @param config    {Object}
	 */
	createMap(elemId, config) {
		return new ymaps.Map(elemId, config);
	}


	/**
	 * Перемещаем карту к местоположению пользователя, если это возможно
	 */
	goToGeolocation(map) {
		return ymaps.geolocation.get({
			provider: "yandex",
			mapStateAutoApply: true
		})
			.then((res) => {
				const firstGeoObject = res.geoObjects.get(0);
				const coords = firstGeoObject.geometry.getCoordinates();
				const yaPlacemark = new ymaps.Placemark(coords, {}, {
					preset: "islands#geolocationIcon",
					draggable: false
				});

				map.geoObjects.add(yaPlacemark);
				map.setCenter(coords, 14, {
					checkZoomRange: true
				});
			});
	}


	/**
	 * Получаем координаты по адресу
	 * @param address {String}
	 */
	getCoordsByAddress(map, address) {
		return ymaps.geocode(address, {
			result: 1
		})
			.then((res) => {
				// Выбираем первый результат геокодирования.
				const firstGeoObject = res.geoObjects.get(0);
				// Координаты геообъекта.
				const coords = firstGeoObject.geometry.getCoordinates();
				const placemark = this.createPlacemark(coords);
				const addressString = firstGeoObject.getAddressLine();
				const forCaption = this.$getForCaption(firstGeoObject);
				// Область видимости геообъекта.
				const bounds = firstGeoObject.properties.get("boundedBy");
				// // Масштабируем карту на область видимости геообъекта.
				map.setBounds(bounds, {
					// Проверяем наличие тайлов на данном масштабе.
					checkZoomRange: true
				});

				placemark.properties.set({
					iconCaption: forCaption,
					balloonContent: addressString
				});

				return placemark;
			});
	}


	/**
	 * Создание метки
	 * @param coords
	 * @return {ymaps.Placemark}
	 */
	createPlacemark(coords) {
		return new ymaps.Placemark(coords, {}, {
			preset: "islands#orangeDotIconWithCaption",
			draggable: true
		});
	}


	/**
	 * Определяем адрес по координатам (обратное геокодирование)
	 * @param coords
	 */
	getAddress(placemark) {
		const coords = placemark.geometry.getCoordinates();

		return ymaps.geocode(coords)
			.then((res) => {
				const firstGeoObject = res.geoObjects.get(0);
				const addressString = firstGeoObject.getAddressLine();
				const forCaption = this.$getForCaption(firstGeoObject);

				placemark.properties.set({
					iconCaption: forCaption,
					balloonContent: addressString
				});

				return addressString;
			});
	}


	/**
	 * Создаем строку с названием объекта
	 * @param geoObject
	 * @return {string}
	 */
	$getForCaption(geoObject) {
		return [
			// Название населенного пункта или вышестоящее административно-территориальное образование.
			geoObject.getLocalities().length ? geoObject.getLocalities() : geoObject.getAdministrativeAreas(),
			// Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
			geoObject.getThoroughfare() || geoObject.getPremise()
		].filter(Boolean).join(", ");
	}
}


angular
	.module(ezdBackend)
	.service(YandexMapsService.name, YandexMapsService);