import academicYears from "./academic_years/index";
import journalSignatures from "./journal_signatures/index";
import roles from "./roles/index";
import studyProfiles from "./study_profiles/index";
import weekDays from "./week_days/index";
import closer from "./mocks_closer/index";

export default angular
	.module("ezd.mocks", [
		academicYears,
		journalSignatures,
		roles,
		studyProfiles,
		weekDays,
		closer
	])
	.name;
