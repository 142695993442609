class ApiWrapperService {
	static $inject = ["$cookies", "ACL", "CMS", "Core", "CSSv2", "EC", "Jersey", "LMSv2", "LMSv3", "LOG", "ORG", "REP", "EXAM", "Argus"];

	constructor($cookies, ...args) {
		this.$cookies = $cookies;
		this.services = args;
	}

	/**
	 * Обновить auth-token и profile-id у врапперов
	 */
	updateHeaders() {
		this.services.forEach((service) => {
			const headers = service.defaultHeaders;
			headers["Auth-Token"] = this.$cookies.get("auth_token");
			headers["Profile-Id"] = this.$cookies.get("profile_id");
		});
	}
}

angular
	.module("ezd.backend")
	.service("ApiWrapperService", ApiWrapperService);
