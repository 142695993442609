import localforage from "localforage";

const BASE_CONFIG = {
	// driver      : localforage.WEBSQL, // Force WebSQL same as using setDriver()
	name: "mrkoApp",
	version: 1.0,
	size: 4980736, // Size of database, in bytes. WebSQL-only for now.
	storeName: "mrkoStorage", // Should be alphanumeric, with underscores.
	description: "MRKO storage"
};

localforage.config(BASE_CONFIG);

const filterStorage = localforage.createInstance({
	...BASE_CONFIG,
	name: "mrkoApp:filters"
});

export {filterStorage, localforage};