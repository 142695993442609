import OrgTypes from "components/common/constants";
import _ from "lodash";

/**
 * This service provides information about
 * access/visibility and text strings for ng Components.
 * This information depends on {@link OrgTypes} and roles.
 * format of json: <pre>
 * [
 *  {
 *    "pageKeys":  - array contains url, component path, hash
 *    "messages": [  - contains key-value pairs
 *      {
 *        keys: - array of keys
 *        value: - string or array. When it is array then each value relates to same key index from keys
 *      }
 *    ],
 *    "elements": [
 *      {
 *        keys: - array of keys
 *        visibility: - boolean or array. When it is array then it contains role string ids
 *        access: - [optional] boolean or array. When it is array then it contains role string ids.
 *                   Default value same as visibility
 *      }
 *    ]
 *  }
 * ]
 * </pre>
 */
export default class OrgLocalization {
	static name = "OrgLocalization";
	/*
	* earlier initialization. It has to exist before other services creation
	*/
	static data;
	static roles;
	static $inject = ["$q", "$$profile", "Rights", "Restangular", "md5"];
	static getHash;

	constructor($q, $$profile, Rights, Restangular, md5) {
		Object.assign(this, {$q, $$profile, Rights, Restangular, md5});

		// scaffolding functions for querying text data
		OrgLocalization.getHash = window.getHash = md5.createHash;
	}

	/**
	 *  initializes static data of class-service
	 */
	async init(noProfile) {
		// is already initialized
		if (OrgLocalization.data) {
			return;
		}

		await this.checkingOfStructure();

		const profile = noProfile ? {} : await this.$$profile.getCurrentProfile();
		console.log('profile:', profile);
		// const orgTypeName = 'MidProfEducation';
		const orgTypeName = profile.org_type_id ? OrgTypes.getById(profile.org_type_id) : 'School';
		console.log('org_type:', orgTypeName);
		window.localizedStyles.setAttribute('href', 'assets/orgs/' + orgTypeName + '/consts.css');

		return this.getAllStrings(orgTypeName)
						 .then((data) => {
								OrgLocalization.data = OrgLocalization._correctData(data);
								OrgLocalization.roles = profile.roles;
						 });
	}

	getAllStrings(orgTypeName) {
		return Promise.all([
			this.Restangular.one('assets/orgs/' + orgTypeName + '/global-strings.json').get(),
			this.Restangular.one('assets/orgs/' + orgTypeName + '/main-strings.json').get(),
			this.Restangular.one('assets/orgs/' + orgTypeName + '/diary-strings.json').get()
		]).then(([global, main, diary]) => [...global,...main,...diary]);
	}

	checkingOfStructure() {
		return this.$q.all(OrgTypes.getAvailable().map(orgTypeName =>
			this.getAllStrings(orgTypeName)
											.then(data => ({
												orgType: orgTypeName,
												pages : OrgLocalization._correctData(data)}))
											.catch(err => {
												if (err.status === 404) {
													return null;
												}
												throw err;
											})))
			.then(files => {
				files = _.compact(files);
				const isMd5 = (key) => key.length === 32 && !key.includes('/');
				const uniqPageKeys = _.uniq(files.flatMap(org => org.pages.flatMap(page => page.pageKeys)));
				// console.log('uniqPageKeys:', uniqPageKeys);

				files.forEach(org => {
					const pageKeys = org.pages.flatMap(page => page.pageKeys);

					const xorKeys = _.xor(
						uniqPageKeys,
						pageKeys);
					if (xorKeys.length > 0) {
						console.error('Localization file for orgType "' + org.orgType + '" has differences with unique pageKeys: ', xorKeys);
					}

					// search duplicates
					const counts = _.countBy(pageKeys.filter(isMd5));
					const duplicates = Object.entries(counts).filter(([k, v]) => v > 1);
					if (duplicates.length > 0) {
						console.error('Localization file for orgType "' + org.orgType + '" has duplicate hash pageKeys: ', duplicates);
					}
				});

				const md5UniqPageKeys = uniqPageKeys.filter(isMd5);

				// checking of messages keys
				const uniqMessageKeysPerPage = md5UniqPageKeys.map(
					pageKey => ({
							pageKey,
							messageKeys: _.uniq(
								files.flatMap(org => {
										const page = org.pages.find(page => page.pageKeys.includes(pageKey));
										return page.messages.flatMap(mes => mes.keys);
								})
							)
					})
				);
				// console.log('uniqMessageKeysPerPage: ', uniqMessageKeysPerPage);

				files.forEach(org => {
					uniqMessageKeysPerPage.forEach(uniqPage => {

						const xorKeys = _.xor(
							uniqPage.messageKeys,
							_.chain(org.pages)
								.find(page => page.pageKeys.includes(uniqPage.pageKey))
								.value()
								.messages.flatMap(mes => mes.keys)
						);
						if (xorKeys.length > 0) {
							console.error('Localization file for orgType "' + org.orgType + '" has differences with unique pageKey = ' + uniqPage.pageKey + ': ', xorKeys);
						}
					});
				});

				// checking of elements keys
				const uniqElementKeysPerPage = md5UniqPageKeys.map(
					pageKey => ({
						pageKey,
						elementKeys: _.uniq(
							files.flatMap(org => {
								const page = org.pages.find(page => page.pageKeys.includes(pageKey));
								return page.elements.flatMap(el => el.keys);
							})
						)
					})
				);
				// console.log('uniqElementKeysPerPage: ', uniqElementKeysPerPage);

				files.forEach(org => {
					uniqElementKeysPerPage.forEach(uniqPage => {

						const xorKeys = _.xor(
							uniqPage.elementKeys,
							_.defaultTo(
								 _.chain(org.pages)
									.find(page => page.pageKeys.includes(uniqPage.pageKey))
									.value().elements,
								[])
								.flatMap(el => el.keys)
						);
						if (xorKeys.length > 0) {
							console.error('Localization file for orgType "' + org.orgType + '" has differences with unique pageKey = ' + uniqPage.pageKey + ': ', xorKeys);
						}
					});
				});
			});
	}

	/**
	 *  @return value for string key
	 */
	static text(pageKey, key) {
		try {
			const page = OrgLocalization.data.find(page =>
				page.pageKeys.find(k => k === pageKey));
			let keyIndex = -1;
			const message = page.messages.find(message =>
				(keyIndex = message.keys.findIndex(k => k === key)) >=0);
			return _.isArray(message.value) ? message.value[keyIndex] : message.value;
		} catch(err) {
			console.error(`it has not found pageKey = "${pageKey}" with message key = "${key}"`, err);
			throw err;
		}
	}

	static _correctData(data) {
		return data.map(page => ({
			...page,
			messages: page.messages ? page.messages : [],
			elements: page.elements ? page.elements : []
		}));
	}

	static _getElement(pageKey, key) {
		const page = OrgLocalization.data.find(page =>
			page.pageKeys.find(k => k === pageKey));
		let keyIndex = -1;
		const element = page.elements.find(element =>
			(keyIndex = element.keys.findIndex(k => k === key)) >= 0);
		return element;
	}

	/***
	 *  it has two signatures:
	 *  @param args <p></p>
	 *    <p> first case: (pageKey, key) </p>
	 *    <p> second case: (element) - already found page element </p>
	 *  @return visibility for string key
	 */
	static visibility(...args) {
		try {
			const element = arguments.length === 1 ?
				arguments[0] :
				OrgLocalization._getElement(...arguments) || OrgLocalization._getElement("$global", arguments[1]);
			const roles = element.visibility;
			return _.isArray(roles) ? _.intersects(roles, OrgLocalization.roles)
															: Boolean(roles);

		} catch(err) {
			const [pageKey, key] = arguments;
			console.error(`it has not found pageKey = "${pageKey}" with elements key = "${key}"`, err);
			throw err;
		}
	}

	/**
	 *  @return access for string key. Default value equals to {@link visibility}
	 */
	static access(pageKey, key) {
		try {
			const element = OrgLocalization._getElement(pageKey, key);
			const roles = element.access;
			return _.isArray(roles) ? _.intersects(roles, OrgLocalization.roles)
															: OrgLocalization.visibility(element);

		} catch(err) {
			console.error(`it has not found pageKey = "${pageKey}" with elements key = "${key}"`, err);
			throw err;
		}
	}

}
/**
 * short aliases
 */
export const getPageString = window.getPageString = OrgLocalization.text;
export const getPageVisibility = window.getPageVisibility = OrgLocalization.visibility;
export const getPageAccess = window.getPageAccess = OrgLocalization.access;

angular.module('ezd.backend')
			 .service(OrgLocalization.name, OrgLocalization)
