

angular
	.module("ezd.backend")
	.factory("ProfileRights", profileRights);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
profileRights.$inject = ["Core"];
function profileRights(Core) {
	return Core.service("/api/profile_rights");
}