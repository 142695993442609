angular
	.module("ezd.backend")
	.factory("AESubjects", aeSubjects);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeSubjects.$inject = ["Core"];
function aeSubjects(Core) {
	return Core.service("/api/ae_subjects");
}
