import "./roll-box.scss";


angular
	.module("ezd.common.ui")
	.directive("ezdRollBox", RollBoxDirective);

RollBoxDirective.$inject = ["$timeout"];
/**
 *
 */
function RollBoxDirective($timeout) {
	return {
		restrict: "A",
		scope: {},
		link: rollBoxLink
	};


	/**
	 * Link function
	 * @param scope
	 * @param elem
	 * @param attrs
	 */
	function rollBoxLink(scope, elem, attrs) {
		elem[0].addEventListener("click", showMoreLess);
		scope.button = null;

		$timeout(() => {
			const watcher = scope.$watch(() => elem[0].innerText, (newVal) => {
				if (newVal && newVal.length) {
					compute(scope, elem, attrs);
				}
			});

			scope.$on("destroy", () => {
				elem[0].removeEventListener("click", showMoreLess);
				watcher();
			});
		}, 0);


		/**
		 *
		 * @param event
		 */
		function showMoreLess(event) {
			const $elem = event.target.closest("[ezd-roll-box]");
			const button = $elem.querySelector("span[ezd-roll-box-button]");

			if (!Boolean(button)) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();

			if (button.classList.contains("expand-less")) {
				button.classList.remove("expand-less");
				$elem.style.height = scope.cssHeight;
			} else {
				button.classList.add("expand-less");
				scope.cssHeight = window.getComputedStyle($elem).height;
				$elem.style.height = "auto";
			}
		}
	}


	/**
	 * Вычисляем высоту элемента
	 * @param scope
	 * @param elem
	 * @param attrs
	 */
	function compute(scope, elem, attrs) {
		const rowsCount = _.int(attrs.ezdRollBox);
		const computedStyle = window.getComputedStyle(elem[0]);
		const lineHeight = _.int(computedStyle.lineHeight);
		const paddingTop = _.int(computedStyle.paddingTop);
		const paddingBottom = _.int(computedStyle.paddingBottom);
		const borderTopWidth = _.int(computedStyle.borderTopWidth);
		const borderBottomWidth = _.int(computedStyle.borderBottomWidth);
		const clientHeight = elem[0].clientHeight;
		const boxSizing = computedStyle.boxSizing;
		const contentHeight = clientHeight - paddingTop - paddingBottom - borderTopWidth - borderBottomWidth;
		let cssHeight = "";

		if (Math.floor(contentHeight / lineHeight) > rowsCount) {
			switch (boxSizing) {
				case "content-box":
					cssHeight = `${lineHeight * rowsCount}px`;
					break;
				case "border-box":
					cssHeight = `${(lineHeight * rowsCount) + paddingTop + paddingBottom + borderTopWidth + borderBottomWidth}px`;
					break;
				case "padding-box":
					cssHeight = `${(lineHeight * rowsCount) + paddingTop + paddingBottom}px`;
					break;
				default:
					break;
			}

			if (!Boolean(scope.button)) {
				scope.button = createBtn();
				elem[0].appendChild(scope.button);
			}

			if (computedStyle.position === "static") {
				elem[0].style.position = "relative";
			}


			elem[0].style.height = cssHeight;
			elem[0].classList.add("ezd-roll-box");
		}
	}


	/**
	 *
	 * @returns {Element}
	 */
	function createBtn() {
		const button = document.createElement("span");
		button.setAttribute("ezd-roll-box-button", "");

		return button;
	}
}
