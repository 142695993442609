angular
	.module("ezd.backend")
	.factory("EOMWrapper", EOMWrapper);

EOMWrapper.$inject = ["CMS", "CMS_v4"];

/**
 *
 * @param CMS
 * @param CMSv4
 * @returns {Object|*}
 * @constructor
 */
function EOMWrapper(CMS, CMSv4) {
	const vm = this;
	const url = "/api/materials/ezd";
	const logicalTypesUrl = "/api/logical_types/ezd";
	const shareUrl = "api/share/link/ezd";
	const externalMaterialPartnersUrl = "/api/external_systems";
	const externalMaterialTypesUrl = "/api/external_material_types";

	vm.getBooks = getBooks;
	vm.getScenarios = getScenarios;
	vm.getGameApps = getGameApps;
	vm.getTests = getTests;
	vm.getAtomicObjects = getAtomicObjects;
	vm.getExternalMaterials = getExternalMaterials;
	vm.getLogicalTypes = getLogicalTypes;
	vm.getComposedDocuments = getComposedDocuments;
	vm.getSharingKey = getSharingKey;
	vm.getExternalMaterialPartners = getExternalMaterialPartners;
	vm.getExternalMaterialTypes = getExternalMaterialTypes;

	/**
	 *
	 * @param params
	 */
	function getBooks(params) {
		const $params = mapParams(params, "books");

		return CMSv4.service(url).getList($params);
	}

	/**
	 *
	 * @param params
	 */
	function getScenarios(params) {
		const $params = mapParams(params, "lesson_templates");

		return CMSv4.service(url).getList($params);
	}

	/**
	 *
	 * @param params
	 */
	function getGameApps(params) {
		const $params = mapParams(params, "game_apps");

		return CMSv4.service(url).getList($params);
	}

	/**
	 *
	 * @param params
	 */
	function getTests(params) {
		const $params = mapParams(params, "test_specifications");

		return CMSv4.service(url).getList($params);
	}

	/**
	 *
	 * @param params
	 */
	function getAtomicObjects(params) {
		const $params = mapParams(params, "atomic_objects");

		return CMSv4.service(url).getList($params);
	}

	/**
	 *
	 * @param params
	 */
	function getExternalMaterials(params) {
		const $params = mapParams(params, "external_materials");

		return CMSv4.service(url).getList($params);
	}

	/**
	 *
	 * @param params
	 */
	function getComposedDocuments(params) {
		const $params = mapParams(params, "composed_documents");

		return CMSv4.service(url).getList($params);
	}

	/**
	 *
	 * @param params
	 * @param type
	 * @returns {*&{types: [*]}}
	 */
	function mapParams(params, type) {
		return {
			...params,
			types: [type],
			...(!params.moderation_status && {moderation_status: "accepted"})
		};
	}

	/**
	 *
	 */
	function getLogicalTypes() {
		return CMSv4.service(logicalTypesUrl).getList();
	}

	/**
	 *
	 */
	function getExternalMaterialPartners() {
		return CMSv4.service(externalMaterialPartnersUrl).getList();
	}

	/**
	 *
	 */
	function getExternalMaterialTypes() {
		return CMSv4.service(externalMaterialTypesUrl).getList();
	}

	/**
	 *
	 */
	function getSharingKey(params) {
		const param = {
			material_id: params.id,
			material_type: params.type,
			access_type: "read_only"
		};

		return CMSv4.service(shareUrl).post({}, param);
	}

	return vm;
}
