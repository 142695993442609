import {Service} from "app/decorators/service";
import _intersects from "tools/fp/intersects";
import _pluck from "lodash/fp/pluck";
import _filter from "lodash/fp/filter";
import _reject from "lodash/fp/reject";
import _isEmpty from "lodash/fp/isEmpty";
import _startsWith from "lodash/fp/startsWith";
import _isFunction from "lodash/fp/isFunction";
import _map from "lodash/fp/map";
import _getOr from "lodash/fp/getOr";
import _int from "tools/fp/int";

const BEGIN_CLASS_LEVELS_IDS = [1, 2, 3, 4];
const PRESCHOOL_CLASS_LEVEL_ID = 14;
import _isArray from "lodash/fp/isArray";

@Service("EzdGuards")
export default class EzdGuardsService {
	static $inject = ["$$profile", "$$studentProfiles", "GUARDS"];

	constructor($$profile, $$studentProfiles, GUARDS) {
		this.services = {$$profile, $$studentProfiles, GUARDS};
		this.guards = {
			[GUARDS.STUDENT_OR_PARENT_NOT_FROM_PRESCHOOL]: this.isStudentOrParentNotFromPreschool.bind(this),
			[GUARDS.GENERAL_EDUCATION_MENTOR]: this.isGeneralEducationMentor.bind(this),
			[GUARDS.PARENT_NOT_FROM_PRESCHOOL]: this.isParentNotFromPreschool.bind(this),
			[GUARDS.PARENT_FROM_PRESCHOOL]: this.isParentFromPreschool.bind(this),
			[GUARDS.ADMIN_OR_BEGIN_CLASS_LEVELS_MENTOR]: this.isAdminOrBeginClassLevelsMentor.bind(this),
			[GUARDS.PARENT_OF_BEGIN_CLASS_LEVEL_STUDENT]: this.isParentOfBeginClassLevelStudent.bind(this),
			[GUARDS.PARENT_OR_STUDENT_WITH_PREVIOUS_PROFILE]: this.isParentOrStudentWithPreviousProfile.bind(this)
		};
	}

	/**
	 * */
	async isParentOrStudentWithPreviousProfile() {
		const {$$studentProfiles} = this.services;
		const studentProfile = await $$studentProfiles.getCurrentProfile();

		return await this.checkRoles(["parent", "student"]) && _getOr(null, "previously_profile_id")(studentProfile);
	}

	/**
	 * */
	async isParentOfBeginClassLevelStudent() {
		const {$$studentProfiles} = this.services;
		const studentProfile = await $$studentProfiles.getCurrentProfile();

		return (await this.checkRoles(["parent"]))
			&& studentProfile && (studentProfile.class_unit.class_level_id >= 1 && studentProfile.class_unit.class_level_id <= 4);
	}

	/**
	 * Админ или классрук начального класса
	 * */
	async isAdminOrBeginClassLevelsMentor() {
		const {$$profile} = this.services;
		const profile = await $$profile.getCurrentProfile();

		return (await this.checkRoles(["teacher_primary", "principal", "school_admin_read_only", "school_admin", "deputy"]))
			|| (_intersects(BEGIN_CLASS_LEVELS_IDS)(_pluck("class_level_id")(profile.managed_class_units)));
	}

	/**
	 * Родитель дошкольника
	 * */
	async isParentFromPreschool() {
		const {$$studentProfiles} = this.services;
		const studentProfile = await $$studentProfiles.getCurrentProfile();

		return await this.checkRoles(["parent"]) && studentProfile.class_unit.class_level_id === PRESCHOOL_CLASS_LEVEL_ID;
	}

	/**
	 * Родитель не дошкольника
	 * */
	async isParentNotFromPreschool() {
		const {$$studentProfiles} = this.services;
		const studentProfile = await $$studentProfiles.getCurrentProfile();

		return await this.checkRoles(["parent"]) && studentProfile.class_unit.class_level_id !== PRESCHOOL_CLASS_LEVEL_ID;
	}

	/**
	 * Родитель или ученик-не дошкольник
	 * */
	async isStudentOrParentNotFromPreschool() {
		const {$$studentProfiles} = this.services;
		const studentProfile = await $$studentProfiles.getCurrentProfile();

		return await this.checkRoles(["parent", "student"]) && studentProfile.class_unit.class_level_id !== PRESCHOOL_CLASS_LEVEL_ID;
	}

	/**
	 * */
	async isGeneralEducationMentor() {
		const {$$profile} = this.services;
		if (await this.checkRoles(["teacher"])) {
			const profile = await $$profile.getCurrentProfile();
			const noPreschoolClassUnits = _filter((classUnit) => {
				return classUnit.class_level_id !== PRESCHOOL_CLASS_LEVEL_ID;
			})(profile.managed_class_units);

			return !_isEmpty(noPreschoolClassUnits);
		}

		return false;
	}

	/**
	 * Проверка доступа по одному из специфических условий
	 * */
	async checkGuard(guardName) {
		if (_isFunction(this.guards[guardName])) {
			return this.guards[guardName]();
		}

		return true;
	}

	/**
	 * Проверка наличия у текущего профиля ролей и прав
	 * */
	async checkRoles(rolesToCheck) {
		const {$$profile} = this.services;
		const profile = await $$profile.getCurrentProfile();
		const roles = _getOr([], "roles")(profile);
		const rights = _getOr([], "right_ids")(profile);

		if (!_isArray(rolesToCheck)) {
			console.error("Invalid parameter's value: rolesToCheck is not Array.", rolesToCheck);
		}

		const intersect = _reject((role) => _startsWith("!", role) || _startsWith("right:", role))(rolesToCheck);
		let notIntersect = _filter((role) => _startsWith("!", role))(rolesToCheck);
		let itemRights = _filter((role) => _startsWith("right:", role))(rolesToCheck);


		notIntersect = _map((n) => _.trimStart(n, "!"))(notIntersect);
		itemRights = _map((n) => _int(_.trimStart(n, "right:")))(itemRights);

		return ((_isEmpty(intersect) || _intersects(roles)(intersect)) && !_intersects(roles)(notIntersect))
			|| _intersects(rights)(itemRights);
	}

	/**
	 * Проверка доступа к урлу
	 * */
	async checkAccess(stateUrl, access) {
		if (access && _isArray(access)) {
			return this.checkAccessByType(access);
		}
		if (access && _isFunction(access)) {
			const {$$profile} = this.services;
			const profile = await $$profile.getCurrentProfile();

			return access({profile});
		}
		console.warn("Invalid access settings format", stateUrl, access);

		return true;
	}

	/**
	 * Проверка ограничений
	 * */
	async checkAccessByType(access) {
		// требования к ролям и правам
		const checkingRoles = _reject((value) => _startsWith("/", value) || _startsWith("$", value))(access);
		// специфические требования
		const checkingGuards = _filter((value) => _startsWith("$", value))(access);

		// если все ограничения пусты пропускаем проверку
		if (_isEmpty(checkingRoles) && _isEmpty(checkingGuards)) {
			return true;
		}

		// в первую очередь проверяем роли
		if (!_isEmpty(checkingRoles) && await this.checkRoles(checkingRoles)) {
			return true;
		}

		// проверяем соответствие специфическим требованиям
		if (!_isEmpty(checkingGuards)) {
			for (const guard of checkingGuards) {
				if (await this.checkGuard(guard)) {
					return true;
				}
			}
		}

		// если все проверки провалены - запрещаем доступ
		return false;
	}
}
