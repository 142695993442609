angular
	.module("ezd.backend")
	.service("$$reportAvgMarks", Service);

/**
 *
 * @param REP
 * @constructor
 */
Service.$inject = ["REP"];
function Service(REP) {
	const url = "api/avg_marks";
	const vm = this;

	vm.getJson = getJson;

	/**
	 * Загрузка данных для отчета в json
	 * @param params {{}}
	 *
	 * @returns {*}
	 */
	function getJson(params) {
		return REP.one(url, "json").get(params);
	}
}

