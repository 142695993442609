angular
	.module("ezd.backend")
	.service("$$specialtyCode", $$specialtyCode);


/**
 *
 * @param Core
 */
$$specialtyCode.$inject = ["Core"];
function $$specialtyCode(Core) {
	const url = "/api/specialty_codes";
	const vm = this;
	const WITHOUT_CODE = "без кода";

	vm.list = [];
	vm.collection = Core.all(url);
	// значение code таблицы specialty_codes для некоторых записей
	vm.SPECIALTY_WITHOUT_CODE = WITHOUT_CODE;

	vm.getListWithoutCode = (list) => {
		return _.filter(list, (specialtyCode) => {
			return specialtyCode.code.toUpperCase() === WITHOUT_CODE.toUpperCase();
		});
	};

	vm.getCodeName = (subject, list, specialtyCodeId) => {
		if (!subject || !list) {
			return null;
		}

		const sId = specialtyCodeId || subject.specialty_code_id;
		if (!subject.specialty_code || subject.specialty_code.id !== sId) {
			subject.specialty_code = _.find(list, {id: sId});
		}

		return subject.specialty_code ? subject.specialty_code.code + " " + subject.name
			: (list.length ? WITHOUT_CODE + " " : "") + subject.name;
	};
}
