angular
	.module("ezd.backend")
	.factory("$$books", books);

books.$inject = ["CMS"];

/**
 *
 * @param CMS - Restangular wrapper for /cms
 * @returns {Object|*} - Restangular service "/api/books"
 */
function books(CMS) {
	const url = "/api/books";

	CMS.extendCollection(url, (collection) => {
		collection.getTotalPages = (response) => {
			const totalEntries = response.headers("x-pagination-total-entries");
			const perPage = response.headers("x-pagination-per-page");

			return _.ceil(totalEntries / perPage);
		};

		return collection;
	});

	return CMS.service(url);
}
