import styles from "./ezd-button.local.scss";

/**
 * Usage:
 *
 * types: orange|blue  - не обязательный параметр, если не указывать будет кнопка белая с бордером
 * Пока хавает только ng-click
 *
 *
 * <ezd-button type="orange" ng-click="$ctrl.click()">Текст кнопки</ezd-button>
 */
function EzdButtonComponent() {
	return {
		restrict: "E",
		template,
		replace: true
	};
}

EzdButtonComponent.selector = "ezdButton";


/**
 *
 * @param tElem
 * @param tAttrs
 * @returns {string}
 */
function template(tElem, tAttrs) {
	const html = tElem.html();
	const type = tAttrs.type || "default";
	let classes = tElem[0].classList;
	let element = "button";

	if (tAttrs.href) {
		element = "a";
	}

	switch (type) {
		case "orange":
			classes += styles.orange;
			break;
		case "blue":
			classes += styles.blue;
			break;
		case "white":
			classes += styles.white;
			break;
		case "transparent":
			classes += styles.transparent;
			break;
		case "green":
			classes += styles.green;
			break;
		case "default":
		default:
			classes += styles.default;
			break;
	}

	return `<${element} ezd-button class="${classes}">${html}</${element}>`;
}


angular.module("ezd.common.ui")
	.directive(EzdButtonComponent.selector, EzdButtonComponent);
