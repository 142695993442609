const URL = "/api/ec_homeworks";
import _map from "lodash/fp/map";
import _getOr from "lodash/fp/getOr";
import _flatten from "lodash/fp/flatten";
import _flow from "lodash/fp/flow";

class ECHomeWorks {
	static $inject = ["EC"];

	/**
	 * Constructor
	 * @param EC
	 */
	constructor(EC) {
		this.ECService = EC;
		this.collection = EC.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.ECService.one(URL, id);
	}

	/**
	 *
	 */
	getAttachments(homework) {
		const attachments = _flow(
			_map((hw) => {
				return _getOr([], "attachments", hw);
			}),
			_flatten
		)(homework.homework_entries);

		return _map((item) => {
			item.homework_id = _.get(homework, "id");

			return this.$getAttachmentItem(item);
		})(attachments);
	}

	/**
	 */
	$getAttachmentItem(item) {
		if (!item) {
			return null;
		}

		const fileName = _.get(item, "file_file_name", "");
		const simpleName = fileName.split(".");
		const extension = (simpleName.length > 1) ? simpleName.pop() : "";

		item.name = simpleName.join(".");
		item.extension = extension ? `.${extension}` : "";
		item.url = item.path;

		return item;
	}
}


angular
	.module("ezd.backend")
	.service("ECHomeWorks", ECHomeWorks);

