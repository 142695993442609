angular
	.module("ezd.backend")
	.factory("EOMBindings", EOMBindings);

EOMBindings.$inject = ["EXAM"];

/**
 *
 * @param EXAM
 * @returns {Object|*}
 * @constructor
 */
function EOMBindings(EXAM) {
	const vm = this;
	const url = "/player/api/binding";

	vm.bindHomework = bindHomework;
	vm.unbindHomework = unbindHomework;

	/**
	 *
	 */
	function bindHomework(homeworkId, testId, type) {
		return EXAM.service(url).one().customPOST({
			material_id: homeworkId, // идентификатор материала.
			material_type: "homework", // тип материала (урок / учебник / домашнее задание).
			test_material_id: testId, // идентификатор тестового материала.
			test_material_type: type // тип тестового материала (тестовое задание / тестовая спецификация).
		});
	}

	/**
	 *
	 * @returns {*}
	 */
	function unbindHomework(bindingId, type) {
		return EXAM.service(`${url}/${bindingId}/${type}`).one().customDELETE();
	}

	return vm;
}
