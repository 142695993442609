

angular
	.module("ezd.backend")
	.service("$$matchDeletedLessons", $$matchDeletedLessons);


/**
 *
 * @param Core
 */
$$matchDeletedLessons.$inject = ["Core"];
function $$matchDeletedLessons(Core) {
	const url = "api/match_deleted_lessons";
	const vm = this;

	vm.startMarksMatch = startMarksMatch;
	vm.getMarksStatus = getMarksStatus;
	vm.startAttendancesMatch = startAttendancesMatch;
	vm.getAttendancesStatus = getAttendancesStatus;

	function startMarksMatch(query) {
		return Core.all(url).customGET("marks/start_match", query);
	}

	function getMarksStatus(query) {
		return Core.all(url).customGET("marks/status", query);
	}

	function startAttendancesMatch(query) {
		return Core.all(url).customGET("attendances/start_match", query);
	}

	function getAttendancesStatus(query) {
		return Core.all(url).customGET("attendances/status", query);
	}
}