import template from "./CurrentDate.html";


angular
	.module("ezd.common.ui")
	.component("currentDate", {
		template,
		controller: currentDateController,
		bindings: {
			oldView: "<"
		}
	});


/**
 *
 * @param $$history
 */
currentDateController.$inject = ["$$history"];
function currentDateController($$history) {
	const vm = this;

	vm.styles = require("./current-date.local.scss");

	const today = moment();

	vm.day = today.date();
	vm.month = today.format("MMMM");
	vm.year = today.format("YYYY");

	vm.date = today.date();
	vm.monthAndDay = today.format("MMMM, dd.");

	vm.oldView = typeof vm.oldView === "undefined" ? true : vm.oldView;

	vm.icons = {
		open_menu: require("../icons/ic_fast_forward_white_24px.svg"),
		close_menu: require("../icons/ic_fast_rewind_white_24px.svg"),
		arrow_back: require("images/icons/arrow_back.svg")
	};

	vm.goBack = $$history.goBack;
}

