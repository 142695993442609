import {filterStorage} from "../../../../main/localforage.js";


angular
	.module("ezd.backend")
	.service("$$filters", $$filters);


/**
 *
 * @param $q
 * @param $timeout
 * @param $$user
 */
$$filters.$inject = ["$q", "$timeout", "$$user"];
function $$filters($q, $timeout, $$user) {
	const vm = this;


	/**
	 *
	 * DECLARATION
	 *
	 */

	vm.init = init;
	vm.addPage = addPage;
	vm.setPageFilterValue = setPageFilterValue;
	vm.getPageFilterValue = getPageFilterValue;
	vm.getSetPageFilterValue = getSetPageFilterValue;
	vm.getSetPageFilterValueWithCallback = getSetPageFilterValueWithCallback;


	/**
	 *
	 * VARIABLES AND CONSTANTS
	 *
	 */

	// Коллекция с фильтрами для всех страниц
	vm.collection = {};
	vm.publicCollection = vm.collection;

	// Статусы синхронизации со стораджем
	vm.STATES = {
		GET: -1,
		IDLE: 0,
		SET: 1
	};

	// Статус синхронизации - используется для блокирования одновременных операций
	vm.syncState = vm.STATES.IDLE;


	/**
	 *
	 * RUN
	 *
	 */

	// Инициализируем приложение из стораджа
	init();


	/**
	 *
	 * IMPLEMENTATIONS
	 *
	 */

	function init() {
		$$user.getCurrentUser()
			.then((data) => {
				vm.user = data;

				return updateStorage(true);
			})
			.then((data) => {
				if (data && _.keys(data).length > 0) {
					vm.collection = data;
				} else {
					// throw new Error('Локальное хранилище пусто')
				}
			})
			.catch((err) => console.log(err));
	}


	/**
	 *
	 * @param get1
	 * @returns {Function}
	 */
	function updateStorage(get1) {
		const deferred = $q.defer();

		syncLoop();

		return deferred.promise;


		/**
		 *
		 */
		function syncLoop() {
			if (vm.syncState === vm.STATES.IDLE && vm.user) {
				if (get1) {
					// Блокируем обращение в стораджу с фильтрами
					vm.syncState = vm.STATES.GET;

					// Получение состояния фильтров из стораджа
					filterStorage.getItem("filters::" + _.get(vm.user, "id"))
						.then((data) => {
							vm.syncState = vm.STATES.IDLE;
							deferred.resolve(data);
						})
						.catch((err) => {
							vm.syncState = vm.STATES.IDLE;
							deferred.reject(err);
						});
				} else {
					vm.syncState = vm.STATES.SET;

					// Сохранение состояний фильтров в сторадж
					filterStorage.setItem("filters::" + _.get(vm.user, "id"), vm.collection)
						.then((data) => {
							vm.syncState = vm.STATES.IDLE;
							deferred.resolve(data);
						})
						.catch((err) => {
							vm.syncState = vm.STATES.IDLE;
							deferred.reject(err);
						});
				}
			} else {
				$timeout(syncLoop, 100);
			}
		}
	}


	/**
	 * Добавляем набор фильтров для страницы
	 * @param pageName
	 * @param filterNames
	 * @param initValue
	 * @param dropPage
	 * @param modifyExisting boolean флаг модификации имеющейся страницы
	 * @returns {boolean}
	 */
	function addPage(pageName, filterNames, initValue = "!null", dropPage = false, modifyExisting = false) {
		if (vm.collection) {
			updateStorage(true)
				.then(() => {
					vm.syncState = vm.STATES.IDLE;

					let exists = true;

					if (typeof vm.collection[pageName] === "undefined" || dropPage) {
						vm.collection[pageName] = {};
						if (Array.isArray(filterNames) && filterNames.length !== 0) {
							for (let i = filterNames.length - 1; i >= 0; i -= 1) {
								vm.collection[pageName][filterNames[i]] = initValue;
							}
						}
						exists = false;
					}

					// Добавляем поля в существующий фильтр страницы
					// Используется, например, в журнале для запоминания форм контроля для разных групп
					if (modifyExisting && exists) {
						if (Array.isArray(filterNames) && filterNames.length !== 0) {
							for (let i = filterNames.length - 1; i >= 0; i -= 1) {
								if (!vm.collection[pageName][filterNames[i]]) { vm.collection[pageName][filterNames[i]] = initValue; }
							}
						}
					}

					updateStorage();
				})
				.catch(() => {
					vm.syncState = vm.STATES.IDLE;
				});
		}
	}


	/**
	 * Устанавливаем значения фильтра на странице
	 * @param pageName
	 * @param key
	 * @param value
	 */
	function setPageFilterValue(pageName, key, value) {
		const deferred = $q.defer();

		if (typeof vm.collection !== "undefined"
			&& typeof vm.collection[pageName] !== "undefined"
			&& typeof vm.collection[pageName][key] !== "undefined") {
			vm.collection[pageName][key] = value || "!null";
			updateStorage()
				.then((data) => deferred.resolve(data))
				.catch((err) => {
					deferred.reject(err);
					vm.syncState = vm.STATES.IDLE;
				});
		} else {
			deferred.reject();
		}

		return deferred.promise;
	}


	/**
	 * Получаем значение фильтра со страницы
	 * @param pageName
	 * @param key
	 * @returns {*}
	 */
	function getPageFilterValue(pageName, key) {
		let value = null;
		if (typeof vm.collection !== "undefined"
			&& typeof vm.collection[pageName] !== "undefined"
			&& typeof vm.collection[pageName][key] !== "undefined") {
			value = vm.collection[pageName][key];
		}

		return value;
	}


	/**
	 * Совмещенный геттерСеттер
	 * @param pageName
	 * @param key
	 * @param value
	 * @returns {*}
	 */
	function getSetPageFilterValue(pageName, key, value) {
		if (typeof value !== "undefined") {
			setPageFilterValue(pageName, key, value)
			// .then(data => {})
				.catch(() => {
					vm.syncState = vm.STATES.IDLE;
				});
		}

		return getPageFilterValue(pageName, key);
	}


	/**
	 * Совмещенный геттерСеттер с колбэком
	 * @param pageName
	 * @param key
	 * @param value
	 * @param callback
	 * @returns {*}
	 */
	function getSetPageFilterValueWithCallback(pageName, key, callback, value) {
		if (typeof value !== "undefined") {
			setPageFilterValue(pageName, key, value)
				.then(() => {
					if (angular.isFunction(callback)) {
						callback(value);
					} else {
						throw new Error("Не передана функция в качестве колбэка");
					}
				})
				.catch(() => {
					vm.syncState = vm.STATES.IDLE;
				});
		}

		return getPageFilterValue(pageName, key);
	}
}
