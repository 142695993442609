angular
	.module("ezd.common.ui")
	.directive("escKeypress", escKeypress);

/**
 *
 * @returns {function(*=, *, *=)}
 */
function escKeypress() {
	return (scope, element, attrs) => {
		element.bind("keydown keypress", (event) => {
			if (event.which === 27) {
				scope.$apply(() => {
					scope.$eval(attrs.escKeypress);
				});

				event.preventDefault();
			}
		});
	};
}
