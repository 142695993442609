import device from "tools/device";

import _intersects from "tools/fp/intersects";
import EomUrl from "components/common/eomUrl";

angular
	.module("ezd.backend")
	.service("$$studentHomework", homeworkEntryStudentService);


homeworkEntryStudentService.$inject = ["Core", "$$profile"];

/**
 *
 * @param Core
 * @param $$profile
 */
function homeworkEntryStudentService(Core, $$profile) {
	const url = "/api/student_homeworks";
	const vm = this;

	vm.eomurl = EomUrl.getBaseUrl();
	vm.list = [];
	vm.collection = Core.all(url);
	vm.collection.getList = (query) => Core.all(url).getList(query);

	vm.deeplinkWrapper = "https://uchebnik.page.link/?&apn=ru.mos.mes&amv=350&efr=1&ibi=ru.mos.etbook&imv=2.1.123&link=";

	// методы для запроса данных с сервера
	vm.bindOne = bindOne;
	vm.getList = getList;
	vm.getAttachments = getAttachments;
	vm.getScripts = getScripts;
	vm.getBooks = getBooks;
	vm.getGameApps = getGameApps;
	vm.getAtomicObjects = getAtomicObjects;
	vm.getExternalMaterials = getExternalMaterials;
	vm.getTests = getTests;


	// расширяем модель
	Core.extendModel(url, (model) => {
		model.getHumanDuration = getHumanDuration;
		model.addAttachments = addAttachments;
		model.getSubjectName = getSubjectName;

		return model;
	});

	/**
	 * Получение домашнего задания для ученика по его id
	 * @param id {Number}
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(url, id);
	}

	/**
	 * Получение списка домашних заданий для ученика
	 * @param params {{}} объект с параметрами запроса {a:1, b:2 ...}
	 * @returns {*}
	 */
	function getList(params) {
		return vm.collection.getList(params);
	}


	function getSubjectName() {
		if (_.isObject(this.homework_entry.homework.subject)) {
			return this.homework_entry.homework.subject.name;
		}

		return this.subject_name;
	}


	function addAttachments(response) {
		const that = this;
		let oldAttachments = that.attachments || [];

		if (!response) {
			return;
		}

		that.attachment_ids = _.compact(_.union(that.attachment_ids, _.map(oldAttachments, "id")));
		that.attachment_ids.push(response.id);
		that.attachments = [];

		return vm.bindOne(that.id).customPUT(that)
			.then(() => {
				response.path = response.url || $getAttachmentPath(response);

				if (!_.isArray(oldAttachments)) {
					oldAttachments = [response];
				} else {
					oldAttachments.push(response);
				}

				that.attachments = oldAttachments;
			})
			.catch(() => {
				that.attachments = oldAttachments;
			});
	}

	/**
	 * Возвращает продолжительность домашней работы
	 * @returns {string}
	 */
	function getHumanDuration() {
		let displayDuration;

		if (_.isEmpty(this.homework_entry)) {
			return "";
		}

		if (this.homework_entry.duration < 60) {
			return (this.homework_entry.duration || 0) + " мин";
		}

		if (this.homework_entry.duration >= 60 && this.homework_entry.duration < 1440) {
			displayDuration = _.floor(this.homework_entry.duration / 60, 2);

			return (displayDuration || 0) + " ч";
		}

		if (this.homework_entry.duration >= 1440) {
			displayDuration = _.floor(this.homework_entry.duration / 1440, 2);

			return (displayDuration || 0) + " д";
		}

		return "";
	}


	/**
	 *
	 */
	function getAttachments(homework) {
		const attachments = _.get(homework, "homework_entry.attachments", []);

		return _.map(attachments, (item) => {
			item.homework_id = _.get(homework, "homework_entry.homework_id");

			return $getAttachmentItem(item);
		});
	}

	/**
	 * Возвращает массив ссылок на сценарии
	 *
	 * @param homework {*} - домашнее задание (student homework)
	 * @returns {Array} - массив ссылок на сценарии
	 */
	function getScripts(homework) {
		const scripts = _.get(homework, "homework_entry.scripts", null);

		try {
			const fromScripts = JSON.parse(scripts);

			return _.map(fromScripts, $getScriptUrl);
		} catch (e) {
			return [];
		}
	}

	/**
	 *
	 * @param homework
	 * @returns {Array}
	 */
	function getGameApps(homework) {
		const apps = _.get(homework, "homework_entry.game_apps", null);

		try {
			const fromApps = JSON.parse(apps);

			return _.map(fromApps, $getGameAppUrl);
		} catch (e) {
			return [];
		}
	}

	/**
	 *
	 * @param gameApp
	 * @returns {{url: string, name}}
	 */
	function $getGameAppUrl(gameApp) {
		const groupRole = _.includes(["parent", "student"], this.currentProfile.type)
			? this.currentProfile.type
			: "employee";
		const $url = [
			`${vm.eomurl}/eom-acl/oidc/login?`,
			`groupRole=${groupRole}`,
			`&redirectUrl=${vm.eomurl}`,
			`${gameApp.sharing_key
				? `/material/app/${gameApp.id}?sharing_key=${gameApp.sharing_key}`
				: `/catalogue/material_view/game_apps/${gameApp.id}`}`
		].join("");

		return {
			url: device.mobile() ? this.deeplinkWrapper + $url : $url,
			name: gameApp.name
		};
	}

	/**
	 *
	 * @param homework
	 * @returns {Array}
	 */
	function getAtomicObjects(homework) {
		const atomicObjects = _.get(homework, "homework_entry.atomic_objects", null);

		try {
			const fromAtoms = JSON.parse(atomicObjects);

			return _.map(fromAtoms, $getAtomicObjectUrl);
		} catch (e) {
			return [];
		}
	}

	/**
	 *
	 * @param atom
	 * @returns {{url: string, name}}
	 */
	function $getAtomicObjectUrl(atom) {
		const isParent = $$profile.currentProfile.type === "parent";
		const isStudent = $$profile.currentProfile.type === "student";
		const groupRole = (isParent || isStudent) ? $$profile.currentProfile.type : "employee";

		let $url;

		if (atom.logicalType === "test") {
			if (isParent) {
				$url = [
					`${vm.eomurl}/eom-acl/oidc/login?`,
					`groupRole=${groupRole}`,
					`&redirectUrl=${vm.eomurl}/exam/player/training_test/${atom.binding_id}`
				].join("");
			} else {
				$url = [
					`${vm.eomurl}/eom-acl/oidc/login?`,
					`groupRole=${groupRole}`,
					`&redirectUrl=${vm.eomurl}/exam/test/task/${atom.binding_id}`
				].join("");
			}
		} else {
			$url = [
				`${vm.eomurl}/eom-acl/oidc/login?`,
				`groupRole=${groupRole}`,
				`&redirectUrl=${vm.eomurl}/catalogue/material_view/atomic_objects/${atom.material_id ? atom.material_id : atom.id}`
			].join("");
		}

		return {
			url: device.mobile() ? this.deeplinkWrapper + $url : $url,
			name: atom.name ? atom.name : atom.caption
		};
	}

	/**
	 *
	 * @param homework
	 * @returns {Array}
	 */
	function getExternalMaterials(homework) {
		const externalMaterials = _.get(homework, "homework_entry.external_materials", null);

		try {
			const fromMaterials = JSON.parse(externalMaterials);

			return _.map(fromMaterials, $getExternalMaterialUrl);
		} catch (e) {
			return [];
		}
	}

	/**
	 *
	 * @param material
	 * @returns {{url: string, name}}
	 */
	function $getExternalMaterialUrl(material) {
		const groupRole = _.includes(["parent", "student"], $$profile.currentProfile.type)
			? $$profile.currentProfile.type
			: "employee";
		const $url = [
			`${vm.eomurl}/eom-acl/oidc/login?`,
			`groupRole=${groupRole}`,
			`&redirectUrl=${vm.eomurl}/extern/api/homework?`,
			`materialId=${material.material_id ? material.material_id : material.id}`
		].join("");

		return {
			url: device.mobile() ? this.deeplinkWrapper + $url : $url,
			name: material.name ? material.name : material.caption
		};
	}

	/**
	 *
	 * @param homework
	 * @returns {Array}
	 */
	function getTests(homework) {
		const tests = _.get(homework, "homework_entry.tests", null);

		try {
			const fromTests = JSON.parse(tests);

			return _.map(fromTests, $getTestsUrl);
		} catch (e) {
			return [];
		}
	}

	/**
	 *
	 * @param test
	 * @returns {{url: string, name}}
	 */
	function $getTestsUrl(test) {
		const groupRole = _.includes(["parent", "student"], $$profile.currentProfile.type)
			? $$profile.currentProfile.type
			: "employee";
		const backUrl = _intersects(["parent", "student"])($$profile.currentProfile.roles)
			? `${vm.eomurl}/exam/test/homework/${test.binding_id}`
			: `${vm.eomurl}/catalogue/material_view/test_specifications/${test.material_id ? test.material_id : test.id}`;

		const $url = `${vm.eomurl}/eom-acl/oidc/login?groupRole=${groupRole}&redirectUrl=${backUrl}`;

		return {
			url: device.mobile() ? this.deeplinkWrapper + $url : $url,
			name: test.name
		};
	}

	/**
	 * Возвращает массив ссылок на учебники (параграфы учебников)
	 *
	 * @param homework {*} - домашнее задание (student homework)
	 * @returns {Array} - массив ссылок на учебники
	 */
	function getBooks(homework) {
		const description = _.get(homework, "homework_entry.description", "");
		const fromDescription = $getBooksFromDescription(description);
		const books = _.get(homework, "homework_entry.books", null);

		try {
			const fromBooks = _.map(JSON.parse(books), $getBookUrl);
			const result = _.union(fromDescription, fromBooks);

			return _.uniq(result, "name");
		} catch (e) {
			return fromDescription;
		}
	}


	/**
	 */
	function $getAttachmentItem(item) {
		if (!item) {
			return null;
		}

		const fileName = _.get(item, "file_file_name", "");
		const simpleName = fileName.split(".");
		const extension = (simpleName.length > 1) ? simpleName.pop() : "";

		item.name = simpleName.join(".");
		item.extension = extension ? `.${extension}` : "";
		item.url = item.path;

		return item;
	}

	/**
	 * Давным давно в далекой-далекой... в описании домашнего задания была захардкожена json строка с ссылками
	 * на учебники. Магия в этом методе достает эту строку и парсит в массив объектов
	 *
	 * @param homeworkDescription {String} - описание домашнего задания
	 *
	 * @returns {Array}
	 */
	function $getBooksFromDescription(homeworkDescription) {
		let links;
		let jsonString;

		try {
			jsonString = homeworkDescription.split("JSON::")[1] || "[]";
			links = JSON.parse(jsonString);

			return _.map(links, $getBookUrl);
		} catch (e) {
			return [];
		}
	}

	/**
	 */
	function $getScriptUrl(script) {
		const groupRole = _.includes(["parent", "student"], $$profile.currentProfile.type)
			? $$profile.currentProfile.type
			: "employee";
		const $url = [
			`${vm.eomurl}/eom-acl/oidc/login?`,
			`groupRole=${groupRole}`,
			`&redirectUrl=${vm.eomurl}/composer3/lesson/${script.material_id ? script.material_id : script.id}/view`
		].join("");

		return {
			url: device.mobile() ? this.deeplinkWrapper + $url : $url,
			name: script.name
		};
	}

	/**
	 */
	function $getBookUrl(link) {
		const groupRole = _.includes(["parent", "student"], $$profile.currentProfile.type)
			? $$profile.currentProfile.type
			: "employee";
		if (!link.id) {
			link.id = link.ebook_id ? link.ebook_id : link.material_id;
		}

		const linkObject = {
			url: [
				`${vm.eomurl}/eom-acl/oidc/login?`,
				`groupRole=${groupRole}`,
				`&redirectUrl=${vm.eomurl}/`,
				`${link.type === "eup" || link.type === "ComposedDocument" ? "composer3/document/" : "catalogue/material_view/books/"}${link.id}`,
				`${link.sharing_key ? `?sharing_key=${link.sharing_key}` : ""}`
			].join(""),
			name: link.name
		};
		if (Boolean(link.path)) {
			linkObject.url += `/articles/${link.path}`;
		}

		linkObject.url = device.mobile() ? this.deeplinkWrapper + linkObject.url : linkObject.url;

		return linkObject;
	}

	/**
	 */
	function $getAttachmentPath(response) {
		if (!response.id || !response.file_file_name) {
			return;
		}
		const id3p = response.id.toString().padStart(9, "0").match(new RegExp(".{1,3}", "ig")).join("/");

		return "/attachments/files/" + id3p + "/original/" + response.file_file_name;
	}
}

