angular
	.module("ezd.backend")
	.service("$$helpers", $$helpers);


/**
 *
 */
function $$helpers() {
	const vm = this;


	/**
	 *
	 * Работа с мультисписком чекбоксов
	 *
	 */

	/**
	 * Перевод массива состояний чекбоксов в массив ids
	 * @param collection
	 * @returns {Array}
	 */
	vm.getCorrectIds = (collection) => {
		const result = [];
		_.forOwn(collection, (value, key) => {
			if (value) {
				result.push(Number(key));
			}
		});

		return result;
	};

	/**
	 * Перевод массива ids в массив состояний чекбоксов
	 * @param ids
	 * @returns {*}
	 */
	vm.setKeysByIds = (ids) => {
		const result = {};
		_.forEach(ids, (id) => {
			result[id] = true;
		});

		return result;
	};


	/**
	 *
	 * Получение элементов или их индексов
	 *
	 */

	/**
	 * Получаем index элемента в массиве по его id
	 * @param array
	 * @param id
	 * @returns {number}
	 *
	 * @deprecated Use _.findIndex instead
	 */
	vm.getIndexById = (array, id) => {
		let result = -1;
		if (array) {
			array.forEach((element, index) => {
				if (Number(element.id) === Number(id)) {
					result = index;

					return result;
				}
			});
		}

		return result;
	};

	/**
	 * Получаем элемент из массива по его id
	 * @param array
	 * @param id
	 * @returns {*}
	 *
	 * @deprecated use _.find(array, {id}) instead
	 */
	vm.getById = (array, id) => {
		let result = -1;
		if (array) {
			array.forEach((element, index) => {
				if (Number(element.id) === Number(id)) {
					result = index;

					return result;
				}
			});
		}

		return result !== -1 ? array[result] : null;
	};

	/**
	 * Получаем массив элементов на основе массива id
	 * @param array
	 * @param ids
	 * @returns {Array}
	 *
	 * @deprecated use _.find(array, el => _.includes(ids, el.id))
	 */
	vm.getByIds = (array, ids) => {
		const result = [];
		for (let i = 0; i < array.length; i += 1) {
			if (_.includes(ids, array[i].id)) {
				result.push(array[i]);
			}
		}

		return result;
	};

	/**
	 * Получаем index элемента в массиве по полю
	 * @param array
	 * @param value
	 * @param key
	 * @returns {number}
	 *
	 * @deprecated use _.findIndex instead
	 */
	vm.getIndexByKeyValue = (array, value, key) => {
		let result = -1;
		if (array) {
			array.forEach((element, index) => {
				if (Number(element[key]) === Number(value)) {
					result = index;

					return result;
				}
			});
		}

		return result;
	};

	/**
	 *
	 * @param array
	 * @param value
	 * @param key
	 * @param strict
	 * @returns {number}
	 *
	 * @deprecated use _.findIndex instead
	 */
	vm.getIndexByValueKey = (array, value, key, strict) => {
		let result = -1;
		for (let i = array.length - 1; i >= 0; i -= 1) {
			if ((strict && array[i][key] === value) || (!strict && array[i][key] === value)) {
				result = i;
				break;
			}
		}

		return result;
	};

	/**
	 * Получаем элемент массива по полю
	 * @param array
	 * @param value
	 * @param key
	 * @returns {*}
	 *
	 * @deprecated use _.find instead
	 */
	vm.getByKeyValue = (array, value, key) => {
		let result = -1;
		if (array) {
			array.forEach((element, index) => {
				if (element[key] === value) {
					result = index;

					return result;
				}
			});
		}

		return result !== -1 ? array[result] : null;
	};


	/**
	 *
	 * ПЕРЕКЛЮЧЕНИЕ СОСТОЯНИЙ SHOW/HIDE
	 *
	 */

	/**
	 * Смена состояние для тоггла
	 * @param index
	 * @param indexName
	 * @param array
	 */
	vm.switchState = (index, indexName, array) => {
		if (typeof array !== "undefined" && array !== null) {
			array[indexName] = (array[indexName] === index) ? -1 : index;
		}
	};

	/**
	 * Узнаём состояние
	 * @param index
	 * @param indexName
	 * @param array
	 * @returns {boolean}
	 */
	vm.getState = (index, indexName, array) =>
		(typeof array !== "undefined" && array !== null)
			? array[indexName] === index
			: undefined;

	/**
	 * Устанавливаем конкретное состояние
	 * @param index
	 * @param indexName
	 * @param array
	 */
	vm.setState = (index, indexName, array) => {
		if (typeof array !== "undefined" && array !== null) {
			array[indexName] = index;
		}
	};

	/**
	 * Для тоггла - проверяем открыто ли состояние у параметра
	 * @param indexName
	 * @param array
	 * @returns {boolean}
	 */
	vm.isStateOpen = (indexName, array) =>
		(typeof array !== "undefined"
			&& array !== null
			&& typeof array[indexName] !== "undefined"
			&& array[indexName] !== null
			&& array[indexName] !== -1
		);


	/**
	 * Меняем пачкой состояние индексов для видимости элементов
	 * @params - массивы ([15, 'lesson'], [20, 'grade'], ...)
	 */
	vm.setStateArray = (statesArray) =>
		_.forEach(arguments, (argument) => {
			statesArray[argument[1]] = (statesArray[argument[1]] === argument[0]) ? -1 : argument[0];
		});


	/**
	 * Сбрасываем все значения элементов массива в заданное состояние
	 * @param array
	 * @param state
	 * @returns {*}
	 */
	vm.dropState = (array, state) => {
		const keys = Object.keys(array);
		for (let i = keys.length - 1; i >= 0; i--) {
			array[keys[i]] = state;
		}

		return array;
	};


	/**
	 *
	 * ПРОЧЕЕ
	 *
	 */

	/**
	 * Максимальное значений из массива
	 * @param numArray
	 * @returns {number}
	 */
	vm.getMaxOfArray = (numArray) => Math.max.apply(null, numArray);

	/**
	 * HTML-символ пробела
	 * @returns {string|*}
	 */
	vm.getSpace = () => String.fromCharCode(160);


	/**
	 * Возвращает дату первого дня учебной недели с номером studyWeekNumber
	 * @param studyWeekNumber {number} номер учебной недели
	 * @param academicYear {{}}
	 * @param format {string} формат, в котором нужно получить дату
	 * @returns {string}
	 */
	vm.getRealWeekByStudyWeekNumber = (studyWeekNumber, academicYear, format) => {
		const targetDate = moment(academicYear.begin_date, "YYYY-MM-DD");
		const $format = format || "L";
		// прибавляем недели
		if (studyWeekNumber > 1) {
			targetDate.add(studyWeekNumber - 1, "week");
		}

		return targetDate.format($format);
	};

	vm.getRandomColor = (excludeColors) => {
		const $excludeColors = excludeColors || [];
		const letters = "0123456789abcdef".split("");
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}

		// генерируем новый цвет, если сгенерированный цвет не подходит
		return ($excludeColors.indexOf(color) !== -1) ? vm.getRandomColor($excludeColors) : color;
	};

	/**
	 * Считаем и отображаем количество ватчеров
	 */
	vm.getWatchersCount = () => {
		const root = angular.element(document.getElementsByTagName("body"));
		const watchers = [];
		const watchersWithoutDuplicates = [];

		function f(element) {
			_.forEach(["$scope", "$isolateScope"], (scopeProperty) => {
				if (element.data() && element.data().hasOwnProperty(scopeProperty)) {
					_.forEach(element.data()[scopeProperty].$$watchers, (watcher) => watchers.push(watcher));
				}
			});
			_.forEach(element.children(), (childElement) => f(angular.element(childElement)));
		}

		f(root);

		_.forEach(watchers, (item) => {
			if (watchersWithoutDuplicates.indexOf(item) < 0) {
				watchersWithoutDuplicates.push(item);
			}
		});

		// console.log(watchersWithoutDuplicates.length)
		// console.log(watchers.length)
	};

	/**
	 * Возвращает точные координаты элемента на странице
	 * @param elem
	 * @returns {{top, left}|*}
	 */
	vm.getOffset = (elem) => {
		function getOffsetSum(elem) {
			let top = 0;
			let left = 0;
			let $e = elem;
			while ($e) {
				top = top + _.int($e.offsetTop);
				left = left + _.int($e.offsetLeft);
				$e = $e.offsetParent;
			}

			return {
				top,
				left
			};
		}

		function getOffsetRect(elem) {
			const box = elem.getBoundingClientRect();

			const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
			const clientTop = document.documentElement.clientTop || document.body.clientTop || 0;
			const clientLeft = document.documentElement.clientLeft || document.body.clientLeft || 0;
			const top = box.top + scrollTop - clientTop;
			const left = box.left + scrollLeft - clientLeft;

			return {
				top: Math.round(top),
				left: Math.round(left)
			};
		}

		// нет элемента - нет координат элемента
		if (!elem) {
			return {
				top: 0,
				left: 0
			};
		}

		return (elem.getBoundingClientRect) ? getOffsetRect(elem) : getOffsetSum(elem);
	};

	vm.executeSequentially = (promiseFactories) => {
		let result = Promise.resolve();
		_.forEach(promiseFactories, (promiseFactory) => {
			result = result.then(promiseFactory);
		});

		return result;
	};
}
