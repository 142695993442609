class URSNews {
	static $inject = ["Argus"];
	static URL = "/api/news";

	constructor(api) {
		this.api = api;
		this.collection = this.api.all(URSNews.URL);
	}


	/**
	 * BindOne
	 */
	bindOne(id) {
		return this.api.one(URSNews.URL, id);
	}
}

angular.module("ezd.backend")
	.service("URSNews", URSNews);
