angular
	.module("ezd.backend")
	.factory("AEFakeGroups", aeFakeGroups);

/**
 *
 * @param Core
 * @returns {*|Object}
 */
aeFakeGroups.$inject = ["Core"];
function aeFakeGroups(Core) {
	return Core.service("/api/ae_fake_groups");
}
