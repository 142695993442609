

angular
	.module("ezd.backend")
	.factory("StudentBag", StudentBag);


/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
StudentBag.$inject = ["Core"];
function StudentBag(Core) {
	return Core.service("/api/student_bag");
}

