angular
	.module("ezd.backend")
	.service("$$VkMar", $$VkMar);

$$VkMar.$inject = ["Restangular"];
function $$VkMar(Restangular) {
	const url = "vk-mar/api";
	const urlWords = url + "/words";

	const vm = this;

	vm.mapUserWord = mapUserWord;
	vm.getUserGuid = getUserGuid;

	/**
	 * Выдача кодового слова пользователю
	 */
	async function mapUserWord() {
		return Restangular.all(urlWords + "/map_user_word").customPOST();
	}

	/**
	 * Получение информации по слову
	 * используется для проверки
	 */
	async function getUserGuid(word) {
		return Restangular.all(urlWords + "/get_user_guid").customGET("", {
			word
		});
	}
}
