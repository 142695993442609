angular
	.module("ezd.backend")
	.factory("MedalCommissionGmcStatement", MedalCommissionGmcStatement);

MedalCommissionGmcStatement.$inject = ["REP"];

/**
 *
 * @param REP
 * @returns {Object|*}
 * @constructor
 */
function MedalCommissionGmcStatement(REP) {
	const service = REP.service("/api/medal_commission_sheet");
	const vm = this;


	vm.getList = getList;

	/**
	 *
	 */
	async function getList(query = {}) {
		vm.list = await service.getList(query);

		return vm.list;
	}


	return vm;
}

