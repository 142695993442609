import template from "./LeftMenu.html";


angular
	.module("ezd.common.ui")
	.component("leftMenu", {
		bindings: {
			restrict: "="
		},
		transclude: true,
		template,
		controller: Controller
	});


/**
 *
 * @param $rootScope
 * @constructor
 */
Controller.$inject = ["$rootScope"];
function Controller($rootScope) {
	const vm = this;

	vm.isShow = isShow;

	init();

	function init() {
		vm.restrict = vm.restrict || [];
	}


	function isShow() {
		if (_.isEmpty(vm.restrict)) {
			return true;
		}

		return _.includes(vm.restrict, $rootScope.refName);
	}
}

