

angular
	.module("ezd.backend")
	.factory("$$printConfig", printConfig);


/**
 *
 * @param Core
 * @returns {*|Object}
 */
printConfig.$inject = ["Core"];
function printConfig(Core) {
	return Core.service("/api/print_config");
}

