

angular
	.module("ezd.common.ui")
	.factory("$$focus", ["$timeout", "$window", function ($timeout, $window) {
		return function (id, delay) {
			delay = delay || 0;
			$timeout(() => {
				const element = $window.document.getElementById(id);

				if (element) {
					element.focus();
				}
			}, delay);
		};
	}]);
