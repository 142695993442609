import template from "./nonattendancesNotificationEditor.html";

import "./style.css";
import _find from "lodash/fp/find";
import _flow from "lodash/fp/flow";
import _getOr from "lodash/fp/getOr";

import {getPageString, getPageVisibility} from "components/backend/components/services/OrgLocalization";

angular
	.module("ezd.common.ui")
	.directive("nonattendancesNotificationEditor", Directive);


/**
 *
 * @returns {{template, controller: Controller, scope: {date: string, classUnit: string, student: string, students: string, actionOnClose: string, notifications: string, forParent: string, timetable: string}, controllerAs: string}}
 * @constructor
 */
function Directive() {
	const directive = {
		template,
		scope: {
			date: "=", // moment объект дня, для которого создается/редактируется оповещение о пропуске
			notifications: "=", // уведомления о пропусках
			student: "=", // профиль ученика
			students: "=", // открытие редактора для установки пропусков для всего класса
			classUnit: "=", // класс, если указан students
			timetable: "=", // расписание звонков, по которому учится ученик
			days: "=", // информация о днях, используется для определения является ли день выходным
			actionOnClose: "&",
			forParent: "=" // флаг означающий что эдитор открывается из интерфейса родителя
		},
		controller: Controller,
		controllerAs: "anController"
	};

	return directive;
}


/**
 *
 * @param $rootScope
 * @param $scope
 * @param $q
 * @param flashAlert
 * @param $$academicYear
 * @param $$attendanceNotification
 * @param $$nonattendanceReasons
 * @param $$lessonScheduleItem
 * @param $$profile
 * @param $$studentProfiles
 * @constructor
 */
Controller.$inject = ["$rootScope", "$scope", "$q", "flashAlert", "$$academicYear", "$$attendanceNotification", "$$nonattendanceReasons",
	"$$lessonScheduleItem", "$$profile", "$$studentProfiles", "EzdConfirm", "ECScheduleItems", "AEScheduleItems", "ECActivity", "AEGroups",
	"AEAttendanceNotification", "ECAttendanceNotification"];

/**
 * @param $rootScope
 * @param $scope
 * @param $q
 * @param flashAlert
 * @param $$academicYear
 * @param $$attendanceNotification
 * @param $$nonattendanceReasons
 * @param $$lessonScheduleItem
 * @param $$profile
 * @param $$studentProfiles
 * @param EzdConfirm
 * @param ECScheduleItems
 * @param AEScheduleItems
 * @param AEAttendanceNotification
 * @constructor
 */
function Controller($rootScope, $scope, $q, flashAlert, $$academicYear, $$attendanceNotification, $$nonattendanceReasons,
										$$lessonScheduleItem, $$profile, $$studentProfiles, EzdConfirm, ECScheduleItems, AEScheduleItems, ECActivity, AEGroups,
										AEAttendanceNotification, ECAttendanceNotification) {
	const anController = this;

	anController.getGlobalString = getPageString.bind(undefined, "$global");
	anController.getPageString = getPageString.bind(undefined, "a75dc7e01bfb11eebe560242ac120002");
	anController.getPageVisibility = getPageVisibility.bind(undefined, "a75dc7e01bfb11eebe560242ac120002");
	anController.allDayNotification = null;
	anController.bells = []; // звонки уроков для выбранного дня
	anController.lessons = []; // уроки ученика
	anController.ecLessons = [];
	anController.aeLessons = [];
	anController.notificationType = "day";
	anController.profile = null; // профиль текущего пользователя
	anController.year = null; // учебный год
	anController.attendanceReasonId = 1; // значение по-умолчанию причины пропуска
	anController.otherReasonId = 4; // id причины "Другое"
	anController.reasons = [];
	anController.description = null;

	anController.dateFrom = moment($scope.date);
	anController.dateTo = moment($scope.date);
	anController.dateFormatDatePicker = "DD.MM.YYYY";
	anController.dateFormatAttendanceNotification = "YYYY-MM-DD";

	anController.disableCreateAttendancesButton = false;

	anController.applyReasonToNewNotifications = applyReasonToNewNotifications;
	anController.existsBellAttendance = existsBellAttendance;
	anController.existsBellAeAttendance = existsBellAeAttendance;
	anController.existsBellEcAttendance = existsBellEcAttendance;
	anController.getDescriptionPlaceholder = getDescriptionPlaceholder;
	anController.toggleAttendancesNotificationForBell = toggleAttendancesNotificationForBell;
	anController.createAttendancesNotificationForDateRange = createAttendancesNotificationForDateRange;
	anController.removeAttendancesNotificationForDateRange = removeAttendancesNotificationForDateRange;
	anController.toggleNotificationType = toggleNotificationType;
	anController.toggleAeAttendancesNotification = toggleAeAttendancesNotification;
	anController.toggleEcAttendancesNotification = toggleEcAttendancesNotification;


	init();

	/** */
	function init() {
		$rootScope.global_promise = $q.all({
			year: $$academicYear.getSelected(),
			profile: $$profile.getCurrentProfile(),
			reasons: $$nonattendanceReasons.getList(),
			student: getStudentWithGroups()
		})
			.then((response) => {
				// признак отображения уведомлений на выходных
				anController.holidayLetters = anController.getPageVisibility("nonattendance_reasons_holiday_letters");
				anController.year = response.year;
				anController.profile = response.profile;
				// список доступных причин отсутствия для установки в журнале пропусков/табеле посещаемости
				// если "nonattendance_reasons_student" / "nonattendance_reasons_class_unit" не задан, то доступны все причины отсутствия
				const idsString = $scope.student ? anController.getPageString("nonattendance_reasons_student") : anController.getPageString("nonattendance_reasons_class_unit");
				const ids = _.map(_.filter(idsString.split(","), (id) => id.length), (id) => _.int(id));
				anController.reasons = _.filter(response.reasons, (reason) => _.includes(ids, reason.id) || !ids.length);
				anController.student = response.student;

				return getLessons();
			})
			.then((data) => {
				const subjectIds = anController.student ? _.map(anController.student.subjects, "id") : [];
				anController.lessons = _.filter(data.lessons, (lesson) => {
					return (_.indexOf(subjectIds, lesson.subject_id) > -1);
				});
				anController.ecLessons = data.ecLessons;
				anController.aeLessons = data.aeLessons;

				return $scope.student
					? $q.all({
						ecActivities: ECActivity.getList({class_unit_ids: $scope.student.class_unit_id}),
						aeGroups: AEGroups.getList({student_ids: $scope.student.id})
					})
					: {ecActivities: [], aeGroups: []};
			})
			.then((data) => {
				mapEcActivitiesToLessons(data.ecActivities);
				mapAeGroupsToLessons(data.aeGroups);
				mapAeNotificationToLessons();
				mapEcNotificationToLessons();
				makeData();
				$scope.$watch(() => anController.dateFrom + "-" + anController.dateTo,
					() => {
						makeAllDayNotificationInfo();
					});
			});
	}

	function mapAeNotificationToLessons() {
		const aeNotifications = _.filter($scope.notifications, {type: "ae"});

		_.forEach(anController.aeLessons, (lesson) => {
			const notification = _.find(aeNotifications, {
				begin_time: lesson.begin_time,
				datestring: moment($scope.date).format("YYYY-MM-DD")
			});

			if (notification) {
				lesson.notification = notification;
			} else {
				lesson.notification = {
					nonattendance_reason_id: anController.attendanceReasonId,
					description: null
				};
			}
		});
	}

	function mapEcNotificationToLessons() {
		const ecNotifications = _.filter($scope.notifications, {type: "ec"});

		_.forEach(anController.ecLessons, (lesson) => {
			const notification = _.find(ecNotifications, {
				begin_time: lesson.begin_time,
				datestring: moment($scope.date).format("YYYY-MM-DD")
			});

			if (notification) {
				lesson.notification = notification;
			} else {
				lesson.notification = {
					nonattendance_reason_id: anController.attendanceReasonId,
					description: null
				};
			}
		});
	}


	function mapAeGroupsToLessons(aeGroups) {
		_.forEach(anController.aeLessons, (lesson) => {
			lesson.subject_name = _flow(_find({id: lesson.ae_group_id}), _getOr(null, "name"))(aeGroups);
		});
	}

	function mapEcActivitiesToLessons(ecActivities) {
		_.forEach(anController.ecLessons, (lesson) => {
			lesson.subject_name = _flow(_find({id: lesson.ec_activity_id}), _getOr(null, "short_name"))(ecActivities);
		});
	}

	/** */
	function makeAllDayNotificationInfo() {
		const start = moment(anController.dateFrom, anController.dateFormatDatePicker).startOf("day");
		const end = moment(anController.dateTo, anController.dateFormatDatePicker).endOf("day");
		const allDayNotifications = _.filter($scope.notifications, (notification) => {
			return notification.date.isBetween(start, end) || notification.date.isSame(start);
		});

		let byDay = _.filter(allDayNotifications, (n) => {
			return n.bell_id === null || n.begin_time === null;
		});
		let byLessons = _.filter(allDayNotifications, (n) => {
			return n.bell_id || n.begin_time;
		});

		// для ДОУ уведомления на выходных не отображаются
		if (anController.holidayLetters === false && $scope.days && $scope.days.length) {
			byDay = _.filter(byDay, (n) => {
				const day = _.find($scope.days, (d) => d.date.isSame(n.date));

				return !(day && day.is_holiday === true);
			});
			byLessons = _.filter(byLessons, (n) => {
				const day = _.find($scope.days, (d) => d.date.isSame(n.date));

				return !(day && day.is_holiday === true);
			});
		}

		anController.notificationsInfo = {
			allDayLength: byDay.length,
			byLessonsLength: byLessons.length,
			allDay: _.groupBy(byDay, (notification) => {
				return notification.date.format(anController.dateFormatDatePicker);
			}),
			byLessons: _.groupBy(byLessons, (notification) => {
				return notification.date.format(anController.dateFormatDatePicker);
			})
		};
	}

	/** */
	function getStudentWithGroups() {
		if (!$scope.student) {
			return null;
		}

		return $$studentProfiles
			.bindOne($scope.student.id)
			.get({
				with_archived_groups: true,
				with_groups: true,
				with_subjects: true
			});
	}

	/**
	 * Собираем уроки, расписание звонков и уведомления о пропусках
	 */
	function makeData() { // great again?
		let weekDay;
		let allDayAttendanceNotification;

		if (Boolean($scope.timetable)) {
			weekDay = _.find($scope.timetable.bells_day_timetables, {week_day_id: $scope.date.isoWeekday()});
		}

		if (Boolean(weekDay)) {
			// обработка звонков расписания
			anController.bells = _.chain(weekDay.bells)
				.filter("is_lesson")
				.sortBy("begin_time")
				.map((bell) => {
					const bellStart = moment(bell.begin_time, "HH:mm");
					const bellEnd = moment(bell.end_time, "HH:mm");

					bell.start = moment($scope.date)
						.hours(bellStart.hours())
						.minutes(bellStart.minutes());

					bell.end = moment($scope.date)
						.hours(bellEnd.hours())
						.minutes(bellEnd.minutes());

					// уроки ученика для звонка (временного промежутка звонка)
					bell.lessons = _
						.chain(anController.lessons)
						.filter((lesson) => {
							const lessonDate = getLessonDateObject(lesson);

							return (lessonDate.isBetween(bell.start, bell.end)
								|| lessonDate.isSame(bell.start)
								|| lessonDate.isSame(bell.end));
						})
						.uniqBy("subject_id")
						.value();
					bell.notification = _.find($scope.notifications, (notification) => {
						return $scope.student && notification.bell_id === bell.id && notification.student_profile_id === $scope.student.id
							&& notification.date.isSame($scope.date);
					});

					if (!bell.notification) {
						bell.notification = {
							nonattendance_reason_id: anController.attendanceReasonId,
							description: null
						};
					}

					return bell;
				})
				.filter((bell) => !_.isEmpty(bell.lessons))
				.value();


			allDayAttendanceNotification = _.find($scope.notifications, (notification) => {
				return ((notification.bell_id === null) && notification.date.isSame($scope.date));
			});

			if (allDayAttendanceNotification) {
				anController.attendanceReasonId = allDayAttendanceNotification.nonattendance_reason_id;
				anController.description = allDayAttendanceNotification.description;
			}
		}
		// оповещение о пропуске всего дня
		anController.allDayNotification = _.find($scope.notifications, (notification) => {
			return ((notification.bell_id === null) && notification.date.isSame($scope.date));
		});
	}

	/**
	 * Загрузка уроков ученика
	 * @returns {*}
	 */
	async function getLessons() {
		if (!$scope.student) {
			return {lessons: [], ecLessons: [], aeLessons: []};
		}

		const fromToDate = $scope.date.format("YYYY-MM-DD");

		const params = {
			from: fromToDate,
			to: fromToDate,
			academic_year_id: anController.year.id,
			group_id: _.map($scope.student.groups, "id").join(","),
			student_profile_id: $scope.student.id,
			with_group_class_subject_info: true,
			cancelled: false
		};


		const ecParams = {
			academic_year_id: anController.year.id,
			student_profile_ids: $scope.student.id,
			begin_date: fromToDate,
			end_date: fromToDate
		};

		const aeParams = {
			student_profile_id: $scope.student.id,
			date_from: $scope.date.format("DD.MM.YYYY"),
			date_to: $scope.date.format("DD.MM.YYYY")
		};

		return $q.all({
			lessons: $scope.student.groups.length > 0 ? $$lessonScheduleItem.getList(params) : [],
			ecLessons: ECScheduleItems.collection.getList(ecParams),
			aeLessons: AEScheduleItems.collection.getList(aeParams)
		});
	}

	/** */
	function getLessonDateObject(lesson) {
		return moment()
			.year(lesson.date[0])
			.month(lesson.date[1] - 1)
			.date(lesson.date[2])
			.hour(lesson.time[0])
			.minutes(lesson.time[1])
			.seconds(lesson.time[2]);
	}

	/** */
	function applyReasonToNewNotifications(sourceBell) {
		if (anController.allDayNotification) {
			return;
		}

		anController.bells = _.forEach(anController.bells, (bell) => {
			if (!bell.notification.id) {
				bell.notification.nonattendance_reason_id = sourceBell.notification.nonattendance_reason_id;
				bell.notification.description = sourceBell.notification.description;
			}
		});

		anController.aeLessons = _.forEach(anController.aeLessons, (bell) => {
			if (!bell.notification.id) {
				bell.notification.nonattendance_reason_id = sourceBell.notification.nonattendance_reason_id;
				bell.notification.description = sourceBell.notification.description;
			}
		});

		anController.ecLessons = _.forEach(anController.ecLessons, (bell) => {
			if (!bell.notification.id) {
				bell.notification.nonattendance_reason_id = sourceBell.notification.nonattendance_reason_id;
				bell.notification.description = sourceBell.notification.description;
			}
		});
	}


	/** */
	function getDescriptionPlaceholder(nonattendanceReasonId) {
		switch (nonattendanceReasonId) {
			case 1 :
				return "Подробнее (по желанию)";
			case 2 :
				return "Самочувствие";
			case 3 :
				return "Семейные обстоятельства";
			case 4:
				return "Опишите причину";
			default:
				return "";
		}
	}

	/**
	 * Проверка наличия уведомления о пропуске для звонка из расписания звонков
	 * @param bell
	 * @returns {boolean}
	 */
	function existsBellAttendance(bell) {
		return Boolean(_.find($scope.notifications, (notification) => {
			try {
				return ((notification.bell_id === bell.id) && notification.date.isSame($scope.date));
			} catch (e) {
				console.error(e);
				console.error(bell);
				console.error(notification);
			}
		}));
	}

	function existsBellAeAttendance(aeLesson) {
		const aeNotifications = _.filter($scope.notifications, {type: "ae"});

		return Boolean(_.find(aeNotifications, (notification) => {
			return (aeLesson.begin_time === notification.begin_time && notification.date.isSame($scope.date));
		}));
	}

	function existsBellEcAttendance(ecLesson) {
		const ecNotifications = _.filter($scope.notifications, {type: "ec"});

		return Boolean(_.find(ecNotifications, (notification) => {
			return ecLesson.begin_time === notification.begin_time && notification.date.isSame($scope.date);
		}));
	}


	/**
	 * Создает/удаляет оповещение о пропуске для конкретного дня и звонка из расписания звонков
	 */
	function toggleAttendancesNotificationForBell(bell) {
		if (!$scope.student) {
			return flashAlert.error("Недоступно создание оповещений о пропуске " + anController.getGlobalString("lesson_gen_case"));
		}

		const notification = _.find($scope.notifications, (notice) => {
			return ((notice.bell_id === bell.id) && notice.date.isSame($scope.date));
		});

		// нет оповещения - создаем
		if (!notification) {
			$rootScope.global_promise = createNotification(bell, $scope.student.id)
				.then((notice) => {
					if (notice.route.indexOf("ec_attendance_notifications") > -1) {
						notice.type = "ec";
					} else if (notice.route.indexOf("ae_attendance_notifications") > -1) {
						notice.type = "ae";
					} else {
						notice.type = "common";
					}
					$scope.notifications.push(notice);
					flashAlert.success("Создано оповещение о пропуске " + anController.getGlobalString("lesson_gen_case"));
					bell.notification = notice;
					makeAllDayNotificationInfo();
				});
			// есть оповещение - удаляем
		} else if (confirm("Удалить оповещение о пропуске")) {
			$rootScope.global_promise = removeNotification(notification)
				.then(() => {
					_.remove($scope.notifications, {id: notification.id});
					flashAlert.success("Удалено оповещение о пропуске " + anController.getGlobalString("lesson_gen_case"));
					bell.notification = {
						nonattendance_reason_id: anController.attendanceReasonId,
						description: null
					};
					makeAllDayNotificationInfo();
				});
		}
	}

	/**
	 * Создание уведомлений на выбранном диапазоне дат.
	 * Если за этот день уже есть уведомления на некоторые уроки, они будут удалены.
	 */
	function createAttendancesNotificationForDateRange() {
		if (moment(anController.dateTo, anController.dateFormatDatePicker).isBefore(moment(anController.dateFrom, anController.dateFormatDatePicker))) {
			flashAlert.error("Установлены некорректные даты");

			return;
		}

		anController.disableCreateAttendancesButton = true;

		if (anController.notificationsInfo.byLessonsLength > 0) {
			EzdConfirm.confirm({
				title: "",
				parent: document.getElementsByTagName("attendances-notification-form"),
				text: "На выбранные даты уже созданы уведомления об отсутствии. "
					+ "Удалить ранее созданные уведомления и создать новые?",
				confirmButton: "Да",
				rejectButton: "Отмена"
			})
				.then(() => {
					const forRemove = _.chain(anController.notificationsInfo.byLessons)
						.values()
						.flatten()
						.value();

					let isNeedDeleteCommonNotices = false;
					let isNeedDeleteAENotices = false;
					let isNeedDeleteECNotices = false;

					_.map(forRemove, (notice) => {
						if (notice.type === "common") {
							isNeedDeleteCommonNotices = true;
						} else if (notice.type === "ae") {
							isNeedDeleteAENotices = true;
						} else {
							isNeedDeleteECNotices = true;
						}
					});

					_.forEach(forRemove, (notice) => {
						if (!notice) {
							return;
						}
						_.remove($scope.notifications, {id: notice.id});
					});
					mapAeNotificationToLessons();
					mapEcNotificationToLessons();

					createNotice(isNeedDeleteCommonNotices, isNeedDeleteAENotices, isNeedDeleteECNotices);
				})
				.catch(() => {
					anController.disableCreateAttendancesButton = false;
				});

			return;
		}

		createNotice();

		/**
		 * Создание уведомления на день
		 */
		function createNotice(isNeedDeleteCommonNotices, isNeedDeleteAENotices, isNeedDeleteECNotices) {
			const daysPromises = [];
			const endDate = moment(anController.dateTo, anController.dateFormatDatePicker);

			const students = $scope.student ? [$scope.student] : $scope.students;
			_.forEach(students, (student) => {
				let current = moment(anController.dateFrom, anController.dateFormatDatePicker);

				while (!current.isAfter(endDate)) {
					const currentDayNotification = _.filter($scope.notifications, {student_profile_id: student.id, datestring: current.format(anController.dateFormatAttendanceNotification)});

					if (currentDayNotification.length > 0) {
						daysPromises.push(...updateNotification(currentDayNotification));
					}

					if (!_.find(currentDayNotification, {type: "common"})) {
						daysPromises.push(createNotification(null, student.id, current.format(anController.dateFormatAttendanceNotification), isNeedDeleteCommonNotices));
					}
					if (!_.find(currentDayNotification, {type: "ec"})) {
						daysPromises.push(createAeDayNotification(student.id, current.format(anController.dateFormatAttendanceNotification), isNeedDeleteAENotices));
					}
					if (!_.find(currentDayNotification, {type: "ae"})) {
						daysPromises.push(createEcDayNotification(student.id, current.format(anController.dateFormatAttendanceNotification), isNeedDeleteECNotices));
					}

					current = current.add(1, "day");
				}
			});

			$rootScope.global_promise = $q.all(daysPromises)
				.then((notifications) => {
					_.forEach(notifications, (notification) => {
						if (notification.route.indexOf("ec_attendance_notifications") > -1) {
							notification.type = "ec";
							notification.date = moment(notification.date, "DD.MM.YYYY");
						} else if (notification.route.indexOf("ae_attendance_notifications") > -1) {
							notification.type = "ae";
							notification.date = moment(notification.date, "DD.MM.YYYY");
						} else {
							notification.type = "common";
							notification.date = moment(notification.date, "YYYY-MM-DD");
						}
						notification.datestring = notification.date.format("YYYY-MM-DD");

						const index = _.findIndex($scope.notifications, {id: notification.id, type: notification.type});
						if (index >= 0) {
							$scope.notifications.splice(index, 1, notification);
						} else {
							$scope.notifications.push(notification);
						}
					});

					flashAlert.success("Созданы оповещения о пропусках " + anController.getGlobalString("lessons_gen_case"));
					makeData();
					makeAllDayNotificationInfo();
				})
				.finally(() => {
					anController.disableCreateAttendancesButton = false;
				});
		}

		function createAeDayNotification(studentProfileId, date, isNeedDeleteAENotices) {
			const notification = {
				date: moment(date).format("DD.MM.YYYY"),
				begin_time: null,
				end_time: null,
				parent_profile_id: anController.profile.roles.indexOf("parent") !== -1 ? anController.profile.id : null,
				teacher_profile_id: anController.profile.roles.indexOf("parent") === -1 ? anController.profile.id : null,
				nonattendance_reason_id: anController.attendanceReasonId,
				description: anController.description,
				student_profile_id: studentProfileId
			};

			return AEAttendanceNotification.createNotification(notification, isNeedDeleteAENotices);
		}


		function createEcDayNotification(studentProfileId, date, isNeedDeleteECNotices) {
			const notification = {
				date: moment(date).format("DD.MM.YYYY"),
				begin_time: null,
				end_time: null,
				parent_profile_id: anController.profile.roles.indexOf("parent") !== -1 ? anController.profile.id : null,
				teacher_profile_id: anController.profile.roles.indexOf("parent") === -1 ? anController.profile.id : null,
				nonattendance_reason_id: anController.attendanceReasonId,
				description: anController.description,
				student_profile_id: studentProfileId
			};

			return ECAttendanceNotification.createNotification(notification, isNeedDeleteECNotices);
		}
	}

	/**
	 * Удалить уведомления на выбранном диапазоне дат
	 */
	function removeAttendancesNotificationForDateRange() {
		if (moment(anController.dateTo, anController.dateFormatDatePicker).isBefore(moment(anController.dateFrom, anController.dateFormatDatePicker))) {
			flashAlert.error("Установлены некорректные даты");

			return;
		}

		if (anController.notificationsInfo.allDayLength === 0
			&& anController.notificationsInfo.byLessonsLength === 0) {
			return;
		}

		anController.disableCreateAttendancesButton = true;

		const daysPromises = [];
		let current = moment(anController.dateFrom, anController.dateFormatDatePicker);
		const endDate = moment(anController.dateTo, anController.dateFormatDatePicker);

		const removedEcNotificationIds = [];
		const removedAeNotificationIds = [];

		while (!current.isAfter(endDate)) {
			const query = removeNotification(null, current.format(anController.dateFormatAttendanceNotification));

			// Проталкиваем запрос только, // 	если на данную дату нет уже созданного уведомления
			if (!_.isEmpty(query)) {
				daysPromises.push(query);
			}

			const aeNotifications = _.filter($scope.notifications, {datestring: current.format("YYYY-MM-DD"), type: "ae"});
			if (aeNotifications.length) {
				_.map(aeNotifications, (aeNotification) => {
					daysPromises.push(removeAeNotification(aeNotification));
					removedAeNotificationIds.push(aeNotification.id);
				});
			}

			const ecNotifications = _.filter($scope.notifications, {datestring: current.format("YYYY-MM-DD"), type: "ec"});
			if (ecNotifications.length) {
				_.map(ecNotifications, (ecNotification) => {
					daysPromises.push(removeEcNotification(ecNotification));
					removedEcNotificationIds.push(ecNotification.id);
				});
			}

			current = current.add(1, "day");
		}

		$rootScope.global_promise = $q.all(daysPromises)
			.then((notifications) => {
				_.forEach(_.flatten(notifications), (notification) => {
					if (!notification) {
						return;
					}
					_.remove($scope.notifications, {id: notification.id});
				});

				_.forEach(removedEcNotificationIds, (id) => {
					_.remove($scope.notifications, {id, type: "ec"});

					const findedLesson = _.find(anController.ecLessons, (lesson) => {
						return lesson.notification.id === id;
					});

					if (findedLesson) {
						findedLesson.notification = {
							nonattendance_reason_id: anController.attendanceReasonId,
							description: null
						};
					}
				});

				_.forEach(removedAeNotificationIds, (id) => {
					_.remove($scope.notifications, {id, type: "ae"});

					const findedLesson = _.find(anController.aeLessons, (lesson) => {
						return lesson.notification.id === id;
					});

					if (findedLesson) {
						findedLesson.notification = {
							nonattendance_reason_id: anController.attendanceReasonId,
							description: null
						};
					}
				});

				flashAlert.success("Удалены оповещения о пропусках " + anController.getGlobalString("lessons_gen_case"));
				makeData();
				makeAllDayNotificationInfo();
			})
			.finally(() => {
				anController.disableCreateAttendancesButton = false;
			});
	}

	/**
	 * Создание увдовления
	 * @returns {*}
	 */
	function createNotification(bell, studentProfileId, date, isNeedDeleteCommonNotices) {
		return $q((resolve) => {
			const reasonId = (bell && bell.notification && bell.notification.nonattendance_reason_id)
				? bell.notification.nonattendance_reason_id
				: anController.attendanceReasonId;
			const reasonDescription = (bell && bell.notification && bell.notification.description)
				? bell.notification.description
				: anController.description;


			$$attendanceNotification.createNotification({
				date: date || $scope.date.format("YYYY-MM-DD"),
				bell_id: bell ? bell.id : null,
				student_profile_id: studentProfileId,
				nonattendance_reason_id: reasonId,
				parent_profile_id: anController.profile.roles.indexOf("parent") !== -1 ? anController.profile.id : null,
				teacher_profile_id: anController.profile.roles.indexOf("parent") === -1 ? anController.profile.id : null,
				description: reasonDescription
			}, isNeedDeleteCommonNotices)
				.then((data) => {
					resolve(data);
				});
		});
	}

	/**
	 * lastNoticeByType
	 */
	function lastNoticeByType(notices, type) {
		const noticesByType = _.filter(notices, {type});
		if (_.isEmpty(noticesByType)) {
			return null;
		}
		const maxId = _.max(_.map(noticesByType, "id"));

		return _.find(noticesByType, {id: maxId});
	}

	/**
	 * Обновить уведомление о пропуске на день
	 * @param currentDayNotification
	 */
	function updateNotification(currentDayNotification) {
		const promises = [];

		// Если есть дубликаты уведомлений, то обновляем только последние (по id) каждого типа
		// Бэк, в свою очередь, удалит конфликтующие записи
		const lastIds = _.map([
			lastNoticeByType(currentDayNotification, "common") || [],
			lastNoticeByType(currentDayNotification, "ae") || [],
			lastNoticeByType(currentDayNotification, "ec") || []
		], "id");
		const conflictNotices = _.filter(currentDayNotification, (notice) => !_.includes(lastIds, notice.id));
		if (conflictNotices.length) {
			_.remove($scope.notifications, (notice) => _.find(conflictNotices, {id: notice.id, type: notice.type}));
		}
		const isNeedDeleteNotices = true; // Boolean(conflictNoticeIds.length);

		_.forEach(currentDayNotification, (notice) => {
			if (_.includes(lastIds, notice.id)) {
				const updatedNotice = {...notice};
				notice.nonattendance_reason_id = anController.attendanceReasonId;
				updatedNotice.nonattendance_reason_id = anController.attendanceReasonId;
				notice.description = anController.description;
				updatedNotice.description = anController.description;
				// кто-то решил перекручивать created_at в сервисе при получении.
				// при апдйте jackson не может распарсить дату, но она в контроллере
				// на бэке не нужна. Поэтому выставляем её в null
				updatedNotice.created_at = null;

				if (updatedNotice.type === "common") {
					updatedNotice.date = notice.date.format("YYYY-MM-DD");
					promises.push($$attendanceNotification.one().customPUT(updatedNotice, null, {is_need_delete_notices: isNeedDeleteNotices}));
				} else if (updatedNotice.type === "ae") {
					updatedNotice.date = notice.date.format("DD.MM.YYYY");
					promises.push(AEAttendanceNotification.one(updatedNotice.id).customPUT(updatedNotice, null, {is_need_delete_notices: isNeedDeleteNotices}));
				} else {
					updatedNotice.date = notice.date.format("DD.MM.YYYY");
					promises.push(ECAttendanceNotification.one(updatedNotice.id).customPUT(updatedNotice, null, {is_need_delete_notices: isNeedDeleteNotices}));
				}
			}
		});

		return promises;
	}

	/**
	 * Удаление уведомления на конкретный урок или за выбранный день
	 * @param notification
	 * @param date
	 * @returns {*}
	 */
	function removeNotification(notification, date) {
		// если notification = null, значит удаляем уведомление за весь день
		const notifications = notification ? [notification] : _.filter($scope.notifications, {date: moment(date, "YYYY-MM-DD"), type: "common"});

		const promises = _.map(notifications, (notice) => {
			return $$attendanceNotification.removeNotification(notice);
		});

		return $q.all(promises);
	}

	function removeAeNotification(notification) {
		return AEAttendanceNotification.removeNotification(notification);
	}

	function removeEcNotification(notification) {
		return ECAttendanceNotification.removeNotification(notification);
	}

	/**
	 * Переключение между вкладками (уведомления на отдельные уроки / по дням)
	 */
	function toggleNotificationType() {
		if (!anController.lessons.length && !anController.aeLessons.length && !anController.ecLessons.length) {
			return;
		}
		if (anController.notificationType === "day") {
			anController.notificationType = "lesson";
		} else {
			anController.notificationType = "day";
		}
	}

	function toggleAeAttendancesNotification(lesson) {
		if (!$scope.student) {
			return flashAlert.error("Недоступно создание оповещений о пропуске " + anController.getGlobalString("lesson_gen_case"));
		}

		mapAeNotificationToLessons();
		const findedLesson = _.find(anController.aeLessons, {begin_time: lesson.begin_time});

		if (findedLesson.notification && findedLesson.notification.id) {
			if (confirm("Удалить оповещение о пропуске")) {
				$rootScope.global_promise = removeAeNotification(findedLesson.notification)
					.then(() => {
						flashAlert.success("Удалено оповещение о пропуске " + anController.getGlobalString("lesson_gen_case"));
						_.remove($scope.notifications, {id: findedLesson.notification.id});
						mapAeNotificationToLessons();
						makeAllDayNotificationInfo();
					});
			}
		} else {
			const notification = {
				date: $scope.date.format("DD.MM.YYYY"),
				begin_time: lesson.begin_time,
				end_time: lesson.end_time,
				parent_profile_id: anController.profile.roles.indexOf("parent") !== -1 ? anController.profile.id : null,
				teacher_profile_id: anController.profile.roles.indexOf("parent") === -1 ? anController.profile.id : null,
				nonattendance_reason_id: findedLesson.notification.nonattendance_reason_id,
				description: findedLesson.notification.description,
				student_profile_id: $scope.student.id
			};

			AEAttendanceNotification.createNotification(notification)
				.then((data) => {
					flashAlert.success("Создано оповещение о пропуске " + anController.getGlobalString("lesson_gen_case"));
					_.assign(data, {
						date: $scope.date,
						type: "ae"
					});
					$scope.notifications.push(data);
					mapAeNotificationToLessons();
					makeAllDayNotificationInfo();
				});
		}
	}

	function toggleEcAttendancesNotification(lesson) {
		if (!$scope.student) {
			return flashAlert.error("Недоступно создание оповещений о пропуске " + anController.getGlobalString("lesson_gen_case"));
		}

		mapEcNotificationToLessons();
		const findedLesson = _.find(anController.ecLessons, {begin_time: lesson.begin_time});

		if (findedLesson.notification && findedLesson.notification.id) {
			if (confirm("Удалить оповещение о пропуске")) {
				$rootScope.global_promise = removeEcNotification(findedLesson.notification)
					.then(() => {
						flashAlert.success("Удалено оповещение о пропуске " + anController.getGlobalString("lesson_gen_case"));
						_.remove($scope.notifications, {id: findedLesson.notification.id});
						mapEcNotificationToLessons();
						makeAllDayNotificationInfo();
					});
			}
		} else {
			const notification = {
				date: $scope.date.format("DD.MM.YYYY"),
				begin_time: lesson.begin_time,
				end_time: lesson.end_time,
				parent_profile_id: anController.profile.roles.indexOf("parent") !== -1 ? anController.profile.id : null,
				teacher_profile_id: anController.profile.roles.indexOf("parent") === -1 ? anController.profile.id : null,
				nonattendance_reason_id: findedLesson.notification.nonattendance_reason_id,
				description: findedLesson.notification.description,
				student_profile_id: $scope.student.id
			};

			ECAttendanceNotification.createNotification(notification)
				.then((data) => {
					flashAlert.success("Создано оповещение о пропуске " + anController.getGlobalString("lesson_gen_case"));
					_.assign(data, {
						date: $scope.date,
						type: "ec"
					});
					$scope.notifications.push(data);
					mapEcNotificationToLessons();
					makeAllDayNotificationInfo();
				});
		}
	}
}

