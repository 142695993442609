angular
	.module("ezd.backend")
	.factory("ECActivity", ecActivity);


/**
 *
 * @param EC
 * @returns {*|Object}
 */
ecActivity.$inject = ["EC"];

function ecActivity(EC) {
	const service = EC.service("/api/ec_activity");

	service.getTemplate = getTemplate;
	service.updateProgram = updateProgram;
	service.removeProgram = removeProgram;
	service.copyProgram = copyProgram;

	return service;


	/**
	 *
	 */
	function getTemplate() {
		return {
			education_level_id: null,
			class_level_ids: null,
			ec_field_id: null,
			ec_form_id: null,
			ec_course_lesson_plan_id: null,
			ec_course_calendar_plan_ids: [],
			category_id: null,
			name: "",
			short_name: "",
			author_profile_id: null,
			description: ""
		};
	}


	/**
	 *
	 */
	function updateProgram(program) {
		return service.one(program.id).customPUT(program);
	}

	/**
	 * Удаление рабочей программы
	 * @param program
	 * @returns {*}
	 */
	function removeProgram(program) {
		return service.one(program.id).customDELETE();
	}


	/**
	 * Копирование рабочей программы
	 * @param program
	 * @returns {*}
	 */
	function copyProgram(program) {
		return service.one(program.id).customPOST({}, "copy");
	}
}
