angular
	.module("ezd.backend")
	.factory("$$aclRoles", aclRoles);

/**
 *
 * @param ACL
 * @returns {*|Object}
 */
aclRoles.$inject = ["ACL"];
function aclRoles(ACL) {
	return ACL.service("/api/acl_roles");
}
