const URL = "/api/academic_debts";

/**
 * Сервис для работы с апи академической задолженности
 */
class AcademicDebtService {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.CoreService.one(URL, id);
	}

	/**
	 * редактирование академической задолженности
	 * @param debt
	 */
	updateDebt(debt) {
		return this.bindOne(debt.id).customPUT(debt);
	}
}


angular
	.module("ezd.backend")
	.service("AcademicDebt", AcademicDebtService);
