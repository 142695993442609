angular
	.module("ezd.backend")
	.service("ECMarks", ECMarks);

/**
 *
 * @param EC
 */
ECMarks.$inject = ["EC"];
function ECMarks(EC) {
	const url = "/api/ec_marks";
	const vm = this;

	vm.list = [];
	vm.collection = EC.all(url);
	vm.collection.getList = (query) => EC.all(url).getList(query);

	vm.validateMark = validateMark;
	vm.validateJournalMarkValue = validateJournalMarkValue;
	vm.prepareMarkValues = prepareMarkValues;


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => EC.one(url, id);


	/**
	 * Оставил метод для обратной совместимости
	 * @param num
	 * @param filter
	 * @returns {*|Array|Object|{method, params, headers}}
	 */
	vm.getPage = (num, filter) => {
		let params = { page: num, per_page: 50 };

		if (_.keys(filter).length !== 0) {
			params = angular.extend(params, filter);
		}

		return EC.all(url).getList(params);
	};


	function validateMark(mark) {
		let result = false;

		_.forEach(mark.values, (value) => {
			const originValue = _.get(value, "grade.origin");
			const isPoint = mark.is_point && !originValue;

			result = isPoint || validateJournalMarkValue(originValue, mark.grade_system_type, _.get(value, "nmax"));

			if (!result) {
				return false;
			}
		});

		return result;
	}

	function validateJournalMarkValue(value, type, nmax) {
		const journalPatterns = {
			five: {
				pattern: /^[0-5]$/,
				min: 0,
				max: 5
			},
			hundred: {
				pattern: /^(\d{1,3})+((\.|,)+\d{1,2})?$/,
				min: 0,
				max: 100
			},
			abc: {
				pattern: /^[abcdfабсдф]$/i
			},
			decimal: {
				pattern: /^(\d{1,2})+((\.|,)+\d{1,2})?$/,
				min: 0,
				max: 10
			},
			approve: {
				pattern: /^З|^Н|^з|^н|^Зачет|^Незачет|^Зачёт|^Незачёт|^зачет|^незачет|^зачёт|^незачёт$/
			},
			nscale: {
				pattern: /^(\d{1,3})+((\.|,)+\d{1,2})?$/,
				min: 0,
				max: _.float(nmax) || 100
			},
			criterial: {
				pattern: /^(\d{1,3})+((\.|,)+\d{1,2})?$/,
				min: 0,
				max: _.float(nmax) || 100
			}
		};

		const checkedValue = _.float(value);
		switch (type) {
			case "approve":
			case "abc":
				return journalPatterns[type].pattern.test(value);
				break;

			default:
				return journalPatterns[type].pattern.test(checkedValue)
					&& (checkedValue >= journalPatterns[type].min)
					&& (checkedValue <= journalPatterns[type].max);
				break;
		}
	}

	function prepareMarkValues(mark) {
		mark.values = _.map(mark.values, (value) => {
			value.grade.origin = (value.grade.origin ? value.grade.origin.toString() : "").replace(/,/, ".");

			return value;
		});
	}
}
