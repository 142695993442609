

angular
	.module("ezd.backend")
	.factory("$$discrepancy", certSubjects);


/**
 *
 * @param Core
 * @param $q
 * @returns {*|Object}
 */
certSubjects.$inject = ["Core", "$q"];
function certSubjects(Core, $q) {
	const vm = Core.service("/api/discrepancy");

	vm.has_not_applied = false;

	vm.count = count;


	/**
	 *
	 * @returns {*}
	 */
	function count() {
		if (!vm.has_not_applied) {
			return vm.one("count", null)
				.get()
				.then((data) => {
					vm.has_not_applied = data.has_not_applied;
				});
		}

		return $q((resolve) => {
			resolve(vm.has_not_applied);
		});
	}

	return vm;
}

