import "./dropdown-profile-list.scss";
import template from "./dropdownProfileList.html";


angular
	.module("ezd.common.ui")
	.component("dropdownProfileList", {
		controller: Controller,
		template
	});

Controller.$inject = ["$rootScope", "$scope", "$q", "$cookies", "$location", "$$academicYear", "$$profile", "$$studentProfiles", "$$user", "$$history", "$$userSelectProfile"];

/**
 *
 * @param $rootScope
 * @param $scope
 * @param $q
 * @param $cookies
 * @param $location
 * @param $$academicYear
 * @param $$profile
 * @param $$studentProfiles
 * @param $$user
 * @param $$history
 * @param $$userSelectProfile
 * @constructor
 */
function Controller($rootScope, $scope, $q, $cookies, $location, $$academicYear, $$profile, $$studentProfiles, $$user, $$history, $$userSelectProfile) {
	const vm = this;

	vm.icons = {
		dropdown: require("images/icons/ic_arrow_drop_down_white_24px.svg"),
		dropup: require("images/icons/ic_arrow_drop_up_white_24px.svg")
	};

	vm.year = null;
	vm.showList = false;
	vm.currentProfile = null;
	vm.childrenProfile = null;
	vm.profilesList = [];
	vm.childrenList = [];


	vm.getChildrenName = getChildrenName;
	vm.getSelectedProfileTitle = getSelectedProfileTitle;
	vm.isSelectedProfile = isSelectedProfile;
	vm.selectProfile = selectProfile;
	vm.openProfileMenu = openProfileMenu;
	vm.redirectParent = redirectParent;


	init();


	/**
	 *
	 */
	function init() {
		$q.all({
			year: $$academicYear.getSelected(),
			profile: $$profile.getCurrentProfile()
		})
			.then((data) => {
				vm.year = data.year;
				vm.currentProfile = data.profile;
				$$profile.castTypesToRolesForProfile(vm.currentProfile);
				// если выбранный профиль
				if (data.profile.roles.indexOf("parent") !== -1) {
					return getChildren();
				}
			})
			.then((children) => {
				setChildren(children);
			});
		// отслеживаем изменение списка профилей
		$scope.$watchCollection(() => $$profile.list, (newVal, oldVal) => {
			vm.profilesList = $$profile.list;
			if ((newVal.length && oldVal.length) && (!_.isEqual(_.sortBy(newVal, "id"), _.sortBy(oldVal, "id"))
					|| vm.profilesList.length === 0)) {
				$$profile.castTypesToRoles();
			}
		});
	}


	/**
	 *
	 * @returns {*}
	 */
	function getChildren() {
		return $$studentProfiles.getStudentsListForParent();
	}


	/**
	 *
	 * @param profiles
	 */
	function setChildren(profiles) {
		vm.childrenList = profiles || [];
		if (vm.childrenList.length > 0) {
			_.forEach(vm.childrenList, (child) => {
				child.roles = ["student"];
			});
			// default profile
			vm.childrenProfile = getDefaultChildrenProfile(vm.childrenList);
			$$studentProfiles.setCurrentProfile(vm.childrenProfile);
		} else if (vm.currentProfile.roles.indexOf("parent") !== -1) {
			// stop loader
			$rootScope.global_promise = {};
		}
	}


	/**
	 *
	 * @param profiles
	 * @returns {T|*}
	 */
	function getDefaultChildrenProfile(profiles) {
		return _.find(profiles, {id: _.int($cookies.get("student_id"))}) || profiles[0];
	}


	/**
	 * Переключает видимость списка
	 */
	function openProfileMenu($mdOpenMenu, $event) {
		$mdOpenMenu($event);
	}


	/**
	 * Выбирает профиль при клике на него в выпадающем списке
	 * @param profile {{}} объект профиля
	 */
	function selectProfile(profile) {
		// Очищаем историю при имперсонации
		$$history.clearHistory()
			.then(() => {
				// если текущий профиль родитель и выбран ребенок
				if ((profile.roles.indexOf("student") !== -1) && (vm.currentProfile.roles.indexOf("parent") !== -1)) {
					if (profile.id === vm.childrenProfile.id) {
						return;
					}
					vm.currentProfile = profile;
					$$studentProfiles
						.setCurrentProfile(profile)
						.then(() => {
							const path = $location.path();
							// дневник ученика
							if (path.search("manage/student_journal") !== -1) {
								$location.path("/manage/student_journal/" + profile.id);

								// домашнее задание ученика
							} else if (path.search("education/homework") !== -1) {
								$location.path("/education/homework/" + profile.id);

								// расписание звонков ученика
							} else if (path.search("refs/timetables/view") !== -1) {
								$$studentProfiles
									.getTimeTableId()
									.then((timetableId) => {
										$location.url("/refs/timetables/editor?id=" + timetableId);
									});

								// график учебных периодов ученика
							} else if (path.search("/refs/study_periods/edit") !== -1) {
								$$studentProfiles
									.getCurrentProfile()
									.then((studentProfile) => {
										return $$studentProfiles.getStudentPeriodsSchedule(studentProfile);
									})
									.then((periodsSchedule) => {
										if (periodsSchedule) {
											$location.path(`/refs/study_periods/edit/${periodsSchedule.id}`);
											location.reload();
										} else {
											document.location.reload(true);
										}
									});

								// Непрохождение промежуточной аттестации
							} else if (path.search("/reports/academic_debt") !== -1) {
								$location.path("/desktop");

								// остальные экраны
							} else {
								document.location.reload(true);
							}
						})
						.catch((err) => console.error(err));
					// текущий профиль не ребенок и не родитель
				} else if (vm.currentProfile.id !== profile.id) {
					$$userSelectProfile.send(profile);
					vm.currentProfile = profile;
					$$profile.setCookies(profile);
					if (vm.currentProfile.type === "observer" || vm.currentProfile.type === "staff") {
						document.location.href = "/admin/schools";

						return;
					}

					if (profile.type === "student") {
						document.location.href = `/student_diary/student_diary/${profile.id}`;

						return;
					}

					if (profile.type === "parent") {
						vm.redirectParent(profile);

						return;
					}

					document.location.href = "/desktop";
				}
			});
	}

	/**
	 * Редирект родителя на дневник ребенка
	 * @param profile
	 */
	function redirectParent(profile) {
		if (profile.children_profile_ids) {
			return $$studentProfiles.getList({
				ids: profile.children_profile_ids.join(",")
			})
				.then((studentProfiles) => {
					return setChildren(studentProfiles);
				})
				.then(() => {
					document.location.href = `/student_diary/student_diary/${vm.childrenProfile.id}`;
				});
		}

		return $$user.bindOne(profile.user_id).get()
			.then((user) => {
				const ids = _.chain(user.profiles).find({id: profile.id}).get("children_profile_ids").value();

				return $$studentProfiles.getList({ids})
					.then((studentProfiles) => {
						return setChildren(studentProfiles);
					})
					.then(() => {
						document.location.href = `/student_diary/student_diary/${vm.childrenProfile.id}`;
					});
			});
	}

	/**
	 *
	 * @returns {*}
	 */
	function getSelectedProfileTitle() {
		if (!vm.currentProfile) {
			return "";
		}
		if (vm.currentProfile.roles.indexOf("parent") !== -1) {
			return getChildrenName(vm.childrenProfile);
		}

		return (vm.currentProfile.is_impersonated)
			? vm.currentProfile.user_name
			: vm.currentProfile.school_shortname;
	}


	/**
	 *
	 * @param profile
	 * @returns {boolean}
	 */
	function isSelectedProfile(profile) {
		if (!vm.currentProfile) {
			return false;
		}

		if ((vm.currentProfile.roles.indexOf("parent") !== -1)
			&& (profile.roles.indexOf("student") !== -1)) {
			return profile.id === vm.childrenProfile.id;
		}

		return profile.id === vm.currentProfile.id;
	}


	/**
	 *
	 * @param profile
	 * @returns {*}
	 */
	function getChildrenName(profile) {
		let profileName;

		if (!profile) {
			return "";
		}

		profileName = (!_.isEmpty(profile) && profile.user_name) ? profile.user_name.split(" ") : [];

		profileName = _.map(profileName, (partOfName, index) => {
			// полная фамилия
			if (index === 0) {
				return `${partOfName} `;
				// первый символ имени в верхнем регистре
			} else if (partOfName) {
				return partOfName.charAt(0).toUpperCase() + ".";
			}

			return partOfName;
		});

		if (profile.class_unit) {
			profileName.push(" | " + profile.class_unit.name);
		}

		return "уч. " + profileName.join("");
	}
}

