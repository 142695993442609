

angular
	.module("ezd.backend")
	.factory("$$testLessons", testLessons);


/**
 *
 * @param Jersey
 * @returns {Object|*}
 */
testLessons.$inject = ["Jersey"];
function testLessons(Jersey) {
	const service = Jersey.service("/api/test_lessons");

	// bindings
	service.approve = approve;

	return service;

	// implementation
	function approve(all) {
		return Jersey.all("/api/test_lessons").customPUT(all);
	}
}
