angular
	.module("ezd.backend")
	.factory("AELessonPlans", aeLessonPlans);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeLessonPlans.$inject = ["Core"];
function aeLessonPlans(Core) {
	return Core.service("/api/ae_lesson_plans");
}

