import {Component} from "app/decorators/index";
import styles from "./support-form-open-link.local.scss";
import template from "./support-form-open-link.template.html";

@Component({
	selector: "supportFormOpenLink",
	template,
	bindings: {
		blackText: "<"
	}
})
/**
 * Компонент, открывающий ФОС (чтобы везде не прописывать одно и то же)
 */
export class SupportFormOpenLinkComponent {
	static $inject = ["EzdSupportFormService"];

	constructor(EzdSupportFormService) {
		this.services = {EzdSupportFormService};
		this.styles = styles;
	}

	/**
	 *
	 */
	openSupportForm() {
		const {EzdSupportFormService} = this.services;

		EzdSupportFormService.openSupportForm();
	}
}
