

angular
	.module("ezd.backend")
	.factory("$$services", services);

/**
 *
 * @param Jersey
 * @returns {Object|*}
 */
services.$inject = ["Jersey"];
function services(Jersey) {
	return Jersey.service("/api/services");
}