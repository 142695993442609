

angular
	.module("ezd.backend")
	.service("$$history", HistoryService);


/**
 *
 * @param $location
 * @param $q
 * @param $$profile
 * @constructor
 */
HistoryService.$inject = ["$location", "$q", "$$profile"];
function HistoryService($location, $q, $$profile) {
	const vm = this;
	let history = [];

	/**
	 * массив урлов, которые не должны попадать в историю посещения экранов<
	 * @type {string[]}
	 */
	const excludeUrls = [
		"", "/", "/auth"
	];

	vm.addToHistory = addToHistory;
	vm.clearHistory = clearHistory;
	vm.getHistory = getHistory;
	vm.goBack = goBack;
	vm.replaceInHistory = replaceInHistory;
	vm.setHistory = setHistory;

	/**
	 * Добавляет урл или объект в историю посещения экранов,
	 * если backUrl объект, то будет выполнен метод
	 * fakeGoBack с аргументами из массива args
	 * при следующем нажатии по кнопке назад
	 *
	 * @param backUrl {string, object}
	 *
	 * backUrl = {
		 *  fakeGoBack: {function},
		 *  args: {array}
		 * }
	 */
	function addToHistory(backUrl) {
		console.log("add to history: ", backUrl);

		return getHistory()
			.then((localHistory) => {
				let $localHistory = localHistory || [];
				// добавляем callback для имитации перехода
				// в местах где не изменяется url
				if (typeof backUrl === "object") {
					$localHistory.push(backUrl);

					return setHistory($localHistory);
				}
				// добавляем url в историю
				if (typeof backUrl === "string" && _.last($localHistory) !== backUrl && excludeUrls.indexOf(backUrl) === -1) {
					$localHistory = $localHistory.filter((item) => typeof item === "string");
					$localHistory.push(backUrl);

					return setHistory($localHistory);
				}
			});
	}

	/**
	 * Удаляет всю историю посещения экранов
	 * @returns {*}
	 */
	function clearHistory() {
		return $q((resolve) => {
			history = [];
			resolve(history);
		});
	}

	/**
	 * Возвращает всю историю посещения экранов
	 * @returns {*}
	 */
	function getHistory() {
		return $q((resolve) => resolve(history));
	}

	/**
	 * переход на предыдущий экран
	 * @returns {*}
	 */
	function goBack(pageParams) {
		let backUrl = null;

		return getHistory()
			.then((localHistory) => {
				backUrl = localHistory.pop();
				if (typeof backUrl === "object") {
					return setHistory(localHistory);
				}
				if (backUrl === $location.url()) {
					backUrl = localHistory.pop();
				}
				if (pageParams) {
					backUrl += pageParams;
				}

				return setHistory(localHistory);
			})
			.then(() => {
				$$profile.getCurrentProfile()
					.then((data) => {
						if (backUrl) {
							if (typeof backUrl === "object") {
								Boolean(backUrl.args)
									? backUrl.fakeGoBack(...backUrl.args)
									: backUrl.fakeGoBack();

								return;
							}
							// $window.location очищает данные в js, поэтому вернуться назад мы можем только один раз.
							// лучше использовать $location.url(), тогда сохранится вся цепочка переходов.
							// либо нужно вручную менять историю переходов в каждом роуте
							$location.url(backUrl);
						} else {
							data.type === "student"
								? $location.url("/desktop")
								: $location.url("/common/schedule");
						}
					});
			});
	}

	/**
	 * Заменяет историю повещения экранов на новую, начиная с указанного урла.
	 * если не найден заменяемый урл, то новые урлы будут просто добавлены к истории посещения экранов.
	 * @param replacedUrl {string} заменяемый урл
	 * @param newURLs {string[]} новые урлы
	 * @returns {*}
	 */
	function replaceInHistory(replacedUrl, newURLs) {
		return getHistory()
			.then((localHistory) => {
				const replaceIndex = _.lastIndexOf(localHistory, replacedUrl);
				let $localHistory = localHistory;

				if (replaceIndex > -1) {
					$localHistory = _.slice(localHistory, 0, replaceIndex);
				}

				_.forEach(newURLs, (url) => $localHistory.push(url));

				return setHistory($localHistory);
			});
	}

	/**
	 * Сохраняет новую историю посещений экранов
	 * @param newHistory {string[]} - массив урлов для истории посещения экранов
	 * @returns {*}
	 */
	function setHistory(newHistory) {
		return $q((resolve) => {
			history = newHistory || [];
			resolve(newHistory);
		});
	}
}

