import {Component} from "app/decorators/index";
import template from "./ezd-crop-image.html";
import styles from "./ezd-crop-image.local.scss";

import {EzdCropImageEditorController} from "../components/popup-editor";
import editorTemplate from "../components/popup-editor/ezd-crop-image_editor.html";


@Component({
	selector: "ezdCropImage",
	template,
	transclude: true,
	bindings: {
		model: "="
	}
})
export class EzdCropImageComponent {
	static $inject = ["$mdDialog", "$scope", "$element", "$timeout"];

	/**
	 * CONSTRUCTOR
	 */
	constructor($mdDialog, $scope, $element, $timeout) {
		this.styles = styles;
		this.services = {
			$mdDialog, $scope, $element, $timeout
		};

		this.inputId = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
		this.input = null;
	}


	/**
	 * ONINIT
	 */
	$onInit() {
		const {$element} = this.services;

		this.input = $element.find("input")[0];
		this.input.addEventListener("change", this.changeFiles.bind(this), false);
	}


	/**
	 * ONDESTROY
	 */
	$onDestroy() {
		this.input.removeEventListener("change", this.changeFiles);
	}


	changeFiles(event) {
		const file = event.target.files[0];

		if (!/image.*/i.test(file.type)) {
			return;
		}

		const reader = new FileReader();
		reader.onload = this.$openEditor.bind(this);

		reader.readAsDataURL(file);
	}


	$openEditor(event) {
		const {$mdDialog} = this.services;

		$mdDialog.show({
			parent: angular.element(document.body),
			clickOutsideToClose: false,
			locals: {
				image: event.target.result
			},
			template: editorTemplate,
			controller: EzdCropImageEditorController,
			controllerAs: "$ctrl",
			fullscreen: true
		})
			.then(({croppedImage}) => {
				this.model = croppedImage;
			});
	}
}