const URL = "/api/ae_schedule_items";

class AEScheduleItemsService {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.CoreService.one(URL, id);
	}
}


angular
	.module("ezd.backend")
	.service("AEScheduleItems", AEScheduleItemsService);
