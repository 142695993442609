import template from "./footer.html";
import styles from "./footer.local.scss";

import _intersects from "tools/fp/intersects";

FooterController.$inject = ["EzdSupportFormService", "$$profile"];
/**
 *
 * @param EzdSupportFormService
 * @constructor
 */
function FooterController(EzdSupportFormService, $$profile) {
	const vm = this;

	vm.styles = styles;
	vm.isParentOrStudent = false;

	this.openSupportForm = openSupportForm;

	checkCurrentProfile();

	/**
	 *
	 */
	async function checkCurrentProfile() {
		const profile = await $$profile.getCurrentProfile();

		vm.isParentOrStudent = _intersects(["parent", "student"])(profile.roles);
	}

	/**
	 * Открывает ФОС
	 */
	function openSupportForm() {
		EzdSupportFormService.openSupportForm();
	}
}

angular
	.module("ezd.common.ui")
	.component("footer", {
		template,
		controller: FooterController
	});
