const URL = "/api/ae_schedules";
const DATE_FORMAT = "YYYY-MM-DD";

class Schedule {
	/**
	 * Constructor
	 * @param weekNumber {number}
	 * @param beginDate  {string} "YYYY-MM-DD"
	 * @param endDate    {string} "YYYY-MM-DD"
	 */
	constructor(weekNumber, beginDate, endDate) {
		this.id = null;
		this.hours_count = 0;
		this.items_count = 0;
		this.week_number = weekNumber;
		this.begin_date = beginDate;
		this.end_date = endDate;
	}
}


class AESchedulesService {
	static $inject = ["Core", "$$academicYear", "bigQuery"];

	/**
	 * Constructor
	 */
	constructor(Core, $$academicYear, bigQuery) {
		this.CoreService = Core;
		this.services = {$$academicYear, bigQuery};
		this.collection = Core.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.CoreService.one(URL, id);
	}


	/**
	 * Загружаем расписания
	 * @param weeklyLoadsFilter: {[key: string]: number | string}
	 * ae_child_association_id: association
	 * building_id: building
	 * ae_field_id: field
	 * ae_group_id: group
	 * ae_program_id: program
	 */
	async getSchedules(weeklyLoadsFilter) {
		const {building_id, ae_field_id, ae_group_ids, ae_program_id} = weeklyLoadsFilter;
		const {$$academicYear, bigQuery} = this.services;
		const weeklyLoadsUrl = "/api/ae_planned_weekly_load";

		try {
			const academicYear = await $$academicYear.getSelected();
			const schedules = await bigQuery.queue.getList(this.collection, {
				academic_year_id: academicYear.id,
				building_id,
				ae_field_id,
				ae_group_ids,
				ae_program_id
			}, 50);
			const weeklyLoads = await this.CoreService.all(weeklyLoadsUrl).getList(weeklyLoadsFilter);

			return this.$mapSchedules(schedules, weeklyLoads, academicYear);
		} catch (error) {
			throw error;
		}
	}


	/**
	 * Копируем расписание
	 * @param id: number
	 * @param query: {is_hard_mode: boolean, week_numbers: number[]}
	 */
	async copy(id, query) {
		try {
			return this.bindOne(id).all("copy").customPUT(query);
		} catch (error) {
			throw error;
		}
	}


	/**
	 * Удаляем расписание
	 * @param id
	 * @returns {Promise<*>}
	 */
	async remove(id) {
		try {
			return this.bindOne(id).remove();
		} catch (error) {
			throw error;
		}
	}


	/**
	 * Мапим расписания
	 */
	$mapSchedules(schedules, weeklyLoads, academicYear) {
		const {begin_date: begin, end_date: end} = academicYear;
		const dummyWeeks = [];
		const beginYear = moment(begin, DATE_FORMAT);
		const endYear = moment(end, DATE_FORMAT);
		let beginMoment = moment(begin, DATE_FORMAT);
		let endMoment = moment(begin, DATE_FORMAT).endOf("week").endOf("day");
		let weekNumber = 1;

		while (beginMoment.isBetween(beginYear, endYear, null, "[]")) {
			const schedule = _.find(schedules, {week_number: weekNumber});
			const weekLoad = _.find(weeklyLoads, {week_number: weekNumber}) || {hours_count: 0, items_count: 0};
			const beginDate = beginMoment.format(DATE_FORMAT);
			const endDate = endMoment.isAfter(endYear) ? endYear.format(DATE_FORMAT) : endMoment.format(DATE_FORMAT);

			if (Boolean(schedule)) {
				_.assign(schedule, {begin_date: beginDate, end_date: endDate}, weekLoad);
			} else {
				dummyWeeks.push(_.assign(new Schedule(weekNumber, beginDate, endDate), weekLoad));
			}

			weekNumber += 1;
			beginMoment = endMoment.clone().add(1, "day");
			endMoment = beginMoment.clone().endOf("week").endOf("day");
		}

		return _.sortBy([...schedules, ...dummyWeeks], "week_number");
	}
}


angular
	.module("ezd.backend")
	.service("AESchedules", AESchedulesService);
