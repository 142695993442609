/**
 * Занятие календарно-тематического плана вд
 */
class ECCourseCalendarLesson {
	/**
	 * Возвращает шаблон тематического плана вд
	 */
	getTemplate() {
		return {
			name: "", // название модуля
			ordinal: 1, // порядок модулей в плане
			is_test_planned: false, // флаг, определяющий запланирована ли промежуточная аттестация на этом занятии
			is_hw_planned: false // флаг, определяющий запланировано ли дз
		};
	}
}


angular
	.module("ezd.backend")
	.service("ECCourseCalendarLesson", ECCourseCalendarLesson);
