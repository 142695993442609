angular
	.module("ezd.backend")
	.service("$$VkId", $$VkId);

import { Connect, Config } from "@vkontakte/superappkit";

$$VkId.$inject = ["Restangular", "$cookies", "$location", "$$profile", "$$studentProfiles", "$timeout", "$window", "$q"];
function $$VkId(Restangular, $cookies, $location, $$profile, $$studentProfiles, $timeout, $window, $q) {
	// Установка npm install @vkontakte/superappkit
	const url = "vk-id/api";
	const urlAuth = url + "/auth";
	const urlSchool = url + "/school";
	const urlProf = url + "/profile";
	const urlChat = url + "/chat";
	const urlChatCall = url + "/chat_call";
	const urlNotification = url + "/notification";
	const urlCore = url + "/core";
	const urlLog = url + "/log";

	const vm = this;

	vm.notifications = null;

	// auth
	vm.redirectToVkIdForMobile = redirectToVkIdForMobile;
	vm.redirectToVkId = redirectToVkId;
	vm.exchangeSilentAuthToken = exchangeSilentAuthToken;
	// school
	vm.getSchool = getSchool;
	// profile
	vm.getEduProfile = getEduProfile;
	vm.updateEduProfile = updateEduProfile;
	vm.deleteEduProfile = deleteEduProfile;
	// chat
	vm.createChat = createChat;
	vm.editChat = editChat;
	vm.deleteChat = deleteChat;
	vm.getChat = getChat;
	vm.listChats = listChats;
	vm.listChatUsers = listChatUsers;
	vm.addChatUser = addChatUser;
	vm.deleteChatUser = deleteChatUser;
	vm.setChatUserAdmin = setChatUserAdmin;
	vm.setChatUserMember = setChatUserMember;
	// chat_call
	vm.createChatCall = createChatCall;
	vm.editChatCall = editChatCall;
	vm.deleteChatCall = deleteChatCall;
	vm.getChatCall = getChatCall;
	vm.listChatCalls = listChatCalls;
	// notification
	vm.deleteNotifications = deleteNotifications;
	vm.getNotifications = getNotifications;
	vm.updateNotifications = updateNotifications;
	vm.waitNotifications = waitNotifications;
	vm.pollNotifications = pollNotifications;
	// core
	vm.listSchools = listSchools;
	vm.listTeachers = listTeachers;
	vm.listStudents = listStudents;
	vm.listParents = listParents;
	vm.listTeacherGroups = listTeacherGroups;
	vm.listClassLevels = listClassLevels;
	vm.listClassUnits = listClassUnits;
	vm.listLessonScheduleItems = listLessonScheduleItems;
	// log
	vm.log = log;
	// parse
	vm.objStringify = objStringify;
	vm.objParse = objParse;

	/**
	 * Генерирует SuperApp Token и перенаправляет на авторизацию в VK ID для МП
	 */
	async function redirectToVkIdForMobile() {
		if (!$location.search().user_id) {
			return;
		}

		const userId = $location.search().user_id;
		const redirectUrl = $location.search().redirect_url || location.origin + location.pathname; // redirectUrl - url куда перекинуть после вк-авторизации (Connect.redirectAuth)
		const finalUrl = $location.search().final_url || "/new_messages"; // finalUrl - url куда перекинуть после сохранения уч.профиля (exchangeSilentAuthToken) или после ошибки

		const superAppTokenMobileParams = {
			user_id: userId,
			url: redirectUrl,
			final_url: finalUrl
		};

		let errMsg = null;
		try {
			const superAppToken = await Restangular.all(urlAuth + "/super_app_token_mobile").customPOST(superAppTokenMobileParams);
			Config.init({appId: superAppToken.app_id});

			const redirectParams = {
				url: superAppToken.url,
				action: {
					name: superAppToken.subject,
					token: superAppToken.token
				},
				state: objStringify({
					user_id: superAppToken.user_id,
					final_url: superAppToken.final_url
				})
			};

			return Connect.redirectAuth(redirectParams);
		} catch (e) {
			errMsg = e.data != null ? e.data.message : e.toString();
			location.href = `${finalUrl}?result=${errMsg === null}` + (errMsg !== null ? `&message=${errMsg}` : "");
		}
	}

	/**
	 * Генерирует SuperApp Token и перенаправляет на авторизацию в VK ID
	 */
	async function redirectToVkId() {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		const superAppTokenParams = {
			school_id: schoolId,
			// ip: $cookies.get("ip"),
			// auth_token: $cookies.get("auth_token"),
			user_id: currentProfile.user_id,
			url: location.origin + location.pathname
		};

		const superAppToken = await Restangular.all(urlAuth + "/super_app_token").customPOST(superAppTokenParams);

		Config.init({appId: superAppToken.app_id});

		const redirectParams = {
			url: superAppToken.url,
			action: {
				name: superAppToken.subject,
				token: superAppToken.token
			}
		};

		return Connect.redirectAuth(redirectParams);
	}

	/**
	 * Обмен Silent token на Access Token
	 */
	async function exchangeSilentAuthToken() {
		if (!$location.search().payload) {
			return;
		}

		const currentProfile = await $$profile.getCurrentProfile();
		const payload = angular.copy($location.search().payload);
		const state = objParse(angular.copy($location.search().state));

		const silentToken = JSON.parse(payload);
		silentToken.user_id = _.get(state, "user_id") || currentProfile.user_id;
		silentToken.load_external_users = silentToken.loadExternalUsers;
		delete silentToken.loadExternalUsers;

		const finalUrl = _.get(state, "final_url") || "/new_messages";

		let errMsg = null;
		try {
			await Restangular.all(urlAuth + "/exchange_silent_auth_token").customPOST(silentToken);
		} catch (e) {
			errMsg = e.data != null ? e.data.message : e.toString();
			log("exchangeSilentAuthToken", errMsg, [
				$location.url(),
				payload,
				JSON.stringify(silentToken)
			]);
			throw e;
		} finally {
			if (_.get(state, "final_url")) {
				location.href = `${finalUrl}?result=${errMsg === null}` + (errMsg !== null ? `&message=${errMsg}` : "");
			} else {
				// чистим url от данных Silent token
				$location.path(finalUrl).search({});

				if (errMsg === null) {
					// перезагружаем спустя 2 сек, чтоб подтянусь новые чаты, в которые система успела закинуть пользователя
					$timeout(() => {
						$location.path(finalUrl).search({});
						$window.location.reload();
					}, 2000);
				}
			}
		}
	}

	/**
	 * Вернет данные по школе, если та подключена к сообществу VK-ID
	 */
	async function getSchool() {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlSchool).customPOST({
			school_id: schoolId
		});
	}

	/**
	 * Вернет данные учебного профиля, если тот создавался для этого пользователя
	 */
	async function getEduProfile() {
		return Restangular.all(urlProf).customGET("", {});
	}

	/**
	 * Запустит для выбранных пользователей синхронизацию по чатам
	 * Следует вызывать при изменении состояния профиля пользователя
	 */
	async function updateEduProfile(userIds) {
		return Restangular.all(urlProf).customPUT({
			user_ids: userIds
		});
	}

	/**
	 * Удаление профиля пользователя
	 */
	async function deleteEduProfile() {
		return Restangular.all(urlProf).customDELETE("", {});
	}

	/**
	 *
	 */
	async function createChat(title, userIds, adminUserIds, groupId) {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlChat + "/create_chat").customPOST({
			school_id: schoolId,
			title,
			user_ids: userIds,
			admin_user_ids: adminUserIds,
			group_id: groupId
		});
	}

	/**
	 *
	 */
	async function editChat(chatId, title, updateUsers, userIds, adminUserIds) {
		return Restangular.all(urlChat + "/edit_chat").customPOST({
			chat_id: chatId,
			title,
			update_users: updateUsers,
			user_ids: userIds,
			admin_user_ids: adminUserIds
		});
	}

	/**
	 *
	 */
	async function deleteChat(chatId) {
		return Restangular.all(urlChat + "/delete_chat").customPOST({
			chat_id: chatId
		});
	}


	/**
	 *
	 */
	async function getChat(chatId, edit, local = true) {
		return Restangular.all(urlChat + "/get_chat").customPOST({
			chat_id: chatId,
			edit,
			local,
			with_chat_calls: true,
			with_class_unit: true,
			with_user_ids: true
		});
	}

	/**
	 *
	 */
	async function listChats(local = true) {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlChat + "/list_chats").customPOST({
			school_id: schoolId,
			local,
			with_chat_calls: true,
			with_class_unit: true,
			with_user_ids: true
		});
	}

	/**
	 *
	 */
	async function listChatUsers(chatId, edit, local = true) {
		return Restangular.all(urlChat + "/list_chat_users").customPOST({
			chat_id: chatId,
			edit,
			local
		});
	}

	/**
	 *
	 */
	async function addChatUser(chatId, userId, admin) {
		return Restangular.all(urlChat + "/add_chat_user").customPOST({
			chat_id: chatId,
			user_id: userId,
			admin
		});
	}

	/**
	 *
	 */
	async function deleteChatUser(chatId, userId) {
		return Restangular.all(urlChat + "/delete_chat_user").customPOST({
			chat_id: chatId,
			user_id: userId
		});
	}

	/**
	 *
	 */
	async function setChatUserAdmin(chatId, userId) {
		return Restangular.all(urlChat + "/set_chat_user_admin").customPOST({
			chat_id: chatId,
			user_id: userId
		});
	}

	/**
	 *
	 */
	async function setChatUserMember(chatId, userId) {
		return Restangular.all(urlChat + "/set_chat_user_member").customPOST({
			chat_id: chatId,
			user_id: userId
		});
	}

	/**
	 *
	 */
	async function createChatCall(chatId, datetime, lessonScheduleItemId, title) {
		return Restangular.all(urlChatCall + "/create_chat_call").customPOST({
			chat_id: chatId,
			datetime,
			lesson_schedule_item_id: lessonScheduleItemId,
			title
		});
	}

	/**
	 *
	 */
	async function editChatCall(chatCallId, datetime, lessonScheduleItemId, title, status) {
		return Restangular.all(urlChatCall + "/edit_chat_call").customPOST({
			chat_call_id: chatCallId,
			datetime,
			lesson_schedule_item_id: lessonScheduleItemId,
			title,
			status
		});
	}

	/**
	 *
	 */
	async function deleteChatCall(chatCallId) {
		return Restangular.all(urlChatCall + "/delete_chat_call").customPOST({
			chat_call_id: chatCallId
		});
	}

	/**
	 *
	 */
	async function getChatCall(chatCallId) {
		return Restangular.all(urlChatCall + "/get_chat_call").customPOST({
			chat_call_id: chatCallId,
			with_chat: true,
			with_call: true,
			with_lesson_schedule_item: true,
			with_profile: true
		});
	}

	/**
	 *
	 */
	async function listChatCalls(chatId, lessonScheduleItemIds, status) {
		return Restangular.all(urlChatCall + "/list_chat_calls").customPOST({
			chat_id: chatId,
			lesson_schedule_item_ids: lessonScheduleItemIds,
			status,
			with_chat: true,
			with_call: true,
			with_lesson_schedule_item: true,
			with_profile: true
		});
	}

	/**
	 *
	 */
	async function deleteNotifications(chatIds) {
		return Restangular.all(urlNotification).customDELETE("", {}, {"Content-Type": "application/json"}, {
			chat_ids: chatIds
		}).then(() => {
			vm.notifications = [];
		});
	}

	/**
	 *
	 */
	async function getNotifications() {
		return Restangular.all(urlNotification).customGET("", {});
	}

	/**
	 *
	 */
	async function updateNotifications() {
		const currentProfile = await $$profile.getCurrentProfile();

		if (_.includes(["teacher", "student", "parent"], currentProfile.type)) {
			vm.notifications = await vm.getNotifications();
		} else {
			vm.notifications = [];
		}

		return vm.notifications;
	}

	/**
	 *
	 */
	async function waitNotifications() {
		const deferred = $q.defer();
		if (vm.notifications === null) {
			const t = setInterval(() => {
				if (vm.notifications !== null) {
					clearInterval(t);
					deferred.resolve(vm.notifications);
				}
			}, 100);
		} else {
			deferred.resolve(vm.notifications);
		}

		return deferred.promise;
	}

	/**
	 *
	 */
	async function pollNotifications() {
		await vm.waitNotifications();
		const notifications = angular.copy(vm.notifications);

		if (vm.notifications && vm.notifications.length) {
			vm.deleteNotifications(_.map(vm.notifications, "chat_id"));
		}

		return notifications;
	}

	/**
	 *
	 */
	async function listSchools() {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlCore + "/list_schools").customPOST({
			school_id: schoolId
		});
	}

	/**
	 *
	 */
	async function listTeachers(classLevelId, classUnitId, subjectId, withoutCompetences) {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlCore + "/list_teachers").customPOST({
			school_id: schoolId,
			class_level_id: classLevelId,
			class_unit_id: classUnitId,
			subject_id: subjectId,
			without_competences: withoutCompetences
		});
	}

	/**
	 *
	 */
	async function listStudents(classLevelId, classUnitId, subjectId, profileIds) {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlCore + "/list_students").customPOST({
			school_id: schoolId,
			profile_ids: profileIds,
			class_level_id: classLevelId,
			class_unit_id: classUnitId,
			subject_id: subjectId
		});
	}

	/**
	 *
	 */
	async function listParents(classLevelId, classUnitId) {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlCore + "/list_parents").customPOST({
			school_id: schoolId,
			class_level_id: classLevelId,
			class_unit_id: classUnitId
		});
	}

	/**
	 *
	 */
	async function listTeacherGroups() {
		const currentProfile = await $$profile.getCurrentProfile();
		if (currentProfile.type !== "teacher") {
			return [];
		}

		return Restangular.all(urlCore + "/list_teacher_groups").customPOST({
			school_id: currentProfile.school_id,
			teacher_id: currentProfile.id
		});
	}

	/**
	 *
	 */
	async function listClassLevels() {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlCore + "/list_class_levels").customPOST({
			school_id: schoolId
		});
	}

	/**
	 *
	 */
	async function listClassUnits() {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlCore + "/list_class_units").customPOST({
			school_id: schoolId
		});
	}

	/**
	 *
	 */
	async function listLessonScheduleItems(groupIds) {
		const currentProfile = await $$profile.getCurrentProfile();
		let schoolId = currentProfile.school_id;
		if (currentProfile.type.toLowerCase() === "parent") {
			const studentProfile = await $$studentProfiles.getCurrentProfile();
			schoolId = studentProfile.school_id;
		}

		return Restangular.all(urlCore + "/list_lesson_schedule_items").customPOST({
			school_id: schoolId,
			group_ids: groupIds
		});
	}

	/**
	 *
	 */
	async function log(source, message, data) {
		return Restangular.all(urlLog).customPOST({
			source,
			message,
			data
		});
	}

	/**
	 *
	 */
	function objStringify(obj) {
		if (obj == null) {
			return null;
		}
		let result = "";

		for (const key of Object.keys(obj)) {
			if (result !== "") {
				result += ";";
			}
			result += key + "=" + obj[key];
		}

		return result;
	}

	/**
	 *
	 */
	function objParse(str) {
		if (str == null) {
			return null;
		}
		const result = {};

		for (const kv of str.split(";")) {
			result[kv.split("=")[0]] = kv.split("=")[1];
		}

		return result;
	}
}
