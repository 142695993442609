import {Component} from "app/decorators/index";
import template from "./ezd-switch.html";
import styles from "./ezd-switch.local.scss";
import _includes from "lodash/fp/includes";

/**
 * Простенький свитч. Для использования в дальнейшем нуждается в доработке, сейчас есть только необходимое
 * Тип "checkbox" означает, что switch ведет себя как обычный чекбокс,
 * т.е. когда выбран, то подсвечивается, а когда не выбран - серый
 * type: {String} "checkbox" | "default"
 */
@Component({
	selector: "ezdSwitch",
	template,
	bindings: {
		model: "=",
		onChange: "&",
		type: "@"
	}
})
class EzdSwitchComponent {
	static TYPES = {
		checkbox: "ezd-switch-checkbox",
		default: "ezd-switch-default"
	};

	constructor() {
		this.styles = styles;
	}

	/**
	 * OnInit
	 */
	$onInit() {
		if (!this.$isValidType(this.type)) {
			this.type = "default";
		}
	}

	/**
	 * Возвразаем css-класс в соответствии с типом
	 */
	getTypeClass() {
		return EzdSwitchComponent.TYPES[this.type];
	}

	/**
	 * Проверяем, что был передан валидный тип
	 * @param type
	 */
	$isValidType(type) {
		const availableTypes = Object.keys(EzdSwitchComponent.TYPES);

		return Boolean(type) && _includes(type)(availableTypes);
	}

	/**
	 * $onChange
	 */
	onChangeModelValue() {
		this.onChange({
			$event: this.model
		});
	}
}


angular.module("ezd.common.ui")
	.component(EzdSwitchComponent.selector, EzdSwitchComponent);
