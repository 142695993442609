angular
	.module("ezd.backend")
	.service("$$periodSchedule", $$periodSchedule);


$$periodSchedule.$inject = ["Core", "$q"];
/**
 *
 * @param Core
 * @param $q
 */
function $$periodSchedule(Core, $q) {
	const url = "/api/periods_schedules";
	const vm = this;

	vm.collection = Core.all(url);
	vm.collection.getList = (query) => Core.all(url).getList(query);


	vm.bindOne = bindOne;
	vm.getOne = getOne;
	vm.getPeriods = getPeriods;
	vm.getCurrentStudyPeriod = getCurrentStudyPeriod;
	vm.copy = copy;
	vm.one = (id) => Core.one(url, id);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core
			.one(url, id)
			.get()
			.then((data) => {
				data.periods = data.periods || [];
				Core.restangularizeCollection(data, data.periods, "periods");

				return data;
			});
	}


	function getOne(id) {
		const deferred = $q.defer();

		if (!id) {
			deferred.reject();

			return deferred.promise;
		}

		return Core
			.one(url, id)
			.get()
			.then((data) => {
				if (data && (!data.errCode || data.errCode === 0)) {
					return data;
				}

				throw data;
			});
	}


	function getPeriods(id) {
		const deferred = $q.defer();

		if (!id) {
			deferred.reject();

			return deferred.promise;
		}

		return Core
			.one(url, id)
			.all("periods")
			.getList()
			.then((data) => {
				if (data && (!data.errCode || data.errCode === 0)) {
					return data;
				}

				throw data;
			});
	}


	/**
	 * Возвращает текущий учебный период.
	 * Если каникулы, то предыдущий
	 * @param periods периоды
	 */
	function getCurrentStudyPeriod(periods) {
		let currentPeriod;
		let prevPeriod;
		periods.sort((lhs, rhs) => {
			return lhs.begin_date > rhs.begin_date
				? 1
				: lhs.begin_date < rhs.begin_date
					? -1
					: 0;
		});

		_.forEach(periods, (period) => {
			if (moment().isBetween(moment(period.begin_date).subtract(1, "days"), moment(period.end_date).add(1, "days"))) {
				// если каникулы
				if (period.is_vacation) {
					currentPeriod = prevPeriod;
				} else {
					currentPeriod = period;
				}

				return false;
			}
		});

		if (!currentPeriod) {
			console.error("Не найден учебный период");
		}

		return currentPeriod;
	}


	/**
	 * Копирование ГУП
	 * @param plan {Object}
	 */
	function copy(plan) {
		const deferred = $q.defer();

		if (!plan.id) {
			deferred.reject({message: "Копирование невозможно"});

			return deferred.promise;
		}

		return vm.collection.customPOST({}, plan.id + "/copy");
	}
}
