import {Component} from "app/decorators/index";
import template from "./group-students-modal.template.html";
import styles from "./group-students-modal.local.scss";
import {getPageString} from "components/backend/components/services/OrgLocalization";

@Component({
	selector: "groupStudentsModal",
	template
})
export class GroupStudentsModalComponent {
	static $inject = ["groupData", "base", "$mdDialog", "$$studentProfiles"];
	getGlobalString = getPageString.bind(undefined, '$global');
	styles = styles;

	/**
	 * */
	constructor(groupData, base, $mdDialog, $$studentProfiles) {
		this.services = {base, $mdDialog, $$studentProfiles};
		this.groupData = groupData;
		this.base = base;
		this.$loadStudents();
	}

	/**
	 * */
	async $loadStudents() {
		try {
			const {$$studentProfiles} = this.services;
			this.students = await (this.promise = $$studentProfiles.collection.getList({
				ids: this.base.getStudentsIdsByGroup(this.groupData).join(","),
				per_page: 50
			}));
		} catch (er) {
			console.error(er);
		}
	}
}
