angular
	.module("ezd.backend")
	.factory("$$achievement", achievements);

/**
 *
 * @param LMSv2
 * @returns {*|Object}
 */
achievements.$inject = ["LMSv2"];
function achievements(LMSv2) {
	const Service = LMSv2.service("/api/achievements");

	// binding
	Service.getNewAchievement = getNewAchievement;

	return Service;

	// implementation
	function getNewAchievement() {
		return {
			kind: "", // (string) тип достижения
			view: "", // (string) вид достижения
			arrangement: "",
			level: "", // (string) Уровень
			result: "", // (string) Результат
			comment: "", // (string) Комментарий
			profile_id: 0, // (number) id профиля пользователя, для которого создается достижение. Если не передано в запросе - берется текущий профиль
			teacher_profile_id: 0, // (number) id профиля учителя (Педагог/наставник)
			done_on: "", // (string) Дата достижения
			category: "", // (string) Категория
			attachment_id: null // (number) прикрепленный файл
		};
	}
}
