/**
 * Модуль тематического плана
 */
class ECCourseLessonModule {
	/**
	 * Возвращает шаблон модуля тематического плана
	 */
	getTemplate() {
		return {
			name: "",
			ordinal: 1, // порядок модулей в плане
			topics: [] // массив ECCourseLessonTopic
		};
	}
}


angular
	.module("ezd.backend")
	.service("ECCourseLessonModule", ECCourseLessonModule);
