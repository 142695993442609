/**
 * @filters category, area, district, name
 */
class CityBuildingsService {
	static $inject = ["Core"];

	static URL = "/api/city_buildings";
	static AREAS_URL = "/api/city_areas";
	static DISTRICTS_URL = "/api/city_districts";
	static CATEGORIES_URL = "/api/city_buildings_categories";


	constructor(Core) {
		this.CoreService = Core;
		this.CoreServiceWithConfig = this.CoreService.withConfig((configBuilder) => configBuilder.setFullResponse(true));

		this.collection = this.CoreService.all(CityBuildingsService.URL);
		this.collectionWithConfig = this.CoreServiceWithConfig.all(CityBuildingsService.URL);

		this.areaCollection = this.CoreService.all(CityBuildingsService.AREAS_URL);
		this.districtCollection = this.CoreService.all(CityBuildingsService.DISTRICTS_URL);
		this.categoryCollection = this.CoreService.all(CityBuildingsService.CATEGORIES_URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.CoreService.one(CityBuildingsService.URL, id);
	}


	/**
	 * Запрашиваем справочники категорий, округов и районов
	 */
	async getMetaData() {
		const [areas, districts, categories] = await Promise.all([
			this.areaCollection.getList({per_page: 1000}),
			this.districtCollection.getList({per_page: 1000}),
			this.categoryCollection.getList({per_page: 1000})
		]);

		return {areas, districts, categories};
	}


	/**
	 * Запрашиваем страницу зданий
	 */
	getPage(query, page = 1, perPage = 30) {
		return this.collectionWithConfig.getList({
			...query,
			page,
			per_page: perPage
		});
	}
}

angular
	.module("ezd.backend")
	.service("CityBuildings", CityBuildingsService);
