

angular
	.module("ezd.backend")
	.factory("$$lessonModules", lessonPlansModules);


/**
 *
 * @param Jersey
 * @returns {Object|*}
 */
lessonPlansModules.$inject = ["Jersey"];
function lessonPlansModules(Jersey) {
	const Service = Jersey.service("/api/lesson_modules");

	Service.getStartModuleDateObject = getStartModuleDateObject;
	Service.getEndModuleDateObject = getEndModuleDateObject;

	return Service;


	function getStartModuleDateObject(module) {
		return moment()
			.year(module.start_date[0])
			.month(module.start_date[1] - 1)
			.date(module.start_date[2])
			.startOf("day");
	}

	function getEndModuleDateObject(module) {
		return moment()
			.year(module.end_date[0])
			.month(module.end_date[1] - 1)
			.date(module.end_date[2])
			.endOf("day");
	}
}
