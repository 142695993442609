import EzdPromptTemplate from "./ezd-prompt.html";
import {EzdPromptController} from "./ezd-prompt.controller";


angular
	.module("ezd.common.ui")
	.service("EzdPrompt", EzdPrompt);

EzdPrompt.$inject = ["$mdDialog"];

/**
 *
 */
function EzdPrompt($mdDialog) {
	const vm = this;
	vm.prompt = prompt;
	vm.openDialog = openDialog;

	/**
	 *
	 */
	function prompt(params) {
		const $params = {
			text: "",
			title: "Успех!",
			confirmButton: "Ок",
			rejectButton: "Отмена",
			onEmptyString: false
		};

		_.assign($params, params);

		return vm.openDialog($params);
	}

	/**
	 *
	 */
	function openDialog(params) {
		return $mdDialog.show({
			params,
			parent: angular.element(document.body),
			clickOutsideToClose: true,
			template: EzdPromptTemplate,
			controller: EzdPromptController,
			controllerAs: "$ctrl"
		});
	}
}
