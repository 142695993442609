import _intersects from "tools/fp/intersects";
import template from "./UserInfo.html";
import {getPageString, getPageVisibility} from "components/backend/components/services/OrgLocalization";
import UnifiedAuthUrl from "components/common/unifiedAuthUrl";

class UserInfoController {
	static $inject = ["$rootScope", "$scope", "$$profile", "Sessions", "$$user", "Messages", "$mdDialog", "$$studentProfiles", "$$school", "RequiredDecisions", "Notifications", "$interval", "$$VkId", "$$academicYear"];
	SCHOOL_ORG_TYPE_ID = 1;
	marusiaVisibility = false;
	getGlobalString = getPageString.bind(undefined, "$global");
	getGlobalVisibility = getPageVisibility.bind(undefined, "$global");

	styles = require("./user-info.local.scss");
	icons = {
		decisions: require("./icons/ic_announcement_white_24px.svg"),
		mail: require("images/icons/ic_email_white_24px.svg"),
		gear: require("images/icons/gear.svg"),
		logout: require("./icons/logout.svg"),
		no_avatar: require("./icons/no_avatar.svg"),
		help: require("images/icons/ic_help_outline_black_24px.svg"),
		key: require("images/icons/ic_vpn_key_black_24px.svg"),
		star: require("images/icons/ic_star_outline_24px.svg"),
		message: require("images/icons/logo-mes.svg"),
		bell: require("images/icons/i-bell-thin.svg"),
		go_back: require("images/icons/go-back.svg"),
		marusia: require("images/icons/marusia.png"),
		alisa: require("images/icons/alisa.png")
	};
	rolesToShow = ["student"];
	userAssoc = {
		student: this.getGlobalString('student').toLowerCase()
	};
	userRole = {};
	childSchool = "";
	isMedal = false;
	updatingInterval;
	checkNotificationTimeout = 300000;

	unifiedAuthUrl = UnifiedAuthUrl.getBaseUrl();

	constructor($rootScope, $scope, $$profile, Sessions, $$user, Messages, $mdDialog, $$studentProfiles,
							$$school, RequiredDecisions, Notifications, $interval, $$VkId, $$academicYear) {
		this.services = {$rootScope, $scope, $$profile, Sessions, $$user, Messages, $mdDialog, $$studentProfiles,
			$$school, RequiredDecisions, Notifications, $interval, $$VkId, $$academicYear};

		$scope.$watch(() => $$profile.currentProfile, (newValue) => {
			if (!_.isEmpty(newValue)) {
				this.school = newValue.school_shortname;
				[this.userRole.title, this.userRole.value] = [this.userAssoc[newValue.type], newValue.type];
			}
		});
	}

	/**
	 * Останавливаем обновление сообщений
	 */
	$onDestroy() {
		const {$interval} = this.services;
		$interval.cancel(this.updatingInterval);
	}

	/**
	 *
	 * @returns {Promise.<void>}
	 */
	async $onInit() {
		const {$$user, $$profile} = this.services;
		try {
			const userData = await $$user.getCurrentUser();
			this.userData = userData;
			await this.setUserInfo(userData);
			const profile = await $$profile.getCurrentProfile();
			this.currentProfile = profile;
			this.marusiaVisibility = this.isSchool();
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 *
	 */
	checkRole() {
		return _.includes(this.rolesToShow, this.userRole.value);
	}

	/**
	 * Сохранение в скоуп данных пользователя для отображения в html шаблоне
	 * @param userData
	 */
	setUserInfo(userData) {
		const {$$profile, $$studentProfiles, $$VkId} = this.services;
		if (!userData) {
			return;
		}

		this.shortName = this.getShortName(userData.last_name, userData.first_name, userData.middle_name);
		this.profiles = _.values(userData.profiles);
		this.undoneTasksCount = 0;
		this.new_decisions_url = "/_decisions/bars";

		if (this.profiles && this.profiles[0] && this.profiles[0].type === "admin_security") {
			this.is_sec_admin = true;

			return;
		}

		if (this.profiles && this.profiles[0] && this.profiles[0].type !== "observer") {
			this.updateUnreadMessagesCount();
		}

		return $$profile
			.getCurrentProfile()
			.then((profile) => {
				this.current_profile = profile;

				if (_.includes(profile.roles, "teacher")) {
					this.decisions_url = "/_decisions/bars";
				}

				if (_.intersects(profile.roles, ["deputy", "admin", "school_admin", "school_admin_read_only", "principal"])) {
					this.decisions_url = "/_decisions/bars";
				}

				if (!_.includes(["parent", "student", "admin_security", "observer"], profile.type) && profile.type !== "curator" && profile.type !== "admin") {
					this.updateDecisionsCount();
				}

				if (_.intersects(profile.roles, ["medal_commission"])) {
					this.isMedal = true;
				}

				if (_.intersects(profile.roles, ["admin_security"])) {
					this.is_sec_admin = true;
				}

				if (_.intersects(profile.roles, ["admin"])) {
					this.is_admin = true;
				}

				if (_.intersects(profile.roles, ["observer"])) {
					this.is_observer = true;
				}

				this.school = profile.school_shortname || "";

				if (profile.type === "parent") {
					this.loadChildSchool();
				}

				$$VkId.updateNotifications();

				if (_intersects(["student", "parent"])(profile.roles)) {
					return $$studentProfiles.getCurrentProfile();
				}
			})
			.then((studentProfile) => {
				if (!studentProfile) {
					return;
				}

				this.studentProfile = studentProfile;

				if (_intersects(["student", "parent"])(this.current_profile.roles)) {
					this.checkUnreadNotifications();
				}
			});
	}

	/**
	 *
	 */
	async loadChildSchool() {
		const {$$studentProfiles, $$school} = this.services;
		try {
			const student = await $$studentProfiles.getCurrentProfile();

			if (!student || !student.school_id) {
				return false;
			}

			if (_.has(student, "schoolShortName")) {
				this.childSchool = student.schoolShortName;

				return;
			}

			const school = await $$school.bindOne(student.school_id).get();

			this.childSchool = _.get(school, "short_name", "");
			student.schoolShortName = this.childSchool;
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 *
	 */
	updateUnreadMessagesCount() {
		if (!this.getGlobalVisibility("ezd_chat")) {
			return;
		}
		const {Messages} = this.services;
		Messages.getUnreadCount();
	}

	/**
	 *
	 */
	updateDecisionsCount() {
		const {RequiredDecisions, $$academicYear} = this.services;

		$$academicYear.getSelected()
			.then((selectedYear) => {
				return RequiredDecisions.count(this.current_profile, selectedYear.id);
			})
			.then((count) => {
				this.undoneTasksCount = count.count > 10 ? "!" : count.count;
			});
	}

	/**
	 *
	 * @param lastName
	 * @param firstName
	 * @param middleName
	 * @returns {string}
	 */
	getShortName(lastName, firstName, middleName) {
		if (!Boolean(lastName)) {
			return "";
		}

		let shortName = lastName.charAt(0).toUpperCase() + lastName.slice(1) + " ";
		let partOfName = [];

		if (firstName) {
			partOfName = firstName.split(" ");
		}

		if (middleName) {
			partOfName = partOfName.concat(middleName.split(" "));
		}

		_.forEach(partOfName, (part) => {
			shortName += part.charAt(0).toUpperCase() + ". ";
		});

		return shortName;
	}

	/**
	 *
	 */
	async goBack() {
		window.location.assign(this.unifiedAuthUrl);
	}

	/**
	 *
	 */
	async logout() {
		const {Sessions} = this.services;
		try {
			await Sessions.dropSession();
		} catch (e) {
			console.log(e);
		}
		Sessions.drop();
	}

	/**
	 *
	 * @param ev
	 */
	getPin(ev) {
		const {$rootScope, $mdDialog, Sessions} = this.services;
		$rootScope.global_promise = Sessions
			.getPin()
			.then((response) => {
				$mdDialog.show(
					$mdDialog.alert()
						.parent(angular.element(document.body))
						.clickOutsideToClose(true)
						.title("Pin")
						.textContent(response.pin)
						.ok("Закрыть")
						.targetEvent(ev)
				);
			});
	}

	/**
	 *
	 */
	showPopupMessage() {
		const {$mdDialog, $scope} = this.services;
		$mdDialog.show({
			scope: $scope.$new(),
			controllerAs: "$ctrl",
			clickOutsideToClose: false,
			template: "<md-dialog>"
				+ "<md-dialog-content ng-class='::$ctrl.styles[\"popup-limited\"]'>"
				+ "<div ng-class='::$ctrl.styles[\"popup-limited-top\"]'>"
				+ "<div	ng-class='::$ctrl.styles[\"popup-limited-close\"]' ng-click='$ctrl.closePopupMessage()'>&times;</div>"
				+ "</div>"
				+ "<div ng-class='::$ctrl.styles[\"popup-limited-img_container\"]'>"
				+ "<md-icon md-svg-src='" + this.icons.message + "' ng-class='::$ctrl.styles[\"popup-limited-message_img\"]'></md-icon>"
				+ "</div>"
				+ "<div ng-class='::$ctrl.styles[\"popup-limited-title\"]'>Доступ к ЭПОС.Школа</div>"
				+ "<div ng-class='::$ctrl.styles[\"popup-limited-content\"]'>Если вы являетесь родителем (или иным законным представителем) ребенка, для доступа к расширенному функционалу ЭПОС.Школа Вам необходимо иметь личный кабинет.</div>"
				+ "<div ng-class='::$ctrl.styles[\"popup-limited-know_more\"]'>"
				+ "<md-button ng-class='::$ctrl.styles[\"popup-limited-know_moreBtn\"]' ng-href='#'>Узнать больше о ЭПОС.Школа</md-button>"
				+ "</div>"
				+ "</md-dialog-content>"
				+ "</md-dialog>"
		});
	}

	/**
	 *
	 */
	closePopupMessage() {
		const {$mdDialog} = this.services;
		$mdDialog.hide();
	}

	/**
	 * Перейти в центр уведомлений
	 */
	goToNotificationCenter() {
		window.location.assign("/student_diary/notification_center");
	}

	/**
	 * Установить интервал, с которым необходимо проверять наличие новых уведомлений
	 */
	checkUnreadNotifications() {
		const {$interval} = this.services;
		this.updatingInterval = $interval(this.updateUnreadNotificationsStatus.bind(this), this.checkNotificationTimeout);
	}

	/**
	 * Проверить наличие непрочитанных уведомлений
	 */
	updateUnreadNotificationsStatus() {
		const {Notifications} = this.services;
		Notifications.one("status").get({student_id: this.studentProfile.id})
			.then((response) => {
				this.isExistUnreadNotification = response.body;
			});
	}

	/**
	 * Определяем, что открыта школа: если текущий профиль - родитель, то тип организации проверяем у выбранного ребенка
	 * @returns {boolean}
	 */
	isSchool() {
		if (this.currentProfile.type === "parent") {
			return this.studentProfile.org_type_id === this.SCHOOL_ORG_TYPE_ID;
		}

		return this.currentProfile.org_type_id === this.SCHOOL_ORG_TYPE_ID;
	}
}

angular
	.module("ezd.common.ui")
	.component("userInfo", {
		template,
		controller: UserInfoController
	});
