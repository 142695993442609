angular
	.module("ezd.backend")
	.service("$$olympiads", $$olympiads);

$$olympiads.$inject = ["Core", "REP"];

/**
 *
 * @constructor
 */
function $$olympiads(Core, REP) {
	const baseUrl = "api/olympiads";
	const vm = this;

	vm.Core = Core;
	vm.REP = REP;
	vm.getOlympiadsList = getOlympiadsList;
	vm.getOlympiadSubjects = getOlympiadSubjects;
	vm.getOlympiadLevels = getOlympiadLevels;
	vm.removeOlympiad = removeOlympiad;

	/**
	 * Получаем список олимпиад
	 */
	function getOlympiadsList(params = {}) {
		return vm.REP.all(`${baseUrl}/json`).getList(params);
	}

	/**
	 * Получаем список диспиплин по которым проводятся олимпиады
	 */
	function getOlympiadSubjects() {
		return vm.Core.all(`${baseUrl}/subjects`).getList();
	}

	/**
	 * Получаем список уровней
	 */
	function getOlympiadLevels() {
		return vm.Core.all(`${baseUrl}/levels`).getList();
	}

	/**
	 * Удаление олимпиады
	 */
	function removeOlympiad(id) {
		return vm.Core.one(`${baseUrl}`, id).customDELETE();
	}
}
