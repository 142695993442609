import isArray from "lodash/fp/isArray";
import isEmpty from "lodash/fp/isEmpty";

/**
 * Декоратор для автоматического инжекта зависимостей
 * @returns Function
 */
export function InjectUnsafe(target, methodName, descriptor) {
	const dependecies = $args(descriptor.value);

	if (isArray(dependecies) && !isEmpty(dependecies)) {
		descriptor.value.$inject = dependecies;
	}

	return descriptor;

	/**
	 * Получает имена аргументов переданной функции
	 * */
	function $args(func) {
		return func.toString()
			.replace(/[/][/].*$/mg, "") // strip single-line comments
			.replace(/\s+/g, "") // strip white space
			.replace(/[/][*][^/*]*[*][/]/g, "") // strip multi-line comments
			.split("){", 1)[0].replace(/^[^(]*[(]/, "") // extract the parameters
			.replace(/=[^,]+/g, "") // strip any ES6 defaults
			.split(",").filter(Boolean); // split & filter [""]
	}
}
