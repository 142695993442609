

angular
	.module("ezd.backend")
	.service("$$calendarPlan", calendarPlanService);


/**
 *
 * @param $q
 * @param Jersey
 */
calendarPlanService.$inject = ["$q", "Jersey"];

/**
 *
 * @param $q
 * @param Jersey
 */
function calendarPlanService($q, Jersey) {
	const vm = this;


	/**
	 *
	 * DECLARATION
	 *
	 */


	vm.bindOne = bindOne;
	vm.generateForGroup = generateForGroup;
	vm.checkCalendarPlan = checkCalendarPlan;


	/**
	 *
	 * VARIABLES
	 *
	 */
	vm.url = "/api/calendar_plans";
	vm.list = [];
	vm.collection = Jersey.all(vm.url);


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Jersey.one(vm.url, id);
	}


	/**
	 * Сгенерировать КТП для группы
	 * @param groupId
	 */
	function generateForGroup(groupId) {
		if (groupId) {
			return Jersey.one(vm.url + "/for_group", groupId)
				.customPOST({group_id: groupId}, "", {group_id: groupId}, "")
				.then((data) => {
					if (!data || (data.errCode && data.errCode !== 0)) {
						throw data;
					}

					return data;
				});
		}

		return $q((resolve, reject) => reject({errorMessage: "Не задан ID группы"}));
	}

	/**
	 * Информация о возможности создания КТП
	 */
	function checkCalendarPlan(groupId, lessonPlanId, isOnlyGroup) {
		if (groupId && lessonPlanId) {
			return Jersey.one(vm.url + "/info")
				.get({
					group_id: groupId,
					lesson_plan_id: lessonPlanId,
					only_group: isOnlyGroup
				});
		}

		return $q((resolve, reject) => reject({errorMessage: "Не передан groupId или lessonPlanId"}));
	}
}
