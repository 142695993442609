const URL = "/api/schedules";
const DATE_FORMAT = "YYYY-MM-DD";
const WEEK_COUNT = 52;
const SPO_WEEK_COUNT = 52;


class Schedule {
	/**
	 * Constructor
	 * @param weekNumber
	 * @param beginDate
	 * @param endDate
	 */
	constructor(weekNumber, beginDate, endDate) {
		this.id = null;
		this.schedule_items_count = 0;
		this.hours_count = 0;
		this.begin_date = beginDate;
		this.end_date = endDate;
		this.week_number = weekNumber;
	}
}


class SchedulesService {
	static $inject = ["Jersey", "$q"];

	/**
	 * Constructor
	 * @param Jersey
	 * @param $q
	 */
	constructor(Jersey, $q) {
		this.services = {Jersey, $q};
		this.list = [];
		this.collection = Jersey.all(URL);
		this.academicYear = null;
		this.schedules = [];
		this.promise = null;
	}


	/**
	 * getWeekItems
	 */
	getWeekItems() {
		return this.collection.all("items");
	}


	/**
	 * bindItem
	 * @param scheduleId
	 * @param itemId
	 * @returns {*|{get}}
	 */
	bindItem(scheduleId, itemId) {
		return this.bindOne(scheduleId).one("items", itemId);
	}


	/**
	 * bindOne
	 * @param id
	 * @returns {*|{get}}
	 */
	bindOne(id) {
		const {Jersey} = this.services;

		return Jersey.one(URL, id);
	}


	/**
	 * Копируем расписание
	 * @param id: number
	 * @param query: {is_hard_mode: boolean, week_numbers: number[]}
	 */
	async copy(id, query) {
		try {
			return this.bindOne(id).all("copy").customPOST(query);
		} catch (error) {
			throw error;
		}
	};


	/**
	 * createSchedule
	 * @param weekNumber
	 * @returns {Promise<void>}
	 */
	async createSchedule(academicYear, weekNumber) {
		if (!Boolean(weekNumber)) {
			throw new Error("Не передан номер недели!");
		}

		const schedule = _.find(this.schedules, {week_number: weekNumber});
		const params = {
			academic_year_id: academicYear.id,
			week_number: weekNumber,
			is_template: false
		};

		if (Boolean(schedule)) {
			return schedule;
		}

		try {
			const newSchedule = await this.collection.customPOST(params);
			this.schedules = this.$prepareSchedules([...this.schedules, newSchedule]);

			return _.find(this.schedules, {id: newSchedule.id});
		} catch (error) {
			throw error;
		}
	}


	/**
	 * removeSchedule
	 * @param id
	 * @returns {Promise<void>}
	 */
	async removeSchedule(id) {
		if (!Boolean(id)) {
			throw new Error("Не передан id удаляемой недели!");
		}

		try {
			await this.bindOne(id).customDELETE();

			const index = _.findIndex(this.schedules, {id});
			if (index > -1) {
				this.schedules.splice(index, 1);
			}
		} catch (error) {
			throw error;
		}
	}


	/**
	 * Возвращает список всех расписаний и шаблонов за год
	 * @param academicYear
	 * @return {*}
	 */
	async getSchedules(academicYear, reload = false) {
		if (!academicYear) {
			throw new Error("Не передан academicYear!");
		}

		try {
			if (!reload && this.promise && !Boolean(this.promise.$$state.status)) {
				this.schedules = await this.promise;

				return this.schedules;
			} else if (
				!reload && Boolean(this.academicYear) && this.academicYear.id === academicYear.id && this.schedules.length > 0
			) {
				return this.schedules;
			}

			this.academicYear = academicYear;
			const params = {
				academic_year_id: this.academicYear.id,
				with_hours: true,
				with_count: true
			};

			this.promise = this.collection.getList(params).then(this.$prepareSchedules.bind(this));
			this.schedules = await this.promise;

			return this.schedules;
		} catch (error) {
			throw error;
		}
	};


	/**
	 * Возвращаем полный список недель
	 * @param academicYear
	 * @param forSPO
	 * @returns {Promise.<*>}
	 */
	async getSchedulesWithAdditionalData(academicYear, forSPO = false) {
		try {
			const schedules = await this.getSchedules(academicYear);

			return this.$mapWithAdditionalData(schedules, forSPO);
		} catch (error) {
			throw error;
		}
	}


	/**
	 * Мапим расписания
	 */
	$mapWithAdditionalData(schedules, forSPO) {
		const weekCount = forSPO ? SPO_WEEK_COUNT : WEEK_COUNT;
		const {begin_date: begin} = this.academicYear;
		const dummyWeeks = [];
		const beginYear = moment(begin, DATE_FORMAT);
		const endYear = beginYear.clone().add(weekCount, "weeks").endOf("week").endOf("day");
		let beginMoment = moment(begin, DATE_FORMAT);
		let endMoment = moment(begin, DATE_FORMAT).endOf("week").endOf("day");
		let weekNumber = 1;

		while (beginMoment.isBetween(beginYear, endYear, null, "[]")) {
			const schedule = _.find(schedules, {week_number: weekNumber});
			const beginDate = beginMoment.format(DATE_FORMAT);
			const endDate = endMoment.isAfter(endYear) ? endYear.format(DATE_FORMAT) : endMoment.format(DATE_FORMAT);

			if (!Boolean(schedule)) {
				dummyWeeks.push(new Schedule(weekNumber, beginDate, endDate));
			}

			weekNumber += 1;
			beginMoment = endMoment.clone().add(1, "days");
			endMoment = beginMoment.clone().endOf("week").endOf("day");
		}

		return _.chain([...schedules, ...dummyWeeks]).sortBy("week_number").slice(0, weekCount + 1).value();
	}


	/**
	 * getScheduleIdByDate
	 * @param day
	 * @param academicYear
	 * @returns {*|{catch}|void|PromiseLike<T>|Promise<T>}
	 */
	async getScheduleIdByDate(day, academicYear) {
		const dayMoment = moment(day, DATE_FORMAT);

		try {
			const schedules = await this.getSchedules(academicYear);
			const $schedule = this.$findSchedule(schedules, dayMoment);

			if (Boolean($schedule)) {
				return $schedule.id;
			}

			return null;
		} catch (error) {
			throw error;
		}
	};


	/**
	 * $findSchedule
	 * @param schedules
	 * @param dayMoment
	 */
	$findSchedule(schedules, dayMoment) {
		return _.find(schedules, (schedule) => {
			const begin = moment(schedule.begin_date, DATE_FORMAT);
			const end = moment(schedule.end_date, DATE_FORMAT);

			return !schedule.is_template
				&& (dayMoment.isAfter(begin) || dayMoment.isSame(begin))
				&& (dayMoment.isBefore(end) || dayMoment.isSame(end));
		});
	}


	/**
	 * Дополняем недели дополнительными данными
	 * @param schedules
	 */
	$prepareSchedules(schedules) {
		const weekStart = moment(this.academicYear.begin_date).weekday(0).format(DATE_FORMAT);
		const numberOfWeeks = 53;

		return _.chain(schedules)
			.filter((schedule) => {
				return Boolean(schedule.is_template) || (Boolean(schedule.week_number) && schedule.week_number <= numberOfWeeks);
			})
			.map((schedule) => {
				if (!schedule.is_template) {
					schedule.begin_date = moment(weekStart).add(schedule.week_number - 1, "week").format("YYYY-MM-DD");
					schedule.end_date = moment(schedule.begin_date).add(6, "day").format("YYYY-MM-DD");
				}

				return schedule;
			})
			.sortBy("week_number")
			.value();
	}

	/**
	 * Изменение технологии урока
	 * @param params
	 */
	async changeLessonsType(params) {
		try {
			return this.collection.customPOST(params, "changeType");
		} catch (error) {
			throw error;
		}
	};
}


angular
	.module("ezd.backend")
	.service("$$schedules", SchedulesService);
