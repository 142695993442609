

angular
	.module("ezd.backend")
	.service("$$scheduleLesson", $$scheduleLesson);


/**
 *
 * @param Jersey
 */
$$scheduleLesson.$inject = ["Jersey"];
function $$scheduleLesson(Jersey) {
	const url = "/api/schedule_items";
	const vm = this;


	vm.list = [];
	vm.collection = Jersey.all(url);
	vm.getLessonsForGroupsWithCurrentDate = getLessonsForGroupsWithCurrentDate;


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Jersey.one(url, id);


	/**
	 *
	 * @param groupIds
	 * @param yearId
	 * @returns {*}
	 */
	function getLessonsForGroupsWithCurrentDate(groupIds, yearId) {
		return Jersey
			.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true))
			.all(url)
			.getList({
				group_id: groupIds.join(","),
				expose: true,
				per_page: 0,
				academic_year_id: yearId
			})
			.then((response) => {
				vm.list = response.data;
				if (!response.data.errCode || response.data.errCode === 0) {
					return {
						data: response.data,
						unixtime: response.headers("X-Datetime")
					};
				}

				throw response;
			});
	}
}
