

angular
	.module("ezd.backend")
	.factory("ECAttendance", ECAttendance);


/**
 *
 * @param Core
 * @returns {*|Object}
 * @constructor
 */
ECAttendance.$inject = ["EC"];
function ECAttendance(EC) {
	/**
	 *     ids=1,2,3,4
	 student_profile_id=12
	 ec_form_id=123
	 academic_year_id=4
	 start_at (DD.MM.YYYY)
	 stop_at (DD.MM.YYYY)
	 * @type {string}
	 */
	const url = "/api/ec_attendances";
	const Service = EC.service(url);
	const ABSENCE_REASONS = {
		OUT: 2,
		FREE: 1
	};

	Service.DATE_FORMAT = "DD.MM.YYYY";


	Service.getTemplate = getTemplate;
	Service.removeAttendance = removeAttendance;
	Service.getList = (query) => EC.all(url).getList(query);

	return Service;


	/**
	 * Удаление пропуска
	 * @param attendance
	 * @returns {*}
	 */
	function removeAttendance(attendance) {
		if (!attendance.restangularized) {
			EC.restangularizeElement(null, attendance, url);
		}

		return attendance.remove();
	}

	/**
	 * Получаем шаблон для пропуска
	 * @returns {*}
	 */
	function getTemplate() {
		return {
			absence_reason_id: ABSENCE_REASONS.OUT,
			ec_lesson_id: null,
			student_profile_id: null,
			teacher_id: null,
			date: null
		};
	}
}
