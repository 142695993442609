const URL = "/api/ae_period_schedules";

class AEPeriodSchedules {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	one(id) {
		return this.CoreService.one(URL, id);
	}


	/**
	 * Копирование КУГ
	 * @param id {Number}
	 */
	async copy(id) {
		return await this.collection.customPOST({}, `${id}/copy`);
	}
}


angular
	.module("ezd.backend")
	.service("AEPeriodSchedules", AEPeriodSchedules);
