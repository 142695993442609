angular
	.module("ezd.backend")
	.factory("AEGroups", aeGroups);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeGroups.$inject = ["Core"];
function aeGroups(Core) {
	return Core.service("/api/ae_groups");
}

