

angular
	.module("ezd.backend")
	.service("$$reportSchoolBags", reportSchoolBagsService);


/**
 *
 * @param REP
 * @param $q
 */
reportSchoolBagsService.$inject = ["REP", "$q"];
function reportSchoolBagsService(REP, $q) {
	const url = "/api/bag/admin";
	const vm = this;
	let abort = $q.defer();

	vm.collection = REP.all(url).withHttpConfig({timeout: abort.promise});

	vm.getJson = getJson;
	vm.abortAll = abortAll;


	/**
	 *
	 * @param params
	 * @returns {*|{then}}
	 */
	function getJson(params) {
		if (abort) {
			abort.resolve();
		}
		abort = $q.defer();

		return REP.all(url).one("json").withHttpConfig({timeout: abort.promise}).get(params);
	}


	/**
	 *
	 */
	function abortAll() {
		abort.resolve();
		abort = $q.defer();
		vm.collection = REP.all(url).withHttpConfig({timeout: abort.promise});
	}
}
