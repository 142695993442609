

angular
	.module("ezd.backend")
	.factory("$$teacherLogs", teacherLogs);


/**
 *
 * @param LMSv2
 * @returns {Object|*}
 */
teacherLogs.$inject = ["LMSv2"];
function teacherLogs(LMSv2) {
	return LMSv2.service("/api/teacher_logs");
}

