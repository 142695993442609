angular
	.module("ezd.backend")
	.factory("RequiredDecisions", RequiredDecisions);

RequiredDecisions.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {{}}
 * @constructor
 */
function RequiredDecisions(Core) {
	const vm = {};

	vm.count = count;
	vm.getScheduleCalcs = getScheduleCalcs;
	vm.getStudentsWithoutCurricula = getStudentsWithoutCurricula;
	vm.getJournalChecks = getJournalChecks;
	vm.getRejectedTestLessons = getRejectedTestLessons;
	vm.getReportedMessages = getReportedMessages;


	/**
	 *
	 * @returns {Promise.<*>}
	 */
	async function count(profile, academicYearId) {
		if (_.has(vm, "counted")) {
			return vm.counted;
		}
		vm.counted = await Core.all("/api/requires_decisions/count").customGET("", {
			academic_year_id: academicYearId
		});
		if (!profile || (profile.roles &&_.includes(profile.roles, "school_admin"))) {
			vm.counted.schedule_calcs_count = (await getScheduleCalcs()).count;
		}

		return vm.counted;
	}

	function getScheduleCalcs(params) {
		return Core.all('../schedule/api/list').customGET(params);
	}

	/**
	 *
	 * @param params
	 */
	function getStudentsWithoutCurricula(params) {
		return Core.all("/api/requires_decisions/students_without_curricula").getList(params);
	}


	/**
	 *
	 * @param params
	 */
	function getJournalChecks(params) {
		return Core.all("/api/requires_decisions/journal_checks").getList(params);
	}


	/**
	 *
	 * @param params
	 */
	function getRejectedTestLessons(params) {
		return Core.all("/api/requires_decisions/test_lessons").getList(params);
	}


	/**
	 *
	 * @param params
	 */
	function getReportedMessages(params) {
		return Core.all("/api/requires_decisions/reported_messages").getList(params);
	}

	return vm;
}

