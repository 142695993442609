angular
	.module("ezd.backend")
	.factory("ECLessonCommentService", ecLessonCommentService);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
ecLessonCommentService.$inject = ["EC"];
function ecLessonCommentService(EC) {
	return EC.service("/api/ec_lesson_comments");
}
