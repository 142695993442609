import next from "./next.svg";
import prev from "./prev.svg";
import style from "./calendar.local.scss";
import template from "./Calendar.html";

angular
	.module("ezd.common.ui")
	.directive("calendar", Calendar);


/**
 *
 * @returns {{scope: {hideTitle: string}, template, controller: Controller, controllerAs: string}}
 * @constructor
 */
function Calendar() {
	return {
		restrict: "AE",
		scope: {
			hideTitle: "=?"
		},
		template,
		controller: Controller,
		controllerAs: "$ctrl"
	};
}

Controller.$inject = ["$rootScope", "$scope", "$$event"];

/**
 *
 * @param $rootScope
 * @param $scope
 * @param $$event
 * @constructor
 */
function Controller($rootScope, $scope, $$event) {
	const vm = this;

	// defaults
	vm.weeks = [];

	vm.styles = style;

	vm.icons = {
		prev,
		next
	};

	vm.hideTitle = $scope.hideTitle;

	// bindings
	vm.getTitle = getTitle;
	vm.prevMonth = prevMonth;
	vm.nextMonth = nextMonth;
	vm.selectDay = selectDay;
	vm.isSelectedDay = isSelectedDay;


	activate();


	function activate() {
		// makeCalendarData();
		// обработчики событий изменения списка событий и изменения фильтров событий
		_.forEach(["organizer:EventListChanged", "organizer:FiltersChanged"], (event) => {
			$rootScope.$on(event, () => {
				makeCalendarData();
			});
		});
	}


	/**
	 * заголовок для календаря (название месяца и год)
	 * @returns {*}
	 */
	function getTitle() {
		return $$event.selectedMonth.format("MMMM YYYY");
	}

	/**
	 * Сдвиг календаря на месяц вперед
	 */
	function nextMonth() {
		$$event.selectedMonth.add(1, "months");
		activate();
		$rootScope.$emit("organizer:MonthChanged");
	}

	/**
	 * Сдвиг календаря на месяц назад
	 */
	function prevMonth() {
		$$event.selectedMonth.subtract(1, "months");
		activate();
		$rootScope.$emit("organizer:MonthChanged");
	}

	/**
	 * выбирает день для списка событий
	 * @param day
	 * @returns {boolean}
	 */
	function selectDay(day) {
		if (!day || !day.date) {
			return false;
		}
		$$event.selectedDay = angular.copy(day.date);
		$rootScope.$emit("organizer:FiltersChanged");
	}

	/**
	 * Проверка является день выбранным в календаре днем
	 * @param day
	 * @returns {boolean}
	 */
	function isSelectedDay(day) {
		if (!day || !day.date) {
			return false;
		}

		return $$event.selectedDay.format("DD.MM.YYYY") === day.date.format("DD.MM.YYYY");
	}

	/**
	 * Формирование календаря с событиями
	 */
	function makeCalendarData() {
		const monthStart = moment($$event.selectedMonth).startOf("month");
		const monthEnd = moment($$event.selectedMonth).endOf("month");
		const result = new Array(monthStart.isoWeekday() - 1);

		// заполняем пустое место в начале месяца
		_.fill(result, null);

		_.times(monthEnd.date(), () => {
			const dayDate = angular.copy(monthStart);
			result.push({
				dayNumber: dayDate.date(),
				date: dayDate,
				icons: findIconsForDate(dayDate),
				test: isTest(dayDate)
			});
			monthStart.add(1, "day");
		});

		const weeks = _.chunk(result, 7);
		_.times(7 - _.last(weeks).length, () => {
			_.last(weeks).push(null);
		});

		vm.weeks = weeks;
		console.log("weeks", weeks);
	}


	/**
	 *
	 * @param date
	 * @returns {number}
	 */
	function isTest(date) {
		return $$event.getTestLessonsForDate(date).length;
	}

	/**
	 * иконки событий для дня в календаре
	 * @param date {moment}
	 * @returns {Array}
	 */
	function findIconsForDate(date) {
		const eventsType = ($rootScope.refName === "my_calendar") ? "private" : "public";
		const events = $$event.findEventsForDate(date, eventsType);
		const priority = $$event.getPriorityForEvents(events);
		const icons = [];

		if (priority.importance_level_2 > 0) {
			icons.push(
				priority.importance_level_2 === 1 ? "b-i-red b-i-sm" : (
					priority.importance_level_2 < 3 ? "b-i-red b-i-md" : "b-i-red b-i-lg"
				)
			);
		}

		if (priority.importance_level_1 > 0) {
			icons.push(
				priority.importance_level_1 === 1 ? "b-i-blue b-i-sm" : (
					priority.importance_level_1 < 3 ? "b-i-blue b-i-md" : "b-i-blue b-i-lg"
				)
			);
		}

		if (priority.importance_level_0 > 0) {
			icons.push(
				priority.importance_level_0 === 1 ? "b-i-green b-i-sm" : (
					priority.importance_level_0 < 3 ? "b-i-green b-i-md" : "b-i-green b-i-lg"
				)
			);
		}

		return icons;
	}
}

