import {Component} from "app/decorators/index";
import template from "./ezd-radio-buttons-item.html";
import "./ezd-radio-buttons-item.local.scss";

@Component({
	selector: "ezdRadioButtonsItem",
	template,
	transclude: true,
	require: {
		parent: "^ezdRadioButtons"
	},
	bindings: {
		valueItem: "<",
		disabled: "<"
	}
})
export class EzdRadioButtonsItem {
	/**
	 * OnInit
	 */
	$onInit() {
		this.inputId = this.$generateRandomString();

		if (_.isUndefined(this.disabled)) {
			this.disabled = false;
		}
	}

	/**
	 * Обрабатываем клик по кнопке
	 */
	clickHandler() {
		this.parent.clickOnButton();
	}


	/**
	 * Генерируем рандомную строку
	 * @returns {string}
	 */
	$generateRandomString() {
		return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now() + "_ezd_radio_button";
	}
}
