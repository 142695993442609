

angular
	.module("ezd.backend")
	.service("$$subject", $$subject);


/**
 *
 * @param Core
 */
$$subject.$inject = ["Core"];
function $$subject(Core) {
	const url = "/api/subjects";
	const vm = this;


	vm.list = [];
	vm.collection = Core.all(url);
	vm.collection.getList = (query) => Core.all(url).getList(query);
	vm.restore = restore;
	vm.restoreById = restoreById;

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);


	/**
	 *
	 * @param query
	 */
	vm.getList = (query) => vm.collection.getList(query);

	function restore(subject) {
		return Core.one(`${url}/${subject.id}/restore`).get({
			with_groups: true,
			with_lessons: true,
			with_control_forms: true,
			with_marks: true
		});
	}

	function restoreById(subjectId) {
		return Core.one(`${url}/${subjectId}/restore`).get({
			with_groups: true,
			with_lessons: true,
			with_control_forms: true,
			with_marks: true
		});
	}
}
