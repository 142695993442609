const _ = require("lodash");
import {LAZY} from "./components.list";

loadLazyComponents.$inject = ["$ocLazyLoad", "$rootScope", "$location", "$urlRouter"];
/**
 * Для регистрации ленивых роутов в самом ангуляре
 * @param $ocLazyLoad
 * @param $rootScope
 * @param $location
 * @param $urlRouter
 */
export function loadLazyComponents($ocLazyLoad, $rootScope, $location, $urlRouter) {
	// вешаем листенер на смену пути для прогрузки ленивых компонентов
	$rootScope.$on("$locationChangeStart", (event, url, current) => {
		console.log("$locationChangeStart", event, url, current);

		const replacedUrl = url.replace(/https?:\/\/[\w+\-?\w+.]+:?\d*/, "");
		const replacedCurrent = current.replace(/https?:\/\/[\w+\-?\w+.]+:?\d*/, "");
		if (replacedUrl.includes("/authenticate?")) {
			// $urlRouter.sync();
			// $location.url(replacedUrl);
			document.location.href = url;
		} else {
			const LazyComponent = getRoute($location.path());

			if (LazyComponent && !LazyComponent.loaded) {
				event.preventDefault();

				$rootScope.componentLoadingPromise = import("components/" + LazyComponent.path + "/index.js")
					.then(() => {
						return $ocLazyLoad.load({name: LazyComponent.name});
					})
					.then(() => {
						LazyComponent.loaded = true;
						if (replacedUrl === replacedCurrent) {
							$urlRouter.sync();
						} else {
							console.log(replacedUrl);

							$location.url(replacedUrl);
						}
					})
					.catch(e => console.error("lazy load error:", e));
			}
		}
	});
}


/**
 *
 * @param route
 */
function getRoute(route) {
	return _.find(LAZY, m => {
		return _.find(m.routes, r => {
			const splitted = r.split(/:\w+\/?/);
			const reg = new RegExp(_.map(_.compact(splitted), s => s + "(.*)").join(""));

			return splitted.length > 1 ? route.match(reg) : splitted[0] === route;
		});
	});
}
