import {EzdCheckboxComponent} from "components/common/components/ezd_checkbox/ezd-checkbox.component";
import {Component} from "app/decorators/index";
import template from "./assignment-checkbox.template.html";
import styles from "./assignment-checkbox.local.scss";
import _isUndefined from "lodash/fp/isUndefined";
import _assign from "lodash/fp/assign";

@Component({
	selector: "assignmentCheckbox",
	template,
	bindings: _assign(EzdCheckboxComponent.bindings)({
		model: "=?",
		selected: "<?"
	}),
	transclude: true
})
export class AssignmentCheckboxComponent extends EzdCheckboxComponent.controller {
	styles = styles;

	/**
	 * */
	constructor($scope, $timeout, $element) {
		super($scope, $timeout, $element);

		if (_isUndefined(this.model)) {
			this.model = this.selected || false;
		}
	}

	$onChanges(changes) {
		if (changes.selected) {
			this.model = this.selected;
		}
	}
}
