angular
	.module("ezd.backend")
	.service("$$reportsSettings", reportsSettingsService);


/**
 *
 * @param REP
 * @param $q
 */
reportsSettingsService.$inject = ["REP", "$q"];
function reportsSettingsService(REP, $q) {
	const url = "/api/ttl_settings";
	const vm = this;
	let abort = $q.defer();

	vm.collection = REP.all(url).withHttpConfig({timeout: abort.promise});

	vm.getJson = getJson;
	vm.truncate = truncate;
	vm.abortAll = abortAll;


	/**
	 *
	 * @returns {*|{then}}
	 */
	function getJson() {
		if (abort) {
			abort.resolve();
		}
		abort = $q.defer();

		return REP.all(url).one("json").withHttpConfig({timeout: abort.promise}).get();
	}

	/**
	 * Очистить кэш
	 */
	function truncate() {
		return REP.all(url).one("truncate").withHttpConfig({timeout: abort.promise}).get();
	}
	/**
	 *
	 */
	function abortAll() {
		abort.resolve();
		abort = $q.defer();
		vm.collection = REP.all(url).withHttpConfig({timeout: abort.promise});
	}
}
