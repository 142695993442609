

angular
	.module("ezd.backend")
	.service("$$scheduleTransfers", Service);

/**
 * сервис для переносов уроков (функционал создания расписания выходного дня)
 * @param Jersey
 * @constructor
 */
Service.$inject = ["Jersey"];
function Service(Jersey) {
	const url = "/api/schedule_transfers";
	const vm = this;

	vm.collection = Jersey.all(url);

	vm.bindOne = bindOne;


	function bindOne(id) {
		return Jersey.one(url, id);
	}
}

