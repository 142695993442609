angular
	.module("ezd.backend")
	.factory("ApprovedControllableItems", ApprovedControllableItems);

ApprovedControllableItems.$inject = ["Core"];

/**
 *
 * @param core
 * @returns {*|Object}
 * @constructor
 */
function ApprovedControllableItems(core) {
	return core.service("/api/approved_controllable_items");
}

