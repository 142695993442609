import styles from "./header.local.scss";
import template from "./header.html";

import {banner, bannerChildren} from "images/icons/index";

/**
 * @param $location
 * @constructor
 */
class HeaderController {
	static $inject = ["$location", "$$user", "$$profile", "$window", "$cookies", "$scope", "$stateParams",
		"$$studentProfiles", "SystemMessages"];

	constructor($location, $$user, $$profile, $window, $cookies, $scope, $stateParams, $$studentProfiles, SystemMessages) {
		this.NODE_ENV = require("NODE_ENV");
		this.icons = {banner, bannerChildren};

		this.services = {$location, $$user, $$profile, $window, $cookies, $scope, $stateParams, $$studentProfiles, SystemMessages};
		this.styles = styles;
		this.bannerMessage = null;
	}

	$onInit() {
		this.activate();
	}

	/**
	 * activate
	 */
	activate() {
		const {SystemMessages} = this.services;
		SystemMessages.getCurrentBanner()
			.then((mainMsg) => {
				if (Boolean(mainMsg)) {
					this.bannerMessage = mainMsg;
				} else {
					this.bannerMessage = null;
				}
			});
	}
}

angular
	.module("ezd.common.ui")
	.component("appHeader", {
		template,
		controller: HeaderController
	});
