angular
	.module("ezd.backend")
	.service("$$attestationPeriodsSchedule", attestationPeriodsSchedule);


/**
 *
 * @param Core
 */
attestationPeriodsSchedule.$inject = ["Core"];
function attestationPeriodsSchedule(Core) {
	const vm = this;

	/**
	 *
	 * DECLARATION
	 *
	 */

	vm.bindOne = bindOne;
	vm.getList = getList;
	vm.save = save;
	vm.getTemplate = getTemplate;


	/**
	 *
	 * VARIABLES
	 *
	 */
	vm.url = "/api/attestation_periods_schedules";
	vm.collection = Core.all(vm.url);
	vm.dateFormat = "DD.MM.YYYY";
	vm.dateFormatReceive = "DD.MM.YYYY";
	// vm.dateFormatReceive = 'YYYY-MM-DD'


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(vm.url, id);
	}


	/**
	 *
	 */
	function getList(params) {
		return vm.collection
			.getList(params)
			.then((data) => {
				let ordinal = 1;

				return _.map(_.sortBy(data, "id"), (aps) => {
					aps.ordinal = ordinal++;
					aps.periods = _.sortBy(
						_.map(aps.periods, (period) => {
							period.begin_date = moment(period.begin_date, vm.dateFormatReceive).format(vm.dateFormat);
							period.end_date = moment(period.end_date, vm.dateFormatReceive).format(vm.dateFormat);
							period.unixtime = moment(period.begin_date, vm.dateFormat).unix();

							return period;
						}), "unixtime"
					);

					return aps;
				});
			});
	}

	/**
	 * Сохраняем ГАП
	 * @returns {*}
	 * @param schedule
	 */
	function save(schedule) {
		if (schedule.id) {
			return update(schedule);
		}

		return create(schedule);
	}


	/**
	 * Создаём ГАП
	 * @returns {*}
	 * @param schedule
	 */
	function create(schedule) {
		return vm.collection.post(schedule);
	}


	/**
	 * Изменяем ГАП
	 * @returns {*}
	 * @param schedule
	 */
	function update(schedule) {
		Core.restangularizeElement(null, schedule, vm.url);

		return schedule.put();
	}


	/**
	 *
	 * @param ordinal
	 * @param yearId
	 * @param schoolId
	 * @returns {{academic_year_id: *, school_id: *, name: string, ordinal: *, periodsQuantity: number, prevPeriodsQuantity: number, periods: [*]}}
	 */
	function getTemplate(ordinal, yearId, schoolId) {
		return {
			academic_year_id: yearId,
			school_id: schoolId,
			name: "График аттестационных периодов " + ordinal,
			ordinal,
			periodsQuantity: 1,
			prevPeriodsQuantity: 1,
			periods: [
				{
					name: "Аттестационный период 1",
					ordinal: 1,
					begin_date: "",
					end_date: ""
				}
			]
		};
	}
}
