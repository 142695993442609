import {Component} from "app/decorators/index";
import template from "./ezd-demo-screen-switch.html";
import styles from "./ezd-demo-screen-switch.local.scss";
import {remove} from "images/icons/index";

import _compact from "lodash/fp/compact";
import _find from "lodash/fp/find";
import _flow from "lodash/fp/flow";
import _forEach from "lodash/fp/forEach";
import _int from "tools/fp/int";
import _split from "lodash/fp/split";


@Component({
	selector: "ezdDemoScreenSwitch",
	template,
	bindings: {
		currentUrl: "<"
	}
})

export class EzdDemoScreenSwitchComponent {
	static $inject = ["$$profile", "Rights", "$location", "$cookies", "$window", "URLS_LIST"];

	styles = styles;
	icons = {remove};

	currentUrlPack = null;
	isDemoVersion = null;
	url = "";


	/**
	 *
	 * @param $$profile
	 * @param Rights
	 * @param $location
	 * @param $cookies
	 * @param $window
	 * @param URLS_LIST
	 */
	constructor($$profile, Rights, $location, $cookies, $window, URLS_LIST) {
		this.services = {$$profile, Rights, $location, $cookies, $window, URLS_LIST};
	}

	$onChanges() {
		const {URLS_LIST} = this.services;

		if (this.currentUrl) {
			this.currentUrlPack = _find((url) => {
				return this.currentUrl.search(url.urlToSearch) > -1 || this.currentUrl.search(url.urlToGo) > -1;
			})(URLS_LIST.urls);

			this.url = this.currentUrlPack ? this.getRedirectUrl() : "";

			if (this.currentUrlPack) {
				this.isDemoVersion = this.hasMatchDemoUrl();
			}
		}
	}

	/**
	 *
	 */
	hasMatchDemoUrl() {
		const {URLS_LIST} = this.services;
		let hasMatch = false;

		_forEach((demoUrl) => {
			hasMatch = this.currentUrl.search(demoUrl) > -1;

			if (hasMatch) {
				return false;
			}
		})(URLS_LIST.demoScreenUrls);

		return hasMatch;
	}

	/**
	 *
	 */
	getRedirectUrl() {
		if (this.currentUrlPack.name === "diary") {
			const studentProfileId = _flow(
				_split(this.currentUrlPack.urlToSearch),
				_compact,
				_split("/"),
				_compact,
				_int
			)(this.currentUrl);

			return `${this.currentUrlPack.urlToGo}/${studentProfileId}`;
		}

		return this.currentUrl.search(this.currentUrlPack.urlToSearch) > -1
			? this.currentUrlPack.urlToGo
			: this.currentUrlPack.urlToSearch;
	}

	/**
	 *
	 * @param $event
	 */
	redirectTo($event) {
		const {$window} = this.services;

		$event.preventDefault();

		if (this.currentUrlPack) {
			$window.open(this.url, "_self");
		}
	}

	/**
	 * Скрываем компонент
	 */
	close() {
		this.currentUrlPack = null;
	}
}

angular
	.module("ezd.common.ui")
	.component(EzdDemoScreenSwitchComponent.selector, EzdDemoScreenSwitchComponent);
