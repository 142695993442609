angular
	.module("ezd.backend")
	.service("$$attestationPeriod", attestationPeriod);


/**
 *
 * @param $q
 * @param Core
 */
attestationPeriod.$inject = ["$q", "Core"];
function attestationPeriod($q, Core) {
	const vm = this;


	/**
	 *
	 * DECLARATION
	 *
	 */

	vm.bindOne = bindOne;
	vm.getTemplate = getTemplate;
	vm.save = save;
	vm.remove = remove;
	vm.saveCollection = saveCollection;
	vm.removeCollection = removeCollection;


	/**
	 *
	 * VARIABLES
	 *
	 */
	vm.url = "/api/attestation_periods";
	vm.collection = Core.all(vm.url);
	vm.dateFormat = "DD.MM.YYYY";
	vm.dateFormatReceive = "DD.MM.YYYY";
	// vm.dateFormatReceive = 'YYYY-MM-DD'


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(vm.url, id);
	}

	/**
	 * Сохраняем период ГАП
	 * @param period
	 * @param dateFormat
	 * @returns {*}
	 */
	function save(period, dateFormat) {
		if (period.id) {
			return update(period, dateFormat);
		}

		return create(period, dateFormat);
	}


	/**
	 * Создаём период ГАП
	 * @returns {*}
	 * @param period
	 * @param dateFormat
	 */
	function create(period, dateFormat) {
		period.begin_date = moment(period.begin_date, dateFormat).format("DD.MM.YYYY");
		period.end_date = moment(period.end_date, dateFormat).format("DD.MM.YYYY");

		return vm.collection.post(period);
	}

	/**
	 * Изменяем период ГАП
	 * @returns {*}
	 * @param period
	 * @param dateFormat
	 */
	function update(period, dateFormat) {
		Core.restangularizeElement(null, period, vm.url);

		period.begin_date = moment(period.begin_date, dateFormat).format(vm.dateFormat);
		period.end_date = moment(period.end_date, dateFormat).format(vm.dateFormat);

		return period.put();
	}

	/**
	 * Удаляем период
	 * @param period
	 * @returns {*}
	 */
	function remove(period) {
		if (!period.restangularized) {
			Core.restangularizeElement(null, period, vm.url);
		}

		return period.remove();
	}

	/**
	 * Сохраняем сразу несколько периодов
	 * @param periods
	 * @param apsId
	 * @param dateFormat
	 * @returns {*}
	 */
	function saveCollection(periods, apsId, dateFormat) {
		const promises = [];
		_.forEach(periods, (period) => {
			promises.push(vm.save(_.assign(period, {attestation_periods_schedule_id: apsId}), dateFormat));
		});

		return $q.all(promises)
			.then((data) => {
				const a = _.map(data, (period) => {
					period.begin_date = moment(period.begin_date, vm.dateFormatReceive).format(vm.dateFormat);
					period.end_date = moment(period.end_date, vm.dateFormatReceive).format(vm.dateFormat);
					period.unixtime = moment(period.begin_date, vm.dateFormat).unix();

					return period;
				});

				return _.sortBy(a, "unixtime");
			});
	}

	/**
	 * Удаляем сразу несколько периодов
	 * @param periods
	 * @returns {*}
	 */
	function removeCollection(periods) {
		const promises = [];
		_.forEach(periods, (period) => {
			promises.push(vm.remove(period));
		});

		return $q.all(promises);
	}

	/**
	 * Создаём шаблон аттестационного периода
	 * @param realOrdinal
	 * @param ordinal
	 * @param beginDate
	 * @param endDate
	 * @param scheduleId
	 * @returns {{name: string, ordinal: *, begin_date: *, end_date: *}}
	 */
	function getTemplate(realOrdinal, ordinal, beginDate, endDate, scheduleId) {
		const period = {
			name: "Аттестационный период " + ordinal,
			ordinal: realOrdinal,
			begin_date: beginDate,
			end_date: endDate
		};
		if (scheduleId) {
			period.attestation_periods_schedule_id = scheduleId;
		}

		return period;
	}
}
