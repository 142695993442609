angular
	.module("ezd.backend")
	.factory("$$userLogged", userLogged);


/**
 *
 * @param ACL
 * @returns {Object|*}
 */
userLogged.$inject = ["ACL"];
function userLogged(ACL) {
	return ACL.service("api/logged_in_date");
}
