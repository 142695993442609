

angular
	.module("ezd.backend")
	.factory("$$mcko", mcko);


mcko.$inject = ["$q", "ACL", "$window", "flashAlert"];
/**
 *
 * @param $q
 * @param ACL
 * @param $window
 * @param flashAlert
 * @returns {Object|*}
 */
function mcko($q, ACL, $window, flashAlert) {
	const service = ACL.service("/api/mcko/token");

	// defaults
	service.targetUrl = "https://okmcko.permkrai.ru/jump_alt.php?sess_token=";

	// bindings
	service.redirectDefault = redirectDefault;
	service.redirectParent = redirectParent;

	return service;

	// implementation

	/**
	 * Перенаправляет пользователя в МЦКО.
	 * @param  {String} profileType Тип пользователя (костыль для правильного POST, не несёт функциональной нагрузки).
	 * @return {Object} Промис с полученным токеном для редиректа.
	 */
	function redirectDefault(profileType) {
		return postProcess(service.post({type: profileType}));
	}

	/**
	 * Перенаправляет родителя в МЦКО.
	 * @param  {String} profileType Тип пользователя (костыль для правильного POST, не несёт функциональной нагрузки).
	 * @param  {Number} studentUserId Идентификатор пользователя ребёнка.
	 * @return {Object} Промис с полученным токеном для редиректа.
	 */
	function redirectParent(profileType, studentUserId) {
		return postProcess(service.post({type: profileType, student_user_id: studentUserId}));
	}

	/**
	 * Обработка данных от API.
	 * @param  {Object} call Промис вызова API.
	 * @return {Object} Промис с полученным токеном для редиректа.
	 */
	function postProcess(call) {
		const deferred = $q.defer();

		// Блокировщик попапов в браузере блокирует новое окно,
		// если в функции происходит асинхронщина (xhr, setTimeout)
		// связанная с переходом
		// https://stackoverflow.com/questions/51542763/calling-window-open-with-parameters-from-ajax
		const newWindow = $window.open("", "_blank");

		call
			.then((res) => {
				if (res.token) {
					newWindow.location = service.targetUrl + res.token;
					deferred.resolve(res.token);
				} else {
					newWindow.close();
					flashAlert.error("К сожалению, переход во внешние системы временно недоступен.");
					deferred.reject(res.plain());
				}
			});

		return deferred.promise;
	}
}
