const URL = "/api/ae_attestation_period_schedules";
const CREATE_WITH_PERIODS = "/create_with_periods";

class AEAttestationPeriodSchedule {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	one(id) {
		return this.CoreService.one(URL, id);
	}


	/**
	 * Создаем аттестационный график вместе с периодами
	 */
	async createWithPeriods(schedule) {
		if (!Boolean(schedule.periods.length)) {
			throw new Error("Невозможно создать аттестационный график без периодов");
		}

		return await this.CoreService.all(`${URL}${CREATE_WITH_PERIODS}`).customPOST(schedule);
	}
}

angular
	.module("ezd.backend")
	.service("AEAttestationPeriodSchedule", AEAttestationPeriodSchedule);
