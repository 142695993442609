import {Component} from "app/decorators/index";
import template from "./ezd-radio-buttons.html";
import styles from "./ezd-radio-buttons.local.scss";

@Component({
	selector: "ezdRadioButtons",
	template,
	transclude: true,
	bindings: {
		model: "=",
		onChange: "&"
	}
})
export class EzdRadioButtons {
	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		this.styles = styles;
		this.name = `${this.$generateRandomString()}_ezd_radio_buttons`;
	}


	/**
	 *
	 */
	clickOnButton() {
		this.onChange({$event: this.model});
	}


	/**
	 * Генерируем рандомную строку
	 * @returns {string}
	 */
	$generateRandomString() {
		return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
	}
}
