import _int from "tools/fp/int";

class ECForms {
	static $inject = ["EC"];
	static URL = "/api/ec_forms";

	api = null;
	apiWithConfig = null;
	collection = null;
	collectionWithConfig = null;

	constructor(EC) {
		this.api = EC;
		this.apiWithConfig = this.api.withConfig((configBuilder) => configBuilder.setFullResponse(true));

		this.collection = this.api.all(ECForms.URL);
		this.collectionWithConfig = this.apiWithConfig.all(ECForms.URL);
	}

	/**
	 * BindOne
	 * @param id
	 * @return {*}
	 */
	bindOne(id) {
		return this.api.one(ECForms.URL, id);
	}


	/**
	 * GetList
	 * @param query
	 * @return {*}
	 */
	getList(query) {
		return this.collection.getList(query);
	}


	/**
	 * Запрашиваем страницу форм ВД
	 */
	async getPage(query, page = 1, perPage = 30) {
		const {data, headers} = await this.collectionWithConfig.getList({
			...query,
			page,
			per_page: perPage
		});

		return {
			data,
			pages: _int(headers("pages")) || 1
		};
	}
}


angular.module("ezd.backend")
	.service("ECForms", ECForms);
