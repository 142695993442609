import template from "./AcademicYearSelector.html";

import _includes from "lodash/fp/includes";


const OPTIONS = {
	template,
	controller: Controller,
	bindings: {
		dark: "<"
	}
};

angular
	.module("ezd.common.ui")
	.component("academicYearSelector", OPTIONS);

Controller.$inject = ["$$academicYear", "$$profile", "$q", "$window", "$$mrko", "$rootScope"];

/**
 *
 * @param $$academicYear
 * @param $$profile
 * @param $q
 * @param $window
 * @param $$mrko
 * @param $rootScope
 * @constructor
 */
function Controller($$academicYear, $$profile, $q, $window, $$mrko, $rootScope) {
	const vm = this;

	vm.NODE_ENV = require("NODE_ENV");
	vm.styles = require("./academic-year-selector.local.scss");
	vm.academic_years = [];
	vm.select = select;
	vm.getCurrentYear = getCurrentYear;
	vm.isAdminSecurity = isAdminSecurity;

	vm.school_id = null;
	vm.is_old = false;


	/**
	 * Тестовые школы. Актуально только на проде
	 * @type {[*]}
	 */
	vm.test_schools = [25];

	activate();

	// //////////

	/**
	 *
	 * @returns {*}
	 */
	function activate() {
		const getYears = $$academicYear
			.getList()
			.then((years) => {
				$$academicYear.list = years;
				vm.academic_years = years.filter((year) => year.id !== 1);

				return $$academicYear.getSelected();
			})
			.then((year) => {
				let academicYear;

				// если уже был выбран учебный год
				if (!_.isEmpty(year)) {
					vm.selected_year_id = year.id;
				} else {
					academicYear = _.find(vm.academic_years, {
						current_year: true
					});

					$$academicYear.setSelected(academicYear);
					vm.selected_year_id = academicYear.id;
				}
			});

		const getProfile = $$profile.getCurrentProfile()
			.then((profile) => {
				vm.school_id = profile.school_id;
				vm.currentProfile = profile;

				if (_.intersects(profile.roles, ["parent", "student"])) {
					vm.academic_years = _.reject(vm.academic_years, {id: 4});
				}

				if (_.intersects(profile.roles, ["observer", "admin_security"])) {
					vm.academic_years = _.reject(vm.academic_years, {id: 5});
				}
			});

		return $q
			.all([getYears, getProfile])
			.then(() => {
				const isFirstPilot = _.includes($$academicYear.first_pilot_schools, vm.school_id);
				// const isSecondPilot = _.includes($$academicYear.second_pilot_schools, vm.school_id);
				const isTestSchool = _.includes(vm.test_schools, vm.school_id);
				const isAdmin = _.includes(vm.currentProfile.roles, "admin");

				vm.academic_years = _.reject(vm.academic_years, {id: 3});
				vm.academic_years = _.reject(vm.academic_years, {id: 4});
				vm.academic_years = _.reject(vm.academic_years, {id: 5});
				vm.academic_years = _.reject(vm.academic_years, {id: 6});

				// не показываем старые года для определенных школ
				if (!isTestSchool && !isAdmin && !isFirstPilot) {
				// убираем 2019-2020 год для школ, вошедших после первого пилота
					vm.academic_years = _.reject(vm.academic_years, {id: 7});
				// 	if (!isSecondPilot) {
				// 		// убираем 2016-2017 год для школ, вошедших после второго пилота
				// 		vm.academic_years = _.reject(vm.academic_years, {id: 4});
				// 	}
				}


				// if (!_.includes($$academicYear.first_pilot_schools, vm.school_id) &&
				// 	!_.includes($$academicYear.second_pilot_schools, vm.school_id) &&
				// 	!_.includes(vm.test_schools, vm.school_id)
				// ) {
				// 	vm.academic_years = _.reject(vm.academic_years, {id: 4});
				// }
				// временно скрываем 2017-2018 год от НЕтестовых школ только на проде
				// if (!_.includes(vm.test_schools, vm.school_id) && vm.NODE_ENV === "production") {
				// 	vm.academic_years = _.reject(vm.academic_years, {id: 5});
				// }
			});
	}


	/**
	 *
	 */
	function select() {
		const isFirstPilotSchool = _.includes($$academicYear.first_pilot_schools, vm.school_id);
		const isSecondPilotSchool = _.includes($$academicYear.second_pilot_schools, vm.school_id);

		if (!isFirstPilotSchool && !isSecondPilotSchool && vm.selected_year_id === 4) {
			$rootScope.global_promise = $$mrko
				.redirect()
				.catch((err) => {
					console.error("redirect to MRKO is rejected with ", err);
				});

			return;
		}

		$$academicYear
			.setSelected(_.find(vm.academic_years, {
				id: vm.selected_year_id
			}))
			.then(() => {
				window.location.reload(true);
				// $window.location.assign("/desktop");
			});
	}

	/**
	 * Получить текущий год
	 * @returns {*}
	 */
	function getCurrentYear() {
		const academicYear = _.find(vm.academic_years, {
			current_year: true
		});

		return academicYear.name;
	}

	/**
	 * Проверить, является ли текущий профиль админом ИБ
	 * @returns {*}
	 */
	function isAdminSecurity() {
		return _includes("admin_security")(vm.currentProfile);
	}
}
