angular
	.module("ezd.backend")
	.factory("AEParallelCurricula", aeParallelCurricula);


aeParallelCurricula.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {Object|*}
 */
function aeParallelCurricula(Core) {
	return Core.service("/api/ae_parallel_curricula");
}
