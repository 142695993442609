import indexTemplate from "./index.html";


layouts.$inject = ["$stateProvider"];
/**
 *
 * @param $stateProvider
 */
export default function layouts($stateProvider) {
	$stateProvider
		.state("indexLayout", {
			abstract: true,
			template: indexTemplate
		});
}