angular
	.module("ezd.backend")
	.factory("$$schoolAttestationMeta", Service);


/**
 *
 * @param LMSv2
 * @returns {Object|*}
 * @constructor
 */
Service.$inject = ["Core"];
function Service(Core) {
	return Core.service("/api/school_attestation_meta");
}
