/**
 * Тематический план вд
 */
class ECCourseLessonPlan {
	static $inject = ["EC"];
	static URL = "/api/ec_course_lesson_plan";

	/**
	 * Constructor
	 */
	constructor(EC) {
		this.ECService = EC;
		this.collection = EC.all(ECCourseLessonPlan.URL);
	}


	one(id) {
		return this.ECService.one(ECCourseLessonPlan.URL, id);
	}

	/**
	 * Возвращает шаблон тематического плана вд
	 */
	getTemplate() {
		return {
			modules: [] // массив модулей (ECCourseLessonModule)
		};
	}

	/**
	 * редактирование тематического плана
	 */
	updatePlan(plan) {
		return this.one(plan.id).customPUT(plan);
	}
}


angular
	.module("ezd.backend")
	.service("ECCourseLessonPlan", ECCourseLessonPlan);
