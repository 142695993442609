

angular
	.module("ezd.backend")
	.factory("TimetableAssignment", TimetableAssignment);


/**
 *
 * @param Core
 * @param $q
 * @returns {{collection}}
 * @constructor
 */
TimetableAssignment.$inject = ["Core", "$q"];
function TimetableAssignment(Core, $q) {
	const url = "/api/bells_timetable_assignments";
	const Service = {
		collection: Core.all(url)
	};
	const fullResponse = Core.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true));

	/**
	 *
	 * DECLARATION
	 *
	 */

	Service.bindOne = bindOne;
	Service.getByClassUnitIdsWithCurrentTime = getByClassUnitIdsWithCurrentTime;
	Service.create = create;
	Service.getListWithHeaders = getListWithHeaders;


	/**
	 *
	 * RUN
	 *
	 */
	return Service;


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(url, id);
	}

	/**
	 *
	 */
	function getListWithHeaders(query) {
		return fullResponse.all(url).getList(query);
	}

	/**
	 *
	 * @param classUnitIds
	 * @returns {*}
	 */
	function getByClassUnitIdsWithCurrentTime(classUnitIds) {
		return $q((resolve, reject) => {
			Core
				.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true))
				.all(url)
				.getList(
					classUnitIds.length > 0
						? {
							class_unit_ids: classUnitIds.join(","),
							page: 1,
							per_page: 500
						}
						: {
							page: 1,
							per_page: 500
						}
				)
				.then((response) => {
					if (!response.data.errCode || response.data.errCode === 0) {
						resolve({
							data: response.data,
							unixtime: response.headers("X-Datetime")
						});
					} else {
						reject(response);
					}
				});
		});
	}

	function create(classUnitId, timetableId, begin, end) {
		return Service.collection.post({
			class_unit_id: classUnitId,
			bells_timetable_id: timetableId,
			begin_date: begin,
			end_date: end
		});
	}
}