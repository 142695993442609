

angular
	.module("ezd.backend")
	.factory("$$nonattendanceReasons", nonattendanceReasons);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
nonattendanceReasons.$inject = ["Core"];
function nonattendanceReasons(Core) {
	return Core.service("/api/nonattendance_reasons");
}
