

angular
	.module("ezd.backend")
	.service("$$school", $$school);

/**
 *
 * @param Core
 * @param $q
 */
$$school.$inject = ["Core", "$q"];
function $$school(Core, $q) {
	const url = "/api/schools";
	const vm = this;


	vm.collection = Core.all(url);
	vm.list = [];


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);


	/**
	 *
	 * @param num
	 * @param filter
	 * @returns {Promise.<T>|*}
	 */
	vm.getPage = (num, filter) => {
		if (vm.abort) {
			vm.abort.resolve();
		}
		vm.abort = $q.defer();
		const result = {
			data: [],
			pages: 1
		};
		const rest = Core
			.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true));
		let params = {page: num, per_page: 10};

		if (_.keys(filter).length !== 0) {
			params = angular.extend(params, filter);
		}

		return rest.all(url).withHttpConfig({timeout: vm.abort.promise})
			.getList(params)
			.then((response) => {
				result.data = response.data;
				result.pages = response.headers("pages");
				//	result.total_entries = response.headers("X-Pagination-Total-Entries");

				return result;
			});
	};
}
