angular
	.module("ezd.backend")
	.factory("AEActivityTypes", aeActivityTypes);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
function aeActivityTypes(Core) {
	return Core.service("/api/ae_activity_types");
}

aeActivityTypes.$inject = ["Core"];

