

angular
	.module("ezd.backend")
	.service("$$reportStudentsQuality", reportStudentsQualityService);

/**
 *
 * @param REP
 */
reportStudentsQualityService.$inject = ["REP"];
function reportStudentsQualityService(REP) {
	const url = "api/quality";
	const vm = this;

	vm.getJson = getJson;

	/**
	 * Загрузка данных для отчета в json
	 * @param params {{}}
	 * {ACADEMIC_YEAR_ID} - учебный год
	 * {CLASS_UNIT_ID} - id класса
	 * {IS_YEAR_MARK} - годовая оценка? true/false
	 * {MARK_TYPE} - тип оценки half_year_first,half_year_last,exam,attestation
	 * {PERIOD_ID} - id учебного периода
	 * {ATTESTATION_PERIOD_ID} - id аттестационного периода
	 * {MODULE_ID} - id модуля
	 * @returns {*}
	 */
	function getJson(params) {
		return REP.one(url, "json").get(params);
	}
}
