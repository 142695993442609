angular
	.module("ezd.backend")
	.factory("ScheduleCalc", scheduleCalc);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
scheduleCalc.$inject = ["Core", "$q"];
function scheduleCalc(Core, $q) {
	const service = Core.service("../schedule/api/");

	service.calcLast = calcLast;
	service.calcLatest = calcLatest;
	service.resultSend = resultSend;
	service.resultsSend = resultsSend;

	return service;

	/**
	 * метод получения id последнего расчета
	 */
	function calcLast(buildingId, forDate) {
		return service.one("calc").one("last").customGET("", {
			buildingId,
			forDate
		});
	}

	/**
	 * ...
	 */
	function calcLatest(buildingIds, forDate) {
		if (!buildingIds || !buildingIds.length) {
			return $q.all([]);
		}

		return service.one("calc").one("latest").customGET("", {
			buildingIds: buildingIds.join(","),
			forDate
		});
	}

	/**
	 * копирование расписания
	 */
	function resultSend(calcId) {
		return service.one("result").one("send").customPUT(null, null, {
			calcId
		});
	}

	/**
	 * ...
	 */
	function resultsSend(calcIds) {
		if (!calcIds || !calcIds.length) {
			return $q.all([]);
		}

		return service.one("results").one("send").customPUT(null, null, {
			calcIds: calcIds.join(",")
		});
	}
}
