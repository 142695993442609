angular
	.module("ezd.backend")
	.factory("$$attendanceReason", attendanceReasons);

/**
 *
 * @param $q
 * @returns {{}}
 */
attendanceReasons.$inject = ["$q"];
function attendanceReasons($q) {
	const Service = {};

	// binding
	Service.getList = getList;

	return Service;

	// return LMSv2.service('/api/absence_reasons')


	function getList() {
		const deferred = $q.defer();

		deferred.resolve([
			{
				id: 1,
				name: "По болезни (со справкой)"
			},
			{
				id: 2,
				name: "Плохое самочувствие"
			},
			{
				id: 3,
				name: "По семейным обстоятельствам"
			},
			{
				id: 4,
				name: "Укажите другую причину"
			}
		]);

		return deferred.promise;
	}
}
