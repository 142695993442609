

angular
	.module("ezd.backend")
	.service("$$reportUserActivity", reportUserActivityService);

/**
 *
 * @param REP
 */
reportUserActivityService.$inject = ["REP"];
function reportUserActivityService(REP) {
	const url = "api/user_activity";
	const vm = this;

	vm.getJson = getJson;
	vm.getLastLogins = getLastLogins;
	vm.getLastLoginStudent = getLastLoginStudent;
	vm.getLastLoginParent = getLastLoginParent;

	function getJson(params) {
		return REP.one(url, "json").get(params);
	}

	/**
	 * Получить 10 последних входов пользователя
	 * @param id
	 * @returns {*|{then}}
	 */
	function getLastLogins(id) {
		return REP.one(url, "last_sign_in").get({user_id: id});
	}

	/**
	 * Получить 10 последних входов ученика
	 * @param studentUserId
	 * @returns {*}
	 */
	function getLastLoginStudent(studentUserId) {
		return REP.one(url, "ten_last_login_student").get({student_user_id: studentUserId});
	}

	/**
	 * Получить 10 последних входов родителя в дневник ученика
	 * @param parentUserId
	 * @param studentUserId
	 * @returns {*}
	 */
	function getLastLoginParent(parentUserId, studentUserId) {
		return REP.one(url, "ten_last_login_parent").get({parent_user_id: parentUserId, student_user_id: studentUserId});
	}
}
