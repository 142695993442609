/**
 * Subscription JSON:
 * {
 *	"id" – integer, идентификатор подписки
 *	"parentProfileId" – integer, идентификатор профиля родителя
 *	"parentSsoId" – string, SSO родителя (опционально)
 *	"studentProfileId" – integer, идентификатор профиля учащегося
 *	"studentSsoId" – string, SSO учащегося (опционально)
 *	"type" – string, тип подписки ("MARKS" или "EXAMS")
 * }
 */
class ParentSubscription {
	static $inject = ["Sender"];
	static URL = "/api/subscription";

	constructor(api) {
		this.api = api;
	}


	/**
	 * BindOne
	 */
	bindOne(id) {
		return this.api.one(ParentSubscription.URL, id);
	}


	/**
	 * Возвращаем restangular-коллекцию для профиля родителя
	 * @param parentId
	 */
	getCollectionForParent(parentId) {
		return this.api.all(`${ParentSubscription.URL}/parent/${parentId}`);
	}
}

angular.module("ezd.backend")
	.service("ParentSubscription", ParentSubscription);
