

angular
	.module("ezd.backend")
	.service("$$supportMessage", $$supportMessage);


/**
 *
 * @param Core
 */
$$supportMessage.$inject = ["Core"];
function $$supportMessage(Core) {
	const url = "/api/epos_otrs_supports";
	const vm = this;

	vm.collection = Core.all(url);
}
