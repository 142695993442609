import template from "./progress-bar.html";

angular
	.module("ezd.backend")
	.service("bigQuery", Service);


/**
 *
 * @param $q
 * @param $rootScope
 * @param Restangular
 * @returns {*}
 * @constructor
 */
Service.$inject = ["$q", "$rootScope", "Restangular"];
function Service($q, $rootScope, Restangular) {
	const defaultParts = 50;


	return {

		/**
		 * Get collection
		 * @param  {Object} provider   Service to use.
		 * @param  {Object} data       Static params.
		 * @param  {String} chunkField Field name for chunked param.
		 * @param  {Number} parts      Chunked param maximum count per chunk.
		 * @param api
		 * @return {Array}             Fetched collection (restangularized if set provider.url)
		 */
		getList(provider, data, chunkField, parts, api) {
			$rootScope.global_promise.template = template;

			if (_.isEmpty(data[chunkField])) {
				return [];
			}

			let $parts = parts;
			if (!parts) {
				$parts = defaultParts;
			}

			const chunks = _.chunk(data[chunkField].split(","), $parts);
			const totalChunks = chunks.length;

			delete data[chunkField];

			return worker([]);


			/**
			 * Fetch chuck
			 * @param  {Array}  result      Resulting collection after each iteration.
			 * @return {Array}              Fetched collection (restangularized if set provider.url)
			 */
			function worker(result) {
				let $result = result;
				const chunk = chunks.shift();
				const chunkedData = _.cloneDeep(data);

				chunkedData[chunkField] = chunk.join(",");

				return provider.getList(chunkedData)
					.then((res) => {
						$result = _.union($result, res.plain());
						setProgress(chunks.length, totalChunks);

						if (chunks.length > 0) {
							return worker($result);
						}

						delete $rootScope.global_promise.template;
						$rootScope.global_promise.progress = 0;

						return restangularize(provider, $result, api);
					});
			}
		},


		/**
		 * Post big data
		 * @param  {Object} provider   Service to use.
		 * @param  {Object} data       Static params.
		 * @param  {String} chunkField Field name for chunked param.
		 * @param  {Number} parts      Chunked param maximum count per chunk.
		 * @return {Array}             Fetched collection (restangularized if set provider.url)
		 */
		post(provider, data, chunkField, parts) {
			$rootScope.global_promise.template = template;

			let $parts = parts;
			if (!parts) {
				$parts = defaultParts;
			}

			const chunks = _.chunk(data[chunkField], $parts);
			const totalChunks = chunks.length;

			delete data[chunkField];

			return worker([]);


			/**
			 * Post chuck
			 * @param  {Array}  result         Resulting collection after each iteration.
			 * @return {Array}                 Fetched collection (restangularized if set provider.url)
			 */
			function worker(result) {
				let $result = result || [];
				const chunk = chunks.shift();
				const chunkedData = _.cloneDeep(data);

				chunkedData[chunkField] = chunk;

				return provider.post(chunkedData)
					.then((res) => {
						$result = _.union($result, res.plain());
						setProgress(chunks.length, totalChunks);

						if (chunks.length > 0) {
							return worker($result);
						}

						delete $rootScope.global_promise.template;
						$rootScope.global_promise.progress = 0;

						return restangularize(provider, $result);
					});
			}
		},


		queue: {

			/**
			 * Put big data
			 * @param  {Array}  data     Collection of restangularized models or [{id: Number|String, data: Object}].
			 * @param  {Number} parts    Chunked param maximum count per chunk.
			 * @param  {Object} provider Service to use.
			 * @param  {Object} params Service to use.
			 */
			custom_put(data, parts, provider, params) {
				console.log(data);
				console.log(parts);
				console.log(provider);
				console.log(params);
				$rootScope.global_promise.template = template;

				let $parts = parts;
				if (!$parts) {
					$parts = defaultParts;
				}

				const chunks = _.chunk(data, $parts);
				const totalChunks = chunks.length;
				let error = false;

				return worker();

				function worker() {
					const chunk = chunks.shift();
					const compiledChunk = [];

					_.forEach(chunk, (c) => {
						compiledChunk.push(
							c.restangularized
								? c.customPUT(c.data, null, params)
									.then((res) => {
										if (!res) {
											error = true;
										}
									})
								: provider.bindOne(c.id).customPUT(c.data, null, params)
									.then((res) => {
										if (!res) {
											error = true;
										}
									})
						);
						// compiledChunk.push(provider.bindOne(c.id).put(c.data))
					});

					return $q.all(compiledChunk)
						.then(() => {
							setProgress(chunks.length, totalChunks);
							if (chunks.length > 0) {
								return worker();
							}

							delete $rootScope.global_promise.template;
							$rootScope.global_promise.progress = 0;

							return error;
						});
				}
			},


			/**
			 * Put big data
			 * @param  {Array}  data     Collection of restangularized models or [{id: Number|String, data: Object}].
			 * @param  {Number} parts    Chunked param maximum count per chunk.
			 * @param  {Object} provider Service to use.
			 */
			put(data, parts, provider) {
				$rootScope.global_promise.template = template;

				let $parts = parts;
				if (!$parts) {
					$parts = defaultParts;
				}

				const chunks = _.chunk(data, $parts);
				const totalChunks = chunks.length;
				let error = false;

				return worker();

				function worker() {
					const chunk = chunks.shift();
					const compiledChunk = [];

					_.forEach(chunk, (c) => {
						compiledChunk.push(
							c.restangularized
								? c.put()
									.then((res) => {
										if (!res) {
											error = true;
										}
									})
								: provider.bindOne(c.id).put(c.data)
									.then((res) => {
										if (!res) {
											error = true;
										}
									})
						);
						// compiledChunk.push(provider.bindOne(c.id).put(c.data))
					});

					return $q.all(compiledChunk)
						.then(() => {
							setProgress(chunks.length, totalChunks);
							if (chunks.length > 0) {
								return worker();
							}

							delete $rootScope.global_promise.template;
							$rootScope.global_promise.progress = 0;

							return error;
						});
				}
			},

			/**
			 * Get big data
			 * @param  {Object} provider   Service to use.
			 * @param  {Object} data       Static params.
			 * @param  {Number} parts      Maximum items per page.
			 * @return {Object}            Promise with fetched collection (restangularized if set provider.url)
			 */
			get(provider, data, parts) {
				let page = 1;
				const perPage = parts || 50;
				let result = [];

				// $rootScope.global_promise.templateUrl = 'app/components/backend/components/services/big_query/progress-bar.html'

				return worker();

				function worker() {
					const chunk = _.cloneDeep(data);

					chunk.page = page;
					chunk.per_page = perPage;

					return provider
						.get(chunk)
						.then((res) => {
							result = _.union(result, res.plain());
							// setProgress(chunks.length, totalChunks)

							page += 1;

							if (_.isEmpty(res)) {
								// delete $rootScope.global_promise.templateUrl
								// $rootScope.global_promise.progress = 0

								return result;
							}

							return worker();
						});
				}
			},

			/**
			 * Get big data list
			 * @param  {Object} provider   Service to use.
			 * @param  {Object} data       Static params.
			 * @param  {Number} parts      Maximum items per page.
			 * @return {Object}            Promise with fetched collection (restangularized if set provider.url)
			 */
			getList(provider, data, parts) {
				let page = 1;
				const perPage = parts || 50;
				let result = [];

				// $rootScope.global_promise.templateUrl = 'app/components/backend/components/services/big_query/progress-bar.html'

				return worker();

				function worker() {
					const chunk = _.cloneDeep(data);

					chunk.page = page;
					chunk.per_page = perPage;

					return provider.getList(chunk)

						.then((res) => {
							// console.log(res);
							result = _.union(result, res.plain());
							// setProgress(chunks.length, totalChunks)

							let totalPages;
							// проверка для /cms/api
							if (res.getTotalPages) {
								totalPages = res.getTotalPages(res);
							} else {
								totalPages = _.int(res.headers("Pages") || res.headers("X-Pagination-Total-Pages")) || _.int(res.headers("total_pages"));
							}
							if (totalPages && !_.isNaN(totalPages) && page === totalPages) {
								return _.map(result, (item) => Restangular.restangularizeElement("", item, res.getRestangularUrl()));
							}

							page += 1;

							if (_.isEmpty(res)) {
								// delete $rootScope.global_promise.templateUrl
								// $rootScope.global_promise.progress = 0

								return _.map(result, (item) => Restangular.restangularizeElement("", item, res.getRestangularUrl()));
							}

							return worker();
						});
				}
			}

		}
	};


	/**
	 * [getProgress description]
	 * @param  {[type]} lost  [description]
	 * @param  {[type]} total [description]
	 * @return {[type]}       [description]
	 */
	function setProgress(lost, total) {
		$rootScope.global_promise.progress = Math.floor((total - lost) * 100 / total);
	}


	/**
	 * [restangularize description]
	 * @param  {[type]} provider   [description]
	 * @param  {[type]} collection [description]
	 * @param api
	 * @return {[type]}            [description]
	 */
	function restangularize(provider, collection, api) {
		return provider.url || provider.route
			? Restangular.restangularizeCollection(null, collection, (api ? "/" + api : "") + provider.route || provider.route)
			: collection;
	}
}

