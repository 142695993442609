

angular
	.module("ezd.backend")
	.factory("JournalSignatures", Factory);

const CADESCOM_CADES_BES = 1;
const CAPICOM_CURRENT_USER_STORE = 2;
const CAPICOM_MY_STORE = "My";
const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
const CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;


/**
 *
 * @param Core
 * @param flashAlert
 * @returns {Object|*}
 * @constructor
 */
Factory.$inject = ["Core", "flashAlert"];
function Factory(Core, flashAlert) {
	const vm = Core.service("/api/journal_signatures");

	// defaults
	vm.certificate_name = null;

	// bindings
	vm.sign = sign;

	return vm;

	function sign(dataToSign) {
		if (_.isNull(vm.certificate_name)) { vm.certificate_name = prompt("Введите название сертификата", "Test Certificate"); }

		return new Promise((resolve, reject) => {
			window.cadesplugin.async_spawn(function* (args) {
				try {
					const oStore = yield window.cadesplugin.CreateObjectAsync("CAPICOM.Store");

					yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE, CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

					const CertificatesObj = yield oStore.Certificates;
					const oCertificates = yield CertificatesObj.Find(CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME, vm.certificate_name);
					const Count = yield oCertificates.Count;

					if (Count === 0) { throw ("Сертификат не найден: " + args[0]); }

					const oCertificate = yield oCertificates.Item(1);
					const oSigner = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");

					yield oSigner.propset_Certificate(oCertificate);

					const oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");

					yield oSignedData.propset_Content(dataToSign);

					const sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES);

					yield oStore.Close();

					args[2](sSignedMessage);

					resolve(sSignedMessage);
				} catch (ex) {
					const msg = "Ошибка при создании подписи. ";

					args[3](msg);
					flashAlert.error(msg + $GetErrorMessage(ex));
					console.error(msg + $GetErrorMessage(ex));
				}
			}, vm.certificate_name, dataToSign, resolve, reject);
		});
	}
}

function $Decimal2Hex(number) {
	let localNumber = number;

	if (number < 0) {
		localNumber = 0xFFFFFFFF + number + 1;
	}

	return localNumber.toString(16).toUpperCase();
}

function $GetErrorMessage(e) {
	let err = e;

	if (e.message) {
		err = e.message;
	}

	if (e.number) {
		err += " (0x" + $Decimal2Hex(e.number) + ")";
	}

	return err;
}

