class URSSchoolDictionaries {
	static $inject = ["Argus"];
	static URL = "/api/school_dictionaries";

	constructor(api) {
		this.api = api;
		this.collection = this.api.all(URSSchoolDictionaries.URL);
	}
}

angular.module("ezd.backend")
	.service("URSSchoolDictionaries", URSSchoolDictionaries);
