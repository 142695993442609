import template from "./NewsList.html";


angular
	.module("ezd.common.ui")
	.directive("newslist", NewsListDirective);

/**
 * @param $$news
 * @param $$profile
 * @returns {{template: string, scope: {}, link: Function}}
 * @constructor
 */
NewsListDirective.$inject = ["$$news", "$$profile"];
function NewsListDirective($$news, $$profile) {
	return {
		template,
		scope: {},
		link(scope) {
			$$profile.getCurrentProfile()
				.then((data) => {
					scope.profileRoles = data.roles;

					// add fake roles
					if (_.intersects(["deputy", "school_admin"], scope.profileRoles)) {
						scope.profileRoles.push("is_school", "all_staff");
					}
				})

				.then(() => {
					return $$news.getList({page: 1, per_page: 15});
				})

				.then((data) => {
					_.forEach(data, (newsItem) => {
						newsItem.date = moment(newsItem.created_at).format("DD.MM");
					});

					const clearNews = [];
					const defaultRoles = ["in_school", "all_staff", "teacher", "deputy"];

					// для родителей и учеников возвращаем новости как есть
					if (_.intersects(["parent", "student"], scope.profileRoles)) {
						scope.news = data;
					} else {
						_.forEach(data, (newsItem) => {
							// школьные новости админ и завуч могут видеть
							if (newsItem.school_id) {
								clearNews.push(newsItem);
							} else { // глобальные новости могут видеть только свои (не учеников и родителей)
								if (_.intersects(["in_school", "all_staff", "teacher", "deputy"], newsItem.audience)) {
									clearNews.push(newsItem);
								}
							}
						});
						scope.news = clearNews.slice(0, 5);
					}
				});
		}
	};
}

