

angular
	.module("ezd.common.ui")
	.directive("passGen", Directive);

/**
 *
 * @returns {{replace: boolean, scope: {model: string, length: string}, template: string, controller: Controller, controllerAs: string}}
 * @constructor
 */
function Directive() {
	const directive = {
		replace: true,
		scope: {
			model: "=",
			length: "@"
		},
		template: "<a href=\"\" class=\"action-link\" ng-click=\"model = c.genPass(length)\">Сгенерировать</a>",
		controller: Controller,
		controllerAs: "c"
	};

	return directive;
}

/**
 *
 * @param $$passChange
 * @constructor
 */
Controller.$inject = ["$$passChange"];
function Controller($$passChange) {
	const c = this;

	c.genPass = $$passChange.generatePassword;
}
