import template from "./rightSidebar.html";


angular
	.module("ezd.common.ui")
	.component("rightSidebar", rightSidebarComponent());

function rightSidebarComponent() {
	return {
		transclude: true,
		template,
		controller: Controller
	};
}


/**
 *
 * @type {[*]}
 */
Controller.$inject = ["$rootScope"];
function Controller($rootScope) {
	const vm = this;

	vm.isOpen = $rootScope.closedMenu;

	vm.icons = {
		apps_b: require("images/icons/ic_apps_black_24px.svg"),
		apps_w: require("images/icons/ic_apps_white_24px.svg")
	};

	vm.styles = require("./styles.local.css");

	vm.toggle = toggle;


	function toggle() {
		vm.isOpen = !vm.isOpen;
	}
}