

angular
	.module("ezd.backend")
	.factory("$$cacheInvalidator", cacheInvalidators);


/**
 *
 * @param Jersey
 * @returns {*|Object}
 */
cacheInvalidators.$inject = ["Jersey"];
function cacheInvalidators(Jersey) {
	const Service = Jersey.service("/api/cache/invalidate");

	// bindings
	Service.invalidate = Invalidate;

	return Service;

	// implementation
	function Invalidate() {
		return Jersey.all("/api/cache/invalidate").customPUT();
	}
}