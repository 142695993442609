angular
	.module("ezd.backend")
	.factory("GlobalSettings", GlobalSettings);

GlobalSettings.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
function GlobalSettings(Core) {
	const service = Core.service("/api/global_settings");
	const vm = this;


	vm.getList = getList;

	/**
	 *
	 */
	async function getList() {
		if (_.has(vm, "list")) {
			return vm.list;
		}

		vm.list = await service.getList();

		return vm.list;
	}


	return vm;
}

