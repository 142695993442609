angular
	.module("ezd.backend")
	.service("$$aoopLoad", $$aoopLoad);


/**
 *
 * @param Core
 */
$$aoopLoad.$inject = ["Core"];
function $$aoopLoad(Core) {
	const url = "/api/aoop_load";
	const vm = this;


	vm.list = [];
	vm.collection = Core.all(url);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);
}
