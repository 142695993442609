

angular
	.module("ezd.backend")
	.service("NewsSubscription", NewsSubscription);


/**
 *
 * @param Core
 * @constructor
 */
NewsSubscription.$inject = ["Core"];
function NewsSubscription(Core) {
	const url = "/api/news/category/subscribe/";
	const vm = this;

	vm.collection = Core.all(url);
	vm.bindOne = (id) => Core.one(url, id);
}

