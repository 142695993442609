import styles from "./ezd-prompt.local.scss";
import {close} from "images/icons/index";
import plus from "./plus.svg";

export class EzdPromptController {
	static $inject = ["$mdDialog", "params"];

	/**
	 * @param $mdDialog
	 * @param params
	 */
	constructor($mdDialog, params) {
		this.services = {$mdDialog, params};
		this.styles = styles;
		this.icons = {close, plus};
		this.params = params;
		this.str = "";
		this.error = false;
	}

	/**
	 *
	 */
	confirm() {
		const {$mdDialog} = this.services;

		if (this.params.onEmptyString && this.str === "") {
			this.error = true;

			return;
		}

		this.error = false;

		$mdDialog.hide(this.str);
	}

	/**
	 *
	 */
	hide() {
		const {$mdDialog} = this.services;

		$mdDialog.cancel();
	}
}
