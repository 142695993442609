

angular
	.module("ezd.common.ui")
	.directive("menuButton", Directive);

function Directive() {
	return {
		scope: {
			icon: "@",
			text: "@",
			link: "@",
			action: "&",
			name: "@",
			type: "@",
			iconcount: "@"
		},
		template: require("./MenuButton.html")
	};
}