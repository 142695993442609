

angular
	.module("ezd.backend")
	.factory("$$systemConfig", systemConfig);


/**
 *
 * @param ACL
 * @returns {Object|*}
 */
systemConfig.$inject = ["ACL"];
function systemConfig(ACL) {
	return ACL.service("api/security-polices");
}