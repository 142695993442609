export class ConnectedParallelCurriculaService {
	static name = "ConnectedParallelCurriculaService";
	static $inject = ["Core", "REP"];

	url = "/api/connected_parallel_curriculas";
	reportUrl = "/api/study_plan_full";

	/**
	 *
	 * @param Core
	 */
	constructor(Core, REP) {
		this.Core = Core;
		this.REP = REP;
	}


	/**
	 *
	 */
	getList(params) {
		// return [
		// 	{
		// 		id: 123,
		// 		name: "Name",
		// 		is_adapted: false,
		// 		education_level_id: 1,
		// 		specialization: "Направленность",
		// 		study_profile_id: 1,
		// 		parallel_curriculas: []
		// 	}
		// ];

		return this.Core.all(this.url).getList(params);
	}

	/**
	 *
	 * @param params
	 * @returns {Promise<*>}
	 */
	getReportInfo(params) {
		return this.REP.one(`${this.reportUrl}/xlsx/info`).get(params);
	}

	/**
	 *
	 * @param url
	 * @returns {Promise<*>}
	 */
	generateUnloadind(url) {
		return this.REP.one(url).get();
	}

	/**
	 *
	 * @param plan
	 */
	savePlan(plan, params) {
		return plan.id ? this.Core.one(this.url, plan.id).customPUT(plan) : this.Core.all(this.url).post(plan, params.clean_cache);
	}


	/**
	 *
	 * @returns {{name: string, education_level_id, specialization: null, study_profile_id: null, parallel_curriculas: Array}}
	 */
	getDummyPlan() {
		return {
			name: "",
			adapted: false,
			education_level_id: null,
			specialization: "",
			study_profile_id: null,
			parallel_curricula_list: []
		};
	}

	/**
	 *
	 * @param plan
	 * @returns {*}
	 */
	removePlan(plan) {
		return this.Core.one(this.url, plan.id).customDELETE();
	}
}

angular
	.module("ezd.backend")
	.service(ConnectedParallelCurriculaService.name, ConnectedParallelCurriculaService);
