

angular
	.module("ezd.backend")
	.factory("Credentials", credentialsFactory);

/**
 *
 * @param ACL
 * @returns {*|Object}
 */
credentialsFactory.$inject = ["ACL"];
function credentialsFactory(ACL) {
	return ACL.service("/api/users");
}