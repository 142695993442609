angular
	.module("ezd.backend")
	.factory("AEProgram", aeProgram);


aeProgram.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {Object|*}
 */
function aeProgram(Core) {
	return Core.service("/api/ae_programs");
}
