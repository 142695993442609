

angular
	.module("ezd.backend")
	.service("$$room", $$room);


/**
 *
 * @param Core
 */
$$room.$inject = ["Core"];
function $$room(Core) {
	const url = "/api/rooms";
	const self = this;

	self.list = [];
	self.collection = Core.all(url);
	self.collection.getList = (query) => Core.all(url).getList(query);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	self.bindOne = (id) => Core.one(url, id);


	/**
	 *
	 * @param room
	 * @returns {string}
	 */
	self.getFullName = (room) => {
		if (!room) {
			console.info("Не передан объект room в метод getFullName");

			return "";
		}

		let roomName = "";

		if (room.number) {
			roomName = room.number + ", " + room.name;
		} else {
			roomName = room.name;
		}

		return roomName;
	};
}