import "angular-mocks";

export default angular
	.module("ezd.mocks.mocks_closer", ["ngMockE2E"])
	.run(Runner)
	.name;

Runner.$inject = ["$httpBackend"];
function Runner($httpBackend) {
	$httpBackend.whenGET().passThrough();
	$httpBackend.whenPOST().passThrough();
	$httpBackend.whenPUT().passThrough();
	$httpBackend.whenDELETE().passThrough();
	$httpBackend.whenHEAD().passThrough();
}
