angular
	.module("ezd.backend")
	.factory("$$attendanceNotification", attendanceNotifications);

attendanceNotifications.$inject = ["$q", "Core", "bigQuery"];

/**
 *
 * @param $q
 * @param Core
 * @param bigQuery
 * @returns {Object|*}
 */
function attendanceNotifications($q, Core, bigQuery) {
	const Service = Core.service("/api/attendance_notifications");

	// binding
	Service.getListAndAssignNotificationDateAndCreatedAt = getListAndAssignNotificationDateAndCreatedAt;
	Service.getTemplate = getTemplate;
	Service.createNotification = createNotification;
	Service.removeNotification = removeNotification;

	return Service;

	// implementation

	/**
	 *
	 * @param query
	 */
	function getListAndAssignNotificationDateAndCreatedAt(query, parts) {
		return bigQuery.queue.getList(Service, query, parts)
			.then((data) => {
				return _.map(data, (attendanceNotification) => {
					return _.assign(attendanceNotification, {
						datestring: attendanceNotification.date,
						date: moment(attendanceNotification.date, "YYYY-MM-DD"),
						created_at: moment(attendanceNotification.created_at, "YYYY-MM-DD HH:mm:ss")
					});
				});
			});
	}


	/**
	 *
	 * @param data
	 * @returns {*}
	 */
	function createNotification(data, isNeedDeleteCommonNotices) {
		return Service.post(data, {is_need_delete_notices: isNeedDeleteCommonNotices})
			.then((response) => {
				return _.assign(response, {
					datestring: response.date,
					date: moment(response.date, "YYYY-MM-DD"),
					created_at: moment(response.created_at, "YYYY-MM-DD HH:mm:ss")
				});
			});
	}


	/**
	 *
	 * @param notification
	 */
	function removeNotification(notification) {
		return Service.one(notification.id).customDELETE()
			.then((response) => {
				return response;
			});
	}


	/**
	 *
	 * @returns {{date: string, bell_id: null, teacher_profile_id: null, parent_profile_id: null, nonattendance_reason_id: null, description: string,
	 *     student_profile_id: null}}
	 */
	function getTemplate() {
		return {
			date: "", // YYYY-MM-DD
			bell_id: null, // number
			teacher_profile_id: null, // number. teacher profile id
			parent_profile_id: null, // number. parent profile id
			nonattendance_reason_id: null, // number
			description: "",
			student_profile_id: null // number. student profile id
		};
	}
}

