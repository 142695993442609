/**
 * Декоратор для фабрики
 * @param name
 * @returns {Function}
 * @constructor
 */
export function Factory(name) {
	return function (target) {
		const res = {name, controller: target};

		target.$type = "factory";

		return res;
	};
}
