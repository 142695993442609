

angular
	.module("ezd.common.ui")
	.directive("stickOnScroll", Directive);

/**
 *
 * @param $document
 * @param $rootScope
 * @returns {{restrict: string, link: (function(*, *=, *))}}
 * @constructor
 */
Directive.$inject = ["$document", "$rootScope"];
function Directive($document, $rootScope) {
	return {
		restrict: "A",
		link(scope, el, attrs) {
			function listener(e) {
				const element = $document[0].getElementById(attrs.stickOnScrollReference);
				if (el && element) {
					const refTop = element.getBoundingClientRect().top;
					const height = el[0].clientHeight;
					const top = (refTop < height) ? el[0].getBoundingClientRect().top : 1;
					const params = [
						top,
						el[0].clientHeight,
						refTop,
						document.documentElement.clientHeight
					];
					// В контексте скопа выполняем выражение
					scope.$eval(attrs.stickOnScrollAction + "(" + params.join(",") + ")");
					scope.$applyAsync();
				}
			}

			const debouncedListener = _.debounce(listener, 50, {leading: true});

			if (el) {
				// Регистрируем обработчик скрола
				$document[0].addEventListener("scroll", debouncedListener);
				// На удалении элемента el
				el.on("$destroy", () => {
					// Удаляем обработчик
					$document[0].removeEventListener("scroll", debouncedListener, true);
				});

				$rootScope.$on("$routeChangeStart", (event, next, current) => {
					$document[0].removeEventListener("scroll", debouncedListener, true);
				});
			}
		}
	};
}

