class CalendarService {
	static $inject = ["Core"];
	static URL = "/api/calendars";
	static TRANSPOSITIONS_PATH = "calendar_transpositions";

	api = null;
	collection = null;

	constructor(api) {
		this.api = api;
		this.collection = this.api.all(CalendarService.URL);
	}


	/**
	 * bindOne
	 * @param id
	 * @return {*}
	 */
	bindOne(id) {
		return this.api.one(CalendarService.URL, id);
	}


	/**
	 * Возвращаем Restangular-collection для calendar_transpositions
	 * @param calendarId
	 * @return {*}
	 */
	getTranspositionCollection(calendarId) {
		return this.bindOne(calendarId).all(CalendarService.TRANSPOSITIONS_PATH);
	}


	/**
	 * Возвращает рабочий календарь на год
	 * @param calendarId {Number} id календаря учебного года
	 */
	getYearCalendar(calendarId) {
		return this.getTranspositionCollection(calendarId).getList();
	}

	/**
	 *
	 */
	now(format = null) {
		return this.api.all(CalendarService.URL).one("now").get({
			format
		});
	}
}

angular.module("ezd.backend")
	.service("$$calendar", CalendarService);
