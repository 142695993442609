/**
 * Сервис для генерации вариантов теста для прохождения учеником
 */
class EOMTestGenerationService {
	static $inject = ["EXAM"];

	constructor(EXAM) {
		this.exam = EXAM;
		this.collection = EXAM.all("/rest/secure/testplayer/group");
	}
}

angular
	.module("ezd.backend")
	.service("EOMTestGenerationService", EOMTestGenerationService);
