

angular
	.module("ezd.backend")
	.factory("ECLessons", ECLessons);


/**
 *
 * @param EC
 * @returns {*|Object}
 */
ECLessons.$inject = ["EC"];
function ECLessons(EC) {
	return EC.service("/api/ec_lessons");
}

