import {Component} from "app/decorators/index";
import template from "./assignments-table-header-cell.template.html";
import {chevronLeft, chevronRight} from "images/icons/index";
import styles from "./assignments-table-header-cell.local.scss";

@Component({
	selector: "assignmentsTableHeaderCell",
	template,
	transclude: true,
	bindings: {
		onSelectAll: "&",
		onSelectGender: "&",
		onClearAssignments: "&",
		withFastSelection: "<?",
		genderMarker: "<?"
	}
})
export class AssignmentsTableHeaderCellComponent {
	static $inject = ["$scope", "$timeout"];
	styles = styles;
	icons = {chevronLeft, chevronRight};

	selectionTypes = {
		all: {
			title: "все",
			callback: this.selectAll.bind(this)
		},
		male: {
			title: "все мальчики",
			callback: this.selectGender.bind(this, "male")
		},
		female: {
			title: "все девочки",
			callback: this.selectGender.bind(this, "female")
		},
		clear: {
			title: "очистить столбцы",
			callback: this.clearAssignments.bind(this)
		}
	};

	/**
	 * */
	constructor($scope, $timeout) {
		this.services = {$scope, $timeout};
		// сбросить выделение быстрого распределения
		$scope.$on("clear-assignments-changes", () => {
			this.isSelected = false;
			this.currentSelectionType = null;
		});
	}

	/**
	 *
	 * */
	$onChanges(changes) {
		const {$timeout} = this.services;
		if (changes.genderMarker) {
			this.currentSelectionType = this.genderMarker ? this.selectionTypes[this.genderMarker].title : null;
			$timeout(() => {
				this.isSelected = Boolean(this.genderMarker);
			});
		}
	}


	/**
	 * */
	selectAll() {
		this.isSelected = true;
		this.currentSelectionType = this.selectionTypes.all.title;
		this.onSelectAll();
	}

	/**
	 * */
	selectGender(type) {
		this.isSelected = true;
		this.currentSelectionType = this.selectionTypes[type].title;
		this.onSelectGender({type});
	}

	/**
	 * */
	clearAssignments() {
		this.isSelected = false;
		this.currentSelectionType = null;
		this.onClearAssignments();
	}
}
