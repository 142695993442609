angular
	.module("ezd.backend")
	.factory("AECalendarPlans", aeCalendarPlans);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeCalendarPlans.$inject = ["Core"];
function aeCalendarPlans(Core) {
	const Service = Core.service("/api/ae_course_calendar_plans");

	Service.recalc = recalc;
	Service.finish = finish;
	Service.check = (id) => Service.one(id).get();

	/**
	 *
	 * @param id
	 */
	function recalc(id) {
		return Service.one(id).all("recalc").customPOST();
	}

	/**
	 *
	 * @param id
	 */
	function finish(id) {
		return Service.one(id).all("finish").customPOST();
	}

	return Service;
}
