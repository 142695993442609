

angular
	.module("ezd.backend")
	.service("$$reportTeacher", Service);


/**
 *
 * @param REP
 * @constructor
 */
Service.$inject = ["REP"];
function Service(REP) {
	const url = "api/teacher";
	const vm = this;

	vm.getMarkTypes = getMarkTypes;
	vm.getPeriods = getPeriods;
	vm.getJson = getJson;

	/**
	 * Получить типы оценок, доступные учителю
	 * (Годовые, экзаменнационные и т.д.)
	 * @returns {*}
	 */
	function getMarkTypes(params) {
		return REP.one(url, "mark_types").get(params);
	}

	/**
	 * Получить периоды (обычные, аттестационные, модули)
	 * @param params
	 * @returns {*}
	 */
	function getPeriods(params) {
		return REP.one(url, "periods").get(params);
	}

	/**
	 * Загрузка данных для отчета в json
	 * @param params {{}}
	 *
	 * @returns {*}
	 */
	function getJson(params) {
		return REP.one(url, "json").get(params);
	}
}

