import {Component} from "app/decorators/index";
import template from "./ezd-paper.html";
import styles from "./ezd-paper.local.scss";

@Component({
	selector: "ezdPaper",
	transclude: true,
	template
})
class EzdPaper {
	/**
	 * Constructor инициализируем основные свойства класса
	 */
	constructor() {
		this.styles = styles;
	}
}

angular.module("ezd.common.ui").component(EzdPaper.selector, EzdPaper);
