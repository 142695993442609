const baseUrl = "/api/chats";

class Chats {
	static $inject = ["Core"];

	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(baseUrl);
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	bindOne(id) {
		return this.CoreService.one(baseUrl, id);
	}

	/**
	 * Создать чат
	 * @returns {*}
	 */
	createChat(params) {
		return this.collection.post(params);
	}

	/**
	 * Удалить чат
	 * @param id
	 * @returns {*}
	 */
	removeChat(id) {
		return this.bindOne(id).customDELETE();
	}

	/**
	 * Обновить чат
	 * @param chat
	 * @returns {*}
	 */
	updateChat(chat) {
		return this.bindOne(chat.id).customPUT(chat);
	}

	/**
	 * Получить информацию об участниках чата
	 * @param id
	 * @returns {*}
	 */
	getChatMembers(id) {
		return this.CoreService.one(`${baseUrl}/${id}/chat_members`).get();
	}
}

angular
	.module("ezd.backend")
	.service("Chats", Chats);
