angular
	.module("ezd.backend")
	.factory("$$homeBasedPeriod", Factory);


/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
Factory.$inject = ["Core"];
function Factory(Core) {
	const Service = Core.service("/api/home_base_periods");

	// binding
	Service.dayInPeriods = dayInPeriods;
	Service.getTemplate = getTemplate;
	Service.checkPeriod = checkPeriod;
	Service.getPeriod = (id) => Service.one(id).get();

	return Service;

	// implementation

	/**
	 * Проверка что дата входит хотя бы в один период
	 * @param homeBasedPeriods [{}]
	 * @param date {string}
	 * @param format {string}
	 * @returns {boolean}
	 */
	function dayInPeriods(homeBasedPeriods, date, format) {
		let result = false;
		const dayObject = (moment.isMoment(date)) ? date : moment(date, (format || ["DD.MM.YYYY", "YYYY-MM-DD"]));

		_.forEach(homeBasedPeriods, (period) => {
			const periodStart = moment(period.begin_date, "YYYY-MM-DD").startOf("day");
			const periodEnd = moment(period.end_date, "YYYY-MM-DD").endOf("day");

			if (dayObject.isBetween(periodStart, periodEnd)
				|| dayObject.isSame(periodStart)
				|| dayObject.isSame(periodEnd)) {
				result = true;

				return false;
			}
		});

		return result;
	}


	function getTemplate() {
		return {
			begin_date: "", // "YYYY-MM-DD"
			end_date: "", // "YYYY-MM-DD"
			student_profile_id: null, // id профиля ученика
			parallel_curriculum_id: null // id УП
		};
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function checkPeriod(id) {
		return Service.one(id).all("check").customDELETE();
	}
}

