angular
	.module("ezd.backend")
	.factory("County", County);

County.$inject = ["Core"];

/**
 * Получение списка округов
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
function County(Core) {
	return Core.service("/api/schools/county");
}

