

angular
	.module("ezd.common.ui")
	.directive("studentEditorPageSwitcher", Directive);


/**
 *
 * @returns {{scope: {student: string, viewType: string}, template: *, controller: Controller, controllerAs: string}}
 * @constructor
 */
function Directive() {
	return {
		scope: {
			student: "=",
			viewType: "@" // текущий вид: common, curricula, groups, portfolio, homebased
		},
		template: require("./studentEditorPageSwitcher.html"),
		controller: Controller,
		controllerAs: "psCtrl"
	};
}


/**
 *
 * @param $location
 * @param $scope
 * @constructor
 */
Controller.$inject = ["$location", "$scope"];
function Controller($location, $scope) {
	const vm = this;

	vm.getGlobalString = getPageString.bind(undefined, "$global");
	vm.getPageString = getPageString.bind(undefined, "59e5dc21042b408e9024afe00502a968");

	vm.changeView = changeView;
	vm.checkView = checkView;

	init();


	function init() {
		$scope.viewType = $scope.viewType || "common";
	}


	function checkView(viewType) {
		return viewType === $scope.viewType;
	}


	function changeView(viewType) {
		if (checkView(viewType)) {
			return;
		}
		switch (viewType) {
			case "common":
				$location.path("/refs/students/edit/" + $scope.student.id); // +
				break;

			case "curricula":
				$location.path("/study_plan/student_to_curricula/" + $scope.student.id);
				break;

			case "groups":
				$location.path("/study_plan/student_to_groups/" + $scope.student.id);
				break;

			case "portfolio":
				$location.path("/portfolio/manage/" + $scope.student.id); // +
				break;

			case "homebased":
				$location.path("/study_plan/homebased/" + $scope.student.id);
				break;

			case "ae_plan":
				$location.path("/study_plan/ae_plan/" + $scope.student.id);
				break;

			case "e_cards":
				$location.path("/study_plan/e_cards/" + $scope.student.id);
				break;

			case "additional_info":
				$location.path("/refs/additional_info/" + $scope.student.id);
				break;
		}
	}
}
