

angular
	.module("ezd.backend")
	.service("Impersonation", impersonations);


/**
 *
 * @param ACL
 * @param $$profile
 * @param $$user
 * @param $rootScope
 * @param flashAlert
 * @param $cookies
 */
function impersonations(ACL, $$profile, $$user, $rootScope, flashAlert, $cookies) {
	const vm = this;
	const impersonation = ACL.service("/api/impersonation");

	vm.to = impersonate;

	/**
	 * Метод для имперосонации пользователя в указанный profile
	 * @param profile
	 */
	async function impersonate(profile) {
		try {
			const currentUser = await $$user.getCurrentUser();
			const currentProfile = await $$profile.getCurrentProfile();

			if (!$cookies.get("admin_pid")) {
				$cookies.put("admin_pid", currentProfile.id);
			}

			const impersonatedProfile = _.cloneDeep(profile.plain());
			// mark new profile as impersonated
			impersonatedProfile.impersonated = true; // backend don't says which prof is impersonated
			// remove old impersonation
			$$profile.list = _.reject($$profile.list, (profileItem) => profileItem.user_id !== currentUser.id || profileItem.impersonated); // same reason as
			// above
			// try to impersonate profile
			$rootScope.global_promise = impersonation.post({target_profile_id: impersonatedProfile.id});

			await $rootScope.global_promise;

			// add new impersonated profile to profiles list
			impersonatedProfile.user_name = impersonatedProfile.name;
			if (!impersonatedProfile.user_name && impersonatedProfile.user) {
				const impersonatedUser = impersonatedProfile.user;
				impersonatedProfile.user_name = _.compact([impersonatedUser.last_name, impersonatedUser.first_name, impersonatedUser.middle_name]).join(" ");
			}
			impersonatedProfile.school_shortname = impersonatedProfile.schoolTitle;
			$$profile.list.push(impersonatedProfile);
			flashAlert.success("Профиль " + impersonatedProfile.id + " успешно имперсонирован");
		} catch (e) {
			console.error(e);
		}
	}
}

impersonations.$inject = ["ACL", "$$profile", "$$user", "$rootScope", "flashAlert", "$cookies"];
