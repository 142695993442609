import {Component} from "app/decorators/component";
import template from "./upload-file-area.template.html";
import isUndefined from "lodash/fp/isUndefined";

import styles from "./upload-file-area.local.scss";
import attach from "images/icons/wys-attach.svg";
import {FileUploadLinkComponent} from "components/common/components/file_upload_link/file-upload-link.component";

@Component({
	selector: "uploadFileArea",
	template,
	bindings: {
		message: "@",
		label: "@",
		...FileUploadLinkComponent.bindings
	}
})
export class UploadImageAreaComponent extends FileUploadLinkComponent.controller {
	static $inject = ["$rootScope", "$scope", "$cookies", "Upload"];
	icons = {attach};
	styles = styles;
	$message = "Загрузите файл";
	$label = "220x220 px";

	/**
	 * */
	constructor($rootScope, $scope, $cookies, Upload) {
		super($rootScope, $scope, $cookies, Upload);
		this.services = {$rootScope, $scope, $cookies, Upload};
		if (isUndefined(this.message)) {
			this.message = this.$message;
		}

		if (isUndefined(this.label)) {
			this.label = this.$label;
		}
	}
}
