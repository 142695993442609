angular
	.module("ezd.backend")
	.service("$$reportMarksHelpers", Service);


/**
 *
 * @param $q
 * @param $$attestationPeriod
 * @param $$attestationPeriodsSchedule
 * @param $$finalMark
 * @param $$lessonModules
 * @param $$lessonPlan
 * @param $$period
 * @param $$periodSchedule
 * @param bigQuery
 * @constructor
 */
Service.$inject = ["$q", "$$attestationPeriod", "$$attestationPeriodsSchedule", "$$finalMark", "$$lessonModules", "$$lessonPlan",
	"$$period", "$$periodSchedule", "bigQuery"];
function Service($q, $$attestationPeriod, $$attestationPeriodsSchedule, $$finalMark, $$lessonModules, $$lessonPlan,
                 $$period, $$periodSchedule, bigQuery) {
	const vm = this;

	vm.addCustomPeriods = addCustomPeriods;
	vm.getCustomPeriodsForFilters = getCustomPeriodsForFilters;
	vm.getAttestationPeriodsScheduleByPeriods = getAttestationPeriodsScheduleByPeriods;
	vm.getLessonPlans = getLessonPlans;
	vm.getPeriodsScheduleByPeriods = getPeriodsScheduleByPeriods;
	vm.periodsToSubjectByFinalMarks = periodsToSubjectByFinalMarks;
	vm.prepareModulesRange = prepareModulesRange;
	vm.preparePeriods = preparePeriods;

	/**
	 * Добавление учебных периодов в предмет. Берутся периоды, за которые есть итоговые оценки.
	 * @param subject предмет
	 * @param finalMarks итоговые оценки
	 * @param studyPeriods учебные периоды
	 * @param attestationPeriods аттестационные периоды
	 * @param modules модули поурочных планов
	 */
	function periodsToSubjectByFinalMarks(subject, finalMarks, studyPeriods, attestationPeriods, modules) {
		const subjectFinalMarks = _.filter(finalMarks, {subject_id: subject.id});

		subject.periods = [];

		_.forEach(subjectFinalMarks, (finalMark) => {
			let period;
			const attestationType = $$finalMark.getAttestationType(finalMark);

			// добавляем период в передмет
			switch (attestationType) {
				// учебный период
				case "study":
					period = angular.copy(_.find(studyPeriods, {id: finalMark.period_id}));
					if (period) {
						period.finalMark = finalMark;
						subject.periods.push(period);
					}
					break;
				// аттестационный период
				case "attestation":
					period = angular.copy(_.find(attestationPeriods, {id: finalMark.attestation_period_id}));
					if (period) {
						period.finalMark = finalMark;
						subject.periods.push(period);
					}
					break;
				// модуль поурочного плана
				case "module":
					period = angular.copy(_.find(modules, {id: finalMark.module_id}));
					if (period) {
						period.finalMark = finalMark;
						subject.periods.push(angular.copy(period));
					}
					break;
			}
		});
		// сортировка периодов по дате начала
		subject.periods = _.sortBy(subject.periods, (period) => period.periodStart.unix());
	}

	function getCustomPeriodsForFilters(finalMarks) {
		const periods = [
			{
				title: "За первое полугодие",
				value: "0-half_year_first"
			},
			{
				title: "За второе полугодие",
				value: "0-half_year_last"
			}
		];

		return (getSubjectsForHalfYearMarks(finalMarks).length > 0) ? periods : [];
	}

	/**
	 * Добавление периодов: полугодия
	 * @param subject
	 * @param finalMarks
	 * @param classUnit
	 * @param academicYear
	 */
	function addCustomPeriods(subject, finalMarks, classUnit, academicYear) {
		if ([10, 11, 12].indexOf(classUnit.class_level_id) === -1) {
			return;
		}

		const targetSubjectsIds = getSubjectsForHalfYearMarks(finalMarks);

		if (targetSubjectsIds.indexOf(subject.id) !== -1) {
			subject.periods = [
				{
					id: 0,
					name: "За первое полугодие",
					subject_id: subject.id,
					periodStart: moment(academicYear.begin_date, "YYYY-MM-DD"),
					periodEnd: moment(academicYear.begin_date, "YYYY-MM-DD").month(11).date(31).endOf("day"),
					dateRange: "",
					type: "half_year_first",
					finalMark: _.find(finalMarks, {
						subject_id: subject.id,
						mark_type: "half_year_first"
					})
				},
				{
					id: 0,
					name: "За второе полугодие",
					subject_id: subject.id,
					periodStart: moment(academicYear.end_date, "YYYY-MM-DD").month(0).date(1).startOf("day"),
					periodEnd: moment(academicYear.end_date, "YYYY-MM-DD").month(4).date(31).endOf("day"),
					dateRange: "",
					type: "half_year_last",
					finalMark: _.find(finalMarks, {
						subject_id: subject.id,
						mark_type: "half_year_last"
					})
				}
			];
		}
	}

	/**
	 * Возвращает id предметов, аттестующихся по полугодиям
	 * @param finalMarks {[]} массив итоговых оценок
	 * @returns {Array}
	 */
	function getSubjectsForHalfYearMarks(finalMarks) {
		const allSubjectsIds = _.uniq(_.map(finalMarks, "subject_id"));
		const result = [];

		// ищем предметы, по которым есть оценки за полугодия и нет оценок за другие периоды
		_.forEach(allSubjectsIds, (subjectId) => {
			const halfYearExists = Boolean(_.find(finalMarks, (finalMark) => {
				return ((finalMark.subject_id === subjectId)
				&& (["half_year_first", "half_year_last"].indexOf(finalMark.mark_type) !== -1));
			}));

			const periodsExists = Boolean(_.find(finalMarks, (finalMark) => {
				return ((finalMark.subject_id === subjectId)
				&& (finalMark.is_year_mark === false)
				&& (["half_year_first", "half_year_last"].indexOf(finalMark.mark_type) === -1));
			}));

			if (halfYearExists && !periodsExists) {
				result.push(subjectId);
			}
		});

		return result;
	}

	/**
	 * Выставление корректных дат начала и конца модулей поурочных планов
	 * Если модуль первый, то дата начала = 1 сентября
	 * Последующие модули имеют дату начала = дате конца предыдущего модуля + 1 день
	 * @param modules модули поурочного плана
	 */
	function prepareModulesRange(modules) {
		let lastModuleEndDate;

		_.forEach(modules, (module, index) => {
			if (index === 0 && module.type === "module") {
				module.periodStart = moment(module.periodStart).month(8).date(1);
			} else if (index > 0 && module.type === "module") {
				module.periodStart = lastModuleEndDate.add(1, "day");
			}
			module.dateRange = module.periodStart.format("DD.MM") + "-" + module.periodEnd.format("DD.MM");
			lastModuleEndDate = angular.copy(module.periodEnd);
		});
	}

	/**
	 * Обработка учебных периодов
	 * @param studyPeriods учебные периоды
	 * @param attestationPeriods аттестационные периоды
	 * @param modules модули поурочных планов
	 */
	function preparePeriods(studyPeriods, attestationPeriods, modules) {
		// учебные периоды
		_.forEach(studyPeriods, (period) => {
			period.periodStart = moment(period.begin_date, "YYYY-MM-DD").startOf("day");
			period.periodEnd = moment(period.end_date, "YYYY-MM-DD").endOf("day");
			period.dateRange = period.periodStart.format("DD.MM") + "-" + period.periodEnd.format("DD.MM");
			period.type = "study";
		});
		// аттестационные периоды
		_.forEach(attestationPeriods, (period) => {
			period.periodStart = moment(period.begin_date, "YYYY-MM-DD").startOf("day");
			period.periodEnd = moment(period.end_date, "YYYY-MM-DD").endOf("day");
			period.dateRange = period.periodStart.format("DD.MM") + "-" + period.periodEnd.format("DD.MM");
			period.type = "attestation";
		});
		// модули
		_.forEach(modules, (module) => {
			module.periodStart = $$lessonModules.getStartModuleDateObject(module);
			module.periodEnd = $$lessonModules.getEndModuleDateObject(module);
			module.dateRange = module.periodStart.format("DD.MM") + "-" + module.periodEnd.format("DD.MM");
			module.type = "module";
		});
	}

	/**
	 * Загрузка аттестационных периодов по id самих периодов
	 * @param periodsIds [] массив id периодов
	 * @returns {*}
	 */
	function getAttestationPeriodsScheduleByPeriods(periodsIds) {
		return $q((resolve) => {
			if (periodsIds.length > 0) {
				// загружаем периоды по их id
				$$attestationPeriod.collection.getList({
					ids: _.uniq(periodsIds).join(",")
				})
					.then((periods) => {
						const schedulesIds = _.uniq(_.map(periods, "attestation_periods_schedule_id"));
						// загружаем шедули периодов

						return $$attestationPeriodsSchedule.collection.getList({
							ids: schedulesIds.join(",")
						});
					})
					.then((attestationPeriodsSchedulesList) => {
						_.forEach(attestationPeriodsSchedulesList, (attestationPeriodsSchedule) => {
							// сортируем периоды по дате начала
							attestationPeriodsSchedule.periods = _.sortBy(attestationPeriodsSchedule.periods, "begin_date");
							// добавляем поле с типом периода, чтобы можно было отличить периоды по типу в общей куче периодов
							_.forEach(attestationPeriodsSchedule.periods, (period) => {
								period.type = "attestation";
							});
						});
						resolve(attestationPeriodsSchedulesList);
					});
			} else {
				resolve([]);
			}
		});
	}

	/**
	 * Загрузка учебных периодов по id самих периодов
	 * @param periodsIds [] массив id периодов
	 * @returns {*}
	 */
	function getPeriodsScheduleByPeriods(periodsIds) {
		return $q((resolve) => {
			if (periodsIds.length > 0) {
				// загружаем периоды по их id
				$$period.collection.getList({
					ids: _.uniq(periodsIds).join(",")
				})
					.then((periods) => {
						const request = [];
						const requestIds = _.map(periods, (period) => {
							return period.periods_schedule.id;
						});
						// При запросе коллекции бэк не добавляет поле is_vacation в периоды, только при запросе по id,
						// поэтому загружаем шедули периодов по одному, а не списком.
						_.forEach(_.uniqBy(requestIds), (periodsScheduleId) => {
							request.push($$periodSchedule.getOne(periodsScheduleId));
						});

						return $q.all(request);
					})
					.then((periodsSchedulesList) => {
						_.forEach(periodsSchedulesList, (periodsSchedule) => {
							// убираем неучебные периоды и сортируем по дате начала
							periodsSchedule.periods = _.sortBy(_.reject(periodsSchedule.periods, {is_vacation: true}), "begin_date");
							// добавляем поле с типом периода, чтобы можно было отличить периоды по типу в общей куче периодов
							_.forEach(periodsSchedule.periods, (period) => {
								period.type = "study";
							});
						});
						resolve(periodsSchedulesList);
					});
			} else {
				resolve([]);
			}
		});
	}

	/**
	 * Загрузка поурочных планов
	 * @param classLevelId id параллели
	 * @param classUnitId id класса
	 * @param academicYearId id учебного года
	 * @param modulesIds [] массив id модулей
	 * @returns {*}
	 */
	function getLessonPlans(classLevelId, classUnitId, academicYearId, modulesIds) {
		return $q((resolve) => {
			if (modulesIds.length > 0) {
				$q.all({
					lessonPlans: getLessonPlansList(academicYearId, classLevelId),
					modules: $$lessonModules.getList({ids: modulesIds.join(",")})
				})
					.then((response) => {
						// при запросе поурочных планов с модулями в самих модулях нет дат
						// даты есть, если запрашивать модули отдельно.
						// далее меняем модули в поурочных планах на те же модули с датами, но загруженные отдельно.
						_.forEach(response.lessonPlans, (lessonPlan) => {
							_.forEach(lessonPlan.modules, (module) => {
								const moduleWithDate = _.find(response.modules, {id: module.id});
								if (moduleWithDate) {
									module.start_date = moduleWithDate.start_date;
									module.end_date = moduleWithDate.end_date;
								}
								module.type = "module";
							});
							lessonPlan.modules = _.filter(lessonPlan.modules, (module) => Boolean(module.start_date) || Boolean(module.end_date));
						});

						resolve(_.filter(response.lessonPlans, (lessonPlan) => {
							const lessonPlanClassUnits = _.map(lessonPlan.class_units, "id");
							const lessonPlanModules = _.map(lessonPlan.modules, "id");

							return ((lessonPlanClassUnits.indexOf(classUnitId) !== -1) && _.intersects(lessonPlanModules, modulesIds));
						}));
					});
			} else {
				resolve([]);
			}
		});
	}

	function getLessonPlansList(academicYearId, classLevelId) {
		return bigQuery.queue.getList($$lessonPlan.collection, {
			academic_year_id: academicYearId,
			class_level_id: classLevelId,
			with_modules: true
		}, 30);
	}
}
