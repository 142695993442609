import _int from "tools/fp/int";

class ECControlForms {
	static $inject = ["EC"];
	static URL = "/api/ec_control_forms";


	api = null;
	apiWithConfig = null;
	collection = null;
	collectionWithConfig = null;

	constructor(EC) {
		this.api = EC;
		this.apiWithConfig = this.api.withConfig((configBuilder) => configBuilder.setFullResponse(true));

		this.collection = this.api.all(ECControlForms.URL);
		this.collectionWithConfig = this.apiWithConfig.all(ECControlForms.URL);
	}

	/**
	 * BindOne
	 * @param id
	 * @return {*}
	 */
	bindOne(id) {
		return this.api.one(ECControlForms.URL, id);
	}


	/**
	 * GetList
	 * @param query
	 * @return {*}
	 */
	getList(query) {
		return this.collection.getList(query);
	}


	/**
	 * Запрашиваем страницу форм ФК
	 */
	async getPage(query, page = 1, perPage = 30) {
		const {data, headers} = await this.collectionWithConfig.getList({
			...query,
			page,
			per_page: perPage
		});

		return {
			data,
			pages: _int(headers("pages")) || 1
		};
	}


	/**
	 * Getter шаблона
	 */
	getTemplate() {
		return {
			short_name: "",
			name: "",
			weight: 1,
			is_exam: false,
			education_level_id: null,
			subject_id: null,
			school_id: null,
			grade_system_id: null,
			origin_control_form_id: null
		};
	}
}


angular.module("ezd.backend")
	.service("ECControlForms", ECControlForms);
