export class ImageCropper {
	/**
	 * CONSTRUCTOR
	 * @param canvasElem {HTMLElement}
	 * @param imageSrc   {String}
	 */
	constructor(canvasElem, imageSrc) {
		this.ctx = canvasElem.getContext("2d");
		this.image = new Image();
		this.scale = 1;
		this.click = false;
		this.baseX = 0;
		this.baseY = 0;
		this.lastPointX = 0;
		this.lastPointY = 0;
		this.cutoutWidth = 80;
		this.windowWidth = 220;

		this.image.setAttribute("crossOrigin", "anonymous");
		this.image.src = imageSrc;
		this.image.onload = this.onImageLoad.bind(this);
		// Cross-browser wheel eventHandler
		this.addWheelListener(canvasElem, this.updateScale.bind(this));
	}


	/**
	 * Animation on the canvas depends on three events of mouse. down, up and move
	 */
	onImageLoad() {
		this.drawimage(0, 0);
		this.ctx.canvas.onmousedown = this.onMouseDown.bind(this);
		this.ctx.canvas.onmousemove = this.onMouseMove.bind(this);
		this.ctx.canvas.onmouseup = this.onMouseUp.bind(this);
	}

	/**
	 * Draw image on canvas, after any changes
	 * @param  {[type]} x
	 * @param  {[type]} y
	 * @return {[type]}
	 */
	drawimage(x, y) {
		const w = this.ctx.canvas.width;
		const h = this.ctx.canvas.height;

		this.ctx.clearRect(0, 0, w, h);
		this.baseX = this.baseX + (x - this.lastPointX);
		this.baseY = this.baseY + (y - this.lastPointY);
		this.lastPointX = x;
		this.lastPointY = y;
		this.ctx.drawImage(this.image, this.baseX, this.baseY, Math.floor(this.image.width * this.scale), Math.floor(this.image.height * this.scale));
		this.drawCutout();
	}

	/**
	 * Responsible to draw the cutout over canvas, clockwise rectangle and anticlock wise rectangle, make sure a cutout.
	 * @return {[type]}
	 */
	drawCutout() {
		this.ctx.fillStyle = "rgba(128, 128, 128, 0.7)";
		this.ctx.beginPath();
		this.ctx.rect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

		// Draw anti clockwise rectangle, for cutout.
		this.ctx.moveTo(this.cutoutWidth, this.cutoutWidth);
		this.ctx.lineTo(this.cutoutWidth, this.windowWidth + this.cutoutWidth);
		this.ctx.lineTo(this.cutoutWidth + this.windowWidth, this.cutoutWidth + this.windowWidth);
		this.ctx.lineTo(this.cutoutWidth + this.windowWidth, this.cutoutWidth);
		this.ctx.closePath();
		this.ctx.fill();
	}

	/**
	 * Get call on mouse press, make click variable to true, which will be used in mousemove events
	 * It also set the point were mouse click happened.
	 * @param  {[type]} e
	 * @return {[type]}
	 */
	onMouseDown(e) {
		e.preventDefault();
		const loc = this.windowToCanvas(e.clientX, e.clientY);

		this.click = true;
		this.lastPointX = loc.x;
		this.lastPointY = loc.y;
	}

	/**
	 * Track the mouse movment and draw the image accordingly, but only when clicked happened.
	 * @param  {[type]} e
	 * @return {[type]}
	 */
	onMouseMove(e) {
		e.preventDefault();
		if (this.click) {
			const loc = this.windowToCanvas(e.clientX, e.clientY);
			this.drawimage(loc.x, loc.y);
		}
	}

	/**
	 * make click = false, hence no canvas draw on mousemovment.
	 * @param  {[type]} e
	 * @return {[type]}
	 */
	onMouseUp(e) {
		e.preventDefault();
		this.click = false;
	}

	/**
	 * Translate to HTML coardinates to Canvas coardinates.
	 */
	windowToCanvas(x, y) {
		const canvas = this.ctx.canvas;
		const bbox = canvas.getBoundingClientRect();

		return {
			x: x - (bbox.left * (canvas.width / bbox.width)),
			y: y - (bbox.top * (canvas.height / bbox.height))
		};
	}

	/**
	 * Get the canavs, remove cutout, create image elemnet on UI.
	 * @return {[type]}
	 */
	getCropedImage() {
		const tempCanvas = document.createElement("canvas");
		const tempCtx = tempCanvas.getContext("2d");

		tempCanvas.width = this.windowWidth;
		tempCanvas.height = this.windowWidth;
		tempCtx.drawImage(this.ctx.canvas, this.cutoutWidth, this.cutoutWidth, this.windowWidth, this.windowWidth, 0, 0, this.windowWidth, this.windowWidth);

		return tempCanvas.toDataURL();
	}

	/**
	 * Update image zoom scale on slider movment.
	 * @param  {[type]} e
	 * @return {[type]}
	 */
	updateScale(e) {
		const min = 0.1;
		const max = 3;
		const value = this.scale + (Math.round(e.deltaY) / 10000);
		if (value > min && value < max) {
			this.scale = value;
		} else if (value < min) {
			this.scale = min;
		} else if (value > max) {
			this.scale = max;
		}

		this.drawimage(this.lastPointX, this.lastPointY);
	}


	/**
	 * Create cross-browser wheel event listener
	 * @param elem       {HTMLElement}
	 * @param listener   {Function}
	 */
	addWheelListener(elem, listener) {
		if (elem.addEventListener) {
			if ("onwheel" in document) {
				// IE9+, FF17+, Ch31+
				elem.addEventListener("wheel", listener);
			} else if ("onmousewheel" in document) {
				// устаревший вариант события
				elem.addEventListener("mousewheel", listener);
			} else {
				// Firefox < 17
				elem.addEventListener("MozMousePixelScroll", listener);
			}
		} else { // IE8-
			elem.attachEvent("onmousewheel", listener);
		}
	}
}
