class CalendarTranspositionService {
	static $inject = ["Core"];
	static URL = "/api/calendar_transpositions";

	api = null;
	collection = null;

	constructor(api) {
		this.api = api;
		this.collection = this.api.all(CalendarTranspositionService.URL);
	}


	/**
	 * bindOne
	 * @param id
	 * @return {*}
	 */
	bindOne(id) {
		return this.api.one(CalendarTranspositionService.URL, id);
	}
}

angular.module("ezd.backend")
	.service("CalendarTransposition", CalendarTranspositionService);
