import _getOr from "lodash/fp/getOr";


/**
 * Сервис для работы с апи выгрузки журнала
 */
class JournalsArchive {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
	}

	/**
	 * скачка файла
	 * @returns {Promise<*>}
	 */
	getArchive() {
		return this.CoreService.all("/api/journals/get_latest?generate_archive=false").withHttpConfig({responseType: "blob"}).customGET();
	}

	/**
	 * Получение даты последнего скачивания выгрузки
	 * @returns {Promise<*>}
	 */
	async getLastDownloadDate() {
		const response = await this.CoreService
			.all("/api/journals")
			.one("get_last_download_date")
			.get();

		return _getOr("", "last_download_date", response);
	}
}

angular
	.module("ezd.backend")
	.service("$$journalsArchive", JournalsArchive);
