angular
	.module("ezd.backend")
	.factory("$$awareJournal", Factory);


/**
 *
 * @param LMSv2
 * @returns {Object|*}
 * @constructor
 */
Factory.$inject = ["Core"];

/**
 * Получить инстанс для AwareJournal
 * @param Core
 * @returns {*|Object}
 * @constructor
 */
function Factory(Core) {
	return Core.service("/api/aware_journals");
}
