import {Component} from "app/decorators/index";
import template from "./ezd-filter-toggle.html";
import styles from "./ezd-filter-toggle.local.scss";
import {filter} from "images/icons/index";

/**
 * isFilterOpen {Boolean}
 */
@Component({
	selector: "ezdFilterToggle",
	template,
	bindings: {
		isFilterOpen: "=",
		onChange: "&"
	}
})
class EzdFilterToggleComponent {
	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		this.styles = styles;
		this.icons = {filter};
	}


	/**
	 *
	 */
	toggleFilterOpen() {
		this.isFilterOpen = !this.isFilterOpen;

		this.onChange();
	}
}


angular.module("ezd.common.ui")
	.component(EzdFilterToggleComponent.selector, EzdFilterToggleComponent);
