// styles
import "npm/angular-material/angular-material.min.css";
import "npm/ng-wysiwyg/dist/editor.min.css";
import "styles/main.scss";

import "angular-native-dragdrop";

import _isObject from "lodash/fp/isObject";
import _get from "lodash/fp/get";

import ezdBackend from "components/backend/index";
import ezdCommonUI from "components/common/index";
import ezdMocks from "components/mocks/index";
import ezdProviders from "components/common/components/$router/$router";

import layouts from "./layouts/index";
import { loadLazyComponents } from "./components.loader";
/**
 *
 * @param r
 */
function requireAll(r) {
	r.keys().forEach(r);
}
requireAll(require.context("../styles", true, /\.css$/));


angular.module("mrko2App", [
	"cfp.hotkeys", "oc.lazyLoad", "ngMaterial", "ngSanitize", "ang-drag-drop",
	ezdBackend, ezdCommonUI, ezdMocks, ezdProviders
])
	.config(Config)
	.config(layouts)
	.run(Runner)
	.run(loadLazyComponents);


/**
 *  Manual initialization
 */
angular.element(document).ready(() => {

	const injector = angular.injector(["ng", "ezd.backend"], true);
	const orgLocalization = injector.get("OrgLocalization");
	const sessions = injector.get("Sessions");

	let initPromise;
	if (_.intersects([window.location.pathname], ["/privacy"])) {
		initPromise = orgLocalization.init(true);
	} else {
		initPromise = sessions.initSession().then(() => orgLocalization.init());
	}

	initPromise
		.then(() => {
			angular.bootstrap(document, ["mrko2App"], {
				strictDi: true
			});
		})
		.catch(err => console.error(err));
});

/**
 *
 * @param type
 * @returns {*}
 */
function getScreenSizes(type) {
	switch (type) {
		case "wide":
			return {
				page: {
					width: "1350px"
				},
				r_sidebar: {
					width: "1141px"
				},
				top_menu: {
					width: "1350px"
				}
			};
			break;
		case "fluid":
			return {
				page: {
					width: "100%",
					"min-width": "1280px"
				},
				r_sidebar_f_small: {
					width: "calc(100% - 209px)"
				},
				r_sidebar_f_full: {
					width: "calc(100% - 154px - 55px)"
				},
				top_menu: {
					width: "100%",
					"min-width": "1280px"
				}
			};
			break;
		default:
			return {
				page: {
					width: "1127px"
				},
				r_sidebar: {
					width: "918px"
				},
				top_menu: {
					width: "1127px"
				}
			};
	}
}


Config.$inject = ["$locationProvider", "$compileProvider", "flashAlertProvider", "$mdThemingProvider", "$mdDateLocaleProvider", "$ariaProvider", "$mdGestureProvider"];
/**
 * @param $locationProvider
 * @param $compileProvider
 * @param flashAlertProvider
 * @param $mdThemingProvider
 * @param $mdDateLocaleProvider
 * @constructor
 */
function Config($locationProvider, $compileProvider, flashAlertProvider, $mdThemingProvider, $mdDateLocaleProvider, $ariaProvider, $mdGestureProvider) {
	$locationProvider.html5Mode(true);
	$mdGestureProvider.skipClickHijack();
	$compileProvider.debugInfoEnabled(false);

	$ariaProvider.config({
		bindKeydown: false,
		bindKeypress: false,
		bindRoleForClick: false,
		tabindex: false
	});


	// локализуем материальный дэйтпикер
	$mdDateLocaleProvider.months = ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"];
	$mdDateLocaleProvider.shortMonths = ["янв", "фев", "март", "апр", "май", "июнь", "июль", "авг", "сен", "окт", "ноя", "дек"];
	$mdDateLocaleProvider.days = ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"];
	$mdDateLocaleProvider.shortDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
	$mdDateLocaleProvider.firstDayOfWeek = 1;

	$mdDateLocaleProvider.parseDate = function (dateString) {
		const m = moment(dateString, "L", true);

		return m.isValid() ? m.toDate() : new Date();
	};

	$mdDateLocaleProvider.formatDate = function (date) {
		const m = moment(date);

		return m.isValid() ? m.format("L") : "";
	};


	/*
	 * тема для контента
	 */
	$mdThemingProvider.theme("default")
		.primaryPalette("cyan", {
			default: "600"
		})
		.accentPalette("cyan", {
			default: "600"
		});

	$mdThemingProvider.theme("header")
		.primaryPalette("cyan", {
			default: "600"
		})
		.dark();

	$mdThemingProvider.theme("leftMenu")
		.backgroundPalette("cyan", {
			default: "600"
		}).dark();


	$mdThemingProvider.theme("rightMenu")
		.primaryPalette("grey", {
			default: "50"
		})
		.dark();


	// $mdThemingProvider.setDefaultTheme('ezdcontent');

	flashAlertProvider.setAlertTime(9000);
}


Runner.$inject = ["$rootScope", "$location", "Sessions", "flashAlert", "$$history", "$route", "natSort", "$$filters",
	"$q", "$window", "$state", "$document", "$mdDialog", "Restangular"];
/**
 * @param $rootScope
 * @param $location
 * @param Sessions
 * @param flashAlert
 * @param $$history
 * @param $route
 * @param natSort
 * @param $$filters
 * @param $q
 * @param $window
 * @param $state
 * @param $document
 * @param $mdDialog
 * @constructor
 */
function Runner($rootScope, $location, Sessions, flashAlert, $$history, $route, natSort, $$filters, $q, $window,
	$state, $document, $mdDialog, Restangular) {
	$window.Promise = $q;
	$$filters.init();

	$rootScope.$state = $state;

	const original = $location.path;
	$location.path = (path, reload) => {
		if (reload === false) {
			const lastRoute = $route.current;
			const un = $rootScope.$on("$locationChangeSuccess", () => {
				$route.current = lastRoute;
				un(); // удаляем слушатель
			});
		}

		return original.apply($location, [path]);
	};

	// промис для общего лоадера страницы
	$rootScope.loaderPromise = null;

	// Глобальный объект для cgBusy, в него можно пропихивать кастомную конфигурацию
	$rootScope.global_promise = {};

	$rootScope.closedMenu = true;

	// FIXME: разобраться, почему нужно инициализировать именно тут и убрать.
	// См. блок "Manual initialization" выше
	if ($location.path() !== "/privacy") {
		Sessions.initSession();
	}

	/**
	 * Проверка на авторизации, а так же динамическое изменение размера экрана.
	 */
	$rootScope.$on("$stateChangeStart", (event, toState) => {
		if (toState) {
			$rootScope.refName = _get("data.refName", toState) || $rootScope.refName;
		}

		$mdDialog.cancel();
	});

	$rootScope.$on("$stateChangeSuccess", (event, toState) => {
		$document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;

		if (toState) {
			$rootScope.styles = _isObject(_get("data.screenSizes", toState))
				? toState.data.screenSizes
				: getScreenSizes(_get("data.screenType", toState));

			$rootScope.disableYearSelector = _get("data.disableYearSelector", false, toState);

			if (_get("data.useHistory", false, toState) !== false) {
				$$history.addToHistory($location.url());
			}
		}
	});

	/**
	 * Метод для натуральной сортировки
	 * @param field
	 *
	 * @returns {Function}
	 */
	$rootScope.natural = (field, ignoreCase = false) =>
		(item) => natSort.naturalValue(item[field], ignoreCase);

	$rootScope.clickRMbuttons = () => {
		flashAlert.info("Извините, данный функционал будет доступен позже");
	};

	Restangular.one("functionality.config.json").get()
		.then((config) => {
			$rootScope.functionalityConfig = config;
		});
}
