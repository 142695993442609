import EzdConfirmTemplate from "./ezd-confirm.html";
import {EzdConfirmController} from "./ezd-confirm.controller";

angular
	.module("ezd.common.ui")
	.service("EzdConfirm", EzdConfirm);

EzdConfirm.$inject = ["$mdDialog"];
/**
 *
 */
function EzdConfirm($mdDialog) {
	const vm = this;
	vm.confirm = confirm;

	// vm.openDialog = openDialog;

	/**
	 *
	 */
	function confirm(params) {
		if (!params) {
			return openDialog({
				text: "",
				title: "Подтвердите действие",
				confirmButton: "Ок",
				rejectButton: "Отмена"
			});
		}

		return openDialog(params);
	}

	/**
	 *
	 */
	function openDialog(params) {
		return $mdDialog.show({
			params,
			parent: params.parent || angular.element(document.body),
			clickOutsideToClose: true,
			template: EzdConfirmTemplate,
			controller: EzdConfirmController,
			controllerAs: "$ctrl"
		});
	}
}
