

angular
	.module("ezd.common.ui")
	.directive("themeManagerButton", Directive);


function Directive() {
	return {
		template: "<div check-profile=\"['student']\"><div class=\"b-main-visually_impaired\" ng-click=\"tmb.togglePanelState()\"><i class=\"i-icon__glasses\"></i> <span>ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ</span></div></div>",
		controller: Controller,
		controllerAs: "tmb"
	};
}


/**
 *
 * @param $rootScope
 * @param $$themeManager
 * @constructor
 */
Controller.$inject = ["$rootScope", "$$themeManager"];
function Controller($rootScope, $$themeManager) {
	const tmb = this;

	tmb.togglePanelState = togglePanelState;

	/**
	 * Переключение состояния
	 */
	function togglePanelState() {
		$rootScope.themeManagerPanelShowing = !$rootScope.themeManagerPanelShowing;

		if ($rootScope.themeManagerPanelShowing && $$themeManager.theme.name === "color") {
			$$themeManager.setTheme("black");
		}
	}
}