angular
	.module("ezd.backend")
	.factory("AEEducationDurations", aeEducationDurations);


aeEducationDurations.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {Object|*}
 */
function aeEducationDurations(Core) {
	const service = Core.service("/api/ae_education_durations");

	service.getList = async (params) => {
		const durationList = await Core.service("/api/ae_education_durations").getList(params);

		return _.chain(durationList)
			.map((duration) => {
				const durationMoment = moment.duration({
					years: duration.education_duration_years,
					months: duration.education_duration_months,
					days: duration.education_duration_days,
					weeks: duration.education_duration_weeks
				});
				const daysInMonth = 30;
				const daysInYear = 365;
				const dayMs = 1000 * 60 * 60 * 24;
				let ms = duration.education_duration_years * dayMs * daysInYear;
				ms += duration.education_duration_months * dayMs * daysInMonth;
				ms += duration.education_duration_days * dayMs;
				ms += duration.education_duration_weeks * dayMs * 7;
				duration.name = moment.priciseHumanizer(ms,
					{
						unitMeasures: {// bind to standard len
							y: dayMs * daysInYear,
							mo: dayMs * daysInMonth,
							w: dayMs * 7,
							d: dayMs,
							h: dayMs / 24,
							m: 1000 * 60,
							s: 1000,
							ms: 1
						}
					});
				duration.durationMoment = durationMoment;

				return duration;
			})
			.sortBy((item) => item.durationMoment.asSeconds())
			.value();
	};

	return service;
}
