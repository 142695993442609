import {MapPopup} from "./map-popup/map-popup";
import MapPopupTemplate from "./map-popup/map-popup.html";

export class EzdMapAddressService {
	static name = "EzdMapAddress";
	static $inject = ["$mdDialog"];

	constructor($mdDialog) {
		this.$mdDialog = $mdDialog;
	}

	/**
	 * Открываем попап для редактирования адреса
	 * @param {address: {string}}
	 * @return {Promise<{address: string}>}
	 */
	async open({address}) {
		return await this.$mdDialog.show({
			parent: angular.element(document.body),
			clickOutsideToClose: false,
			locals: {
				address
			},
			template: MapPopupTemplate,
			controller: MapPopup,
			controllerAs: "$ctrl",
			fullscreen: true
		});
	}
}
