const URL = "/api/academic_debt_documents";

class AcademicDebtDocument {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}

	getTemplate() {
		return {
			name: "",
			date: "",
			attachment_id: null
		};
	}
}


angular
	.module("ezd.backend")
	.service("AcademicDebtDocument", AcademicDebtDocument);
