

angular
	.module("ezd.common.ui")
	.directive("revision", RevisionDirective);

function RevisionDirective() {
	return {
		scope: {
			revision: "@"
		},
		replace: true,
		template: "<div class=\"git-revision-description\">v{{revision}}</div>"
	};
}