import {Constant} from "app/decorators/constant";

@Constant("URLS_LIST")
export class UrlsListConst {
	urls = [
		{ name: "subject", urlToGo: "/refs/_subjects", urlToSearch: "/refs/subjects", isDemoVersion: true },
		{ name: "staff", urlToGo: "/refs/staff", urlToSearch: "/refs/_staff_list", isDemoVersion: true },
		{ name: "studentsWithoutCurriculaBars", urlToGo: "/_decisions/bars", urlToSearch: "/decisions/students_without_curricula", isDemoVersion: false },
		{ name: "studentsWithoutCurriculaRows", urlToGo: "/_decisions/rows", urlToSearch: "/decisions/students_without_curricula", isDemoVersion: false },
		{ name: "reportedMessages", urlToGo: "/_decisions/bars", urlToSearch: "/decisions/reported_messages", isDemoVersion: false },
		{ name: "journalChecks", urlToGo: "/_decisions/bars", urlToSearch: "/decisions/journal_checks", isDemoVersion: false },
		{ name: "rejectedTestLessons", urlToGo: "/_decisions/bars", urlToSearch: "/decisions/rejected_test_lessons", isDemoVersion: false },
		{ name: "studentToGroups", urlToGo: "/study_plan/_students_to_groups", urlToSearch: "/study_plan/students_to_groups", isDemoVersion: false },
		{ name: "controlForms", urlToGo: "/refs/controlforms", urlToSearch: "/refs/_controlforms", isDemoVersion: true },
		{ name: "finalMarks", urlToGo: "/reports/_finalmarks", urlToSearch: "/reports/finalmarks", isDemoVersion: false },
		{ name: "ecControlForms", urlToGo: "/refs/ec_control_forms", urlToSearch: "/refs/_ec_control_forms", isDemoVersion: true },
		{ name: "lessonPlan", urlToGo: "/manage/lesson_plans", urlToSearch: "/manage/new_lesson_plans", isDemoVersion: true },
		{ name: "calendarPlan", urlToGo: "/manage/calendar_plans", urlToSearch: "/manage/new_calendar_plans", isDemoVersion: true },
		{ name: "diary", urlToGo: "/student_diary/student_diary", urlToSearch: "/manage/student_journal", isDemoVersion: false },
		{ name: "homeworks", urlToGo: "/student_diary/homeworks", urlToSearch: "/manage/student_homeworks", isDemoVersion: false },
		{ name: "marks", urlToGo: "/student_diary/marks", urlToSearch: "/progress/all_marks", isDemoVersion: false }
	];

	/**
	 * Url'ы демо-версий экранов
	 * @type {string[]}
	 */
	demoScreenUrls = [
		"/_decisions/bars",
		"/_decisions/bad_messages",
		"/_decisions/journal_check",
		"/_decisions/rejected_control_works",
		"/_decisions/rows",
		"/manage/new_lesson_plans",
		"/manage/new_calendar_plans",
		"/refs/subjects",
		"/refs/_staff_list",
		"/refs/_controlforms",
		"/refs/_ec_control_forms",
		"/reports/_finalmarks",
		"/student_diary/student_diary",
		"/student_diary/homeworks",
		"/study_plan/_students_to_groups"
	];
}
