angular
	.module("ezd.backend")
	.service("$$reportType", $$reportType);


/**
 *
 * @param $q
 */
$$reportType.$inject = ["$q", "$$profile"];
function $$reportType($q, $$profile) {
	const vm = this;

	vm.getFilteredList = getFilteredList;
	vm.getList = getList;

	const getGlobalString = getPageString.bind(undefined, '$global');
	const allRoles = [0, 1, 2, 3, 4, 5, 6, 7];
	const allStaffRoles = [2, 3, 4, 5, 6, 7];

	const reportTypes = [
		{
			id: 0,
			name: "Профили в системе (сотрудник, родитель, обучающийся)",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allStaffRoles
		},
		{
			id: 1,
			name: "Роли в системе (добавление/изменение)",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allStaffRoles
		},
		{
			id: 2,
			name: "Дублирующие личности",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allRoles
		},
		{
			id: 3,
			name: "Ошибка «Пользователь не найден»",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allRoles
		},
		{
			id: 4,
			name: "Ошибка «Нашлось больше одного пользователя»",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allRoles
		},
		{
			id: 5,
			name: "Ошибка «Упс, что-то пошло не так»",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allRoles
		},
		{
			id: 6,
			name: "Ошибка «Вам не был предоставлен доступ к подсистемам ЭПОС»",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allRoles
		},
		{
			id: 7,
			name: "Проблема со входом (другое)",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allRoles
		},
		{
			id: 8,
			name: "Производительность системы (замедления и др.)",
			type: "ЭПОС_Прочее",
			roles: allRoles
		},
		{
			id: 9,
			name: "Мобильное приложение: проблема со входом (android)",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allRoles
		},
		{
			id: 10,
			name: "Мобильное приложение: проблема со входом (ios)",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: allRoles
		},
		{
			id: 11,
			name: "Мобильное приложение: общее",
			type: "ЭПОС_Прочее",
			roles: allRoles
		},
		{
			id: 12,
			name: "Проблемы в отображении личных данных",
			type: "ЭПОС_Проблемы с персональными данными",
			roles: allRoles
		},
		{
			id: 13,
			name: "Справочник «Здания, кабинеты, места»",
			type: "ЭПОС_Проблемы со справочниками",
			roles: [3]
		},
		{
			id: 14,
			name: "Справочник «Кадры»",
			type: "ЭПОС_Проблемы со справочниками",
			roles: [3]
		},
		{
			id: 15,
			name: "Справочник «Контингент»",
			type: "ЭПОС_Проблемы со справочниками",
			roles: [3]
		},
		{
			id: 16,
			name: "Справочник «КУГ»",
			type: "ЭПОС_Проблемы со справочниками",
			roles: [3]
		},
		{
			id: 17,
			name: "Справочник «Режимы пребывания»",
			type: "ЭПОС_Проблемы со справочниками",
			roles: [3]
		},
		{
			id: 18,
			name: "Справочник «Формы контроля»",
			type: "ЭПОС_Проблемы со справочниками",
			roles: [3]
		},
		{
			id: 19,
			name: "Справочник «Периоды аттестации»",
			type: "ЭПОС_Проблемы со справочниками",
			roles: [3]
		},
		{
			id: 20,
			name: "Заполнение содержания УП",
			type: "ЭПОС_Проблемы с учеными планами",
			roles: [3]
		},
		{
			id: 21,
			name: "Привязки обучающихся к УП/группам",
			type: "ЭПОС_Проблемы с учеными планами",
			roles: [3]
		},
		{
			id: 22,
			name: "Удаление/редактирование УП",
			type: "ЭПОС_Проблемы с учеными планами",
			roles: [3]
		},
		{
			id: 23,
			name: "Деление на группы, составление метагрупп",
			type: "ЭПОС_Проблемы с учеными планами",
			roles: [3]
		},
		{
			id: 24,
			name: "Группы, учителя, часы",
			type: "ЭПОС_Проблемы с учеными планами",
			roles: [3]
		},
		{
			id: 25,
			name: "Расписание (копирование)",
			type: "ЭПОС_Проблемы с расписанием",
			roles: [3]
		},
		{
			id: 26,
			name: "Роли в системе (суперадминистратор)",
			type: "ЭПОС_Проблемы с расписанием",
			roles: [3]
		},
		{
			id: 27,
			name: "Расписание (изменение)",
			type: "ЭПОС_Проблемы с расписанием",
			roles: [3]
		},
		{
			id: 28,
			name: "Расписание (автоматическое составление)",
			type: "ЭПОС_Проблемы с расписанием",
			roles: [3]
		},
		{
			id: 29,
			name: "Замены",
			type: "ЭПОС_Проблемы с заменами",
			roles: [3]
		},
		{
			id: 30,
			name: "Добавление группы в КТП",
			type: "ЭПОС_Проблемы с рабочей программой",
			roles: allStaffRoles
		},
		{
			id: 31,
			name: "КТП/РП",
			type: "ЭПОС_Проблемы с рабочей программой",
			roles: allStaffRoles
		},
		{
			id: 32,
			name: "Проблемы с домашними заданиями",
			type: "ЭПОС_Проблемы с домашними заданиями",
			roles: allStaffRoles
		},
		{
			id: 33,
			name: "Проблемы с оценками/отметками",
			type: "ЭПОС_Проблемы с оценками и уроками",
			roles: allStaffRoles
		},
		{
			id: 34,
			name: "Проблемы в отображении списков обучающихся",
			type: "ЭПОС_Проблемы с контингентом",
			roles: allStaffRoles
		},
		{
			id: 35,
			name: "Уведомление об отсутствии",
			type: "ЭПОС_Прочее",
			roles: allStaffRoles
		},
		{
			id: 36,
			name: "Отчеты/Требует решения",
			type: "ЭПОС_Проблемы с отчетами",
			roles: allStaffRoles
		},
		{
			id: 37,
			name: "Аттестаты",
			type: "ЭПОС_Проблемы с аттестатами",
			roles: allStaffRoles
		},
		{
			id: 38,
			name: "Чат/Новости/Мероприятия",
			type: "ЭПОС_Проблемы с чатом/новостями/мероприятиями",
			roles: allRoles
		},
		{
			id: 39,
			name: "Расписание ВД",
			type: "ЭПОС_Внеурочная деятельность",
			roles: allStaffRoles
		},
		{
			id: 40,
			name: "Рабочая программа/КТП ВД",
			type: "ЭПОС_Внеурочная деятельность",
			roles: allStaffRoles
		},
		{
			id: 41,
			name: "Привязка обучающихся к группам ВД",
			type: "ЭПОС_Внеурочная деятельность",
			roles: allStaffRoles
		},
		{
			id: 42,
			name: "Учебный план ВД",
			type: "ЭПОС_Внеурочная деятельность",
			roles: allStaffRoles
		},
		{
			id: 43,
			name: "Группа ДО",
			type: "ЭПОС_Дополнительное образование",
			roles: allStaffRoles
		},
		{
			id: 44,
			name: "Журнал ДО",
			type: "ЭПОС_Дополнительное образование",
			roles: allStaffRoles
		},
		{
			id: 45,
			name: "Рабочая программа ДО",
			type: "ЭПОС_Дополнительное образование",
			roles: allStaffRoles
		},
		{
			id: 46,
			name: "Расписание ДО",
			type: "ЭПОС_Дополнительное образование",
			roles: allStaffRoles
		},
		{
			id: 47,
			name: "Учебный план ДО",
			type: "ЭПОС_Дополнительное образование",
			roles: allStaffRoles
		},
		{
			id: 48,
			name: "Проблемы при работе в подсистеме «Контингент»",
			type: "ЭПОС_Прочее",
			roles: allStaffRoles
		},
		{
			id: 49,
			name: "Проблемы при работе в подсистеме «Траектория»",
			type: "ЭПОС_Прочее",
			roles: allStaffRoles
		},
		{
			id: 50,
			name: "Проблемы при работе в подсистеме «Аналитика и отчетность»",
			type: "ЭПОС_Прочее",
			roles: allStaffRoles
		},
		{
			id: 51,
			name: "Прочее",
			type: "ЭПОС_Прочее",
			roles: allRoles
		},
		{
			id: 52,
			name: "Вижу данные другой личности",
			type: "ЭПОС_Чужой персональные данные",
			roles: [0, 1]
		},
		{
			id: 53,
			name: "Вижу данные не всех своих детей",
			type: "ЭПОС_Проблемы с персональными данными",
			roles: [0]
		},
		{
			id: 54,
			name: "Запрос профиля (есть родитель, но нет сотрудника)",
			type: "ЭПОС_Проблемы с аутентификацией",
			roles: [0]
		},
		{
			id: 55,
			name: "Отображение расписания",
			type: "ЭПОС_Проблемы с расписанием",
			roles: [0, 1]
		},
		{
			id: 56,
			name: "Отображение домашних заданий",
			type: "ЭПОС_Проблемы с домашними заданиями",
			roles: [0, 1]
		},
		{
			id: 57,
			name: "Отображение оценок/отметок (отсутствуют, не все, не те и т.д.)",
			type: "ЭПОС_Проблемы с оценками и уроками",
			roles: [0, 1]
		},
		{
			id: 58,
			name: "Вижу не ту школу",
			type: "ЭПОС_Проблемы с персональными данными",
			roles: [1]
		}
	];

	const roleTypesAll = [
		{id: 0, name: "Родитель: "},
		{id: 1, name: getGlobalString('student') + ": "},
		{id: 2, name: getGlobalString('teacher') + ": "},
		{id: 3, name: "Администратор школы: "},
		{id: 4, name: "Администратор ЭПОС: "},
		{id: 5, name: "Администратор ДО ОО: "},
		{id: 6, name: "Администратор ДО СПО: "},
		{id: 7, name: "Администратор СПО: "}
	];

	/**
	 *
	 *
	 * @returns {*}
	 */
	function getList() {
		return $q((resolve) => resolve(reportTypes));
	}

	/**
	 *
	 * @paam roleId
	 * @returns {*}
	 */
	function getFilteredList(roles) {
		let result = [];
		if (roles === null) {
			return _.filter(reportTypes, (type) => {
				return type.roles.length === 0;
			});
		}
		_.forEach(roles, (role) => {
			const resultList = _.filter(reportTypes, (rt) => {
				return rt.roles.includes(role.id);
			});

			const count = result.length;
			for (let i=0; i < resultList.length; i++) {
				const rt = {
					id: count + i + 1,
					name: roleTypesAll[role.id].name + resultList[i].name
					// type: resultList[i].type,
					// roles: resultList[i].roles
				};
				result.push(rt);
			}
		});

		return $q((resolve) => resolve(result));
	}
}
