import {Component} from "app/decorators/index";
import template from "./ezd-autocomplete-with-chips.html";
import styles from "./ezd-autocomplete-with-chips.local.scss";
import {close, find, selectArrow} from "images/icons/index";

import forEach from "lodash/fp/forEach";
import size from "lodash/fp/size";
import isEqual from "lodash/fp/isEqual";
import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import _isEmpty from "lodash/fp/isEmpty";
import _isUndefined from "lodash/fp/isUndefined";
import _find from "lodash/fp/find";

/**
 * Это - автокомплит с чипами.
 * В collection передаём метод, возвращающий промис, либо готовую коллекцию
 * model - коллекция чипов
 * width - ширина списка и самого элемента
 * chip-max-width - ширина выбранных элементов
 * name-field - какое поле отображаем и используем для поиска
 * min-length - минимальная длина текста для начала поиска
 * max-selected-length - максимальное количество элементов, которые можно выбрать
 * multi-select-mode - флаг для множественного выбора в списке
 * enable-all - флаг доступности пункта "все" при multi-select-mode=true
 * preset-all - флаг автоматического выбора всей модели при изначально пустой модели и enable-all=true
 * show-deleted-label - показывать ли лэйбл у айтема, что айтем удалён (см. журнал - ПМ - формы контроля)
 * Пример:
 *        <ezd-autocomplete-with-chips  collection="$ctrl.base.getTeacherProfiles"
 width="600px"
 name-field="shortName"
 min-length="2"
 model="$ctrl.base.selectedTeacherProfiles"></ezd-autocomplete-with-chips>
 */
@Component({
	selector: "ezdAutocompleteWithChips",
	template,
	bindings: {
		collection: "<",
		model: "=",
		width: "@",
		chipMaxWidth: "@",
		maxSelectedLength: "<",
		nameField: "@",
		listNameField: "@",
		minLength: "<",
		onChange: "&",
		placeholder: "@",
		multiSelectMode: "<",
		enableAll: "<",
		presetAll: "<",
		disabled: "<",
		required: "<",
		maxHeight: "<?",
		name: "@",
		dontTriggerFirstChange: "<",
		showDeletedLabel: "<"
	}
})

class EzdAutocompleteWithChipsComponent {
	static $inject = ["$element", "$timeout", "$scope"];

	/**
	 *
	 * @param $element
	 * @param $timeout
	 * @param $scope
	 */
	constructor($element, $timeout, $scope) {
		this.services = {$timeout, $scope};
		this.icons = {close, find, selectArrow};
		this.dropdownOpened = false;
		this.showInput = false;
		this.isSelectedAll = false;
		this.styles = styles;
		this.searchText = "";
		this.inputElement = $element.find("input")[0];
	}

	/**
	 *
	 */
	$onInit() {
		const {$scope} = this.services;
		this.model = this.model || [];
		this.nameField = this.nameField || "name";
		this.listNameField = this.listNameField || this.nameField;

		if (_isUndefined(this.maxHeight)) {
			this.maxHeight = "200px";
		}

		if (typeof this.collection === "function") {
			this.collectionIsAFunction = true;
		} else {
			_.forEach(this.collection, (item) => {
				item.selected = Boolean(_.find(this.model, {id: item.id}));
			});
			this.$updateSelection();
			this.filteredCollection = this.collection;
			this.buildModel();
		}

		$scope.$watch(() => this.model, (nV, pV) => {
			forEach((item) => {
				item.selected = Boolean(_find({id: item.id})(this.model));
			})(this.collection);
			if (size(nV) !== size(pV)) {
				this.$updateSelection();
			}
		});
	}

	/**
	 * */
	$onChanges(changes) {
		if ((changes.collection && !changes.collection.isFirstChange()) || !_isEmpty(this.collection)) {
			this.$onInit();
			this.$presetAll();
		}
	}

	/**
	 * кликаем по основному элементу
	 */
	containerClicked() {
		if (this.disabled) {
			return;
		}

		const {$timeout} = this.services;

		this.dropdownOpened = !this.dropdownOpened;
		this.showInput = true;
		$timeout(() => {
			this.inputElement.focus();
		}, 0);
	}

	/**
	 * кликаем по элементу списка
	 * @param item
	 */
	itemClicked(item) {
		if (!this.$canClickItem(item)) {
			return;
		}

		if (!this.multiSelectMode) {
			this.dropdownOpened = false;
			this.showInput = false;
			this.searchText = "";
			this.changeFilterText();
		}

		if (this.collectionIsAFunction) {
			const newItem = _.cloneDeep(item);
			newItem.selected = !Boolean(item.selected);
			this.buildModel(newItem);
		} else {
			item.selected = !item.selected;
			this.buildModel();
		}

		this.$updateSelection();

		this.$onChange();
	}

	/**
	 * Проверка можно ли выбрать / отменить выбор элемента
	 *
	 * @param item
	 * @returns {boolean}
	 */
	$canClickItem(item) {
		const maxLength = _.int(this.maxSelectedLength);
		const selectedLength = _.get(this.model, "length", 0);

		// если не задано ограничение на количество выбранных элементов, то элемент можно выбрать
		if (!maxLength) {
			return true;
		}

		// Если элемент уже выбран, то можно отменить выбор
		if (Boolean(item.selected)) {
			return true;
		}

		return selectedLength < maxLength;
	}

	/**
	 * стили для элементов списка
	 * @param item
	 * @returns {string}
	 */
	getItemStyle(item) {
		return `${this.styles["dropdown-menu-item"]} ${item.selected ? this.styles.selected : ""}`;
	}

	/**
	 *
	 */
	stopEdit() {
		this.dropdownOpened = false;
		this.showInput = false;
	}

	/**
	 * меняем текст автокомплита
	 * @returns {Promise.<void>}
	 */
	async changeFilterText() {
		try {
			if (this.minLength && this.minLength > this.searchText.length) {
				if (this.searchText.length === 0) {
					this.filteredCollection = [];
				}

				return;
			}
			if (this.collectionIsAFunction) {
				this.collectionPromise = this.collection(this.searchText);
				this.filteredCollection = await this.collectionPromise;
				_.forEach(this.filteredCollection, (item) => {
					if (_.includes(_.map(this.model, "id"), item.id)) {
						item.selected = true;
					}
				});
			} else {
				this.filteredCollection = _.filter(this.collection, (item) => {
					return _.includes(item[this.nameField].toUpperCase(), this.searchText.toUpperCase());
				});
			}
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 *
	 * @param newItem
	 */
	buildModel(newItem) {
		if (this.collectionIsAFunction) {
			if (newItem.selected) {
				this.model.push(newItem);
			} else {
				this.model = _.reject(this.model, {id: newItem.id});
			}
		} else {
			this.model = _.filter(this.collection, {selected: true});
		}
	}

	/**
	 * ловим backspace
	 * @param $event
	 */
	keyPressed($event) {
		if ($event.keyCode === 8 && this.model.length > 0 && !this.searchText) {
			$event.preventDefault();

			const itemToReject = this.model[this.model.length - 1];
			itemToReject.selected = false;

			this.model = _.reject(this.model, itemToReject);
			this.searchText = itemToReject[this.nameField];
			this.changeFilterText();
		}
	}

	/**
	 * удаляем элемент из модели
	 * @param item
	 * @param $event
	 */
	removeItem(item, $event) {
		$event.stopPropagation();
		item.selected = false;
		this.model = _.reject(this.model, item);
		this.$onChange();
	}

	/**
	 * */
	toggleAll(isAll, dontTriggerChange = false) {
		this.isSelectedAll = _isUndefined(isAll) ? !this.isSelectedAll : isAll;
		this.dropdownOpened = false;
		this.showInput = false;
		forEach((item) => {
			item.selected = this.isSelectedAll;
		})(this.collection);
		this.buildModel();
		if (!dontTriggerChange) {
			this.$onChange();
		}
	}

	/**
	 * */
	$presetAll() {
		if (this.multiSelectMode && this.enableAll && this.presetAll) {
			if (_isEmpty(this.model) || this.isSelectedAll) {
				this.toggleAll(!_isEmpty(this.collection), this.dontTriggerFirstChange);
			}
		}
	}

	/**
	 * */
	$updateSelection() {
		// если указан флаг enableAll проверяем не выбраны ли все элементы
		if (this.enableAll && !_isEmpty(this.collection)) {
			this.isSelectedAll = flow(
				filter({selected: true}),
				size,
				isEqual(this.collection.length)
			)(this.collection);
		}
	}

	/**
	 *
	 */
	$onChange() {
		const {$timeout} = this.services;
		$timeout(() => {
			this.onChange({$event: this.model});
		}, 0);
	}
}

angular
	.module("ezd.common.ui")
	.component(EzdAutocompleteWithChipsComponent.selector, EzdAutocompleteWithChipsComponent);
