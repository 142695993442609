

angular
	.module("ezd.backend")
	.factory("ProfileClassUnitVersion", ProfileClassUnitVersion);


/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
ProfileClassUnitVersion.$inject = ["Core"];
function ProfileClassUnitVersion(Core) {
	return Core.service("/api/profile_class_unit_version");
}