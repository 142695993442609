import {Component} from "app/decorators";
import template from "./ezd-button-group-item.component.html";

@Component({
	selector: "ezdButtonGroupItem",
	template,
	bindings: {
		item: "<",
		selectedValue: "<",
		onSelect: "&"
	}
})
export class EzdButtonGroupItemComponent {
	/**
	 * Выбрасываем событи о выборе элемента
	 */
	select() {
		if (!this.item.isDisabled) {
			this.onSelect({$event: this.item});
		}
	}
}
