

angular
	.module("ezd.backend")
	.service("Interceptors", Interceptors);

Interceptors.$inject = ["$injector"];

/**
 * @desc Описываем перехватчики запросов
 * @param flashAlert
 * @param $injector
 * @returns {{error: error, fullRequest: fullRequest, responseInterceptor: responseInterceptor}}
 * @constructor
 */
function Interceptors($injector) {


	function getFlashAlert() {
		return $injector.has('flashAlert') ? $injector.get('flashAlert') : new Proxy({}, {get: function(){return function(){}}});
	}

	return {
		error,
		fullRequest,
		responseInterceptor
	};


	/**
	 * @desc Перезватчик ошибок
	 * @param res
	 * @param deferred
	 * @returns {boolean}
	 */
	function error(res, deferred) {
		const {config: {method, url}} = res;
		console.log(res);

		// костыль для косячного выставления статуса
		if (res.status === -1) {
			return true;
		}

		// перехват ошибок от ОПЭОМ
		if (/^(\/cms\/api)/i.test(url)) {
			/* if (/\/books/i.test(url)) {
				flashAlert.error("Доступ к ресурсам ОПЭОМ (учебники) временно недоступен. Попробуйте позже.");
			}

			if (/\/materials/i.test(url)) {
				flashAlert.error("Доступ к ресурсам ОПЭОМ (материалы) временно недоступен. Попробуйте позже.");
			}

			if (/\/logical_types/i.test(url)) {
				flashAlert.error("Доступ к ресурсам ОПЭОМ (материалы) временно недоступен. Попробуйте позже.");
			}*/

			return true;
		}

		// перехват ошибок от УРС на полезные ссылки
		if (/\/argus\/api\/useful_links/i.test(url)) {
			return true;
		}

		// исключаем ошибку при отсутствии групп, чтобы не показывать алёрт
		if (/\/schedule_items/i.test(url) && res.status === 400) {
			return true;
		}

		// перехват ошибки при сохранении календарно-поурочного плана
		if (/^(\/jersey\/api\/calendar_plans)/i.test(url) && method.toUpperCase() === "PUT") {
			return true;
		}

		if (/^(\/core\/api\/journals)/i.test(url) && method.toUpperCase() === "GET") {
			return true;
		}

		if (/^(\/core\/api\/bells_timetables)/i.test(url) && method.toUpperCase() === "POST") {
			return true;
		}

		// костыль для загрузки картинок для зданий
		if (_.includes(url, "image_building")) {
			return true;
		}

		if (/^(\/core\/api\/home_base_periods)/i.test(url) && method.toUpperCase() === "GET") {
			return true;
		}

		if (/^(\/core\/api\/ae_course_calendar_plans)/i.test(url) && method.toUpperCase() === "GET") {
			return true;
		}

		if (_.isObject(res.data) && res.data.description) { // обрабатываем ошибку в формате lms
			let errMsg = res.data.description + "\n";

			if (res.data.details && Object.keys(res.data.details).length > 0) {
				_.forIn(res.data.details, (key, value) => {
					errMsg += value + ": " + key.join(", ") + ";\n";
				});
			}
			getFlashAlert().error(errMsg);
		} else if (_.isObject(res.data) && res.data.message) { // jersey format
			getFlashAlert().error(res.data.message);
		} else {
			getFlashAlert().error(res.status + " " + res.statusText);
		}

		if (res.status === 401) {
			$injector.get("Sessions").drop();
			deferred.reject();

			return false;
		}

		return true;
	}


	/**
	 *
	 * @param element
	 * @param operation
	 * @returns {{headers: {Content-Type: string}, element: null}}
	 */
	function fullRequest(element, operation) {
		if (operation === "remove") {
			return {
				headers: {"Content-Type": "application/json;charset=utf-8"},
				element: null
			};
		}
	}


	/**
	 *
	 * @param data
	 * @param operation
	 * @param what
	 * @param url
	 * @param response
	 * @returns {*}
	 */
	function responseInterceptor(data, operation, what, url, response) {
		let $data = data;
		// бросим исключение, если у нас кривой ответ, чтобы всё легло сразу, не кэшируясь
		if ($data === "\"err\"") {
			console.error("Неверный формат ответа");
			throw new Error("Неверный формат ответа");
		}

		if (url.search("/css2/api/v1/") > -1) {
			return $data.elements;
		}

		// запрос на проверку возможности создания привязки к УП ученика возвращает ответ не в json
		if (url.search("/parallel_curriculum_assignments/check") > -1) {
			return {
				result: $data
			};
		}

		if (url.search("/logs") > -1 && url.search("/logs/count") === -1) {
			$data = $data.result.logs || [];
		}

		if (!_.isObject($data)) {
			$data = {
				body: $data
			};
		}

		$data.headers = response.headers;

		return $data;
	}

}
