import ezdBackend from "components/backend";


/**
 * @param Core
 */
class SystemMessagesService {
	static $inject = ["ACL"];

	constructor(ACL) {
		this.services = {ACL};
		this.url = "/api/system_messages";
		this.collection = ACL.all(this.url);
		this.current = null;
	}


	bindOne(id) {
		const {ACL} = this.services;

		return ACL.one(this.url, id);
	}


	getCurrentMain() {
		return this.$findMessage("main");
	}


	getCurrentWarning() {
		return this.$findMessage("warning");
	}

	getCurrentBanner() {
		return this.$findMessage("banner");
	}

	async $findMessage(categoryName) {
		await this.$getCurrent();

		const messages = _.filter(this.current, (msg) => msg.category === categoryName);
		const messageIndefinitely = _.find(messages, (msg) => {
			const {begin, indefinitely} = msg;
			const beginMoment = Boolean(begin) ? moment(begin) : null;

			if (!indefinitely || !Boolean(beginMoment)) {
				return false;
			}

			return indefinitely && (this.serverTime.isAfter(beginMoment) || this.serverTime.isSame(beginMoment));
		});
		const messageLimited = _.find(messages, (msg) => {
			const {begin, end, indefinitely} = msg;
			const beginMoment = Boolean(begin) ? moment(begin) : null;
			const endMoment = Boolean(end) ? moment(end) : null;

			if (indefinitely || !Boolean(beginMoment) || !Boolean(endMoment)) {
				return false;
			}

			return !indefinitely
				&& (this.serverTime.isAfter(beginMoment) || this.serverTime.isSame(beginMoment))
				&& (this.serverTime.isBefore(endMoment) || this.serverTime.isSame(endMoment));
		});

		return Boolean(messageLimited) ? messageLimited : messageIndefinitely;
	}


	async $getCurrent() {
		const {ACL} = this.services;
		const fullResponse = ACL.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true));

		if (this.getCurrentPromise && !Boolean(this.getCurrentPromise.$$state.status)) {
			this.current = await this.getCurrentPromise;

			return this.current;
		} else if (Boolean(this.current)) {
			return this.current;
		}

		this.getCurrentPromise = fullResponse.all(this.url).getList({
			today: true,
			published: true
		})
			.then((messages) => this.$mapMessages(messages));

		this.current = await this.getCurrentPromise;

		return this.current;
	}


	$mapMessages(messages) {
		this.serverTime = moment(messages.headers("server-time"));

		return messages.data;
	}
}

angular
	.module(ezdBackend)
	.service("SystemMessages", SystemMessagesService);
