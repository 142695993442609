import {Component} from "app/decorators/index";
import "./ezd-tabs.scss";
import {getPageString} from "components/backend/components/services/OrgLocalization";

import _guid from "tools/fp/guid";

@Component({
	selector: "ezdTabs",
	controllerAs: "ezdTabsCtrl",
	bindings: {
		model: "=?",
		onChange: "&?"
	}
})
export class EzdTabsComponent {
	static $inject = ["$scope", "$element"];
	static values = [];

	/**
	 * CONSTRUCTOR
	 */
	constructor($scope, $element) {
		this.services = {$scope, $element};
	}

	/**
	 * TemplateFunction
	 */
	static template(elem) {
		const head = elem.find("ezd-tabs-head");
		const tabs = _.reduce([...head.find("ezd-tabs-item")], (result, item) => {
			const value = item.getAttribute("ng-value");
			const disabled = item.getAttribute("ng-disabled");
			const disabledText = item.getAttribute("disabled-text");
			let globalStringText = "";
			if (item.hasAttribute("bind-global-string")) {
				const getGlobalString = getPageString.bind(undefined, "$global");
				globalStringText = getGlobalString(item.getAttribute("bind-global-string"));
			}
			const style = item.getAttribute("style");
			const inputId = _guid() + "_ezd_tabs_item";

			const itemTemplate = `
				<div class="ezd-tabs-item">
					<input type="radio"
			       id="${inputId}"
			       ng-value="${value}"
			       ng-model="ezdTabsCtrl.model"
			       ${item.hasAttribute("ng-disabled") ? `ng-disabled="${disabled}"` : ""}/>
					<label for="${inputId}" ${item.hasAttribute("style") ? `style="${style}"` : ""}>
						${globalStringText}
						${item.innerHTML}
						${item.hasAttribute("ng-disabled") ? `<md-tooltip ng-if="${disabled}">${disabledText}</md-tooltip>` : ""}
					</label>
				</div>
			`;

			return result + itemTemplate;
		}, "");

		const content = elem.find("ezd-tabs-content");
		const contentItems = _.reduce([...content.find("ezd-tabs-content-item")], (result, item) => {
			const value = item.getAttribute("ng-value");
			EzdTabsComponent.values.push(value);
			const contentTemplate = `
				<div ng-if="ezdTabsCtrl.model === ${value}">
					${item.innerHTML}
				</div>
			`;

			return result + contentTemplate;
		}, "");

		return `<section class="ezd-tabs">
			<header class="ezd-tabs-header">
					${tabs}
			</header>
			<main class="ezd-tabs-content">
					${contentItems}
			</main>
		</section>`;
	}


	/**
	 * OnDestroy
	 */
	$onDestroy() {
		this.destroyModelWatcher();
	}

	/**
	 * OnInit
	 */
	$onInit() {
		const {$scope} = this.services;

		this.destroyModelWatcher = $scope.$watch(() => this.model, (newVal, oldVal) => {
			if (newVal !== oldVal && Boolean(this.onChange)) {
				this.onChange({$event: this.model});
			}
		});

		this.model = this.model || _.int(EzdTabsComponent.values[0]) || EzdTabsComponent.values[0];
	}
}
