

angular
	.module("ezd.backend")
	.factory("$$mrko", mrko);


mrko.$inject = ["$q", "ACL", "$window", "flashAlert"];
function mrko($q, ACL, $window, flashAlert) {
	const service = ACL.service("/api/mrko/token");

	// defaults
	service.targetUrl = "https://mrko.permkrai.ru/dnevnik/services/index.php?token=";

	// bindings
	service.redirect = Redirect;

	return service;

	// implementation
	/**
	 * Перенаправляет пользователя на указанный адрес и возвращает полученный токен.
	 * @param  {String} url Адрес для перенаправления.
	 * @return {Object} Промис с полученным токеном для редиректа.
	 */
	function Redirect(url) {
		const $service = this;
		const $url = url ? url : this.targetUrl;
		const deferred = $q.defer();

		$service.getList()
			.then((res) => {
				if (res[0].token) {
					$window.open($url + res[0].token, "_blank");
					deferred.resolve(res[0].token);
				} else {
					flashAlert.error("К сожалению, переход в МРКО временно недоступен.");
					deferred.reject(res.plain());
				}
			});

		return deferred.promise;
	}
}

