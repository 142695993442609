

angular
	.module("ezd.backend")
	.service("$$controlForms", Service);


/**
 *
 * @param Core
 * @constructor
 */
Service.$inject = ["Core"];
function Service(Core) {
	const url = "/api/control_forms";
	const vm = this;

	vm.list = [];
	vm.collection = Core.all(url);
	vm.collection.getList = (query) => Core.all(url).getList(query);

	vm.bindOne = bindOne;
	vm.getTemplate = getTemplate;


	vm.getList = (query) => vm.collection.getList(query);


	/**
	 *
	 * @returns {{short_name: string, name: string, weight: number, is_exam: boolean, education_level_id: null, subject_id: null, school_id: null,
	 *     grade_system_id: null, origin_control_form_id: null}}
	 */
	function getTemplate() {
		return {
			short_name: "",
			name: "",
			weight: 1,
			is_exam: false,
			education_level_id: null,
			subject_id: null,
			school_id: null,
			grade_system_id: null,
			origin_control_form_id: null
		};
	}


	/**
	 *
	 * @param id
	 * @returns {{get}|*}
	 */
	function bindOne(id) {
		return Core.one(url, id);
	}
}

