

/**
 * НЕ форматируем этот файл, тут магия
 */

export const StringMask = (function () {
	const tokens = {
		0: {pattern: /\d/, _default: "0"},
		9: {pattern: /\d/, optional: true},
		"#": {pattern: /\d/, optional: true, recursive: true},
		S: {pattern: /[a-zA-Z]/},
		$: {escape: true}
	};
	const isEscaped = function (pattern, pos) {
		let count = 0;
		let i = pos - 1;
		let token = {escape: true};
		while (i >= 0 && token && token.escape) {
			token = tokens[pattern.charAt(i)];
			count += token && token.escape ? 1 : 0;
			i--;
		}

		return count > 0 && count % 2 === 1;
	};
	const calcOptionalNumbersToUse = function (pattern, value) {
		const numbersInP = pattern.replace(/[^0]/g, "").length;
		const numbersInV = value.replace(/[^\d]/g, "").length;

		return numbersInV - numbersInP;
	};
	const concatChar = function (text, character, options) {
		if (options.reverse) {
			return character + text;
		}

		return text + character;
	};
	var hasMoreTokens = function (pattern, pos, inc) {
		const pc = pattern.charAt(pos);
		const token = tokens[pc];
		if (pc === "") {
			return false;
		}

		return token && !token.escape ? true : hasMoreTokens(pattern, pos + inc, inc);
	};
	const insertChar = function (text, char, position) {
		const t = text.split("");
		t.splice(position >= 0 ? position : 0, 0, char);

		return t.join("");
	};
	var StringMask = function (pattern, opt) {
		this.options = opt || {};
		this.options = {
			reverse: this.options.reverse || false,
			usedefaults: this.options.usedefaults || this.options.reverse
		};
		this.pattern = pattern;

		StringMask.prototype.process = function proccess(value) {
			if (!value) {
				return "";
			}
			value = String(value);
			let pattern2 = this.pattern;
			let valid = true;
			let formatted = "";
			let valuePos = this.options.reverse ? value.length - 1 : 0;
			let optionalNumbersToUse = calcOptionalNumbersToUse(pattern2, value);
			let escapeNext = false;
			const recursive = [];
			let inRecursiveMode = false;

			const steps = {
				start: this.options.reverse ? pattern2.length - 1 : 0,
				end: this.options.reverse ? -1 : pattern2.length,
				inc: this.options.reverse ? -1 : 1
			};

			const continueCondition = function (options) {
				if (!inRecursiveMode && hasMoreTokens(pattern2, i, steps.inc)) {
					return true;
				} else if (!inRecursiveMode) {
					inRecursiveMode = recursive.length > 0;
				}

				if (inRecursiveMode) {
					const pc = recursive.shift();
					recursive.push(pc);
					if (options.reverse && valuePos >= 0) {
						i++;
						pattern2 = insertChar(pattern2, pc, i);

						return true;
					} else if (!options.reverse && valuePos < value.length) {
						pattern2 = insertChar(pattern2, pc, i);

						return true;
					}
				}

				return i < pattern2.length && i >= 0;
			};

			for (var i = steps.start; continueCondition(this.options); i = i + steps.inc) {
				const pc = pattern2.charAt(i);
				const vc = value.charAt(valuePos);
				const token = tokens[pc];
				if (!inRecursiveMode || vc) {
					if (this.options.reverse && isEscaped(pattern2, i)) {
						formatted = concatChar(formatted, pc, this.options);
						i = i + steps.inc;
						continue;
					} else if (!this.options.reverse && escapeNext) {
						formatted = concatChar(formatted, pc, this.options);
						escapeNext = false;
						continue;
					} else if (!this.options.reverse && token && token.escape) {
						escapeNext = true;
						continue;
					}
				}

				if (!inRecursiveMode && token && token.recursive) {
					recursive.push(pc);
				} else if (inRecursiveMode && !vc) {
					if (!token || !token.recursive) {
						formatted = concatChar(formatted, pc, this.options);
					}
					continue;
				} else if (recursive.length > 0 && token && !token.recursive) {
					// Recursive tokens most be the last tokens of the pattern
					valid = false;
					continue;
				} else if (!inRecursiveMode && recursive.length > 0 && !vc) {
					continue;
				}

				if (!token) {
					formatted = concatChar(formatted, pc, this.options);
					if (!inRecursiveMode && recursive.length) {
						recursive.push(pc);
					}
				} else if (token.optional) {
					if (token.pattern.test(vc) && optionalNumbersToUse) {
						formatted = concatChar(formatted, vc, this.options);
						valuePos = valuePos + steps.inc;
						optionalNumbersToUse--;
					} else if (recursive.length > 0 && vc) {
						valid = false;
						break;
					}
				} else if (token.pattern.test(vc)) {
					formatted = concatChar(formatted, vc, this.options);
					valuePos = valuePos + steps.inc;
				} else if (!vc && token._default && this.options.usedefaults) {
					formatted = concatChar(formatted, token._default, this.options);
				} else {
					valid = false;
					break;
				}
			}

			return {result: formatted, valid};
		};

		StringMask.prototype.apply = function (value) {
			return this.process(value).result;
		};

		StringMask.prototype.validate = function (value) {
			return this.process(value).valid;
		};
	};

	StringMask.process = function (value, pattern, options) {
		return new StringMask(pattern, options).process(value);
	};

	StringMask.apply = function (value, pattern, options) {
		return new StringMask(pattern, options).apply(value);
	};

	StringMask.validate = function (value, pattern, options) {
		return new StringMask(pattern, options).validate(value);
	};

	return StringMask;
}());


angular.module("ezd.common.ui")
	.directive("uiDateMask", () => {
		return {
			restrict: "A",
			require: "ngModel",
			link(scope, element, attrs, ctrl) {
				let dateFormat = attrs.uiDateMask || "DD.MM.YYYY",
					dateMask = new StringMask(dateFormat.replace(/[YMD]/g, "0"));

				function applyMask(value) {
					const cleanValue = value.replace(/[^0-9]/g, "");
					const formattedValue = dateMask.process(cleanValue).result || "";

					return formattedValue.trim().replace(/[^0-9]$/, "");
				}

				function formatter(value) {
					if (ctrl.$isEmpty(value)) {
						return value;
					}

					const formattedValue = applyMask(moment(value, dateFormat).format(dateFormat));
					validator(formattedValue);

					return formattedValue;
				}

				function parser(value) {
					if (ctrl.$isEmpty(value)) {
						validator(value);

						return value;
					}

					const formattedValue = applyMask(value);

					if (ctrl.$viewValue !== formattedValue) {
						ctrl.$setViewValue(formattedValue);
						ctrl.$render();
					}
					validator(formattedValue);

					return moment(formattedValue, dateFormat).format(dateFormat);
				}

				function validator(value) {
					const isValid = moment(value, dateFormat).isValid()
						&& value.length === dateFormat.length;
					ctrl.$setValidity("date", ctrl.$isEmpty(value) || isValid);
				}

				ctrl.$formatters.push(formatter);
				ctrl.$parsers.push(parser);
			}
		};
	});

angular.module("ezd.common.ui")
	.directive("uiTimeMask", () => {
		return {
			restrict: "A",
			require: "ngModel",
			link(scope, element, attrs, ctrl) {
				let timeFormat = "00:00:00";

				if (angular.isDefined(attrs.uiTimeMask) && attrs.uiTimeMask === "short") {
					timeFormat = "00:00";
				}

				const formattedValueLength = timeFormat.length;
				const unformattedValueLength = timeFormat.replace(":", "").length;
				const timeMask = new StringMask(timeFormat);

				function formatter(value) {
					if (ctrl.$isEmpty(value)) {
						return value;
					}

					const cleanValue = value.replace(/[^0-9]/g, "").slice(0, unformattedValueLength) || "";

					return (timeMask.apply(cleanValue) || "").replace(/[^0-9]$/, "");
				}

				ctrl.$formatters.push(formatter);

				ctrl.$parsers.push((value) => {
					if (ctrl.$isEmpty(value)) {
						return value;
					}

					const viewValue = formatter(value);
					const modelValue = viewValue;

					if (ctrl.$viewValue !== viewValue) {
						ctrl.$setViewValue(viewValue);
						ctrl.$render();
					}

					return modelValue;
				});

				ctrl.$validators.time = function (modelValue) {
					if (ctrl.$isEmpty(modelValue)) {
						return true;
					}

					const splittedValue = modelValue.toString().split(/:/).filter((v) => {
						return Boolean(v);
					});

					let hours = parseInt(splittedValue[0]),
						minutes = parseInt(splittedValue[1]),
						seconds = parseInt(splittedValue[2] || 0);

					return modelValue.toString().length === formattedValueLength
						&& hours < 24 && minutes < 60 && seconds < 60;
				};
			}
		};
	});


angular.module("ezd.common.ui")
	.directive("uiPhoneMask", () => {
		return {
			restrict: "A",
			require: "ngModel",
			link(scope, element, attrs, ctrl) {
				let phoneFormat = attrs.uiPhoneMask || "+0 (000) 000-00-00",
					phoneMask = new StringMask(phoneFormat);

				function applyMask(value) {
					const cleanValue = value.replace(/[^0-9]/g, "");
					const formattedValue = phoneMask.process(cleanValue).result || "";

					return formattedValue.trim().replace(/[^0-9]$/, "");
				}

				function formatter(value) {
					if (ctrl.$isEmpty(value)) {
						return value;
					}

					const formattedValue = applyMask(value);

					return formattedValue;
				}

				function parser(value) {
					if (ctrl.$isEmpty(value)) {
						return value;
					}

					const formattedValue = applyMask(value);

					if (ctrl.$viewValue !== formattedValue) {
						ctrl.$setViewValue(formattedValue);
						ctrl.$render();
					}

					return formattedValue.trim().replace(/[^0-9]/g, "");
				}

				ctrl.$formatters.push(formatter);
				ctrl.$parsers.push(parser);
			}
		};
	});
