

angular
	.module("ezd.backend")
	.service("$$academicYear", academicYearService);


academicYearService.$inject = ["Core", "$cookies", "$q", "$$calendar"];

/**
 *
 * @param Core
 * @param $cookies
 * @param $q
 * @param $$calendar
 */
function academicYearService(Core, $cookies, $q, $$calendar) {
	const url = "/api/academic_years";
	const vm = this;


	vm.list = [];
	vm.collection = Core.all(url);

	vm.bindOne = bindOne;
	vm.getList = getList;
	vm.getSelected = getSelected;
	vm.setSelected = setSelected;
	vm.$loadHolidays = $loadHolidays;

	vm.first_pilot_schools = _.range(1489, 1513);


	vm.second_pilot_schools = [];


	/**
	 *
	 * @param id
	 */
	function bindOne(id) {
		return Core.one(url, id);
	}


	/**
	 *
	 */
	function getList() {
		if (vm.list.length) {
			return $q((resolve) => resolve(vm.list));
		}

		return vm.collection
			.getList()
			.then((data) => {
				vm.list = data;

				return data;
			});
	}


	/**
	 *
	 */
	function getSelected() {
		const deferred = $q.defer();
		const aid = _.int($cookies.get("aid"));

		let t;
		let selectedYear;
		if (_.isEmpty(vm.list)) {
			t = setInterval(() => {
				if (!_.isEmpty(vm.list)) {
					clearInterval(t);
					selectedYear = aid ? _.find(vm.list, {id: aid}) : _.find(vm.list, {current_year: true});
					deferred.resolve(selectedYear || {});
				}
			}, 200);
		} else {
			selectedYear = aid ? _.find(vm.list, {id: aid}) : _.find(vm.list, {current_year: true});
			deferred.resolve(selectedYear || {});
		}

		return deferred.promise;
	}


	/**
	 * */
	function setSelected(year) {
		const deferred = $q.defer();

		if (year && !_.isEmpty(year)) {
			$cookies.put("aid", year.id);
			deferred.resolve(year || {});
		} else {
			deferred.reject({message: "Не передан academic_year"});
		}

		return deferred.promise;
	}

	/**
	 * Загрузка праздников для учебных годов
	 *
	 * @returns {*}
	 */
	function $loadHolidays(years) {
		const requests = [];

		// загружаем праздничные дни
		_.forEach(years, (year) => {
			const calendarId = _.get(year, "calendar_id", null);

			if (calendarId) {
				requests.push($$calendar.getYearCalendar(calendarId));
			}
		});

		// мапим праздничные дни на учебные года
		return $q
			.all(requests)
			.then((response) => {
				const allHollidays = _.chain(response)
					.flatten()
					.filter({is_holiday: true})
					.value();

				_.forEach(years, (year) => {
					year.holidays = _.filter(allHollidays, {calendar_id: year.calendar_id});
				});

				this.isLoading = false;
			});
	}
}

