angular
	.module("ezd.backend")
	.service("$$profile", profileService);

profileService.$inject = ["Core", "$cookies", "$injector", "$q", "ProfileRights", "$$teacherProfiles", "$$academicYear",
	"$$group", "$window", "$$userSelectProfile"];

const PRESCHOOL_CLASS_LEVEL_ID = 14;

/**
 *
 * @param args
 */
function profileService(...args) {
	const [Core, $cookies, $injector, $q, ProfileRights, $$teacherProfiles, $$academicYear,
		$$group, $window, $$userSelectProfile] = args;
	const url = "/api/profiles";
	const vm = this;

	vm.collection = Core.all(url);


	/**
	 * Список профилей пользователя
	 * @type {Array}
	 */
	vm.list = [];


	/**
	 * объект выбранного профиля пользователя
	 * @type {{}}
	 */
	vm.currentProfile = {};


	// методы для запроса данных с сервера
	vm.bindOne = bindOne;
	vm.getList = getList;
	vm.getPage = getPage;

	// методы для работы с профилем
	vm.getCurrentProfile = getCurrentProfile;
	vm.isSpo = isSpo;
	vm.setCurrentProfile = setCurrentProfile;
	vm.setDefaultProfile = setDefaultProfile;
	vm.castTypesToRoles = castTypesToRoles;
	vm.castTypesToRolesForProfile = castTypesToRolesForProfile;
	vm.drop = drop;

	vm.goToDefaultPageForProfile = goToDefaultPageForProfile;

	vm.setCookies = setCookies;

	/**
	 *
	 */
	function drop() {
		vm.currentProfile = {};
	}


	/**
	 * профили по страницам
	 * @param num
	 * @param filter
	 */
	function getPage(num, filter) {
		const result = {
			data: [],
			pages: 1
		};
		const rest = Core.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true));
		let params = {page: num, per_page: 50};

		if (_.keys(filter).length !== 0) {
			params = angular.extend(params, filter);
		}

		return rest.all(url)
			.getList(params)
			.then((response) => {
				result.data = response.data;
				result.pages = response.headers("X-Pagination-Total-Pages") || 1;
				result.total_entries = response.headers("X-Pagination-Total-Entries") || 0;

				return result;
			});
	}


	/**
	 * Получение профиля пользователя по его id
	 * @param id {Number}
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(url, id);
	}


	/**
	 * Получение списка профилей
	 * @param params {{}} объект с параметрами запроса {a:1, b:2 ...}
	 * @returns {*}
	 */
	function getList(params) {
		return vm.collection.getList(params);
	}


	/**
	 * Сохранение id и типа профиля в cookies
	 * @param profile
	 */
	function setCookies(profile) {
		$cookies.put("is_auth", true);
		$cookies.put("profile_id", profile.id);
		$cookies.put("profile_roles", profile.roles);

		if (profile.user && /^support/.test(profile.user.gusoev_login || profile.user.login || profile.user.email)) {
			$cookies.put("is_support", true);
		} else {
			$cookies.remove("is_support");
		}
	}


	/**
	 * Возвращает promise текущего профиля пользователя
	 * @returns {*}
	 */
	async function getCurrentProfile() {
		const deferred = $q.defer();
		if (_.isEmpty(vm.currentProfile)) {
			const t = setInterval(() => {
				if (!_.isEmpty(vm.currentProfile)) {
					clearInterval(t);
					deferred.resolve(vm.currentProfile);
				}
			}, 100);
		} else {
			deferred.resolve(vm.currentProfile);
		}

		return deferred.promise;
	}

	/**
	 * Проверяет, является ли текущий профиль - СПО
	 * @returns {boolean}
	 */
	function isSpo() {
		return this.getCurrentProfile().then((profile) => {
			return profile.org_type_id === 2;
		});
	}


	/**
	 * Сохраняет в сервис объект текущего профиля
	 * @param profileData
	 */
	async function setCurrentProfile(profileData) {
		try {
			if (!_.isEmpty(profileData)) {
				$$userSelectProfile.send(profileData);
				setCookies(profileData);
				await mapProfile(profileData);
				vm.currentProfile = profileData;
				// checkChangePasswordRequired();
			}
		} catch (e) {
			console.error(e);
		}

		return profileData || {};
	}

	// /**
	//  * Проверить, нужно ли сменить пароль
	//  */
	// async function checkChangePasswordRequired() {
	// 	await LockUser.checkChangePasswordRequired(vm.currentProfile);
	// }

	/**
	 *
	 * @param profile
	 * @returns {Promise.<Array>}
	 */
	async function getTeacherProfilesWithGroups(profile) {
		// дергаем профили учителей и группы, чтобы понять является ли профиль классруком и ведет ли он у начальных классов
		try {
			const teacherProfile = await $$teacherProfiles.bindOne(profile.id).get({
				academic_year_id: vm.currentYear.id,
				with_assigned_groups: true
			});

			if (teacherProfile) {
				const profileAssignedGroupIds = (teacherProfile.assigned_group_ids || []).join(",");

				if (profileAssignedGroupIds) {
					teacherProfile.assignedGroups = await $$group.getList({
						academic_year_id: vm.currentYear.id,
						group_ids: profileAssignedGroupIds
					});
				}
			}

			return teacherProfile;
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 * Выбирает профиль по-умолчанию из списка профилей
	 * и сохраняет его в local storage как текущий профиль.
	 *
	 * @param profileList [{}] массив профилей пользователя
	 * @returns {*}
	 */
	async function setDefaultProfile(profileList) {
		try {
			vm.currentYear = await $$academicYear.getSelected();
			const profiles = _.map(profileList, (profile) => {
				// набиваем в название школы данные для тех у кого их нету (для красоты)
				if (profile.roles && _.includes(profile.roles, "admin")) {
					profile.school_shortname = "Администратор";
				}

				if (profile.type === "admin_security") {
					profile.school_shortname = "Администратор ИБ";
				}

				if (profile.type === "observer") {
					profile.school_shortname = "Наблюдатель";
				}

				if (profile.type === "staff") {
					profile.school_shortname = "Тех. поддержка";
				}

				if (profile.roles && _.includes(profile.roles, "curator")) {
					profile.school_shortname = "Куратор";
				}
				if (profile.roles && _.includes(profile.roles, "medal_commission")) {
					profile.school_shortname = "Медальная комиссия";
				}
				if (!profile.school_shortname && profile.roles && _.includes(profile.roles, "parent")) {
					profile.school_shortname = "Родитель";
				}

				return profile;
			});

			vm.list = profiles;

			const selectedProfile = _.find(profiles, {id: _.int($cookies.get("profile_id"))});
			const isObserver = Boolean(_.find(vm.list, {type: "observer"}));
			const isStaff = Boolean(_.find(vm.list, {type: "staff"}));

			let defaultProfile
				= _.find(profiles, {type: "teacher"})
				|| _.find(profiles, {type: "admin"})
				|| _.find(profiles, {type: "curator"})
				|| _.find(profiles, {type: "medal_commission"});

			if (!defaultProfile) {
				defaultProfile = profiles[0];
			}

			if (!_.isEmpty(selectedProfile || defaultProfile) && $cookies.get("is_auth")) {
				if (isObserver) {
					selectedProfile.roles.push("school_observer");
				}

				if (isStaff) {
					selectedProfile.roles.push("school_staff");
				}

				return await setCurrentProfile(selectedProfile);
			}

			if (isObserver) {
				defaultProfile.roles.push("school_observer");
			}

			if (isStaff) {
				selectedProfile.roles.push("school_staff");
			}

			return await setCurrentProfile(defaultProfile)
				.then(goToDefaultPageForProfile);
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 *
	 * @param profile
	 * @returns {*}
	 */
	async function mapProfile(profile) {
		// проверка на саппорта
		try {
			if (!_.includes(["student", "parent", "admin_security", "observer", "staff", "admin", "curator", "medal_commission"], profile.type)) {
				const isObserver = Boolean(_.includes(profile.roles, "school_observer"));
				const teacherProfile = await getTeacherProfilesWithGroups(profile);
				if (teacherProfile) {
					_.assign(profile, teacherProfile.plain());
				}

				if ($cookies.get("is_support")) {
					profile.roles.push("support");
				}

				if (isObserver) {
					profile.roles.push("school_observer");
				}

				profile.roles = castTypesToRolesForProfile(profile);

				// проверка на классрука
				if (profile.managed_class_unit_ids && profile.managed_class_unit_ids.length) {
					profile.roles.push("mentor");
				}

				// проверка на учителя началки
				if (profile.assignedGroups && _.some(profile.assignedGroups, (group) => group.class_level_id <= 4 && group.class_level_id)) {
					profile.roles.push("teacher_primary");
				}

				await getProfileRights(profile);
			} else if (profile.type === "student") {
				const student = await getStudentProfile(profile);

				_.assign(profile, student);
				profile.roles = castTypesToRolesForProfile(profile);
			} else {
				profile.roles = castTypesToRolesForProfile(profile);
			}
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * загрузка ученика, для получения дополнительной информации из его профиля
	 * @param profile
	 * @returns {Promise<*>}
	 */
	async function getStudentProfile(profile) {
		return await Core.one("/api/student_profiles", profile.id).get();
	}


	/**
	 *
	 * @param profile
	 * @returns {Promise.<void>}
	 */
	async function getProfileRights(profile) {
		if (_.intersects(profile.roles, ["parent", "medal_commission"])) {
			profile.right_ids = [];

			return;
		}

		const rights = await ProfileRights.getList({profile_id: profile.id});
		profile.right_ids = _.map(rights[0].rights, "id");
		profile.roles.push(..._.map(profile.right_ids, (rightId) => `right:${rightId}`));
	}

	/**
	 *
	 * @param profiles
	 * @returns {*}
	 */
	function collapseAuthor(profiles) {
		const author = _.find(profiles, {type: "author"});
		let recipient;

		if (author) {
			recipient = _.find(profiles, (p) => p.school_id === author.school_id && p.type !== "author" && !_.isEmpty(p.roles));

			if (recipient) {
				recipient.author_id = author.id;
			}

			_.remove(profiles, {type: "author"});
		}

		return profiles;
	}

	/**
	 *
	 * @param profiles
	 */
	function castTypesToRoles(profiles = vm.list) {
		_.forEach(profiles, (profile) => castTypesToRolesForProfile(profile));
	}


	/**
	 *
	 * @param profile
	 */
	function castTypesToRolesForProfile(profile) {
		const typesToCast = ["student", "parent", "admin", "curator", "medal_commission", "admin_security", "observer", "staff"];

		if (profile && profile.type && _.includes(typesToCast, profile.type)) {
			if (profile.type === "staff") {
				profile.roles = ["super_staff"];
			} else {
				profile.roles = [profile.type];
			}
		}

		return profile ? profile.roles : [];
	}


	/**
	 * переход к странице по-умолчанию
	 * @param profile
	 */
	function goToDefaultPageForProfile(profile) {
		if (profile.type === "student" || profile.type === "parent") {
			$injector
				.get("$$studentProfiles")
				.getCurrentProfile()
				.then((studentProfile) => {
					// дневник ученика не доступен для дошкольника
					if (studentProfile.class_unit.class_level_id === PRESCHOOL_CLASS_LEVEL_ID) {
						return $window.location.assign("/desktop");
					}

					$window.location.assign("/student_diary/student_diary/" + studentProfile.id);
				});

			return;
		}

		if (_.includes(profile.roles, "teacher")) {
			$window.location.assign("/common/schedule");

			return;
		}

		if (_.includes(profile.type, "medal_commission")) {
			$window.location.assign("/medal_commission_mcko");

			return;
		}

		if (_.includes(profile.type, "admin_security")) {
			$window.location.assign("/administrator/security/authorized_users");

			return;
		}

		if (_.includes(["observer", "staff"], profile.type)) {
			$window.location.assign("/admin/schools");

			return;
		}

		$window.location.assign("/desktop");
	}
}

