angular
	.module("ezd.backend")
	.factory("TDAY", TDAY);


/**
 * @desc Создаем новый инстанс Restangular с конфигом для модуля TDAY
 * @param Restangular
 * @returns {*}
 * @constructor
 */
TDAY.$inject = ["$cookies", "Restangular", "Interceptors"];
function TDAY(Restangular) {
	return Restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setDefaultHeaders({Accept: "application/json"});
		RestangularConfigurer.setBaseUrl("/tday");
	});
}
