import {Component} from "app/decorators/index";
import template from "./caution.html";
import styles from "./caution.local.scss";

@Component({
	selector: "caution",
	template
})
export class CautionComponent {
	static $inject = ["$rootScope", "$location", "SystemMessages"];
	constructor($rootScope, $location, SystemMessages) {
		this.styles = styles;
		this.caution = null;
		this.cautionStyle = null;

		$rootScope.$on("$locationChangeSuccess", (event, newUrl) => {
			if ($location.path() === "/" || $location.path() === "/auth") {
				this.cautionStyle = this.$getStyle();
			} else {
				this.cautionStyle = null;
			}
		});

		if ($location.path() === "/" || $location.path() === "/auth") {
			this.cautionStyle = this.$getStyle();
		}

		SystemMessages.getCurrentWarning()
			.then((warningMsg) => {
				if (Boolean(warningMsg)) {
					this.caution = warningMsg;
				} else {
					this.caution = null;
				}
			});
	}

	$getStyle() {
		return {
			position: "fixed",
			left: "0",
			top: "0",
			width: "100%",
			"z-index": "10"
		};
	}
}
