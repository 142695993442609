import styles from "./ezd-alert-dialog.local.scss";
import {close} from "images/icons/index";
import error from "./ezd-alert-error.svg";
import success from "./ezd-alert-success.svg";
import warn from "./ezd-alert-warn.svg";

export class EzdAlertDialogController {
	static $inject = ["$mdDialog", "params", "$sce"];

	/**
	 * @param $mdDialog
	 * @param params
	 */
	constructor($mdDialog, params, $sce) {
		this.services = {$mdDialog, params, $sce};
		this.styles = styles;
		this.icons = {close, error, success, warn};
		this.params = params;
		this.init();
	}

	/**
	 *
	 */
	init() {
		const {$sce} = this.services;

		if (this.params.type === "success") {
			this.mainIcon = this.icons.success;
		}
		if (this.params.type === "error") {
			this.mainIcon = this.icons.error;
		}
		if (this.params.type === "warn") {
			this.mainIcon = this.icons.warn;
		}
		if (this.params.type === "info") {
			this.mainIcon = this.params.icon || this.icons.warn;
		}
		if (this.params.text) {
			this.params.text = $sce.trustAsHtml(this.params.text);
		}
	}

	/**
	 *
	 */
	confirm() {
		const {$mdDialog} = this.services;

		if (this.params.isIBClient) {
			window.open(this.params.downloadLink, "_blank");
		}

		$mdDialog.hide(true);
	}

	/**
	 *
	 */
	hide() {
		const {$mdDialog} = this.services;
		$mdDialog.hide(false);
	}

	/**
	 * Проверяем нажатую клавишу
	 **/
	checkKeyPressed(event) {
		if (event.keyCode === 13) {
			this.hide();
		}
	}
}
