import ezdBackend from "components/backend/index";
import fileUploadLink from "./components/file_upload_link/fileUploadLink";
import cgBusy from "./components/cgbusy/angular-busy";
import ezdDateRangePicker from "./components/ezd-date-range-picker/index";
import ezdMaskDatePicker from "./components/ezd-mask-date-picker/index";
import EzdAssignmentsTableModule from "./components/assignments-table/index";
import EzdMapAddressModule from "./components/ezd-map-address";
import EzdButtonGroupModule from "./components/ezd-button-group";

import "./components/ezd-toast/ezd-toast.min.css";
import "./components/ezd-toast/ezd-toast.min.js";

import {EventsTypesConst} from "components/events/events-types.const";
import {EzdCheckboxComponent} from "./components/ezd_checkbox/ezd-checkbox.component";
import {AjaxLoaderComponent} from "./components/ajax_loader/ajax-loader.component";
// import {UrlsListConst} from "components/common/components/ezd-demo-screen-switch/urls-list.const";
import ezdProviders from "components/common/components/$router/$router";

export default angular
	.module("ezd.common.ui", [
		ezdBackend, ezdProviders, fileUploadLink, cgBusy, ezdDateRangePicker, ezdMaskDatePicker, "alert", EzdAssignmentsTableModule,
		EzdMapAddressModule, EzdButtonGroupModule
	])
	.component(EzdCheckboxComponent.selector, EzdCheckboxComponent)
	.component(AjaxLoaderComponent.selector, AjaxLoaderComponent)
	.constant("EVENTS_TYPES", EventsTypesConst.controller)
	// .constant("URLS_LIST", UrlsListConst.controller)
	.value("cgBusyDefaults", cgBusyDefaults())
	.name;

/**
 * Рекурсивно реквайрим все .js файлы из папок и подпапок
 */
function requireAll(r) {
	r.keys().map(r);
}

requireAll(require.context("./components", true, /\.js$/));


/**
 *
 * @returns {{message: string, delay: number}}
 */
function cgBusyDefaults() {
	return {
		message: "Подождите, пожалуйста...",
		delay: 0
	};
}
