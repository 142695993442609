import {Component} from "app/decorators/component";
import template from "./file-upload-link.template.html";

import find from "lodash/fp/find";
import includes from "lodash/fp/includes";
import _toLower from "lodash/fp/toLower";

import styles from "./file-upload-link.local.scss";

@Component({
	selector: "fileUploadLink",
	template,
	bindings: {
		uploadResponse: "=?",
		configUpload: "=?",
		progress: "=?",
		requestFields: "=?",
		disabled: "=?",
		afterLoaded: "&",
		url: "@",
		baseApi: "@",
		color: "@" // цвет овверайтится из-за https://github.com/chromium/chromium/commit/5c56063a3a156531e2e368047bec5cfd48b2ff21
	},
	transclude: true
})
export class FileUploadLinkComponent {
	static $inject = ["$rootScope", "$scope", "$cookies", "Upload", "EzdAlertDialog"];

	constructor($rootScope, $scope, $cookies, Upload, EzdAlertDialog) {
		this.services = {$rootScope, $scope, $cookies, Upload, EzdAlertDialog};
		this.inputId = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
		this.styles = styles;
	}

	$onChanges() {
		const {$cookies} = this.services;

		switch (this.baseApi) {
			case "jersey":
				this.baseUrl = "jersey/api/";
				break;
			case "reports":
				this.baseUrl = "reports/api/";
				break;
			case "ec":
				this.baseUrl = "ec/api/";
				break;
			default:
				this.baseUrl = "core/api/";
				break;
		}

		this.url = this.url || "attachments";

		if (_.isUndefined(this.disabled)) {
			this.disabled = false;
		}

		this.upConfig = {
			url: this.baseUrl + this.url, // upload url
			callbackWithResponse: true,
			acceptData: [], // accept file mime types ('image/*, application/pdf', 'audio/*', 'video/*', '.pdf,.jpg')
			headers: {
				"Profile-Id": $cookies.get("profile_id"),
				"Auth-Token": $cookies.get("auth_token"),
				Accept: "application/json"
			},
			sendFieldsAs: "form",
			sizeLimit: { // ограничения на размеры файла в байтах
				min: 0, // bytes
				max: 104857600 // bytes (default 100 Mb)
			},
			nameMaxLenght: 200
		};
		// дополняем конфиг из параметров

		angular.extend(this.upConfig, this.configUpload);

		this.upConfig.acceptString = "'" + this.upConfig.acceptData.join(",") + "'";
		if (this.upConfig.captcha && this.upConfig.captcha.base_id) {
			this.upConfig.url += "/" + this.upConfig.captcha.base_id;
		}
		this.$setErrorMessages();
	}


	$setErrorMessages() {
		this.fileSizeErrorMsg = "Превышен допустимый размер файла. Максимальный размер загружаемого файла " + this.$normalizeFileSize(this.upConfig.sizeLimit.max);
		this.formatErrorMsg = "Формат прикрепляемого файла должен быть ограничен следующими вариантами:" + this.upConfig.acceptData.join(", ");
		this.nameMaxLenghtErrorMsg = "Имя файла не может содержать более " + this.upConfig.nameMaxLenght + " символов";
	}


	/**
	 * Загрузка файлов на сервер
	 * @param files {[]} массив файлов для загрузки
	 */
	upload(files) {
		const {$rootScope, Upload, EzdAlertDialog} = this.services;
		console.log(files);
		if (this.upConfig.acceptData.length) {
			// const sizeLimitErrorMsg = "Размер прикрепляемого файла должен быть ограничен ";

			const hasNoAcceptableFiles = find((file) => {
				return !includes(this.$getFileExtension(file.name), this.upConfig.acceptData);
			})(files);

			const hasLongNameFiles = find((file) => {
				return file.name.length > this.upConfig.nameMaxLenght;
			})(files);

			if (hasNoAcceptableFiles || hasLongNameFiles) {
				EzdAlertDialog.error({
					parent: _.get(this.configUpload, "fileUploadMessagesParent", angular.element(document.body)),
					title: "Загрузка файлов",
					text: hasNoAcceptableFiles ? this.formatErrorMsg : this.nameMaxLenghtErrorMsg
				});

				return;
			}
		}

		this.query = "?";

		this.requestFields = this.requestFields || {};
		_.forOwn(this.requestFields, (field, key) => {
			this.query += key + "=" + (field ? field.toString() : "");
			this.query += "&";
		});

		this.query = _.trimEnd(this.query, "&");

		if (files && files.length) {
			for (let i = 0; i < files.length; i++) {
				$rootScope.global_promise = Upload
					.upload({
						url: this.upConfig.url + this.query,
						file: files[i],
						headers: this.upConfig.headers,
						callbackWithResponse: true,
						fields: this.java ? null : angular.extend({file_name: files[i].name}),
						sendFieldsAs: "form"
					})
					.progress((evt) => {
						// прогрес загрузки
						if (this.progress !== undefined) {
							this.progress = _.int(100.0 * evt.loaded / evt.total);
						}
					})
					.success((data) => {
						if (this.uploadResponse !== undefined) {
							this.uploadResponse = data.result || data;
						}

						if (this.upConfig.callbackWithResponse === true) {
							this.afterLoaded({
								response: data.result || data
							});
						} else {
							this.afterLoaded();
						}
					})
					.error((data) => {
						data.isError = true;
						this.uploadResponse = data.result || data;
					});
			}
		}
	}

	$getFileExtension(filename) {
		const ext = `.${filename.split(".").pop()}`;

		return _toLower(ext);
	}

	/**
	 *
	 * @param file
	 * @returns {string}
	 */
	$normalizeFileSize(size) {
		const sizeInKB = (size / 1024).toFixed(2);

		if (sizeInKB > 1024) {
			return `${(sizeInKB / 1024).toFixed(2)} MB`;
		}

		return `${sizeInKB} KB`;
	}
}
