

angular
	.module("ezd.backend")
	.service("$$reportJournalCheck", reportJournalCheckService);


/**
 *
 * @param REP
 */
reportJournalCheckService.$inject = ["REP"];
function reportJournalCheckService(REP) {
	const url = "api/journal_check";
	const vm = this;

	vm.getJson = getJson;

	function getJson(params) {
		return REP.one(url, "json").get(params);
	}
}