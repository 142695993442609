import {Component} from "app/decorators/index";
import template from "./edit-in-place.html";

/**
 * Спан, который при клике превращается в инпут
 *
 * Bindings:
 * @param value
 * @param input-class
 * @param span-class
 * @param cb - callback.
 * @param maxlength
 * @param placeholder
 * @param parser - $parser, который определяет как значения из view будут записаны в модель.
 * @param clear-on-focus - очищать ли поле ввода при фокусе.
 *
 */
@Component({
	selector: "editInPlace",
	template,
	bindings: {
		value: "=",
		inputClass: "@",
		spanClass: "@",
		cb: "&",
		maxlength: "@",
		placeholder: "@",
		parser: "&?",
		clearOnFocus: "<"
	}
})
class EditInPlaceComponent {
	static $inject = ["$element"];

	/**
	 *
	 * @param $element
	 */
	constructor($element) {
		this.$element = $element;

		// Initially, we"re not editing.
		this.editing = false;
	}


	/**
	 * activate edit-in-place
	 */
	beginEdit() {
		this.oldModelValue = this.value;
		this.editing = true;

		// We control display through a class on the directive itself. See the CSS.
		this.$element.addClass("active");

		// And we must focus the element.
		// `angular.element()` provides a chainable array, like jQuery so to access a native DOM function,
		// we have to reference the first element in the array.
		this.inputElement[0].focus();
		if (Boolean(this.clearOnFocus)) {
			this.inputElement.val("");
		}
	};

	/**
	 * deactivate edit-in-place
	 */
	endEdit(noCallBack) {
		this.editing = false;
		this.$element.removeClass("active");
		if (!noCallBack) {
			this.cb();
		}
	};

	$postLink() {
		// Let"s get a reference to the input element, as we"ll want to reference it.
		this.inputElement = angular.element(this.$element.children()[1]);

		// This directive should have a set class so we can style it.
		this.$element.addClass("edit-in-place");

		this.$element[0].tabIndex = "0";

		// When we leave the input, we"re done editing.
		this.$element[0].addEventListener("focus", () => {
			this.beginEdit();
		});
	}
}


/**
 *
 * @returns {{restrict: string, require: [string,string], link: (function(*, *, *, *))}}
 */
function editInPlaceFormatParse() {
	return {
		restrict: "A",
		require: ["ngModel", "^editInPlace"],
		link(scope, element, attrs, controllers) {
			const ngModel = controllers[0];
			const editInPlace = controllers[1];
			let isEscHandled;

			ngModel.$render = () => {
				element.val(ngModel.$modelValue);
			};

			ngModel.$parsers.push((value) => {
				if (editInPlace.parser) {
					return editInPlace.parser({value});
				}

				return value;
			});

			// When we leave the input, we"re done editing.
			element[0].addEventListener("blur", () => {
				if (ngModel.$modelValue === editInPlace.oldModelValue) {
					isEscHandled = true;
				}

				if (!isEscHandled) {
					ngModel.$render();
					editInPlace.endEdit();
				} else {
					restoreOldValue();
				}
				isEscHandled = false;

				if (!scope.$$phase) {
					scope.$apply();
				}
			});

			element[0].addEventListener("keydown", (e) => {
				switch (e.keyCode) {
					case 13:
						if (!Boolean(ngModel.$viewValue)) {
							isEscHandled = true;
						}
						element[0].blur();
						break;
					case 27:
						isEscHandled = true;
						element[0].blur();
						break;
				}
				if (!scope.$$phase) {
					scope.$apply();
				}
			});


			/**
			 * Восстанавливаем старое значение
			 */
			function restoreOldValue() {
				editInPlace.value = editInPlace.oldModelValue;
				ngModel.$render();
				editInPlace.endEdit(true);
			}
		}
	};
}

angular
	.module("ezd.common.ui")
	.component(EditInPlaceComponent.selector, EditInPlaceComponent)
	.directive("editInPlaceFormatParse", editInPlaceFormatParse);

