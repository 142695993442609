import {Constant} from "app/decorators/constant";

@Constant("GUARDS")
export class GuardsConst {
	STUDENT_OR_PARENT_NOT_FROM_PRESCHOOL ="$STUDENT_OR_PARENT_NOT_FROM_PRESCHOOL";
	GENERAL_EDUCATION_MENTOR ="$GENERAL_EDUCATION_MENTOR";
	PARENT_NOT_FROM_PRESCHOOL ="$PARENT_NOT_FROM_PRESCHOOL";
	PARENT_FROM_PRESCHOOL ="$PARENT_FROM_PRESCHOOL";
	ADMIN_OR_BEGIN_CLASS_LEVELS_MENTOR ="$ADMIN_OR_BEGIN_CLASS_LEVELS_MENTOR";
	PARENT_OF_BEGIN_CLASS_LEVEL_STUDENT ="$PARENT_OF_BEGIN_CLASS_LEVEL_STUDENT";
	PARENT_OR_STUDENT_WITH_PREVIOUS_PROFILE ="$PARENT_OR_STUDENT_WITH_PREVIOUS_PROFILE";
}
