angular
	.module("ezd.backend")
	.factory("AEScheduleItemComments", aeScheduleItemComments);

/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeScheduleItemComments.$inject = ["Core"];
function aeScheduleItemComments(Core) {
	return Core.service("/api/ae_schedule_item_comments");
}

