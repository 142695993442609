

angular
	.module("ezd.backend")
	.service("$$reportFinalMarks", reportFinalMarks);


/**
 *
 * @param REP
 */
reportFinalMarks.$inject = ["REP"];

/**
 *
 * @param REP
 */
function reportFinalMarks(REP) {
	const url = "api/final_marks";
	const fileInfoUrl = "api/final_marks/xlsx/info";
	const vm = this;

	vm.getJson = getJson;
	vm.getFileInfo = getFileInfo;

	/**
	 * Загрузка данных для отчета в json
	 * @param params {{}}
	 * {ACADEMIC_YEAR_ID} - учебный год
	 * {CLASS_UNIT_ID} - id класса
	 * {IS_YEAR_MARK} - годовая оценка? true/false
	 * {MARK_TYPE} - тип оценки half_year_first,half_year_last,exam,attestation
	 * {PERIOD_ID} - id учебного периода
	 * {ATTESTATION_PERIOD_ID} - id аттестационного периода
	 * {MODULE_ID} - id модуля
	 * {WITH_DELETED} - флаг, для фильтрациии удаленных учеников
	 * @returns {*}
	 */
	function getJson(params) {
		return REP.one(url, "json").get(params);
	}

	/**
	 * Получение размеров .xlsx файлов
	 * @param {object} params data
	 */
	function getFileInfo(params) {
		return REP.one(fileInfoUrl).get(params);
	}
}

