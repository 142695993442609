angular
	.module("ezd.backend")
	.service("$$reportPreschoolEducation", Service);


Service.$inject = ["REP"];

function Service(REP) {
	const url = "api/preschool_present";
	const self = this;

	self.getAttendPreschoolEducation = getAttendPreschoolEducation;

	function getAttendPreschoolEducation()	{
		return REP.one(url, "json").get();
	}
}
