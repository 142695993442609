const URL = "/api/ex_mentors";

import _compact from "lodash/fp/compact";
import _findIndex from "lodash/fp/findIndex";

class ExMentors {
	static $inject = ["Core", "$$teacherProfiles", "$$academicYear"];

	/**
	 * Constructor
	 * @param Core
	 * @param $$teacherProfiles
	 * @param $$academicYear
	 */
	constructor(Core, $$teacherProfiles, $$academicYear) {
		this.services = {$$teacherProfiles, $$academicYear};
		this.CoreService = Core;

		// устанавливаем интерцептор ответа, чтобы избежать ошибки в случае ответа вида [null]
		this.collection = Core.withConfig((RestangularConfigurer) => {
			RestangularConfigurer.setFullResponse(true);
			RestangularConfigurer.addResponseInterceptor((data) => {
				return _compact(data);
			});
		}).all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.CoreService.one(URL, id);
	}

	/**
	 * Получить данные о классе за предыдущий год
	 * */
	async getPreviousYearData(classUnit) {
		const {$$teacherProfiles, $$academicYear} = this.services;
		let res = null;

		const selectedYear = await $$academicYear.getSelected();
		const years = await $$academicYear.getList();
		const selectedYearIx = _findIndex({id: selectedYear.id})(years);
		const previousYear = years[selectedYearIx - 1];

		if (previousYear) {
			try {
				const previousYearData = await this.collection.getList({
					class_unit_name: classUnit.name,
					academic_year_id: previousYear.id
				});
				const previousClassUnit = previousYearData.data[0];
				if (previousClassUnit) {
					const mentor = await $$teacherProfiles.bindOne(previousClassUnit.teacher_id).get();

					res = {
						classUnit: previousClassUnit,
						mentor
					};
				}
			} catch (err) {
				console.error(err);
			}
		}

		return res;
	}
}


angular
	.module("ezd.backend")
	.service("ExMentors", ExMentors);

