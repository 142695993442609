/**
 * Тема календарно-тематического плана вд
 */
class ECCourseCalendarTopic {
	/**
	 * Возвращает шаблон тематического плана вд
	 */
	getTemplate() {
		return {
			name: "", // название модуля
			ordinal: 1, // порядок модулей в плане
			is_test_planned: false, // флаг, определяющий запланирована ли промежуточная аттестация для этой темы
			lessons: [] // массив ECCourseCalendarLesson
		};
	}
}


angular
	.module("ezd.backend")
	.service("ECCourseCalendarTopic", ECCourseCalendarTopic);
