angular
	.module("ezd.backend")
	.factory("Messages", messages);

messages.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {*|Object}
 */
function messages(Core) {
	const url = "/api/messages";
	const vm = this;

	Core.extendModel(url, (model) => {
		model.isReadBy = isReadBy;

		return model;
	});

	const Service = Core.service(url);
	Service.getUnreadCount = getUnreadCount;


	/**
	 *
	 * @returns {Promise.<*>}
	 */
	async function getUnreadCount() {
		try {
			if (_.has(vm, "unreadCount")) {
				Service.unreadCount = vm.unreadCount;
			}

			const res = await Service.one("count").get();
			vm.unreadCount = _.has(res, "body") ? _.int(res.body) : res.count;

			Service.unreadCount = vm.unreadCount;
		} catch (e) {
			console.error(e);
		}
	}

	return Service;
}


/**
 *
 * @param id
 */
function isReadBy(id) {
	return _.includes(this.read_by, id);
}
