

angular
	.module("ezd.backend")
	.service("$$parallelCurricula", $$parallelCurricula);

$$parallelCurricula.$inject = ["Jersey", "$q"];

/**
 * Учебный план параллели
 * @param Jersey
 * @param $q
 */
function $$parallelCurricula(Jersey, $q) {
	const url = "/api/parallel_curricula";
	const vm = this;
	let abort = $q.defer();


	vm.list = [];
	vm.collection = Jersey.all(url).withHttpConfig({timeout: abort.promise});


	/**
	 *
	 * @param classUnitId
	 */
	vm.unlinkClassUnit = (classUnitId) =>
		Jersey
			.all(`${url}/unlink`)
			.remove({class_unit_id: classUnitId});


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) =>
		Jersey
			.one(url, id)
			.get()
			.then((data) => {
				if (data && data.subjects) {
					Jersey.restangularizeCollection(data, data.subjects, "subjects");
				}

				return data;
			});


	/**
	 *
	 * @param studentProfile
	 * @returns {*}
	 */
	vm.getCurriculaForStudent = (studentProfile) =>
		Jersey
			.one(url, studentProfile.curricula.id)
			.get({student_profile_id: studentProfile.id});


	/**
	 *
	 */
	vm.abortAll = () => {
		abort.resolve();
		abort = $q.defer();
		vm.collection = Jersey.all(url).withHttpConfig({timeout: abort.promise});
	};


	/**
	 *
	 * @param curricula
	 * @returns {*}
	 */
	vm.recountWeeks = (curricula) => {
		curricula.weeks_hours = _.clone(curricula.subjects[0].loads);

		return curricula;
	};

	/**
	 * Пересчет нагрузки по предмету
	 * @param planId
	 * @param subjectId
	 * @returns {*|Array}
	 */
	vm.recalculateHoursForSubject = (planId, subjectId) =>
		Jersey
			.one("api/parallel_curricula", planId)
			.one("subjects", subjectId)
			.all("recalculate_hours")
			.post({});
}
