
angular
	.module("ezd.backend")
	.service("$$storage", $$storage);

$$storage.$inject = ["$$filters"];
/**
 * $$storage - обертка над $$filters
 */
function $$storage($$filters) {
	const vm = this;

	vm.bind = bind;
	vm.get = get;
	vm.set = set;
	vm.clear = clear;
	vm.clearExcept = clearExcept;

	// Коллекция с pageName и binds
	vm.map = [];

	vm.initValue = "!null";

	/**
	 * bind
	 */
	function bind(pageName, names, dropPage = false) {
		vm.map[pageName] = [];
		const binds = vm.map[pageName];

		if (names && names.length) {
			_.forEach(names, (name) => {
				if (!_.find(binds, {name})) {
					const bindEntry = {
						name,
						bind: $$filters.getSetPageFilterValue.bind(this, pageName, name)
					};
					binds.push(bindEntry);
				}
			});

			$$filters.addPage(pageName, names, vm.initValue, dropPage);
		}
	}

	/**
	 * get
	 */
	function get(pageName) {
		const binds = vm.map[pageName];

		const result = {};
		if (binds && binds.length) {
			_.forEach(_.map(binds, "name"), (name) => {
				const bindEntry = _.find(binds, {name});
				if (bindEntry) {
					const value = bindEntry.bind();
					result[name] = value && value !== vm.initValue ? JSON.parse(value) : null;
				}
			});
		}
		console.log({...result});

		return result;
	}

	/**
	 * set
	 */
	function set(pageName, values) {
		const binds = vm.map[pageName];

		if (binds && binds.length) {
			_.forEach(Object.keys(values), (name) => {
				const bindEntry = _.find(binds, {name});
				const value = values[name];
				if (bindEntry && !angular.isUndefined(value)) {
					bindEntry.bind(value ? JSON.stringify(value) : vm.initValue);
				}
			});
		}
	}

	/**
	 * clear
	 */
	function clear(pageName, names) {
		const binds = vm.map[pageName];

		if (binds && binds.length) {
			_.forEach(names || _.map(binds, "name"), (name) => {
				const bindEntry = _.find(binds, {name});
				if (bindEntry) {
					bindEntry.bind(vm.initValue);
				}
			});
		}
	}

	/**
	 * clearExcept
	 */
	function clearExcept(pageName, exceptNames) {
		const binds = vm.map[pageName];

		if (binds && binds.length) {
			const names = _.map(binds, "name");
			_.forEach(_.filter(names, (name) => !_.includes(exceptNames, name)), (name) => {
				const bindEntry = _.find(binds, {name});
				if (bindEntry) {
					bindEntry.bind(vm.initValue);
				}
			});
		}
	}
}
