angular
	.module("ezd.backend")
	.factory("AEFields", aeFields);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
aeFields.$inject = ["Core"];
function aeFields(Core) {
	return Core.service("/api/ae_fields");
}
