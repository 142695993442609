angular
	.module("ezd.common.ui")
	.directive("schedulesDateNav", Directive);

/**
 *
 * @returns {{require: string, scope: {url: string, defaultSchedule: string}, controller: Controller, controllerAs: string, template: string}}
 * @constructor
 */
function Directive() {
	const directive = {
		require: "datePicker",
		scope: {
			url: "@",
			defaultSchedule: "=",
			endLimit: "=?"
		},
		controller: Controller,
		controllerAs: "scheduleNav",
		template
	};

	return directive;
}


/**
 * TemplateFunction
 * @param elem
 * @param attrs
 */
function template(elem, attrs) {
	const {endLimit} = attrs;

	return `
			<date-picker ng-if="scheduleNav.schedules.length > 0"
				selected="scheduleNav.selectedDate"
				academic-year-limit="true"
				${Boolean(endLimit) ? "end-limit=\"scheduleNav.endLimit\"" : ""}
				is-weekly="true">
			</date-picker>
		`;
}


/**
 *
 * @param $location
 * @param $scope
 * @param $$academicYear
 * @param $$schedules
 * @constructor
 */
Controller.$inject = ["$location", "$scope", "$$academicYear", "$$schedules"];
function Controller($location, $scope, $$academicYear, $$schedules) {
	const scheduleNav = this;

	scheduleNav.selectedDate = "";
	scheduleNav.schedules = [];
	scheduleNav.academicYear = {};
	scheduleNav.endLimit = $scope.endLimit;

	init();

	function init() {
		$$academicYear
			.getSelected()
			.then((academicYear) => {
				scheduleNav.academicYear = academicYear;

				return $$schedules.getSchedules(scheduleNav.academicYear);
			})
			.then((schedules) => {
				// собираем недели учебного года и шедули вместе
				setSchedules(schedules);
				// Устанавливаем дату календаря, соответствующую дате начала недели редактируемого шедуля
				setEditedSchedule();
				// ждем смены даты в datepicker
				$scope.$watch(() => scheduleNav.selectedDate,
					(newValue, oldValue) => {
						const newWeek = moment(newValue, "DD.MM.YYYY").startOf("week");
						const oldWeek = moment(oldValue, "DD.MM.YYYY").startOf("week");

						if (!newWeek.isSame(oldWeek)) {
							gotToSchedule(getWeekByDate(newValue));
						}
					});

				$scope.$watch(() => $scope.endLimit,
					(newValue) => {
						scheduleNav.endLimit = newValue;
					});
			});
	}

	/**
	 *
	 * @param schedules [{}]
	 */
	function setSchedules(schedules) {
		const scheduleByDate = [];
		let weekNumber;
		const academicYearStart = moment(scheduleNav.academicYear.begin_date, "YYYY-MM-DD").startOf("week");
		const academicYearEnd = moment(scheduleNav.academicYear.end_date, "YYYY-MM-DD").endOf("day");
		const weeksInAcademicYear = academicYearEnd.diff(academicYearStart, "weeks") + 1; // количество недель в учебном году

		_.times(weeksInAcademicYear, (n) => {
			weekNumber = n + 1;
			scheduleByDate.push({
				weekStart: moment(academicYearStart).startOf("week"),
				weekEnd: moment(academicYearStart).endOf("week"),
				weekNumber,
				schedule: _.find(schedules, {week_number: weekNumber})
			});
			academicYearStart.add(1, "week");
		});

		scheduleNav.schedules = scheduleByDate;
	}

	function setEditedSchedule() {
		if (!$scope.defaultSchedule) {
			return;
		}
		const scheduleId = _.int($scope.defaultSchedule.id);
		const week = _.find(scheduleNav.schedules, (scheduleWeek) => {
			return (scheduleWeek.schedule && scheduleWeek.schedule.id === scheduleId);
		});
		if (week !== undefined) {
			scheduleNav.selectedDate = week.weekStart.format("DD.MM.YYYY");
		}
	}

	/**
	 * возвращает неделю, которая соотвествует выбранной в календаре дате
	 * @param date {string}
	 */
	function getWeekByDate(date) {
		// так как работаем с датами, а не со временем,
		// то устанавливаем время отличное от начала и конца дня,
		// чтобы упростить проверку, исключив moment.isSame()
		const checkedDate = moment(date, "DD.MM.YYYY").hours(11).minutes(0);
		//

		return _.find(scheduleNav.schedules, (weekSchedule) => {
			return checkedDate.isBetween(weekSchedule.weekStart, weekSchedule.weekEnd);
		});
	}

	/**
	 * Переход к редактированию шедуля
	 * @param scheduleWeek {{}}
	 */
	function gotToSchedule(scheduleWeek) {
		if (!scheduleWeek) {
			return;
		}
		// если для выбранной недели есть шедуль, то переходим к редактирвоанию шедуля
		if (scheduleWeek.schedule) {
			$location.path($scope.url + "/" + scheduleWeek.schedule.id);
		}
		// если для выбранной недели нет шедуля, то пробуем создать его
		else {
			// createSchedule(scheduleWeek)
			$$schedules.createSchedule(scheduleNav.academicYear, scheduleWeek.weekNumber)
				.then((schedule) => {
					// переходим к редактированию шедуля
					scheduleWeek.schedule = schedule;
					$location.path($scope.url + "/" + scheduleWeek.schedule.id);
				});
		}
	}
}

