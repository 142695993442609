import {Module} from "app/decorators/index";

import {AssignmentsTableHeaderComponent} from "./assignments-table-header/assignments-table-header.component";
import {AssignmentsTableHeaderCellComponent} from "./assignments-table-header-cell/assignments-table-header-cell.component";
import {AssignmentsTableRowComponent} from "./assignments-table-row/assignments-table-row.component";
import {AssignmentCheckboxComponent} from "./assignment-checkbox/assignment-checkbox.component";
import {GroupStudentsModalComponent} from "./group-students-modal/group-students-modal.component";

@Module({
	name: "ezd.common.ui.assignments-table",
	imports: [],
	declarations: [
		AssignmentsTableHeaderComponent,
		AssignmentsTableHeaderCellComponent,
		AssignmentsTableRowComponent,
		AssignmentCheckboxComponent,
		GroupStudentsModalComponent
	],
	providers: []
})
export default class EzdAssignmentsTableModule {}
