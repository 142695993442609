import styles from "./map-popup.local.scss";
import {removeNew} from "images/icons";
import _guid from "tools/fp/guid";

export class MapPopup {
	static $inject = ["address", "$mdDialog", "$scope", "$timeout", "YandexMaps"];

	/**
	 * CONSTRUCTOR
	 */
	constructor(address, $mdDialog, $scope, $timeout, YandexMaps) {
		this.services = {$mdDialog, $scope, $timeout, YandexMaps};
		this.styles = styles;
		this.icons = {close: removeNew};
		this.mapId = _guid();
		this.placemark = null;
		this.address = address;

		YandexMaps.loadScript()
			.then((ymaps) => $timeout(() => {
				this.$createMap(ymaps);
			}, 200));
	}


	/**
	 * Реджектим mdDialog
	 */
	cancel() {
		const {$mdDialog} = this.services;

		$mdDialog.cancel();
	}


	/**
	 * Резолвим mdDialog
	 */
	save() {
		const {$mdDialog} = this.services;

		$mdDialog.hide({
			address: this.address
		});
	}


	/**
	 * Удаляем адрес
	 */
	removeAddress() {
		this.address = null;

		if (Boolean(this.placemark)) {
			this.placemark.events.remove("dragend", this.$changeAddress);
			this.map.geoObjects.remove(this.placemark);
			this.placemark = null;
		}
	}


	/**
	 * Создаем карту
	 */
	$createMap(ymaps) {
		const {YandexMaps} = this.services;
		const searchControl = new ymaps.control.SearchControl({
			options: {
				noPlacemark: true
			}
		});
		const config = {
			center: [55.76, 37.64],
			zoom: 9,
			controls: ["zoomControl", "geolocationControl", searchControl]
		};

		this.map = YandexMaps.createMap(this.mapId, config);

		if (Boolean(this.address)) {
			YandexMaps.getCoordsByAddress(this.map, this.address)
				.then((placemark) => {
					this.placemark = placemark;
					this.map.geoObjects.add(this.placemark);

					// Слушаем событие окончания перетаскивания на метке.
					this.placemark.events.add("dragend", this.$changeAddress.bind(this));
				});
		} else {
			YandexMaps.goToGeolocation(this.map);
		}

		this.map.events.add("click", (event) => {
			const coords = event.get("coords");

			if (Boolean(this.placemark)) {
				this.placemark.geometry.setCoordinates(coords);
			} else {
				this.placemark = YandexMaps.createPlacemark(coords);
				this.map.geoObjects.add(this.placemark);

				// Слушаем событие окончания перетаскивания на метке.
				this.placemark.events.add("dragend", this.$changeAddress.bind(this));
			}

			this.$changeAddress();
		});
	}


	/**
	 * Callback on change address
	 */
	$changeAddress() {
		const {$scope, YandexMaps} = this.services;

		YandexMaps.getAddress(this.placemark)
			.then((address) => {
				this.address = address;
				$scope.$apply();
			});
	}
}
