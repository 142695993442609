angular
	.module("ezd.backend")
	.factory("SchoolSettings", SchoolSettings);

/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
function SchoolSettings(Core) {
	const service = Core.service("/api/school_settings");

	service.getList = getList;

	/**
	 *
	 * @returns {Promise<*>}
	 */
	async function getList() {
		if (_.has(service, "list")) {
			return service.list;
		}

		service.list = await Core.all("/api/school_settings").getList();

		return service.list;
	}

	return service;
}

SchoolSettings.$inject = ["Core"];

