angular
	.module("ezd.backend")
	.service("$$educationLevelBase", $$educationLevelBase);


/**
 *
 * @param Core
 */
$$educationLevelBase.$inject = ["Core"];
function $$educationLevelBase(Core) {
	const url = "/api/education_level_base";
	const vm = this;

	vm.list = [];
	vm.collection = Core.all(url);
}
