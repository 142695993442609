export const USER_RELATION_TYPES = {
	1: "Отец",
	2: "Мать",
	3: "Законный представитель",
	4: "опекун",
	5: "попечитель",
	6: "представитель органа опеки и попечительства",
	7: "доверенный представитель",
	8: "не указан на портале"
};
