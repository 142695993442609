/**
 *
 * @type {[*]}
 */
export const LAZY = [


	// Личные сообщения
	{
		name: "ezd.new_messages",
		path: "new_messages",
		routes: ["/new_messages", "/new_messages/new", "/new_messages/history", "/new_messages/:id", "/new_messages/edit/:id"]
	},

	// manage
	{name: "ezd.manage.journal", path: "manage/journal", routes: ["/manage/myjournals", "/manage/aejournal", "/reports/external_materials"]},
	{name: "ezd.manage.new_journal", path: "manage/new_journal", routes: ["/manage/journals", "/manage/journal"]},
	{
		name: "ezd.manage.lesson_plans",
		path: "manage/lesson_plans",
		routes: ["/manage/lesson_plans", "/manage/lesson_plans/edit/:id", "/manage/lesson_plans/create"]
	},
	{
		name: "ezd.manage.new_lesson_plans",
		path: "manage/new_lesson_plans",
		routes: ["/manage/new_lesson_plans", "/manage/new_lesson_plans/edit/:id", "/manage/new_lesson_plans/create"]
	},
	{
		name: "ezd.manage.system_templates",
		path: "manage/system_templates",
		routes: ["/manage/system_templates", "/manage/system_templates/edit/:id", "/manage/system_templates/create"]
	},
	{
		name: "ezd.reports.olympiads", path: "reports/olympiads", routes: ["/olympiads"]
	},
	{
		name: "ezd.manage.calendar_plans",
		path: "manage/calendar_plans",
		routes: ["/manage/calendar_plans", "/manage/calendar_plans/edit/:id"]
	},
	{
		name: "ezd.manage.new_calendar_plans",
		path: "manage/new_calendar_plans",
		routes: ["/manage/new_calendar_plans", "/manage/new_calendar_plans/edit/:id"]
	},
	{name: "ezd.manage.test_lessons", path: "manage/test_lessons", routes: ["/manage/test_lessons"]},
	// {name: "ezd.manage.homeworks", path: "manage/homeworks", routes: ["/manage/hw", "/manage/hw/edit/:id", "/manage/hw/create"]},
	{name: "ezd.manage.homeworks", path: "manage/homeworks", routes: ["/manage/hw"]},
	{
		name: "ezd.manage.new_homeworks",
		path: "manage/new_homeworks",
		routes: ["/manage/_hw", "/manage/_hw/edit/:id", "/manage/_hw/create"]
	},
	{name: "ezd.manage.student_homeworks", path: "manage/student_homeworks", routes: ["/manage/student_homeworks"]},
	{
		name: "ezd.manage.preschool",
		path: "manage/preschool",
		routes: ["/manage/preschool_journal", "/manage/preschool_journals"]
	},
	{name: "ezd.manage.final_attestation", path: "manage/final_attestation", routes: ["/manage/final_attestation_form"]},
	{name: "ezd.manage.nonattendance", path: "manage/nonattendance", routes: ["/manage/attendances_journal"]},
	{
		name: "ezd.manage.student_journal",
		path: "manage/student_journal",
		routes: ["/manage/student_journal", "/manage/student_journal/:id"]
	},
	{name: "ezd.manage.periods_status", path: "manage/periods_status", routes: ["/manage/periods_status"]},
	{
		name: "ezd.manage.ae_lesson_plans",
		path: "manage/ae_lesson_plans",
		routes: ["/manage/ae_lesson_plans", "/manage/ae_lesson_plans/edit/:id", "/manage/ae_lesson_plans/create"]
	},
	{name: "ezd.manage.ae_journals", path: "manage/ae_journals", routes: ["/manage/aejournals"]},
	{name: "ezd.manage.student_bag", path: "manage/student_bag", routes: ["/manage/student_bag"]},
	{name: "ezd.manage.student_schedule", path: "manage/student_schedule", routes: ["/manage/student_schedule"]},
	{
		name: "ezd.manage.visiting_preschool",
		path: "manage/visiting_preschool",
		routes: ["/manage/visiting_preschool/:id"]
	},
	{
		name: "ezd.manage.test_analysis",
		path: "manage/test_analysis",
		routes: ["/manage/test_analysis", "/reports/test_analysis"]
	},

	// study_plan
	{
		name: "ezd.study_plan.parallel_curricula", path: "study_plan/parallel_curricula",
		routes: ["/study_plan", "/study_plan/templates", "/study_plan/edit/:id", "/study_plan/create", "/study_plan/create_template"]
	},
	{
		name: "ezd.study_plan.teacher_to_group",
		path: "study_plan/teacher_to_group",
		routes: ["/study_plan/teachertogroup"]
	},
	{
		name: "ezd.study_plan.students_to_groups",
		path: "study_plan/students_to_groups",
		routes: ["/study_plan/students_to_groups"]
	},
	{
		name: "ezd.study_plan.students_to_groups_new",
		path: "study_plan/student_to_groups-new",
		routes: ["/study_plan/_students_to_groups"]
	},
	{
		name: "ezd.study_plan.student_to_groups",
		path: "study_plan/student_to_groups",
		routes: ["/study_plan/student_to_groups/:id"]
	},
	{
		name: "ezd.study_plan.e_cards",
		path: "study_plan/e_cards",
		routes: ["/study_plan/e_cards/:id"]
	},
	{
		name: "ezd.refs.additional_info",
		path: "refs/additional_info",
		routes: ["/refs/additional_info/:id"]
	},
	{
		name: "ezd.study_plan.student_to_curricula",
		path: "study_plan/student_to_curricula",
		routes: ["/study_plan/student_to_curricula/:id"]
	},
	{name: "ezd.study_plan.homebased", path: "study_plan/homebased", routes: ["/study_plan/homebased/:id"]},
	{name: "ezd.study_plan.groups_subjects", path: "study_plan/groups_subjects", routes: ["/study_plan/groups_subjects"]},
	{
		name: "ezd.study_plan.student_to_plan",
		path: "study_plan/student_to_plan",
		routes: ["/study_plan/addstudenttoplan", "/study_plan/class_unit_curricula"]
	},
	{
		name: "ezd.study_plan.replacements",
		path: "study_plan/replacements",
		routes: ["/study_plan/replacements", "/study_plan/replacements/create"]
	},
	{
		name: "ezd.study_plan.all_schedule_teacher",
		path: "study_plan/all_schedule_teacher",
		routes: ["/study_plan/scheduleteacher"]
	},
	{
		name: "ezd.study_plan.schedule_calc",
		path: "study_plan/schedule_calc",
		routes: ["/study_plan/schedule_calc"]
	},
	{name: "ezd.study_plan.add_parallel", path: "study_plan/add_parallel", routes: ["/study_plan/addparallel/:id"]},
	{name: "ezd.study_plan.teacher_load", path: "study_plan/teacher_load", routes: ["/study_plan/teacherload"]},
	{name: "ezd.study_plan.ae_plan", path: "study_plan/ae_plan", routes: ["/study_plan/ae_plan/:id"]},
	{
		name: "ezd.connected_par_cur",
		path: "study_plan/connected_par_cur",
		routes: ["/study_plan/connected_par_cur", "/study_plan/connected_par_cur/edit/:educationLevelId"]
	},

	// admin
	// {name: "ezd.admin.$route", path: "admin/$route", routes: ["/admin"]},
	{name: "ezd.admin.marks", path: "admin/marks", routes: ["/admin/marks"]},
	{name: "ezd.admin.config", path: "admin/config", routes: ["/admin/config"]},
	{name: "ezd.admin.config", path: "admin/config", routes: ["/admin/config"]},
	{name: "ezd.admin.homeworks", path: "admin/homeworks", routes: ["/admin/homeworks"]},
	{name: "ezd.admin.logs", path: "admin/logs", routes: ["/admin/logs"]},
	{name: "ezd.admin.school_settings", path: "admin/school_settings", routes: ["/admin/school_settings/marks_fixation"]},
	{name: "ezd.admin.reports_settings", path: "admin/reports_settings", routes: ["/admin/reports_settings"]},
	{name: "ezd.admin.privacy", path: "admin/privacy", routes: ["/privacy"]},
	{
		name: "ezd.admin.final_marks_transfer",
		path: "admin/pills/final_marks_transfer",
		routes: ["/admin/pills/final_marks_transfer"]
	},
	{name: "ezd.admin.system_users", path: "admin/system_users", routes: ["/admin/system_users"]},
	{name: "ezd.admin.schools", path: "admin/schools", routes: ["/admin/schools"]},
	{name: "ezd.admin.draft_calculation", path: "admin/draft_calculation", routes: ["/admin/draft_calculation"]},
	{name: "ezd.admin.users", path: "admin/users", routes: ["/admin/users"]},
	{name: "ezd.admin.news_categories", path: "admin/news_categories", routes: ["/admin/news_categories"]},
	{name: "ezd.admin.system_messages", path: "admin/system_messages", routes: ["/admin/system_messages"]},
	{
		name: "ezd.admin.calendar_transpositions",
		path: "admin/calendar-transpositions",
		routes: ["/admin/calendar_transpositions"]
	},

	// refs
	{name: "ezd.refs.staff", path: "refs/staff", routes: ["/refs/staff", "/refs/staff/edit/:id", "/refs/staff/create"]},
	{
		name: "ezd.refs._staff",
		path: "refs/new_staff",
		routes: [
			"/refs/_staff_list",
			"/refs/_staff_list_login",
			"/refs/_staff_list/teacher_profile/:teacherId",
			"/refs/_staff_list/teacher_profile_edit/:teacherId"
		]
	},

	{name: "ezd.refs.students", path: "refs/students", routes: ["/refs/students", "/refs/students/edit/:id"]},
	{
		name: "ezd.refs.new_study_periods", path: "refs/new_study_periods",
		routes: ["/refs/study_periods", "/refs/study_periods/edit/:id", "/refs/study_periods/create"]
	},
	{name: "ezd.refs.parents", path: "refs/parents", routes: ["/parents/edit/:id"]},
	{name: "ezd.refs.registry.staff", path: "refs/registry/staff", routes: ["/refs/registry_staff"]},
	{name: "ezd.refs.registry.students", path: "refs/registry/students", routes: ["/refs/registry_students"]},

	{
		name: "ezd.refs.new_controlforms",
		path: "refs/new_controlforms",
		routes: [
			"/refs/_controlforms",
			"/refs/_controlforms/subject/:subjectId",
			"/refs/_controlforms/subject/:subjectId/form/create",
			"/refs/_controlforms/subject/:subjectId/form/:formId",
			"/refs/_controlforms/subject/:subjectId/form/:formId/copy"
		]
	},

	{
		name: "ezd.refs.controlforms",
		path: "refs/old_controlforms",
		routes: ["/refs/controlforms"]
	},

	{
		name: "ezd.refs.timetables",
		path: "refs/timetables",
		routes: ["/refs/timetables", "/refs/timetables/view", "/refs/timetables/editor"]
	},
	{
		name: "ezd.refs.new_buildings",
		path: "refs/new_buildings",
		routes: ["/buildings", "/buildings/edit/:id", "/buildings/list", "/buildings/info/:id", "/buildings/create", "/buildings/room_edit/:id",
			"/buildings/room_create", "/buildings/city_buildings", "/buildings/cgu_buildings", "/buildings/city_buildings/categories",
			"/buildings/city_buildings/:id"]
	},
	{
		name: "ezd.refs.city-buildings",
		path: "refs/city-buildings",
		routes: [
			"/city_buildings",
			"/city_buildings/list",
			"/city_buildings/create",
			"/city_buildings/edit/:id"
		]
	},
	{
		name: "ezd.refs._timetables",
		path: "refs/new_timetables",
		routes: ["/refs/_timetables", "/refs/_timetables/edit/:id", "/refs/_timetables/create", "/refs/_timetables/templates"]
	},
	{name: "ezd.refs.subjects", path: "refs/subjects", routes: ["/refs/_subjects"]},
	{
		name: "ezd.refs.new_subjects",
		path: "refs/new_subjects",
		routes: ["/refs/subjects", "/refs/subjects/create", "/refs/subjects/edit/:id"]
	},
	{name: "ezd.refs.student_timetables", path: "refs/student_timetables", routes: ["/refs/student_timetables"]},
	{
		name: "ezd.refs.ae_class_units",
		path: "refs/ae_class_units",
		routes: ["/refs/ae_class_units", "/refs/ae_class_units/:ids"]
	},
	{name: "ezd.refs.ae_control_forms", path: "refs/ae_control_forms", routes: ["/refs/ae_control_forms"]},
	{
		name: "ezd.refs.backpack",
		path: "refs/backpack",
		routes: ["/refs/backpack", "/refs/backpack/books-list", "/refs/backpack/stationery-list", "/refs/backpack/backpack-weight"]
	},
	{
		name: "ezd.refs.mentoring",
		path: "refs/mentoring",
		routes: ["/refs/mentoring"]
	},

	// planning
	{
		name: "ezd.planning.ae_parallel_curricula",
		path: "additional_education/planning/ae_parallel_curricula",
		routes: [
			"/planning/ae_parallel_curricula",
			"/planning/ae_parallel_curricula/create"
		]
	},
	{
		name: "ezd.refs._ae_lesson_plans",
		path: "additional_education/planning/ae_lesson_plans",
		routes: [
			"/planning/ae_lesson_plans",
			"/planning/ae_lesson_plans/edit/:programId"
		]
	},


	// progress
	{name: "ezd.progress.all_marks", path: "progress/all_marks", routes: ["/progress/all_marks"]},
	{name: "ezd.progress.dynamic", path: "progress/dynamic", routes: ["/progress/dynamic"]},
	{name: "ezd.progress.final_marks", path: "progress/final_marks", routes: ["/progress/final_marks"]},


	// schedules
	{name: "ezd.schedules.class", path: "schedules/class", routes: ["/schedules/class/:id"]},
	{name: "ezd.schedules.class_teacher", path: "schedules/class_teacher", routes: ["/schedules/class_teacher/:id"]},

	{
		name: "ezd.nonattendances_notifications",
		path: "nonattendances_notifications",
		routes: ["/nonattendances_notifications/parent"]
	},
	{name: "ezd.schedules.new_teacher", path: "schedules/new_teacher", routes: ["/schedules/teacher/:id"]},
	{name: "ezd.schedules.rooms", path: "schedules/rooms", routes: ["/schedules/rooms/:id"]},

	// принятие решений
	{
		name: "ezd.decisions",
		path: "decisions",
		routes: [
			"/decisions/students_without_curricula", "/decisions/journal_checks", "/decisions/rejected_test_lessons", "/decisions/integrator",
			"/decisions/reported_messages"
		]
	},
	{
		name: "ezd._decisions",
		path: "new_decisions",
		routes: [
			"/_decisions/bars",
			"/_decisions/rows",
			"/_decisions/homework_changes",
			"/_decisions/mark_changes",
			"/_decisions/missing_marks",
			"/_decisions/missing_topics",
			"/_decisions/wrong_schedule",
			"/_decisions/schedule_calc",
			"/_decisions/new_staff",
			"/_decisions/new_students",
			"/_decisions/bad_messages",
			"/_decisions/rejected_control_works",
			"/_decisions/journal_check",
			"/_decisions/left_students",
			"/_decisions/left_staff",
			"/_decisions/change_class",
			"/_decisions/classes_without_timetable",
			"/_decisions/groups_without_teacher",
			"/_decisions/missing_period_marks",
			"/_decisions/unrecognized_school_attendance_marks"
		]
	},

	// portfolio
	{name: "ezd.portfolio", path: "portfolio", routes: ["/portfolio/student", "/portfolio/manage/:id"]},

	// личный кабинет
	{name: "ezd.cabinet", path: "cabinet", routes: ["/cabinet"]},

	// // index
	{name: "ezd.index", path: "index", routes: ["/desktop", "/"]},
	{name: "ezd.index", path: "palette", routes: ["/palette"]},
	{name: "ezd.common.ui", path: "common/components/ezd-support-form", routes: ["/support-form"]},


	{
		name: "ezd.certificate.school_meta_info",
		path: "certificate/school_meta_info",
		routes: ["/certificate/school_meta_info"]
	},
	{name: "ezd.certificate.subjects", path: "certificate/subjects", routes: ["/certificate/subjects"]},
	{name: "ezd.certificate.marks", path: "certificate/marks", routes: ["/certificate/marks"]},
	{name: "ezd.certificate.print", path: "certificate/print", routes: ["/certificate/print"]},
	{name: "ezd.certificate.numbers", path: "certificate/numbers", routes: ["/certificate/numbers"]},
	{
		name: "ezd.certificate.certificate_hold",
		path: "certificate/certificate_hold",
		routes: ["/certificate/certificate_hold"]
	},
	{
		name: "ezd.certificate.duplicate_registry",
		path: "certificate/duplicate_registry",
		routes: [
			"/certificate/duplicate_registry/list",
			"/certificate/duplicate_registry/edit/:id",
			"/certificate/duplicate_registry/create",
			"/certificate/duplicate_registry/print"
		]
	},
	{
		name: "ezd.certificate.exam_marks_sheet",
		path: "certificate/final-marks-sheet",
		routes: [
			"/certificate/final_marks_sheet"
		]
	},

	{name: "ezd.mentor.students", path: "mentor/students", routes: ["/mentor/students"]},
	{name: "ezd.mentor._students", path: "mentor/new_students", routes: ["/mentor/_students"]},
	{name: "ezd.mentor.journals", path: "mentor/journals", routes: ["/mentor/journals"]},
	{
		name: "ezd.mentor.schedule",
		path: "mentor/schedule",
		routes: [
			"/mentor/schedule",
			"/mentor/schedule/class/:classId/level/:levelId/week/:week"
		]
	},
	// {name: "ezd.mentor.parents", path: "mentor/parents", routes: ["/mentor/parents"]},

	// {name: "ezd.organizer", path: "organizer", routes: ["/feed"]},

	{name: "ezd.widgets.test_lessons", path: "widgets/test_lessons", routes: ["/widgets/test_lessons"]},
	{
		name: "ezd.widgets.news",
		path: "widgets/news",
		routes: [
			"/widgets/news", "/widgets/news/:id", "/widgets/news/list", "/widgets/news/create", "/widgets/news/edit/:id",
			"/widgets/news/urs_list", "/widgets/news/urs_view/:id"
		]
	},
	{name: "ezd.widgets.replacements", path: "widgets/replacements", routes: ["/widgets/replacements"]},
	{name: "ezd.widgets.nonattendances", path: "widgets/nonattendances", routes: ["/common/nonattendances"]},
	{name: "ezd.widgets.schedule", path: "widgets/schedule", routes: ["/common/schedule"]},
	{name: "ezd.widgets.teachers", path: "widgets/teachers", routes: ["/common/teachers"]},

	{name: "ezd.medal_commission", path: "medal_commission", routes: ["/medal_commission"]},
	{
		name: "ezd.medal_commission.mcko_statement",
		path: "new_medal_commission/mcko_statement",
		routes: ["/medal_commission_mcko"]
	},
	{
		name: "ezd.medal_commission.school_statement",
		path: "new_medal_commission/school_statement",
		routes: ["/medal_commission_school"]
	},
	{
		name: "ezd.medal_commission.gmc_statement",
		path: "new_medal_commission/gmc_statement",
		routes: ["/medal_commission_gmc"]
	},

	{name: "ezd.outter", path: "outter", routes: ["/outter/links"]},


	/**
	 * Доп образование
	 */

	// refs
	{
		name: "ezd.ae.refs.groups_programs_association",
		path: "additional_education/refs/groups_programs_association",
		routes: ["/ae/refs/groups_programs_association"]
	},
	{
		name: "ezd.ae.refs.study_periods",
		path: "additional_education/refs/_study_periods",
		routes: ["/ae/refs/_study_periods", "/ae/refs/_study_periods/edit/:id", "/ae/refs/_study_periods/create"]
	},
	{
		name: "ezd.ae.refs.groups_teachers_places",
		path: "additional_education/refs/groups_teachers_places",
		routes: ["/ae/refs/groups_teachers_places"]
	},
	{
		name: "ezd.ae.refs.final_attestation", path: "additional_education/refs/final_attestation",
		routes: [
			"/ae/refs/final_attestation/forms",
			"/ae/refs/final_attestation/forms/programs",
			"/ae/refs/final_attestation/forms/groups",
			"/ae/refs/final_attestation/periods"
		]
	},
	{
		name: "ezd.ae.refs.control_forms",
		path: "additional_education/refs/control_forms",
		routes: [
			"/ae/refs/control_forms",
			"/ae/refs/control_forms/program/:id",
			"/ae/refs/control_forms/template/:id",
			"/ae/refs/control_forms/templates_list",
			"/ae/refs/control_forms/program/:id/create",
			"/ae/refs/control_forms/template/:id/create",
			"/ae/refs/control_forms/program/:id/form/:formId",
			"/ae/refs/control_forms/template/:id/form/:formId",
			"/ae/refs/control_forms/copy_program"
		]
	},
	// journal
	{
		name: "ezd.ae.journal",
		path: "additional_education/journal",
		routes: [
			"/ae/journal",
			"/ae/journal/list",
			"/ae/journal/my_list",
			"/ae/journal/program/:programId",
			"/ae/journal/program/:programId/groups/:groupId"
		]
	},
	// planning
	{
		name: "ezd.ae.planning.parallel_curricula",
		path: "additional_education/planning/parallel_curricula",
		routes: [
			"/ae/planning/parallel_curricula",
			"/ae/planning/parallel_curricula/create"
		]
	},
	{
		name: "ezd.ae.schedules", path: "additional_education/schedules",
		routes: [
			"/ae/schedules", "/ae/schedules/list", "/ae/schedules/tiles", "/ae/schedules/edit/:id",
			"/ae/schedules/edit/:id/groups", "/ae/schedules/edit/:id/teachers", "/ae/schedules/edit/:id/rooms"
		]
	},

	/**
	 * Внеурочная деятельность
	 */
	{
		name: "ezd.ec.planning", path: "extracurricular_activities/programs",
		routes: [
			"/ec/planning",
			"/ec/planning/programs",
			"/ec/planning/program/create",
			"/ec/planning/program/:id",
			"/ec/planning/program/:programId/plan/:planId",
			"/ec/planning/groups_assignments"
		]
	},
	{
		name: "ezd.schedules.ec",
		path: "schedules/ec",
		routes: ["/schedules/ec"]
	},
	{
		name: "ezd.refs.ec_control_forms",
		path: "refs/ec_control_forms",
		routes: ["/refs/ec_control_forms"]
	},
	{
		name: "ezd.refs.ec_forms",
		path: "refs/ec_forms",
		routes: ["/refs/ec_forms"]
	},
	{
		name: "ezd.ec.journal", path: "extracurricular_activities/journal",
		routes: [
			"/ec/journal",
			"/ec/journal/list",
			"/ec/journal/my_journals",
			"/ec/journal/activity/:activityId",
			"/ec/journal/activity/:activityId/groups/:groupId"
		]
	},
	{
		name: "ezd.ec.schedules", path: "extracurricular_activities/schedules",
		routes: [
			"/ec/schedules", "/ec/schedules/tiles", "/ec/schedules/list", "/ec/schedules/edit/:id",
			"/ec/schedules/edit/:id/class_units"
		]
	},
	{
		name: "ezd.ec.groups_formation", path: "extracurricular_activities/groups_formation",
		routes: ["/ec/planning/groups_formation"]
	},

	{
		name: "ezd.ec.refs.new_control_forms",
		path: "extracurricular_activities/refs/new-control-forms",
		routes: [
			"/refs/_ec_control_forms",
			"/refs/_ec_control_forms/list",
			"/refs/_ec_control_forms/ec_field/:ecFieldId/ec_form/:ecFormId",
			"/refs/_ec_control_forms/ec_field/:ecFieldId/ec_form/:ecFormId/ec_form/:ecFormId/create",
			"/refs/_ec_control_forms/ec_field/:ecFieldId/ec_form/:ecFormId/edit/:ecControlFormId",
			"/refs/_ec_control_forms/ec_field/:ecFieldId/ec_form/:ecFormId/copy/:ecControlFormId"
		]
	},

	/**
	 * Академическая задолженность
	 */
	{
		name: "ezd.academic_debt",
		path: "academic_debt",
		routes: [
			"/academic_debt/list"
		]
	},

	/**
	 * Мероприятия
	 */
	{
		name: "ezd.events", path: "events",
		routes: ["/events", "/events/_feed", "/events/editor/:id", "/events/_feed/list", "/events/_feed/calendar"]
	},
	{
		name: "ezd.ec.refs.forms", path: "extracurricular_activities/forms",
		routes: [
			"/ec/refs/forms"
		]
	},

	{
		name: "ezd.external-materials", path: "external-materials",
		routes: ["/reports/external_materials/:groupId/results"]
	},


	/**
	 * Отчеты
	 */
	{
		name: "ezd.ec.reports.plan", path: "extracurricular_activities/reports/plan",
		routes: [
			"/ec/reports/plan", "/ec/reports/plan/view"
		]
	},
	{name: "ezd.reports.finalMarks", path: "reports/finalMarks", routes: ["/reports/finalmarks"]},
	{name: "ezd.reports.newFinalMarks", path: "reports/final_marks", routes: ["/reports/_finalmarks"]},
	{name: "ezd.reports.homework", path: "reports/homework", routes: ["/reports/homework", "/reports/homework/:id"]},
	{name: "ezd.reports.progress", path: "reports/progress", routes: ["/reports/progress", "/reports/progress/:id"]},
	{name: "ezd.reports.students_quality", path: "reports/students_quality", routes: ["/reports/students_quality"]},
	{name: "ezd.reports.without_curricula", path: "reports/without_curricula", routes: ["/reports/without_curricula"]},
	{
		name: "ezd.reports.class_san_pin_schedule",
		path: "reports/class_san_pin_schedule",
		routes: ["/reports/class_san_pin_schedule"]
	},
	{name: "ezd.reports.user_activity", path: "reports/user_activity", routes: ["/reports/user_activity"]},
	{name: "ezd.reports.reliable_school", path: "reports/reliable_school", routes: ["/reports/reliable_school"]},
	{name: "ezd.reports.bag_pack", path: "reports/bag_pack", routes: ["/reports/bag_pack"]},
	{name: "ezd.reports.bag_pack_details", path: "reports/bag_pack_details", routes: ["/reports/bag_pack/:id"]},
	{
		name: "ezd.reports.controlWorks",
		path: "reports/controlWorks",
		routes: ["/reports/control_works", "/reports/control_works/:id"]
	},
	{name: "ezd.reports.academic_debt", path: "reports/academic_debt", routes: ["/reports/academicdebt"]},
	{
		name: "ezd.reports.academic_debt_by_periods",
		path: "reports/academic_debt_by_periods",
		routes: ["/reports/academic_debt"]
	},
	{name: "ezd.reports.bad_marks", path: "reports/bad_marks", routes: ["/reports/badmarks", "/reports/badmarks/:id"]},
	{name: "ezd.reports.sc_report", path: "reports/sc_report", routes: ["/reports/sc_report"]},
	{
		name: "ezd.reports.new_missing",
		path: "reports/new_missing",
		routes: [
			"/reports/missing/by_class_unit/:id",
			"/reports/missing/by_class_level",
			"/reports/nonattendance/by_class_unit/:id?:date",
			"/reports/nonattendance/by_student/:student_id?:date"
		]
	},
	{
		name: "ezd.reports.preschool_education",
		path: "reports/preschool_education",
		routes: ["/reports/preschool_education"]
	},
	{name: "ezd.reports.teachers", path: "reports/teachers", routes: ["/reports/teachers", "/reports/teachersext"]},
	{name: "ezd.reports.replacements", path: "reports/replacements", routes: ["/reports/replacements"]},
	{name: "ezd.reports.journalCheck", path: "reports/journalCheck", routes: ["/reports/journal_check"]},
	{name: "ezd.reports.study_report", path: "reports/study_report", routes: ["/reports/study_report"]},
	{
		name: "ezd.reports.rooms_availability",
		path: "reports/rooms_availability",
		routes: ["/reports/rooms_availability", "/reports/rooms_availability/city"]
	},
	{name: "ezd.reports.epos", path: "reports/epos", routes: ["/reports/epos"]},
	{
		name: "ezd.reports.schedules",
		path: "reports/schedules",
		routes: ["/reports/schedules", "/reports/schedules/teachers", "/reports/schedules/students", "/reports/schedules/rooms"]
	},
	{name: "ezd.reports.avg_marks", path: "reports/avg_marks", routes: ["/reports/avg_marks"]},
	{name: "ezd.reports.student_mark_statement", path: "reports/student_mark_statement", routes: ["/reports/student_mark_statement"]},
	{
		name: "ezd.reports.implementation_curriculum",
		path: "reports/implementation_curriculum",
		routes: ["/reports/implementation_curriculum", "/reports/implementation_curriculum/list", "/reports/implementation_curriculum/view"]
	},
	{
		name: "ezd.reports.test_analysis",
		path: "reports/test_analysis",
		routes: ["/reports/test_analysis_list"]
	}
];
