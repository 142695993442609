import {Component} from "app/decorators/index";
import template from "./ezd-breadcrumbs.html";
import styles from "./ezd-breadcrumbs.local.scss";

@Component({
	selector: "ezdBreadcrumbs",
	template,
	transclude: true
})
class EzdBreadcrumbsComponent {
	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		this.styles = styles;
	}
}


angular.module("ezd.common.ui")
	.component(EzdBreadcrumbsComponent.selector, EzdBreadcrumbsComponent);

