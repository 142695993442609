

angular
	.module("ezd.backend")
	.service("$$academicDebtReport", $$academicDebtReport);


/**
 *
 * @param REP
 */
$$academicDebtReport.$inject = ["REP"];
function $$academicDebtReport(REP) {
	const url = "api/academic_debt";
	const vm = this;

	vm.collection = REP.all(url);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => REP.one(url, id);
}
