

angular
	.module("ezd.common.ui")
	.directive("themeManager", Directive);

function Directive() {
	return {
		template: require("./themeManager.html"),
		controller: Controller,
		controllerAs: "tManager"
	};
}


/**
 *
 * @param $rootScope
 * @param $$themeManager
 * @constructor
 */
Controller.$inject = ["$rootScope", "$$themeManager"];
function Controller($rootScope, $$themeManager) {
	const tManager = this;

	tManager.setColorSchema = setColorSchema;
	tManager.setFontSize = setFontSize;
	tManager.switchToColorTheme = switchToColorTheme;
	tManager.isVisiblePanel = isVisiblePanel;

	init();


	function init() {
		$$themeManager.loadStoredTheme();
		$rootScope.themeManager = $$themeManager;

		if ($rootScope.themeManagerPanelShowing === undefined) {
			$rootScope.themeManagerPanelShowing = false;
		}
	}

	function isVisiblePanel() {
		return (($rootScope.themeManager.theme.name !== "color") || $rootScope.themeManagerPanelShowing);
	}

	function switchToColorTheme() {
		$rootScope.themeManagerPanelShowing = false;
		$$themeManager.setTheme("color");
	}

	/**
	 * выбор цветовой схемы
	 * @param schema {string} название цветовой схемы
	 */
	function setColorSchema(schema) {
		$$themeManager.setTheme(schema);
	}

	/**
	 * выбор размера шрифта
	 */
	function setFontSize() {
		// TODO
	}
}