

angular
	.module("ezd.backend")
	.factory("$$groupStudentsAssmnt", groupStudentsAssmnts);

/**
 *
 * @param Jersey
 * @returns {Object|*}
 */
groupStudentsAssmnts.$inject = ["Jersey"];
function groupStudentsAssmnts(Jersey) {
	return Jersey.service("/api/group_students");
}
