

angular
	.module("ezd.backend")
	.factory("$$timetable", timetable);


/**
 *
 * @param Core
 * @returns {{collection: (Object|*)}}
 */
timetable.$inject = ["Core"];
function timetable(Core) {
	const url = "/api/bells_timetables";
	const Service = {
		collection: Core.service(url)
	};
	const fullResponse = Core.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true));

	/**
	 *
	 * DECLARATION
	 *
	 */

	Service.bindOne = bindOne;
	Service.getTemplate = getTemplate;
	Service.getListWithHeaders = getListWithHeaders;


	/**
	 *
	 * RUN
	 *
	 */
	return Service;


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(url, id);
	}

	/**
	 *
	 */
	function getListWithHeaders(query) {
		return fullResponse.all(url).getList(query);
	}

	/**
	 *
	 * @param academicYearId
	 * @param lessonDuration
	 * @returns {{academic_year_id: *, lesson_duration: *, name: null, building_id: null, bells_day_timetables: [*,*,*,*,*,*,*]}}
	 */
	function getTemplate(academicYearId, lessonDuration) {
		return {
			academic_year_id: academicYearId,
			lesson_duration: lessonDuration,
			name: null,
			building_id: null,
			bells_day_timetables: [
				{
					week_day_id: 1,
					week_day_name: "Понедельник",
					bells: []
				},
				{
					week_day_id: 2,
					week_day_name: "Вторник",
					bells: []
				},
				{
					week_day_id: 3,
					week_day_name: "Среда",
					bells: []
				},
				{
					week_day_id: 4,
					week_day_name: "Четверг",
					bells: []
				},
				{
					week_day_id: 5,
					week_day_name: "Пятница",
					bells: []
				},
				{
					week_day_id: 6,
					week_day_name: "Суббота",
					bells: []
				},
				{
					week_day_id: 7,
					week_day_name: "Воскресенье",
					bells: []
				}
			]
		};
	}
}