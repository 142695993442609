import "angular-mocks";

export default angular
	.module("ezd.mocks.journal_signatures", ["ngMockE2E"])
	.run(Runner)
	.name;

Runner.$inject = ["$httpBackend"];
function Runner($httpBackend) {
	$httpBackend
		.whenGET(/\/api\/journal_signatures/)
		.respond(200,
			[]
		);
}