angular
	.module("ezd.backend")
	.service("Notifications", NotificationsService);

NotificationsService.$inject = ["Notification"];
function NotificationsService(Notification) {
	const URL = "/api/notifications";

	return Notification.service(URL);
}
