angular
	.module("ezd.backend")
	.factory("$$StudyReport", studyReport);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
studyReport.$inject = ["REP"];
function studyReport(REP) {
	const url = "/api/education";
	const Service = REP.service(url);

	Service.getList = (query) => REP.all(`${url}/json`).getList(query);

	return Service;
}