import "angular-mocks";

export default angular
	.module("ezd.mocks.academic_years", ["ngMockE2E"])
	.run(Runner)
	.name;

Runner.$inject = ["$httpBackend"];

/**
 *
 * @param $httpBackend
 * @constructor
 */
function Runner($httpBackend) {
	const ACADEMIC_YEARS_MOCK = [
		{
			id: 7,
			name: "2019 - 2020",
			begin_date: "2019-09-01",
			end_date: "2020-08-31",
			calendar_id: 33
		},
		{
			id: 8,
			name: "2020 - 2021",
			begin_date: "2020-09-01",
			end_date: "2021-08-31",
			calendar_id: 34
		},
		{
			id: 9,
			name: "2021 - 2022",
			begin_date: "2021-09-01",
			end_date: "2022-08-31",
			calendar_id: 35
		},
		{
			id: 10,
			name: "2022 - 2023",
			begin_date: "2022-09-01",
			end_date: "2023-08-31",
			calendar_id: 36
		},
		{
			id: 11,
			name: "2023 - 2024",
			begin_date: "2023-09-01",
			end_date: "2024-08-31",
			calendar_id: 37
		},
		{
			id: 12,
			name: "2024 - 2025",
			begin_date: "2024-09-01",
			end_date: "2025-08-31",
			calendar_id: 38,
			current_year: true
		}
	];

	$httpBackend.whenGET(/\/api\/academic_years/).respond(() => {
		const academicYearsList = angular.copy(ACADEMIC_YEARS_MOCK);

		return [200, academicYearsList];
	});
}

