angular
	.module("ezd.backend")
	.service("$$passwordSettings", passwordSettingsService);

passwordSettingsService.$inject = ["ACL"];

/**
 *
 * @param ACL
 */
function passwordSettingsService(ACL) {
	const url = "/api/security-settings/password_requirements";
	const vm = this;

	vm.getSettings = getSettings;
	vm.save = save;

	/**
	 *
	 * @returns {*}
	 */
	function getSettings() {
		return ACL.one(url).customGET();
	}

	/**
	 *
	 * @param params
	 * @returns {*}
	 */
	function save(params) {
		return ACL.all(url).one("").customPUT(params);
	}
}
