

angular
	.module("ezd.backend")
	.service("$$teachersExt", $$teachersExt);


/**
 *
 * @param REP
 */
$$teachersExt.$inject = ["REP"];
function $$teachersExt(REP) {
	const url = "api/teachers_ext";
	const vm = this;


	vm.list = [];
	vm.collection = REP.all(url);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => REP.one(url, id);
}