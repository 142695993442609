

angular
	.module("ezd.backend")
	.factory("ProfileSubscriptions", profileSubscriptions);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
profileSubscriptions.$inject = ["Core"];

function profileSubscriptions(Core) {
	return Core.service("/api/profile_subscriptions");
}
