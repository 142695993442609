angular
	.module("ezd.backend")
	.factory("$$attestationNumbers", attendances);


/**
 *
 * @param Core
 * @returns {*|Object}
 */
attendances.$inject = ["Core"];
function attendances(Core) {
	return Core.service("/api/attestation_numbers");
}
