

angular
	.module("ezd.backend")
	.service("$$themeManager", themeManagerService);

/**
 *
 * @param $cookies
 * @param $q
 */
themeManagerService.$inject = ["$cookies", "$q"];
function themeManagerService($cookies, $q) {
	const vm = this;
	const themes = {
		color: {
			name: "color",
			fontSize: 12,
			fontSizeScale: {
				small: 12,
				middle: 14,
				large: 16
			}
		},
		black: {
			name: "black-theme",
			fontSize: 12,
			fontSizeScale: {
				small: 12,
				middle: 14,
				large: 16
			}
		},
		white: {
			name: "white-theme",
			fontSize: 12,
			fontSizeScale: {
				small: 12,
				middle: 14,
				large: 16
			}
		}
	};

	vm.theme = {};

	vm.loadStoredTheme = loadStoredTheme;
	vm.setTheme = setTheme;


	/**
	 * Загрузка темы сохраненной в local storage
	 */
	function loadStoredTheme() {
		return getTheme()
			.then((storedTheme) => {
				vm.theme = storedTheme || getDefaultTheme();
			});
	}

	/**
	 * Выбор темы
	 * @param themeName {string} название темы
	 * @returns {*}
	 */
	function setTheme(themeName) {
		const newTheme = themes[themeName] || getDefaultTheme();
		// используем уже установленный размер шрифта
		newTheme.fontSize = vm.theme.fontSize || newTheme.fontSize;

		return $q((resolve) => {
			$cookies.put("theme", JSON.stringify(newTheme));
			vm.theme = newTheme;
			resolve(newTheme);
		});
	}

	/**
	 * Возвращает объект темы, сохраненный
	 * @returns {*}
	 */
	function getTheme() {
		return $q((resolve) => {
			if ($cookies.get("theme")) {
				resolve(JSON.parse($cookies.get("theme")));
			} else {
				resolve(getDefaultTheme());
			}
		});
	}

	function getDefaultTheme() {
		return themes.color;
	}
}

