

angular
	.module("ezd.backend")
	.factory("$$logEvent", logEvents);

logEvents.$inject = ["LOG", "Core"];
/**
 *
 * @param LOG
 * @param Core
 */
function logEvents(LOG, Core) {
	const vm = this;

	vm.getList = getList;
	vm.getHomeWorkChanges = getHomeWorkChanges;

	/**
	 *
	 * @returns {*|Object}
	 */
	function getList(params) {
		return LOG.service("/api/logs").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	function getHomeWorkChanges(params) {
		return Core.service("/api/homework_changelogs/updated").getList(params);
	}

	return vm;
}
