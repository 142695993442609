import styles from "./ezd-confirm.local.scss";
import {close} from "images/icons/index";
import warn from "./icons/ezd-confirm-warn.svg";
import trash from "./icons/ezd-confirm-trash.svg";
import generateKey from "./icons/ezd-confirm-key.svg";
import success from "./icons/ezd-confirm-success.svg";

const types = {
	SUCCESS: "success",
	REMOVE: "remove",
	GENERATE_KEY: "key",
	NO_ICON: "none"
};

export class EzdConfirmController {
	static $inject = ["$mdDialog", "params", "$sce"];

	/**
	 * @param $mdDialog
	 * @param params
	 */
	constructor($mdDialog, params, $sce) {
		this.services = {$mdDialog, params, $sce};
		this.styles = styles;
		this.icons = {close, warn, trash, generateKey, success};
		this.params = params;

		switch (this.params.type) {
			case types.SUCCESS:
				this.logo = this.icons.success;
				break;
			case types.REMOVE:
				this.logo = this.icons.trash;
				break;
			case types.GENERATE_KEY:
				this.logo = this.icons.generateKey;
				break;
			case types.NO_ICON:
				this.logo = null;
				break;
			default:
				this.logo = this.icons.warn;
				break;
		}

		if (this.params.htmlText) {
			this.params.htmlText = $sce.trustAsHtml(this.params.htmlText);
		}
	}

	/**
	 *
	 */
	confirm(response = null) {
		const {$mdDialog} = this.services;

		if (response) {
			$mdDialog.hide(response);
		}

		if (this.params.isAdmin) {
			$mdDialog.hide(this.params.selectedItem);
		} else {
			$mdDialog.hide();
		}
	}

	/**
	 *
	 */
	hide() {
		const {$mdDialog} = this.services;

		$mdDialog.cancel();
	}

	/**
	 * Проверяем нажатую клавишу
	 **/
	checkKeyPressed(event) {
		if (event.keyCode === 13) {
			if (!this.params.fromChats) {
				this.confirm();
			}
		}
	}
}
