import "angular-mocks";

export default angular
	.module("ezd.mocks.week_days", ["ngMockE2E"])
	.run(Runner)
	.name;

Runner.$inject = ["$httpBackend"];

/**
 *
 * @param $httpBackend
 * @constructor
 */
function Runner($httpBackend) {
	$httpBackend
		.whenGET(/\/api\/week_days/)
		.respond(200, [
			{
				id: 1,
				name: "Понедельник",
				ordinal: 1
			},
			{
				id: 2,
				name: "Вторник",
				ordinal: 2
			},
			{
				id: 3,
				name: "Среда",
				ordinal: 3
			},
			{
				id: 4,
				name: "Четверг",
				ordinal: 4
			},
			{
				id: 5,
				name: "Пятница",
				ordinal: 5
			},
			{
				id: 6,
				name: "Суббота",
				ordinal: 6
			},
			{
				id: 7,
				name: "Воскресенье",
				ordinal: 7
			}
		]);
}
