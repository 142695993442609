import _forEach from "tools/fp/forEach";
import _isEmpty from "lodash/fp/isEmpty";
import _isNumber from "lodash/fp/isNumber";
import {Component} from "app/decorators/index";
import styles from "./ezd-autocomplete.local.scss";

import template from "./ezd-autocomplete.html";
import {find, removeNew} from "images/icons/index";


@Component({
	selector: "ezdAutocomplete",
	template,
	bindings: {
		required: "<",
		requiredModel: "<",
		idField: "@",
		nameField: "@",
		placeholder: "@",
		width: "@",
		emptyText: "@",
		minLength: "@",
		minHeightOptionList: "@",
		maxHeightOptionList: "@",
		searchText: "=",
		name: "@",
		collection: "<",
		disabled: "<",
		customStyle: "<",
		onItemChange: "&",
		onSearchTextChange: "&",
		onClear: "&"
	}
})
class EzdSelectComponent {
	static $inject = ["$scope", "$timeout", "$element"];

	services = {};
	styles = styles;
	icons = {find, removeNew};
	selected = {};
	showOptionsList = false;
	optionListStyle = {};

	// searchText = "";

	/** */
	constructor(...args) {
		_forEach((val, index) => {
			this.services[EzdSelectComponent.$inject[index]] = val;
		})(args);
	}


	/** */
	$onInit() {
		const {$element} = this.services;
		this.idField = this.idField || "id";
		this.nameField = this.nameField || "name";
		this.emptyText = this.emptyText || "По запросу ничего не найдено";
		this.minLength = this.minLength ? _.int(this.minLength) : 3;
		this.clearCollection();

		if (_.isUndefined(this.disabled)) {
			this.disabled = false;
		}

		if (this.width) {
			$element.css({width: this.width});
		}

		if (this.maxHeightOptionList) {
			this.optionListStyle["max-height"] = this.maxHeightOptionList;
		}

		if (this.minHeightOptionList) {
			this.optionListStyle["min-height"] = this.minHeightOptionList;
		}
	}


	/** */
	$onChanges(changes) {
		if (changes.collection && changes.collection.currentValue) {
			this.loading = false;
		}
	}


	/**
	 * показывает/прячет выпадающий список
	 */
	toggleOptionsList() {
		this.showOptionsList = !this.showOptionsList;
	}


	/**
	 * запускает создаёт запрос на изменение коллекции при изменении значения фильтра
	 */
	searchTextChanged($event) {
		if (this.searchText === "" && (!$event || $event.type !== "focus")) {
			this.selected = {[this.idField]: null};

			this.services.$timeout(this.onClear.bind(null, {$event: {item: this.selected}}), 0);
		}

		if (this.selected[this.nameField] === this.searchText) {
			return;
		}

		if (this.searchText.length >= this.minLength) {
			this.loading = true;

			this.services.$timeout(this.onSearchTextChange.bind(null, {$event: {searchText: this.searchText}}), 0);
		}
	}


	/**
	 * сбрасывает значение фильтра
	 */
	clear() {
		const {$timeout} = this.services;

		if (this.disabled) {
			return;
		}

		if (!_.isUndefined(this.requiredModel)) {
			if (_.isArray(this.requiredModel)) {
				this.requiredModel.splice(0);
			} else {
				this.requiredModel = null;
			}
		}

		this.searchText = "";

		if (this.selected[this.idField]) {
			this.selected = {[this.idField]: null};
			$timeout(this.onClear.bind(null, {$event: {item: this.selected}}), 0);
		}
	}


	/**
	 * очищает выпадающий список
	 */
	closeOptionsList() {
		this.clearCollection();
	}


	/**
	 * очищает коллекцию выпадающего списка
	 */
	clearCollection() {
		if (this.collection) {
			this.collection.splice(0);
		}
	}

	isEmptyModel() {
		return _isNumber(this.requiredModel)
			? !this.requiredModel
			: _isEmpty(this.requiredModel);
	}


	/**
	 * запускает метод onItemChange при выборе элемента в выпадающем списке
	 */
	selectItem(item) {
		const {$timeout} = this.services;

		this.selected = item;
		this.searchText = item[this.nameField];
		this.clearCollection();

		$timeout(this.onItemChange.bind(null, {$event: {item: this.selected}}), 0);
	}
}


angular
	.module("ezd.common.ui")
	.component(EzdSelectComponent.selector, EzdSelectComponent);
