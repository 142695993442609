import {Component} from "app/decorators/index";

import template from "./ezd-mask-date-picker.html";
import styles from "./ezd-mask-date-picker.local.scss";
import {datepicker} from "images/icons/";

/**
 * Компонент для выбора дат c маской
 * Использование:
 * date: type Date()
 * mask: type String
 * minDate: type Date()
 * maxDate: type Date()
 *
 * <ezd-mask-date-picker
 *      model="$ctrl.date"
 *      mask="$ctrl.mask"
 *      min-date="$ctrl.minDate"
 *      max-date="$ctrl.maxDate"
 *      on-change="$ctrl.onChange()"
 * ></ezd-mask-date-picker>
 *
 */
@Component({
	selector: "ezdMaskDatePicker",
	template,
	bindings: {
		model: "=",
		mask: "@?",
		onChange: "&",
		minDate: "<?",
		maxDate: "<?"
	}
})
export class EzdMaskDatePicker {
	static $inject = ["$mdMenu", "$timeout"];

	dataDateFormat = "YYYY-MM-DD";
	viewDateFormat = "DD.MM.YYYY";
	icons = {datepicker};
	isValid = this.model ? moment(this.model, this.dataDateFormat).isValid() : true;

	constructor($mdMenu, $timeout) {
		this.styles = styles;
		this.services = {$mdMenu, $timeout};
	}

	$onChanges() {
		this.setDate();
	}

	/** */
	setDate() {
		this.showDate();
		this.setPlaceholder();
	}

	/**
	 * Показать календарь
	 */
	showDate() {
		this.viewModel = moment(this.model, this.dataDateFormat).format(this.viewDateFormat);
	}

	/**
	 * Изменение даты
	 * @param $event
	 */
	changeDate($event) {
		const {$timeout, $mdMenu} = this.services;
		const ezdMaskDatePicker = event.type === "input" ? event.currentTarget : null;

		const viewModel = $event ? $event.date : this.viewModel;
		const momentDate = moment(viewModel, this.viewDateFormat);
		let isBetween = null;

		if (this.maxDate && this.minDate) {
			isBetween = momentDate.isBetween(this.minDate, this.maxDate, "day");
		}
		/**
		 * если нет переданной даты или дата валидна или она в диапозоне от мин до макс значения,
		 * то обрабатываем как положительное значение
		 * @type {boolean}
		 */
		this.isValid = !viewModel || viewModel.length === 0 || momentDate.isValid() || isBetween;
		if (!ezdMaskDatePicker || ezdMaskDatePicker.value.length === 10) {
			this.model = momentDate.toDate();
			this.showDate();
			$mdMenu.hide();

			$timeout(() => {
				this.onChange();
			}, 0);
		}
	}

	/**
	 * Установка placeholder(а) в зависимости от маски
	 */
	setPlaceholder() {
		switch (this.mask) {
			case "DD-MM-YYYY" :
				this.placeholder = "DD-MM-YYYY";
				break;
			case "DD/MM/YYYY" :
				this.placeholder = "DD/MM/YYYY";
				break;
			default:
				this.placeholder = "DD.MM.YYYY";
		}
	}
}
