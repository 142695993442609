

angular
	.module("ezd.backend")
	.service("$$lessonPlan", lessonPlanService);


/**
 *
 * @param $q
 * @param Jersey
 */
lessonPlanService.$inject = ["$q", "Jersey"];
function lessonPlanService($q, Jersey) {
	const vm = this;


	/**
	 *
	 * DECLARATION
	 *
	 */

	vm.bindOne = bindOne;
	vm.getPage = getPage;
	vm.updateLesson = updateLesson;

	/**
	 *
	 * VARIABLES
	 *
	 */
	vm.url = "/api/lesson_plans";
	vm.collection = Jersey.all(vm.url);


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Jersey.one(vm.url, id);
	}

	/**
	 *
	 * @param page
	 * @param params
	 * @returns {*}
	 */
	function getPage(page, params) {
		const $params = params || {};
		$params.per_page = 20;
		$params.page = page;

		return vm.collection.getList($params);
	}

	/**
	 *
	 * @param lessonPlanId
	 * @param moduleId
	 * @param topicId
	 * @param lessonId
	 * @param name
	 * @param cis
	 * @returns {*}
	 */
	function updateLesson(lessonPlanId, moduleId, topicId, lessonId, name, cis) {
		const args = arguments;

		if (lessonPlanId && moduleId && topicId && lessonId && name) {
			return Jersey
				.one(vm.url, lessonPlanId)
				.one("modules", moduleId)
				.one("topics", topicId)
				.one("lessons", lessonId)
				.customPUT({id: lessonId, name, controllable_items: cis}, "", {}, "")
				.then((data) => {
					if (!data || (data.errCode && data.errCode !== 0)) {
						throw data;
					}

					return data;
				});
		}

		const deferred = $q.defer();
		deferred.reject({errorMessage: "Не хватает данных для корректного изменения урока", data: args});

		return deferred.promise;
	}
}
