angular
	.module("ezd.common.ui")
	.filter("ucFirst", () =>
		(input) =>
			(Boolean(input))
				? input.replace(/([^\W_]+[^\s-]*) */g, (txt) =>
				txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
				)
				: ""
	)

	.filter("sum", () =>
		(input, field) =>
			field
				? _.sumBy(input, field)
				: _.sum(input)
	)


	.filter("filterByIds", () =>
		(input, matchIds) =>
			_.filter(input, (el) =>
				_.indexOf(matchIds, el.id) !== -1
			)
	)

	.filter("lessThanOrEqual", () =>
		(input, value, field) => {
			const result = [];
			const len = input.length;
			for (let i = 0; i < len; i += 1) {
				if (input[i][field] <= value) {
					result.push(input[i]);
				}
			}

			return result;
		}
	)

	.filter("greaterThan", () =>
		(input, value, field) => {
			const result = [];
			const len = input.length;
			for (let i = 0; i < len; i += 1) {
				if (input[i][field] > value) {
					result.push(input[i]);
				}
			}

			return result;
		}
	)

	.filter("notNullUndefinedField", () =>
		(input, field) => {
			const result = [];
			const len = input.length;
			for (let i = 0; i < len; i += 1) {
				if (input[i][field] !== null && input[i][field] !== undefined) {
					result.push(input[i]);
				}
			}

			return result;
		}
	)

	.filter("limitToWithOffset", () =>
		(input, limit, offset) => {
			const result = [];
			for (let i = offset; i < limit + offset + 1; i += 1) {
				if (input[i] !== undefined) {
					result.push(input[i]);
				}
			}

			return result;
		}
	)

	.filter("unique", () =>
		(collection, field) => {
			if (collection !== undefined) {
				return _.uniqBy(collection, (c) => c[field]);
			}
		}
	)

	.filter("uniqueForAttendance", () =>
		(collection) => {
			if (collection !== undefined) {

				function uniqual() {
					let dayItem;
					_.forEach(collection, (c) => {
						if (c.type === "ae" || c.type === "ec") {
							dayItem = c;
						}
					});

					if(dayItem) {
						return [dayItem];
					} else  {
						return collection;
					}
				}

				return uniqual();

				//return _.uniqBy(collection, (c) => c[field]);
			}
		}
	)


	.filter("exists", () =>
		(collection, arrayFieldName, fieldValue) => {
			if (fieldValue === undefined || fieldValue === "!null") {
				return collection;
			}
			if (collection !== undefined && arrayFieldName !== undefined && fieldValue !== undefined) {
				const result = [];
				const len = collection.length;

				// Нереверсивный цикл, чтобы сохранить порядок элементов
				for (let i = 0; i < len; i += 1) {
					if (_.includes(collection[i][arrayFieldName], fieldValue)) {
						result.push(collection[i]);
					}
				}

				return result;
			}
		}
	)

	.filter("notExistsIds", ["$$helpers", ($$helpers) => {
		/**
		 * @param collection [{}] - фильтруемая коллекция
		 * @param arrayFieldName [] || [{}] - массив скаляров/объектов, у которого не должно быть пересечений
		 * @param fieldValues {[int]} - массив айдишников, с которым не должно быть пересечения
		 * @param isScalar {boolean} - плоский массив айдишников в arrayFieldName или же объекты с id
		 */
		return (collection, arrayFieldName, fieldValues, isScalar) => {
			if (collection !== undefined && arrayFieldName !== undefined && fieldValues !== undefined) {
				let result = [],
					i = 0, j,
					len = collection.length,
					exists;

				// Нереверсивный цикл, чтобы сохранить порядок элементов
				for (i; i < len; i += 1) {
					exists = false;
					j = fieldValues.length - 1;
					while (j >= 0 && !exists) {
						if (isScalar) {
							if (_.includes(collection[i][arrayFieldName], fieldValues[j])) {
								exists = true;
							}
						} else {
							if ($$helpers.getIndexById(collection[i][arrayFieldName], fieldValues[j]) !== -1) {
								exists = true;
							}
						}
						j -= 1;
					}
					if (!exists) {
						result.push(collection[i]);
					}
				}

				return result;
			}
		};
	}])


	.filter("fio", () => {
		return (input, fio) => {
			return _.filter(input, (el) => {
				const fioBase = el.user.last_name + el.user.first_name + el.user.middle_name;

				return fioBase.search(fio) !== -1;
			});
		};
	})


	.filter("cut", () => {
		return (input, maxlength) => {
			if (typeof input === "string") {
				return (input.length > maxlength) ? input.slice(0, maxlength - 1) + "…" : input;
			}

			return input;
		};
	})


	.filter("filterSubjectsByEduLevelIds", () => {
		return (subjects, eduLevelIds) => {
			return _.filter(subjects, (subject) => {
				let someSubjectIdMatchesSomeEduLevelId = false;
				let i;
				for (i = eduLevelIds.length - 1; i >= 0; i -= 1) {
					if (_.indexOf(subject.education_level_ids, eduLevelIds[i])) {
						someSubjectIdMatchesSomeEduLevelId = true;
						break;
					}
				}

				return someSubjectIdMatchesSomeEduLevelId;
			});
		};
	})


	.filter("subjectByEducationLevel", () => {
		return (subjects, eduLevel) => {
			eduLevel = _.int(eduLevel);

			return _.filter(subjects, (subject) => {
				return (subject.education_level_ids.indexOf(eduLevel) !== -1);
			});
		};
	})


	.filter("classUnitsByClassLevel", ["$rootScope", ($rootScope) => {
		return (classUnits, classLevelId) => {
			classLevelId = _.int(classLevelId);

			return _.filter(classUnits, (classUnit) => {
				return (classUnit.class_level_id == classLevelId || classLevelId == $rootScope.classLevelAll);
			});
		};
	}])


	.filter("classUnitsByClassUnit", ["$rootScope", ($rootScope) => {
		return (classUnits, classUnitId) => {
			const result = [];
			classUnitId = _.int(classUnitId);
			if (classUnits) {
				classUnits.forEach((classUnit) => {
					if (Number(classUnit.id) === Number(classUnitId) || Number(classUnitId) === Number($rootScope.classUnitAll)) {
						result.push(classUnit);
					}
				});
			}

			return result;
		};
	}])


	.filter("classUnitsById", () => {
		return (classUnits, classUnitId) => {
			classUnitId = _.int(classUnitId);
			if (classUnitId !== classUnitId) {
				return classUnits;
			}

			return _.filter(classUnits, {id: classUnitId});
		};
	})


	.filter("subjectWithNullParentAndChildren", () => {
		return (subjects, subjectId) => {
			return _.filter(subjects, (subject) => {
				if (subjectId !== undefined && subjectId !== null) {
					return (subject.id !== subjectId && subject.parent_subject_id === null && subject.children_subjects.length === 0
						&& subject.school_id !== null);
				}

				return (subject.parent_subject_id === null && subject.children_subjects.length === 0 && subject.school_id !== null);
			});
		};
	})


	.filter("subjectBySubjectOfItselfAndChildrenSubjectsAndByEduLevel", () => {
		return (subjects, subjectId, eduLevelId) => {
			if (subjects !== undefined) {
				const result = [];
				let i = subjects.length - 1;

				for (i; i >= 0; i -= 1) {
					// (subjects[i].disciplines === undefined || subjects[i].disciplines.length === 0 || subjects[i].disciplines[0].id === subjectId)
					if (subjects[i].id === subjectId && _.includes(subjects[i].education_level_ids, eduLevelId)) {
						result.push(subjects[i]);
					}
				}

				return result;
			}
		};
	})


	.filter("classLevelBySubject", ["$$helpers", ($$helpers) => {
		return (classLevels, subjectId, curriculas, groups, currentAcademicYear) => {
			const result = [];
			let i;
			let j;
			let k;
			let isCurriculaForClassLevelExists;
			let isThereAnyGroupsWithCurriculaClassLevelSubject;
			let index;
			// Если есть массивы для работы с ними, то
			if (groups !== undefined && curriculas !== undefined && classLevels !== undefined) {
				// Проверяем все classLevels
				j = classLevels.length - 1;
				for (j; j >= 0; j -= 1) {
					i = curriculas.length - 1;
					isCurriculaForClassLevelExists = false;
					while (i >= 0 && !isCurriculaForClassLevelExists) {
						k = groups.length - 1;
						isThereAnyGroupsWithCurriculaClassLevelSubject = false;
						while (k >= 0 && !isThereAnyGroupsWithCurriculaClassLevelSubject) {
							if (groups[k].parallel_curriculum_id === curriculas[i].id && groups[k].class_level_id === classLevels[j].id
								&& groups[k].subject_id === subjectId && groups[k].academic_year_id === currentAcademicYear.id) {
								isThereAnyGroupsWithCurriculaClassLevelSubject = true;
							}
							k -= 1;
						}
						// Если есть для данного classLevel хотя бы один подходящий studyPlan
						// И есть для него хоть одна группа
						// То закидываем этот classLevel в результирующий массив
						if (isThereAnyGroupsWithCurriculaClassLevelSubject && _.includes(curriculas[i].subjects_ids, subjectId)
							&& (curriculas[i].class_level_id === classLevels[j].id)) {
							// if (curriculas[i].subjects_ids.exists(subjectId) && (curriculas[i].class_level_id === classLevels[j].id)) {
							index = $$helpers.getIndexById(result, classLevels[j].id);
							if (index === -1) {
								result.push(classLevels[j]);
								isCurriculaForClassLevelExists = true;
							}
						}
						i -= 1;
					}
				}
			}

			return result;
		};
	}])


	.filter("studyPlanByClassLevel", () => {
		return (studyPlans, classLevelId) => {
			const result = [];
			if (studyPlans !== undefined) {
				studyPlans.forEach((studyPlan) => {
					if (studyPlan.class_level_id === classLevelId && studyPlan.class_unit_ids.length > 0) {
						result.push(studyPlan);
					}
				});
			}

			return result;
		};
	})

	.filter("knowledgeFieldsBySubject", ["$$helpers", ($$helpers) => {
		return (knowledgeFields, subjectId, subjects, eduLevelId) => {
			const result = [];
			let knowledgeFieldIndex;
			let i;
			const knowledgeFieldIds = [];
			if (subjectId === "!null") {
				return knowledgeFields;
			}
			const subjectIndex = $$helpers.getIndexById(subjects, subjectId);
			if (subjectIndex !== -1 && _.includes(subjects[subjectIndex].education_level_ids, eduLevelId)) {
				i = subjects[subjectIndex].knowledge_field_ids.length - 1;
				for (i; i >= 0; i -= 1) {
					knowledgeFieldIndex = $$helpers.getIndexById(knowledgeFields, subjects[subjectIndex].knowledge_field_ids[i]);
					if (knowledgeFieldIndex !== -1 && !_.includes(knowledgeFieldIds, knowledgeFields[knowledgeFieldIndex].id)) {
						result.push(knowledgeFields[knowledgeFieldIndex]);
						knowledgeFieldIds.push(knowledgeFields[knowledgeFieldIndex].id);
					}
				}
			}

			return result;
		};
	}])


	.filter("classUnitByLevelAndPlan", () => {
		return (classUnits, classLevel, studyPlan) => {
			const result = [];

			_.forEach(classUnits, (classUnit) => {
				// Если совпадает параллель для класс - проверяем дальше
				if (classUnit.class_level_id === classLevel.id && studyPlan.class_unit_ids.indexOf(classUnit.id) !== -1) {
					result.push(classUnit);
				}
			});

			return result;
		};
	})


	.filter("entityExceptEntitiesById", () => {
		return (entities, entitiesContainer, fieldName, idName) => {
			const ids
				= fieldName === undefined
				? _.map(entitiesContainer, "id")
				: (
					idName === undefined
						? _.map(entitiesContainer[fieldName], "id")
						: _.map(entitiesContainer[fieldName], idName)
				);

			return _.filter(entities, (entity) => {
				return ids.indexOf(entity.id) === -1;
			});
		};
	})


	.filter("subjectExceptSubjectsById", () => {
		return (subjects, room) => {
			const subjectIds = _.map(room.subjects, "id");

			return _.filter(subjects, (subject) => {
				return subjectIds.indexOf(subject.id) === -1;
			});
		};
	})


	.filter("teacherExceptTeachersById", () => {
		return (teachers, room) => {
			const teacherIds = _.map(room.teachers, "id");

			return _.filter(teachers, (teacher) => {
				return teacherIds.indexOf(teacher.id) === -1;
			});
		};
	})


	.filter("findOneById", () => {
		return (input, id, field) => {
			const r = _.find(input, {id});

			return (r && field) ? r[field] : r || "";
		};
	})


	.filter("controlFormsFilter", () => {
		return (collection, subject_id, education_level_id) => {
			subject_id = _.int(subject_id);
			education_level_id = _.int(education_level_id);

			return _.filter(collection, (item) => {
				if (subject_id !== subject_id) {
					return (item.education_level_id === education_level_id);
				}

				return ((item.education_level_id === education_level_id) && (item.subject_id === subject_id));
			});
		};
	})

	// фильтр параллелей по уровню образования
	.filter("classLevelsByStudyLevel", () => {
		return (classLevels, studyLevelId) => {
			// если не выбран уровень образования, то отдаем все параллели
			studyLevelId = _.int(studyLevelId);
			if (studyLevelId !== studyLevelId) {
				return classLevels;
			}
			//
			return _.filter(classLevels, (classLevel) => {
				return classLevel.education_level_id === studyLevelId;
			});
		};
	})

	// фильтр параллелей по уровню образования
	.filter("classUnitsByClassLevel", () => {
		return (classUnits, classLevelId) => {
			// если не выбран уровень образования, то отдаем все параллели
			classLevelId = _.int(classLevelId);
			if (classLevelId !== classLevelId) {
				return classUnits;
			}

			return _.filter(classUnits, (classUnit) => {
				return classUnit.class_level_id === classLevelId;
			});
		};
	})

	.filter("scheduleClassRoomsByBuilding", () => {
		return (rooms, buildingId) => {
			buildingId = _.int(buildingId);
			if (buildingId !== buildingId) {
				return rooms;
			}
			//
			return _.sortBy(_.filter(rooms, (room) => {
				return room.building_id === buildingId;
			}), "name");
		};
	})

	.filter("scheduleClassGroupsFilter", () => {
		return (groups, classUnitId, selectedGroups) => {
			let selectedGroupsIds = (selectedGroups.length > 0) ? _.map(selectedGroups, "id") : [],
				incompatibleGroupsIds = [];

			classUnitId = _.int(classUnitId);

			// формируем список id групп несовместимых с уже выбранными группами
			_.forEach(selectedGroups, (group) => {
				if (group.incompatible_group_ids && group.incompatible_group_ids.length > 0) {
					incompatibleGroupsIds = _.union(incompatibleGroupsIds, group.incompatible_group_ids);
				}
			});

			return _.filter(groups, (group) => {
				// группа не будет отфильтрована, если она уже выбрана для урока
				if ((selectedGroupsIds.length > 0) && (selectedGroupsIds.indexOf(group.id) !== -1)) {
					return true;
				}
				// группа будет отфильтрована, если она не принадлежит выбранному классу (чужая группа)
				if (group.class_unit_ids.indexOf(classUnitId) === -1) {
					return false;
				}
				// группа не будет отфильтрована, если она прошла предыдущие условия и не является несовместимой с уже выбранными группами
				return (incompatibleGroupsIds.indexOf(group.id) === -1);
			});
		};
	})
	.filter("inArr", () => {
		return (collection, arrName, value) => {
			return _.filter(collection, (obj) => {
				return _.indexOf(obj[arrName], value) !== -1;
			});
		};
	})
	.filter("toInt", () => {
		return (value) => {
			return _.int(value);
		};
	})

	.filter("dateFromArr", () => {
		return (value, format, fix) => {
			if (fix) {
				return moment([value[0], value[1] - 1, value[2]]).format(format);
			}

			return moment(value).format(format);
		};
	})

	.filter("moment", () => {
		return (value, format_from, format_to) => {
			return moment(value, format_from).format(format_to);
		};
	})


	.filter("includes", () => {
		return (collection, arrName, value) => {
			return _.filter(collection, (obj) => {
				return _.includes(obj[arrName], value);
			});
		};
	})


	.filter("selectParCurSubject", () => {
		return (collection, subjects, kfId, parentId) => {
			parentId = parentId || null;
			if (kfId !== null) {
				collection = _.filter(collection, (subj) => {
					return _.includes(subj.knowledge_field_ids, kfId);
				});
			}

			return _.reject(collection, (obj) => {
				return _.find(subjects, {subject_id: obj.id}) !== undefined;
			});
		};
	})

	.filter("mapRole", () => {
		return (item) => {
			switch (item) {
				case "teacher":
					return "учитель";
				case "deputy":
					return "завуч";
				case "principal":
					return "директор";
				case "staff":
					return "сотрудник";
				case "junior_educator":
					return "воспитатель";
				case "educator":
					return "старший воспитатель";
				case "school_admin":
					return "администратор школы";
				case "school_admin_read_only":
					return "администратор школы в режиме просмотра";
				case "passport_printer":
					return "ответственный за печать аттестатов";
				case "psychologist":
					return "педагог-психолог";
				case "methodologist":
					return "методист";
				case "ae_educator":
					return "преподаватель ДО";
				case "ae_admin":
					return "администратор ДО";
				default:
					return item;
			}
		};
	})

	.filter("groupAssSortBy", () => {
		return (collection) => {
			return _.orderBy(
				collection,
				["subject_name", "begin_timestamp", "end_timestamp"],
				["asc", "asc", "desc"]
			);
		};
	})

	.filter("finalMarkCreatedDate", () => (date) => moment(date, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY"))

	.filter("mapTestStatus", () => {
		return (item) => {
			switch (item) {
				case "APPROVED":
					return "утверждена";
				case "READY":
					return "отправлено на согласование";
				case "NEW":
					return "ожидается отправка на согласование";
				case "REJECTED":
					return "отклонена";
				default:
					return item;
			}
		};
	})
	// сортировка с учетом кода буквы 'Ё'
	.filter("uniOrderBy", () => {
		return (items, field) => {
			function uniSort(a, b) {
				const aCode = a[field].toLowerCase().replace("ё", "е" + String.fromCharCode(1110));
				const bCode = b[field].toLowerCase().replace("ё", "е" + String.fromCharCode(1110));

				if (aCode > bCode) {
					return 1;
				}
				if (aCode < bCode) {
					return -1;
				}

				return 0;


				// return a;
			}

			return items.sort(uniSort);
		};
	})

	.filter("toFixed", () => {
		return (val, n) => {
			if (val === 0) {
				return 0;
			}

			return parseFloat(val).toFixed(n);
		};
	})

	// правильное округление числа до указанной точности
	.filter("roundToPrecision", () => {
		return (number, precision) => {
			return _.round(number, precision).toFixed(precision);
		};
	});
