/**
 *
 * @param Core
 * @returns {Object|*}
 */
class DecisionsCommon {
	static $inject = ["Core", "Jersey"];
	static name = "DecisionsCommon";

	constructor(Core, Jersey) {
		this.core = Core;
		this.jersey = Jersey;
	}

	/**
	 *
	 * @param params
	 */
	getMarksChanges(params) {
		return this.core.service("/api/marks/requires_decisions").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getHomeworksChanges(params) {
		return this.core.service("/api/homework_changelogs").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getMissingMarks(params) {
		return this.core.service("/api/teacher_profiles/requires_decisions").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getMissingTopics(params) {
		return this.core.service("/api/event_tem").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getWrongSchedules(params) {
		return this.core.service("/api/subjects/requires_decisions").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getClassChanges(params) {
		return this.core.service("/api/student_profiles/requires_decisions").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getClassesWithoutTimetable(params) {
		return this.core.service("/api/class_units/requires_decisions").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getGroupsWithoutTeacher(params) {
		return this.jersey.service("/api/groups/unattached").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getGroupsForJournalCheck(params) {
		return this.core.service("/api/requires_decisions/filter/groups").getList({
			...params,
			for_journal_checks: true
		});
	}

	/**
	 *
	 * @param params
	 */
	getGroupsWithoutFinalMarks(params) {
		return this.core.service("/api/requires_decisions/group_without_final_marks").getList(params);
	}

	/**
	 *
	 * @param params
	 */
	getGroupsForFilterWithoutFinalMarks(params) {
		return this.core.service("/api/requires_decisions/filter/groups").getList({
			...params,
			for_groups_without_final_marks: true
		});
	}
}

angular
	.module("ezd.backend")
	.service(DecisionsCommon.name, DecisionsCommon);
