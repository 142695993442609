angular
	.module("ezd.backend")
	.service("MoodleService", MoodleService);

/**
 *
 * @param Moodle
 */
MoodleService.$inject = ["Moodle"];
function MoodleService(Moodle) {
	const vm = this;
	vm.exportToMoodle = exportToMoodle;

	/**
	 *
	 * @param lessonId
	 */
	function exportToMoodle(lessonId) {
		return Moodle.all("/").customPOST(null, null, {courseId: lessonId});
	}
}
