const URL = "/eom/results";
const SUCCESS_RESULTS_URL = "/eom/results_success_ids";

class EomResultsService {
	static name = "EomResults";
	static $inject = ["ExternalApps"];

	/**
	 * constructor
	 * @param {object} ExternalApps service
	 */
	constructor(ExternalApps) {
		this.collection = ExternalApps.all(URL);
		this.successResultsApi = ExternalApps.all(SUCCESS_RESULTS_URL);
		this.fullApi = ExternalApps.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true)).all(URL);
	}

	/**
	 * */
	getPage(params) {
		return this.fullApi.getList(params);
	}
}

angular
	.module("ezd.backend")
	.service(EomResultsService.name, EomResultsService);
