angular
	.module("ezd.backend")
	.factory("AEEducationLevels", aeEducationLevels);


aeEducationLevels.$inject = ["Core"];

/**
 *
 * @param Core
 * @returns {Object|*}
 */
function aeEducationLevels(Core) {
	return Core.service("/api/ae_education_levels");
}
