import "angular-ui-router";
import _getOr from "lodash/fp/getOr";

export default angular
	.module("ezd.providers", ["ui.router"])
	.provider("$route", $routeProvider)
	.value("$routeParams", {})
	.run($routeRun)
	.name;


$routeProvider.$inject = ["$stateProvider"];

/**
 *
 * @param $stateProvider
 */
function $routeProvider($stateProvider) {
	this.when = (path, object) => {
		const state = {
			url: path,
			template: object.template,
			controller: object.controller,
			controllerAs: object.controllerAs,
			resolve: object.resolve,
			data: {
				screenSizes: object.screenSizes,
				screenType: object.screenType,
				useHistory: object.useHistory,
				disableYearSelector: object.disableYearSelector,
				refName: object.refName,
				additionalParams: object.additionalParams,
				access: object.access
			}
		};
		$stateProvider.state(path, state);

		return this;
	};

	this.$get = $get;

	$get.$inject = ["$urlRouter"];

	/**
	 *
	 * @param $urlRouter
	 * @returns {{reload: (function)}}
	 */
	function $get($urlRouter) {
		return {
			reload: $urlRouter.sync
		};
	}
}


$routeRun.$inject = ["$routeParams", "$rootScope", "$location", "EzdGuards", "$$profile"];

/**
 *
 *
 * @param $routeParams
 * @param $rootScope
 * @param $location
 * @param EzdGuards
 * @param $$profile
 */
function $routeRun($routeParams, $rootScope, $location, EzdGuards, $$profile) {
	$rootScope.$on("$stateChangeStart", async (e, toState, toParams) => {
		// проверка доступа к стейту
		if (_getOr(false, "data.access")(toState) && !await EzdGuards.checkAccess(toState.url, toState.data.access)) {
			e.preventDefault();

			return $$profile.goToDefaultPageForProfile(await $$profile.getCurrentProfile());
		}

		_.forOwn($routeParams, (item, key) => delete $routeParams[key]);
		_.assign($routeParams, toParams);
		_.assign($routeParams, $location.search());
	});
}
