angular
	.module("ezd.backend")
	.factory("$$passChange", passChange);

/**
 *
 * @returns {{}}
 */
function passChange() {
	const service = {};
	const lowerCharacters = ["a", "b", "c", "d", "e", "f", "g", "h", "j", "k", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
	const upperCharacters = ["A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
	const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
	const specialCharacters = ["!", "@", "#", "$", "%", "^", "&", "*", "(", ")"];
	const finalCharacters = [...lowerCharacters, ...numbers, ...upperCharacters, ...specialCharacters];

	let settings = null;


	// bindings
	service.generatePassword = generatePassword;

	return service;

	/**
	 *
	 * @returns {string}
	 */
	function generatePassword(set) {
		if (typeof set.plain === "function") {
			settings = set.plain();
		} else {
			settings = set;
		}

		let countSpecialConditions = 0;
		Object.keys(settings).forEach((key) => {
			if (settings[key] === true) {
				countSpecialConditions++;
			}
		});

		if (settings.password_min_length < countSpecialConditions) {
			return generateUnsecurePassword();
		}

		return generateSecurePassword();
	}


	/**
	 * Создаём не секьюрный пароль в том случае,
	 * если кол-во различных символов больше,
	 * чем длина пароля
	 * @returns {string}
	 */
	function generateUnsecurePassword() {
		const pass = _.fill(Array(settings.password_min_length), null);

		for (let i = 0; i < pass.length; i++) {
			pass[i] = getRandomChar(finalCharacters);
		}

		return pass.join("");
	}

	/**
	 * Создать пароль в соответствии с правилами
	 * @returns {string}
	 */
	function generateSecurePassword() {
		let pass = _.fill(Array(settings.password_min_length), null);

		let rndChar;
		if (settings.password_req_spec) {
			rndChar = getRandomChar(specialCharacters);
			pass = insertChar(rndChar, pass);
		}

		if (settings.password_req_low_letter) {
			rndChar = getRandomChar(lowerCharacters);
			pass = insertChar(rndChar, pass);
		}

		if (settings.password_req_digit) {
			rndChar = getRandomChar(numbers);
			pass = insertChar(rndChar, pass);
		}

		if (settings.password_req_up_letter) {
			rndChar = getRandomChar(upperCharacters);
			pass = insertChar(rndChar, pass);
		}

		let freeCharCount = _.filter(pass, (p) => {
			return p === null;
		}).length;

		while (freeCharCount > 0) {
			rndChar = getRandomChar(finalCharacters);
			insertChar(rndChar, pass);

			freeCharCount--;
		}

		return pass.join("");
	}

	/**
	 * Вставить символ в случайное незанятое место в массиве
	 * @param char
	 * @param pass
	 * @param $rndIdx
	 */
	function insertChar(char, pass, $rndIdx = null) {
		let rndIdx = $rndIdx;

		if (rndIdx === null) {
			rndIdx = Math.floor(Math.random() * pass.length);
		}

		if (pass[rndIdx] === null) {
			pass[rndIdx] = char;

			return pass;
		}

		if (rndIdx === pass.length - 1) {
			rndIdx = 0;
		} else {
			rndIdx = rndIdx + 1;
		}

		return insertChar(char, pass, rndIdx);
	}

	/**
	 *
	 * @param chars
	 * @returns {*}
	 */
	function getRandomChar(chars) {
		return chars[Math.floor(Math.random() * chars.length)];
	}
}
