import {eyeClosed, eyeOpened} from "images/icons/index";


class ShowPassCtrl {
	static $inject = ["$element", "$scope", "$compile"];

	/**
	 *
	 */
	constructor($element, $scope, $compile) {
		this.services = {$element};
		this.icon = eyeClosed;
	}

	/**
	 *
	 */
	toggleShowPass() {
		const {$element} = this.services;

		const input = $element.find("input");
		const visible = input.attr("type") === "text";

		if (visible) {
			input.attr("type", "password");
			this.icon = eyeClosed;
		} else {
			input.attr("type", "text");
			this.icon = eyeOpened;
		}
	}
}


/**
 *
 * @returns {*}
 * @constructor
 */
export function ShowPassDir() {
	return {
		restrict: "A",
		replace: true,
		controller: ShowPassCtrl,
		controllerAs: "ctrl",
		template(tElem, tAttrs) {
			const icon = "<md-icon ng-click='ctrl.toggleShowPass()' md-svg-src='{{ctrl.icon}}'></md-icon>";

			return `<div>
				${tElem[0].innerHtml}
				${icon}
			</div>`;
		}
	};
}

angular.module("ezd.common.ui").directive("showPass", ShowPassDir);
