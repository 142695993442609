angular
	.module("ezd.backend")
	.service("$$week", $$week);


/**
 *
 * @param LMSv2
 */
$$week.$inject = ["Core"];

/**
 *
 * @param Core
 */
function $$week(Core) {
	const url = "/api/week";
	const vm = this;

	vm.collection = Core.all(url);

	vm.bindOne = bindOne;

	/**
	 *
	 * @param date
	 */
	function bindOne() {
		return Core.one(url);
	}
}
