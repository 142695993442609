

angular
	.module("ezd.backend")
	.factory("$$certSubjects", certSubjects);


/**
 *
 * @param Core
 * @returns {*|Object}
 */
certSubjects.$inject = ["Core"];
function certSubjects(Core) {
	return Core.service("/api/attestation/subjects");
}