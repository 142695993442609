/**
 * Занятие тематического плана
 */
class ECCourseLesson {
	/**
	 * Возвращает шаблон занятия тематического плана
	 */
	getTemplate() {
		return {
			name: "",
			ordinal: 1 // порядок занятия
		};
	}
}


angular
	.module("ezd.backend")
	.service("ECCourseLesson", ECCourseLesson);
