

angular
	.module("ezd.backend")
	.factory("$$cleanUp", cleanUp);


/**
 *
 * @param Jersey
 * @returns {*|Object}
 */
cleanUp.$inject = ["Jersey"];
function cleanUp(Jersey) {
	const Service = Jersey.service("/api/parallel_curricula");

	// bindings
	Service.clean = Clean;

	return Service;

	// implementation
	function Clean(id) {
		return Service.one(id).all("empty_groups").remove();
	}
}
