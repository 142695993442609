

angular
	.module("ezd.backend")
	.service("scheduleClassFilters", Service);

/**
 *
 * @param $cookies
 * @param $q
 * @param natSort
 * @param $$academicYear
 * @param $$classLevel
 * @param $$classUnit
 * @param $$educationLevel
 * @constructor
 */
Service.$inject = ["$cookies", "$q", "natSort", "$$academicYear", "$$classLevel", "$$classUnit", "$$educationLevel"];
function Service($cookies, $q, natSort, $$academicYear, $$classLevel, $$classUnit, $$educationLevel) {
	const filters = this;
	let $classLevels = [];
	let $academicYear;

	filters.studyLevels = [];

	filters.classLevels = [];

	filters.classUnits = [];

	filters.classUnitPromise = null;

	filters.selected = {
		studyLevel: null,
		classLevel: null,
		classUnit: null
	};


	filters.init = init;

	filters.setClassLevel = setClassLevel;

	filters.setClassUnit = setClassUnit;

	filters.setStudyLevel = setStudyLevel;

	filters.storeFilters = storeFilters;

	filters.resetFilters = resetFilters;


	function init() {
		return $$academicYear
			.getSelected()
			.then((year) => {
				$academicYear = year;

				return $q.all({
					studyLevels: $$educationLevel.collection.getList(),
					classLevels: $$classLevel.collection.getList({academic_year_id: year.id})
				});
			})
			.then((response) => {
				filters.studyLevels = response.studyLevels || [];
				$classLevels = _.sortBy(response.classLevels, "level") || [];

				if ($cookies.get("filterStudyLevelId")) {
					restoreFilters();

					return loadClassUnits();
				}

				// default study level
				setStudyLevel(filters.studyLevels[0]);
			})
			.then((classUnits) => {
				if (classUnits) {
					setClassUnits(classUnits);

					if ($cookies.get("filterClassUnitId")) {
						setClassUnit(_.find(filters.classUnits, {id: _.int($cookies.get("filterClassUnitId"))}));
					}
				}
			});
	}

	function setStudyLevel(selected) {
		filters.selected.studyLevel = selected;
		filters.classLevels = _.filter($classLevels, {education_level_id: selected.id});
		// default class level
		setClassLevel(filters.classLevels[0]);
	}

	function setClassLevel(selected) {
		filters.selected.classLevel = selected;
		filters.selected.classUnit = null;
		filters.classUnitPromise = loadClassUnits().then(setClassUnits);
	}

	function setClassUnit(selected) {
		filters.selected.classUnit = selected;
	}

	function loadClassUnits() {
		if (!filters.selected.classLevel) {
			return [];
		}

		return $$classUnit.collection.getList({
			academic_year_id: $academicYear.id,
			class_level_id: filters.selected.classLevel.id,
			with_home_based: true,
			with_home_based_periods: true
		});
	}

	function setClassUnits(data) {
		// item => natSort.naturalValue(item.name)
		filters.classUnits = _.orderBy(data, ["home_based", "unit_name"], ["asc", "asc"]);
		// default class unit
		filters.selected.classUnit = null;
	}

	function storeFilters() {
		$cookies.put("filterStudyLevelId", filters.selected.studyLevel.id);
		$cookies.put("filterClassLevelId", filters.selected.classLevel.id);

		if (filters.selected.classUnit) {
			$cookies.put("filterClassUnitId", filters.selected.classUnit.id);
		}
	}


	function resetFilters() {
		setStudyLevel(filters.studyLevels[0]);
		$cookies.remove("filterClassUnitId");
		storeFilters();
	}

	function restoreFilters() {
		filters.selected.studyLevel = _.find(filters.studyLevels, {id: _.int($cookies.get("filterStudyLevelId"))});

		filters.classLevels = _.filter($classLevels, {education_level_id: _.int($cookies.get("filterStudyLevelId"))});
		filters.selected.classLevel = _.find($classLevels, {id: _.int($cookies.get("filterClassLevelId"))});
	}
}
