

angular
	.module("ezd.common.ui")
	.directive("checkProfile", Directive);

/**
 *
 * @returns {{restrict: string, scope: {checkProfile: string}, transclude: boolean, template: string, controller: Controller, controllerAs: string}}
 * @constructor
 */
function Directive() {
	return {
		restrict: "A",
		scope: {
			checkProfile: "="
		},
		transclude: true,
		template: "<ng-transclude ng-if=\"chkPro.isShow\"></ng-transclude>",
		controller: Controller,
		controllerAs: "chkPro"
	};
}


/**
 *
 * @param $scope
 * @param $$profile
 * @param $$teacherProfiles
 * @param Rights
 * @constructor
 */
Controller.$inject = ["$scope", "$$profile", "$$teacherProfiles", "Rights"];
function Controller($scope, $$profile, $$teacherProfiles, Rights) {
	const vm = this;

	vm.isShow = false;

	$$profile.getCurrentProfile()
		.then((profile) => {
			$scope.$watchCollection(
				() => {
					return profile.roles;
				},
				(roles) => {
					$scope.rights = _($scope.checkProfile)
						.filter((name) => _.startsWith(name, "right:"))
						.map((pr) => {
							const rightName = pr.split("right:")[1];

							return `right:${Rights[rightName]}`;
						})
						.value();

					if (_.intersects($scope.checkProfile, roles) || _.intersects($scope.rights, roles)) {
						vm.isShow = true;

						return;
					}
					if (_.includes(roles, "teacher") && $scope.checkProfile.indexOf("mentor") > -1) {
						$$teacherProfiles.bindOne(profile.id).get()
							.then((teacherProfile) => {
								if (teacherProfile && !_.isEmpty(teacherProfile.managed_class_unit_ids)) {
									roles.push("mentor");
									vm.isShow = _.intersects($scope.checkProfile, roles) || _.intersects($scope.rights, roles);
								}
							});
					} else {
						vm.isShow = _.intersects($scope.checkProfile, roles) || _.intersects($scope.rights, roles);
					}
				}
			);
		});
}

