angular
	.module("ezd.backend")
	.factory("Stationery", Stationery);


/**
 *
 * @param Core
 * @returns {Object|*}
 * @constructor
 */
function Stationery(Core) {
	const Service = Core.service("/api/stationery");

	Service.Deleted = Core.service("/api/stationery/delete");

	return Service;
}

Stationery.$inject = ["Core"];

