

angular
	.module("ezd.backend")
	.factory("ChatGroups", chatGroups);


/**
 *
 * @param Core
 * @returns {*|Object}
 */
chatGroups.$inject = ["Core"];
function chatGroups(Core) {
	const url = "/api/chat_groups";

	return Core.service(url);
}

