import _intersects from "tools/fp/intersects";

angular
	.module("ezd.common.ui")
	.directive("ezdDownloadFile", EzdDownloadFile);

EzdDownloadFile.$inject = ["$http", "$$profile", "EzdConfirm", "EzdSupportFormService", "EzdAlertDialog"];

/**
 * Директива, проверяющая наличие скачиваемого файла перед скачиванием.
 * Если файла нет, выводится модальное окно с предупреждением.
 * @param $http
 * @param EzdConfirm
 * @returns {{restrict: string, scope: {parentId: string, homeworkId: string}, link: ezdDownloadFileLink}}
 * @constructor
 */
function EzdDownloadFile($http, $$profile, EzdConfirm, EzdSupportFormService, EzdAlertDialog) {
	return {
		restrict: "A",
		scope: {
			parentId: "@", // родительский элемент, в который встраивается модальное окно
			homeworkId: "@",
			isTeacherAttachment: "@",
			isStudentAttachment: "@"
		},
		link: ezdDownloadFileLink
	};

	/** */
	function ezdDownloadFileLink(scope, element, attrs) {
		element[0].addEventListener("click", checkDownloadFile.bind(null, scope, element, attrs));
	}

	/**
1. Ученик пытается скачать собственное решенное ДЗ - выводим сообщение о необходимости заново прикрепить файл
2. Ученик пытается скачать доп.материалы по ДЗ - предложение написать учителю - перенаправляем на /new_messages
3. Учитель пытается скачать решенные ДЗ учеников - предложение написать в ТП - открываем openSupportForm
4. Учитель пытается скачать собственные доп. материалы ДЗ - выводим сообщение о необходимости заново прикрепить файл
   */
	async function checkDownloadFile(scope, element, attrs, $event) {
		$event.preventDefault();

		const isShowReattachMessage = (isStaff() && scope.isTeacherAttachment) || (!isStaff() && scope.isStudentAttachment);
		const isOpenNewMessages = !isStaff() && scope.isTeacherAttachment;
		const isOpenSupportForm = !isShowReattachMessage && !isOpenNewMessages;

		let head = {};
		try {
			head = await $http.head(attrs.href);
		} catch (e) {
			head = e;
		}
		if (200 <= head.status && head.status <= 299) {
			window.open(attrs.href, "_blank");
		} else {
			if (!scope.homeworkId) {
				EzdAlertDialog.info({
					parent: document.getElementById(scope.parentId),
					title: "",
					text: "Вы не можете скачать файл, пока не создано домашнее задание."
				});

				return;
			}

			if (isShowReattachMessage) {
				EzdAlertDialog.warn({
					parent: document.getElementById(scope.parentId),
					title: "",
					text: "Открываемый Вами файл недоступен. Если ошибка будет повторяться, то просьба прикрепить файл заново."
				});

				return;
			}

			await EzdConfirm.confirm({
				parent: document.getElementById(scope.parentId),
				text: "Открываемый Вами файл недоступен. Для решения данной проблемы следует "
				+ (isOpenNewMessages
					? "связаться с учителем, назначившим домашнее задание."
					: "обратиться в службу технической поддержки."),
				confirmButton: isOpenNewMessages ? "Написать учителю" : "Написать обращение"
			});

			if (isOpenNewMessages) {
				window.open("/new_messages");

				return;
			}

			if (isOpenSupportForm) {
				openSupportForm(attrs);

				return;
			}
		}
		element[0].removeEventListener("click", checkDownloadFile);
	}

	/** */
	function isStaff() {
		return !_intersects($$profile.currentProfile.roles, ["parent", "student"]);
	}

	/** */
	function openSupportForm(attrs) {
		window.location.href = `/support/support-form?errorText=${attrs.homeworkId}&errorUrl=${attrs.href}`;
	}
}
