import "./resizable.css";


angular
	.module("ezd.common.ui")
	.directive("resizable", Directive);

/**
 *
 * @returns {{restrict: string, scope: {rDirections: string, rCenteredX: string, rCenteredY: string, rWidth: string, rHeight: string, rFlex: string,
 *     onResizeEnd: string}, link: (function(*, *=, *))}}
 * @constructor
 */
function Directive() {
	return {
		restrict: "AE",
		scope: {
			rDirections: "=",
			rCenteredX: "=",
			rCenteredY: "=",
			rWidth: "=",
			rHeight: "=",
			rFlex: "=",
			onResizeEnd: "&",
			backgroundColor: "@"
		},
		link(scope, element, attr) {
			scope.dimensions = "px";

			element.addClass("resizable");

			const style = window.getComputedStyle(element[0], null);
			let w;
			let h;
			const dir = scope.rDirections;
			let start;
			let dragDir;
			let axis;

			function dragging(e) {
				const offset = axis === "x" ? start - e.clientX : start - e.clientY;
				switch (dragDir) {
					case "top":
						element[0].style.height = h + offset + "px";
						break;
					case "right":
						element[0].style.width = w - offset + "px";
						break;
					case "bottom":
						element[0].style.height = h - offset + "px";
						break;
					case "left":
						element[0].style.width = w + offset + "px";
						break;
				}
			}

			function dragEnd() {
				scope.$apply();
				scope.onResizeEnd(element);
				document.removeEventListener("mouseup", dragEnd, false);
				document.removeEventListener("mousemove", dragging, false);
			}

			function dragStart(e, direction) {
				dragDir = direction;
				axis = dragDir === "left" || dragDir == "right" ? "x" : "y";
				start = axis === "x" ? e.clientX : e.clientY;
				w = parseInt(style.getPropertyValue("width"));
				h = parseInt(style.getPropertyValue("height"));

				document.addEventListener("mouseup", dragEnd, false);
				document.addEventListener("mousemove", dragging, false);

				// Disable highlighting while dragging
				if (e.stopPropagation) { e.stopPropagation(); }
				if (e.preventDefault) { e.preventDefault(); }
				e.cancelBubble = true;
				e.returnValue = false;

				scope.$apply();
			}

			for (var i = 0; i < dir.length; i++) {
				(function () {
					const grabber = document.createElement("div");
					const direction = dir[i];

					// add class for styling purposes
					grabber.style.backgroundColor = scope.backgroundColor;
					grabber.setAttribute("class", "rg-" + dir[i]);
					grabber.innerHTML = "<span></span>";
					element[0].appendChild(grabber);
					grabber.ondragstart = function () { return false; };
					grabber.addEventListener("mousedown", (e) => {
						dragStart(e, direction);
					}, false);
				}());
			}
		}
	};
}
