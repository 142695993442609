angular
	.module("ezd.common.ui")
	.directive("downloadFileLink", Directive);

import remove from "images/icons/removeRed.svg";

/**
 *
 * @returns {{
 * replace: boolean, transclude: boolean, scope: {url: string, text: string, iconClass: string}, template: string, controller: Controller,
 * controllerAs: string}}
 * @constructor
 */
function Directive() {
	return {
		replace: true,
		transclude: true,
		scope: {
			url: "@",
			text: "@",
			iconClass: "@",
			withRemove: "<",
			onRemove: "&"
		},
		template: "<div><a download=\"\" target=\"_blank\" href=\"{{ url }}\" title=\"{{ text }}\" ng-click=\"c.download($event)\">"
		+ "<i ng-if=\"iconClass\" class=\"{{ iconClass }}\"></i>"
		+ "<span ng-if=\"text\" ng-bind=\"text\"></span>"
		+ "</a>"
		+ "<md-icon ng-if='withRemove' ng-click='onRemove($event)' md-svg-src=\"{{ c.icons.remove }}\"></md-icon></div>",
		controller: Controller,
		controllerAs: "c"
	};
}


/**
 *
 * @constructor
 */
function Controller() {
	const c = this;

	c.icons = {remove};
	c.download = download;

	/**
	 *
	 * @param event
	 */
	function download(event) {
		if (!confirm("Скачивание этого файла может навредить вашему компьютеру. Вы всё еще хотите скачать этот файл?")) {
			event.stopPropagation();
			event.preventDefault();
		}
	}
}
