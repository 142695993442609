angular
	.module("ezd.backend")
	.service("$$studentAdditionalInfo", Service);

Service.$inject = ["Core"];

/**
 * @param Core
 */
function Service(Core) {
	/**
	 *
	 * CONSTANTS AND VARIABLES
	 *
	 */

	const vm = this;
	vm.url = "/api/student_additional_info";

	vm.list = [];
	vm.collection = Core.all(vm.url);
	vm.collection.getList = (query) => Core.all(vm.url).getList(query);
	vm.selectedProfile = {};

	/**
	 *
	 * DECLARATION
	 *
	 */
	vm.getStudentAdditionalInfo = getStudentAdditionalInfo;
	vm.getAdaptGroupList = getAdaptGroupList;
	vm.getDisabilityGroupList = getDisabilityGroupList;
	vm.getStudentAdditionalInfoFieldNameList = getStudentAdditionalInfoFieldNameList;
	vm.create = create;
	vm.update = update;
	vm.remove = remove;

	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function getStudentAdditionalInfo(id) {
		return Core.one(vm.url, id);
	}

	/**
	 * Получение списка групп адаптации (Нозология)
	 */
	function getAdaptGroupList() {
		return Core.all(vm.url + "/adapt_groups").customGET();
	}

	/**
	 * Получение списка групп инвалидности
	 */
	function getDisabilityGroupList() {
		return Core.all(vm.url + "/disability_groups").customGET();
	}

	/**
	 * Получение списка имен полей дополнительной информации об ученике
	 */
	function getStudentAdditionalInfoFieldNameList() {
		return Core.all(vm.url + "/field_names").customGET();
	}

	/**
	 * Создание дополнительной информации об ученике
	 * @param studentAdditionalInfo
	 * @returns {*}
	 */
	function create(studentAdditionalInfo) {
		if (!studentAdditionalInfo.restangularized) {
			Core.restangularizeElement(null, studentAdditionalInfo, vm.url);
		}

		return studentAdditionalInfo.post();
	}

	/**
	 * Обновление дополнительной информации об ученике
	 * @param studentAdditionalInfo
	 * @returns {*}
	 */
	function update(studentAdditionalInfo) {
		return Core.all(vm.url).customPUT(studentAdditionalInfo);
	}

	/**
	 * Удаление дополнительной информации об ученике
	 * @param studentAdditionalInfo
	 * @returns {*}
	 */
	function remove(studentAdditionalInfo) {
		if (!studentAdditionalInfo.restangularized) {
			Core.restangularizeElement(null, studentAdditionalInfo, vm.url);
		}

		return studentAdditionalInfo.remove();
	}
}
