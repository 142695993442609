import arrowBack from "./arrow_back.svg";
import expandLess from "./ic_expand_less_black_24px.svg";
import expandMore from "./ic_expand_more_black_24px.svg";
import remove from "./ic_close_black_24px.svg";
import removeNew from "./delete.svg";
import removeRed from "./removeRed.svg";
import circleAdd from "./ic_add_circle_outline_black_24px.svg";
import stationery from "./stationery.svg";
import deleteRed from "./deleteRed.svg";
import plus from "./plus.svg";
import dots from "./dots.svg";
import empty from "./empty.svg";
import history from "./history.svg";
import circleOk from "./i-modal-circle-ok.svg";
import copy from "./copy.svg";
import add from "./ic_add_black_24px.svg";
import apps from "./ic_apps_black_24px.svg";
import book from "./book.svg";
import dropDown from "./ic_arrow_drop_down_white_24px.svg";
import dropUp from "./ic_arrow_drop_up_white_24px.svg";
import attachFile from "./ic_attach_file_black_24px.svg";
import attachImg from "./ic_add_pic.svg";
import chevronLeft from "./ic_chevron_left_white_24px.svg";
import chevronRight from "./ic_chevron_right_white_24px.svg";
import deleteForever from "./ic_delete_forever_black_24px.svg";
import done from "./ic_done_black_24px.svg";
import doneWhite from "./ic_done_white_24px.svg";
import drafts from "./ic_drafts_white_24px.svg";
import email from "./ic_email_white_24px.svg";
import help from "./ic_help_outline_black_24px.svg";
import helpNew from "./help_new.svg";
import edit from "./ic_mode_edit_black_24px.svg";
import editLight from "./edit-light.svg";
import more from "./ic_more_vert_white_24px.svg";
import report from "./ic_report_problem_black_24px.svg";
import send from "./ic_send_black_24px.svg";
import star from "./ic_star_outline_24px.svg";
import vpnKey from "./ic_vpn_key_black_24px.svg";
import work from "./ic_work_black_24px.svg";
import calendar from "./calendar.svg";
import desktop from "./desktop.svg";
import feed from "./feed.svg";
import gear from "./gear.svg";
import info from "./info.svg";
import news from "./news.svg";
import print from "./print.svg";
import plusSmall from "./plus_small.svg";
import filter from "./filter.svg";
import selectArrow from "./select_arrow.svg";
import find from "./find_icon.svg";
import findNew from "./search.svg";
import downloadLink from "./downloadLink.svg";
import home from "./home.svg";
import close from "./ic_close_white_24px.svg";
import datepicker from "./datepicker.svg";
import datepickerSmall from "./datepicker_small.svg";
import editBig from "./edit.svg";
import questionMark from "./question_mark.svg";
import users from "./users.svg";
import trash from "./trash.svg";
import checkmark from "./checkmark.svg";
import adapted from "./adapted.svg";
import restore from "./restore.svg";
import bigGreenPlus from "./big_green_plus.svg";
import bigGreenEdit from "./big_green_edit.svg";
import alert from "./alert.svg";
import lock from "./lock.svg";
import checkIcon from "./i-check-thin.svg";
import filterList from "./ic-filter-list.svg";
import checkIconFat from "./ic-check-fat.svg";
import eyeClosed from "./eyeClosed.svg";
import eyeOpened from "./eyeOpened.svg";
import filterTiles from "./ic-filter-tiles.svg";
import phone from "./phone.svg";
import newMessage from "./newMessage.svg";
import trashRed from "./trashRed.svg";
import iAddLink from "./i-add-link.svg";
import editDashed from "./editDashed.svg";
import arrowDown from "./arrowDown.svg";
import arrowUp from "./arrowUp.svg";
import comment from "./comment.svg";
import homeworkIcon from "./homework.svg";
import attachDocument from "./attach-document.svg";
import circleRemove from "./i-modal-circle-remove.svg";
import circleWarning from "./i-modal-circle-warning.svg";
import download from "./download-icon.svg";
import printer from "./group-2.svg";
import numbers from "./numbers.svg";
import emptySchedule from "./empty-schedule.svg";
import criterionMark from "./criterion-mark.svg";
import allStudents from "./all-students.svg";
import currentStudents from "./current-students.svg";
import journalWithFinalMarks from "./journal-with-final-marks.svg";
import journalWithHW from "./journal-with-hw.svg";
import onlyJournalTable from "./only-journal-table.svg";
import downloadIcon from "./download-icon-cube-active.svg";
import smallDots from "./icon-dots.svg";
import mapMarker from "./map-marker.svg";
import marusia from "./marusia.svg";
import plusThin from "./plus-thin.svg";
import arrowUpThin from "./arrow_up.svg";
import arrowDownThin from "./arrow_down.svg";
import eyeInCircle from "./i-eye-in-circle.svg";
import playInCircle from "./i-play-in-circle.svg";
import play from "./i-play.svg";
import eye from "./i-eye.svg";
import copyBlack from "./ic_content_copy_black_24px.svg";
import arrowTop from "./i-arrow-top.svg";
import arrowBottom from "./i-arrow-bottom.svg";
import doubleArrowTop from "./i-arrow-double-top.svg";
import doubleArrowBottom from "./i-arrow-double-bottom.svg";
import bell from "./i-bell-thin.svg";
import closeLight from "./i-close.svg";
import doubleChecked from "./double_checked.svg";
import alertSimple from "./alert_simple.svg";
import lightEdit from "./i-icon_edit_light.svg";
import shape from "./shape.svg";
import SchoolLogo from "./dnevnik-logo-cmyk.png";
import MidProfEducationLogo from "./epos-spo-logo.png";
import KindergardenLogo from "./kindergarden-logo.png";
import AddEducationLogo from "./kindergarden-logo.png";
import checkIconNew from "./i-check.svg";
import checkList from "./i-check-list.svg";
import banner from "./banner.svg";
import bannerChildren from "./banner-children.svg";
import linkArrow from "./link-arrow.svg";
import dotsVertical from "./dots-vertical.svg";
import eposChatIcon from "./epos-chat-icon.svg";
import vkMessengerIcon from "./vk-messenger-icon.svg";
import headphone from "./headphone.svg";
import headphoneChat from "./headphone-chat.svg";
import editChat from "./edit-chat.svg";
import deleteChat from "./delete-chat.svg";
import editChatDisabled from "./edit-chat-disabled.svg";
import deleteChatDisabled from "./delete-chat-disabled.svg";
import unpublishedChatCall from "./unpublished-chat-call.svg";
import publishedChatCall from "./published-chat-call.svg";
import messageOutline from "./message-outline.svg";
import calendarBlank from "./calendar-blank.svg";
import doubleArrowDown from "./double_arrow_down.svg";
import doubleArrowUp from "./double_arrow_up.svg";

export {
	closeLight,
	arrowTop,
	arrowBottom,
	doubleArrowBottom,
	doubleArrowTop,
	copyBlack,
	eye,
	play,
	eyeInCircle,
	playInCircle,
	arrowUpThin,
	arrowDownThin,
	dots,
	plus,
	empty,
	history,
	arrowDown,
	arrowUp,
	editDashed,
	stationery,
	arrowBack,
	expandLess,
	expandMore,
	circleOk,
	remove,
	trashRed,
	add,
	book,
	copy,
	calendar,
	desktop,
	feed,
	gear,
	circleAdd,
	apps,
	dropDown,
	dropUp,
	attachFile,
	attachImg,
	chevronLeft,
	chevronRight,
	deleteRed,
	deleteForever,
	done,
	doneWhite,
	drafts,
	email,
	help,
	edit,
	editLight,
	more,
	report,
	send,
	star,
	vpnKey,
	work,
	info,
	news,
	print,
	plusSmall,
	filter,
	selectArrow,
	find,
	downloadLink,
	home,
	close,
	datepicker,
	datepickerSmall,
	editBig,
	questionMark,
	users,
	trash,
	checkmark,
	adapted,
	restore,
	helpNew,
	findNew,
	removeNew,
	removeRed,
	bigGreenPlus,
	bigGreenEdit,
	alert,
	lock,
	checkIcon,
	checkIconNew,
	checkList,
	filterList,
	checkIconFat,
	eyeClosed,
	eyeOpened,
	filterTiles,
	phone,
	newMessage,
	iAddLink,
	comment,
	homeworkIcon,
	attachDocument,
	circleRemove,
	circleWarning,
	download,
	printer,
	numbers,
	emptySchedule,
	criterionMark,
	allStudents,
	currentStudents,
	journalWithFinalMarks,
	journalWithHW,
	onlyJournalTable,
	downloadIcon,
	smallDots,
	mapMarker,
	marusia,
	plusThin,
	bell,
	alertSimple,
	doubleChecked,
	lightEdit,
	shape,
	SchoolLogo,
	MidProfEducationLogo,
	KindergardenLogo,
	AddEducationLogo,
	banner,
	bannerChildren,
	linkArrow,
	dotsVertical,
	eposChatIcon,
	vkMessengerIcon,
	headphone,
	headphoneChat,
	editChat,
	deleteChat,
	editChatDisabled,
	deleteChatDisabled,
	unpublishedChatCall,
	publishedChatCall,
	messageOutline,
	calendarBlank,
	doubleArrowDown,
	doubleArrowUp
};
