const URL = "/api/ae_attendance_facts_unmatched_status";

class AEAttendanceFactsUnmatchedStatusService {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}

	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.CoreService.one(URL, id);
	}

	/**
	 * Обертка над методом getList из restangular
	 * @param params
	 * @returns {*}
	 */
	getList(params) {
		return this.CoreService.all(URL).getList(params);
	}
}

angular
	.module("ezd.backend")
	.service("AEAttendanceFactsUnmatchedStatusService", AEAttendanceFactsUnmatchedStatusService);
