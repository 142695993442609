

angular
	.module("ezd.common.ui")
	.directive("actionOnClickOutside", Directive);


/**
 *
 * @param $document
 * @returns {{restrict: string, link: (function(*, *, *))}}
 * @constructor
 */
Directive.$inject = ["$document"];
function Directive($document) {
	return {
		restrict: "A",
		link(scope, el, attrs) {
			function listener(e) {
				let currentEl = e.target;
				const elementName = _.find(el[0].attributes, {name: "action-on-click-outside-name"});
				const eventIgnore = _.find(e.target.attributes, {name: "action-on-click-outside-ignore"});
				// Пока есть текущий элемент
				while (currentEl) {
					// Если текущий элемент наш - значит клик произошел внутри
					if (currentEl === el[0]) {
						return;
					}
					// Если нет - двигаемся вверх по dom
					currentEl = currentEl.parentNode;
				}

				// Игнорируем элементы с аттрибутом action-on-click-outside-ignore,
				// если его значение равно значению аттрибута action-on-click-outside-name элемента директивы
				if (!e.target || !e.target.attributes["action-on-click-outside-ignore"]
					|| (!elementName || !eventIgnore || elementName.value !== eventIgnore.value)) {
					// В контексте scope вычислим (и выполним) выражение, переданное директиве
					scope.$eval(attrs.actionOnClickOutside);
					// И вызовем дайджест вручную, потому как $eval этого не делает
					scope.$applyAsync();
				}
			}

			// Регистрируем обработчик клика, который сработает только на погружении
			$document[0].addEventListener("click", listener, true);

			// На удалении элемента el
			el.on("$destroy", () => {
				// Удаляем обработчик
				$document[0].removeEventListener("click", listener, true);
			});
		}
	};
}
