import {Component} from "app/decorators/index";
import styles from "./ezd-popup-notification.local.scss";

@Component({
	selector: "ezdPopupNotification",
	bindings: {
		pnTitle: "=",
		pnFbname: "=",
		pnSbname: "=",
		pnClose: "&",
		pnSend: "&",
		pnTop: "=",
		pnLeft: "=",
		pnIsabove: "="
	},
	template:
		`<div class="${styles.popup_notification}" ng-style="$ctrl.position">
			<span class="${styles.popup_arrow_top}" ng-if="!$ctrl.pnIsabove"></span>
			<div class="${styles.popup_notification_content}">
				<ng-transclude></ng-transclude>
			</div>
			<span class="${styles.popup_arrow_bottom}" ng-if="$ctrl.pnIsabove"></span>
		</div>`,

	transclude: true
})
class EzdPopupNotificationComponent {
	/**
	 *
	 */
	$onInit() {
		this.position = {};

		if (this.pnTop && this.pnLeft) {
			this.position = {
				top: this.pnTop+"px",
				left: this.pnLeft+"px"
			};
		} else {
			this.position = {
				top: "auto",
				left: "auto"
			};
		}
	}
}

angular
	.module("ezd.common.ui")
	.component(EzdPopupNotificationComponent.selector, EzdPopupNotificationComponent);

