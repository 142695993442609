

angular
	.module("ezd.common.ui")
	.directive("selectOnClick", Directive);

/**
 *
 * @param $window
 * @returns {{restrict: string, link: (function(*, *, *))}}
 * @constructor
 */
Directive.$inject = ["$window"];
function Directive($window) {
	const directive = {
		restrict: "A",
		link(scope, element, attrs) {
			element.on("click", function () {
				if (!$window.getSelection().toString()) {
					this.setSelectionRange(0, this.value.length);
				}
			});
		}
	};

	return directive;
}