

angular
	.module("ezd.backend")
	.service("$$reportReliableSchoolService", reportReliableSchoolService);


/**
 *
 * @param REP
 */
reportReliableSchoolService.$inject = ["REP"];
function reportReliableSchoolService(REP) {
	const url = "api/reliable_school";
	const scheduleCountUrl = "api/reliable_school/schedule_count/json";
	const self = this;

	self.getMarkChange = getMarkChange;
	self.getScheduleChange = getScheduleChange;
	self.getScheduleCountReport = getScheduleCountReport;
	self.getStudyPeriods = getStudyPeriods;

	/**
	 * Загрузка отчета по количеству уроков для учеников
	 */
	function getScheduleCountReport(params) {
		return REP.all(scheduleCountUrl).getList(params);
	}

	function getStudyPeriods() {
		return REP.one(url, "study_periods/json").get();
	}

	function getScheduleChange() {
		return REP.one(url, "schedule_change/json").get();
	}

	function getMarkChange(params, teacherId) {
		if (teacherId) {
			return REP.one(url, "change_marks/" + teacherId).get(params);
		}

		return REP.one(url, "change_marks").get(params);
	}
}
