angular
	.module("ezd.backend")
	.factory("CMS_v4", CMS_v4);

CMS_v4.$inject = ["$cookies", "Restangular", "Interceptors", "$$profile"];

/**
 * @desc Создаем новый инстанс Restangular с конфигом для модуля CMS
 * @param $cookies
 * @param Restangular
 * @param Interceptors
 * @returns {*}
 * @constructor
 */
function CMS_v4($cookies, Restangular, Interceptors, $$profile) {
	return Restangular.withConfig((RestangularConfigurer) => {
		const headers = {Accept: "application/vnd.cms-v4+json"};
		if ($cookies.get("auth_token")) {
			headers["Auth-Token"] = $cookies.get("auth_token");
		}

		if ($cookies.get("profile_id")) {
			headers["Profile-Id"] = $cookies.get("profile_id");
			// RestangularConfigurer.setDefaultRequestParams({pid: $cookies.get("profile_id")});
		}

		if ($cookies.get("user_id")) {
			headers["User-Id"] = $$profile.currentProfile && $$profile.currentProfile.user_id ? $$profile.currentProfile.user_id : $cookies.get("user_id");
		}

		RestangularConfigurer.setDefaultHeaders(headers);
		RestangularConfigurer.setBaseUrl("/cms");
		RestangularConfigurer.setErrorInterceptor(Interceptors.error);
		RestangularConfigurer.addFullRequestInterceptor(Interceptors.fullRequest);
		RestangularConfigurer.addResponseInterceptor(Interceptors.responseInterceptor);
	});
}
