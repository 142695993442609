

angular
	.module("ezd.common.ui")
	.directive("checkEmail", checkEmailDir);

/**
 *
 * @returns {{restrict: string, require: string, link: checkEmailLink}}
 */
function checkEmailDir() {
	return {
		restrict: "A",
		require: "ngModel",
		link: checkEmailLink
	};
}

/**
 *
 * @param scope
 * @param elm
 * @param attrs
 * @param ngModelCtrl
 */
function checkEmailLink(scope, elm, attrs, ngModelCtrl) {
	// eslint-disable-next-line no-useless-escape
	const validPattern = /^(([^<>()\[\]\\.,;:\s@"!#%*=+{}'/|]+(\.[^<>()\[\]\\.,;:\s@"!#%*=+{}'/|]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Zа-яА-ЯёЁйЙщЩЫы\-0-9]+\.)+[a-zA-ZрфРФ]{2,10}))$/;

	ngModelCtrl.$parsers.unshift((viewValue) => {
		ngModelCtrl.$setValidity("checkEmail", isValid(viewValue));

		return viewValue;
	});

	ngModelCtrl.$formatters.unshift((modelValue) => {
		ngModelCtrl.$setValidity("checkEmail", isValid(modelValue));

		return modelValue;
	});

	/**
	 *
	 * @param value
	 * @returns {*|boolean}
	 */
	function isValid(value) {
		return value && value.length > 0 && validPattern.test(value);
	}
}
