class ReportImplementationCurriculum {
	static $inject = ["REP"];


	/**
	 * Constructor
	 * @param REP
	 */
	constructor(REP) {
		this.REP = REP;
		this.URL = "/api/implementation_curriculum";
	}


	/**
	 * Get JSON
	 * @param params {[key: string]: number}
	 * student_profile_id
	 * parallel_curriculum_id
	 * class_unit_id
	 */
	getJson(params) {
		return this.REP.one(this.URL, "json").get(params);
	}


	/**
	 * Get XLSX
	 * @param params {[key: string]: number}
	 * student_profile_id
	 * parallel_curriculum_id
	 * class_unit_id
	 */
	getXlsx(params) {
		const url = "/reports/api/implementation_curriculum/xlsx?";
		const paramsArr = [];

		_.forOwn(params, (value, key) => {
			if (Boolean(value)) {
				paramsArr.push(`${key}=${value}`);
			}
		});

		window.open(url + paramsArr.join("&"));
	}
}

angular
	.module("ezd.backend")
	.service("ReportImplementationCurriculum", ReportImplementationCurriculum);
