

angular
	.module("ezd.common.ui")
	.directive("fileread", [function () {
		return {
			scope: {
				fileread: "="
			},
			link(scope, element) {
				element.bind("change", (changeEvent) => {
					const reader = new FileReader();
					reader.onload = function (loadEvent) {
						scope.$apply(() => {
							scope.fileread = loadEvent.target.result;
						});
					};
					if (changeEvent.target.files[0] instanceof Blob) {
						reader.readAsBinaryString(changeEvent.target.files[0]);
					}
				});
			}
		};
	}]);