angular
	.module("ezd.common.ui")
	.directive("ezdInfiniteScroll", Directive);

/**
 * Кастомный бесконечный скролл. Использование: <div ezd-infinite-scroll="callback()">
 * @returns {{restrict: string, link: ezdInfiniteScroll}}
 * @constructor
 */
function Directive() {
	return {
		restrict: "A",
		link: ezdInfiniteScroll
	};
}


/**
 *
 * @param scope
 * @param element
 * @param attrs
 */
function ezdInfiniteScroll(scope, element, attrs) {
	let scrolled = false;
	const raw = element[0];

	const onScrollBinding = () => {
		if (raw.scrollTop + raw.offsetHeight + 10 > raw.scrollHeight) {
			if (!scrolled) {
				scope.$apply(attrs.ezdInfiniteScroll);
				scrolled = true;
			}
		} else {
			scrolled = false;
		}
	};

	element.on("scroll", onScrollBinding);

	scope.$on("$destroy", () => {
		element.off("scroll", onScrollBinding);
	});
}

