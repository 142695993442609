import "./style.css";

import _getOr from "lodash/fp/getOr";

angular
	.module("ezd.common.ui")
	.config(["hotkeysProvider", (hotkeysProvider) => {
		hotkeysProvider.useNgRoute = false;
	}])
	.directive("treeAutocomplete", () => {
		return {
			restrict: "E",
			template: Template,
			bindToController: {
				result: "=",
				getNodes: "&",
				isTextarea: "=",
				textareaPlaceholder: "@",
				containerClass: "@",
				fieldClass: "@",
				onFocusCallback: "&",
				onBlurCallback: "&"
			},
			controller: TreeAutocompleteController,
			controllerAs: "trac"
		};
	});


/**
 *
 * @returns {string}
 * @constructor
 */
function Template() {
	return `
		<div class="tree-autocomplete__container {{trac.containerClass || ''}}">
			<input
				ng-if="!trac.isTextarea"
				class="ezd-input tree-autocomplete__input-field {{trac.fieldClass || ''}}"
				ng-model="trac.result"
				ng-trim="false"
				ng-change="trac.updateNodePath()"
				ng-blur="trac.onBlurCallback()"
				ng-focus="trac.onFocusCallback()"/>
			<textarea
				ng-if="trac.isTextarea"
				placeholder="{{trac.textareaPlaceholder}}"
				class="ezd-textarea tree-autocomplete__textarea {{trac.fieldClass || ''}}"
				ng-model="trac.result"
				ng-change="trac.updateNodePath()"
				ng-blur="trac.onBlurCallback()"
				ng-focus="trac.onFocusCallback()"
				ng-trim="false"></textarea>
			<div class="tree-autocomplete__hint-container">
				<span
					data-index="{{$index}}"
					data-token-index="{{trac.currentTokenIndex}}"
					class="tree-autocomplete__hint-container__node{{$index === trac.currentTokenIndex ? '-active' : ''}}"
					ng-repeat="node in trac.currentNodes track by node.id"
					ng-bind="node.data"
					ng-click="trac.chooseNodeByIndex($index)"></span>
			</div>
		</div>
	`;
}

TreeAutocompleteController.$inject = ["$scope", "$element", "$attrs", "hotkeys"];

/**
 *
 * @param $scope
 * @param $element
 * @param $attrs
 * @param hotkeys
 * @constructor
 */
function TreeAutocompleteController($scope, $element, $attrs, hotkeys) {
	const vm = this;


	/**
	 *
	 * BINDINGS
	 *
	 */


	vm.updateNodePath = updateNodePath;
	vm.addTokenToResult = addTokenToResult;
	vm.chooseNodeByIndex = chooseNodeByIndex;
	vm.isTokenInTokens = isTokenInTokens;


	/**
	 *
	 * VARIABLES
	 *
	 */


	// Если есть метод для получения нод - вызовем его, иначе получим заглушку
	vm.nodes = setLinksInNodes(vm.getNodes() || convertGraphWithArraysToPlain(getBaseNodes()));
	vm.tokens = getTokensFromNodes(vm.nodes);

	vm.currentNodes = [];
	vm.currentTokenIndex = 0;

	vm.allowHotkeysInTags = ["INPUT", "TEXTAREA"];

	vm.sentenceSplitters = /[.|\n]/;
	vm.punctuation = /[\s|.|,]/;


	/**
	 *
	 * RUN
	 *
	 */

	bindHotkeys($scope);
	vm.updateNodePath();


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */

	/**
	 * Биндим хоткеи к скоупу, чтобы при его уничтожении удалились и бинды (иначе отслеживать самостоятельно)
	 * @param scope
	 */
	function bindHotkeys(scope) {
		hotkeys.bindTo(scope)
			.add({
				combo: "ctrl+[",
				description: "Сделать активной предыдущую ноду",
				callback: setPrevNodeActive,
				allowIn: vm.allowHotkeysInTags
			})
			.add({
				combo: "ctrl+]",
				description: "Сделать активной следующую ноду",
				callback: setNextNodeActive,
				allowIn: vm.allowHotkeysInTags
			})
			.add({
				combo: "ctrl+enter",
				description: "Добавить содержимое ноды в строку",
				callback: chooseCurrentNode,
				allowIn: vm.allowHotkeysInTags
			});
	}


	/**
	 * Обновляем текущий набор токенов исходя из введенной строки
	 */
	function updateNodePath() {
		const cleanString = vm.result || "";
		const lastSymbol = cleanString[cleanString.length - 1];
		const isPuncuationSymbolAtTheEnd = vm.punctuation.test(lastSymbol);

		const sentence = _.last(_.compact((cleanString).split(vm.sentenceSplitters)));
		const tokens = _.compact((sentence || "").split(vm.punctuation));
		const cleanTokens = getCleanTokens(tokens || [], vm.tokens, !isPuncuationSymbolAtTheEnd);

		// const isLastTokenInCommonTokens = isTokenInTokens(vm.tokens, tokens.pop(), !isPuncuationSymbolAtTheEnd);

		if (cleanTokens.length > 0 && isPuncuationSymbolAtTheEnd) {
			// Если пробел - закончен ввод предыдущего слова
			vm.currentNodes = getLastNodesAfterInput(vm.nodes, cleanTokens);
		} else if (cleanTokens.length > 0) {
			// Если не пробел - значит продолжается ввод предыдущего слова
			vm.currentNodes = getLastNodesWhileInput(vm.nodes, cleanTokens);
		} else {
			// Если ничего (кроме пробелов и пунктуации) не введено - показываем ноды без предшественников
			vm.currentNodes = getStartNodes(vm.nodes);
		}

		vm.tokensCount = _getOr(0, "length")(vm.currentNodes);
		vm.currentTokenIndex = 0;
	}

	/**
	 * Выбираем из всех токенов те, что входят и в словарь goodTokens
	 * @param allTokens
	 * @param goodTokens
	 * @param partly
	 * @returns {*}
	 */
	function getCleanTokens(allTokens, goodTokens, partly) {
		return _(allTokens)
			.map((token, index, tokens) => {
				const isTokenLast = index === tokens.length - 1;
				if (
					isTokenHasEqualInTokens(goodTokens, token)
					|| (partly && isTokenLast && isTokenPartOfAnyToken(goodTokens, token))
				) {
					return token;
				}

				return null;
			})
			.compact().value();
	}

	/**
	 *
	 * @param tokens
	 * @param token
	 * @returns {boolean}
	 */
	function isTokenHasEqualInTokens(tokens, token) {
		return Boolean(_.find(tokens, (goodToken) =>
			(goodToken || "").toUpperCase() === (token || "").toUpperCase()
		));
	}

	/**
	 *
	 * @param tokens
	 * @param token
	 * @returns {boolean}
	 */
	function isTokenPartOfAnyToken(tokens, token) {
		return Boolean(_.find(tokens, (goodToken) =>
			(goodToken || "").toUpperCase().indexOf((token || "").toUpperCase()) === 0
		));
	}


	/**
	 * Есть ли среди токенов такой, чтобы заданный токен являлся его началом
	 * @param token
	 * @param tokens
	 * @returns {boolean}
	 */
	function isTokenInTokens(tokens, token, partly) {
		return partly
			? isTokenPartOfAnyToken(tokens, token)
			: isTokenHasEqualInTokens(tokens, token);
	}

	/**
	 * Получить ноды для выбора в процессе ввода слова
	 * @param nodes
	 * @param tokens
	 * @returns {Array}
	 */
	function getLastNodesWhileInput(nodes, tokens) {
		return _.filter(
			tokens.length > 1
				? getLastNodesForTokensList(nodes, _.initial(tokens))
				: getStartNodes(nodes),
			(node) => node.data.toUpperCase().indexOf(_.last(tokens).toUpperCase()) !== -1
		);
	}

	/**
	 * Получить ноды для нового слова
	 * @param nodes
	 * @param tokens
	 * @returns {*}
	 */
	function getLastNodesAfterInput(nodes, tokens) {
		return getLastNodesForTokensList(nodes, tokens);
	}

	/**
	 * Получить ноды на основе списка предшествующих слов
	 * @param nodes
	 * @param tokens
	 * @returns {*}
	 */
	function getLastNodesForTokensList(nodes, tokens) {
		if (tokens && tokens.length > 0) {
			return getLastNodesForTokensList(
				_.get(
					_.find(nodes, (node) => (node.replaceWith || node.data || "").toUpperCase() === _.head(tokens).toUpperCase()),
					"next"
				),
				_.tail(tokens)
			);
		}

		return nodes;
	}

	/**
	 * Получить ноды, если нет предыдущих слов
	 * @param nodes
	 * @returns {*}
	 */
	function getStartNodes(nodes) {
		return _(nodes).filter((node) => _.isEmpty(node.prev)).value();
	}

	/**
	 * Добавялем выбранный токен к строке
	 * @param token
	 */
	function addTokenToResult(token) {
		vm.result = vm.result
			? _.trim(_.flatten([_.initial(vm.result.split(" ")), token]).join(" ")) + " "
			: token;
		updateNodePath();
	}

	/**
	 * Проставляем между нодами ссылки для обеспечения простой двунаправленной связанности
	 * @param nodes
	 * @returns {Array}
	 */
	function setLinksInNodes(nodes) {
		return _.map(nodes, (node) => _.assign(node,
			{
				prev: _.filter(nodes, (n) => _.includes(node.prev, n.id)),
				next: _.filter(nodes, (n) => _.includes(node.next, n.id))
			})
		);
	}

	/**
	 * Пересобираем массив со словарём
	 * @param nodes
	 * @returns {*}
	 */
	function convertGraphWithArraysToPlain(nodes) {
		let i = 1;

		return _(nodes)
			.map((node) =>
				_.map(node.data, (token) => ({
					id: i++,
					data: token,
					appendAfter: node.appendAfter,
					replaceWith: node.replaceWith,
					old: {
						id: node.id,
						prev: node.prev,
						next: node.next
					}
				}))
			)
			.flatten()
			.map((node, index, array) => _.assign(node, {
				prev: _(array).filter((el) => _.includes(node.old.prev, el.old.id)).map("id").value(),
				next: _(array).filter((el) => _.includes(node.old.next, el.old.id)).map("id").value()
			}))
			.map((node) => _.assign(node, {old: _.noop()}))
			.sortBy("data")
			.value();
	}


	/**
	 *
	 * УПРАВЛЕНИЕ ТОКЕНАМИ
	 *
	 */

	/**
	 * Делаем активной следующую ноду
	 */
	function setPrevNodeActive() {
		vm.currentTokenIndex -= vm.currentTokenIndex > 0 ? 1 : 0;
	}

	/**
	 * Делаем активной предыдующую ноду
	 */
	function setNextNodeActive() {
		vm.currentTokenIndex += vm.currentTokenIndex < vm.currentNodes.length - 1 ? 1 : 0;
	}

	/**
	 * Добавляем в строку ноду по индексу
	 */
	function chooseNodeByIndex(index) {
		addTokenToResult(
			getFullTokenFromNode(
				vm.currentNodes[index]
			)
		);
		vm.onBlurCallback();
	}

	/**
	 * Добавляем значение текущей ноды в строку
	 */
	function chooseCurrentNode() {
		return chooseNodeByIndex(vm.currentTokenIndex);
	}

	/**
	 *
	 * @param node
	 * @returns {*|string}
	 */
	function getFullTokenFromNode(node) {
		return ((_.get(node, "replaceWith") || _.get(node, "data")) + _.get(node, "appendAfter")) || "";
	}


	/**
	 *
	 * ПРОЧЕЕ
	 *
	 */
	function getTokensFromNodes(nodes) {
		return _(nodes).map((node) => [node.data, node.replaceWith]).flattenDeep().compact().uniq().value();
	}


	/**
	 * Заглушка с нодами для теста
	 * @returns {*[]}
	 */
	function getBaseNodes() {
		return [
			{
				id: 1,
				data: [
					"перечитать",
					"дочитать",
					"прочитать",
					"читать"
				],
				appendAfter: " ",
				next: [2, 1020],
				prev: []
			},
			{
				id: 1020,
				data: [
					"параграф §"
				],
				replaceWith: "§",
				appendAfter: "",
				prev: [1, 2, 1020],
				next: [2, 1020]
			},
			{
				id: 2,
				data: [
					"страницу",
					"упражнение",
					"тетрадь",
					"задание",
					"учебник",
					"тему",
					"текст",
					"правило",
					"сборник",
					"стихотворение",
					"рассказ",
					"диалог",
					"материал ",
					"историю",
					"работу",
					"термины",
					"документы"
				],
				appendAfter: ", ",
				prev: [1, 2, 1020],
				next: [2, 1020]
			},
			{
				id: 3,
				data: ["определить"],
				appendAfter: " ",
				prev: [],
				next: [4]
			},
			{
				id: 4,
				data: [
					"слова",
					"тему",
					"правило",
					"правила",
					"глаголы",
					"существительные",
					"прилагательные",
					"вариант",
					"решение",
					"форму",
					"даты",
					"схему",
					"правописаниие",
					"написание",
					"значение"
				],
				appendAfter: ", ",
				prev: [3, 4],
				next: [4]
			},
			{
				id: 5,
				data: ["подчеркнуть"],
				appendAfter: " ",
				prev: [],
				next: [6]
			},
			{
				id: 6,
				data: [
					"слова",
					"правила",
					"глаголы",
					"существительные",
					"прилагательные",
					"вариант",
					"даты",
					"значения"
				],
				appendAfter: ", ",
				prev: [5, 6],
				next: [6]
			},
			{
				id: 7,
				data: ["приготовить"],
				appendAfter: " ",
				next: [8],
				prev: []
			},
			{
				id: 8,
				data: [
					"тетрадь",
					"задание",
					"работу",
					"конспект",
					"текст",
					"таблицу",
					"словарь",
					"рабочую тетрадь",
					"презентацию",
					"рассказ",
					"диалог",
					"материал",
					"бумагу",
					"клей",
					"документы",
					"ножницы",
					"роман",
					"карту",
					"ткань",
					"инструменты",
					"форму",
					"краски",
					"кисти",
					"картон",
					"карточку",
					"диск",
					"дневник",
					"карандаши",
					"монолог",
					"контурную карту",
					"схемы",
					"тесты"
				],
				appendAfter: ", ",
				prev: [7, 8],
				next: [8]
			},
			{
				id: 9,
				data: [
					"выучить",
					"изучить",
					"знать"
				],
				appendAfter: " ",
				prev: [],
				next: [10, 910]
			},
			{
				id: 910,
				data: [
					"параграф §"
				],
				replaceWith: "§",
				appendAfter: "",
				prev: [9, 10, 910],
				next: [910, 10, 11]
			},
			{
				id: 10,
				data: [
					"страницу",
					"тему",
					"определение",
					"правило",
					"стихотворение",
					"диалог",
					"монолог",
					"материал",
					"историю",
					"понятие",
					"характеристики",
					"глаголы",
					"существительные",
					"прилагательные",
					"примеры",
					"свойства",
					"функции"
				],
				appendAfter: ", ",
				prev: [9, 10, 910],
				next: [11, 10, 910]
			},
			{
				id: 11,
				data: [
					"наизусть"
				],
				appendAfter: " ",
				prev: [10],
				next: []
			},
			{
				id: 12,
				data: [
					"подобрать",
					"найти",
					"выбрать"
				],
				appendAfter: " ",
				prev: [],
				next: [13]
			},
			{
				id: 13,
				data: [
					"страницу",
					"вопросы",
					"задание",
					"предложение",
					"учебник",
					"тему",
					"конспект",
					"правило",
					"стихотворение",
					"понятия",
					"характеристики",
					"существительные",
					"прилагательные",
					"глаголы",
					"книгу",
					"рассказ",
					"повесть",
					"отрывок"
				],
				appendAfter: ", ",
				prev: [12, 13],
				next: [13]
			},
			{
				id: 14,
				data: ["дописать"],
				appendAfter: " ",
				prev: [],
				next: [15]
			},
			{
				id: 15,
				data: [
					"страницу",
					"упражнение",
					"вопросы",
					"задание",
					"предложения",
					"слова",
					"темы",
					"сочинение",
					"решение",
					"план",
					"тест",
					"практикум",
					"работу",
					"определение",
					"отрывок",
					"статью",
					"монолог",
					"диалог",
					"план работ"
				],
				appendAfter: ", ",
				prev: [14, 15],
				next: [15]
			},
			{
				id: 16,
				data: ["объяснить"],
				appendAfter: " ",
				prev: [],
				next: [17]
			},
			{
				id: 17,
				data: [
					"вопрос",
					"правило",
					"тему",
					"задачу",
					"пункт",
					"характеристики",
					"формулы",
					"почему",
					"роль"
				],
				appendAfter: ", ",
				prev: [16, 17],
				next: [17]
			},
			{
				id: 18,
				data: ["рассказать"],
				appendAfter: " ",
				prev: [],
				next: [19, 1819]
			},
			{
				id: 1819,
				data: [
					"параграф §"
				],
				replaceWith: "§",
				appendAfter: "",
				prev: [18, 19, 1819],
				next: [19, 1819]
			},
			{
				id: 19,
				data: [
					"страницу",
					"задание",
					"тему",
					"текст",
					"сочинение",
					"стихотворение",
					"монолог",
					"диалог",
					"рассказ",
					"план",
					"историю",
					"характеристики",
					"содержание",
					"отрывок"
				],
				appendAfter: ", ",
				prev: [18, 19, 1819],
				next: [19, 1819]
			},
			{
				id: 20,
				data: ["оформить"],
				appendAfter: " ",
				prev: [],
				next: [21]
			},
			{
				id: 21,
				data: [
					"страницу",
					"тетрадь",
					"рабочую тетрадь",
					"работу",
					"конспект",
					"текст",
					"презентацию",
					"план",
					"документ"
				],
				appendAfter: ", ",
				prev: [20, 21],
				next: [21]
			},
			{
				id: 22,
				data: ["написать"],
				appendAfter: " ",
				prev: [],
				next: [23]
			},
			{
				id: 23,
				data: [
					"вопросы",
					"ответы",
					"работу",
					"конспект",
					"текст",
					"правила",
					"стихотворения",
					"план",
					"предложения",
					"существительные",
					"глаголы",
					"прилагательные",
					"слова",
					"монолог",
					"диалог",
					"рассказ",
					"сочинение",
					"изложение",
					"документ",
					"примеры",
					"отрывок"
				],
				appendAfter: ", ",
				prev: [22, 23],
				next: [23]
			},
			{
				id: 24,
				data: ["нарисовать"],
				appendAfter: " ",
				prev: [],
				next: [25]
			},
			{
				id: 25,
				data: [
					"презентацию",
					"рисунок",
					"карту",
					"карточку"
				],
				appendAfter: ", ",
				prev: [24, 25],
				next: [25]
			},
			{
				id: 26,
				data: ["подписать"],
				appendAfter: " ",
				prev: [],
				next: [27]
			},
			{
				id: 27,
				data: [
					"рисунок",
					"страницу",
					"карту",
					"тетрадь"
				],
				appendAfter: ", ",
				prev: [26, 27],
				next: [27]
			},
			{
				id: 28,
				data: [
					"завершить",
					"доделать"
				],
				appendAfter: " ",
				prev: [],
				next: [29]
			},
			{
				id: 29,
				data: [
					"страницу",
					"упражнение",
					"тетрадь",
					"работу",
					"конспект",
					"рассказ",
					"диалог",
					"монолог",
					"презентацию",
					"рисунок",
					"карту",
					"карточку",
					"сочинение",
					"изложение",
					"предложения",
					"документ"
				],
				appendAfter: ", ",
				prev: [28, 29],
				next: [29]
			},
			{
				id: 30,
				data: ["построить"],
				appendAfter: " ",
				prev: [],
				next: [31]
			},
			{
				id: 31,
				data: [
					"функцию",
					"таблицу"
				],
				appendAfter: ", ",
				prev: [30, 31],
				next: [31]
			},
			{
				id: 32,
				data: ["дать"],
				appendAfter: " ",
				prev: [],
				next: [33]
			},
			{
				id: 33,
				data: [
					"определение",
					"пример"
				],
				appendAfter: ", ",
				prev: [32, 33],
				next: [33]
			},
			{
				id: 34,
				data: ["не задано"],
				appendAfter: " ",
				prev: [],
				next: []
			}
		];
	}
}
