

angular
	.module("ezd.backend")
	.factory("$$news", news);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
news.$inject = ["Core"];
function news(Core) {
	const url = "/api/news_items";
	const Service = Core.service(url);

	// Service.getList = (query) => Core.all(url).getList(query);

	return Service;
}