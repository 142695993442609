

export default class OrgTypes {
	/**
	 * Школа
	 */
	static School = 1;
	/**
	 * ССУЗ (Среднее специальное учебное заведение)
	 */
	static MidProfEducation = 2;
	/**
	 * ВУЗ (Высшее учебное заведение)
	 */
	static University = 3;
	/**
	 * УДО (Учреждение дополнительного образования)
	 */
	static AddEducation = 4;
	/**
	 * ДОУ (Дошкольное образовательное учреждение)
	 */
	static Kindergarden = 5;

	static getById(id) {
		if (!id) {
			throw new DOMException('profile.org_type_id = ' + id);
		}
		return Object.entries(OrgTypes)
								 .find(([key, val]) => val === id)[0];
	}

	/**
	 * @return array of keys
	 */
	static getAvailable() {
		return ['School', 'MidProfEducation', 'Kindergarden', 'AddEducation', 'University'];
	}
}
