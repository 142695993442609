

angular
	.module("ezd.common.ui")
	.directive("textareaInPlace", Directive);


/**
 *
 * @returns {{restrict: string, scope: {value: string, textareaClass: string, spanClass: string, cb: string, maxlength: string}, template: string, link:
 *     (function(*, *))}}
 * @constructor
 */
function Directive() {
	return {
		restrict: "E",
		scope: {
			value: "=",
			textareaClass: "@",
			spanClass: "@",
			cb: "&",
			maxlength: "@",
			maxwidth: "@"
		},
		template: "<span ng-click=\"edit()\" class=\"{{ spanClass }}\" ng-bind=\"value\"></span>"
		+ "<textarea class=\"{{inputClass}}\" ng-model=\"value\" rows=\"3\" cols=\"35\" ng-blur=\"onBlur()\" maxlength=\"{{ maxlength || 500 }}\" ng-style=\"{maxWidth: maxwidth ? maxwidth : ''}\" style=\"min-height: 20px; min-width: 40px;\" />",
		link($scope, element) {
			// Let's get a reference to the input element, as we'll want to reference it.
			const inputElement = angular.element(element.children()[1]);

			// This directive should have a set class so we can style it.
			element.addClass("edit-in-place");

			// Initially, we're not editing.
			$scope.editing = false;

			// ng-click handler to activate edit-in-place
			$scope.edit = function () {
				$scope.editing = true;

				// We control display through a class on the directive itself. See the CSS.
				element.addClass("active");

				// And we must focus the element.
				// `angular.element()` provides a chainable array, like jQuery so to access a native DOM function,
				// we have to reference the first element in the array.
				inputElement[0].focus();
			};

			// When we leave the input, we're done editing.
			inputElement.prop("onblur", () => {
				$scope.editing = false;
				element.removeClass("active");
				$scope.cb();
			});
		}
	};
}
