

angular
	.module("ezd.backend")
	.service("$$sanPin", sanPinService);


sanPinService.$inject = ["Jersey", "$cookies", "$q"];

/**
 *
 * @param Jersey
 * @param $cookies
 * @param $q
 */
function sanPinService(Jersey, $cookies, $q) {
	const url = "/api/sanpin/";
	const vm = this;

	vm.collection = Jersey.all(url);

	vm.getAll = getAll;


	/**
	 *Получение всех норм санпина
	 */
	function getAll() {
		return vm.collection.getList();
	}
}

