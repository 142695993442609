const URL = "/api/class_units/attestation_fixation";


class CertificateHoldService {
	static name = "CertificateHold";
	static $inject = ["Core", "$q"];

	/**
	 * constructor
	 * @param {object} Core service
	 * @param {object} $q service
	 */
	constructor(Core, $q) {
		this.services = {$q};
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}

	/**
	 * изменение статуса фиксации
	 * @param {number} classUnitId id
	 * @returns {Promise<*>}
	 */
	async changeStatus(classUnitId) {
		if (classUnitId) {
			const url = `/api/class_units/${classUnitId}/attestation_fixation`;

			return await this.CoreService.all(url).customPOST();
		}
	}
}

angular
	.module("ezd.backend")
	.service(CertificateHoldService.name, CertificateHoldService);
