import {Component} from "app/decorators/component";
import template from "./wysiwyg-container.template.html";

import "./wysiwyg.scss";
import styles from "./wysiwyg-container.local.scss";
import merge from "lodash/fp/merge";

@Component({
	selector: "wysiwygContainer",
	template,
	bindings: {
		uploadConfig: "<",
		wysiwygConfig: "<",
		model: "=",
		attachments: "<",
		onLoadAttachment: "&",
		onRemoveAttachment: "&",
		required: "<",
		name: "@"
	}
})
export class WysiwygContainerComponent {
	static $inject = ["$q", "$mdDialog"];

	styles = styles;

	defaultWysiwygConfig = {
		config: {
			sanitize: false,
			toolbarPosition: "top",
			toolbar: [
				{
					name: "basicStyling",
					items: ["bold", "italic", "underline"]
				},
				{
					name: "baseToolbar",
					items: [
						"customImageDialog",
						"customUl",
						"customOl",
						"customTable",
						"customAttachment",
						"customEmpty",
						"customPreview"
					]
				},
				{
					name: "doers",
					items: ["undo", "redo"]
				}
			],
			buttons: {
				customTable: {
					type: "div",
					title: "Таблица",
					class: "wys-button table",
					specialCommand: "customAction('table')"
				},
				customOl: {
					type: "div",
					title: "Нумерованный список",
					class: "wys-button ol",
					command: "insertorderedlist"
				},
				customUl: {
					type: "div",
					title: "Маркированный список",
					class: "wys-button ul",
					command: "insertunorderedlist"
				},
				customImageDialog: {
					type: "div",
					title: "Добавить изображение",
					specialCommand: "customAction('image')",
					class: "wys-button img"
				},
				customLinkDialog: {
					type: "div",
					title: "Добавить ссылку",
					specialCommand: "customAction('link')",
					class: "wys-button a"
				},
				customAttachment: {
					type: "div",
					title: "Прикрепить файл",
					specialCommand: "customAction('attach')",
					class: "wys-button attach"
				},
				customPreview: {},
				customEmpty: {
					type: "div",
					title: "",
					specialCommand: "customAction('empty')",
					class: "button-empty-preview"
				}
			}
		},
		api: {
			customAction: this.$editorCustomDialog.bind(this)
		}
	};

	constructor($q, $mdDialog) {
		this.services = {$q, $mdDialog};
	}

	$onInit() {
		this.$wysiwygConfig = merge(this.defaultWysiwygConfig)(this.wysiwygConfig);
	}

	/**
	 * WYSIWIG
	 * */
	$editorCustomDialog(dialogType) {
		const {$q, $mdDialog} = this.services;
		this.customDialogDefer = $q.defer();
		const element = document.querySelector("file-upload-link input");
		switch (dialogType) {
			case "attach":
				if (element.fireEvent) {
					element.fireEvent("onclick");
				} else {
					const evObj = document.createEvent("Events");

					evObj.initEvent("click", true, false);
					element.dispatchEvent(evObj);
				}
				this.customDialogDefer.reject("");
				break;

			case "table":
				$mdDialog.show({
					template: "<wys-insert-table-modal></wys-insert-table-modal>",
					parent: angular.element(document.body),
					targetEvent: null,
					clickOutsideToClose: true
				}).then((html) => {
					this.customDialogDefer.resolve(html);
				}).catch(() => {
					this.customDialogDefer.reject("");
				});
				break;

			default:
				this.customDialogDefer.reject("");
				break;
		}

		return this.customDialogDefer.promise;
	}

	$onLoadAttachment(response) {
		console.log(response);
		this.onLoadAttachment({attachment: response});
	}
}
