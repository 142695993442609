export class ReportMissingService {
	static name = "ReportMissing";
	static $inject = ["REP"];

	baseUrl = "/api/missing";


	/**
	 *
	 * @param REP
	 */
	constructor(REP) {
		this.services = {REP};
	}


	/**
	 *
	 * @param params
	 *  date=DD.MM.YYYY - required
	 *  class_level_id
	 *  class_unit_id
	 *  building_id
	 *  nonattendance_reason_id
	 * @returns {Promise}
	 */
	async getJSONByClassLevel(params) {
		try {
			const {REP} = this.services;

			return await REP.all(`${this.baseUrl}/by_class_level/json`).getList(params);
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 *
	 * @param params
	 * @returns {string}
	 */
	getXLSXByClassLevelUrl(params) {
		return _.compact([
			`/reports${this.baseUrl}/by_class_level/xlsx`,
			params.date && `?date=${params.date}`,
			params.class_level_id && `&class_level_id=${params.class_level_id}`,
			params.class_unit_id && `&class_unit_id=${params.class_unit_id}`,
			params.nonattendance_reason_id && `&nonattendance_reason_id=${params.nonattendance_reason_id}`,
			params.building_id && `&building_id=${params.building_id}`,
			params.academic_year_id && `&academic_year_id=${params.academic_year_id}`
		]).join("");
	}


	/**
	 *
	 * @param params
	 *  class_unit_id - required
	 *  date - required
	 *  nonattendance_reason_id
	 * @returns {Promise}
	 */
	async getJSONByDate(params) {
		try {
			const {REP} = this.services;

			return await REP.one(`${this.baseUrl}/by_date/json`).get(params);
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 *
	 * @param params
	 * @returns {string}
	 */
	getXLSXByDateUrl(params) {
		return _.compact([
			`/reports${this.baseUrl}/by_date/xlsx`,
			params.date && `?date=${params.date}`,
			params.class_unit_id && `&class_unit_id=${params.class_unit_id}`,
			params.nonattendance_reason_id && `&nonattendance_reason_id=${params.nonattendance_reason_id}`
		]).join("");
	}


	/**
	 *
	 * @param params
	 *  begin_date=DD.MM.YYYY - required
	 *  end_date=DD.MM.YYYY - required
	 *  class_unit_id - required
	 * @returns {Promise}
	 */
	async getJSONByClassUnit(params) {
		try {
			const {REP} = this.services;

			return await REP.one(`${this.baseUrl}/by_class_unit/json`).get(params);
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 *
	 * @param params
	 * @returns {string}
	 */
	getXLSXByClassUnitUrl(params) {
		return _.compact([
			`/reports${this.baseUrl}/by_class_unit/xlsx`,
			params.begin_date && `?begin_date=${params.begin_date}`,
			params.end_date && `&end_date=${params.end_date}`,
			params.class_unit_id && `&class_unit_id=${params.class_unit_id}`
		]).join("");
	}

	/**
	 *
	 * @param params
	 * @returns {string}
	 */
	getXLSXByClassUnitReasonUrl(params) {
		return _.compact([
			`/reports${this.baseUrl}/by_class_unit_reason/xlsx`,
			params.begin_date && `?begin_date=${params.begin_date}`,
			params.end_date && `&end_date=${params.end_date}`,
			params.class_unit_id && `&class_unit_id=${params.class_unit_id}`,
			params.nonattendance_reason_ids && `&nonattendance_reason_ids=${params.nonattendance_reason_ids}`,
			angular.isDefined(params.holiday_letters) && `&holiday_letters=${params.holiday_letters}`
		]).join("");
	}


	/**
	 *
	 * @param params
	 *  student_id - required
	 *  nonattendance_reason_id
	 *  date
	 * @returns {Promise}
	 */
	async getJSONByStudent(params) {
		try {
			const {REP} = this.services;

			return await REP.all(`${this.baseUrl}/by_student/json`).getList(params);
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 *
	 * @param params
	 * @returns {string}
	 */
	getXLSXByStudentUrl(params) {
		return _.compact([
			`/reports${this.baseUrl}/by_student/xlsx`,
			params.date && `?date=${params.date}`,
			params.student_id && `&student_id=${params.student_id}`,
			params.nonattendance_reason_id && `&nonattendance_reason_id=${params.nonattendance_reason_id}`
		]).join("");
	}
}


angular
	.module("ezd.backend")
	.service(ReportMissingService.name, ReportMissingService);
