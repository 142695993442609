

angular
	.module("ezd.backend")
	.factory("Subscriptions", subscriptions);


/**
 *
 * @param Core
 * @returns {Object|*}
 */
subscriptions.$inject = ["Core"];

function subscriptions(Core) {
	const vm = Core.service("/api/subscriptions");

	vm.run = run;

	return vm;

	function run() {
		return Core.all("api").all("subscriptions").all("run").getList();
	}
}

