export default class EomUrl {
	static production = "https://uchebnik-epos.permkrai.ru";
	static test = "https://eom.test.dfsystems.ru";
	static dev = "https://eom.test.dfsystems.ru";

	static env = require("NODE_ENV");

	/**
	 * Получение ссылки на ЭОМ в зависимости от профиля сборки
	 */
	static getBaseUrl() {
		switch (this.env) {
			case "production":
				return this.production;
			case "test":
				return this.test;
			case "dev":
				return this.dev;
			default:
				return this.dev;
		}
	}
}
