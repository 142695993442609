import {Component} from "app/decorators/index";
import styles from "./ezd-inline-edit.local.scss";
import template from "./ezd-inline-edit.html";


@Component({
	selector: "ezdInlineEdit",
	template,
	bindings: {
		model: "=",
		type: "@",
		maxLength: "@",
		onChange: "&"
	}
})
export class ezdInlineEditComponent {
	static $inject = ["$element", "$timeout"];

	/**
	 *
	 */
	constructor($element, $timeout) {
		this.services = {$element, $timeout};
		this.styles = styles;
	}

	/**
	 *
	 */
	$onInit() {
		this.edit = false;
	}

	/**
	 *
	 */
	editModel() {
		const {$element, $timeout} = this.services;
		this.edit = true;
		$timeout(() => {
			const inp = $element.find("input");
			inp.focus();
		}, 0);
	}

	/**
	 *
	 */
	stopEditModel() {
		this.edit = false;
	}

	/**
	 *
	 */
	$onChange() {
		const {$timeout} = this.services;

		$timeout(() => {
			this.onChange({$event: this.model});
		}, 0);
	}

	/**
	 *
	 */
	checkInput($event) {
		if ($event.keyCode === 13) {
			this.stopEditModel();

			return;
		}

		if (this.type && this.type === "number") {
			// проверяем ввод с клавиатуры на числа/точки/delete/backspace
			if (!(($event.keyCode >= 48 && $event.keyCode <= 57) || $event.keyCode === 190 || $event.keyCode === 46 || $event.keyCode === 8)) {
				$event.preventDefault();
			}
		}

		if (this.maxLength) {
			this.maxLength = _.int(this.maxLength);
			if ($event.target.value.length >= this.maxLength) {
				this.model = "";
			}
		}
	}

	/**
	 *
	 */
	classesForSpan() {
		return `${this.styles["span-model"]} ${this.type !== "number" ? this.styles["not-a-number"] : ""}`;
	}
}

angular
	.module("ezd.common.ui")
	.component(ezdInlineEditComponent.selector, ezdInlineEditComponent);
