

angular
	.module("ezd.backend")
	.service("NewsCategory", NewsCategory);

/**
 *
 * @param Core
 * @constructor
 */
NewsCategory.$inject = ["Core"];
function NewsCategory(Core) {
	const url = "/api/news/category";
	const vm = this;

	vm.collection = Core.all(url);
	vm.bindOne = (id) => Core.one(url, id);
}