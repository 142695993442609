import {Component} from "app/decorators/index";
import template from "./ezd-journal-marks-mode.html";
import styles from "./ezd-journal-marks-mode.local.scss";

import attendanceType from "./icons/attendance_type.svg";
import normalType from "./icons/normal_type.svg";
import fastType from "./icons/fast_type.svg";

@Component({
	selector: "ezdJournalMarksMode",
	template,
	bindings: {
		controlForms: "<",
		selectedControlForm: "<",
		selectedMarksMode: "<",
		onChangeMode: "&",
		onChangeControlForm: "&"
	}
})
export class EzdJournalMarksModeComponent {
	/**
	 *
	 */
	constructor() {
		this.styles = styles;
		this.icons = {normalType, attendanceType, fastType};
	}

	setMode(mode) {
		this.onChangeMode({
			$event: mode
		});
	}

	setControlForm($event) {
		this.onChangeControlForm({$event});
	}
}


angular
	.module("ezd.common.ui")
	.component(EzdJournalMarksModeComponent.selector, EzdJournalMarksModeComponent);
