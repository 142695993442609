angular
	.module("ezd.backend")
	.service("$$SportLessons", $$SportLessons);

$$SportLessons.$inject = ["Restangular"];
function $$SportLessons(Restangular) {
	const urlLessons = "sport/api/lessons";

	const vm = this;

	vm.updateLessons = updateLessons;
	vm.listLessons = listLessons;

	/**
	 * Отправляем запрос на обновление списка уроков СП
	 */
	async function updateLessons() {
		return Restangular.all(urlLessons + "/update").customGET();
	}

	/**
	 * Получаем список уроков СП
	 */
	async function listLessons(params) {
		return Restangular.all(urlLessons + "/list").customGET("", params);
	}
}
