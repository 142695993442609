const URL = "/api/academic_debt_attempt";

class AcademicDebtAttempt {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.collection = Core.all(URL);
	}

	getTemplate() {
		return {
			order: 1,
			academic_debt_id: null,
			academic_debt_document: null,
			academic_debt_final_mark: null
		};
	}
}

angular
	.module("ezd.backend")
	.service("AcademicDebtAttempt", AcademicDebtAttempt);
