import template from "./Filter.html";


angular
	.module("ezd.common.ui")
	.directive("filter", Filter);

/**
 *
 * @returns {{restrict: string, template, replace: boolean, scope: {title: string, options: string, preselectIndex: string, preselectId: string, orderBy:
 *     string, limitTo: string, anyName: string, anyShow: string, getter: string, setter: string, viaRepeat: string}, link: Link}}
 * @constructor
 */
function Filter() {
	return {
		restrict: "E",
		template,
		replace: true,
		scope: {
			title: "@",
			options: "=",
			preselectIndex: "@",
			preselectId: "@",
			orderBy: "@",
			limitTo: "@",
			anyName: "@",
			anyShow: "@",
			getter: "&",
			setter: "&",
			viaRepeat: "@"
		},
		link: Link
	};
}

/**
 *
 * @param $scope
 * @constructor
 */
function Link($scope) {
	// DECLARATIONS
	$scope.getterSetter = getterSetter;

	// RUN
	if ($scope.preselectId) {
		getterSetter($scope.preselectId || _.get($scope.options[$scope.preselectIndex || 0], "id"));
	}

	// IMPLEMENTATION
	function getterSetter(id) {
		if (angular.isDefined(id)) {
			$scope.setter({id});
		}

		return $scope.getter();
	}
}
