

angular
	.module("ezd.backend")
	.service("$$inputHotkeys", $$inputHotkeys);

/**
 *
 * @param $rootScope
 */
$$inputHotkeys.$inject = ["$rootScope"];
function $$inputHotkeys($rootScope) {
	const vm = this;


	/**
	 *
	 * DECLARATION
	 *
	 */

	vm.setKeyDown = setKeyDown;
	vm.setKeyUp = setKeyUp;
	vm.registerCallback = registerCallback;
	vm.registerCallbackByCombination = registerCallbackByCombination;
	vm.regCb = registerCallbackByCombination;
	vm.flush = flush;


	/**
	 *
	 * VARIABLES
	 *
	 */

	// Буфер с текущими нажатыми клавишами
	vm.keyBuffer = [];
	// Доп буфер с предыдущим состоянием - чтобы изебгать лишних срабатываний (инициализирован чтобы отличаться)
	vm.oldBuffer = [1];
	// Коллекция с комбинациями и хоткеями для них
	vm.callbacks = [];
	// Режим выбора колбэков позволяет повесить на одни комбинации разные колбэки
	// и переключив режим получать разное поведение без сложностей
	vm.modeId = 0;
	// Блокировка для задержки на комбинациях
	vm.lock = false;


	/**
	 *
	 * CONSTANTS
	 *
	 */

	vm.keysAndItsNames = {
		up: 38,
		down: 40,
		left: 37,
		right: 39,
		ctrl: 17,
		alt: 18,
		esc: 27,
		shift: 16,
		n: 78,
		o: 79,
		del: 46,
		enter: 13
	};

	vm.combinationsToResetEverytime = [[27]];


	/**
	 *
	 * RUN
	 *
	 */

	$rootScope.$on("$routeChangeStart", () => vm.flush());


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */


	/**
	 *
	 * ОБРАБОТКА КЛАВИШ
	 *
	 */

	/**
	 * Добавляем в буфер код клавиши
	 * @param event
	 * @param modeId
	 */
	function setKeyDown(event, modeId) {
		setModeId(modeId);

		if (!_.includes(vm.keyBuffer, event.keyCode) && event.keyCode !== vm.keysAndItsNames.tab) {
			vm.keyBuffer.push(event.keyCode);
			// Вызывыем функцию через apply, потому что не знаем сколько будет передано параметров после modeId
			// [].splice.call используем, чтобы из объекта arguments сделать массив, который можно передать
			// в apply как группу параметров
			processBufferChanges.apply(vm, [].splice.call(arguments, 2, arguments.length));
		}
	}

	/**
	 * Удаляем из буфера код клавиши
	 * @param event
	 * @param modeId
	 */
	function setKeyUp(event, modeId) {
		setModeId(modeId);

		const index = vm.keyBuffer.indexOf(event.keyCode);
		if (index !== -1) {
			vm.keyBuffer.splice(index, 1);
			// Вызывыем функцию через apply, потому что не знаем сколько будет передано параметров после modeId
			// [].splice.call используем, чтобы из объекта arguments сделать массив, который можно передать
			// в apply как группу параметров
			processBufferChanges.apply(vm, [].splice.call(arguments, 2, arguments.length));
		}
	}

	/**
	 * Обрабатываем изменения, происходящие в буфере
	 */
	function processBufferChanges() {
		if (!isLocked() && isBufferChanged()) {
			setLocked();
			setOldBufferFromCurrent();
			// Вызывыем функцию через apply, потому что не знаем сколько будет передано параметров
			// [].splice.call используем, чтобы из объекта arguments сделать массив, который можно передать
			// в apply как группу параметров
			runCallbackByKeys.apply(vm, [].concat([vm.keyBuffer]).concat([].slice.call(arguments)));
			unsetLocked();
		}
	}


	/**
	 *
	 * РАБОТА С КОЛБЭКАМИ
	 *
	 */

	/**
	 * Сбрасываем колбэки и, на всякий случай, буферы и переменные
	 * Используется при переходе между экранами
	 */
	function flush() {
		vm.callbacks = [];
		vm.keyBuffer = [];
		vm.oldBuffer = [1];
		vm.modeId = 0;
		vm.lock = false;
	}

	/**
	 * Регистрируем колбэк для комбинации и режима
	 * @param keys
	 * @param callback
	 * @param modeId
	 * @returns {*|jQuery|HTMLElement}
	 */
	function registerCallback(keys, callback, modeId) {
		vm.callbacks.push({
			modeId: (typeof modeId !== "undefined") ? modeId : 0,
			keys,
			callback
		});

		return vm;
	}

	/**
	 * Регистрируем колбэк по комбинации
	 * @param combination
	 * @param callback
	 * @param modeId
	 */
	function registerCallbackByCombination(combination, callback, modeId) {
		const keysNames = combination.split("+");
		const keys = getKeysByItsNames(keysNames);
		if (keys.length > 0) {
			registerCallback(keys, callback, modeId);
		}

		return vm;
	}

	/**
	 * Находим подходящий по комбинации и режиму колбэк и вызываем его
	 * @param keys
	 * @returns {boolean}
	 */
	function runCallbackByKeys(keys) {
		let i = vm.callbacks.length - 1;
		let keysFound = false;
		while (i >= 0 && !keysFound) {
			if (vm.callbacks[i].modeId === vm.modeId && _.isEqual(vm.callbacks[i].keys, keys)) {
				resetKeysIfShould(keys);
				// Вызывыем функцию через apply, потому что не знаем сколько будет передано параметров
				// [].splice.call используем, чтобы из объекта arguments сделать массив, который можно передать
				// в apply как группу параметров
				vm.callbacks[i].callback.apply(null, [].splice.call(arguments, 1, arguments.length));
				keysFound = true;
			}
			i -= 1;
		}

		return keysFound;
	}


	/**
	 *
	 * БЛОКИРОВКА
	 *
	 */

	/**
	 * Проверяем блокировку
	 * @returns {boolean}
	 */
	function isLocked() {
		return vm.lock;
	}

	/**
	 * Устанавливаем блокировку
	 */
	function setLocked() {
		vm.lock = true;
	}

	/**
	 * Снимаем блокировку
	 */
	function unsetLocked() {
		vm.lock = false;
	}


	/**
	 *
	 * РАБОТА С БУФЕРОМ
	 *
	 */

	/**
	 * Сравниваем старый буфер с новым
	 * @returns {boolean}
	 */
	function isBufferChanged() {
		return !_.isEqual(vm.oldBuffer, vm.keyBuffer);
	}

	/**
	 * Сохраняем vm.keyBuffer в vm.oldBuffer
	 */
	function setOldBufferFromCurrent() {
		vm.oldBuffer = _.cloneDeep(vm.keyBuffer);
	}


	/**
	 *
	 * ПРОЧЕЕ
	 *
	 */

	/**
	 * Получаем коды клавиш по их названиям
	 * @param keysNames
	 * @returns {*}
	 */
	function getKeysByItsNames(keysNames) {
		const keys = [];
		for (let i = keysNames.length - 1; i >= 0; i -= 1) {
			if (typeof vm.keysAndItsNames[keysNames[i]] !== "undefined") {
				if (!_.includes(keys, vm.keysAndItsNames[keysNames[i]])) {
					keys.push(vm.keysAndItsNames[keysNames[i]]);
				}
			} else {
				return [];
			}
		}

		return keys;
	}

	/**
	 * Изменяем id режима выбора колбэков
	 * @param modeId
	 */
	function setModeId(modeId) {
		vm.modeId = (typeof modeId !== "undefined") ? modeId : 0;
	}

	function resetKeysIfShould(keys) {
		let i = vm.combinationsToResetEverytime.length - 1,
			shouldReset = false;
		while (i >= 0 && !shouldReset) {
			if (_.isEqual(vm.combinationsToResetEverytime[i], keys)) {
				shouldReset = true;
				vm.keyBuffer = [];
			}
			i -= 1;
		}

		return shouldReset;
	}
}
