const ROLE_IDS = {
	NONE: 1,
	TEACHER: 2,
	STUDENT: 3,
	PARENT: 4,
	SCHOOL_ADMIN: 5,
	SCHOOL_ADMIN_READ_ONLY: 6,
	EDUCATOR: 7,
	JUNIOR_EDUCATOR: 8,
	DEPUTY: 9,
	PRINCIPAL: 10,
	AE_EDUCATOR: 11,
	AE_ADMIN: 12,
	MID_PROF_EDUCATION_STUDENT: 13, /* Ученик СПО*/
	MID_PROF_EDUCATION_TEACHER: 14, /* Учитель СПО*/
	MID_PROF_EDUCATION_ADMIN: 15, /* Администратор СПО*/
	MID_PROF_EDUCATION_EDUCATOR: 16, /* Куратор СПО (= роли классный руководитель в школе)*/
	MID_PROF_EDUCATION_DEPUTY: 17, /* Заместитель директора СПО*/
	MID_PROF_EDUCATION_PRINCIPAL: 18, /* Директор СПО*/
	MID_PROF_EDUCATION_PARENT: 19 /* Родитель СПО*/
};

const ORG_TYPES = {
	SCHOOL: 1,
	MID_PROF_EDUCATION: 2,
	UNIVERSITY: 3,
	ADD_EDUCATION: 4,
	KINDERGARTEN: 5
};

/**
 *
 */
export class SmartiusService {
	static $inject = ["$cookies", "$$profile"];
	getGlobalString = getPageString.bind(undefined, "$global");

	constructor($cookies, $$profile) {
		Object.assign(this, {$cookies, $$profile});
	}

	/**
	 *
	 */
	async insertUserData(user) {
		try {
			const selectedProfile = await this.$$profile.getCurrentProfile();
			const sUser = {};
			this.showSMGuide();

			if (!user || !selectedProfile) {
				return;
			}

			sUser.name = [user.last_name, user.first_name, user.middle_name].join(" ");
			sUser.email = user.email;

			if (!selectedProfile.roles || selectedProfile.roles.length < 1) {
				return;
			}

			sUser.id = selectedProfile.id;

			const r = [];
			r.push(...this.collectRoles(selectedProfile));

			sUser.role = r.length ? r : [ROLE_IDS.NONE];

			if (!window.Smartius) {
				window.Smartius = {};
			}
			window.Smartius._user = sUser;
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 *
	 */
	clearUserData() {
		if (!window.Smartius) {
			window.Smartius = {};
		}
		window.Smartius._user = {
			id: null,
			role: [ROLE_IDS.NONE],
			name: null,
			email: null
		};
	}

	/**
	 *
	 */
	getRoleId(role, orgTypeId) {
		switch (role) {
			case "teacher":
				return this.isMidProfEducation(orgTypeId) ? ROLE_IDS.MID_PROF_EDUCATION_TEACHER : ROLE_IDS.TEACHER;
				break;
			case "student":
				return this.isMidProfEducation(orgTypeId) ? ROLE_IDS.MID_PROF_EDUCATION_STUDENT : ROLE_IDS.STUDENT;
				break;
			case "parent":
				return this.isMidProfEducation(orgTypeId) ? ROLE_IDS.MID_PROF_EDUCATION_PARENT : ROLE_IDS.PARENT;
				break;
			case "school_admin":
				return this.isMidProfEducation(orgTypeId) ? ROLE_IDS.MID_PROF_EDUCATION_ADMIN : ROLE_IDS.SCHOOL_ADMIN;
				break;
			case "school_admin_read_only": return ROLE_IDS.SCHOOL_ADMIN_READ_ONLY;
				break;
			case "educator":
				return this.isMidProfEducation(orgTypeId) ? ROLE_IDS.MID_PROF_EDUCATION_EDUCATOR: ROLE_IDS.EDUCATOR;
				break;
			case "junior_educator": return ROLE_IDS.JUNIOR_EDUCATOR;
				break;
			case "deputy":
				return this.isMidProfEducation(orgTypeId) ? ROLE_IDS.MID_PROF_EDUCATION_DEPUTY : ROLE_IDS.DEPUTY;
				break;
			case "principal":
				return this.isMidProfEducation(orgTypeId) ? ROLE_IDS.MID_PROF_EDUCATION_PRINCIPAL : ROLE_IDS.PRINCIPAL;
				break;
			case "ae_educator": return ROLE_IDS.AE_EDUCATOR;
				break;
			case "ae_admin": return ROLE_IDS.AE_ADMIN;
				break;

			default:
				return null;
				break;
		}
	}

	isMidProfEducation(orgTypeId) {
		return orgTypeId !== null && orgTypeId === ORG_TYPES.MID_PROF_EDUCATION;
	}

	/**
	 * собирает роли из профиля
	 */
	collectRoles(profile) {
		const r = [];
		const roles = (Array.isArray(profile.roles)) ? profile.roles : [];
		if (roles.length > 0) {
			roles.forEach((role) => {
				const roleId = this.getRoleId(role, profile.org_type_id);
				if (roleId && !r.includes(roleId)) {
					r.push(roleId);
				}
			});
		}
		if (profile.type) {
			const roleId = this.getRoleId(profile.type, profile.org_type_id);
			if (roleId && !r.includes(roleId)) {
				r.push(roleId);
			}
		}

		return r;
	}

	/**
	 * Показать Помощника, если текущая организация школа
	 */
	showSMGuide() {
		const orgWithAccess = ["Школа", "Среднее профессиональное образование"].includes(this.getGlobalString("organisation_type"));
		if (orgWithAccess) {
			if (window.parent === window) {
				window.Smartius = {
					apiUrl: "https://sgdev.permkrai.ru/api",
					staticUrl: "https://dev-manager.smguide.ru",
					publicKey: 2,
					_user: {
						id: null,
						role: [1],
						name: null,
						email: null
					}
				};
				let script = document.createElement("script");
				script.src = "https://dev-manager.smguide.ru/lib.js", document.head.appendChild(script);
			}
		}
	}
}

angular
	.module("ezd.backend")
	.service("SmartiusService", SmartiusService);
