angular
	.module("ezd.backend")
	.service("$$knowledgeField", $$knowledgeField);

/**
 *
 * @param LMSv2
 */
$$knowledgeField.$inject = ["Core"];
function $$knowledgeField(Core) {
	const url = "/api/knowledge_fields";
	const vm = this;


	vm.list = [];
	vm.collection = Core.all(url);


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);
}
