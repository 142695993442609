angular
	.module("ezd.backend")
	.service("$$teacherProfiles", teacherProfilesService);

/**
 *
 * @param Core
 */
teacherProfilesService.$inject = ["Core"];

/**
 *
 * @param Core
 */
function teacherProfilesService(Core) {
	const url = "/api/teacher_profiles";
	const vm = this;

	vm.list = [];
	vm.collection = Core.all(url);
	vm.collection.getList = (query) => Core.all(url).getList(query);
	vm.currentFilter = {};


	vm.bindOne = bindOne;
	vm.getPage = getPage;

	/**
	 * @type {getShortNameForTeacher}
	 */
	vm.getShortNameForTeacher = getShortNameForTeacher;

	/**
	 * @type {getFullName}
	 */
	vm.getFullName = getFullName;
	vm.getList = getList;

	vm.dismissTeacher = dismissTeacher;


	// расширяем модель
	Core.extendModel(url, (model) => {
		model.getShortName = getShortName;

		return model;
	});


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(url, id);
	}

	/**
	 *
	 * @param query
	 */
	function getList(query) {
		return vm.collection.getList(query);
	}

	/**
	 *
	 * @param num
	 * @param filter
	 */
	function getPage(num, filter) {
		const result = {
			data: [],
			pages: 1
		};
		const rest = Core.withConfig((RestangularConfigurer) => RestangularConfigurer.setFullResponse(true));
		let params = {page: num, per_page: 50};

		if (_.keys(filter).length !== 0) {
			params = angular.extend(params, filter);
		}

		return rest.all(url)
			.getList(params)
			.then((response) => {
				console.log(response);
				result.data = response.data;
				result.pages = _.int(response.headers("X-Pagination-Total-Pages")) || 1;
				result.total_entries = _.int(response.headers("X-Pagination-Total-Entries")) || 0;

				return result;
			});
	}

	/**
	 * Возвращает имя учителя в формате "фамилия И.О."
	 */
	function getShortNameForTeacher(teacher) {
		let teacherName;

		if (!teacher) {
			return;
		}

		// Формат профиля учителя, возвращаемого $$lessonReplacement.getAutocompleteTeachers
		if (teacher.fio) {
			return teacher.fio;
		}

		// виртуальный учитель
		if (teacher.first_name && teacher.last_name) {
			teacherName = [
				teacher.last_name,
				teacher.first_name,
				teacher.middle_name || ""
			];
		} else if (Boolean(teacher.name)) {
			teacherName = teacher.name.split(" ");
		} else if (teacher.full_name) {
			teacherName = teacher.full_name.split(" ");
		} else {
			teacherName = [
				teacher.last_name || "",
				teacher.first_name || "",
				teacher.middle_name || ""
			];
		}

		teacherName = _.map(teacherName, (partOfName, index) => {
			let $partOfName = partOfName.toLowerCase();
			// полная фамилия
			if (index === 0) {
				const partsNameArr = $partOfName.split("-");

				partsNameArr.forEach((item, idx) => {
					partsNameArr[idx] = item.charAt(0).toUpperCase() + item.slice(1);
				});

				$partOfName = partsNameArr.join("-");
				$partOfName += " ";
			} else if ($partOfName) {
				// первый символ имени в верхнем регистре
				$partOfName = $partOfName.charAt(0).toUpperCase() + ".";
			}

			return $partOfName;
		});

		return teacherName.join("");
	}


	/**
	 * @deprecated
	 * @param teacher
	 * @return {*}
	 */
	function getFullName(teacher) {
		if (!teacher) {
			return;
		}

		// Формат профиля учителя, возвращаемого $$lessonReplacement.getAutocompleteTeachers
		if (teacher.fullName) {
			return teacher.fullName;
		}

		// виртуальный учитель
		if (teacher.name || teacher.full_name) {
			return teacher.name || teacher.full_name;
		}

		// обычный учитель
		const teacherName = [
			teacher.last_name || "",
			teacher.first_name || "",
			teacher.middle_name || ""
		];

		return teacherName.join(" ");
	}


	/**
	 * Возвращает имя учителя в формате "фамилия И.О."
	 */
	function getShortName() {
		// this - экземпляр restangular модели профиля учителя
		const teacher = this;

		return getShortNameForTeacher(teacher);
	}

	/**
	 *
	 * @param teacher
	 */
	function dismissTeacher(teacher, date, transferredGroupIds) {
		const params = {
			date
		};
		if (transferredGroupIds) {
			params.transferred_group_ids = transferredGroupIds;
		}

		return Core.one(`${url}/dismiss`, teacher.id).remove(params);
	}
}
