import {Component} from "app/decorators/index";

import template from "./ezd-mask-date-picker-calendar.html";
import {chevronLeft, chevronRight} from "images/icons/index";
import styles from "./ezd-mask-date-picker-calendar.local.scss";

import _times from "lodash/fp/times";
import _flow from "lodash/fp/flow";
import _compact from "lodash/fp/compact";
import _join from "lodash/fp/join";

@Component({
	selector: "ezdMaskDatePickerCalendar",
	bindings: {
		date: "<",
		minDate: "<",
		maxDate: "<",
		onChange: "&"
	},
	template
})
export class EzdMaskDatePickerCalendar {
	icons = {chevronLeft, chevronRight};
	styles = styles;

	weekDays = [
		{number: 1, name: "пн"},
		{number: 2, name: "вт"},
		{number: 3, name: "ср"},
		{number: 4, name: "чт"},
		{number: 5, name: "пт"},
		{number: 6, name: "сб"},
		{number: 0, name: "вс"}
	];

	weeks = [];

	$onInit() {
		this.setDate();
	}

	$onChanges(changes) {
		if (changes.date) {
			this.setDate();
		}
	}

	/**
	 * Установка даты
	 */
	setDate() {
		this.now = moment(this.date).isValid() ? moment(this.date).clone() : moment();

		if (this.minDate && moment.isMoment(this.minDate) && this.minDate.isAfter(this.now)) {
			this.now = this.minDate.clone();
		}

		if (this.maxDate && moment.isMoment(this.maxDate) && this.maxDate.isBefore(this.now)) {
			this.now = this.maxDate.clone();
		}

		this.makeMonth();
	}


	/**
	 * Следующий месяц
	 */
	nextMonth() {
		if (this.maxDate && this.now.isSame(this.maxDate, "month")) {
			return;
		}
		this.now.add(1, "month");
		this.makeMonth();
	}

	/**
	 * Предыдущий месяц
	 */
	prevMonth() {
		if (this.minDate && this.now.isSame(this.minDate, "month")) {
			return;
		}
		this.now.subtract(1, "month");
		this.makeMonth();
	}

	/**
	 *
	 */
	makeMonth() {
		const start = this.now.clone().startOf("month");
		const end = this.now.clone().endOf("month");
		const startDay = start.day();
		this.weeks = [];
		const daysOnWeek = 7;

		if (startDay > 1 || startDay === 0) {
			const times = startDay === 0 ? 6 : (startDay - 1);
			start.subtract(times, "d");
		}

		while (start.isBefore(end, "d")) {
			const week = [];
			_times(() => {
				week.push({
					date: start.date(),
					isCurrentMonth: start.month() === this.now.month(),
					isToday: start.isSame(moment(), "d"),
					moment: start.clone()
				});
				start.add(1, "d");
			})(daysOnWeek);
			this.weeks.push(week);
		}

		this.nameMonth = this.now.format("MMMM") || "Месяц";
	}

	/**
	 *
	 * @param day
	 * @returns {string}
	 */
	dayClasses(day) {
		let date = false;
		if (this.date) {
			date = day.moment.isSame(this.date, "d");
		}

		const singleActive = this.date && day.moment.isSame(this.date, "d");

		const stylesDays = [
			this.styles.day,
			!day.isCurrentMonth && this.styles["not-current-month"],
			day.isToday && this.styles.today,
			date && this.styles.active,
			singleActive && this.styles["single-active"]
		];

		return _flow(
			_compact,
			_join(" ")
		)(stylesDays);
	}

	/**
	 * Выбрать дату
	 * @param day
	 */
	selectDay(day) {
		this.date = day.moment.clone();

		this.onChange({
			$event: {
				date: this.date
			}
		});
	}
}
