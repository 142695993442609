/**
 * Модуль календарно-тематического плана вд
 */
class ECCourseCalendarModule {
	/**
	 * Возвращает шаблон тематического плана вд
	 */
	getTemplate() {
		return {
			name: "", // название модуля
			ordinal: 1, // порядок модулей в плане
			topics: [] // массив ECCourseCalendarTopic
		};
	}
}


angular
	.module("ezd.backend")
	.service("ECCourseCalendarModule", ECCourseCalendarModule);
