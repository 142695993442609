angular
	.module("ezd.backend")
	.factory("$$aclRules", aclRules);

/**
 *
 * @param ACL
 * @returns {*|Object}
 */
aclRules.$inject = ["ACL"];
function aclRules(ACL) {
	return ACL.service("/api/acl_rules");
}

