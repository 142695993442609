

angular
	.module("ezd.backend")
	.service("$$gradeSystemsValidator", $$gradeSystemsValidator);

/**
 *
 */
function $$gradeSystemsValidator() {
	const vm = this;
	const ABCValues = ["A", "B", "C", "D", "E", "F"];
	const digitRegExp = /^(\d*[\.,])?\d+$/;

	vm.decimalPattern = /^(\d{1,3})+((\.|,)+\d{1,2})?$/;

	vm.isDigit = isDigit;
	vm.isABCValue = isABCValue;
	vm.isValidRangeField = isValidRangeField;

	vm.checkRange = checkRange;

	/**
	 * @deprecated
	 * @type {checkRangeValueInScale}
	 */
	vm.checkRangeValueInScale = checkRangeValueInScale;
	vm.checkRangeCollection = checkRangeCollection;
	vm.checkDecimalRangeCollection = checkDecimalRangeCollection;

	vm.validateCriterial = validateCriterial;
	vm.validateAbc = validateAbc;
	vm.validateHundred = validateHundred;
	vm.validateDecimal = validateDecimal;
	vm.validateFive = validateFive;
	vm.validateApprove = validateApprove;
	vm.validateNscale = validateNscale;


	/**
	 * Проверка на соответствие целым положительным числам (минимум 1 цифра)
	 * @param value number
	 * @returns {boolean}
	 */
	function isDigit(value) {
		return digitRegExp.test(value);
	}


	/**
	 * Проверка на соответствие одному из доступных значений ABC шкалы
	 * @param value
	 * @returns {boolean}
	 */
	function isABCValue(value) {
		return _.includes(ABCValues, value);
	}


	/**
	 *
	 * @param collection
	 * @param index
	 * @param rangeField
	 * @param inversion boolean флаг инверсии диапазона
	 * @returns {boolean}
	 */
	function isValidRangeField(collection, index, rangeField, inversion) {
		const checkedValue = _.float(collection[index].range[rangeField]);
		const isInv = (typeof inversion === "undefined") ? false : Boolean(inversion);
		//
		if (rangeField === "to") {
			return (checkedValue >= _.float(collection[index].range.from));
		}
		// если это нижняя граница диапазона и это первый диапазон, и шкала с инверсией, то нечего проверять
		if (isInv && index === 0) {
			return true;
		}
		// если это нижняя граница диапазона и это последний диапазон, и шкала без инверсии то нечего проверять
		if ((index + 1) >= collection.length && !isInv) {
			return true;
		}
		//
		if (isInv) {
			return (checkedValue > _.float(collection[index - 1].range.to));
		}

		// если это не последний диапазон
		return (checkedValue > _.float(collection[index + 1].range.to));
	}


	/**
	 * @deprecated
	 * @param value
	 * @param collection
	 * @param rangeField
	 * @returns {boolean}
	 */
	function checkRangeValueInScale(value, collection, rangeField) {
		let isValid = true;

		_.forEach(collection, (item) => {
			if (_.float(value) <= _.float(item[rangeField].to) || _.float(value) <= _.float(item[rangeField].from)) {
				isValid = false;
			}
		});

		return isValid;
	}


	/**
	 * Проверка диапазона чисел
	 * @param lowerValue number нижнее значение диапазона
	 * @param higherValue number верхнее значение диапазона
	 * @returns {boolean}
	 */
	function checkRange(lowerValue, higherValue) {
		// верхнее и нижнее значения диапазона должны быть числами
		if (!isDigit(lowerValue) || !isDigit(higherValue)) {
			return false;
		}

		return (_.float(lowerValue) < _.float(higherValue));
	}


	/**
	 * Проверка корректности заполнения диапазона
	 * @param collection [] коллекция диапазонов
	 * @param rangeField string название поля, содержащего диапазон { from: , to: }
	 * @param inversion boolean флаг инверсии
	 * @param isHundredType boolean флаг 100-бальной системы
	 * @returns {boolean}
	 */
	function checkRangeCollection(collection, rangeField, inversion, isHundredType) {
		let result = true;
		let previous;

		if (!inversion) {
			_.forEach(collection, (item) => {
				if (!checkRange(item[rangeField].from, item[rangeField].to)) {
					result = false;

					return false;
				}

				if (previous) {
					// проверяем корректность последовательности диапазонов.
					if (!checkRange(item[rangeField].to, previous[rangeField].from)) {
						result = false;

						return false;
					}
					// проверяем наличие дырок в диапазонах: 10-9 .. 7-6
					const delta = _.float((previous[rangeField].from - item[rangeField].to).toFixed(2));
					if (Boolean(isHundredType)) {
						if (delta !== 1) {
							result = false;

							return false;
						}
					} else {
						if (delta !== 0.01) {
							result = false;

							return false;
						}
					}
				}
				previous = item;
			});
		} else {
			_.forEach(collection, (item) => {
				if (!checkRange(item[rangeField].from, item[rangeField].to)) {
					result = false;

					return false;
				}

				if (Boolean(previous)) {
					// проверяем корректность последовательности диапазонов.
					if (!checkRange(previous[rangeField].to, item[rangeField].from)) {
						result = false;

						return false;
					}
					// проверяем наличие дырок в диапазонах: 10-9 .. 7-6
					const delta = _.float((item[rangeField].from - previous[rangeField].to).toFixed(2));
					if (Boolean(isHundredType)) {
						if (delta !== 1) {
							result = false;

							return false;
						}
					} else {
						if (delta !== 0.01) {
							result = false;

							return false;
						}
					}
				}
				previous = item;
			});
		}

		return result;
	}


	/**
	 *
	 * @param collection
	 * @param rangeField
	 * @returns {boolean}
	 */
	function checkDecimalRangeCollection(collection, rangeField) {
		let result = true;
		let previous;
		//
		_.forEach(collection, (item, n) => {
			// в 10-балльной шкале предпоследний диапазон, значение to не может быть валидным,
			// если валидны предыдущие диапазоны, так как в 10-балльной шкале не хватит диапазонов.
			// поэтому нет смысла проверять его.
			if (n >= (collection.length - 2)) {
				return false;
			}
			// проверяем корректность диапазона item
			if (!checkRange(item[rangeField].from, item[rangeField].to)) {
				result = false;
			}
			// проверяем наличие дырок в диапазонах: 10-9 .. 7-6
			if (((n + 1) < collection.length) && ((item[rangeField].from - collection[n + 1][rangeField].to) > 0)) {
				result = false;
			}
			// проверяем корректность последовательности диапазонов.
			if ((n !== 0) && !checkRange(item[rangeField].to, previous[rangeField].from)) {
				result = false;
			}
			previous = item;
		});

		return result;
	}


	/**
	 * Валидация критериальной шкалы
	 * @param scale [{}] коллекция шкал
	 * @returns {boolean}
	 */
	function validateCriterial(scale) {
		let result = true;
		_.forEach(scale.defaults, (criteria) => {
			// check n
			if (!checkRangeCollection(criteria.n, "range", criteria.inversion)) {
				result = false;
			}
			// check hundred
			if (!checkRangeCollection(criteria.hundred, "range", null, true)) {
				result = false;
			}
		});

		return result;
	}


	/**
	 * Валидация ABC шкалы
	 * @param scale {{}} шкала
	 * @returns {boolean}
	 */
	function validateAbc(scale) {
		let result = true;
		// check A, B, C, D, E, F values
		_.forEach(scale.names, (item) => {
			if (!isABCValue(item)) {
				result = false;

				return false;
			}
		});
		// check hundred
		_.forEach(scale.hundred, (item) => {
			if (!isDigit(item.range.to)) {
				result = false;

				return false;
			}
		});

		return result;
	}


	/**
	 * Валидация 100-балльной шкалы
	 * @param scale {{hundred}} шкала
	 * @returns {boolean}
	 */
	function validateHundred(scale) {
		return checkRangeCollection(scale.hundred, "range", null, true);
	}


	/**
	 * Валидация 10-балльной шкалы
	 * @param scale {{n, hundred}} шкала
	 * @returns {boolean}
	 */
	function validateDecimal(scale) {
		if (!checkRangeCollection(scale.n, "range")) {
			return false;
		}

		// check hundred
		return checkRangeCollection(scale.hundred, "range", null, true);
	}


	/**
	 * Валидация 5-балльной шкалы
	 * @param scale {{hundred: []}} шкала
	 * @returns {boolean}
	 */
	function validateFive(scale) {
		let result = true;
		// check hundred
		_.forEach(scale.hundred, (item) => {
			if (!isDigit(item.range.to)) {
				result = false;

				return false;
			}
		});

		return result;
	}


	/**
	 * Валидация "зачет/незачет" шкалы
	 * @param scale {{hundred: []}} шкала
	 * @returns {boolean}
	 */
	function validateApprove(scale) {
		let result = true;
		// check hundred
		_.forEach(scale.hundred, (item) => {
			if (!isDigit(item.range.to)) {
				result = false;

				return false;
			}
		});

		return result;
	}


	/**
	 * Валидация N-размерной шкалы
	 * @param scale {{}} шкала
	 * @returns {boolean}
	 */
	function validateNscale(scale) {
		let result = true;
		// check n
		if (!checkRangeCollection(scale.n, "range", scale.inversion)) {
			result = false;
		}
		// check hundred
		if (!checkRangeCollection(scale.hundred, "range", null, true)) {
			result = false;
		}

		return result;
	}
}
