/**
 * Декоратор для фильтра
 * @param name: String
 * @returns {Function}
 * @constructor
 */
export function Filter(name) {
	return function (filterClass) {
		const res = {name, controller: filterClass};

		const filterClassInstance = new filterClass();
		filterClassInstance.filter.$type = "filter";
		res.controller = filterClassInstance.filter;

		return res;
	};
}
