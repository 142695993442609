angular
	.module("ezd.backend")
	.service("$$classUnit", $$classUnit);

$$classUnit.$inject = ["Core", "$q", "TimetableAssignment", "bigQuery"];

/**
 *
 * @param Core
 * @param $q
 * @param TimetableAssignment
 * @param bigQuery
 */
function $$classUnit(Core, $q, TimetableAssignment, bigQuery) {
	const url = "/api/class_units";
	const vm = this;
	let abort = $q.defer();

	vm.list = [];
	vm.collection = Core.all(url).withHttpConfig({timeout: abort.promise});

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Core.one(url, id);

	vm.getList = (query) => vm.collection.getList(query);


	/**
	 *
	 */
	vm.abortAll = () => {
		abort.resolve();
		abort = $q.defer();
		vm.collection = Core.all(url).withHttpConfig({timeout: abort.promise});
	};


	/**
	 * Ставит реальные bells_timetable_id в классы в зависимости от даты
	 * Выставляет null, если нет привязки для класса в данный период времени
	 * @param classUnits
	 * @param checkDay. Format DD.MM.YYYY
	 * return classUnits
	 */
	vm.setRealBellsTimetableId = (classUnits, checkDay) => {
		const classUnitsIds = _.map(classUnits, "id").join(",");

		return bigQuery.getList(TimetableAssignment.collection, {class_unit_ids: classUnitsIds, per_page: 1000}, "class_unit_ids", 50)
			.then((bellsTimetableAssignments) => {
				const selectedDay = moment(checkDay, "DD.MM.YYYY").format("YYYY-MM-DD");

				_.forEach(classUnits, (classUnit) => {
					const bellTimetable = _.find(bellsTimetableAssignments, (timetable) => {
						if (timetable.class_unit_id === classUnit.id
							&& (moment(timetable.begin_date, "DD.MM.YYYY").isBefore(selectedDay)
								|| moment(timetable.begin_date, "DD.MM.YYYY").isSame(selectedDay))
							&& (moment(timetable.end_date, "DD.MM.YYYY").isAfter(selectedDay)
								|| moment(timetable.end_date, "DD.MM.YYYY").isSame(selectedDay))
						) {
							return true;
						}
					});

					if (bellTimetable) {
						classUnit.bells_timetable_id = bellTimetable.bells_timetable_id;
					} else {
						classUnit.bells_timetable_id = null;
					}
				});

				return classUnits;
			});
	};

	/**
	 *
	 * @param schoolId
	 * @param classLevelIds
	 */
	vm.getClassWithAttestationInfo = (schoolId, classLevelIds) => {
		return Core.all(url + "/attestation_fixation").getList({
			school_id: schoolId,
			class_level_ids: classLevelIds
		});
	};
}
