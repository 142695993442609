

angular
	.module("ezd.backend")
	.service("$$finalMark", finalMarkService);


/**
 *
 * @param $q
 * @param Core
 */
finalMarkService.$inject = ["$q", "Core"];
function finalMarkService($q, Core) {
	const vm = this;


	/**
	 *
	 * DECLARATION
	 *
	 */

	vm.bindOne = bindOne;
	vm.create = create;
	vm.getAttestationType = getAttestationType;
	vm.getMarkValue = getMarkValue;
	vm.getTemplate = getTemplate;
	vm.remove = remove;
	vm.saveById = saveById;


	/**
	 *
	 * VARIABLES
	 *
	 */
	vm.url = "/api/final_marks";
	vm.list = [];
	vm.collection = Core.all(vm.url);


	/**
	 *
	 * IMPLEMENTATION
	 *
	 */


	function getTemplate() {
		return {
			id: 123456,
			grade_system_type: "five",
			value: 5,
			academic_year_id: 3,
			module_id: null,
			period_id: null,
			attestation_period_id: null,
			subject_id: 1111,
			student_profile_id: 101010,
			attested: true,
			academic_debt: false,
			comment: null,
			is_year_mark: false,
			no_mark: false,
			mark_type: null
		};
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function bindOne(id) {
		return Core.one(vm.url, id);
	}


	/**
	 * Сохраняем итоговую оценку
	 * @param id
	 * @param data
	 * @returns {*}
	 */
	function saveById(id, data) {
		return vm.bindOne(id)
			.get()
			.then((fm) => {
				fm.academic_debt = data.academic_debt;
				fm.attested = data.attested;
				fm.no_mark = data.no_mark;
				fm.grade_system_type = data.grade_system_type;
				fm.value = data.value;

				return fm.save();
			})
			.then((finalMark) => {
				if (finalMark.errCode && finalMark.errCode !== 0) {
					throw finalMark;
				}

				return finalMark;
			});
	}


	/**
	 * Создаём итоговую оценку
	 * @param mark
	 * @returns {*}
	 */
	function create(mark) {
		return vm.collection
			.post(mark)
			.then((finalMark) => {
				if (finalMark.errCode && finalMark.errCode !== 0) {
					throw finalMark;
				}

				return finalMark;
			});
	}


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	function remove(id) {
		const deferred = $q.defer();

		if (id) {
			vm.bindOne(id)
				.get()
				.then((fm) => fm.remove())
				.then((data) => {
					if (!data.errCode || data.errCode === 0) {
						deferred.resolve(data);
					} else {
						deferred.reject(data);
					}
				});
		} else {
			deferred.reject();
		}
	}


	/**
	 * Определяет тип аттестации в зависимости от того за какой период поставлена оценка
	 * @param finalMark
	 * @returns {*}
	 */
	function getAttestationType(finalMark) {
		if (finalMark.module_id !== null) {
			return "module";
		} else if (finalMark.period_id !== null) {
			return "study";
		} else if (finalMark.attestation_period_id !== null) {
			return "attestation";
		}

		return finalMark.mark_type;
	}


	/**
	 * Возвращает значение итоговой оценки для отображения в интерфейсе
	 * @param finalMark итоговая оценка
	 * @returns {*}
	 */
	function getMarkValue(finalMark) {
		// нет оценки
		if (!finalMark || finalMark.no_mark) {
			return "б/о";
		}

		// не аттестован по уважительной причине
		if (!finalMark.attested && finalMark.is_good_reason) {
			return "нпа";
		}

		// не аттестован
		if (!finalMark.attested) {
			return "н/а";
		}

		// академическая задаолженность
		if (finalMark.academic_debt) {
			return "а/з";
		}

		// зачет/незачет
		if (finalMark.grade_system_type === "approve") {
			return (finalMark.value) ? "Зч" : "Нзч";
		}

		// значение оценки по-умолчанию
		return finalMark.value;
	}
}
