angular
	.module("ezd.backend")
	.factory("Scripts", Scripts);


/**
 *
 * @param CMS
 * @returns {Object|*}
 * @constructor
 */
Scripts.$inject = ["CMS"];

function Scripts(CMS) {
	const url = "/api/materials";

	CMS.extendCollection(url, (collection) => {
		collection.getTotalPages = (response) => {
			const totalEntries = response.headers("x-pagination-total-entries");
			const perPage = response.headers("x-pagination-per-page");

			return _.ceil(totalEntries / perPage);
		};

		return collection;
	});

	return CMS.service(url);
}
