import template from "./schedulesDownload.html";
import styles from "./styles.local.css";

const DIRECTIVE_PARAMS = {
	scope: {},
	template,
	controller: Controller,
	controllerAs: "sdCtrl"
};

angular
	.module("ezd.common.ui")
	.directive("schedulesDownload", () => DIRECTIVE_PARAMS);


Controller.$inject = ["$rootScope", "$q", "natSort", "$$academicYear", "$$classLevel", "$$classUnit", "$mdDialog", "$$profile"];
/**
 *
 * @param $rootScope
 * @param $q
 * @param natSort
 * @param $$academicYear
 * @param $$classLevel
 * @param $$classUnit
 * @param $mdDialog
 * @param $$profile
 * @constructor
 */
function Controller($rootScope, $q, natSort, $$academicYear, $$classLevel, $$classUnit, $mdDialog, $$profile) {
	const DEFAULT_DATE_FORMAT = "DD.MM.YYYY";

	const vm = this;
	let _academicYear = null;

	vm.SPO_EDUCATION_LEVEL_ID = 5;
	vm.classLevels = [];
	vm.classUnits = [];

	vm.classUnitPromise = null;

	vm.selected = {
		classUnitIds: [],
		date: moment().format(DEFAULT_DATE_FORMAT)
	};

	vm.styles = styles;


	vm.setClassUnit = setClassUnit;
	vm.getDownloadUrl = getDownloadUrl;
	vm.closeDialog = closeDialog;
	vm.isChecked = isChecked;
	vm.selectAll = selectAll;
	vm.unSelectAll = unSelectAll;


	init();


	function init() {
		$rootScope.global_promise = $q.all({
			classLevels: $$classLevel.collection.getList(),
			academicYear: $$academicYear.getSelected(),
			classUnits: $$classUnit.collection.getList({with_home_based: true}),
			currentProfile: $$profile.getCurrentProfile()
		})
			.then((data) => {
				_academicYear = data.academicYear;

				const isMentor = _.includes(data.currentProfile.roles, "mentor")
					&& !_.intersects(data.currentProfile.roles, ["school_admin", "deputy", "principal", "school_admin_read_only"]);

				_.remove(data.classLevels, {id: 14});
				_.remove(data.classUnits, {class_level_id: 14});

				if (isMentor) {
					vm.classUnits = _.filter(data.classUnits, (unit) => {
						return _.includes(data.currentProfile.managed_class_unit_ids, unit.id);
					});

					vm.classLevels = _.chain(data.classLevels)
						.filter((level) => {
							return _.find(vm.classUnits, {class_level_id: level.id});
						})
						.sortBy("level")
						.value();
				} else {
					vm.classUnits = data.classUnits;
					vm.classLevels = _.sortBy(data.classLevels, "level");
				}
				vm.selected.classUnitIds = _.map(vm.classUnits, "id");
			});
	}

	function setClassUnit(item) {
		const idx = vm.selected.classUnitIds.indexOf(item.id);
		if (idx > -1) {
			vm.selected.classUnitIds.splice(idx, 1);
		} else {
			vm.selected.classUnitIds.push(item.id);
		}
	}

	function isChecked(id) {
		return vm.selected.classUnitIds.indexOf(id) > -1;
	}

	function getDownloadUrl() {
		const beginDate = moment(vm.selected.date, DEFAULT_DATE_FORMAT).startOf("week").format(DEFAULT_DATE_FORMAT);
		const endDate = moment(vm.selected.date, DEFAULT_DATE_FORMAT).endOf("week").format(DEFAULT_DATE_FORMAT);

		if (vm.selected.classUnitIds.length === 0) {
			return "";
		}

		const classUnitIds = vm.selected.classUnitIds.join(",");


		return `/reports/api/schedules/xlsx?class_unit_ids=${classUnitIds}&academic_year_id=${_academicYear.id}&begin_date=${beginDate}&end_date=${endDate}&with_replacements=false`;
	}

	function selectAll() {
		vm.selected.classUnitIds = _.map(vm.classUnits, "id");
	}

	function unSelectAll() {
		vm.selected.classUnitIds = [];
	}

	function closeDialog() {
		$mdDialog.hide();
	}
}
