import {Component} from "app/decorators/index";
import styles from "./ezd-autocomplete-select.local.scss";

import selectTemplate from "./ezd-autocomplete-select.html";
import {selectArrow} from "images/icons/index";
import _filter from "lodash/fp/filter";
import _find from "lodash/fp/find";
import _isNull from "lodash/fp/isNull";

@Component({
	selector: "ezdAutocompleteSelect",
	bindings: {
		required: "<",
		collection: "<",
		idField: "@",
		nameField: "@",
		model: "=",
		onChange: "&",
		nullText: "@",
		placeholder: "@",
		width: "@",
		customStyle: "<",
		disabledText: "<",
		disabled: "<",
		type: "@",
		name: "@",
		onBlur: "&",
		showOnClick: "@"
	},
	controllerAs: "ezdAutocompleteSelectCtrl"
})
export class EzdAutocompleteSelectComponent {
	static $inject = ["$scope", "$timeout", "$element"];
	styles = styles;
	icons = {selectArrow};
	selected = {};
	showOptionsList = false;
	searchText = null;

	/**
	 *
	 * @param tElem
	 */
	static template(tElem) {
		const itemTemplate = tElem.find("ezd-item-template").html() || "{{::item[ezdAutocompleteSelectCtrl.nameField]}}";

		return selectTemplate.replace("<item-placeholder></item-placeholder>", itemTemplate);
	}

	/**
	 *
	 * @param $scope
	 * @param $timeout
	 * @param $element
	 */
	constructor($scope, $timeout, $element) {
		this.services = {$scope, $timeout, $element};
	}

	/**
	 *
	 */
	$onInit() {
		const {$scope} = this.services;
		this.idField = this.idField || "id";
		this.nameField = this.nameField || "name";
		this.placeholder = this.placeholder || "Выбрать";

		this.watcherModel = $scope.$watch(() => this.model, this.updateModel.bind(this));
	}

	/**
	 *
	 */
	$postLink() {
		const {$element} = this.services;

		if (this.width) {
			// меняем только ширину выпадающего списка элементов, но не самого компонента
			// $element.css({width: this.width});
		} else {
			this.width = ($element[0].clientWidth || 200) + "px";
		}
	}


	/**
	 *
	 * @param newVal
	 */
	updateModel(newVal) {
		if (_isNull(newVal)) {
			this.selected = {};
			this.searchText = this.nullText ? this.nullText : null;
		} else {
			this.selected = _find({[this.idField]: newVal})(this.collection);

			if (this.selected) {
				this.searchText = this.selected[this.nameField] || this.selected.name;
			}
		}
	}


	/**
	 *
	 * @param changes
	 */
	$onChanges(changes) {
		if (changes.collection) {
			this.filteredCollection = angular.copy(this.collection);
		}
		if (changes.collection && this.model) {
			this.selected = _.find(this.filteredCollection, {[this.idField]: this.model}) || {};
		}
	}


	/**
	 *
	 */
	$onDestroy() {
		this.watcherModel();
	}

	/**
	 * Закрытие выпадающего списка без выбора пунта
	 */
	optionsListClosed() {
		this.onBlur({$event: {name: this.name}});
	}

	/**
	 *
	 */
	toggleOptionsList() {
		this.showOptionsList = !this.showOptionsList;
	}

	/**
	 *
	 */
	onClick() {
		if (this.showOnClick) {
			if (this.nullText && this.nullText === this.searchText) {
				this.searchText = null;
			}
			this.filterItems();
		}
	}


	/**
	 *
	 */
	closeOptionsList() {
		this.showOptionsList = false;
	}


	/**
	 *
	 * @param item
	 */
	selectItem(item) {
		const {$timeout} = this.services;
		this.showOptionsList = false;

		this.selected = item;
		this.model = item ? this.selected[this.idField] : null;
		this.searchText = item ? item[this.nameField] : null;

		//	TODO: скользкая дорожка
		$timeout(this.onChange.bind(null, {$event: this.model}), 0);
	}

	/**
	 *
	 */
	filterItems() {
		if (!this.searchText) {
			this.filteredCollection = angular.copy(this.collection);

			return;
		}

		this.filteredCollection = _filter((item) => {
			return item[this.nameField].toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
		})(this.collection);
	}
}


angular
	.module("ezd.common.ui")
	.component(EzdAutocompleteSelectComponent.selector, EzdAutocompleteSelectComponent);
