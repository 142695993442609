import {Component} from "app/decorators/index";
import template from "./assignments-table-row.template.html";
import {chevronLeft, chevronRight} from "images/icons/index";
import styles from "./assignments-table-row.local.scss";
import moment from "tools/moment.local";

@Component({
	selector: "assignmentsTableRow",
	template,
	transclude: true,
	bindings: {
		student: "<",
		groups: "<",
		onSelect: "&",
		base: "<",
		offset: "<?"
	}
})
export class AssignmentsTableRowComponent {
	static $inject = ["$rootScope"];
	styles = styles;
	icons = {chevronLeft, chevronRight};

	/**
	 * */
	constructor($rootScope) {
		this.services = {$rootScope};
	}

	/**
	 * */
	$onInit() {
		const {$rootScope} = this.services;
		this.offset = this.offset ? this.offset : 0;
		$rootScope.$on("assignmentsTableHeader:shift", (ev, offset) => {
			this.offset = offset;
		});
	}

	/**
	 * Получить строку диапазона дат привязки
	 * */
	getAssignmentLabel(assignment) {
		if (assignment && assignment.begin_date && assignment.end_date) {
			return `${moment(assignment.begin_date, "YYYY-MM-DD").format("DD.MM.YYYY")} - ${moment(assignment.end_date, "YYYY-MM-DD").format("DD.MM.YYYY")}`;
		}

		return "";
	}
}
