

angular
	.module("ezd.common.ui")
	.directive("technicalWorks", TechnicalWorksDirective);

/**
 *
 * @returns {{replace: boolean, transclude: boolean, template: string}}
 * @constructor
 */
function TechnicalWorksDirective() {
	return {
		replace: true,
		transclude: true,
		template: "<div class=\"technical-works\" ng-transclude></div>"
	};
}
