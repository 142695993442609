class LockUser {
	static $inject = ["ACL", "EzdConfirm", "$location"];

	url = "/api/lock_user";

	constructor(ACL, EzdConfirm, $location) {
		this.acl = ACL;
		this.collection = ACL.all(this.url);
		this.services = {EzdConfirm, $location};
	}

	/**
	 * Обертка над методом one из restangular
	 * @param urlPart
	 * @returns {*|{get}}
	 */
	one(urlPart) {
		return this.acl.one(this.url, urlPart);
	}

	// /**
	//  * Проверить, нужно ли пользователю сменить пароль
	//  * @param profile
	//  * @returns {Promise<void>}
	//  */
	// async checkChangePasswordRequired(profile) {
	// 	const notRequiredChangePwdRoles = ["student", "parent", "curator", "observer", "admin", "admin_security"];
	//
	// 	if (_.intersects(profile.roles, notRequiredChangePwdRoles)) {
	// 		return;
	// 	}
	//
	// 	const lockUserInfo = await this.one("").get({user_id: profile.user_id});
	//
	// 	if (lockUserInfo.changePasswordRequired) {
	// 		await this.openChangePasswordModalSuggestion();
	// 	}
	// }

	/**
	 * Открыть модальное окно с предложением сменить пароль
	 * @returns {Promise<void>}
	 */
	async openChangePasswordModalSuggestion() {
		const {EzdConfirm, $location} = this.services;

		try {
			await EzdConfirm.confirm({
				text: "При первом входе рекомендуется сменить пароль. Перейти в личный кабинет для смены пароля",
				confirmButton: "Перейти"
			});
		} catch (e) {
			return;
		}

		$location.url("/cabinet");
	}
}


angular
	.module("ezd.backend")
	.service("LockUser", LockUser);
