

angular
	.module("ezd.backend")
	.service("$$teacher", $$teacher);

/**
 *
 * @param Jersey
 */
$$teacher.$inject = ["Jersey"];
function $$teacher(Jersey) {
	const url = "/api/teachers";
	const vm = this;

	vm.list = [];
	vm.collection = Jersey.all(url);
	vm.currentFilter = {};


	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	vm.bindOne = (id) => Jersey.one(url, id);
}