import {Component} from "app/decorators/index";
import styles from "./ezd-pure-select.local.scss";

import selectTemplate from "./ezd-pure-select.html";
import {selectArrow} from "images/icons/index";


@Component({
	selector: "ezdPureSelect",
	bindings: {
		collection: "<",
		idField: "@",
		nameField: "@",
		model: "=?",
		onChange: "&",
		nullText: "@",
		placeholder: "@",
		width: "@",
		customStyle: "<",
		disabledText: "@",
		disabled: "<",
		type: "@"
	}
})
class EzdPureSelectComponent {
	static $inject = ["$scope", "$timeout", "$element"];
	styles = styles;
	icons = {selectArrow};
	selected = {};
	showOptionsList = false;
	isOpened = false;


	/**
	 *
	 * @param tElem
	 */
	static template(tElem) {
		const itemTemplate = tElem.find("ezd-item-template").html() || "{{::item[$ctrl.nameField]}}";

		return selectTemplate.replace("<item-placeholder></item-placeholder>", itemTemplate);
	}


	/**
	 * @param $scope
	 * @param $timeout
	 * @param $element
	 */
	constructor($scope, $timeout, $element) {
		this.services = {$scope, $timeout, $element};
	}


	/**
	 * @returns {string}
	 */
	getHeaderClasses() {
		return _.compact([
			this.styles["ezd-pure-select-header"],
			this.type === "line" && this.styles["ezd-pure-select-header__line"]
		])
			.join(" ");
	}


	/**
	 *
	 */
	$onInit() {
		const {$scope} = this.services;
		this.idField = this.idField || "id";
		this.nameField = this.nameField || "name";
		this.placeholder = this.placeholder || "Выбрать";
		this.customStyle = this.customStyle || {};
		this.watcherModel = $scope.$watch(() => this.model, this.updateModel.bind(this));
		this.isOpened = false;
	}


	/**
	 *
	 */
	$postLink() {
		const {$element} = this.services;

		if (this.width) {
			$element.css({width: this.width});
		} else {
			this.width = ($element[0].clientWidth || 200) + "px";
		}
	}


	/**
	 * @param newVal
	 */
	updateModel(newVal) {
		if (_.isNull(newVal)) {
			this.selected = {};
		} else {
			this.selected = _.find(this.collection, {[this.idField]: newVal});
		}
	}


	/**
	 * @param changes
	 */
	$onChanges(changes) {
		if (changes.collection && this.model) {
			this.selected = _.find(this.collection, {[this.idField]: this.model}) || {};
		}
	}


	/**
	 *
	 */
	$onDestroy() {
		this.watcherModel();
		this.isOpened = false;
	}


	/**
	 * @param item
	 */
	selectItem(item) {
		this.isOpened = false;
		this.selected = item;
		this.model = item ? this.selected[this.idField] : null;
		this.onChange({$event: this.model});
	}


	/**
	 *
	 * @param $event
	 */
	toggleMenuOpened($event) {
		if ($event) {
			$event.stopPropagation();
		}
		this.isOpened = !this.isOpened;
	}
}


angular
	.module("ezd.common.ui")
	.component(EzdPureSelectComponent.selector, EzdPureSelectComponent);
