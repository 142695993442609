import "./ezd-button-group.scss";
import {EzdButtonGroupComponent} from "./button-group/ezd-button-group.component";
import {EzdButtonGroupItemComponent} from "./button-group-item/ezd-button-group-item.component";


export default angular
	.module("ezd.common.ui.button-group", [])
	.component(EzdButtonGroupComponent.selector, EzdButtonGroupComponent)
	.component(EzdButtonGroupItemComponent.selector, EzdButtonGroupItemComponent)
	.name;
