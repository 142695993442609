import template from "./inputTime.html";
import moment from "tools/moment.local";


angular
	.module("ezd.common.ui")
	.directive("inputTime", inputTimeDirective);


/**
 *
 * @returns {{scope: {model: string}, controller: inputTimeController, controllerAs: string, template}}
 */
function inputTimeDirective() {
	return {
		scope: {
			model: "=",
			onChange: "&",
			placeholder: "@",
			disabled: "<",
			required: "<",
			name: "@",
			minTime: "<",
			maxTime: "<"
		},
		controller: inputTimeController,
		controllerAs: "inputTimeCtrl",
		template
	};
}


/**
 *
 * @param $scope
 */
inputTimeController.$inject = ["$scope", "$timeout", "$element"];
/**
 * */
function inputTimeController($scope, $timeout, $element) {
	const vm = this;

	vm.upArrowActive = false;
	vm.downArrowActive = false;

	vm.activeInput = "";
	vm.timeHours = "";
	vm.timeMinutes = "";

	vm.placeholder = $scope.placeholder;
	vm.required = $scope.required;
	vm.name = $scope.name;
	vm.disabled = $scope.disabled;

	vm.upArrowClick = upArrowClick;
	vm.downArrowClick = downArrowClick;
	vm.setTimeToModel = setTimeToModel;

	vm.setPlaceholder = setPlaceholder;
	vm.setEditMode = setEditMode;
	vm.onOutsideClick = onOutsideClick;
	vm.generateRandomString = generateRandomString;

	vm.$onChange = $onChange;

	init();

	/**
	 * */
	function init() {
		// если уже есть данные в модели
		if (!_.isEmpty($scope.model)) {
			setTimeFromModel();
		} else if (vm.placeholder) {
			setPlaceholder(true);
		}
		// изменения в модели
		$scope.$watch(() => {
			return $scope.model;
		}, () => {
			setTimeFromModel();
		});
		// изменения в часах
		$scope.$watch(() => {
			return vm.timeHours;
		}, () => {
			if (vm.timeHours) {
				checkHours();
				// setTimeToModel();
				vm.$onChange();
			}
		});
		// изменения в минутах
		$scope.$watch(() => {
			return vm.timeMinutes;
		}, () => {
			if (vm.timeMinutes) {
				checkMinutes();
				// setTimeToModel();
				vm.$onChange();
			}
		});
		// изменения в ограничениях
		$scope.$watch("minTime", vm.$onChange.bind(vm));
		$scope.$watch("maxTime", vm.$onChange.bind(vm));

		$scope.$watch("disabled", (nV) => {
			vm.disabled = nV;
		});

		vm.timeHours = null;
		vm.timeMinutes = null;

		vm.groupName = generateRandomString();
	}

	/**
	 * */
	function $onChange() {
		if ($scope.minTime) {
			if (!moment.isMoment($scope.minTime)) {
				$scope.minTime = moment($scope.minTime, "HH:mm:ss");
			}

			if (moment($scope.model, "HH:mm:ss").isBefore($scope.minTime)) {
				$scope.model = moment($scope.minTime).format("HH:mm:ss");
				setTimeFromModel();
			}
		}

		if ($scope.maxTime) {
			if (!moment.isMoment($scope.maxTime)) {
				$scope.maxTime = moment($scope.maxTime, "HH:mm:ss");
			}

			if (moment($scope.model, "HH:mm:ss").isAfter($scope.maxTime)) {
				$scope.model = moment($scope.maxTime).format("HH:mm:ss");
				setTimeFromModel();
			}
		}

		$scope.onChange({
			model: $scope.model
		});
	}


	/**
	 * */
	function setTimeFromModel() {
		if (_.isEmpty($scope.model)) {
			return;
		}
		const time = $scope.model.split(":");
		if (vm.timeHours !== time[0]) {
			vm.timeHours = time[0];
		}
		if (vm.timeMinutes !== time[1]) {
			vm.timeMinutes = time[1];
		}
	}

	/**
	 * */
	function setPlaceholder(value) {
		vm.showPlaceholder = value;
	}

	/**
	 * */
	function setEditMode() {
		if (vm.showPlaceholder) {
			setPlaceholder(false);
			$timeout(() => {
				$element[0].querySelector("input").focus();
			});
		}
	}

	/**
	 * */
	function onOutsideClick() {
		vm.activeInput = "";
		setPlaceholder(!(vm.timeHours || vm.timeMinutes));
	}

	/**
	 * */
	function setTimeToModel() {
		const timeObj = moment().startOf("day");

		timeObj.hours(_.int(vm.timeHours || 0));
		timeObj.minutes(_.int(vm.timeMinutes || 0));
		$scope.model = timeObj.format("HH:mm:ss");

		vm.$onChange();
	}

	/**
	 * Обработчик нажатия на стрелку вверх
	 */
	function upArrowClick() {
		if (!vm.activeInput) {
			vm.activeInput = "minutes";
		}
		if (vm.activeInput === "hours") {
			incrementHours();
		}
		if (vm.activeInput === "minutes") {
			incrementMinutes();
		}
		setTimeToModel();
	}

	/**
	 * обработчик нажатия на стрелку вниз
	 */
	function downArrowClick() {
		if (!vm.activeInput) {
			vm.activeInput = "minutes";
		}
		if (vm.activeInput === "hours") {
			decrementHours();
		}
		if (vm.activeInput === "minutes") {
			decrementMinutes();
		}
		setTimeToModel();
	}

	/**
	 * Увеличиваем количество часов
	 */
	function incrementHours() {
		const hours = _.int(vm.timeHours) || 0;
		vm.timeHours = moment().hours(hours + 1).format("HH");
	}

	/**
	 * Уменьшаем количество часов
	 */
	function decrementHours() {
		const hours = _.int(vm.timeHours) || 0;
		vm.timeHours = moment().hours(hours - 1).format("HH");
	}

	/**
	 * Увеличиваем количество минут
	 */
	function incrementMinutes() {
		const minutes = _.int(vm.timeMinutes) || 0;
		vm.timeMinutes = moment().minutes(minutes + 1).format("mm");
	}

	/**
	 * Уменьшаем количество минут
	 */
	function decrementMinutes() {
		const minutes = _.int(vm.timeMinutes) || 0;
		vm.timeMinutes = moment().minutes(minutes - 1).format("mm");
	}

	/**
	 * проверка валидности введенных часов
	 */
	function checkHours() {
		if (vm.timeHours !== "0") {
			vm.timeHours = vm.timeHours.replace(/[^0-9]/gim, "");
			const checkedValue = _.int(vm.timeHours) || 0;
			if (checkedValue > 23 || checkedValue <= 0) {
				vm.timeHours = "00";
			}
		}
	}

	/**
	 * проверка валидности введенных минут
	 */
	function checkMinutes() {
		if (vm.timeMinutes !== "0") {
			vm.timeMinutes = vm.timeMinutes.replace(/[^0-9]/gim, "");
			const checkedValue = _.int(vm.timeMinutes) || 0;
			if (checkedValue > 59 || checkedValue <= 0) {
				vm.timeMinutes = "00";
			}
		}
	}

	/**
	 *
	 * @returns {string}
	 */
	function generateRandomString() {
		return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
	}
}
