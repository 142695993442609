const URL = "/api/exam/marks";

class ExamMarks {
	static $inject = ["Core"];

	/**
	 * Constructor
	 */
	constructor(Core) {
		this.CoreService = Core;
		this.marksCollection = Core.all("/api/exam/marks");
		this.subjectsCollection = Core.all("/api/exam/subjects");
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	one(id) {
		return this.CoreService.one(URL, id);
	}
}


angular
	.module("ezd.backend")
	.service("ExamMarks", ExamMarks);
