const URL = "/api/ec_schedule_items";

class ECScheduleItemsService {
	static $inject = ["EC"];

	/**
	 * Constructor
	 */
	constructor(EC) {
		this.ECService = EC;
		this.collection = EC.all(URL);
	}


	/**
	 * Обертка над методом one из restangular
	 * @param id {Number}
	 */
	bindOne(id) {
		return this.ECService.one(URL, id);
	}
}


angular
	.module("ezd.backend")
	.service("ECScheduleItems", ECScheduleItemsService);
